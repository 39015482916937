import {
  StyledEngineProvider,
  Theme,
  ThemeProvider,
  createTheme,
} from '@mui/material/styles';
import { StyleRulesCallback } from '@mui/styles';
import makeStyles from '@mui/styles/makeStyles';
import { FunctionComponent, useState } from 'react';

import {
  IThemeWrapperContext,
  ThemeWrapperContext,
} from './ThemeWrapperContext';

declare module '@mui/material/styles' {
  interface ExtendedTheme {
    spacingNumber(s: number): number;
  }
  interface Theme extends ExtendedTheme {}
}

const theme = createTheme({
  palette: {
    primary: {
      main: '#00364F',
    },
    secondary: {
      main: '#E86E2A',
    },
    background: {
      default: '#ffffff',
    },
  },
  /**
   * Global typography. This change was made to stop MUI from overriding AGGrid
   * settings needed to wrap cell and header text automatically
   */
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          textOverflow: 'clip',
          overflow: 'visible',
          whiteSpace: 'normal',
        },
      },
    },
  },
} as const);

// @ts-ignore
type StyleMaker<Props> = StyleRulesCallback<Theme, Props>;

const ThemeWrapper: FunctionComponent<React.PropsWithChildren<unknown>> = (
  props,
) => {
  const { children } = props;

  const [themeWrapperContext, setThemeWrapperContext] =
    useState<IThemeWrapperContext>();

  const overrideTheme = (themeParam: Theme) => {
    setThemeWrapperContext({ overrideTheme, theme: themeParam });
  };

  const initialThemeContext: IThemeWrapperContext = {
    theme: {
      ...theme,
      spacingNumber: (s: number) => Number(theme.spacing(s).slice(0, -2)),
    },
    overrideTheme,
  };

  if (!themeWrapperContext) {
    setThemeWrapperContext(initialThemeContext);
    return;
  }

  return (
    <ThemeWrapperContext.Provider value={themeWrapperContext}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={themeWrapperContext.theme}>
          {children}
        </ThemeProvider>
      </StyledEngineProvider>
    </ThemeWrapperContext.Provider>
  );
};

export function makeThemedStyles<Props extends Record<string, any>>(
  styleMaker: StyleMaker<Props>,
) {
  // @ts-ignore
  return makeStyles<IThemeWrapperContext, Props>(styleMaker);
}

export default ThemeWrapper;
