import _ from 'lodash';
import outliers from 'outliers';

import { ClientManager } from '../clientManager';

interface IDistributeDataParams {
  records: any[];
  field: string;
  maxBuckets?: number;
  minBucketSize?: number;
  removeOutliers?: boolean; //coming soon
  bucketRounding?: number;
  ascDesc?: 'asc' | 'desc';
  orderBy?: {
    collection: any[];
    iteratee: any;
    order: any;
  };
}

/** Aggregates records */
export class DistributeData {
  public clientManager: ClientManager;

  public initialize(clientManager: ClientManager) {
    this.clientManager = clientManager;
  }
  public distributeNumericData(params: IDistributeDataParams): any {
    const {
      records,
      field,
      ascDesc = 'asc',
      removeOutliers = false,
      minBucketSize,
      maxBuckets,
      bucketRounding,
    } = params;
    const returnObj = {};
    let valueArray = _.orderBy(records, field, ascDesc);
    if (removeOutliers) {
      valueArray = valueArray.filter(outliers(field));
    }
    const targetBucketSize = Math.max(
      minBucketSize || 0,
      Math.round(valueArray.length / maxBuckets),
    );
    const numBuckets = Math.min(
      Math.ceil(valueArray.length / targetBucketSize),
      maxBuckets,
    );
    const bucketSize = Math.ceil(valueArray.length / numBuckets);

    let nextRecord = 0;
    for (let bucket = 0; bucket < numBuckets; bucket++) {
      if (nextRecord >= valueArray.length) {
        break;
      }
      const nextBucket = { min: null, max: null, records: [] };
      if (ascDesc === 'asc') {
        nextBucket.min = bucketRounding
          ? Math.floor(valueArray[nextRecord][field] / bucketRounding) *
            bucketRounding
          : valueArray[nextRecord][field];
      } else {
        nextBucket.max = bucketRounding
          ? Math.floor(valueArray[nextRecord][field] / bucketRounding) *
            bucketRounding
          : valueArray[nextRecord][field];
      }
      const targetIndex = Math.min(
        (bucket + 1) * bucketSize - 1,
        valueArray.length - 1,
      );
      while (nextRecord < valueArray.length) {
        nextBucket.records.push(valueArray[nextRecord]);
        if (nextRecord == valueArray.length - 1) {
          nextRecord++;
          break;
        }
        if (
          bucket !== numBuckets - 1 && // if last bucket don't quit until all records are pushed
          (!minBucketSize ||
            valueArray.length - (nextRecord + 1) > minBucketSize) &&
          nextRecord >= targetIndex &&
          valueArray[nextRecord][field] !== valueArray[nextRecord + 1][field]
        ) {
          if (
            !bucketRounding ||
            Math.floor(valueArray[nextRecord][field] / bucketRounding) !==
              Math.floor(valueArray[nextRecord + 1][field] / bucketRounding)
          ) {
            nextRecord++;
            break;
          }
        }
        nextRecord++;
      }
      if (ascDesc === 'asc') {
        nextBucket.max = bucketRounding
          ? Math.ceil(valueArray[nextRecord - 1][field] / bucketRounding) *
            bucketRounding
          : valueArray[nextRecord - 1][field];
        returnObj[`${nextBucket.min}-${nextBucket.max}`] = nextBucket;
      } else {
        nextBucket.min = bucketRounding
          ? Math.floor(valueArray[nextRecord - 1][field] / bucketRounding) *
            bucketRounding
          : valueArray[nextRecord - 1][field];
        returnObj[`${nextBucket.max}-${nextBucket.min}`] = nextBucket;
      }
    }
    return returnObj;
  }
}
