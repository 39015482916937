import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const UI_STATE = 'uiState';

export const uiStateSlice = createSlice({
  name: UI_STATE,
  initialState: {} as { [key: string]: any },
  reducers: {
    updateUIState: (
      state,
      action: PayloadAction<{ key: string; value: any }>,
    ) => {
      const { key, value } = action.payload;
      state[key] = value;
    },
    setInitialState: (state, action: PayloadAction<{ [key: string]: any }>) => {
      Object.assign(state, action.payload);
    },
  },
});

export const { setInitialState, updateUIState } = uiStateSlice.actions;

export default uiStateSlice.reducer;
