import { Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';

const styles = () => ({});

function HeaderDiv(props) {
  const { children, backgroundColor, textColor, header, style } = props;
  return (
    <div style={style}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          width: '100%',
        }}
      >
        <Typography
          style={{
            backgroundColor,
            color: textColor,
            height: '24px',
            width: '100%',
            paddingLeft: '5px',
            boxSizing: 'border-box',
          }}
        >
          {header}
        </Typography>
        <div style={{ overflow: 'auto', height: '100%', width: '100%' }}>
          {children}
        </div>
      </div>
    </div>
  );
}

export default withStyles(styles, { withTheme: true })(HeaderDiv);
