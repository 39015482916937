import { Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';

export const TitleWrapper = (Container, title: string, theme: Theme) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Typography
        sx={{
          background: theme.palette.primary.main,
          color: 'white',
          textAlign: 'center',
        }}
        component="h1"
      >
        {title}
      </Typography>
      {Container}
    </div>
  );
};
