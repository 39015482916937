import { Grid, Paper } from '@mui/material';
import _ from 'lodash';
import { compose } from 'redux';
import { AccessType } from 'universal/permissionManager';

import { compileProperties, widgetCan } from '../../../util/widgetUtilities';
import HeaderDiv from '../../molecules/HeaderDiv';
import { withActions } from '../../widgetEngine/ActionEnabler';
import Container from '../../widgetEngine/Container';

interface IProps {
  component;
}

class LayoutWidget extends Container<IProps> {
  public declare props;
  public declare state;
  constructor(props: IProps) {
    super(props);
    const {
      component: { widgetPool, layouts, selectorPools },
    } = props;
    const widgetsByName = widgetPool.reduce(
      (acc, widget) => Object.assign(acc, { [widget.name]: widget }),
      {},
    );
    const layoutsByName = layouts.reduce(
      (acc, layout) => Object.assign(acc, { [layout.name]: layout }),
      {},
    );
    const selectorPoolsByBName = selectorPools.reduce(
      (acc, selectorPool) =>
        Object.assign(acc, { [selectorPool.name]: selectorPool }),
      {},
    );
    this.state = {
      widgetPool: widgetsByName,
      layouts: layoutsByName,
      selectorPools: selectorPoolsByBName,
    };
  }

  processFlexAreas(flexAreas) {
    const {
      context: { nestedConfigContext: nonWidgetContext },
    } = this.props;
    const { widgetPool, selectorPools } = this.state;
    return flexAreas.map((area) => {
      const areaProperties = compileProperties(
        area.properties,
        nonWidgetContext,
      );
      const {
        flexDirection: direction,
        justifyContent: justify,
        alignItems,
        alignContent,
        border,
        gridColumn,
        gridRow,
        width,
        height,
        padding,
        allowGridBlowout,
        xl,
        lg,
        md,
        sm,
        xs,
        header,
        headerTextColor,
        headerBackgroundColor,
        margin,
        render,
        preventRender,
      } = areaProperties;

      const parentGridSizing = { xl, lg, md, sm, xs };
      if (!render || preventRender) {
        return null;
      }
      const poolSelectors = area.selectorPoolSelectors.reduce(
        (acc, selectorPoolSelector) => {
          const selectorProperties = compileProperties(
            selectorPoolSelector.properties,
            nonWidgetContext,
          );
          const { selectorPoolName } = selectorProperties;
          const selectorPool = selectorPools[selectorPoolName];
          return acc.concat(selectorPool.widgetSelectors);
        },
        [],
      );
      const arraySelectors = this.convertArraysToSelectors(area.widgetArrays);

      const allSelectors = [
        ...area.widgetSelectors,
        ...poolSelectors,
        ...arraySelectors,
      ];
      const areaContent = allSelectors.reduce((acc, selector) => {
        let selectorProperties = selector.properties;
        if (selector.type !== 'WidgetArray') {
          selectorProperties = compileProperties(
            selector.properties,
            nonWidgetContext,
          );
        }
        const {
          widgetName,
          // eslint-disable-next-line @typescript-eslint/no-shadow
          xl,
          // eslint-disable-next-line @typescript-eslint/no-shadow
          lg,
          // eslint-disable-next-line @typescript-eslint/no-shadow
          md,
          // eslint-disable-next-line @typescript-eslint/no-shadow
          sm,
          // eslint-disable-next-line @typescript-eslint/no-shadow
          xs,
          useOwnGridSizing,
          // eslint-disable-next-line @typescript-eslint/no-shadow
          padding,
        } = selectorProperties;
        const childGridSizing = { xl, lg, md, sm, xs };
        const widget = widgetPool[widgetName];
        const children = this.generateChildren([widget], {
          renderer: (child) => (
            <Grid
              item
              style={{ padding }}
              {...(useOwnGridSizing ? childGridSizing : parentGridSizing)}
            >
              {child}
            </Grid>
          ),
        });

        return [...acc, ...children];
      }, []);

      const Outer = border === 'paper' ? Paper : 'div';
      const Wrapper = header ? HeaderDiv : 'div';

      return (
        <Outer
          style={{
            margin,
            gridColumn,
            gridRow,
            ...(!allowGridBlowout && { minWidth: 0, minHeight: 0 }),
          }}
        >
          <Wrapper
            style={{
              width,
              height,
              border,
              boxSizing: 'border-box',
            }}
            {...(header && {
              header,
              textColor: headerTextColor,
              backgroundColor: headerBackgroundColor,
            })}
          >
            <Grid
              container
              style={{
                flex: 1,
                display: 'flex',
                height: '100%',
                width: '100%',
                overflow: 'auto',
                padding,
              }}
              // spacing={spacing}
              direction={direction}
              justifyContent={justify}
              alignItems={alignItems}
              alignContent={alignContent}
              // component={component}
            >
              {areaContent}
            </Grid>
          </Wrapper>
        </Outer>
      );
    });
  }

  processSelectors(widgetSelectors) {
    const {
      context: { nestedConfigContext: nonWidgetContext },
    } = this.props;
    const { widgetPool } = this.state;
    return widgetSelectors.map((widgetSelector, i) => {
      const {
        gridRow,
        gridColumn,
        alignSelf,
        justifySelf,
        padding,
        widgetName,
      } = compileProperties(widgetSelector.properties, nonWidgetContext);

      return (
        <div
          style={{ gridRow, gridColumn, alignSelf, justifySelf, padding }}
          key={i}
        >
          {this.generateChildren([widgetPool[widgetName]])}
        </div>
      );
    });
  }

  convertArraysToSelectors(widgetArrays) {
    const {
      context: { nestedConfigContext: nonWidgetContext },
    } = this.props;
    const widgetSelectors = [];
    widgetArrays.map((widgetArray) => {
      const { widgetNames } = compileProperties(
        widgetArray.properties,
        nonWidgetContext,
      );
      widgetNames.forEach((widgetName) => {
        const nextSelector = _.cloneDeep(widgetArray);
        nextSelector.properties.widgetName = widgetName;
        widgetSelectors.push(nextSelector);
      });
    });
    return widgetSelectors;
  }

  render() {
    const {
      layoutToUse,
      context: { nestedConfigContext: nonWidgetContext },
      getPermission,
    } = this.props;
    const { layouts } = this.state;

    const layout = layouts[layoutToUse] || this.props.component.layouts[0];
    const layoutProperites = compileProperties(
      layout.properties,
      nonWidgetContext,
    );

    const {
      border,
      maxWidth,
      maxHeight,
      width,
      height,
      padding,
      boxSizing,
      gridColumns: gridTemplateColumns,
      gridRows: gridTemplateRows,
      borderWidth,
      borderColor,
      borderRadius,
      backgroundColor,
      color,
      alignItems,
      justifyItems,
      overflow,
      gridRowGap,
      gridColumnGap,
      marginLeft,
      marginRight,
      permissionId,
    } = layoutProperites;

    const allowedToRead = widgetCan(
      AccessType.Read,
      getPermission(permissionId),
    );

    if (permissionId && !allowedToRead) {
      return null;
    }

    const { flexAreas, widgetSelectors, widgetArrays } = layout;
    const arraySelectors = this.convertArraysToSelectors(widgetArrays);
    const processedSelectors = this.processSelectors([
      ...arraySelectors,
      ...widgetSelectors,
    ]);
    const processedFlexAreas = this.processFlexAreas(flexAreas);

    const containerStyle = {
      flex: 1,
      display: 'grid',
      gridTemplateColumns,
      gridTemplateRows,
      borderStyle: border,
      borderWidth,
      borderColor,
      borderRadius,
      backgroundColor,
      color,
      alignItems,
      justifyItems,
      overflow,
      minHeight: 0,
      minWidth: 0,
      gridRowGap,
      gridColumnGap,
      marginLeft,
      marginRight,
    };

    const layoutContent = [...processedSelectors, ...processedFlexAreas];

    const Wrapper = border === 'paper' ? Paper : 'div';

    return (
      <Wrapper
        key="layout"
        style={{
          maxWidth,
          maxHeight,
          width,
          height,
          display: 'flex',
          flexGrow: 1,
          padding,
          boxSizing,
        }}
      >
        <div style={containerStyle}>{layoutContent}</div>
      </Wrapper>
    );
  }
}

export default compose(withActions)(LayoutWidget);
