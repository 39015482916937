import { Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import _ from 'lodash';
import { Component } from 'react';
import { JSONTree } from 'react-json-tree';
import { StageType } from 'universal/types';
import { isEqualFromRight, omitDeep } from 'universal/utilityFunctions';

import JSONTreeLabelRenderer from './JSONTreeLabelRenderer';

const styles = () => ({});

const treeTheme = {
  base00: '#fafafa', // background
  base01: '#202020',
  base02: '#303030',
  base03: '#505050',
  base04: '#b0b0b0',
  base05: '#d0d0d0',
  base06: '#e0e0e0',
  base07: '#ffffff',
  base08: '#eb008a',
  base09: '#f29333',
  base0A: '#f8ca12',
  base0B: '#af3f00', // values
  base0C: '#00aabb',
  base0D: '#1f8c98', // keys
  base0E: '#b31e8d',
  base0F: '#7a2d00',
};

const nonDisplayedFields = {
  treeId: true,
  properties: true,
  widgetId: true,
  name: true,
  category: true,
  recordType: true,
  id: true,
  isConfiguration: true,
  configType: true,
  configName: true,
  matchProperties: true,
  parentType: true,
  parentProperties: true,
  subscribeFields: true,
};

interface IProps {
  tree;
  canEditNode;
  setEditingNodePath;
  addNode;
  deleteNode;
  editingNodePath;
  copyNode;
  pasteNode;
  moveNode;
  classes;
  getNode;
}

class WidgetMap extends Component<IProps> {
  constructor(props) {
    super(props);
    this.labelRenderer = this.labelRenderer.bind(this);
    this.shouldExpandNode = this.shouldExpandNode.bind(this);
  }

  public shouldExpandNode(keyPath) {
    if (keyPath[1] === 'events' || keyPath[0] === 'stages') {
      return true;
    }
    const { editingNodePath } = this.props;
    return isEqualFromRight(editingNodePath, keyPath);
  }

  public labelRenderer(keyPath) {
    const {
      tree,
      canEditNode,
      setEditingNodePath,
      addNode,
      deleteNode,
      editingNodePath,
      copyNode,
      pasteNode,
      moveNode,
      getNode,
    } = this.props;

    const node = _.get(tree, keyPath.slice().reverse());

    return (
      <JSONTreeLabelRenderer
        editingNodePath={editingNodePath}
        selectNode={setEditingNodePath}
        addNode={addNode}
        deleteNode={deleteNode}
        keyPath={keyPath}
        canEditNode={canEditNode}
        node={node}
        copyNode={copyNode}
        pasteNode={pasteNode}
        moveNode={moveNode}
        getNode={getNode}
      >
        {keyPath[0]}
      </JSONTreeLabelRenderer>
    );
  }

  public render() {
    const { tree } = this.props;

    if (!tree) {
      return <Typography>Select a tree</Typography>;
    }

    const treeWithoutStageProperties = removeStageProperties(tree);

    // prunes fields for tree display in a non mutating util function, tree needs to be first for speed
    const treeForDisplay = omitDeep(
      treeWithoutStageProperties,
      nonDisplayedFields,
    );

    return (
      <JSONTree
        // className={classes.JSONTree}
        theme={treeTheme}
        data={treeForDisplay}
        invertTheme={false}
        hideRoot
        getItemString={() => null}
        // sortObjectKeys={(a) => {
        //  return a === 'children';
        //}}
        shouldExpandNode={this.shouldExpandNode}
        labelRenderer={this.labelRenderer}
      />
    );
  }
}

export default withStyles(styles, { withTheme: true })(WidgetMap);

function removeStageProperties(tree) {
  const newTree = _.cloneDeep(tree);
  removeStagePropertiesRecurse(newTree);
  return newTree;
}

function removeStagePropertiesRecurse(tree) {
  if (!Array.isArray(tree) && typeof tree !== 'object') {
    return;
  }
  if (tree?.stages) {
    tree.stages.forEach((stage) => {
      const execPipeline = stage._stageType === StageType.execPipeline;
      Object.keys(stage).forEach((propertyName) => {
        if (!execPipeline || propertyName !== 'stages') {
          delete stage[propertyName];
        }
      });
    });
  }

  _.forEach(tree, (subTree) => removeStagePropertiesRecurse(subTree));
}
