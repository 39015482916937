import {
  ExcelExportFooter,
  ExcelExportGroupFooter,
} from '@progress/kendo-react-excel-export';
import keyMirror from 'keymirror';

import {
  compileProperties,
  convertColorToHex,
} from '../../../util/widgetUtilities';

import { flattenNestedItems, getCellDisplay } from './KendoGridUtilities';

export const exportCellTypes = keyMirror({
  cell: null,
  groupCell: null,
  footerCell: null,
});

class KendoExportGroupFooter extends ExcelExportGroupFooter {
  public render() {
    const { cellProps, cell, context, columnField, customAggregator, allRows } =
      this.props as any;

    const {
      field = columnField,
      group: { items },
      column,
      aggregates,
    } = cellProps;

    const { properties } = cell;

    const cellContext = {
      ...context,
      ...cellProps,
    };

    const compiledProperties = compileProperties(properties, cellContext);

    const { customAggregation } = compiledProperties;

    const aggregatorInput = {
      ...context,
      _rows: allRows,
      _column: column,
      _groupRows: flattenNestedItems(items),
    };

    const aggregateValue = customAggregation
      ? customAggregator(customAggregation, aggregatorInput)
      : (aggregates[field] && Object.values(aggregates[field])[0]) || '';

    const displayValue = getCellDisplay(
      properties.display,
      aggregateValue,
      cellContext,
    );

    return displayValue;
  }
}

class KendoExportFooter extends ExcelExportFooter {
  public render() {
    const {
      cellProps,
      cell,
      context,
      customAggregator,
      allRows = [],
    } = this.props as any;

    const { column } = cellProps;

    const { properties } = cell;

    const cellContext = {
      ...context,
      ...cellProps,
    };

    const compiledProperties = compileProperties(properties, cellContext);

    const { customAggregation } = compiledProperties;

    const aggregatorInput = {
      ...context,
      _rows: allRows,
      _column: column,
    };

    const aggregateValue = customAggregation
      ? customAggregator(customAggregation, aggregatorInput)
      : '';

    const displayValue = getCellDisplay(
      properties.display,
      aggregateValue,
      cellContext,
    );

    return displayValue;
  }
}

function buildCellOptions(props) {
  const {
    context,
    // cellProps,
    cell: { properties },
  } = props;
  const compiledProperties = compileProperties(properties, context);

  const { color, backgroundColor, italic, bold, textWrap, excelFormat } =
    compiledProperties;

  return {
    bold,
    ...(backgroundColor && { background: convertColorToHex(backgroundColor) }),
    ...(color && { color: convertColorToHex(color) }),
    italic,
    wrap: textWrap,
    format: excelFormat,
  };
}

export default function buildExportCell(additionalProps) {
  const { cellType } = additionalProps;
  if (cellType === exportCellTypes.groupCell) {
    return {
      groupFooter: function CustomCell(cellProps) {
        return (
          <KendoExportGroupFooter cellProps={cellProps} {...additionalProps} />
        );
      },
      groupFooterCellOptions: buildCellOptions(additionalProps),
    };
  }
  if (cellType === exportCellTypes.footerCell) {
    return {
      footer: function CustomCell(cellProps) {
        return <KendoExportFooter cellProps={cellProps} {...additionalProps} />;
      },
      footerCellOptions: buildCellOptions(additionalProps),
    };
  }
  if (cellType === exportCellTypes.cell) {
    return {
      cellOptions: buildCellOptions(additionalProps),
    };
  }
}
