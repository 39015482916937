import _ from 'lodash';
import { createSelector } from 'reselect';
import { reThrow } from 'universal/errors/errorLog';
import { getLogger, Loggers, LogLevels } from 'universal/loggerSupport';

import { IData, resolveGlobalAddress, resolveRawPath } from './context';
import { STATE_DATA } from './data';
import { store } from './store';

// import { EVENT_BINDING_CONTAINER, STATE_DATA } from './data';

interface ISubscriptionMap {
  // The order of the keys corresponds to the order of the values in the selectionResults array
  [key: string]: ISubscriptionMap | SubscriptionFields;
}

const logger = getLogger({
  name: Loggers.CLIENT,
  level: LogLevels.Info,
});

type SubscriptionFields = [];

export const getStoreState = (
  requestedFields: Array<string | string[]>,
  aliases,
) => {
  const selector = createComposeContextSelector(requestedFields, aliases, {});
  const state = store.getState();
  return selector(state);
};

export const createSubscriptionMapSelector = (
  subscriptionMap: ISubscriptionMap,
  aliases,
  memoizedPaths,
) => {
  const selectors = Object.values(subscriptionMap).map((subscriptionLayer) => {
    if (Array.isArray(subscriptionLayer)) {
      return createComposeContextSelector(
        subscriptionLayer as SubscriptionFields,
        aliases,
        memoizedPaths,
      );
    } else if (typeof subscriptionLayer === 'object') {
      return createSubscriptionMapSelector(
        subscriptionLayer as ISubscriptionMap,
        aliases,
        memoizedPaths,
      );
    } else {
      throw new Error(
        `createSubscriptionMapSelector received non object subscriptionMap ${subscriptionLayer}`,
      );
    }
  });

  return createSelector(selectors, (...selectorResults) => {
    const contextByType = Object.keys(subscriptionMap).reduce(
      (acc, type, index) => {
        return Object.assign(acc, { [type]: selectorResults[index] });
      },
      {},
    );
    return contextByType;
  });
};

export const createComposeContextSelector = (
  subscribeFields: Array<string | string[]>,
  aliases,
  memoizedPaths,
) => {
  const selectors = subscribeFields.map((field) =>
    createSelectorFromRawPath(field, aliases, memoizedPaths),
  );

  return createSelector(selectors, (...selectorResults) => {
    const newContext = subscribeFields.reduce((acc, rawPath, index) => {
      // this causes referential inequality in nested store accessors
      // if we are subscribing to placement.quantity, then the quantity is extracted
      // and placed into a new object placement. However, this selector is memoized against
      // the values of its child selectorResults, so these will only show up as different when
      // a value has changed, so any updates would happen anyway. The nested selector objects will
      // always show up when observing context diffs, despite not being the cause
      try {
        return _.set(acc, rawPath, selectorResults[index]);
      } catch (error) {
        reThrow({
          error,
          logger,
          message: `Problem setting store results for path: ${rawPath} on ${JSON.stringify(
            selectorResults[index],
          )}`,
        });
      }
    }, {});
    return newContext;
  });
};

export function createSelectorFromRawPath(rawPath, aliases, memoizedPaths) {
  const { path, aliases: perspectiveAliases } = resolveRawPath(
    rawPath,
    aliases,
  );

  const { widgetPath: perspectivePath } = perspectiveAliases;

  if ((path as IData).data) {
    return () => (path as IData).data;
  }

  return (state) => selectFromPath(state, path, perspectivePath, memoizedPaths);
}

function selectFromPath(state, path, perspectivePath, memoizedPaths) {
  if (memoizedPaths[path]) {
    return _.get(state[STATE_DATA], memoizedPaths[path]);
  }

  if (path.length && path[0][0] === '/') {
    const globalPath = resolveGlobalAddress(path);
    memoizedPaths[path] = globalPath;
    return _.get(state[STATE_DATA], globalPath);
  }

  const { value, searchPath } = findValue(path, perspectivePath, state);
  if (value !== undefined /* && path[0] !== EVENT_BINDING_CONTAINER*/) {
    memoizedPaths[path] = searchPath;
  }
  return value;
}

const findValue = (path, widgetPath, state) => {
  const searchPath: any = widgetPath.concat('context', path);
  const value = _.get(state[STATE_DATA], searchPath);

  if (value === undefined) {
    return widgetPath.length < 1
      ? { value: undefined }
      : findValue(path, widgetPath.slice(0, widgetPath.length - 1), state);
  }
  return { value, searchPath };
};
