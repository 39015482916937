import withStyles from '@mui/styles/withStyles';
import randomColor from 'randomcolor';
import { useMemo } from 'react';
import {
  Area,
  Bar,
  BarChart,
  Brush,
  CartesianGrid,
  ComposedChart,
  Label,
  LabelList,
  Legend,
  Line,
  ReferenceDot,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { compose } from 'redux';

import { compileProperties } from '../../../util/widgetUtilities';
import { withActions } from '../../widgetEngine/ActionEnabler';

const styles = {
  roboto: { fontFamily: 'Roboto, sans-serif', fontSize: 10 },
};

const graphImports = {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Line,
  Area,
  ReferenceLine,
  ReferenceDot,
  Brush,
  LabelList,
  Label,
};

function DataVis(props) {
  const {
    component: { graphs },
    data,
    classes,
    context: { nestedConfigContext: context },
  } = props;

  const graphComponents = useMemo(
    () => buildGraphComponent(graphs, classes, context),
    [graphs, classes, context],
  );
  return (
    <ResponsiveContainer
      width="97%"
      height="97%"
      debounce={50}
      key="dataVisWidget"
      className={classes.roboto}
    >
      <ComposedChart
        margin={{ top: 15, right: 10, left: 10, bottom: 10 }}
        data={data}
        className={classes.roboto}
      >
        {graphComponents}
      </ComposedChart>
    </ResponsiveContainer>
  );
}

export default compose(
  withActions,
  withStyles(styles, { withTheme: true }),
)(DataVis);

function buildGraphComponent(graphChildren, classes, context, parentContext?) {
  const components = graphChildren.reduce((graphAcc, graph) => {
    // FIXME lines below are so we don't have to create a widget type 'Label' for this specific type of data viz axis label, but reCharts expects a Label child -- didn't see a way to alias
    if (graph.type === 'DataVisLabel') {
      graph.type = 'Label';
    }
    const Component = graphImports[graph.type];
    const compileContext = {
      ...context,
      ...parentContext,
      ...graph.duplicateContext,
    };

    const compiledProperties = compileProperties(
      graph.properties,
      compileContext,
    );
    if (
      graph.matchProperties.fill &&
      (!compiledProperties.fill || compiledProperties.fill.startsWith('random'))
    ) {
      if (compiledProperties.duplicateBy) {
        compiledProperties.fill = compiledProperties.fill || 'random';
      } else {
        const args =
          (compiledProperties.fill && compiledProperties.fill.split(' ')) ||
          'random';
        compiledProperties.fill = randomColor({
          ...(args[1] && { hue: args[1] }),
          ...(args[2] && { luminosity: args[2] }),
        });
      }
    }

    if (
      compiledProperties.duplicateBy &&
      Array.isArray(compiledProperties.duplicateBy)
    ) {
      const duplicateMetaGraphs = compiledProperties.duplicateBy.map(
        (duplicateContext) => {
          return {
            ...graph,
            duplicateContext,
            properties: {
              ...graph.properties,
              duplicateBy: false,
            },
          };
        },
      );
      return graphAcc.concat(
        buildGraphComponent(
          duplicateMetaGraphs,
          classes,
          context,
          graph.duplicateContext,
        ),
      );
    }
    const label =
      graph.dataVisLabels &&
      buildGraphComponent(
        graph.dataVisLabels,
        classes,
        context,
        graph.duplicateContext,
      );

    const labels =
      graph.labels &&
      buildGraphComponent(
        graph.labels,
        classes,
        context,
        graph.duplicateContext,
      );
    return graphAcc.concat(
      <Component
        {...compiledProperties}
        key={`${graph.name}-${compiledProperties.dataKey}`}
        className={classes.roboto}
        {...(labels && { children: labels })}
        {...(label && { children: label })}
      />,
    );
  }, []);
  return components;
}
