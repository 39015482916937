import { Kind, ObjectFieldNode, SelectionSetNode, visit } from 'graphql';
import { DocumentNode, FieldNode } from 'graphql/language/ast';
import { Mutable } from 'utility-types';

import { ClientManager } from '../clientManager';
import {
  INCARNATION,
  SYSTEM_QUERYABLE_FIELDS,
} from '../metadataSupportConstants';
import {
  IVisitorFunctionParams,
  IVisitorFunctionReturn,
} from '../metadataVisitor';
import { IItemType, TypeDefinition } from '../typeDefinition';

import { isInOperation } from './graphQLSupportAst';

// Convert the input argument of the mutation to a SelectionSet, adding an id field for
// any associated entities
export function makeMutationSelectionSet(params: {
  mutation: DocumentNode;
  typeDef: TypeDefinition;
  clientManager: ClientManager;
  // Required only if the object value is not present in the mutation arguments
  obj?: any;
}): SelectionSetNode {
  const { mutation, typeDef, clientManager } = params;

  let hasVariableArgument;
  visit(mutation, {
    Variable: {
      enter(node, key, parent, path, ancestors) {
        // One argument which points to a variable
        if (
          ancestors.length === 7 &&
          (ancestors[6] as any).length === 1 &&
          (parent as any).kind === 'Argument'
        ) {
          hasVariableArgument = true;
        }
      },
    },
  });
  if (hasVariableArgument) {
    return makeSelectionSetFromObject(params as Required<typeof params>);
  }

  let rootSelectionSet: Mutable<SelectionSetNode>;
  const currentSelectionSets: Array<Mutable<SelectionSetNode>> = [];
  visit(mutation, {
    ObjectValue: {
      enter(node, key, parent, path, ancestors) {
        if (!isInOperation(ancestors)) {
          return;
        }
        const selectionSet: SelectionSetNode = {
          kind: Kind.SELECTION_SET,
          selections: [],
        };
        if (!rootSelectionSet) {
          rootSelectionSet = selectionSet;
        } else {
          const ancestorObjectField: ObjectFieldNode =
            (parent as ObjectFieldNode).kind === Kind.OBJECT_FIELD
              ? (parent as ObjectFieldNode)
              : ([...ancestors]
                  .reverse()
                  .find(
                    (a) => (a as ObjectFieldNode).kind === Kind.OBJECT_FIELD,
                  ) as ObjectFieldNode);
          const parentField: Mutable<FieldNode> = currentSelectionSets[
            currentSelectionSets.length - 1
          ].selections.find(
            (f) =>
              (f as FieldNode).name.value === ancestorObjectField.name.value,
          ) as FieldNode;
          parentField.selectionSet = selectionSet;
        }
        currentSelectionSets.push(selectionSet);
      },
      leave() {
        currentSelectionSets.pop();
      },
    },
    ObjectField: {
      enter(node, key, parent, path, ancestors) {
        const attributePath = [node.name.value];
        for (let a = ancestors.length - 1; a >= 0; a--) {
          if ((ancestors[a] as ObjectFieldNode).kind === 'ObjectField') {
            attributePath.push((ancestors[a] as ObjectFieldNode).name.value);
          } else if (
            (ancestors[a] as SelectionSetNode).kind === 'SelectionSet'
          ) {
            break;
          }
        }
        attributePath.reverse();
        if (attributePath.length === 1 && attributePath[0] === INCARNATION) {
          // @ts-ignore - this should be allowed by Mutable<SelectionSetNode> but does not seem to work FIXME
          currentSelectionSets[currentSelectionSets.length - 1].selections.push(
            { kind: 'Field', name: { kind: 'Name', value: INCARNATION } },
          );
        } else {
          const itemType = clientManager.metadataSupport.getItemTypeFromPath({
            typeDef,
            path: attributePath,
          });

          const fieldToAdd = getFieldNodeToAdd(itemType);
          // @ts-ignore - this should be allowed by Mutable<SelectionSetNode> but does not seem to work FIXME
          currentSelectionSets[currentSelectionSets.length - 1].selections.push(
            fieldToAdd,
          );
        }
      },
    },
  });
  return rootSelectionSet;
}

export function convertAssocEntitiesToIds(params: {
  selectionSet: SelectionSetNode;
  typeDef: TypeDefinition;
  clientManager: ClientManager;
}): SelectionSetNode {
  const { selectionSet, typeDef, clientManager } = params;

  const retSelectionSet = visit(selectionSet, {
    Field: {
      leave(node, key, parent, path, ancestors) {
        const attributePath = [node.name.value];
        for (let a = ancestors.length - 1; a >= 0; a--) {
          if ((ancestors[a] as FieldNode).kind === 'Field') {
            attributePath.push((ancestors[a] as ObjectFieldNode).name.value);
          }
        }
        attributePath.reverse();
        const itemType = clientManager.metadataSupport.getItemTypeFromPath({
          typeDef,
          path: attributePath,
          noThrow: true,
        });
        if (itemType && itemType.itemInfo?.associatedEntity) {
          return {
            ...node,
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          };
        }
      },
    },
  });
  return retSelectionSet;
}

export function makeSelectionSetFromObject(params: {
  obj: any;
  typeDef: TypeDefinition;
  clientManager: ClientManager;
}): SelectionSetNode {
  const { obj, typeDef, clientManager } = params;
  const { metadataSupport } = clientManager;

  let rootSelectionSet: Mutable<SelectionSetNode>;
  const currentSelectionSets: Array<Mutable<SelectionSetNode>> = [];

  const makeSelectionSetVisitor = (
    paramsMsv: IVisitorFunctionParams,
  ): IVisitorFunctionReturn => {
    const {
      enclosingObject,
      enclosingTypeDef,
      objectField,
      attr,
      index,
      finalAttributeInParentAttribute,
    } = paramsMsv;

    if (index >= 0) {
      if (index > 0) {
        return { ignoreChildren: true };
      }
      return;
    }
    if (
      objectField === undefined ||
      (typeof objectField === 'object' && Array.isArray(enclosingObject))
    ) {
      return;
    }

    let selectionSet: SelectionSetNode;
    if (
      !rootSelectionSet ||
      (attr &&
        metadataSupport.isObjectType({
          itemInfo: attr.itemInfo,
          parentType: enclosingTypeDef,
        }))
    ) {
      selectionSet = {
        kind: Kind.SELECTION_SET,
        selections: [],
      };
      if (!rootSelectionSet) {
        rootSelectionSet = selectionSet;
      }
    }

    if (attr) {
      const fieldToAdd: Mutable<FieldNode> = getFieldNodeToAdd(attr);
      // @ts-ignore - this should be allowed by Mutable<SelectionSetNode> but does not seem to work FIXME
      currentSelectionSets[currentSelectionSets.length - 1].selections.push(
        fieldToAdd,
      );
      if (selectionSet) {
        fieldToAdd.selectionSet = selectionSet;
      }
    }

    if (attr && finalAttributeInParentAttribute) {
      currentSelectionSets.pop();
    }

    if (selectionSet) {
      currentSelectionSets.push(selectionSet);
    }
  };

  metadataSupport.metadataVisitor.visitObjectFieldsWithTypeDef({
    obj,
    typeDefName: typeDef.id,
    parentType: typeDef,
    visitorFunctions: [makeSelectionSetVisitor],
  });

  if (rootSelectionSet) {
    if (Array.isArray(obj) && obj.length === 0) {
      return;
    }

    // Assume the first record of the array is representative
    const checkObj = Array.isArray(obj) ? obj[0] : obj;
    const fieldsToAdd: FieldNode[] = [];
    for (const key of Object.keys(SYSTEM_QUERYABLE_FIELDS)) {
      if (checkObj[key] !== undefined) {
        fieldsToAdd.push({
          kind: Kind.FIELD,
          name: { kind: Kind.NAME, value: key },
        });
      }
    }
    if (fieldsToAdd.length > 0) {
      rootSelectionSet = {
        kind: Kind.SELECTION_SET,
        selections: [
          ...(rootSelectionSet.selections as FieldNode[]),
          ...fieldsToAdd,
        ],
      };
    }
  }
  return rootSelectionSet;
}

export function objectHasAllSelectionSetFields(
  selectionSet: SelectionSetNode,
  obj: any,
): boolean {
  const foundFields: { [fieldName: string]: boolean } = {};
  for (const key of Object.keys(obj)) {
    foundFields[key] = true;
  }
  let childHasAll = true;
  let foundCount = 0;
  for (const field of selectionSet.selections as FieldNode[]) {
    if (foundFields[field.name.value]) {
      foundCount++;
    }
    if (field.selectionSet) {
      const childObj = obj[field.name.value];
      if (childObj) {
        childHasAll = objectHasAllSelectionSetFields(
          field.selectionSet,
          childObj,
        );
      } else {
        return false;
      }
    }
  }
  return childHasAll && foundCount === selectionSet.selections.length;
}

export function mergeSelectionSets(params: {
  clientManager: ClientManager;
  selectionSet1: SelectionSetNode;
  selectionSet2: SelectionSetNode;
}): SelectionSetNode {
  const { clientManager, selectionSet1, selectionSet2 } = params;
  const selectionsMap: { [key: string]: FieldNode } = {};

  const mergeSelections = (selectionSet: SelectionSetNode) => {
    for (const field of selectionSet.selections as Array<FieldNode>) {
      if (field.kind !== 'Field') {
        throw new Error(
          `SelectionSets with non-Field members not supported, found: ${field.kind}`,
        );
      }
      const { value: fieldName } = field.name;
      if (!selectionsMap[fieldName]) {
        selectionsMap[fieldName] = field;
        continue;
      }

      if (selectionsMap[fieldName].selectionSet && field.selectionSet) {
        selectionsMap[fieldName] = {
          kind: Kind.FIELD,
          name: { kind: Kind.NAME, value: fieldName },
          selectionSet: mergeSelectionSets({
            clientManager,
            selectionSet1: selectionsMap[fieldName].selectionSet,
            selectionSet2: field.selectionSet,
          }),
        };
      } else if (
        !selectionsMap[fieldName].selectionSet &&
        !field.selectionSet
      ) {
        // This is OK, the only thing we care about from the FieldNode is the name and selectionSet
      } else if (!selectionsMap[fieldName].selectionSet && field.selectionSet) {
        selectionsMap[fieldName] = {
          kind: Kind.FIELD,
          name: { kind: Kind.NAME, value: fieldName },
          selectionSet: field.selectionSet,
        };
      }
    }
  };

  mergeSelections(selectionSet1);
  mergeSelections(selectionSet2);
  return { kind: Kind.SELECTION_SET, selections: Object.values(selectionsMap) };
}

const getFieldNodeToAdd = (attr?: IItemType, name?: string): FieldNode => {
  if (attr) {
    ({ name } = attr);
  }

  let newFieldSelectionSet;
  if (attr?.itemInfo.associatedEntity) {
    newFieldSelectionSet = {
      kind: Kind.SELECTION_SET,
      selections: [
        {
          kind: Kind.FIELD,
          name: { kind: Kind.NAME, value: 'id' },
        },
      ],
    };
  }

  const newField: FieldNode = {
    kind: Kind.FIELD,
    name: { kind: Kind.NAME, value: name },
  };
  return newFieldSelectionSet
    ? { ...newField, selectionSet: newFieldSelectionSet }
    : newField;
};
