import { ApolloCache, FieldPolicy, InMemoryCache } from '@apollo/client/cache';
import { DocumentNode, Kind, SelectionSetNode } from 'graphql';
import {
  FieldNode,
  SelectionNode,
  StringValueNode,
} from 'graphql/language/ast';

import { ClientManager } from '../clientManager';
import { exists } from '../common/commonUtilities';
import { reThrow } from '../errors/errorLog';
import { getLogger, Loggers, LogLevels } from '../loggerSupport';
import { MetadataSupport } from '../metadataSupport';
import {
  ACTION_CREATED,
  ACTION_DELETED,
  ACTION_UPDATED,
  CHUNK_ID,
  CREATION_TIME,
  DEACTIVATION_DATE,
  DIRECTIVE_ACTIVEASOF,
  DIRECTIVE_FILTER,
  IEntityType,
  IHasId,
  IHasIdAndTypeName,
  INCARNATION,
  ITEMS,
  NEXT_TOKEN,
  QueryType,
  TYPE_NAME,
} from '../metadataSupportConstants';
import {
  IVisitorFunctionParams,
  IVisitorFunctionReturn,
} from '../metadataVisitor';
import {
  FragmentType,
  IMetadataRequest,
  MetadataParentType,
  SchemaManager,
} from '../schemaManager';
import { getChunkId } from '../sizeClass';
import { IItemInfo, TypeDefinition } from '../typeDefinition';
import {
  getLocalIsoTimestamp,
  manglePreservingUniqueness,
  sleep,
} from '../utilityFunctions';

import _ from 'lodash';
import { readQueryRoot, ROOT_QUERY } from './graphQLExecutor';
import { INetworkMessage, NetworkProcessor } from './graphQLNetworkProcessor';
import {
  executeFilters,
  getActiveAsOfInfo,
  IActiveAsOfInfo,
} from './graphQLSupport';
import {
  addMissingFields,
  getFragmentNameFromEntityId,
  makeFragmentFromSelectionSet,
  makeGraphqlFieldList,
  printGraphQLDocument,
} from './graphQLSupportAst';
import {
  convertAssocEntitiesToIds,
  makeSelectionSetFromObject,
  mergeSelectionSets,
} from './graphQLSupportSelectionSets';
import { LoadIntoMemorySupport } from './loadIntoMemorySupport';
import { IQueryConnectionResult, PipelineManager } from './pipelineManager';
import { StageImpl } from './stageImpl';
import { StageQuery } from './stageQuery';
import { isListQuery } from './stageTypes/graphQLQuery';

const logger = getLogger({
  name: Loggers.PIPELINE_MANAGER,
});
const logBatching = getLogger({
  name: Loggers.PIPELINE_MANAGER_BATCHING,
  level: LogLevels.Warn,
});

// Number of ms to wait for network messages before processing them, so that fewer
// callbacks are made when there are a lot of network updates
export const NETWORK_BATCH_MS = 1000;

export const ITEM_MAP = 'itemMap';
export const DELETED_ITEMS = 'deletedItems';

type ItemMapType = { [id: string]: { __ref: string } };

// The internal cache storage format uses a map instead of a list of items for performance
// reasons on large queries
export interface IListQueryCacheEntry
  extends Omit<IQueryConnectionResult, 'nextToken'> {
  [ITEM_MAP]: ItemMapType;
  [DELETED_ITEMS]?: [{ __ref: string }];
}

export enum ConvertType {
  ID_TO_OBJECT_WITH_ONLY_ID = 'ID_TO_OBJECT_WITH_ONLY_ID',
  OBJECT_TO_ID = 'OBJECT_TO_ID',
}

class GraphQLCounters {
  requests: Partial<Record<QueryType, { started; completed }>> = {};
  subscriptionNotificationsReceived = 0;
  subscriptionNotificationsProcesses = 0;
  suppressLocalUpdateIgnoredQueries = 0;
  cacheHits = 0;
  cacheMisses = 0;
  cacheUpdates = 0;
  cacheDeletes = 0;

  public toString() {
    const output = [];
    Object.keys(this.requests).forEach((k) =>
      output.push(
        `${k} req: ${this.requests[k].started}/${this.requests[k].completed}`,
      ),
    );
    output.push(
      `SubNotifs rec/pro: ${this.subscriptionNotificationsReceived}/${this.subscriptionNotificationsProcesses}`,
    );
    output.push(
      `SupLocalIgnoredQueries:: ${this.suppressLocalUpdateIgnoredQueries}`,
    );
    output.push(
      `Cache hit/upd/del/mis: ${this.cacheHits}/${this.cacheUpdates}/${this.cacheDeletes}/${this.cacheMisses}`,
    );
    return output.join(', ');
  }
}

interface IActiveQuery {
  queryInfo: StageQuery;
}

interface IQueryKeysMapEntry {
  // The entire key value, the key to this map entry
  queryKey: string;
  args: { [key: string]: string };
  argsToCheck: string[];
  activeAsOfInfo: IActiveAsOfInfo;
  filters: string[];
  entityId: string;
  listQuery: boolean;
  queryField: FieldNode;
  // The selection set for the record, for a list query, this is the selection within the 'items' field
  querySelectionSet: SelectionSetNode;
}

interface IQueryKeysMap {
  // The queryKay is the <fieldName>:<arguments>, just as stored in the cache
  [queryKey: string]: IQueryKeysMapEntry;
}

export class GraphQLManager {
  private readonly clientManager: ClientManager;
  private readonly pipelineManager: PipelineManager;
  private readonly schemaManager: SchemaManager;
  private readonly metadataSupport: MetadataSupport;
  public loadIntoMemorySupport: LoadIntoMemorySupport;

  public suppressCallbacks = 0;

  private pendingCallbacks: any;

  private messageQueue: any[] = [];
  private timerRunning: boolean;

  private counters: { [entityId: string]: GraphQLCounters } = {};

  // For all query keys currently in the cache, even if the query is not active
  private queryKeysMap: IQueryKeysMap = {};

  private activeNetworkProcessors: {
    [entityId: string]: NetworkProcessor;
  } = {};

  private activeQueries: {
    [queryId: string]: IActiveQuery;
  } = {};

  constructor(pipelineManager: PipelineManager) {
    this.pipelineManager = pipelineManager;
    this.clientManager = pipelineManager.clientManager;
    this.schemaManager = pipelineManager.schemaManager;
    this.metadataSupport = pipelineManager.metadataSupport;
    this.loadIntoMemorySupport = new LoadIntoMemorySupport(pipelineManager);
  }

  public getCounters(entityId: string) {
    if (!this.counters[entityId]) {
      this.counters[entityId] = new GraphQLCounters();
    }
    return this.counters[entityId];
  }

  public allCountersString(): string {
    const output = [];
    Object.keys(this.counters)
      .sort()
      .forEach((k) => output.push(`${k} - ${this.counters[k].toString()}`));
    if (Object.keys(this.activeNetworkProcessors).length > 0) {
      output.push('Active network processors');
      Object.keys(this.activeNetworkProcessors).forEach((k) =>
        output.push(`${k}`),
      );
    }
    if (Object.keys(this.activeQueries).length > 0) {
      output.push('Active queries');
      Object.keys(this.activeQueries).forEach((k) =>
        output.push(
          `${k} - ${this.activeQueries[k].queryInfo.stage.graphQLDocumentText}`,
        ),
      );
    }
    return output.join('\n');
  }

  public evictFromCache(objs: { id: string }[], broadcast = true) {
    const { cache } = this.pipelineManager.apolloClient;
    objs.forEach((obj) => {
      if (!obj[TYPE_NAME] || !obj.id) {
        throw new Error('Cannot compute cache key');
      }
      cache.evict({ id: `${obj[TYPE_NAME]}:${obj.id}`, broadcast: false });
    });
    if (broadcast) {
      cache.gc();
      // @ts-ignore
      cache.broadcastWatches();
    }
  }

  public broadcastCacheWatches() {
    const { cache } = this.pipelineManager.apolloClient;
    cache.gc();
    // @ts-ignore
    cache.broadcastWatches();
  }

  public hookupNetworkProcessor(entityType: IEntityType) {
    let processor = this.activeNetworkProcessors[entityType.id];
    if (!processor) {
      processor = new NetworkProcessor(this.pipelineManager, entityType);
      processor.subscribe();
      this.activeNetworkProcessors[entityType.id] = processor;
      return;
    }
  }

  public async resetAllNetworkProcessors(clearStore?: boolean) {
    logger.debug('Resetting all network processors');

    this.activeNetworkProcessors = {};
    this.queryKeysMap = {};

    while (true) {
      try {
        if (clearStore) {
          await this.clientManager.apolloClient.clearStore();
        } else {
          // This will cause any open queries to be redriven
          await this.clientManager.apolloClient.resetStore();
        }
        return;
      } catch (error) {
        reThrow({
          logger,
          error,
          message: 'Problem during reset - retrying',
          noThrow: true,
        });
        await sleep(500);
      }
    }
    logger.debug('Resetting all network processors/reset store - done');
  }

  public async networkSubscriptionError() {
    await this.resetAllNetworkProcessors();
  }

  public makeQueryKeys(params: {
    args: { [key: string]: string };
    field: FieldNode;
    typeDef: TypeDefinition;
  }): string {
    const { args, field, typeDef } = params;

    const values = [];
    const filterStrings = [];

    if (field?.directives) {
      for (const directive of field.directives) {
        if (directive.name.value === DIRECTIVE_FILTER) {
          const filterString = (directive.arguments[0].value as StringValueNode)
            .value;
          filterStrings.push(filterString);
          values.push(manglePreservingUniqueness(filterString));
        } else if (directive.name.value === DIRECTIVE_ACTIVEASOF) {
          const activeAsOfString =
            directive.arguments.length > 0
              ? (directive.arguments[0].value as StringValueNode).value
              : 'activeAsOfNoValue';
          values.push(manglePreservingUniqueness(activeAsOfString));
        }
      }
    }
    const activeAsOfInfo = getActiveAsOfInfo(field, getLocalIsoTimestamp());

    let argsToCheck;
    if (args) {
      args[CHUNK_ID] ? parseInt(args[CHUNK_ID], 10) : undefined;
      argsToCheck = [];
      Object.keys(args)
        .filter((k) => k !== NEXT_TOKEN)
        .forEach((k) => argsToCheck.push(k));
      argsToCheck.forEach((k) => {
        values.push(k);
        values.push(args[k]);
      });
    }
    const queryKeysString = values.join('_');
    const queryKey = queryKeysString
      ? `${field.name.value}:${queryKeysString}`
      : field.name.value;

    // Multiple queries with the same query key and different selection sets are possible.
    // We need the union of all of those to be able to read and update the query
    let fieldToUse = field;
    if (this.queryKeysMap[queryKey]) {
      fieldToUse = {
        ...field,
        selectionSet: mergeSelectionSets({
          clientManager: this.clientManager,
          selectionSet1: this.queryKeysMap[queryKey].queryField.selectionSet,
          selectionSet2: field.selectionSet,
        }),
      };
    }

    const listQuery = isListQuery(field.name.value);
    const selectionSet = listQuery
      ? (
          fieldToUse.selectionSet.selections.find(
            (s) => (s as FieldNode).name.value === ITEMS,
          ) as FieldNode
        )?.selectionSet
      : fieldToUse.selectionSet;
    this.queryKeysMap[queryKey] = {
      queryKey,
      args,
      argsToCheck,
      entityId: this.metadataSupport.getEntityIdFromFieldName(field.name.value),
      activeAsOfInfo,
      filters: filterStrings,
      listQuery,
      querySelectionSet: convertAssocEntitiesToIds({
        selectionSet,
        typeDef,
        clientManager: this.clientManager,
      }),
      queryField: fieldToUse,
    };
    return queryKeysString;
  }

  public getQueryKeyMapEntry(keys: string): IQueryKeysMapEntry {
    return this.queryKeysMap[keys];
  }

  public getActiveQuery(queryId: number) {
    return this.activeQueries[queryId.toString()];
  }

  public removeActiveQuery(queryId: number) {
    if (exists(queryId)) {
      delete this.activeQueries[queryId.toString()];
    }
  }

  public addActiveQuery(stage: StageImpl) {
    this.activeQueries[stage.stageQuery.queryId.toString()] = {
      queryInfo: stage.stageQuery,
    };
  }

  public addCallback(id: any, callback) {
    this.pendingCallbacks[id] = callback;
  }

  public startSuppressCallbacks() {
    if (this.suppressCallbacks === 0) {
      this.pendingCallbacks = {};
    }

    this.suppressCallbacks++;
    logBatching.debug(
      `startSuppressCallbacks - setting suppressCallbacks: ${this.suppressCallbacks}`,
    );
  }

  public endSuppressCallbacks() {
    this.suppressCallbacks--;
    logBatching.debug(
      `endSuppressCallbacks - setting suppressCallbacks: ${this.suppressCallbacks}`,
    );
    if (this.suppressCallbacks === 0) {
      logBatching.debug('endSuppressCallbacks - executing pending callbacks');
      Object.keys(this.pendingCallbacks).forEach((k) =>
        this.pendingCallbacks[k](),
      );
      this.pendingCallbacks = {};
      logBatching.debug(
        'endSuppressCallbacks - executing pending callbacks - DONE',
      );
    }
  }

  public queueMessage(message: INetworkMessage) {
    logBatching.debug(
      `Queue network message: ${message.queryFieldName} ${
        message.data[message.queryFieldName]
          ? message.data[message.queryFieldName].id
          : ''
      }`,
    );
    if (!this.timerRunning) {
      logBatching.debug('setting timeout');
      this.timerRunning = true;
      setTimeout(() => {
        logBatching.debug(
          `timeout expired - processing ${this.messageQueue.length} messages`,
        );
        this.startSuppressCallbacks();
        for (const m of this.messageQueue) {
          this.processNetworkMessage(m);
        }
        logBatching.debug(
          `timeout expired - processing ${this.messageQueue.length} messages - DONE`,
        );
        this.messageQueue = [];
        this.timerRunning = false;
        this.endSuppressCallbacks();
      }, NETWORK_BATCH_MS);
    }
    this.messageQueue.push(message);
    logBatching.debug(`pushed - queue length: ${this.messageQueue.length}`);
  }

  private processNetworkMessage(message: INetworkMessage) {
    const {
      data,
      queryFieldName,
      entityType,
      objectToWrite,
      requestingClientId,
    } = message;
    logBatching.debug(
      { data },
      ` processNetworkMessage start ${entityType.id} ${queryFieldName} ${
        data[queryFieldName] ? data[queryFieldName].id : ''
      } requestingClientId: ${requestingClientId}`,
    );

    const counters = this.getCounters(entityType.unqualifiedId);
    counters.subscriptionNotificationsProcesses++;
    const typeDef = message.entityType.typeDefinitionObject;

    // This will cause the Apollo client to notify the affected queries
    switch (message.action) {
      case ACTION_CREATED:
      case ACTION_UPDATED: {
        if (exists(entityType.sizeClass)) {
          objectToWrite[CHUNK_ID] = getChunkId({
            id: objectToWrite.id,
            sizeClass: entityType.sizeClass,
          });
        }
        const selectionSet = makeSelectionSetFromObject({
          obj: objectToWrite,
          typeDef,
          clientManager: this.pipelineManager.clientManager,
        });

        this.updateQueriesInCache({
          incoming: [objectToWrite],
          cache: this.pipelineManager.apolloClient.cache as InMemoryCache,
          inputRecordSelectionSet: selectionSet,
          entityType,
          fromNetwork: true,
        });
        break;
      }
      case ACTION_DELETED:
        this.pipelineManager.graphQLManager.evictFromCache([objectToWrite]);
        break;
      default:
        throw new Error(`Unknown action ${message.action}`);
    }
    logBatching.debug('processNetworkMessage end');
  }

  public getInlineCompositeType(
    itemInfo: IItemInfo,
    parentType: MetadataParentType,
  ): TypeDefinition {
    if (itemInfo.associatedEntity) {
      return null;
    }
    const typeDef = this.schemaManager.getTypeDefinition({
      name: itemInfo.type,
      parentRecord: parentType,
      noThrow: true,
    });

    if (!typeDef || typeDef.isEnum) {
      return null;
    }
    return typeDef;
  }

  public makeGraphqlFieldListFromName(
    params: IMetadataRequest & { deep?: boolean },
  ): string {
    const typeDef = this.schemaManager.getTypeDefinition(params);

    return this.makeGraphqlFieldList({
      typeDef,
      fragmentType: params.deep
        ? FragmentType.DEEP
        : FragmentType.SHALLOW_PLUS_ID,
    });
  }

  // REMOVEME - replace by getRecord - used only in the client
  public buildGetQuery(entityName, entityId) {
    const fieldName = `get${entityName}`;
    const schemaInfo = this.schemaManager.getSchemaInfo();
    const configName = schemaInfo.entityIdToConfigName[entityName];
    const typeDef = this.schemaManager.getTypeDefinition({
      name: entityName,
      configName,
    });

    const queryFields = this.makeGraphqlFieldList({
      typeDef,
      fragmentType: FragmentType.SHALLOW_PLUS_ID,
    });

    const query = `
    query {
      ${fieldName}(id: "${entityId}") {
        ${queryFields}
      }
    }
    `;

    return query;
  }

  // REMOVEME - replace by listRecords - used only in the client
  public buildListQuery(entityName: string, attributes?: string[]) {
    if (!Array.isArray(attributes)) {
      throw new Error(
        'buildListQuery must receive an array of attributes to query',
      );
    }

    const fieldName = `list${entityName}`;

    const typeDef =
      this.metadataSupport.getTypeDefFromUnqualifiedEntityName(entityName);

    const normalizedAttributes: any = typeDef
      .getAttributes()
      .reduce((acc, attribute) => {
        return Object.assign(acc, { [attribute.name]: attribute });
      }, {});

    const queryAttributes = attributes.map((attribute) => {
      if (normalizedAttributes[attribute].itemInfo.associatedEntity) {
        return `${attribute} {
          id
        }`;
      }
      return attribute;
    });

    const query = `
    query {
      ${fieldName} {
        items {
          ${queryAttributes.join(' ')}
        }
      }
    }
    `;

    return query;
  }

  public makeGraphqlFieldList(params: {
    typeDef: TypeDefinition;
    fragmentType: FragmentType;
    associatedEntities?: string[];
    selectionSet?: SelectionSetNode;
    schemaManager?: SchemaManager;
    metadataSupport?: MetadataSupport;
  }): string {
    return makeGraphqlFieldList({
      ...params,
      schemaManager: this.schemaManager,
      metadataSupport: this.metadataSupport,
    });
  }

  public readIncarnation(
    unqualifiedTypeName: string,
    entityId: string,
    id: string,
  ): number | undefined {
    const cache = this.pipelineManager.apolloClient.cache;
    const data = cache.readFragment({
      id: `${unqualifiedTypeName}:${id}`,
      fragment: this.pipelineManager.clientManager.schemaManager.getFragment(
        entityId,
        FragmentType.ONLY_INCARNATION,
      ),
    });
    if (Number.isInteger(data?.[INCARNATION])) {
      return data[INCARNATION];
    }
    return undefined;
  }

  resolveAssocEntityVisitor(
    params: IVisitorFunctionParams,
  ): IVisitorFunctionReturn {
    const { objectField, attr, context, objectFieldTypeDef, metadataSupport } =
      params;

    if (!exists(objectField) || !attr?.itemInfo.associatedEntity) {
      return;
    }

    const data = context.cache.readFragment({
      id: `${objectFieldTypeDef.getUnqualifiedId()}:${objectField.id}`,
      fragment: metadataSupport.schemaManager.getFragment(
        attr.itemInfo.associatedEntity,
        FragmentType.DEEP,
      ),
      fragmentName: getFragmentNameFromEntityId(
        attr.itemInfo.associatedEntity,
        FragmentType.DEEP,
      ),
      returnPartialData: true,
    });

    return { newValue: data };
  }

  private isMemberOfQuery(params: {
    obj: any;
    keys: IQueryKeysMapEntry;
    cache: ApolloCache<any>;
    entityType: IEntityType;
  }): boolean {
    const { obj, keys, cache, entityType } = params;
    const { args, argsToCheck, filters } = keys;

    if (argsToCheck) {
      const matched = argsToCheck.filter((arg) => {
        const valueToCheck =
          typeof obj[arg] === 'object' && obj[arg] !== null
            ? obj[arg].id
            : obj[arg];
        return args[arg] === valueToCheck;
      });
      if (matched.length !== argsToCheck.length) {
        return false;
      }
    }

    if (filters.length > 0) {
      this.clientManager.metadataVisitor.visitObjectFieldsWithTypeDef({
        obj,
        context: { cache },
        typeDefName: entityType.typeDefinition,
        parentType: entityType,
        visitorFunctions: [this.resolveAssocEntityVisitor],
      });

      return executeFilters({
        item: obj,
        clientManager: this.clientManager,
        logger,
        filters: filters,
      });
    }

    return this.isActiveForQuery(obj, keys);
  }

  private isActiveForQuery(obj: any, keys: IQueryKeysMapEntry): boolean {
    const { activeAsOfInfo } = keys;
    if (!activeAsOfInfo.ignoreDeactivationDate) {
      const ok =
        (!obj[DEACTIVATION_DATE] ||
          obj[DEACTIVATION_DATE] > activeAsOfInfo.activeAsOfStartDate) &&
        (!obj[CREATION_TIME] ||
          obj[CREATION_TIME] <= activeAsOfInfo.activeAsOfEndDate ||
          obj[CREATION_TIME].startsWith(activeAsOfInfo.activeAsOfEndDate));
      if (!ok) {
        return false;
      }
    }
    return true;
  }

  public updateQueriesInCache(params: {
    incoming: (IHasIdAndTypeName & { __ref?: string })[];
    inputRecordSelectionSet: SelectionSetNode;
    entityType: IEntityType;
    cache: ApolloCache<any>;
    optimistic?: boolean;
    suppressLocalNotify?: boolean;
    fromNetwork?: boolean;
  }) {
    const {
      incoming,
      cache,
      inputRecordSelectionSet,
      entityType,
      optimistic,
      suppressLocalNotify,
      fromNetwork,
    } = params;
    const { clientManager } = this.pipelineManager;

    const entityId = entityType.unqualifiedId;
    const unqualifiedType = MetadataSupport.getUnqualifiedName(
      entityType.typeDefinition,
    );

    const activeQueries: {
      [queryKey: string]: {
        keyMapEntry: IQueryKeysMapEntry;
        objectsToUpdate: { [objId: string]: any };
      };
    } = {};

    const cacheData = cache.extract(optimistic);
    if (cacheData[ROOT_QUERY]) {
      for (const queryKey of Object.keys(cacheData?.[ROOT_QUERY])) {
        if (queryKey === TYPE_NAME) {
          continue;
        }
        const keys = this.getQueryKeyMapEntry(queryKey);
        if (!keys) {
          throw new Error(
            `Can't find query keys for ${queryKey} - this is bad`,
          );
        }
        activeQueries[queryKey] = { keyMapEntry: keys, objectsToUpdate: {} };
      }
    }

    const counters =
      clientManager.pipelineManager.graphQLManager.getCounters(entityId);
    counters.cacheUpdates++;

    // Get all the data available for the incoming objects so we can match them
    // with a query
    const hydratedIncoming: {
      [id: string]: { obj: IHasId; found?: boolean };
    } = {};
    const hydrateFragment = clientManager.schemaManager.getFragment(
      entityId,
      FragmentType.SHALLOW_PLUS_ID,
    );
    // Have to get everything we know about the objects since the mutation might not
    // specify the arguments needed to match the query
    for (const incomingObj of incoming) {
      const id = incomingObj.__ref || `${unqualifiedType}:${incomingObj.id}`;
      const objFromCache: IHasId = cache.readFragment(
        {
          id,
          fragment: hydrateFragment,
          fragmentName: getFragmentNameFromEntityId(
            entityId,
            FragmentType.SHALLOW_PLUS_ID,
          ),
          returnPartialData: true,
        },
        optimistic,
      );

      if (fromNetwork && objFromCache) {
        // Make sure we don't overwrite objects in the cache with stale database values
        // because of network updates triggered by a mutation from this process, also due to Dynamodb
        // read consistency issues
        const objectFromCacheIncarnation = objFromCache[INCARNATION];
        const objectToUpdateIncarnation = incomingObj[INCARNATION];
        if (objectFromCacheIncarnation >= objectToUpdateIncarnation) {
          logger.debug(
            `Ignoring object ${id} existing inc: ${objectFromCacheIncarnation} new inc: ${objectToUpdateIncarnation}`,
          );
          continue;
        }
      }

      hydratedIncoming[incomingObj.id] = {
        obj: { ...objFromCache, ...incomingObj },
      };
    }

    if (Object.keys(hydratedIncoming).length === 0) {
      return;
    }

    for (const activeQuery of Object.values(activeQueries)) {
      const { objectsToUpdate, keyMapEntry } = activeQuery;

      // Check for adding the incoming objects to the query
      let activeQueryMatch = false;
      for (const hRec of Object.values(hydratedIncoming)) {
        const obj = hRec.obj;
        if (keyMapEntry.entityId === entityId) {
          // the record might not be a member of the query if it's being removed, so we still
          // need to update the query
          if (
            this.isMemberOfQuery({
              obj,
              cache,
              keys: keyMapEntry,
              entityType,
            })
          ) {
            activeQueryMatch = true;
            objectsToUpdate[obj.id] = obj;
            hRec.found = true;
          }
        }
      }

      const { queryKey, queryField, querySelectionSet, listQuery } =
        keyMapEntry;
      const queryFieldName = queryField.name.value;

      const readQuery = this.makeCacheQuery({
        field: queryField,
        isThisListQuery: listQuery,
      });
      const { itemsObj } = readQueryRoot({
        cache,
        id: ROOT_QUERY,
        query: readQuery,
        fieldName: queryFieldName,
        returnPartialData: true,
        optimistic,
        listQuery,
      });

      const deletedItems = [];

      // Find the incoming items that are no longer in the query
      Object.values(itemsObj)
        .filter(
          (i) =>
            hydratedIncoming[i.id]?.obj &&
            !this.isMemberOfQuery({
              obj: hydratedIncoming[i.id]?.obj,
              cache,
              keys: keyMapEntry,
              entityType,
            }),
        )
        .forEach((i) => {
          activeQueryMatch = true;
          // Have to provide the full object to avoid warnings about missing fields
          deletedItems.push({ __ref: `${unqualifiedType}:${i.id}` });
          // We don't mark it found, because we want to update the object in the cache
          // for a future query even if it does not belong here.
        });

      if (!activeQueryMatch) {
        continue;
      }

      logger.debug(
        `updateQueriesInCache, writing ${
          Object.keys(objectsToUpdate).length
        } updated records for ${queryKey}, opt: ${optimistic} selSet: ${printGraphQLDocument(
          inputRecordSelectionSet,
        ).replace(/\s+/g, ' ')}`,
      );

      const writeSelectionSet = mergeSelectionSets({
        clientManager: this.clientManager,
        selectionSet1: inputRecordSelectionSet,
        selectionSet2: querySelectionSet,
      });
      const writeField: FieldNode = listQuery
        ? {
            ...queryField,
            selectionSet: {
              kind: Kind.SELECTION_SET,
              selections: queryField.selectionSet.selections.map((sel) => {
                if ((sel as FieldNode).name.value === ITEMS) {
                  return {
                    ...sel,
                    selectionSet: writeSelectionSet,
                  };
                } else {
                  return sel;
                }
              }),
            },
          }
        : {
            ...queryField,
            selectionSet: writeSelectionSet,
          };

      const objectsToUpdateReady = [];
      Object.values(objectsToUpdate).forEach((obj) => {
        // Since the objects to update also include what was read from the cache,
        // anything undefined at this point should be set to null to keep the cache
        // happy
        const objToUpdate = _.cloneDeep(obj);
        addMissingFields({
          selectionSet: writeSelectionSet,
          typeDef: entityType.typeDefinitionObject,
          schemaManager: this.schemaManager,
          data: objToUpdate,
        });
        objectsToUpdateReady.push(objToUpdate);
      });

      const writeQuery = this.makeCacheQuery({
        field: writeField,
        isThisListQuery: listQuery,
        writeQuery: true,
      });
      cache.writeQuery({
        id: ROOT_QUERY,
        query: writeQuery,
        data: {
          [queryFieldName]: listQuery
            ? ({
                [ITEMS]: Object.values(objectsToUpdateReady),
                [DELETED_ITEMS]: deletedItems,
              } as IQueryConnectionResult)
            : Object.values(objectsToUpdateReady)[0],
        },
        broadcast: !suppressLocalNotify,
      });
    }

    // Some objects might not be part of any query, nevertheless, they must be updated
    // because something might refer to them
    let writeFragment;
    Object.values(hydratedIncoming)
      .filter((h) => !h.found)
      .forEach((h) => {
        if (!writeFragment) {
          writeFragment = makeFragmentFromSelectionSet({
            selectionSet: inputRecordSelectionSet,
            entityId,
            typeName: unqualifiedType,
          });
        }

        cache.writeFragment({
          id: `${unqualifiedType}:${h.obj.id}`,
          data: h.obj,
          fragment: writeFragment,
        });
      });
  }

  private makeCacheQuery(params: {
    field: FieldNode;
    isThisListQuery: boolean;
    writeQuery?: boolean;
  }): DocumentNode {
    const { isThisListQuery, writeQuery } = params;
    const { field } = params;
    const objectSelectionSet: SelectionSetNode = (isThisListQuery
      ? (
          field.selectionSet.selections.find(
            (f) => (f as FieldNode).name.value === ITEMS,
          ) as FieldNode
        )?.selectionSet
      : field.selectionSet) || { kind: Kind.SELECTION_SET, selections: [] };

    if (
      !objectSelectionSet.selections.find(
        (s) => (s as FieldNode).name?.value === INCARNATION,
      )
    ) {
      const selections: SelectionNode[] = [...objectSelectionSet.selections];
      selections.push({
        kind: Kind.FIELD,
        name: { kind: Kind.NAME, value: INCARNATION },
      });
      objectSelectionSet.selections = selections;
    }

    let outputField;
    if (isThisListQuery) {
      outputField = {
        ...field,
        selectionSet: {
          kind: Kind.SELECTION_SET,
          selections: [
            {
              kind: Kind.FIELD,
              name: { kind: Kind.NAME, value: ITEMS },
              selectionSet: objectSelectionSet,
            },
          ],
        },
        arguments: field?.arguments?.filter((a) => a.name.value !== NEXT_TOKEN),
      };

      if (writeQuery) {
        outputField.selectionSet.selections = [
          ...outputField.selectionSet.selections,
          {
            kind: Kind.FIELD,
            name: { kind: Kind.NAME, value: DELETED_ITEMS },
          } as const,
        ];
      }
    } else {
      outputField = { ...field, selectionSet: objectSelectionSet };
    }

    return {
      kind: Kind.DOCUMENT,
      definitions: [
        {
          kind: Kind.OPERATION_DEFINITION,
          // @ts-ignore - not sure why TS 5.3.3 is giving this error
          operation: 'query',
          selectionSet: {
            kind: Kind.SELECTION_SET,
            selections: [outputField],
          },
        },
      ],
    };
  }

  public static createListFieldPolicy(
    clientManager: ClientManager,
    typeDef: TypeDefinition,
  ): FieldPolicy {
    return {
      keyArgs: (args, context) => {
        return clientManager.pipelineManager.graphQLManager.makeQueryKeys({
          args,
          field: context.field,
          typeDef,
        });
      },

      read(existing: IListQueryCacheEntry) {
        const retValue = existing
          ? {
              ...existing,
              [ITEMS]: existing[ITEM_MAP]
                ? Object.values(existing[ITEM_MAP])
                : [],
            }
          : undefined;

        if (retValue) {
          delete retValue[ITEM_MAP];
          if (!retValue[NEXT_TOKEN]) {
            retValue[NEXT_TOKEN] = null;
          }
          logger.debug(`Read: ${retValue[ITEMS].length}`);
        }
        return retValue;
      },

      merge(
        existing: IListQueryCacheEntry,
        incoming: IQueryConnectionResult,
        options,
      ) {
        if (logger.isLevelEnabled(LogLevels.Debug)) {
          logger.info(
            `merge: ${options.fieldName} existing: ${existing?.[ITEM_MAP] ? Object.keys(existing[ITEM_MAP]).length : 0} incoming: ${incoming[ITEMS].length} chunk: ${incoming[CHUNK_ID]} nextToken: ${incoming[NEXT_TOKEN]}`,
          );
        }

        let newItemMap: ItemMapType;
        if (existing?.[ITEM_MAP]) {
          newItemMap = { ...existing[ITEM_MAP] };
          if (incoming[DELETED_ITEMS]?.length > 0) {
            incoming[DELETED_ITEMS].forEach((i) => delete newItemMap[i.__ref]);
          }
        } else {
          newItemMap = {};
        }

        if (incoming[ITEMS]) {
          // IQueryConnectionResult
          incoming[ITEMS].forEach((i) => (newItemMap[i.__ref] = i));
        } else {
          // IListQueryCacheEntry
          Object.values<{ __ref: string }>(incoming[ITEM_MAP]).forEach(
            (value) => (newItemMap[value.__ref] = value),
          );
        }

        const retValue = {
          ...existing,
          // Use the latest for things like NEXT_TOKEN
          ...incoming,
          [ITEM_MAP]: newItemMap,
        } as IListQueryCacheEntry;
        delete retValue[ITEMS];
        // NEXT_TOKEN is also stored in the cache because all subscription notifications
        // use only the data in the cache. the FROM_NETWORK flag is used to indicate
        // the last cache update is actually from the network and the NEXT_TOKEN can be used,
        // otherwise it's to be ignored.
        return retValue;
      },
    };
  }

  public static createGetFieldPolicy(
    clientManager: ClientManager,
    typeDef: TypeDefinition,
  ): FieldPolicy {
    return {
      keyArgs: (args, context) => {
        return clientManager.pipelineManager.graphQLManager.makeQueryKeys({
          args,
          field: context.field,
          typeDef,
        });
      },
    };
  }
}
