import _ from 'lodash';
import safeJsonStringify from 'safe-json-stringify';

import { ClientManager } from './clientManager';
import { exists } from './common/commonUtilities';
import { getErrorString } from './errors/errorString';
import {
  CollectionTypes,
  CONFIG_SEPARATOR,
  IConfigItem,
  IConfigItems,
  IHasConfigName,
  KEY_FIELD,
  QualifiedName,
  TYPE_NAME,
} from './metadataSupportConstants';
import { MetadataVisitor } from './metadataVisitor';
import { ConvertType, GraphQLManager } from './pipeline/graphQLManager';
import { requiresGraphqlQuotes } from './pipeline/graphQLSupport';
import { MetadataParentType, SchemaManager } from './schemaManager';
import { IItemInfo, IItemType, TypeDefinition } from './typeDefinition';
import { isBuiltin } from './types';
import { escapeGraphqlString, stringifyPretty } from './utilityFunctions';

export function findConfigItem<Type extends IConfigItem>(
  configItems: IConfigItems<Type>,
  configItemId: string,
  configName: string,
  noThrowOnError?: boolean,
): Type {
  const qualifiedConfigItemId = MetadataSupport.getQualifiedName(
    configItemId,
    configName,
  );
  const configItem = configItems[qualifiedConfigItemId];
  if (!configItem) {
    if (noThrowOnError) {
      return null;
    }
    throw new Error(`Config item not found for ${qualifiedConfigItemId}`);
  }
  return configItem;
}

export class MetadataSupport {
  public schemaManager: SchemaManager;
  public metadataVisitor: MetadataVisitor;
  public clientManager: ClientManager;
  public graphqlManager: GraphQLManager;
  public cacheConfig;

  public initialize(clientManager: ClientManager) {
    this.schemaManager = clientManager.schemaManager;
    this.metadataVisitor = clientManager.metadataVisitor;
    this.clientManager = clientManager;
  }

  public initializeAfterFullStartup(clientManager: ClientManager) {
    this.graphqlManager = clientManager.pipelineManager.graphQLManager;
  }

  // This is a type that can represent an object defined by a type definition
  // Opaque objects like JSON and Object types are not considered object types for this purpose
  public isObjectType(params: {
    itemInfo?: IItemInfo;
    parentType?: MetadataParentType;
    typeDef?: TypeDefinition;
  }): boolean {
    const { itemInfo } = params;
    let { typeDef, parentType } = params;
    if (!parentType && typeDef) {
      parentType = typeDef;
    }

    if (!itemInfo) {
      // typeDef has to be specified
      return !(isBuiltin(typeDef.id) || typeDef.isEnum);
    }

    if (isBuiltin(itemInfo.type) || itemInfo.associatedEntity) {
      return false;
    }

    typeDef = this.schemaManager.getTypeDefinition({
      name: itemInfo.type,
      parentRecord: parentType,
      noThrow: true,
    });
    return !(typeDef && typeDef.isEnum);
  }

  public enumStringToNumber(typeDef: TypeDefinition, value: string): number {
    const index = typeDef.getAttributes().findIndex((a) => a.name === value);
    if (index === -1) {
      throw new Error(`Enum value ${value} not found in type ${typeDef.id}`);
    }
    return index;
  }

  private handleAttribute(attr: IItemType, value, configName): string {
    if (value === null || value === undefined) {
      return 'null';
    }
    // An accommodation for the client code which databinds to an id which might be
    // null if nothing selected
    if (attr.itemInfo.associatedEntity && value && value.id === null) {
      return null;
    }

    const attrTypeName = attr.itemInfo.type;
    const attrType = this.schemaManager.getTypeDefinition({
      name: attrTypeName,
      configName,
      noThrow: true,
    });

    const inlineType = this.graphqlManager.getInlineCompositeType(
      attr.itemInfo,
      attrType,
    );
    if (
      (attr.itemInfo.collectionType === CollectionTypes.ARRAY ||
        attr.itemInfo.collectionType === CollectionTypes.MAP) &&
      Array.isArray(value)
    ) {
      let stringVal = ' [';
      value.forEach((item) => {
        stringVal += ' ' + this.handleAttribute(attr, item, configName);
      });
      stringVal += '] ';
      return stringVal;
    } else if (inlineType) {
      let stringVal = ' { ';
      stringVal += this.makeGraphqlObjectMutation(
        value,
        inlineType.id,
        configName,
      );
      stringVal += ' } ';
      return stringVal;
    }
    // enums are strings for the database
    else if (
      (attrType && attrType.isEnum) ||
      typeof value === 'number' ||
      typeof value === 'boolean'
    ) {
      if (attrType && attrType.isEnum) {
        if (typeof value !== 'string') {
          throw new Error(`Non-string value for enum: ${attr.name} - ${value}`);
        }
        if (value.includes(' ')) {
          throw new Error(
            `Enum value includes a space: ${attr.name} - ${value}`,
          );
        }
      }
      return value;
    } else if (attr.itemInfo.associatedEntity && typeof value !== 'string') {
      // Get the string value for the associated entity from the object.
      // If it's a string, it will just be the correct value
      // FIXME - assumes all of our entity keys are 'id' which they are
      return `"${value.id}"`;
    }
    if (requiresGraphqlQuotes(attr.itemInfo.type)) {
      return escapeGraphqlString(value);
    }
    return value;
  }

  // Return graphql mutation on the object of the given type
  public makeGraphqlObjectMutation(
    obj: Record<string, any>,
    typeName: string,
    configName?: string,
    fieldsToOmit?: string[],
  ): string {
    try {
      let omitSet = null;
      if (fieldsToOmit) {
        omitSet = new Set(fieldsToOmit);
      }

      let graphQL = '';
      const typeDef = this.schemaManager.getTypeDefinition({
        name: typeName,
        configName,
      });
      for (const attr of typeDef.getAttributes()) {
        if (omitSet && omitSet.has(attr.name)) {
          continue;
        }
        if (!obj.hasOwnProperty(attr.name)) {
          continue;
        }
        graphQL += `${attr.name}: ${this.handleAttribute(
          attr,
          obj[attr.name],
          configName,
        )}\n`;
      }
      return graphQL;
    } catch (error) {
      throw new Error(
        `Error: ${getErrorString(
          error,
        )} creating graphql mutation for ${safeJsonStringify(obj)}`,
      );
    }
  }

  public static getQualifiedName(
    name: string,
    configName: string,
    noThrow = false,
  ): QualifiedName {
    if (!name) {
      return name;
    }
    if (name.includes(CONFIG_SEPARATOR)) {
      return name;
    }
    if (!configName) {
      if (noThrow) {
        return name;
      }
      throw new Error(
        `qualifyName: unqualified name ${name} and no configName specified`,
      );
    }
    return `${configName}${CONFIG_SEPARATOR}${name}`;
  }

  public static getUnqualifiedName(name: string): string {
    if (!exists(name)) {
      throw new Error(`getUnqualifiedName called with name ${name}`);
    }

    if (!name.includes(CONFIG_SEPARATOR)) {
      return name;
    }
    const nameSplit = name.split(CONFIG_SEPARATOR);
    return nameSplit[1];
  }

  public static isQualifiedName(name: string): boolean {
    return name.includes(CONFIG_SEPARATOR);
  }

  public static getConfigName(name: QualifiedName, noThrow?: boolean) {
    if (MetadataSupport.isQualifiedName(name)) {
      return name.split(CONFIG_SEPARATOR)[0];
    }
    if (!noThrow) {
      throw new Error(`getConfigName called on unqualified name: ${name}`);
    }
  }

  public static getQualifiedNameForObject(obj: IHasConfigName): string {
    if (MetadataSupport.isQualifiedName(obj.id)) {
      return obj.id;
    }
    return `${obj.configName}${CONFIG_SEPARATOR}${obj.id}`;
  }

  public getTypeInfoFromEntity({
    entityName,
    configName,
    noThrowTypeDef,
  }: {
    entityName: string;
    configName?: string;
    noThrowTypeDef?: boolean;
  }): { typeName: string; typeDef: TypeDefinition } {
    // We always have to find the entity
    const entity = this.schemaManager.getEntityType({
      name: entityName,
      configName,
    });

    // But the typeDef is optional (in the noThrow case)
    const typeDef = this.schemaManager.getTypeDefinition({
      name: entity.typeDefinition,
      configName: entity.configName,
      noThrow: noThrowTypeDef,
    });

    return {
      typeName: MetadataSupport.getUnqualifiedName(entity.typeDefinition),
      typeDef,
    };
  }

  public getEntityTypeFromUnqualifiedEntityName(
    entityName: string,
    noThrow?: boolean,
  ) {
    const configName = this.getConfigNameFromUnqualifiedEntityName(
      entityName,
      noThrow,
    );
    return this.schemaManager.getEntityType({
      name: entityName,
      configName,
      noThrow,
    });
  }

  public getTypeDefFromUnqualifiedEntityName(
    entityName: string,
    noThrow?: boolean,
  ) {
    const configName = this.getConfigNameFromUnqualifiedEntityName(
      entityName,
      noThrow,
    );
    const entityType = this.schemaManager.getEntityType({
      name: entityName,
      configName,
      noThrow,
    });
    if (!entityType && noThrow) {
      return undefined;
    }
    const typeDef = this.schemaManager.getTypeDefinition({
      name: entityType.typeDefinition,
      configName,
      noThrow,
    });
    return typeDef;
  }

  public getTypeDefFromUnqualifiedName(typeDefName: string, noThrow?: boolean) {
    const configName = this.getConfigNameFromUnqualifiedTypeDefName(
      typeDefName,
      noThrow,
    );
    const typeDef = this.schemaManager.getTypeDefinition({
      name: typeDefName,
      configName,
      noThrow,
    });
    return typeDef;
  }

  public getConfigNameFromUnqualifiedEntityName(
    entityName: string,
    noThrow?: boolean,
  ): string {
    const schemaInfo = this.schemaManager.getSchemaInfo();
    const configName = schemaInfo.entityIdToConfigName[entityName];

    if (!configName && !noThrow) {
      throw new Error(
        `Entity ${entityName} not found in schema info. Maybe you need to re-initialize the schema or re-install metadata?`,
      );
    }

    return configName;
  }

  public getConfigNameFromUnqualifiedTypeDefName(
    typeDefName: string,
    noThrow?: boolean,
  ): string {
    const schemaInfo = this.schemaManager.getSchemaInfo();
    const configName = schemaInfo.typeDefIdToConfigName[typeDefName];

    if (!configName && !noThrow) {
      throw new Error(
        `TypeDef ${typeDefName} not found in schema info. Maybe you need to re-initialize the schema or re-install metadata?`,
      );
    }

    return configName;
  }

  public getEntityIdFromFieldName(graphQLFieldName: string): string {
    return graphQLFieldName.replace(/^list/, '').replace(/^get/, '');
  }

  public getItemTypeFromPath(params: {
    typeDef: TypeDefinition;
    path: string[];
    noThrow?: boolean;
  }): IItemType {
    const { typeDef, path, noThrow } = params;
    const itemType = typeDef.getAttributes().find((a) => a.name === path[0]);
    if (!itemType) {
      if (noThrow) {
        return null;
      }
      throw new Error(`Attribute ${path[0]} not found in ${typeDef.id}`);
    }
    const { itemInfo } = itemType;
    if (
      (itemInfo.associatedEntity || !isBuiltin(itemInfo.type)) &&
      path.length > 1
    ) {
      let childTypeDef;
      if (itemInfo.associatedEntity) {
        childTypeDef = this.getTypeInfoFromEntity({
          entityName: itemInfo.associatedEntity,
          configName: typeDef.configName,
        }).typeDef;
      } else {
        childTypeDef = this.schemaManager.getTypeDefinition({
          name: itemInfo.type,
          parentRecord: typeDef,
        });
      }
      return this.getItemTypeFromPath({
        typeDef: childTypeDef,
        path: path.slice(1),
        noThrow,
      });
    }
    if (path.length !== 1) {
      if (noThrow) {
        return null;
      }
      throw new Error(`Attribute ${path.join('.')} not found in ${typeDef.id}`);
    }
    return itemType;
  }

  public unResolveAssociatedEntities({
    record,
    configName,
  }: {
    record: any;
    configName: string;
  }): void {
    const { [TYPE_NAME]: typeName } = record;

    if (!exists(typeName)) {
      throw new Error(
        `Records passed to unresolveAssociatedEntities must have a type name. ${stringifyPretty(
          record,
        )}`,
      );
    }

    const typeDefinition = this.schemaManager.getTypeDefinition({
      name: typeName,
      configName,
    });

    this.convertObjectReferences({
      obj: record,
      typeDef: typeDefinition,
      convertType: ConvertType.OBJECT_TO_ID,
    });
  }

  // Converts associated object references from a string to an object, or an object
  // to a string, the conversion is in-place
  // FIXME - convert to use metadataVisitor
  public convertObjectReferences(params: {
    obj: any;
    typeDef: TypeDefinition;
    convertType: ConvertType;
  }): void {
    const { obj, typeDef, convertType } = params;

    if (Array.isArray(obj)) {
      obj.forEach((m) =>
        this.convertObjectReferences({ obj: m, typeDef, convertType }),
      );
      return;
    }

    for (const attr of typeDef.getAttributes()) {
      const objectField = obj[attr.name];
      if (objectField === '') {
        obj[attr.name] = null;
        continue;
      }
      if (!objectField) {
        continue;
      }
      if (attr.itemInfo.associatedEntity) {
        const fixAssocReference = (refObj: any): any => {
          const unQualTypeDef = MetadataSupport.getUnqualifiedName(
            this.getTypeInfoFromEntity({
              entityName: MetadataSupport.getQualifiedName(
                attr.itemInfo.associatedEntity,
                typeDef.configName,
              ),
            }).typeName,
          );

          switch (convertType) {
            case ConvertType.OBJECT_TO_ID:
              if (typeof refObj === 'string') {
                return refObj;
              }
              return refObj.id;
            case ConvertType.ID_TO_OBJECT_WITH_ONLY_ID:
              return {
                id: typeof refObj === 'object' ? refObj.id : refObj,
                [TYPE_NAME]: unQualTypeDef,
              };
          }
        };

        if (Array.isArray(objectField)) {
          objectField.forEach((m, index) => {
            // FIXME what about the delete in this case?
            objectField[index] = fixAssocReference(m);
          });
        } else {
          obj[attr.name] = fixAssocReference(objectField);
          if (obj[attr.name] === undefined) {
            delete obj[attr.name];
          }
        }
      } else if (this.isObjectType({ itemInfo: attr.itemInfo, typeDef })) {
        this.convertObjectReferences({
          obj: objectField,
          typeDef: this.schemaManager.getTypeDefinition({
            name: attr.itemInfo.type,
            parentRecord: typeDef,
          }),
          convertType,
        });
      }
    }
    return;
  }
}

export function getRecordCountFromResult(result: any): string {
  let recordCount = 0;

  if (result) {
    if (Array.isArray(result)) {
      recordCount = result.length;
    } else {
      recordCount = 1;
    }
  }
  return `records: ${recordCount}`;
}

export function handleDuplicates(records: any[]): {
  cleanRecords: any[];
  duplicateRecords?: any[];
} {
  const uniqueRecords = _.uniqBy(records, KEY_FIELD);

  if (uniqueRecords.length !== records.length) {
    const counts = _.countBy(records, KEY_FIELD);
    const duplicateIds = Object.keys(counts).filter((id) => counts[id] !== 1);

    const duplicateRecords = duplicateIds.map((id) =>
      records.filter((record) => record[KEY_FIELD] === id),
    );
    records = uniqueRecords;
    return {
      cleanRecords: records,
      duplicateRecords: duplicateRecords,
    };
  } else {
    return {
      cleanRecords: records,
    };
  }
}
