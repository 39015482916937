import { Icon, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';

const styles = () =>
  ({
    wrapper: {
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      textAlign: 'center',
    },

    flex: {
      flex: 1,
    },

    progress: {
      height: '100%',
      align: 'center',
      color: 'red',
      fontSize: 48,
    },
  }) as const;

interface IProps {
  classes: any;
  message: any;
}

function Broken(props: IProps) {
  const { classes, message } = props;
  let renderMessage = message;
  if (typeof message === 'object') {
    renderMessage = JSON.stringify(message);
  }
  return (
    <div className={classes.wrapper}>
      <div className={classes.flex}>
        <Icon className={classes.progress}>cancel</Icon>
        <Typography>{renderMessage}</Typography>
      </div>
    </div>
  );
}

export default withStyles(styles, { withTheme: true })(Broken);
