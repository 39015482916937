export enum BasicType {
  String = 'String',
  Boolean = 'Boolean',
  Int = 'Int',
  Decimal = 'Decimal',
  Float = 'Float',
  Date = 'Date',
  DateTime = 'DateTime',
  Time = 'Time',
  Duration = 'Duration',
  ID = 'ID',
  JSON = '_JSON',
  Object = '_Object',
  Function = 'Function',
}

export enum GraphQLType {
  Int = 'Int',
  Float = 'Float',
  String = 'String',
  Boolean = 'Boolean',
  ID = 'ID',
  JSON = 'JSON',
  Object = 'Object',
  Duration = 'Duration',
}

export enum Html5InputType {
  Text = 'text',
  CheckBox = 'checkbox',
  Number = 'number',
  Date = 'date',
  DateTime = 'datetime-local',
  Time = 'time',
}

export const basicToGraphQL = {
  [BasicType.String]: GraphQLType.String,
  [BasicType.Boolean]: GraphQLType.Boolean,
  [BasicType.Int]: GraphQLType.Int,
  [BasicType.Decimal]: GraphQLType.Float,
  [BasicType.Float]: GraphQLType.Float,
  [BasicType.Date]: GraphQLType.String,
  [BasicType.DateTime]: GraphQLType.String,
  [BasicType.Time]: GraphQLType.String,
  [BasicType.Duration]: GraphQLType.Duration,
  [BasicType.ID]: GraphQLType.ID,
  [BasicType.JSON]: GraphQLType.JSON,
  [BasicType.Object]: GraphQLType.Object,
  [BasicType.Function]: GraphQLType.String,
};

export type BasicToTsTypes = {
  [BasicType.String]: string;
  [BasicType.Boolean]: boolean;
  [BasicType.Int]: number;
  [BasicType.Decimal]: number;
  [BasicType.Float]: number;
  [BasicType.Date]: Date;
  [BasicType.DateTime]: DateTime;
  [BasicType.Time]: DateTime;
  [BasicType.Duration]: Duration;
  [BasicType.ID]: string;
  [BasicType.JSON]: object;
  [BasicType.Object]: object;
  [BasicType.Function]: (...args: any[]) => any;
};

export const basicToHtml5 = {
  [BasicType.String]: Html5InputType.Text,
  [BasicType.Boolean]: Html5InputType.CheckBox,
  [BasicType.Int]: Html5InputType.Number,
  [BasicType.Decimal]: Html5InputType.Number,
  [BasicType.Float]: Html5InputType.Number,
  [BasicType.Date]: Html5InputType.Date,
  [BasicType.DateTime]: Html5InputType.DateTime,
  [BasicType.Time]: Html5InputType.Time,
  [BasicType.Duration]: Html5InputType.Text,
  [BasicType.ID]: Html5InputType.Text,
  [BasicType.JSON]: Html5InputType.Text,
  [BasicType.Object]: Html5InputType.Text,
};

export const basicToOpenApi = {
  [BasicType.String]: { type: ['string', 'null'] },
  [BasicType.Boolean]: { type: ['boolean', 'null'] },
  [BasicType.Int]: { type: ['integer', 'null'], format: 'int32' },
  [BasicType.Decimal]: { type: ['number', 'null'], format: 'double' },
  [BasicType.Float]: { type: ['number', 'null'], format: 'float' },
  [BasicType.Date]: { type: ['string', 'null'], format: 'date' },
  [BasicType.DateTime]: { type: ['string', 'null'], format: 'date-time' },
  [BasicType.Time]: { type: ['string', 'null'], format: 'date-time' },
  [BasicType.Duration]: { type: ['string', 'null'], format: 'date-time' },
  [BasicType.ID]: { type: ['string', 'null'] },
  [BasicType.JSON]: { type: ['object', 'null'] },
  [BasicType.Object]: { type: ['object', 'null'] },
  [BasicType.Function]: { type: ['string', 'null'] },
};

// Do not change these numbers as they are what's recorded in the source files
//  of the pipelines
export enum StageType {
  graphQLQuery = 0,
  graphQLMutation = 1,
  javaScript = 2,
  execPipeline = 3,
  http = 4,
  email = 5,
  lambda = 6,
  s3 = 7,
  typeScript = 8,
}

export enum EditorType {
  Javascript,
  GraphQL,
}

export type SimpleType = boolean | number | string;

export enum AdditionalEditor {
  Color = 'color',
}

export function toHtml5InputType(type: BasicType): Html5InputType {
  return basicToHtml5[type];
}

export function isTypeBrowserNumber(type: BasicType): boolean {
  return (
    type === BasicType.Int ||
    type === BasicType.Float ||
    type === BasicType.Decimal
  );
}

export function isBuiltin(typeName: string): boolean {
  return (
    isScalar(typeName) ||
    typeName === BasicType.JSON ||
    typeName === BasicType.Object
  );
}

export function isScalar(typeName: string): boolean {
  return (
    typeName === BasicType.String ||
    typeName === BasicType.Boolean ||
    typeName === BasicType.Int ||
    typeName === BasicType.Decimal ||
    typeName === BasicType.Float ||
    typeName === BasicType.Date ||
    typeName === BasicType.DateTime ||
    typeName === BasicType.Time ||
    typeName === BasicType.Duration ||
    typeName === BasicType.ID ||
    typeName === BasicType.Function
  );
}

export function isNumeric(typeName: string): boolean {
  return (
    typeName === BasicType.Int ||
    typeName === BasicType.Decimal ||
    typeName === BasicType.Float
  );
}

// Use strings as values so that it matches what GraphQL will expect
export enum Duration {
  Year = 'Year',
  Quarter = 'Quarter',
  Month = 'Month',
  Week = 'Week',
  Day = 'Day',
}

/** ISO 8601 Date */
export type Date = string;

/** ISO 8601 DateTime - always UTC */
export type DateTime = string;
