import { Box, MenuItem, Paper } from '@mui/material';
import { FunctionComponent, useContext } from 'react';
import { Link } from 'react-router-dom';

import { AuthenticatorContext } from '../../wrappers/AuthenticatorContext';
import { ClientContext } from '../../wrappers/ClientContext';

import ApplicationSelect from './ApplicationSelect';
import ConfigName from './ConfigName';
import ImpersonateRole from './ImpersonateRole';
import UseTestData from './UseTestData';

const UserOptions: FunctionComponent<React.PropsWithChildren<unknown>> = () => {
  const { userInfo } = useContext(ClientContext);
  const { logout: auth0Logout } = useContext(AuthenticatorContext);
  const { id } = userInfo;
  // FIXME - make this a constant
  const userUrl = `/users/${id}`;

  const logout = () => auth0Logout();

  // FIXME <ToggleDebugMode/> was removed from below because it's not working

  return (
    <Paper sx={{ p: 1 }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'left',
          minWidth: '200px',
        }}
      >
        <ApplicationSelect />
        <ConfigName />
        <ImpersonateRole />
        <UseTestData />
        <MenuItem component={Link} to={userUrl}>
          {'User Info'}
        </MenuItem>
        <MenuItem onClick={logout}>Logout</MenuItem>
      </Box>
    </Paper>
  );
};

export default UserOptions;
