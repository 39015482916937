import {
  Button,
  Checkbox,
  FormControlLabel,
  LinearProgress,
} from '@mui/material';
import classNames from 'classnames';
import React, { Fragment } from 'react';
import { KEY_FIELD } from 'universal/metadataSupportConstants';
import { getEnumKeys } from 'universal/utilityFunctions';

import CustomSelect from '../../components/atoms/CustomSelect';
import CustomTextField from '../../components/atoms/CustomTextField';
import Prompt from '../../components/atoms/Prompt';

import { TypeToEdit } from './EditPage';

function EditorControls({
  pageName,
  handleSelectTree,
  handleCreateNewTree,
  handleRevertTree,
  treeName,
  disableTreeName,
  handleUpdateField,
  handleSaveTree,
  handleSaveAllTrees,
  handleDeleteTree,
  toggleShowPreview,
  showPreview,
  classes,
  trees,
  error,
  canSave,
  saving,
  typeToEdit,
  handleSelectTypeToEdit,
}) {
  const typeToEditName = TypeToEdit[typeToEdit];

  return (
    <Fragment>
      <span style={{ display: 'flex' }}>
        <CustomSelect
          value={TypeToEdit[typeToEdit]}
          name="Type to Edit"
          onChange={(key) => handleSelectTypeToEdit(TypeToEdit[key])}
          options={getEnumKeys(TypeToEdit)}
          style={{ width: '200px' }}
        />
        <CustomSelect
          value={pageName === null ? '' : pageName}
          name={typeToEditName}
          onChange={handleSelectTree}
          options={Object.keys(trees)}
          style={{ width: '200px' }}
        />
        <Button onClick={handleCreateNewTree}>New</Button>
        <Button onClick={handleRevertTree} disabled={pageName === ''}>
          Revert
        </Button>
        <CustomTextField
          name={`${typeToEditName} Name`}
          value={treeName}
          identifier={[KEY_FIELD]}
          onChange={handleUpdateField}
          disabled={disableTreeName}
          sx={{ width: '300px' }}
        />
        <span className={classes.saveButtonWrapper}>
          <Button
            onClick={handleSaveTree}
            className={classNames({
              [classes.saveButtonError]: error,
            })}
            disabled={!canSave}
          >
            Save
          </Button>

          {saving && <LinearProgress className={classes.progress} />}
        </span>
        <span className={classes.saveButtonWrapper}>
          <Prompt
            onAccept={handleSaveAllTrees}
            message="Are you sure you want to save all?"
          >
            <Button
              className={classNames({
                [classes.saveButtonError]: error,
              })}
            >
              Save All
            </Button>
          </Prompt>
          {saving && <LinearProgress className={classes.progress} />}
        </span>
        <Prompt
          onAccept={handleDeleteTree}
          message={`Are you sure you want to delete ${treeName}?`}
        >
          <Button>Delete</Button>
        </Prompt>
        {typeToEdit === TypeToEdit.widgetTree && (
          <FormControlLabel
            control={
              <Checkbox
                checked={showPreview}
                onChange={toggleShowPreview}
                value="showPreview"
              />
            }
            label="Show Preview"
          />
        )}
      </span>
    </Fragment>
  );
}

export default React.memo(EditorControls);
