import _ from 'lodash';
import { v1 as uuidv1 } from 'uuid';

import { COMPANY_DOMAIN } from '../../common/commonConstants';
import { escapeGraphqlString } from '../../utilityFunctions';
import { PipelineExecutor } from '../pipelineExecutor';
import { IStageInfo, IStageProperties } from '../stage';
import { StageImpl } from '../stageImpl';

import { IUserInfo } from 'universal/permissionManager';

export interface IStagePropertiesEmail extends IStageProperties {
  to: string;
  cc: string;
  from: string;
  subject: string;
  text: string;
  otherId: string;
  entityType: string;
  entityId: string;
  ccToSender: boolean;
}

const execute = async (executor: PipelineExecutor, stage: StageImpl) => {
  const currentEmail: any = Object.assign({}, stage.workingProperties);
  const stackId = executor.pipelineManager.clientManager.stackId;

  let fields = `timestamp: "${new Date().toISOString()}" 
      to: "${currentEmail.to}"
      from: "${currentEmail.from}"
      subject: ${escapeGraphqlString(currentEmail.subject)}
      content: ${escapeGraphqlString(currentEmail.text)}
      stackId: "${stackId}"
      `;

  if (currentEmail.ccToSender) {
    if (currentEmail.cc) {
      currentEmail.cc = `${currentEmail.from}, ${currentEmail.cc}`;
      fields += ` cc: "${currentEmail.cc}"`;
    } else {
      currentEmail.cc = `${currentEmail.from}`;
      fields += ` cc: "${currentEmail.from}"`;
    }
  } else if (currentEmail.cc) {
    fields += ` cc: "${currentEmail.cc}"`;
  }
  if (currentEmail.otherId) {
    fields += ` otherId: "${currentEmail.otherId}"`;
  }
  if (currentEmail.entityId) {
    fields += ` entityId: "${currentEmail.entityId}"`;
  }
  if (currentEmail.entityType) {
    fields += ` entityType: "${currentEmail.entityType}"`;
  }

  // Using "from" will cause some email to be undelivered to some recipients, depending
  // on the level of spam protection they are using. The Reply-To header will always
  // for for this purpose.
  currentEmail['h:Reply-To'] = currentEmail.from;

  // This is going to show up as the "from" address in the email client. Try to make it a little
  // friendly by showing the sender's name in the from address, if we know that.
  const fromEmail = `postmaster@mail.${COMPANY_DOMAIN}`;
  let userInfo: IUserInfo;
  try {
    userInfo =
      await executor.pipelineManager.clientManager.permissionManager.getUserInfo(
        { email: currentEmail.from },
      );
  } catch (e) {
    // It's OK
  }
  if (userInfo) {
    currentEmail.from = `${userInfo.userName} <${fromEmail}>`;
  } else {
    currentEmail.from = fromEmail;
  }

  currentEmail.emailLogId = uuidv1();
  currentEmail.text += `\nid: ${stackId}/${currentEmail.emailLogId}`;

  await executor.pipelineManager.executeGraphqlMutation({
    mutation: ` mutation {  createEmailLog(input: { id: "${currentEmail.emailLogId}", 
       ${fields} 
      } ) { id }  }`,
  });

  const emailToSend = _.pick(currentEmail, [
    'from',
    'to',
    'cc',
    'subject',
    'bcc',
    "'h:Reply-To'",
    'text',
  ]);

  await executor.pipelineManager.executePipelineRemoteAsync({
    name: 'system:email',
    input: {
      currentEmail: emailToSend,
    },
  });
};

export function initialize(stageInfo: IStageInfo) {
  stageInfo.executor = execute;
}
