import Typography from '@mui/material/Typography';
import { FunctionComponent, useContext, useEffect, useState } from 'react';

import { useCheckPermissionSysAdmin } from '../../hooks/useCheckPermissions';
import { AuthenticatorContext } from '../../wrappers/AuthenticatorContext';
import { ClientContext } from '../../wrappers/ClientContext';
import CustomSelect from '../atoms/CustomSelect';

const ConfigName: FunctionComponent<React.PropsWithChildren<unknown>> = () => {
  const { clientManager } = useContext(ClientContext);
  const { schemaManager } = clientManager;
  const { logout } = useContext(AuthenticatorContext);

  const allowed = useCheckPermissionSysAdmin();

  const configName = schemaManager.executionConfigName;

  const [changed, setChanged] = useState(false);
  const [newConfigName, setNewConfigName] = useState(configName);

  useEffect(() => {
    if (changed) {
      void schemaManager
        .changeExecutionConfig(newConfigName, true)
        // Force a reload on the stack info to make sure the browser does not used a cached version
        .then(() => clientManager.stackInfo.refreshStackInfo(true))
        .then(() => logout());
    }
  });

  if (!allowed) {
    return null;
  }

  function handleChangeConfigName(configNameLocal) {
    if (configNameLocal !== newConfigName) {
      setNewConfigName(configNameLocal);
      setChanged(true);
    }
  }

  if (changed) {
    return <Typography>Logging you out for new config</Typography>;
  }
  return (
    <CustomSelect
      name="Configuration"
      options={schemaManager.availableExecConfigNames || []}
      value={configName}
      onChange={handleChangeConfigName}
    />
  );
};

export default ConfigName;
