import { Component } from 'react';

export default class Input extends Component {
  public declare props;
  componentDidMount() {
    this.initializeValue();
  }

  public initializeValue() {
    // nothing
  }

  componentDidUpdate(prevProps) {
    const { initialValue, updateInitialValue, persistValue } = this.props;
    if (updateInitialValue && initialValue !== prevProps.initialValue) {
      this.initializeValue();
    }
    if (updateInitialValue && persistValue !== prevProps.persistValue) {
      this.initializeValue();
    }
  }
}
