import { transFormToCSV } from 'universal/utilityFunctions';

const filetypes = {
  csv: 'text/csv;charset=utf-8;',
  excel: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  spreadsheet:
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  default: 'text/csv;charset=utf-8;',
};

export function downloadFile({ file, filename, filetype }) {
  const type = filetypes[filetype] || filetype || filetypes.default;
  const blob = new Blob([file], { type });
  downloadBlob({ blob, filename });
}

export function downloadBlob({ blob, filename }) {
  const link = document.createElement('a');
  const url = URL.createObjectURL(blob);
  link.setAttribute('href', url);
  link.setAttribute('download', filename);
  link.style.visibility = 'hidden';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export function downloadCSV({
  filename,
  columns,
  columnLabels,
  rows,
  sortFields,
}) {
  const csv = transFormToCSV({
    columns,
    columnLabels,
    rows,
    sortFields,
  });

  // const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });

  downloadFile({ file: csv, filename, filetype: 'text/csv;charset=utf-8;' });
}
