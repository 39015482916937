import { Button, Icon, IconButton } from '@mui/material';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import { Fragment, useContext, useMemo, useRef } from 'react';
import { useContextSelector } from 'use-context-selector';

import { compileProperties } from '../../../util/widgetUtilities';
import { ClientContext } from '../../../wrappers/ClientContext';
import { withActions } from '../../widgetEngine/ActionEnabler';

import { GridStatus } from './KendoGrid';
import {
  addAggregationToDataState,
  buildDataStateFromProps,
  buildExportColumns,
  extractConfigurationFromColumns,
  getExportMetaColumns,
  processRows,
} from './KendoGridUtilities';
import { ExportButtonProps } from './KendoTypes';

const KendoExportButton = (props: ExportButtonProps) => {
  const {
    icon,
    disabled,
    component,
    context: { nestedConfigContext: columnContext },
    getPermission,
    fileName,
  } = props;

  const label = 'Export';

  const {
    exportConfig: [exportConfig],
  } = component;

  const { clientManager } = useContext(ClientContext);
  const _export = useRef(null);

  const gridDataState = useContextSelector(
    GridStatus,
    (status) => status?.state?.dataState,
  );
  const gridMetaColumns = useContextSelector(
    GridStatus,
    (status) => status?.metaColumns,
  );
  const gridAggregates = useContextSelector(
    GridStatus,
    (status) => status?.kendoAggregates,
  );
  const gridRows = useContextSelector(GridStatus, (status) => status?.rows);
  const { rows, dataState, excelExportMetaColumns } = useMemo(() => {
    const exportConfigProperties =
      exportConfig && compileProperties(exportConfig.properties, columnContext);

    const dataStateLocal = exportConfig?.properties.rows
      ? buildDataStateFromProps(exportConfigProperties)
      : gridDataState;

    const excelExportMetaColumnsLocal = exportConfig
      ? getExportMetaColumns(exportConfig, columnContext)
      : gridMetaColumns;

    return {
      rows: exportConfig?.properties.rows
        ? exportConfigProperties?.rows
        : gridRows,
      dataState: dataStateLocal,
      excelExportMetaColumns: excelExportMetaColumnsLocal,
    };
  }, [columnContext, exportConfig, gridDataState, gridMetaColumns, gridRows]);

  const { columnFieldAggregates } = useMemo(
    () => extractConfigurationFromColumns(excelExportMetaColumns),
    [excelExportMetaColumns],
  );
  const processedDataState = useMemo(() => {
    return addAggregationToDataState(
      gridAggregates,
      columnFieldAggregates,
      dataState,
    );
  }, [dataState, gridAggregates, columnFieldAggregates]);
  const excelExport = () => {
    const exportAggregates = exportConfig
      ? exportConfig.kendoAggregates
      : gridAggregates;

    delete processedDataState.take; // 'Take' is used in virtualization, never want to virtualize an export
    const { data } = processRows(
      rows,
      processedDataState,
      excelExportMetaColumns,
      exportAggregates,
      true,
    );

    const exportColumns = buildExportColumns(
      excelExportMetaColumns,
      data,
      getPermission,
      clientManager,
    );
    _export.current.save(
      { data, group: processedDataState.group },
      exportColumns,
    );
  };

  const disableButton =
    disabled || !rows || !excelExportMetaColumns.length || !dataState;
  return (
    <Fragment>
      {icon ? (
        <IconButton disabled={disableButton} onClick={excelExport} size="large">
          <Icon>{icon}</Icon>
        </IconButton>
      ) : (
        <Button disabled={disableButton} onClick={excelExport}>
          {label}
        </Button>
      )}
      <ExcelExport ref={_export} key="KendoGrid" fileName={fileName} />
    </Fragment>
  );
};

export default withActions(KendoExportButton);
