import mqtt from 'mqtt';
import { reThrow } from 'universal/errors/errorLog';
import { getErrorString } from 'universal/errors/errorString';
import { retry, RETRY_SHORT } from 'universal/errors/retry';
import { getLogger, Loggers, LogLevels } from 'universal/loggerSupport';
import {
  hmacSha256HashBytes,
  sha256HashBytes,
  sleep,
} from 'universal/utilityFunctions';
import { ClientManager } from './clientManager';

const logger = getLogger({
  name: Loggers.GRAPHQL_SUBSCRIPTIONS,
  level: LogLevels.Warn,
});

interface createMqttClientParams {
  clientManager: ClientManager;
  mqttHost: string;
  clientId: string;
  subHandler?: ISubHandler;
  selectionNames?: string[];
}

export interface IMqttProvider {
  createMqttClient: (params: createMqttClientParams) => IMqttClient;
}

export interface IMqttClient {
  subscribe: (topic: string, observer: any) => void;
  publish: (topic: string, payload: any) => Promise<void>;
  end: () => Promise<void>;
}

export interface ISubHandler {
  onConnectionLost: (
    clientId: string,
    errorInfo?: { errorCode; errorMessage? },
    forceLog?: boolean,
  ) => void;
  onClosed: (clientId: string) => void;
  onOffline: (clientId: string) => void;
  onConnected: (mqttClient: IMqttClient, clientId: string) => void;
  onSubscribeSuccess: (clientId: string, topic: string, observer: any) => void;
  onSubscribeFailure: (
    clientId: string,
    topic: string,
    errorInfo: { errorCode; errorMessage },
  ) => void;
  onMessage: (topic: string, message: string, selectionNames: string[]) => void;
}

const makeUrl = (clientManager: ClientManager, mqttHost: string): string => {
  const { awsConfig, stackInfo } = clientManager;
  const { credentials } = awsConfig;
  const url = prepareWebSocketUrl({
    region: stackInfo.baseStackInfo.region,
    hostName: mqttHost,
    awsAccessId: credentials.accessKeyId,
    awsSecretKey: credentials.secretAccessKey,
    awsSTSToken: credentials.sessionToken,
    debug: false,
  });
  return url;
};

export const createMqttClient = (
  params: createMqttClientParams,
): IMqttClient => {
  const { clientManager, subHandler, mqttHost, clientId, selectionNames } =
    params;
  const url = makeUrl(clientManager, mqttHost);

  try {
    const client = mqtt.connect(url, {
      clientId,
      connectTimeout: 5000,
      transformWsUrl: () => makeUrl(clientManager, mqttHost),
    });

    const mqttClient = {
      subscribe: (topic: string, observer: any) =>
        subscribe({
          mqttClient: client,
          subHandler,
          clientId,
          topic,
          observer,
        }),
      publish: (topic: string, payload: any) =>
        publish({ mqttClient: client, topic, payload }),
      end: () => end(client),
    };

    client.on('disconnect', (errorObject) => {
      logger.info({ clientId, errorObject }, 'disconnect');
      subHandler?.onConnectionLost(clientId, {
        errorCode: errorObject.reasonCode,
      });
    });
    client.on('connect', () => {
      logger.info({ clientId }, 'connected');
      subHandler?.onConnected(mqttClient, clientId);
    });
    client.on('close', () => {
      logger.info({ clientId }, 'closed');
      subHandler?.onClosed(clientId);
    });
    client.on('offline', () => {
      logger.info({ clientId }, 'offline');
      subHandler?.onOffline(clientId);
    });
    client.on('message', (topic, message) => {
      logger.debug(
        {
          topic,
          message,
        },
        'subscription client message',
      );
      subHandler?.onMessage(topic, message.toString(), selectionNames);
    });

    return mqttClient;
  } catch (error) {
    reThrow({
      logger,
      message: 'Exception creating MQTT client (this is bad)',
      error,
    });
  }
};

const publish = async (params: {
  mqttClient: mqtt.MqttClient;
  topic: string;
  payload: any;
}) => {
  const { mqttClient, topic, payload } = params;
  logger.debug({ payload }, `Publish: ${topic}:`);
  await retry({
    command: () =>
      mqttClient.publishAsync(topic, payload, {
        qos: 1,
      }),
    timeoutMs: RETRY_SHORT,
    retryFunction: async (error, context) => {
      if (error.message === 'client disconnecting') {
        mqttClient.reconnect();
        await sleep(2000);
      }
      return {};
    },
  });
};

const end = async (mqttClient: mqtt.MqttClient) => {
  logger.info('End');
  await mqttClient.endAsync(true);
};

const subscribe = (params: {
  mqttClient: mqtt.MqttClient;
  subHandler: ISubHandler;
  clientId: string;
  topic: string;
  observer: any;
}) => {
  const { mqttClient, subHandler, clientId, topic, observer } = params;
  logger.debug({ topic, clientId }, 'subscribed to topic start');

  mqttClient.subscribe(topic, { qos: 1 }, (err) => {
    if (!err) {
      subHandler.onSubscribeSuccess(clientId, topic, observer);
    } else {
      subHandler.onSubscribeFailure(clientId, topic, {
        errorCode: 1,
        errorMessage: getErrorString(err),
      });
    }
  });
};

/*
 * Code below
 *
 * Copyright 2010-2015 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License").
 * You may not use this file except in compliance with the License.
 * A copy of the License is located at
 *
 *  http://aws.amazon.com/apache2.0
 *
 * or in the "license" file accompanying this file. This file is distributed
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either
 * express or implied. See the License for the specific language governing
 * permissions and limitations under the License.
 */

function makeTwoDigits(n) {
  if (n > 9) {
    return n;
  } else {
    return '0' + n;
  }
}

function getDateTimeString() {
  const d = new Date();

  //
  // The additional ''s are used to force JavaScript to interpret the
  // '+' operator as string concatenation rather than arithmetic.
  //
  return (
    d.getUTCFullYear() +
    '' +
    makeTwoDigits(d.getUTCMonth() + 1) +
    '' +
    makeTwoDigits(d.getUTCDate()) +
    'T' +
    '' +
    makeTwoDigits(d.getUTCHours()) +
    '' +
    makeTwoDigits(d.getUTCMinutes()) +
    '' +
    makeTwoDigits(d.getUTCSeconds()) +
    'Z'
  );
}

function getDateString(dateTimeString) {
  return dateTimeString.substring(0, dateTimeString.indexOf('T'));
}

function getSignatureKey(key, dateStamp, regionName, serviceName) {
  const kDate = hmacSha256HashBytes(dateStamp, 'AWS4' + key);
  const kRegion = hmacSha256HashBytes(regionName, kDate);
  const kService = hmacSha256HashBytes(serviceName, kRegion);
  const kSigning = hmacSha256HashBytes('aws4_request', kService);
  return kSigning;
}

function signUrl(
  method,
  scheme,
  hostname,
  path,
  queryParams,
  accessId,
  secretKey,
  region,
  serviceName,
  payload,
  today,
  now,
  debug,
  awsSTSToken,
) {
  const signedHeaders = 'host';
  const canonicalHeaders = 'host:' + hostname.toLowerCase() + '\n';
  const canonicalRequest =
    method +
    '\n' + // method
    path +
    '\n' + // path
    queryParams +
    '\n' + // query params
    canonicalHeaders + // headers
    '\n' + // required
    signedHeaders +
    '\n' + // signed header list
    sha256HashBytes(payload); // hash of payload (empty string)
  const hashedCanonicalRequest = sha256HashBytes(canonicalRequest);

  const stringToSign =
    'AWS4-HMAC-SHA256\n' +
    now +
    '\n' +
    today +
    '/' +
    region +
    '/' +
    serviceName +
    '/aws4_request\n' +
    hashedCanonicalRequest;

  const signingKey = getSignatureKey(secretKey, today, region, serviceName);
  const signature = hmacSha256HashBytes(stringToSign, signingKey);

  let finalParams = queryParams + '&X-Amz-Signature=' + signature;
  if (awsSTSToken !== undefined) {
    finalParams += '&X-Amz-Security-Token=' + encodeURIComponent(awsSTSToken);
  }

  const url = scheme + hostname + path + '?' + finalParams;
  if (debug === true) {
    console.log('url: ' + url + '\n');
  }

  return url;
}

function prepareWebSocketUrl(params: {
  hostName: string;
  region: string;
  awsAccessId: string;
  awsSecretKey: string;
  awsSTSToken: string;
  debug?: boolean;
}) {
  const { hostName, region, awsAccessId, awsSecretKey, awsSTSToken, debug } =
    params;
  const now = getDateTimeString();
  const today = getDateString(now);
  const path = '/mqtt';
  const awsServiceName = 'iotdevicegateway';
  const queryParams =
    'X-Amz-Algorithm=AWS4-HMAC-SHA256' +
    '&X-Amz-Credential=' +
    awsAccessId +
    '%2F' +
    today +
    '%2F' +
    region +
    '%2F' +
    awsServiceName +
    '%2Faws4_request' +
    '&X-Amz-Date=' +
    now +
    '&X-Amz-SignedHeaders=host';

  return signUrl(
    'GET',
    'wss://',
    hostName,
    path,
    queryParams,
    awsAccessId,
    awsSecretKey,
    region,
    awsServiceName,
    '',
    today,
    now,
    debug,
    awsSTSToken,
  );
}
