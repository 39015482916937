/*
GraphQL Support functions related to ASTs and examining/building queries/mutations/subscriptions
 */

import {
  ArgumentNode,
  ASTNode,
  BREAK,
  DefinitionNode,
  DirectiveNode,
  DocumentNode,
  FieldNode,
  FragmentDefinitionNode,
  GraphQLResolveInfo,
  IntValueNode,
  Kind,
  OperationDefinitionNode,
  SelectionSetNode,
  visit,
} from 'graphql';
import { print } from 'graphql/language/printer.mjs';

import { getLogger, Loggers } from '../loggerSupport';
import { MetadataSupport } from '../metadataSupport';
import {
  CONNECTION_COUNT,
  DIRECTIVE_QUERYID,
  IFieldNameInfo,
  KEY_FIELD,
  QUERY_ARG_LIMIT,
  QUERY_ARG_PAGE_LIMIT,
  UpdateType,
} from '../metadataSupportConstants';
import { FragmentType, SchemaManager } from '../schemaManager';
import { TypeDefinition } from '../typeDefinition';
import { BasicType, isBuiltin, isNumeric, SimpleType } from '../types';
import { escapeGraphqlString } from '../utilityFunctions';

const logger = getLogger({ name: Loggers.GRAPHQL_SUPPORT });

export function getGraphqlOperation(
  document: DocumentNode,
): OperationDefinitionNode {
  const opDefs = (document.definitions as OperationDefinitionNode[]).filter(
    (def) => def.kind === 'OperationDefinition',
  );
  if (opDefs.length !== 1) {
    throw new Error(
      `Only one GraphQL operation is supported: ${document.loc.source.body}`,
    );
  }
  return opDefs[0];
}

// Used by visitor functions
export function isInOperation(ancestors): boolean {
  return (
    (ancestors[2] as OperationDefinitionNode)?.kind === 'OperationDefinition'
  );
}

export function getGraphqlQueryFieldNameFromDocument(document: DocumentNode) {
  return getGraphqlOperationFieldFromDocument(document).name.value;
}

export function getGraphqlOperationFieldFromDocument(
  document: DocumentNode,
): FieldNode {
  const opDef = getGraphqlOperation(document);
  return opDef.selectionSet.selections[0] as FieldNode;
}

export function getGraphqlOperationFieldFromOperation(
  operation: OperationDefinitionNode,
): FieldNode {
  return operation.selectionSet.selections[0] as FieldNode;
}

export function printGraphQLDocument(query: ASTNode) {
  return print(query).replace(/\n/g, ' ');
}

export function requiresGraphqlQuotes(typeName: string): boolean {
  return !(isNumeric(typeName) || typeName === BasicType.Boolean);
}

export function getFieldNameInfo(fieldName: string): IFieldNameInfo {
  const returnVal: IFieldNameInfo = {
    fieldName,
    entityId: null,
    updateType: UpdateType.NOT_UPDATE,
  };
  if (fieldName === '__schema') {
    returnVal.queryType = 'Not_Query';
    return returnVal;
  }

  if (fieldName.endsWith('Internal')) {
    fieldName = fieldName.slice(0, -8);
  }

  if (fieldName.startsWith('batch')) {
    fieldName = fieldName.substring(5);
    fieldName = fieldName.charAt(0).toLowerCase() + fieldName.substring(1);
    returnVal.queryType = 'Mutation_Batch';
  }

  if (fieldName.startsWith('get')) {
    returnVal.queryType = 'Get';
    returnVal.entityId = fieldName.substring(3);
  } else if (fieldName.startsWith('list')) {
    returnVal.queryType = 'List';
    returnVal.entityId = fieldName.substring(4);
  } else if (
    fieldName.startsWith('create') ||
    fieldName.startsWith('update') ||
    fieldName.startsWith('upsert') ||
    fieldName.startsWith('delete')
  ) {
    returnVal.queryType = returnVal.queryType
      ? returnVal.queryType
      : 'Mutation';
    returnVal.entityId = fieldName.substring(6);
    const updateType = fieldName.slice(0, 6);
    switch (updateType) {
      case 'create':
        returnVal.updateType = UpdateType.CREATE;
        break;
      case 'update':
        returnVal.updateType = UpdateType.UPDATE;
        break;
      case 'upsert':
        returnVal.updateType = UpdateType.UPSERT;
        break;
      case 'delete':
        returnVal.updateType = UpdateType.DELETE;
        break;
      default:
        throw new Error(`Unknown updateType: ${updateType}`);
    }
  } else {
    returnVal.queryType = 'Subscription';
    returnVal.entityId = fieldName;
  }
  return returnVal;
}

export function getTopLevelDirective(
  definition: DefinitionNode,
  directive: string,
): DirectiveNode {
  const found = (definition as any).directives.filter(
    (d) => d.name.value === directive,
  );
  if (found.length > 0) {
    return found[0];
  }
  return null;
}

export function isConnectionCount(operationNode: OperationDefinitionNode) {
  let foundCount = false;
  visit(operationNode, {
    SelectionSet: {
      leave(node, key, parent, path, ancestors) {
        if (
          ancestors.length === 3 &&
          (ancestors[0] as OperationDefinitionNode).kind ===
            'OperationDefinition' &&
          (ancestors[1] as SelectionSetNode).kind === 'SelectionSet'
        ) {
          if (
            node.selections.find((s) => {
              return (s as FieldNode).name.value === CONNECTION_COUNT;
            })
          ) {
            foundCount = true;
          }
          return BREAK;
        }
      },
    },
  });
  return foundCount;
}

export function getQueryArgumentNames(graphQLDocument: DocumentNode) {
  const args = [];
  visit(graphQLDocument, {
    Argument: {
      enter(node, key, parent, path, ancestors) {
        if (
          ancestors.length === 6 &&
          isInOperation(ancestors) &&
          path[5] === 'arguments'
        ) {
          const argNode = node as ArgumentNode;
          args.push(argNode.name.value);
        }
      },
    },
  });
  return args;
}

export function getQueryId(queryField: FieldNode) {
  const queryDirective = queryField.directives.find(
    (d) => d.name.value === DIRECTIVE_QUERYID,
  );
  if (!queryDirective) {
    return undefined;
  }
  const queryId = (queryDirective.arguments[0].value as IntValueNode)
    .value as unknown as number;
  return queryId;
}

export function getGraphqlFieldKind(typeName: string): string {
  if (
    typeName === BasicType.String ||
    typeName === BasicType.Date ||
    typeName === BasicType.DateTime ||
    typeName === BasicType.Time ||
    typeName === BasicType.ID ||
    typeName === BasicType.Duration
  ) {
    return 'StringValue';
  }
  if (typeName === BasicType.Int) {
    return 'IntValue';
  }
  if (typeName === BasicType.Float) {
    return 'FloatValue';
  }
  if (typeName === BasicType.Boolean) {
    return 'BooleanValue';
  }
  if (typeName === BasicType.JSON || typeName === BasicType.Object) {
    return 'ObjectValue';
  }
  return 'StringValue';
}

export function getReplaceOrDeleteDirective(
  directive: string,
  info: GraphQLResolveInfo,
) {
  return info.fieldNodes?.[0].directives?.find(
    (d) => d.name.value === directive,
  );
}

export function getFragmentNameFromEntityId(
  entityId: string,
  fragmentType: FragmentType,
) {
  return `f${fragmentType}_${MetadataSupport.getUnqualifiedName(entityId)}`;
}

export function getFragmentTypeAndEntityIdFromFragmentName(
  fragmentName: string,
): {
  entityId: string;
  fragmentType: FragmentType;
} {
  const parts = fragmentName.split('_');
  const fragmentType = parseInt(parts[0].substring(1), 10);
  const entityId = parts.slice(1).join('');
  return { fragmentType, entityId };
}

export function getFragmentNameFromFragment(fragment: DocumentNode): string {
  return (fragment.definitions[0] as FragmentDefinitionNode).name.value;
}

export function makeFragmentFromSelectionSet(params: {
  selectionSet: SelectionSetNode;
  entityId: string;
  typeName: string;
}): DocumentNode {
  return {
    kind: Kind.DOCUMENT,
    definitions: [
      {
        kind: Kind.FRAGMENT_DEFINITION,
        name: {
          kind: Kind.NAME,
          value: params.entityId,
        },
        typeCondition: {
          kind: Kind.NAMED_TYPE,
          name: {
            kind: Kind.NAME,
            value: params.typeName,
          },
        },
        selectionSet: params.selectionSet,
      },
    ],
  };
}

// The returned string does not include outermost braces
export function makeGraphqlFieldList(params: {
  typeDef: TypeDefinition;
  fragmentType: FragmentType;
  schemaManager: SchemaManager;
  metadataSupport: MetadataSupport;
  selectionSet?: SelectionSetNode;
  associatedEntities?: string[];
  schemaManagerInit?: boolean;
}): string {
  const {
    typeDef,
    fragmentType,
    associatedEntities = [],
    selectionSet,
    schemaManager,
    metadataSupport,
    schemaManagerInit,
  } = params;

  const makeFields = (
    ft,
    td: TypeDefinition,
    typesSeen: TypeDefinition[],
    nestedSelectionSet?: SelectionSetNode,
  ) => {
    typesSeen.push(td);
    td.getAttributes().forEach((attr) => {
      let currentSelectionSet: SelectionSetNode;
      if (nestedSelectionSet) {
        const currentSelection = nestedSelectionSet.selections.find(
          (s) => s.kind === 'Field' && s.name.value === attr.name,
        ) as FieldNode;
        if (!currentSelection && attr.name !== KEY_FIELD) {
          return;
        }
        if (currentSelection) {
          currentSelectionSet = currentSelection.selectionSet;
        }
      }

      let nestedTypeDesc = null;
      if (
        metadataSupport.isObjectType({
          itemInfo: attr.itemInfo,
          typeDef,
        })
      ) {
        nestedTypeDesc = schemaManager.getTypeDefinition({
          name: attr.itemInfo.type,
          parentRecord: td,
          errorContext: td.id,
        });
        if (nestedTypeDesc.isEnum) {
          nestedTypeDesc = null;
        }
      }
      if (typesSeen.find((x) => x === nestedTypeDesc)) {
        return;
      }

      ft += ` ${attr.name}  `;
      if (attr.itemInfo.associatedEntity) {
        switch (fragmentType) {
          case FragmentType.DEEP:
            // We have a fragment for each entity
            associatedEntities.push(
              MetadataSupport.getUnqualifiedName(
                attr.itemInfo.associatedEntity,
              ),
            );
            if (schemaManagerInit) {
              ft += ` { ...${getFragmentNameFromEntityId(
                attr.itemInfo.associatedEntity,
                FragmentType.DEEP,
              )} } `;
              break;
            }

            ft += ' { ';
            nestedTypeDesc = schemaManager.getEntityType({
              name: attr.itemInfo.associatedEntity,
              parentRecord: td,
              errorContext: td.id,
            }).typeDefinitionObject;
            if (typesSeen.find((x) => x === nestedTypeDesc)) {
              // There are many cases where the DEEP fragment type (used with the REST API) is useful even though
              // there is recursion, so we don't want to disallow the entire root type definition from using it.
              // In these cases, just provide the id of the associated entity as if it was not a deep fragment.
              logger.debug(
                {
                  rootType: typeDef.id,
                  thisType: td.id,
                  associatedEntityAttr: attr.name,
                },
                'Deep fragment resolution: suppressing associated reference because of recursion',
              );
              ft += 'id';
            } else {
              ft = makeFields(
                ft,
                nestedTypeDesc,
                typesSeen,
                currentSelectionSet,
              );
            }
            ft += ' } ';

            break;
          case FragmentType.SHALLOW_PLUS_ID:
            ft += ' { id } ';
            break;
          case FragmentType.SHALLOW:
            break;
        }
      } else {
        if (nestedTypeDesc) {
          ft += ' { ';
          ft = makeFields(ft, nestedTypeDesc, typesSeen, currentSelectionSet);
          ft += ' } ';
        }
      }
    });
    typesSeen.pop();
    return ft;
  };

  const fieldsString = makeFields('', typeDef, [], selectionSet);
  return fieldsString;
}

// For queries to make sure all requested fields are provided (with a null value if the field was not present in the database).
// This makes sure the cache does not consider a field missing.
export function addMissingFields(params: {
  selectionSet: SelectionSetNode;
  typeDef?: TypeDefinition;
  data: Record<string, any>;
  schemaManager?: SchemaManager;
}) {
  const { selectionSet, typeDef, data, schemaManager } = params;
  if (typeof data !== 'object' || !data) {
    return;
  }
  for (const sel of selectionSet.selections) {
    const field = sel as FieldNode;
    const fieldName = field.name.value;
    const fieldData = data[fieldName];
    if (fieldData === undefined) {
      data[fieldName] = null;
      continue;
    }
    if (fieldData && typeof fieldData === 'object') {
      if (!field.selectionSet) {
        continue;
      }
      const attr = typeDef?.getAttribute(fieldName);
      if (attr?.itemInfo.associatedEntity) {
        continue;
      }
      let attrTypeDef: TypeDefinition;
      if (attr && !isBuiltin(attr?.itemInfo.type)) {
        attrTypeDef = schemaManager.getTypeDefinition({
          name: attr.itemInfo.type,
          parentRecord: typeDef,
        });
      }
      if (Array.isArray(fieldData)) {
        fieldData.forEach((m) =>
          addMissingFields({
            selectionSet: field.selectionSet,
            data: m,
            typeDef: attrTypeDef,
            schemaManager,
          }),
        );
      } else {
        addMissingFields({
          selectionSet: field.selectionSet,
          data: fieldData,
          typeDef: attrTypeDef,
          schemaManager,
        });
      }
    }
  }
}

export function makeGraphQLArgumentsString({
  queryArguments,
  typeDefinition,
}: {
  queryArguments: {
    [key: string]: SimpleType;
  };
  typeDefinition: TypeDefinition;
}): string {
  if (queryArguments && Object.keys(queryArguments).length !== 0) {
    const insideParens = Object.keys(queryArguments)
      .map((attributeName) => {
        let value = queryArguments[attributeName];

        if (
          attributeName === QUERY_ARG_LIMIT ||
          attributeName === QUERY_ARG_PAGE_LIMIT
        ) {
          return `${attributeName}: ${value}`;
        }
        const attribute = typeDefinition
          .getAttributes()
          .find((att) => att.name === attributeName);

        if (!attribute) {
          throw new Error(
            `Could not find attribute ${attributeName} in type definition ${typeDefinition.id}`,
          );
        }
        if (requiresGraphqlQuotes(attribute.itemInfo.type)) {
          value = escapeGraphqlString(value);
        }
        return `${attributeName}: ${value}`;
      })
      .join(',');

    return `(${insideParens})`;
  }

  return '';
}
