import { BaseLogger } from 'pino';

import { ErrorNested, getErrorString } from './errorString';

export function reThrow({
  logger,
  message,
  logObject,
  error,
  level = 'error',
  noThrow,
}: {
  logger?: BaseLogger;
  message: string;
  logObject?: any;
  error?: Error;
  level?: string;
  noThrow?: boolean;
}) {
  if (logger) {
    let logMessage = message;
    if (error) {
      logMessage = `${message}: ${getErrorString(error)}`;
    }
    if (logObject) {
      logger[level](logObject, logMessage);
    } else {
      logger[level](logMessage);
    }
  }

  if (noThrow) {
    return;
  }
  if (error) {
    throw new ErrorNested({
      message,
      originalError: error,
      additionalInfo: logObject,
    });
  }
  throw new Error(message);
}
