import { connect } from 'react-redux';

export function withUiState(Component) {
  return connect(mapStateToProps)(Component);
}

const mapStateToProps = (state, props) => {
  return {
    ...(props.persistKey && {
      persistValue: state.uiState[props.persistKey],
    }),
  };
};
