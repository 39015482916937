import { argumentsObjectFromField } from '@apollo/client/utilities';
import {
  ArgumentNode,
  BREAK,
  FieldNode,
  Kind,
  OperationDefinitionNode,
  SelectionNode,
  SelectionSetNode,
  StringValueNode,
  VariableDefinitionNode,
  visit,
} from 'graphql';
import gql from 'graphql-tag';
import _ from 'lodash';
import { Writable } from 'ts-essentials';

import { reThrow } from 'universal/errors/errorLog';
import { getLogger, Loggers, LogLevels } from '../../loggerSupport';
import { getRecordCountFromResult } from '../../metadataSupport';
import {
  AGGREGATE_PIPELINE_OUTPUT,
  CHUNK_ID,
  CONNECTION_COUNT,
  DIRECTIVE_FILTER,
  DIRECTIVE_IGNORE_SIZECLASS,
  DIRECTIVE_OUTPUTPIPELINE,
  DIRECTIVE_PAGED,
  DIRECTIVE_QUERYID,
  DIRECTIVE_QUERYID_ID,
  ENTITY_INCARNATIONS,
  FETCH_POLICY_NO_CACHE,
  INCARNATION,
  ITEMS,
  KEY_FIELD,
  NEXT_TOKEN,
  QUERY_ARG_LIMIT,
  QUERY_ARG_PAGE_LIMIT,
  QUERY_LIST_SUFFIX,
  SYSTEM_QUERYABLE_FIELDS,
  TYPE_NAME,
} from '../../metadataSupportConstants';
import { sizeClassProperties } from '../../sizeClass';
import { waitFor } from '../../utilityFunctions';
import {
  getFieldNameInfo,
  getGraphqlOperationFieldFromDocument,
  getGraphqlQueryFieldNameFromDocument,
  isConnectionCount,
  isInOperation,
  printGraphQLDocument,
} from '../graphQLSupportAst';
import { PipelineExecutor } from '../pipelineExecutor';
import {
  IQueryConnectionResult,
  PipelineManager,
  QueryConnectionResultFields,
} from '../pipelineManager';
import { IStageInfo, IStageProperties } from '../stage';
import { StageImpl } from '../stageImpl';
import { StageQuery } from '../stageQuery';

const logger = getLogger({ name: Loggers.PIPELINE_MANAGER });
const logQueryTiming = getLogger({
  name: Loggers.QUERY_TIMING,
  level: LogLevels.Warn,
});

// For use with the @outputpipeline directive
export const QUERY_RESULT = '_queryResult';
export const CONSOLIDATED_OUTPUTS = '_consolidatedOutputs';

// Make sure this is aligned with the stage type below
export interface IStagePropertiesGraphQLQuery extends IStageProperties {
  query: string;
  writeToExternal?: boolean;
  outputAsMap?: boolean;
  variables?: any;
  noCache?: boolean;
  fetchPolicy?: string;
  connectionResult?: IQueryConnectionResult;
  pageNumber?: number;
  useLocalLink?: boolean;
  orderBy?: string;
  ascDesc?: 'asc' | 'desc';
}

export const isListQuery = (queryFieldName: string): boolean => {
  return queryFieldName.startsWith('list');
};

const evaluate = (executor: PipelineExecutor, stage: StageImpl) => {
  const { query } = stage.workingProperties as IStagePropertiesGraphQLQuery;
  // This is a little bit of a cheat, but we can't parse the query at this point
  // since there might be unresolved handlebar references
  if (query) {
    stage.workingProperties._paged = query.includes(`@${DIRECTIVE_PAGED}`);
  }
  stage.stageQuery = new StageQuery();
  stage.stageQuery.stage = stage;
};

const prepare = (executor: PipelineExecutor, stage: StageImpl) => {
  stage.stageQuery.savedOutput = _.cloneDeep(executor.output);

  const stageQuery = stage.stageQuery;
  const { query } = stage.workingProperties as IStagePropertiesGraphQLQuery;

  // Even though this was done in evaluate(), it has to be done again here because
  // it needs to get the current compiled property values
  stage.setupGraphqlInfo(gql(query));

  const opDef = stage.getGraphQLOperation();
  if (opDef.operation !== 'query') {
    throw new Error(
      `Using graphqlQuery stage type, but the operation is a ${opDef.operation}, perhaps you could reconsider the stage execution type?`,
    );
  }

  if (opDef.name) {
    stageQuery.queryName = opDef.name.value;
  }

  stage.fieldNameInfo = getFieldNameInfo(
    getGraphqlQueryFieldNameFromDocument(stage.graphQLDocument),
  );

  const { fieldName } = stage.fieldNameInfo;

  stageQuery.tracingInfo = executor.getTracingInfo() + '/' + fieldName;

  const entityId =
    executor.pipelineManager.metadataSupport.getEntityIdFromFieldName(
      fieldName,
    );

  const schemaInfo = executor.pipelineManager.schemaManager.getSchemaInfo();
  const configName = schemaInfo.entityIdToConfigName[entityId];
  if (!configName) {
    throw new Error(`${fieldName} not found in schema info`);
  }
  stage.configName = configName;

  stage.entity = executor.pipelineManager.schemaManager.getEntityType({
    name: entityId,
    configName,
  });

  if (!stage.entity) {
    throw new Error(`Entity: ${entityId} not found`);
  }

  stage.typeDef = executor.pipelineManager.schemaManager.getTypeDefinition({
    name: stage.entity.typeDefinition,
    configName: stage.entity.configName,
  });

  if (isListQuery(fieldName)) {
    stageQuery.isListQuery = true;
    stage.graphQLDocument = fixListQuery(stage);
    stageQuery.isConnectionCount = isConnectionCount(
      stage.getGraphQLOperation(),
    );
  }

  stageQuery.args = argumentsObjectFromField(
    getGraphqlOperationFieldFromDocument(stage.graphQLDocument),
    stage.getGraphQLVariables(),
  );

  const filters = stage.graphQLField.directives
    .filter((d) => d.name.value === DIRECTIVE_FILTER)
    .map((d) => (d.arguments[0].value as StringValueNode).value);
  stageQuery.filters = filters;

  stageQuery.queryId = executor.pipelineManager.nextQueryId++;
  stage.graphQLDocument = fixQuery(stage);
  stage.graphQLDocumentText = printGraphQLDocument(stage.graphQLDocument);

  executor.pipelineManager.graphQLManager.addActiveQuery(stage);
};

const execute = async (executor: PipelineExecutor, stage: StageImpl) => {
  prepare(executor, stage);

  const { pipelineManager } = executor;
  const workingProperties =
    stage.workingProperties as IStagePropertiesGraphQLQuery;
  const stageQuery = stage.stageQuery;
  let nextToken;

  const { clientManager } = executor.pipelineManager;
  if (clientManager.serverExecutionContext) {
    const { remoteContext, testContext } = clientManager.serverExecutionContext;

    if (!workingProperties._testContext) {
      workingProperties._testContext = testContext;
    }
    if (!workingProperties._requestId) {
      workingProperties._requestId = remoteContext.requestId;
    }
  }

  if (workingProperties._paged) {
    const nextPage = executor.getNextPage();
    if (nextPage) {
      nextToken = nextPage.nextToken;
    }
  }

  stageQuery.outputPipelineInfo = pipelineManager.getOutputPipelineInfo(
    stage.getGraphQLOperation(),
  );

  const { outputPipelineInfo } = stageQuery;
  if (!stageQuery.isListQuery && outputPipelineInfo.hasOutputPipeline) {
    throw new Error(
      `${DIRECTIVE_OUTPUTPIPELINE} is supported only for list queries`,
    );
  }

  if (outputPipelineInfo.aggregatePipeline && !outputPipelineInfo.logOutput) {
    throw new Error('Output must be logged when an aggregate pipeline is used');
  }

  if (workingProperties.noCache) {
    workingProperties.fetchPolicy = FETCH_POLICY_NO_CACHE;
  }

  stage.useLocalLink = workingProperties.useLocalLink;

  stageQuery.subscribeOptions = {
    query: stage.graphQLDocument,
    // FIXME - right now we don't have the cache working with paged fetching
    fetchPolicy:
      workingProperties._paged ||
      outputPipelineInfo.hasOutputPipeline ||
      stageQuery.isConnectionCount
        ? FETCH_POLICY_NO_CACHE
        : stage.getFetchPolicy(),
    variables: {
      ...stage.getGraphQLVariables(),
    },
    context: pipelineManager.getGraphQLRequestContext(stage),
  };

  // Put this in only if it's undefined, otherwise it's treated as an undefined (instead of not present)
  // variable in the cache access
  if (nextToken) {
    stageQuery.subscribeOptions.variables.nextToken = nextToken;
  }
  logger.trace(
    stageQuery.subscribeOptions,
    `graphQLQuery execute: ${executor.getTracingInfo()} stageQuery.subscribeOptions:`,
  );

  // Reset/setup the runtime objects
  stageQuery.unsubscribeFromObservableQueries();
  stageQuery.reset();

  // Copy this to a local, because the promise in stageQuery is cleared when the stage is closed
  const obsQueryPromise = (stageQuery.obsQueryPromise = new Promise(
    (resolve, error) =>
      (stageQuery.obsQueryPromiseResolve = {
        resolve,
        error,
      }),
  ));

  // Handle reading config objects from local source code for local testing
  if (
    pipelineManager.localSourceRoot &&
    stage.entity.isConfiguration &&
    !stageQuery.isListQuery
  ) {
    const queryField = (
      stage.graphQLDocument.definitions[0] as OperationDefinitionNode
    ).selectionSet.selections[0] as FieldNode;
    let objectName;
    if (queryField) {
      objectName = (queryField.arguments[0].value as StringValueNode).value;
    }
    if (objectName) {
      const confObject = pipelineManager.clientManager.getLocalConfigObject({
        objectName,
        recordType: stage.entity.id,
      });
      if (confObject) {
        await handleQueryReturn({
          stage,
          queryResult: {
            data: { [stage.fieldNameInfo.fieldName]: confObject },
          },
          localConfig: true,
        });
        await obsQueryPromise;
        return null;
      }
    }
  }

  executor.graphQLExecutor.subscribeStageForQuery(stage);

  // Wait for the callback associated with the query to finish
  await obsQueryPromise;
  return null;
};

/* Called when the results of the query are available. This can happen inside of the
  an execution of a pipeline, or at any other time. When called inside the pipeline
  execution, obsQueryPromise is used to synchronize the pipeline so that it does
  not continue until the query is finished. At other times, this method will
  directly call the execution of the rest of the pipeline.
*/
export async function handleQueryReturn(params: {
  stage: StageImpl;
  queryResult?;
  error?;
  localConfig?: boolean;
}) {
  const { stage, queryResult, localConfig } = params;
  let { error } = params;
  const { executor } = stage;
  const { _name, outputAsMap, variables, orderBy, ascDesc } =
    stage.workingProperties as IStagePropertiesGraphQLQuery;

  const { stageQuery } = stage;

  if (
    executor.closed ||
    (!localConfig && Object.keys(stageQuery.obsQueries).length === 0)
  ) {
    return;
  }

  const endAndWake = async () => {
    if (
      stageQuery.queryStartTime &&
      logQueryTiming.isLevelEnabled(LogLevels.Info) &&
      stage.entity.id !== ENTITY_INCARNATIONS
    ) {
      logQueryTiming.info(
        `Query for stage/query ${_name} - ${
          stage.entity.id
        } done count: ${getRecordCountFromResult(stage.result)} time: ${
          Date.now() - stageQuery.queryStartTime
        }ms`,
      );
      stageQuery.queryStartTime = null;
    }

    if (stageQuery.obsQueryPromise) {
      if (error) {
        stageQuery.obsQueryPromiseResolve.error(error);
      } else {
        // When nextToken is specified is with the apolloHandler is executing the query for subsequent
        // pages. It does so with the original query. We don't want the aggregate handling in that case.
        if (
          stageQuery.outputPipelineInfo.hasOutputPipeline &&
          !variables?.[NEXT_TOKEN]
        ) {
          try {
            await handleAggregatePipeline(executor, stage);
          } catch (agError) {
            error = agError;
          }
        }

        // Finishing the initial execution of the pipeline
        if (error) {
          stageQuery.obsQueryPromiseResolve.error(error);
        } else {
          stageQuery.obsQueryPromiseResolve.resolve();
        }
      }

      stageQuery.obsQueryPromise = null;
      if (!executor.callback) {
        close(executor, stage);
      }
      return;
    }

    // In this case, the query has returned when the pipeline is not running,
    // So we run the pipeline starting from the stage after this one
    if (executor.callback) {
      // FIXME - what about the error case here?
      logger.debug(`Pipeline start (Query) ${executor.getTracingInfo()}`);
      logger.debug(
        'calling query - savedOutput: ',
        stageQuery.savedOutput,
        ' new output: ',
        executor.output,
        'Calling at stage: ',
        stage.stageIndex + 1,
      );
      executor.output = _.cloneDeep(stageQuery.savedOutput);
      executor.output[stage.originalProperties._name] = stage.result;
      await executor.execute(null, stage.stageIndex + 1);
    }
  };

  if (error) {
    await endAndWake();
    return;
  }

  const queryResultData = queryResult.data[stage.fieldNameInfo.fieldName];

  if (!stageQuery.isListQuery) {
    let dataToStore;
    if (queryResultData) {
      dataToStore = {
        ...queryResultData,
      };
    } else {
      dataToStore = null;
    }

    recordResult(executor, stage, dataToStore);
    return endAndWake();
  }

  // List processing
  const { obsQueries, cumChunkedQueryResult, limitArgumentValue } = stageQuery;
  let items = [];
  const getItemsForAllChunks = () => {
    if (Object.keys(cumChunkedQueryResult).length === 1) {
      items = cumChunkedQueryResult[0] || [];
      return;
    }

    const itemMap = {};
    Object.keys(cumChunkedQueryResult).forEach((chunkId) =>
      cumChunkedQueryResult[chunkId].forEach((i) => (itemMap[i.id] = i)),
    );
    Object.keys(itemMap).forEach((k) => items.push(itemMap[k]));
  };

  let queryIsTruncatedByCountArgument = false;
  if (limitArgumentValue) {
    getItemsForAllChunks();
    if (items.length >= limitArgumentValue) {
      queryIsTruncatedByCountArgument = true;
      items = items.slice(0, limitArgumentValue);
    }
  }

  if (!queryIsTruncatedByCountArgument) {
    if (!stage.workingProperties._paged && queryResultData.nextToken) {
      return;
    }

    let doneCount = 0;
    Object.keys(obsQueries).forEach((chunkId) => {
      if (stageQuery.chunkDone[chunkId]) {
        doneCount++;
      }
    });
    logger.debug(
      `${doneCount} chunks done of ${Object.keys(obsQueries).length}`,
    );
    if (doneCount < Object.keys(obsQueries).length) {
      return;
    }

    if (!stageQuery.isConnectionCount) {
      // Note - queryResult is only used for nextToken and get queries. For list
      // queries, we use the cumulative chunks.
      getItemsForAllChunks();

      // This can happen with chunks as we can't control the sum of the counts
      // from the database, so we might get too much
      if (items.length > limitArgumentValue) {
        items = items.slice(0, limitArgumentValue);
      }
    }
  }

  if (stageQuery.isConnectionCount) {
    (stage.workingProperties as IStagePropertiesGraphQLQuery).connectionResult =
      {
        [CONNECTION_COUNT]: stageQuery.cumCount,
        requestId: executor.requestId,
      };

    recordResult(executor, stage, { [CONNECTION_COUNT]: stageQuery.cumCount });
    return endAndWake();
  }

  // We are done - which could be inside of a recursively nested
  // fetchMore, so the results setup here, but don't close anything
  // until the recursion is finished
  let result;
  if (outputAsMap) {
    result = {};
    items.forEach((item) => (result[item.id] = item));
  } else if (orderBy) {
    result = _.orderBy(items, orderBy, ascDesc || 'asc');
  } else {
    result = items;
  }

  (stage.workingProperties as IStagePropertiesGraphQLQuery).connectionResult = {
    items,
    [NEXT_TOKEN]: queryResultData[NEXT_TOKEN],
    requestId: executor.requestId,
  };

  recordResult(executor, stage, result);
  await endAndWake();
}

const handleAggregatePipeline = async (
  executor: PipelineExecutor,
  stage: StageImpl,
) => {
  const { clientManager } = executor.pipelineManager;
  const { stageQuery: stageQuery } = stage;

  if (!stageQuery.outputPipelineInfo.aggregatePipeline) {
    return;
  }

  const { outputPipeline, aggregatePipeline } = stageQuery.outputPipelineInfo;

  let consolidatedOutputs;

  const waitForRecords = waitFor(async () => {
    consolidatedOutputs = [];
    const statusArray =
      await executor.pipelineManager.getRemoteAsyncPipelineStatus({
        requestId: executor.requestId,
      });

    logQueryTiming.debug(
      `${PipelineManager.remoteIdString(
        stage.remoteContext,
      )} Aggregate query - found ${statusArray.length} parts - cum time: ${
        Date.now() - stageQuery.queryStartTime
      }ms so far`,
    );

    const statusNameMatch = statusArray.filter(
      (s) => s.pipelineName === outputPipeline,
    );

    const statusErrors = statusNameMatch.filter(
      (s) => !!s.errorString || (!!s.endTime && !s.outputSummary),
    );

    if (statusErrors.length > 0) {
      statusErrors.forEach((s) =>
        logger.error(PipelineManager.statusToString(s)),
      );
      throw new Error(
        `Errors in output pipeline executions for ${outputPipeline} when processing aggregate pipeline ${aggregatePipeline}. It could be that the output is missing from the output pipelines. See log messages`,
      );
    }

    const statusChunkMap = {};
    statusNameMatch
      .filter((s) => !!s.endTime)
      .forEach((s) => {
        const execOutput = s.outputSummary;
        const chunkId = execOutput.chunkId ? execOutput.chunkId : 0;
        statusChunkMap[chunkId] = {
          ...statusChunkMap[chunkId],
          [execOutput.pageNumber]: !execOutput.nextToken,
        };
        consolidatedOutputs.push(s.outputSummary);
      });

    // Make sure we have all chunks, and within each chunk we have all of the
    // parts (pages from the database).
    const chunkCount = stageQuery.sizeClass
      ? sizeClassProperties[stageQuery.sizeClass].numberOfBuckets
      : 1;
    for (let chunk = 0; chunk < chunkCount; chunk++) {
      if (!statusChunkMap[chunk]) {
        return false;
      }
      const statusChunk = statusChunkMap[chunk];

      // Find the maximum part
      let maxPageNumber;
      let pageNumber = 0;
      while (true) {
        // Have not seen this part
        if (statusChunk[pageNumber] === undefined) {
          return false;
        }
        // true means there is no nextToken, so this is the last part
        if (statusChunk[pageNumber]) {
          maxPageNumber = pageNumber;
          break;
        }
        pageNumber++;
      }
      if (maxPageNumber === undefined) {
        return false;
      }

      // Make sure we have all of the parts up to the max
      for (let i = 0; i <= maxPageNumber; i++) {
        if (statusChunk[i] === undefined) {
          return false;
        }
      }
    }
    return true;
  }, 1000);

  await waitForRecords();

  logQueryTiming.debug(
    `${PipelineManager.remoteIdString(
      stage.remoteContext,
    )} Aggregate query - found ${
      consolidatedOutputs.length
    } parts - calling aggregate pipeline - cum time: ${
      Date.now() - stageQuery.queryStartTime
    }ms`,
  );

  const output = await clientManager.pipelineManager.executeNamedPipeline({
    name: aggregatePipeline,
    input: { [CONSOLIDATED_OUTPUTS]: consolidatedOutputs },
  });

  const { connectionResult } =
    stage.workingProperties as IStagePropertiesGraphQLQuery;
  Object.assign(connectionResult, { [AGGREGATE_PIPELINE_OUTPUT]: output });
};

const recordResult = (executor: PipelineExecutor, stage: StageImpl, result) => {
  if (stage.workingProperties.writeToExternal && executor.external) {
    if (stage.workingProperties._paged) {
      executor.external.appendSelf(stage.originalProperties._name, result);
    } else {
      executor.external.writeSelf(stage.originalProperties._name, result);
    }
  }
  executor.recordResult(stage, result);
};

const close = (executor: PipelineExecutor, stage: StageImpl) => {
  const { stageQuery } = stage;
  const { graphQLManager } = executor.pipelineManager;
  if (!stageQuery) {
    return;
  }
  graphQLManager.removeActiveQuery(stageQuery.queryId);
  stageQuery.unsubscribeFromObservableQueries(true);
};

export function initialize(stageInfo: IStageInfo) {
  stageInfo.executor = execute;
  stageInfo.evaluator = evaluate;
  stageInfo.close = close;
}

function fixListQuery(stage: StageImpl) {
  const nonConnectionArgNames = {};

  // Get the query args for the key
  visit(stage.graphQLDocument, {
    Argument: {
      enter(node, key, parent, path, ancestors) {
        if (
          ancestors.length === 6 &&
          isInOperation(ancestors) &&
          path[5] === 'arguments'
        ) {
          const argNode = node as ArgumentNode;
          const argName = argNode.name.value;
          if (argName === QUERY_ARG_LIMIT) {
            stage.stageQuery.limitArgumentValue = parseInt(
              (argNode.value as StringValueNode).value,
              10,
            );
          }

          if (
            !QueryConnectionResultFields[argName] &&
            argName !== QUERY_ARG_LIMIT &&
            argName !== QUERY_ARG_PAGE_LIMIT
          ) {
            nonConnectionArgNames[argName] = true;
          }
        }
      },
    },
  });

  let editedAst = visit(stage.graphQLDocument, {
    SelectionSet: {
      enter(node, key, parent, path, ancestors) {
        if (
          ancestors.length === 8 &&
          isInOperation(ancestors) &&
          (ancestors[3] as SelectionSetNode).kind === 'SelectionSet' &&
          (ancestors[6] as SelectionSetNode).kind === 'SelectionSet'
        ) {
          const nodeSelectionSet: SelectionSetNode =
            node as unknown as SelectionSetNode;
          if (!nodeSelectionSet.selections) {
            return;
          }

          Object.keys(nonConnectionArgNames).forEach((an) => {
            if (
              nodeSelectionSet.selections.find(
                (f) => f.kind === 'Field' && (f as FieldNode).name.value === an,
              )
            ) {
              delete nonConnectionArgNames[an];
            }
          });

          const fieldsToBeAdded = [];
          Object.keys(nonConnectionArgNames).forEach((k) => {
            const { typeDef } = stage;
            let attribute = typeDef.getAttributes().find((a) => a.name === k);
            const argNameToUse = attribute
              ? k
              : k.slice(0, QUERY_LIST_SUFFIX.length * -1);
            if (!attribute) {
              attribute = typeDef
                .getAttributes()
                .find((a) => a.name === argNameToUse);
              if (!attribute) {
                throw new Error(
                  `Query argument ${k} not found as attribute of ${typeDef.id}`,
                );
              }
            }
            const field: FieldNode = {
              kind: Kind.FIELD,
              directives: [],
              name: { kind: Kind.NAME, value: argNameToUse },
              selectionSet: attribute.itemInfo.associatedEntity
                ? {
                    kind: Kind.SELECTION_SET,
                    selections: [
                      {
                        kind: Kind.FIELD,
                        name: { kind: Kind.NAME, value: KEY_FIELD },
                      },
                    ],
                  }
                : undefined,
            };
            fieldsToBeAdded.push(field);
          });

          return {
            ...node,
            selections: [...nodeSelectionSet.selections, ...fieldsToBeAdded],
          };
        }
      },
    },
  });

  let doBreak = false;

  // Add the nextToken field in selection set
  editedAst = visit(editedAst, {
    SelectionSet: {
      leave(node, key, parent, path, ancestors) {
        if (
          ancestors.length === 5 &&
          isInOperation(ancestors) &&
          (ancestors[3] as SelectionSetNode).kind === 'SelectionSet'
        ) {
          if (
            node.selections.find((s) => {
              return (s as FieldNode).name.value === NEXT_TOKEN;
            })
          ) {
            return undefined;
          }
          const fieldNextToken = {
            kind: 'Field',
            directives: [],
            name: { kind: 'Name', value: NEXT_TOKEN },
          };

          return {
            ...node,
            selections: [...node.selections, fieldNextToken],
          };
        }
      },
    },
  });

  doBreak = false;

  // Add the nextToken variable, optionally ignoresizeclass directive
  editedAst = visit(editedAst, {
    OperationDefinition: {
      enter(node, key, parent, path, ancestors) {
        if (doBreak) {
          return BREAK;
        }
        if (ancestors.length === 1) {
          const localOpDef = node as OperationDefinitionNode;
          if (localOpDef.kind !== 'OperationDefinition') {
            return undefined;
          }
          if (
            localOpDef.variableDefinitions.find((s) => {
              return (
                (s as VariableDefinitionNode).variable.name.value === NEXT_TOKEN
              );
            })
          ) {
            return undefined;
          }

          const directives: any = [...localOpDef.directives];
          if (stage.workingProperties._paged && stage.entity.sizeClass) {
            directives.push({
              kind: 'Directive',
              name: { kind: 'Name', value: DIRECTIVE_IGNORE_SIZECLASS },
            });
          }

          const variable = {
            kind: 'VariableDefinition',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
            variable: {
              kind: 'Variable',
              name: { kind: 'Name', value: NEXT_TOKEN },
            },
          };
          doBreak = true;
          return {
            ...node,
            variableDefinitions: [...localOpDef.variableDefinitions, variable],
            directives,
          };
        }
      },
    },
  });

  doBreak = false;

  // Add the argument for the nextToken and connection directive
  editedAst = visit(editedAst, {
    Field: {
      enter(node, key, parent, path, ancestors) {
        if (ancestors.length > 4 || !isInOperation(ancestors)) {
          return false;
        }
        if (doBreak) {
          return BREAK;
        }
        return undefined;
      },
      leave(node, key, parent, path, ancestors) {
        if (doBreak || ancestors.length !== 4 || !isInOperation(ancestors)) {
          return undefined;
        }
        const args = [];

        if (
          !(node as FieldNode).arguments.find((s) => {
            return s.name.value === NEXT_TOKEN;
          })
        ) {
          args.push({
            kind: 'Argument',
            name: { kind: 'Name', value: NEXT_TOKEN },
            value: {
              kind: 'Variable',
              name: { kind: 'Name', value: NEXT_TOKEN },
            },
          });
        }

        doBreak = true;
        return {
          ...node,
          arguments: [...(node as FieldNode).arguments, ...args],
        };
      },
    },
  });
  return editedAst;
}

function fixQuery(stage: StageImpl) {
  let doBreak = false;

  let editedAst;

  // Add the argument for the nextToken and connection directive
  editedAst = visit(stage.graphQLDocument, {
    Field: {
      enter(node, key, parent, path, ancestors) {
        if (ancestors.length > 4 || !isInOperation(ancestors)) {
          return false;
        }
        if (doBreak) {
          return BREAK;
        }
        return undefined;
      },
      leave(node, key, parent, path, ancestors) {
        if (doBreak || ancestors.length !== 4 || !isInOperation(ancestors)) {
          return undefined;
        }

        // This query could have been derived from another query that had a queryId (the output pipeline case)
        const directivesSansQueryId = node.directives.filter(
          (d) => d.name.value !== DIRECTIVE_QUERYID,
        );
        const directive = {
          kind: 'Directive',
          name: { kind: 'Name', value: DIRECTIVE_QUERYID },
          arguments: [
            {
              kind: 'Argument',
              name: { kind: 'Name', value: DIRECTIVE_QUERYID_ID },
              value: { kind: 'IntValue', value: stage.stageQuery.queryId },
            },
          ],
        };
        doBreak = true;
        return {
          ...node,
          directives: [...directivesSansQueryId, directive],
        };
      },
    },
  });

  const { pipelineManager } = stage.executor;

  const dataPermissions =
    pipelineManager.clientManager.permissionManager.getCurrentDataPermissions() ||
    [];
  const dataPermissionFieldPaths: Array<string[]> = [];
  for (const dp of dataPermissions) {
    for (const ivf of dp.itemValueFilters) {
      if (ivf.entityId === stage.entity.id && ivf.fieldPath) {
        dataPermissionFieldPaths.push(ivf.fieldPath.split('.'));
      }
    }
  }

  const fieldPathToFieldNode = (path: string[]): FieldNode => {
    const pathElement = path.shift();
    const field: Writable<FieldNode> = {
      kind: Kind.FIELD,
      name: { kind: Kind.NAME, value: pathElement },
    };
    if (path.length > 0) {
      field.selectionSet = {
        kind: Kind.SELECTION_SET,
        selections: [fieldPathToFieldNode(path)],
      };
    }
    return field;
  };

  const addDataPermissionFields = (
    selections: Array<SelectionNode>,
    fieldPath: string[],
  ) => {
    const matchField = selections.find(
      (f) => fieldPath[0] === (f as FieldNode).name.value,
    );
    if (!matchField) {
      selections.push(fieldPathToFieldNode(fieldPath));
    } else {
      if (fieldPath.length > 1) {
        const nestedSelections = [];
        addDataPermissionFields(nestedSelections, fieldPath.slice(1));
        const writableMatchField: Writable<FieldNode> =
          matchField as Writable<FieldNode>;
        if (!writableMatchField.selectionSet) {
          writableMatchField.selectionSet = {
            kind: Kind.SELECTION_SET,
            selections: nestedSelections,
          };
        }
      }
    }
  };

  // Add the id argument if not provided, also add incarnation everywhere
  // Also add any missing parts of any data permission field paths
  editedAst = visit(editedAst, {
    Field: {
      enter(node, key, parent, path, ancestors) {
        if (!isInOperation(ancestors)) {
          return;
        }
        const getQuery = stage.fieldNameInfo.queryType === 'Get';
        const queryContainerFieldIndex = getQuery ? 5 : 8;

        let modifyThisSelectionSet: boolean;
        let associatedEntity: boolean;

        // Validate the selection set respects the typedef
        // Look for the associated entities to check if an id needs to be added
        if (ancestors.length > queryContainerFieldIndex + 1) {
          const attributePath = [];
          for (
            let a = queryContainerFieldIndex + 1;
            a < ancestors.length;
            a++
          ) {
            if ((ancestors[a] as FieldNode).kind === 'Field') {
              attributePath.push((ancestors[a] as FieldNode).name.value);
            }
          }
          attributePath.push(node.name.value);
          try {
            if (
              Object.keys(SYSTEM_QUERYABLE_FIELDS).includes(
                attributePath[attributePath.length - 1],
              )
            ) {
              return;
            }
            // This can be added at some point in the query processing, must ignore it
            if (attributePath[attributePath.length - 1].endsWith(TYPE_NAME)) {
              return;
            }
            // FIXME - need a more uniform way of handling these system fields that are not
            // in the typeDef
            if (attributePath[attributePath.length - 1].endsWith(CHUNK_ID)) {
              return;
            }
            const itemType =
              pipelineManager.metadataSupport.getItemTypeFromPath({
                typeDef: stage.typeDef,
                path: attributePath,
              });
            if (itemType.itemInfo.associatedEntity) {
              modifyThisSelectionSet = true;
              associatedEntity = true;
            } else {
              return;
            }
          } catch (error) {
            reThrow({
              error,
              logger,
              message: `Field not found for ${stage.workingProperties.query}`,
            });
          }
        }

        // Top-level get and list
        let topLevelSelectionSet;
        if (
          ancestors.length === queryContainerFieldIndex - 1 &&
          isInOperation(ancestors) &&
          (ancestors[3] as SelectionSetNode).kind === Kind.SELECTION_SET &&
          (getQuery || node.name.value === ITEMS)
        ) {
          topLevelSelectionSet = true;
          modifyThisSelectionSet = true;
        }

        if (modifyThisSelectionSet) {
          let newSelections = [];

          if (
            !node.selectionSet.selections.find(
              (s) => (s as FieldNode).name.value === KEY_FIELD,
            )
          ) {
            newSelections.push({
              kind: 'Field',
              name: { kind: 'Name', value: KEY_FIELD },
            });
          }

          newSelections = newSelections.concat(node.selectionSet.selections);
          // For an associated entity, if we are only getting the id, we are not actually
          // reading the object, so don't try and get the incarnation
          if (
            (!associatedEntity || newSelections.length > 1) &&
            !newSelections.find((s: FieldNode) => s.name.value === INCARNATION)
          ) {
            newSelections.push({
              kind: 'Field',
              name: { kind: 'Name', value: INCARNATION },
            });
          }

          if (topLevelSelectionSet && dataPermissionFieldPaths.length > 0) {
            for (const fp of dataPermissionFieldPaths) {
              addDataPermissionFields(newSelections, fp);
            }
          }

          const newNode = {
            ...node,
            selectionSet: { kind: 'SelectionSet', selections: newSelections },
          };

          return newNode;
        }
      },
    },
  });

  return editedAst;
}
