import { Tooltip, Typography } from '@mui/material';
import classNames from 'classnames';
import keyMirror from 'keymirror';
import _ from 'lodash';
import React, { useMemo } from 'react';
import { useContextSelector } from 'use-context-selector';

import {
  compileProperties,
  decorateStringKendo,
} from '../../../util/widgetUtilities';
import Widget from '../../widgetEngine/Widget';

import { GridStatus } from './KendoGrid';
import { flattenNestedItems, getCellDisplay } from './KendoGridUtilities';

export const cellTypes = keyMirror({
  cell: null,
  headerCell: null,
  footerCell: null,
});

function KendoCell(props) {
  const {
    cellProps,
    cellType,
    cell,
    passThroughProps,
    aliases,
    rowGetter,
    rowInstanceName,
    formPath,
    className: injectedClassName,
    context,
    cellAccessors,
    columnAliases,
    columnPath,
    rowHeight,
    footerHeight,
    groupFooter,
    columnField,
    columnTitle,
    customAggregator,
    allRows = [],
  } = props;
  // TODO: pass rows to cells via context api rather than props, so they don't have to be passed to build columns at all

  const {
    rowType,
    dataItem,
    field = columnField,
    title = columnTitle,
    style,
    colSpan,
    className: kendoCellClassName,
    onClick,
  } = cellProps;

  const className = classNames(injectedClassName, kendoCellClassName);

  const scrolling = useContextSelector(
    GridStatus,
    (contextLocal) => contextLocal.state.scrolling,
  );

  const isGroupFooter = rowType === 'groupFooter';
  const renderingCell = isGroupFooter ? groupFooter : cell;

  const sourceIndex = dataItem?.sourceIndex;

  const universalSubscribePaths = useMemo(
    () => ({
      ...columnAliases,
      _rows: [rowGetter],
    }),
    [columnAliases, rowGetter],
  );

  const universalAliases = useMemo(
    () => ({
      _column: {
        data: {
          title,
          field,
        },
      },
    }),
    [title, field],
  );

  // this is the empty cells that technically exist under group headers, but should not be rendered, they mess up the grid
  if (rowType === 'groupHeader') {
    return null;
  }

  if (!renderingCell) {
    const defaultCell = (
      <td className={className} style={style}>
        {_.get(dataItem, field)}
      </td>
    );
    return defaultCell;
  }

  const { children, properties } = renderingCell;

  if (children.length && !(scrolling && cellType === cellTypes.cell)) {
    const component = children[0];
    if (cellType === cellTypes.cell) {
      if (isGroupFooter) {
        return (
          <WidgetGroupFooter
            {...{
              style,
              rowHeight,
              colSpan,
              className,
              passThroughProps,
              columnPath,
              aliases,
              universalSubscribePaths,
              field,
              universalAliases,
              dataItem,
              component,
            }}
          />
        );
      }

      return (
        <WidgetCell
          {...{
            dataItem,
            field,
            cellAccessors,
            rowGetter,
            sourceIndex,
            rowInstanceName,
            formPath,
            universalSubscribePaths,
            rowHeight,
            colSpan,
            style,
            className,
            passThroughProps,
            columnPath,
            aliases,
            universalAliases,
            component,
          }}
        />
      );
    }

    if (cellType === cellTypes.headerCell) {
      return (
        <WidgetHeaderCell
          {...{
            component,
            passThroughProps,
            columnPath,
            aliases,
            universalSubscribePaths,
            universalAliases,
            onClick,
            field,
          }}
        />
      );
    }

    if (cellType === cellTypes.footerCell) {
      return (
        <WidgetFooterCell
          {...{
            style,
            footerHeight,
            colSpan,
            className,
            passThroughProps,
            component,
            columnPath,
            aliases,
            universalAliases,
            universalSubscribePaths,
          }}
        />
      );
    }
  }

  if (cellType === cellTypes.cell) {
    if (isGroupFooter) {
      return (
        <GroupFooter
          {...{
            context,
            dataItem,
            properties,
            allRows,
            field,
            title,
            customAggregator,
            rowHeight,
            style,
            className,
          }}
        />
      );
    }

    return (
      <BodyCell
        {...{
          cellAccessors,
          dataItem,
          properties,
          context,
          field,
          style,
          rowHeight,
          className,
        }}
      />
    );
  }

  if (cellType === cellTypes.headerCell) {
    return (
      <HeaderCell
        {...{
          context,
          dataItem,
          properties,
          title,
          field,
          className,
          style,
          onClick,
        }}
      />
    );
  }

  if (cellType === cellTypes.footerCell) {
    return (
      <FooterCell
        context={context}
        dataItem={dataItem}
        properties={properties}
        allRows={allRows}
        field={field}
        title={title}
        customAggregator={customAggregator}
        footerHeight={footerHeight}
        style={style}
        colSpan={colSpan}
        className={className}
      />
    );
  }

  return null;
}

function WidgetFooterCell(props) {
  const {
    style,
    footerHeight,
    colSpan,
    className,
    passThroughProps,
    component,
    columnPath,
    aliases,
    universalAliases,
    universalSubscribePaths,
  } = props;

  const intermediateNodes = useMemo(
    () => [columnPath, '_footer'],
    [columnPath],
  );

  return (
    <td
      style={{ ...style, height: `${footerHeight}px` }}
      colSpan={colSpan}
      className={className}
    >
      <div
        style={{
          height: `${footerHeight}px`,
        }}
      >
        <Widget
          {...{
            component,
            passThroughProps,
            intermediateNodes,
            aliases,
            subscribePaths: universalSubscribePaths,
            childAliases: universalAliases,
          }}
        />
      </div>
    </td>
  );
}

function WidgetHeaderCell(props) {
  const {
    component,
    passThroughProps,
    columnPath,
    aliases,
    universalSubscribePaths,
    universalAliases,
    onClick,
    field,
  } = props;

  const sortStatus = useContextSelector(
    GridStatus,
    (context) => context.state.dataState.sort,
  );

  const intermediateNodes = useMemo(
    () => [columnPath, '_header'],
    [columnPath],
  );

  return (
    <span
      onClick={onClick}
      style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
    >
      <Widget
        {...{
          component,
          passThroughProps,
          intermediateNodes,
          aliases,
          subscribePaths: universalSubscribePaths,
          childAliases: universalAliases,
        }}
      />
      <SortDirection sortStatus={sortStatus} field={field} />
    </span>
  );
}

function WidgetCell(props) {
  const {
    dataItem,
    field,
    cellAccessors,
    rowGetter,
    sourceIndex,
    rowInstanceName,
    formPath,
    universalSubscribePaths,
    rowHeight,
    colSpan,
    style,
    passThroughProps,
    columnPath,
    aliases,
    universalAliases,
    component,
    className,
  } = props;

  const { _skips, _spans } = dataItem;

  const rowGetterPath = _.toPath(rowGetter);
  const cellAliases = useMemo(
    () =>
      Object.entries(cellAccessors).reduce((acc, [key, accessor]) => {
        return Object.assign(acc, {
          [key]: [...rowGetterPath, sourceIndex, ..._.toPath(accessor)],
        });
      }, {}),
    [cellAccessors, rowGetterPath, sourceIndex],
  );

  const rowAlias = useMemo(() => {
    return dataItem && [...rowGetterPath, sourceIndex];
  }, [dataItem, rowGetterPath, sourceIndex]);

  const subscribePaths = useMemo(
    () => ({
      _row: rowAlias,
      ...(rowInstanceName && { [rowInstanceName]: rowAlias }),
      _cell: [...rowGetterPath, sourceIndex, ..._.toPath(field)],
      ...(formPath && {
        form: [...formPath, ...rowGetterPath, sourceIndex, ..._.toPath(field)],
      }),
      ...cellAliases,
      ...universalSubscribePaths,
    }),
    [
      rowAlias,
      rowInstanceName,
      rowGetterPath,
      sourceIndex,
      field,
      formPath,
      cellAliases,
      universalSubscribePaths,
    ],
  );

  const intermediateNodes = useMemo(
    () => [columnPath, `_row_${sourceIndex}`],
    [columnPath, sourceIndex],
  );

  if (_skips && _skips[field]) {
    return null;
  }

  return (
    <td
      style={{ ...style, height: `${rowHeight}px` }}
      colSpan={colSpan}
      className={className}
      {...(_spans &&
        _spans[field] && {
          rowSpan: _spans[field],
        })}
    >
      <div
        style={{
          ...style,
          height: `${rowHeight}px`,
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Widget
          {...{
            component,
            passThroughProps,
            intermediateNodes,
            aliases,
            subscribePaths,
            childAliases: universalAliases,
            key: sourceIndex,
          }}
        />
      </div>
    </td>
  );
}

function WidgetGroupFooter(props) {
  const {
    style,
    rowHeight,
    colSpan,
    className,
    passThroughProps,
    columnPath,
    aliases,
    universalSubscribePaths,
    field,
    universalAliases,
    dataItem,
    component,
  } = props;

  const { items, aggregates } = dataItem;

  const intermediateNodes = useMemo(() => [columnPath], [columnPath]);

  const childAliases = useMemo(
    () => ({
      _groupRows: {
        data: flattenNestedItems(items),
      },
      _aggregates: { data: aggregates },
      _aggregate: { subscribePath: ['_aggregates', field] },
      ...universalAliases,
    }),
    [items, aggregates, field, universalAliases],
  );

  return (
    <td
      style={{ ...style, height: `${rowHeight}px` }}
      colSpan={colSpan}
      className={className}
    >
      <div
        style={{
          height: `${rowHeight}px`,
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Widget
          {...{
            component,
            passThroughProps,
            intermediateNodes,
            aliases,
            subscribePaths: universalSubscribePaths,
            childAliases,
          }}
        />
      </div>
    </td>
  );
}

function GroupFooter(props) {
  const {
    context,
    dataItem,
    properties,
    allRows,
    field,
    title,
    customAggregator,
    rowHeight,
    style,
    className,
  } = props;

  const compiledProperties = useMemo(
    () =>
      compileProperties(properties, {
        ...context,
        ...dataItem,
      }),
    [properties, context, dataItem],
  );

  const { color, backgroundColor, italic, bold, textWrap, customAggregation } =
    compiledProperties;

  const { aggregates, items } = dataItem;

  const displayValue = useMemo(() => {
    const aggregatorInput = {
      ...context,
      _rows: allRows,
      _column: { field, title },
      _groupRows: flattenNestedItems(items),
    };

    const aggregateValue = customAggregation
      ? customAggregator(customAggregation, aggregatorInput)
      : aggregates[field] && Object.values(aggregates[field])[0];

    const cellContent = decorateStringKendo(
      aggregateValue,
      compiledProperties as any,
    );

    return getCellDisplay(properties.display, cellContent, {
      ...context,
      ...dataItem,
    });
  }, [
    context,
    allRows,
    field,
    title,
    items,
    customAggregation,
    customAggregator,
    aggregates,
    compiledProperties,
    properties.display,
    dataItem,
  ]);

  return (
    <Cell
      style={style}
      height={rowHeight}
      color={color}
      backgroundColor={backgroundColor}
      italic={italic}
      bold={bold}
      textWrap={textWrap}
      className={className}
      displayValue={displayValue}
    />
  );
}

function HeaderCell(props) {
  const {
    context,
    dataItem,
    properties,
    title,
    field,
    className,
    style,
    onClick,
  } = props;

  const sortStatus = useContextSelector(
    GridStatus,
    (contextLocal) => contextLocal.state.dataState.sort,
  );

  const compiledProperties = useMemo(
    () =>
      compileProperties(properties, {
        ...context,
        ...dataItem,
      }),
    [properties, context, dataItem],
  );

  const displayValue = useMemo(() => {
    const cellContent = decorateStringKendo(title, compiledProperties as any);
    return getCellDisplay(properties.display, cellContent, {
      ...context,
      ...dataItem,
    });
  }, [title, compiledProperties, properties.display, context, dataItem]);

  const { color, backgroundColor, italic, bold, textWrap, tooltip } =
    compiledProperties;

  let typo = (
    <span
      style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
    >
      <Typography
        style={{
          ...style,
          color,
          backgroundColor,
          ...(italic && { fontStyle: 'italic' }),
          ...(bold && { fontWeight: 'bold' }),
          whiteSpace: textWrap ? 'normal' : 'nowrap',
        }}
        className={className}
        onClick={onClick}
      >
        {displayValue}
      </Typography>
      <SortDirection sortStatus={sortStatus} field={field} />
    </span>
  );
  if (tooltip) {
    typo = <Tooltip title={tooltip}>{typo}</Tooltip>;
  }
  return typo;
}

function FooterCell(props) {
  const {
    context,
    dataItem,
    properties,
    field,
    title,
    customAggregator,
    footerHeight,
    style,
    colSpan,
    className,
  } = props;

  const cellContext = useMemo(
    () => ({
      ...context,
      ...dataItem,
    }),
    [context, dataItem],
  );

  const compiledProperties = useMemo(
    () => compileProperties(properties, cellContext),
    [properties, cellContext],
  );

  const rows = useContextSelector(
    GridStatus,
    (contextLocal) => contextLocal.result.data,
  );

  const { color, backgroundColor, italic, bold, textWrap, customAggregation } =
    compiledProperties;

  const displayValue = useMemo(() => {
    const aggregatorInput = {
      ...context,
      _rows: rows,
      _column: { field, title },
    };

    const aggregateValue = customAggregation
      ? customAggregator(customAggregation, aggregatorInput)
      : '';

    const cellContent = decorateStringKendo(
      aggregateValue,
      compiledProperties as any,
    );

    return getCellDisplay(properties.display, cellContent, cellContext);
  }, [
    context,
    rows,
    field,
    title,
    customAggregation,
    customAggregator,
    compiledProperties,
    properties.display,
    cellContext,
  ]);

  return (
    <Cell
      style={style}
      height={footerHeight}
      color={color}
      backgroundColor={backgroundColor}
      italic={italic}
      bold={bold}
      textWrap={textWrap}
      colSpan={colSpan}
      className={className}
      displayValue={displayValue}
    />
  );
}

function BodyCell(props) {
  const {
    cellAccessors,
    dataItem,
    properties,
    context,
    field,
    style,
    rowHeight,
    className,
  } = props;

  const cellContext = useMemo(
    () => ({
      ...context,
      ...dataItem,
      ...Object.entries(cellAccessors).reduce(
        (acc, [key, accessor]) => ({
          ...acc,
          [key]: dataItem[accessor as any],
        }),
        {},
      ),
    }),
    [context, dataItem, cellAccessors],
  );

  const compiledProperties = useMemo(
    () => compileProperties(properties, cellContext),
    [properties, cellContext],
  );
  const { color, backgroundColor, italic, bold, textWrap } = compiledProperties;

  const { _skips, _spans } = dataItem;

  const displayValue = useMemo(() => {
    const cellContent = decorateStringKendo(
      _.get(dataItem, field),
      compiledProperties as any,
    );
    return getCellDisplay(properties.display, cellContent, cellContext);
  }, [dataItem, field, compiledProperties, properties.display, cellContext]);

  if (_skips && _skips[field]) {
    return null;
  }

  return (
    <Cell
      style={style}
      height={rowHeight}
      color={color}
      backgroundColor={backgroundColor}
      italic={italic}
      bold={bold}
      textWrap={textWrap}
      className={className}
      displayValue={displayValue}
      {...(_spans &&
        _spans[field] && {
          rowSpan: _spans[field],
        })}
    />
  );
}

function Cell(props) {
  const {
    style,
    height,
    color,
    backgroundColor,
    italic,
    bold,
    textWrap,
    colSpan,
    rowSpan,
    className,
    displayValue,
  } = props;

  return (
    <td
      style={{
        ...style,
        height: `${height}px`,
        color,
        backgroundColor,
        ...(italic && { fontStyle: 'italic' }),
        ...(bold && { fontWeight: 'bold' }),
        whiteSpace: textWrap ? 'normal' : 'nowrap',
      }}
      colSpan={colSpan}
      rowSpan={rowSpan}
      className={className}
    >
      {displayValue}
    </td>
  );
}

function SortDirection({ sortStatus, field }) {
  const sortIndex = sortStatus?.findIndex((entry) => entry.field === field);
  const sort = sortStatus && sortIndex !== -1 ? sortStatus[sortIndex] : null;
  const sortDirection = sort?.dir;

  const sortIcon = sortDirection ? (
    <span>
      <span className={`k-icon k-i-sort-${sortDirection}-sm`} />
      {sortDirection && sortStatus.length > 1 && (
        <span
          className="k-sort-order"
          style={{
            verticalAlign: 'middle',
            marginTop: '0px',
            marginBottom: '8px',
          }}
        >
          {sortIndex + 1}
        </span>
      )}
    </span>
  ) : null;

  return sortIcon;
}

export function buildCell(additionalProps) {
  return React.memo(function CustomCell(cellProps) {
    return <KendoCell cellProps={cellProps} {...additionalProps} />;
  });
}
