import deepEqual from 'fast-deep-equal';
import _ from 'lodash';

import { MeaningName, meanings, type IMeaning } from './meanings';
import { MetadataSupport } from './metadataSupport';
import {
  CollectionTypes,
  IAggregationInfo,
  IDerivedField,
  INameInfo,
  IPresentationInfo,
  TYPE_NAME,
} from './metadataSupportConstants';
import { SchemaManager } from './schemaManager';
import { BasicType, isBuiltin } from './types';
import { removeFunctions } from './utilityFunctions';

export interface IItemInfo {
  isArray?: boolean;
  collectionType?: CollectionTypes;
  associatedEntity?: string;
  generateQueryField?: boolean;
  required?: boolean;
  allowedCodeTypes?: string[];
  // Used if this field will cause changes to aggregations
  aggregationInfo?: IAggregationInfo[];
  // Next three are used if this is an entity used for aggregations
  isAggregationMatchKey?: boolean;
  aggregationType?: string;
  //If true a record will be deleted within the aggregation update if this field is zero
  deleteAggregationOnZero?: boolean;
  ingestLocale?: string;
  // Inline nested attributes
  attributes?: IItemType[];

  // This can refer to a TypeDef name
  type: BasicType | string;
  meaning?: MeaningName;
  typeDefinitionObject?: TypeDefinition;
  derivedField?: IDerivedField;

  // Computed, not stored in metadata definitions
  usesDatabaseId?: boolean;
  initialValue?: any;
  nullString?: string;
  meaningObject?: IMeaning;
}

export interface IInheritsInfo {
  type: string;
  excludedAttributes?: string[];
  includedAttributes?: IItemType[];

  // Computed
  inheritsFromObject?: TypeDefinition;
}

export interface IItemType {
  name?: string;
  nameInfo?: INameInfo;
  itemInfo?: IItemInfo;
  presentationInfo?: IPresentationInfo;
  inheritsFrom?: IInheritsInfo;
}

export class TypeDefinition {
  id: string;

  private attributes: IItemType[];
  private additionalPresentationAttributes: IItemType[] = [];

  configName: string;

  isEnum: boolean;
  presentationInfo: IPresentationInfo;

  // Computed

  private resolvedAttributes: IItemType[];
  private resolvedPresentationAttributes: IItemType[];

  // This typeDef is both a top-level typeDef for an entity and a nested
  // typeDef. In the nested case, the GraphQL typename (__typename) is altered
  // FIXME - Remove this when WORM-2474 is resolved
  useAlternateTypeNameWhenNested: boolean;

  // This typeDef is only used for an Entity, and not used in the nested case.
  isEntitysType: boolean;

  constructor(source: any) {
    Object.assign(this, source);
    if (this.attributes) {
      if (Object.values(this.attributes).length !== this.attributes.length) {
        throw new Error(
          `Found null/undefined values in TypeDef attributes while attempting to construct from source\n: ${JSON.stringify(
            source,
            null,
            2,
          )}`,
        );
      }
      for (const attr of this.attributes) {
        // This causes the graphql to choke
        if (attr.name?.startsWith('__')) {
          throw new Error(
            `Cannot have attribute start with '__' ${attr.name} in ${this.id}`,
          );
        }
      }
    }
  }

  public setupForComparison(other: TypeDefinition): {
    typeDef: TypeDefinition;
    otherTypeDef: TypeDefinition;
  } {
    const typeDef = _.cloneDeep<TypeDefinition>(this);
    const otherTypeDef = _.cloneDeep<TypeDefinition>(other);
    // Make sure the attributes are resolved in both
    typeDef.getAttributes();
    typeDef.getPresentationAttributes();
    otherTypeDef.getAttributes();
    otherTypeDef.getPresentationAttributes();

    // Functions mess up the comparison
    removeFunctions(typeDef);
    removeFunctions(otherTypeDef);

    return { typeDef, otherTypeDef };
  }

  public deepEqual(other: TypeDefinition): boolean {
    const typeDefsToCompare = this.setupForComparison(other);
    return deepEqual(typeDefsToCompare.typeDef, typeDefsToCompare.otherTypeDef);
  }

  public getAttributes(): IItemType[] {
    if (!this.resolvedAttributes) {
      this.resolvedAttributes = this.resolveAttributes({
        attributes: this.attributes,
      });
    }
    return this.resolvedAttributes;
  }

  /** Returns both the attributes and additional presentation attributes */
  public getPresentationAttributes(): IItemType[] {
    if (!this.resolvedPresentationAttributes) {
      this.resolvedPresentationAttributes = [
        ...this.getAttributes(),
        ...this.resolveAttributes({
          attributes: this.additionalPresentationAttributes,
        }),
      ];
    }
    return this.resolvedPresentationAttributes;
  }

  public getVisiblePresentationAttributesInOrder(): IItemType[] {
    const orderedAttributes = _.orderBy(this.getPresentationAttributes(), [
      'presentationInfo.order',
    ]);
    return orderedAttributes.filter(
      (att) => att.presentationInfo?.presentationVisibility !== 'exclude',
    );
  }

  public getAttribute(attribute: string): IItemType {
    return this.getPresentationAttributes().find((a) => a.name === attribute);
  }

  public initializeComputedFields(schemaManager: SchemaManager) {
    if (!this.attributes) {
      throw new Error(
        `Missing the 'attributes' property in type definition ${this.id}`,
      );
    }
    const seenFields: { [fieldName: string]: boolean } = {};
    this.initializeAttributes({
      schemaManager,
      attributes: this.attributes,
      seenFields,
    });
    if (this.additionalPresentationAttributes) {
      this.initializeAttributes({
        schemaManager,
        attributes: this.additionalPresentationAttributes,
        seenFields,
      });
    }
  }

  private initializeAttributes(params: {
    schemaManager: SchemaManager;
    attributes: IItemType[];
    seenFields: { [fieldName: string]: boolean };
  }) {
    const { schemaManager, attributes, seenFields } = params;

    attributes.forEach((a) => {
      if (a.name) {
        if (seenFields[a.name]) {
          throw new Error(
            `Duplicate field name '${a.name}' in type definition ${this.id}`,
          );
        }
        seenFields[a.name] = true;
      }
      // If an attribute isn't of a builtin type, populate it's
      // typeObject with the typeDefinition for easy access
      if (a.itemInfo?.type && !isBuiltin(a.itemInfo.type)) {
        a.itemInfo.typeDefinitionObject = schemaManager.getTypeDefinition({
          name: a.itemInfo.type,
          parentRecord: this,
        });
      }

      // if we're using a meaning, this will validate that it's being used on the
      // right type, and populate meaningObject
      if (a.itemInfo?.meaning) {
        // @ts-ignore
        a.itemInfo.meaningObject = meanings[a.itemInfo.meaning as MeaningName];
        if (!a.itemInfo.meaningObject) {
          throw new Error(
            `Meaning ${a.itemInfo.meaning} is not valid. Valid types: ${Object.keys(meanings).join(', ')}`,
          );
        }
        if (
          a.itemInfo.meaningObject.allowedTypes.indexOf(
            a.itemInfo.type as BasicType,
          ) === -1 &&
          a.itemInfo.meaningObject.allowedTypes.length > 0
        ) {
          throw new Error(
            `Meaning ${a.itemInfo.meaning} is not allowed for type ${a.itemInfo.type}`,
          );
        }
      }
      if (a.inheritsFrom) {
        if (!a.inheritsFrom.type) {
          throw new Error(
            `Missing the 'type' property from inheritsFrom in ${this.id}`,
          );
        }
        a.inheritsFrom.inheritsFromObject = schemaManager.getTypeDefinition({
          name: a.inheritsFrom.type,
          configName: this.configName,
        });
        if (!a.inheritsFrom.inheritsFromObject) {
          throw new Error(
            `In ${this.id} inherited typeDef ${a.inheritsFrom.type} not found`,
          );
        }
      } else {
        if (a.itemInfo?.associatedEntity) {
          a.itemInfo.usesDatabaseId = true;
        }
      }
    });
  }

  public unenrich(): TypeDefinition {
    this.attributes.forEach((a) => {
      delete a.inheritsFrom?.inheritsFromObject;
      const { itemInfo } = a;
      delete itemInfo?.usesDatabaseId;
      delete itemInfo?.typeDefinitionObject;
      delete itemInfo?.meaningObject;
    });
    delete this.isEntitysType;
    delete this.useAlternateTypeNameWhenNested;
    delete this.resolvedAttributes;
    delete this.resolvedPresentationAttributes;
    delete (this as any)[TYPE_NAME];
    return this;
  }

  private resolveAttributes(params: {
    attributes: IItemType[];
    seenTypeDefs?: { [typeDefName: string]: number };
    seenFields?: { [fieldName: string]: TypeDefinition };
    excludedInheritedAttributes?: { [fieldName: string]: boolean };
    includedInheritedAttributes?: { [fieldName: string]: IItemType };
    ignoreFieldNameCheck?: boolean;
    levelNumber?: number;
  }): IItemType[] {
    const {
      attributes,
      seenTypeDefs = {},
      seenFields = {},
      includedInheritedAttributes = {},
      excludedInheritedAttributes = {},
      ignoreFieldNameCheck,
      levelNumber = 0,
    } = params;

    if (!attributes) {
      return [];
    }
    if (
      seenTypeDefs[this.id] !== undefined &&
      seenTypeDefs[this.id] < levelNumber
    ) {
      throw new Error(`Recursive inherited typeDef detected in ${this.id}`);
    }
    seenTypeDefs[this.id] = levelNumber;
    let resolvedAttributes = [];
    const hasIncludedInheritedFields =
      Object.keys(includedInheritedAttributes).length > 0;
    attributes
      .filter((a) => !excludedInheritedAttributes[a.name])
      .filter(
        (a) =>
          !hasIncludedInheritedFields || includedInheritedAttributes[a.name],
      )
      .forEach((attr) => {
        if (!ignoreFieldNameCheck && attr.name && seenFields[attr.name]) {
          throw new Error(
            `In typeDef ${this.id} Inherited field ${
              attr.name
            } has already been defined in ${seenFields[attr.name].id}`,
          );
        }
        if (attr.name) {
          seenFields[attr.name] = this;
        }
        if (attr.inheritsFrom?.inheritsFromObject) {
          const excludedAttributes = {};
          attr.inheritsFrom.excludedAttributes?.forEach(
            (a) => (excludedAttributes[a] = true),
          );
          const includedAttributes = {};
          attr.inheritsFrom.includedAttributes?.forEach(
            (a) => (includedAttributes[a.name] = a),
          );
          const attrsToUse =
            attr.inheritsFrom.inheritsFromObject.resolveAttributes({
              attributes: attr.inheritsFrom.inheritsFromObject.attributes,
              seenTypeDefs,
              seenFields,
              excludedInheritedAttributes: excludedAttributes,
              includedInheritedAttributes: includedAttributes,
              ignoreFieldNameCheck: !!attr.name,
              levelNumber: levelNumber + 1,
            });
          if (attr.name) {
            if (attrsToUse.length !== 1) {
              throw new Error(
                `Attribute ${attr.name} is inheriting from ${attr.inheritsFrom.type} but ${attrsToUse.length} attributes were selected, expected exactly one inherited attribute`,
              );
            }
            resolvedAttributes.push(_.merge({}, attrsToUse[0], attr));
          } else {
            resolvedAttributes = resolvedAttributes.concat(attrsToUse);
          }
        } else {
          resolvedAttributes.push(
            _.merge(attr, includedInheritedAttributes[attr.name]),
          );
        }
      });

    return resolvedAttributes;
  }

  public getUnqualifiedId() {
    return MetadataSupport.getUnqualifiedName(this.id);
  }
}
