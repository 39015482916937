import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import LinearProgress from '@mui/material/LinearProgress';
import Modal from '@mui/material/Modal';
import Paper from '@mui/material/Paper';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { Fragment, useState } from 'react';
import { useRouteMatch } from 'react-router';

import { getIcon } from '../../util/clientUtilities';
import { createUrlProps } from '../../util/widgetUtilities';
import CustomSelect from '../atoms/CustomSelect';
import {
  sxModal,
  sxModalButtons,
  sxModalPaper,
  sxModalTypography,
} from '../atoms/Prompt';
import { withActions } from '../widgetEngine/ActionEnabler';

import { updateContextBatch } from '../../store/data';
import { store } from '../../store/store';
import {
  buttonProperties,
  IWidgetProps,
  linkProperties,
  UniversalWidgetProps,
} from './types';

const CHAR_DOWN_ARROW = '\u{25BC}';
const buttonPropertyInfo = {
  ...buttonProperties,
  ...linkProperties,
  closePopupOnClick: {
    options: [true, false],
    description: 'if true, this will close the popup this is within',
  },
  submitForm: {
    default: false,
    description:
      'If true, and this button is within a form, it runs the form containers update pipeline on click and closes the popup',
    options: [true, false],
  },
  showUpdating: {
    default: false,
    options: [true, false],
    description:
      'will show a progress notification while the buttons update pipeline is running',
  },
  valid: {
    description:
      'pointer to a boolean value in context that will allow or block this from running its update pipeline',
  },
  disableOnClick: {
    default: false,
    options: [true, false],
    description:
      'disable the button when clicked -- must set the reenable field to true to get it back',
  },
  reenable: {
    default: false,
    description: 'when set to true the disableOnClick will be overridden',
  },
  message: {
    description:
      'if this is set, an additional dialogue with a message will popup on click, if valid is set and is false, no further action will be allowed, otherwise another submit button will run the actual pipeline',
  },
  iconSize: {
    default: 24,
    description: 'Size in pixels for the icon',
  },
  width: {
    description: 'Width in pixels',
  },
  height: {
    description: 'Height in pixels',
  },
} as const;

// FIXME - this needs to be handled better - but will go away with the typescript change
type Props = IWidgetProps<typeof buttonPropertyInfo> & UniversalWidgetProps;

export const buttonTypeInfo = {
  types: {
    children: true,
  },
  childTypes: {
    actions: true,
  },
  properties: buttonPropertyInfo,
  events: {
    load: [],
    update: [],
  },
};

const ButtonWidget: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const {
    valid,
    message,
    submitForm,
    updating,
    iconSize,
    aliases,
    passThroughProps: {
      formValid,
      formValidationMessage,
      formSubmitted,
      formValidationReasonCodes,
      formValidationReasonStoreLocation,
    },
  } = props;

  const messageToUse = message || (submitForm && formValidationMessage);
  const isValid = valid && (!submitForm || formValid);
  const isDisabled = props.disabled || (!isValid && !messageToUse);

  const buttonStyle = {
    color: props.color,
    backgroundColor: props.backgroundColor,
    width: props.width || null,
    height: props.height || null,
  };

  const iconButtonStyle = {
    ...buttonStyle,
    padding: '0px',
  };
  const iconStyle = iconSize
    ? {
        fontSize: `${iconSize}px !important`,
      }
    : {};

  const [disabledOnClick, setDisabledOnClick] = useState(false);
  const [selectedReasonCode, setSelectedReasonCode] = useState();
  const disabled = isDisabled || disabledOnClick;

  function handleAccept() {
    props.updateFromWidget({
      acceptConditionalValidation: true,
    });
  }

  function handleCloseModal() {
    props.updateFromWidget({ acceptConditionalValidation: false });
  }

  // If the cursor is in a text field, and this button is the submit button on the form
  // the Click event does not happen (and there does not seem to ba anything we can do about that,
  // the MUI text widget stuff probably eats it somehow). In this case, we respond to the mouse up button
  // which does happen.
  function handleMouseUp(event) {
    handleClickInternal(event);
  }

  function handleClick(event) {
    if (event.nativeEvent.pointerId !== -1) {
      // A real mouse, handled in the mouseUp event
      return;
    }
    handleClickInternal(event);
  }

  function handleClickInternal(event) {
    const { elementAttributes } = props;
    if (!messageToUse || submitForm) {
      void handleUpdate();
    }
    elementAttributes?.onClick && elementAttributes.onClick(event);
  }

  async function handleUpdate() {
    const {
      updateFromWidget,
      passThroughProps,
      closePopupOnClick,
      disableOnClick,
    } = props;
    if (disableOnClick || (closePopupOnClick && !submitForm)) {
      setDisabledOnClick(true);
    }
    updateFromWidget({
      widgetDoesNotProvideData: true,
      ...((closePopupOnClick || submitForm) &&
        passThroughProps.closePopup && {
          callback: passThroughProps.closePopup,
        }),
    });
  }

  function reasonCodeUpdate(event, storeLocation) {
    setSelectedReasonCode(event);
    store.dispatch(
      updateContextBatch({
        updates: [{ data: event, rawPath: storeLocation }],
        aliases,
      }),
    );
  }

  const {
    icon,
    url,
    absolute,
    relativeUrlDepth,
    showUpdating,
    external,
    newTab,
    reenable,
    tooltip,
    variant,
  } = props;
  let label = props.label;
  const match = useRouteMatch();
  const renderUpdating = updating && showUpdating;
  let button;
  if (reenable && disabledOnClick) {
    setDisabledOnClick(false);
  }
  const urlProps = createUrlProps({
    url,
    relativeUrlDepth,
    absolute,
    external,
    newTab,
    match,
  });

  const baseButtonProps = {
    ...props.elementAttributes,
    // We need to look for mouseUp because the click event is not seen if the focus is on
    // an input element that has been updated. Not sure why the click event does not occur
    // in this case, but this seems to work
    onMouseUp: handleMouseUp,
    // This handles the case of space or enter
    onClick: handleClick,
    disabled: disabled || isDisabled,
    ...urlProps,
  };

  if (icon) {
    const IconToUse = getIcon(
      icon,
      props.component.configName,
      props.elementAttributes,
    );
    button = (
      <IconButton
        sx={iconButtonStyle}
        {...baseButtonProps}
        //       size="large"
      >
        <IconToUse sx={iconStyle} />
        {renderUpdating && <CircularProgress size={48} />}
      </IconButton>
    );
  } else {
    // FIXME - use the icon support in the Button
    if (
      props.component.actions.find((action) => action.type === 'MenuWidget')
    ) {
      label += CHAR_DOWN_ARROW;
    }
    button = (
      <Button
        sx={buttonStyle}
        variant={variant || 'outlined'}
        {...baseButtonProps}
      >
        {label}
        {renderUpdating && <LinearProgress />}
      </Button>
    );
  }

  if (tooltip) {
    button = <Tooltip title={tooltip}>{button}</Tooltip>;
  }
  const acceptButton = isValid ? (
    <Button onClick={handleAccept}>Okay</Button>
  ) : null;

  let reasonDropdown;
  if (formValidationReasonCodes?.length > 0 && acceptButton) {
    if (!selectedReasonCode) {
      reasonCodeUpdate(
        formValidationReasonCodes[0],
        formValidationReasonStoreLocation,
      );
    }
    reasonDropdown = (
      <CustomSelect
        style={{ margin: 10, flex: 1 }}
        onChange={(event) =>
          reasonCodeUpdate(event, formValidationReasonStoreLocation)
        }
        optionLabelField={'description'}
        value={selectedReasonCode || formValidationReasonCodes[0]}
        required={true}
        name={'Reason'}
        options={formValidationReasonCodes}
      />
    );
  }

  const modal = messageToUse ? (
    <Modal
      onClose={handleCloseModal}
      open={messageToUse && formSubmitted}
      sx={sxModal}
    >
      <Paper sx={sxModalPaper}>
        <Typography variant="subtitle1" sx={sxModalTypography}>
          {messageToUse}
        </Typography>
        {reasonDropdown}
        <Box sx={sxModalButtons}>
          <Button onClick={handleCloseModal}>Cancel</Button>
          {acceptButton}
        </Box>
      </Paper>
    </Modal>
  ) : null;

  return (
    <Fragment key="button">
      {button}
      {modal}
    </Fragment>
  );
};

export default withActions(ButtonWidget);
