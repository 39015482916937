import { useContext } from 'react';
import { compose } from 'redux';
import { findConfigItem } from 'universal/metadataSupport';

import { ClientContext } from '../../wrappers/ClientContext';
import { withActions } from '../widgetEngine/ActionEnabler';
import Widget from '../widgetEngine/Widget';

function TreeLink(props) {
  const {
    passThroughProps,
    treeName,
    optional,
    name,
    aliases,
    component: { configName },
  } = props;

  const { widgetTrees } = useContext(ClientContext);

  const childTree = findConfigItem(widgetTrees, treeName, configName, optional);

  const child = childTree && childTree.widgets && childTree.widgets[0];
  if (!child) {
    if (!optional) {
      throw new Error(`TreeLink ${name} failed to find treeName ${treeName}`);
    } else {
      return <div />;
    }
  }
  return (
    <Widget
      {...{
        component: child,
        passThroughProps,
        aliases,
      }}
    />
  );
}

export default compose(withActions)(TreeLink);
