import { Box, Tooltip, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { RouteComponentProps, withRouter } from 'react-router';
import { compose } from 'redux';
import { MapKeys } from 'universal/utilityFunctions';

import { materialUiFontWeights } from '../../../util/typographyTranslate';
import { createUrlProps } from '../../../util/widgetUtilities';
import { withActions } from '../../widgetEngine/ActionEnabler';
import { EventBindingChildWidgetProps, UniversalWidgetProps } from '../types';

import { properties } from './DisplayTypeInfo';

type IProperties = MapKeys<typeof properties>;
type IProps = IProperties &
  EventBindingChildWidgetProps &
  RouteComponentProps &
  UniversalWidgetProps;

const styles = {};

function convertToString(data) {
  if (data === undefined || data === null) {
    return '';
  } else if (typeof data === 'object') {
    return JSON.stringify(data);
  } else if (typeof data === 'number') {
    return data;
  } else {
    return String(data);
  }
}

function Display(props: IProps) {
  const {
    label,
    data,
    align,
    variant,
    url,
    absolute,
    textDecoration,
    color = url ? 'blue' : 'black',
    backgroundColor,
    width,
    noWrap,
    paddingTop,
    paddingBottom,
    paddingLeft,
    paddingRight,
    external,
    newTab,
    cursor,
    match,
    relativeUrlDepth,
    location,
    colorWhileVisiting,
    backgroundWhileVisiting,
    fontStyle,
    fontWeight,
    tooltip,
  } = props;

  const renderDisplay = typeof data === 'string' ? data : convertToString(data);

  const renderLabel =
    typeof label === 'string' ? label : convertToString(label);

  let widgetColor = color;
  let widgetBackgroundColor = backgroundColor;

  const urlProps = createUrlProps({
    url,
    relativeUrlDepth,
    absolute,
    external,
    newTab,
    match,
  });

  if (url) {
    if (colorWhileVisiting || backgroundWhileVisiting) {
      const locationArray = location.pathname.split('/');
      const atLink =
        urlProps.to &&
        urlProps.to
          .split('/')
          .reduce(
            (acc, address, index) => acc && address === locationArray[index],
            true,
          );
      if (atLink) {
        if (colorWhileVisiting) {
          widgetColor = colorWhileVisiting;
        }
        if (backgroundWhileVisiting) {
          widgetBackgroundColor = backgroundWhileVisiting;
        }
      }
    }
  }

  let displayAlign = align;
  if (align === 'default') {
    displayAlign = typeof data === 'number' ? 'right' : 'left';
  }

  let typo = (
    <Typography
      noWrap={noWrap}
      key="display"
      {...props.elementAttributes}
      align={displayAlign || 'left'}
      variant={variant}
      style={{
        textDecoration,
        fontWeight: materialUiFontWeights[fontWeight],
        fontStyle,
        backgroundColor: widgetBackgroundColor,
        color: widgetColor,
        maxWidth: '100%',
        width,
        paddingTop,
        paddingBottom,
        paddingLeft,
        paddingRight,
        cursor,
        boxSizing: 'border-box',
      }}
    >
      {renderLabel}
      {renderDisplay}
    </Typography>
  );
  if (tooltip) {
    typo = <Tooltip title={tooltip}>{typo}</Tooltip>;
  }

  return <Box {...urlProps}>{typo}</Box>;
}

export default compose(
  withRouter,
  withActions,
  withStyles(styles, { withTheme: true }),
)(Display);
