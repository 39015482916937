import { Popover } from '@mui/material';
import React, { Component } from 'react';

//readme: must have a react component child

class CustomPopover extends Component {
  public declare props;
  public declare state;
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
    };
    this.handleClick = this.handleClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  handleClick(event) {
    this.setState({ anchorEl: event.currentTarget });
  }

  handleClose() {
    this.setState({ anchorEl: null });
  }

  render() {
    const { anchorEl } = this.state;
    const { children, content } = this.props;

    const child = React.cloneElement(children, {
      onClick: this.handleClick,
    });

    return (
      <React.Fragment>
        {child}
        <Popover
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
        >
          {content}
        </Popover>
      </React.Fragment>
    );
  }
}

export default CustomPopover;
