import Menu from '@mui/material/Menu';
import React, { Component } from 'react';

import CustomMenuItem from '../atoms/CustomMenuItem';

const ITEM_HEIGHT = 48;

//readme: must have a react component child

class ScrollingMenu extends Component {
  public declare props;
  public declare state;
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
    };
    this.handleClick = this.handleClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
  }

  handleClick(event) {
    this.setState({ anchorEl: event.currentTarget });
  }

  handleClose() {
    this.setState({ anchorEl: null });
  }

  handleSelect(option) {
    const { onSelect } = this.props;
    onSelect(option);
    this.handleClose();
  }

  render() {
    const { anchorEl } = this.state;
    const { children, options } = this.props;

    const child = React.cloneElement(children, {
      onClick: this.handleClick,
    });

    const menuOptions = options.map((option) => (
      <CustomMenuItem
        option={option}
        onClick={this.handleSelect}
        key={option}
      />
    ));

    return (
      <React.Fragment>
        {child}
        <Menu
          id="long-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
          PaperProps={{
            style: {
              maxHeight: ITEM_HEIGHT * 4.5,
              width: 200,
            },
          }}
        >
          {menuOptions}
        </Menu>
      </React.Fragment>
    );
  }
}

export default ScrollingMenu;
