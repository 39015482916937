import { BasicToTsTypes, BasicType } from './types';

type MeaningDefinition<
  Complex extends BasicToTsTypes[keyof BasicToTsTypes],
  Types extends BasicType[],
> = {
  types: Types;
  complex: Complex;
};

export type MeaningName = keyof MeaningDefinitions;

export type IMeaningMap = {
  [key in MeaningName]: IMeaning<
    MeaningDefinitions[key]['complex'],
    MeaningDefinitions[key]['types']
  >;
};

export interface IMeaning<
  Complex extends
    BasicToTsTypes[keyof BasicToTsTypes] = BasicToTsTypes[keyof BasicToTsTypes],
  Types extends BasicType[] = BasicType[],
> {
  name: string;
  allowedTypes: Types;
  // if this returns a string, it is an error message and is invalid.
  // if this returns true, it is valid.
  // if this returns false, it is invalid and does not have a specific error message.
  validate: (value: Complex) => boolean | string;

  // Function to convert a value to a basic type
  toBasic: (value: Complex, type: keyof Types) => any;

  // Function to convert a value from a basic type
  fromBasic: (value: BasicToTsTypes[Types[number]], type: keyof Types) => any;
}

type MeaningDefinitions = {
  Currency: MeaningDefinition<number, [BasicType.Decimal]>;
  Email: MeaningDefinition<string, [BasicType.String]>;
  RichText: MeaningDefinition<string, [BasicType.String]>;
};

// Used to specify that a toBasic/fromBasic function should just return the value as is
const direct = <T>(from: T): T => from;

// Used to specify that a validate function will always be valid
const alwaysValid = () => true;

export const meanings: IMeaningMap = {
  Currency: {
    name: 'Currency',
    allowedTypes: [BasicType.Decimal],
    validate: function (value: number): boolean | string {
      if (value === undefined || value === null) {
        return false;
      }
      return typeof value === 'number' || 'Value must be a number.';
    },
    toBasic: direct,
    fromBasic: direct,
  },
  Email: {
    name: 'Email',
    allowedTypes: [BasicType.String],
    validate: (data: string): boolean | string => {
      const regexNightmare =
        // eslint-disable-next-line no-control-regex
        /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)])/;
      return regexNightmare.test(data) || 'Invalid email.';
    },
    toBasic: direct,
    fromBasic: direct,
  },
  RichText: {
    name: 'RichText',
    allowedTypes: [BasicType.String],
    validate: alwaysValid,
    toBasic: direct,
    fromBasic: direct,
  },
};
