import { ApolloCache, InMemoryCache } from '@apollo/client/cache';
import {
  ApolloQueryResult,
  MutationOptions,
  NetworkStatus,
} from '@apollo/client/core';
import { DocumentNode, SelectionSetNode, visit } from 'graphql';
import { FieldNode } from 'graphql/language/ast';
import { BREAK } from 'graphql/language/visitor.mjs';
import _ from 'lodash';
import PQueue from 'p-queue';

import { ClientManager } from '../clientManager';
import { getDatabaseProviderClient } from '../database/databaseProviderClient';
import { reThrow } from '../errors/errorLog';
import { analyzeError } from '../errors/errorString';
import { retry } from '../errors/retry';
import { getLogger, Loggers, LogLevels } from '../loggerSupport';
import {
  CHUNK_ID,
  CollectionTypes,
  CONNECTION_COUNT,
  DIRECTIVE_BULK_UPDATE,
  DIRECTIVE_IGNORE_SIZECLASS,
  FETCH_POLICY_NO_CACHE,
  IHasId,
  IHasIdAndTypeName,
  NEXT_TOKEN,
  UpdateType,
} from '../metadataSupportConstants';
import { sizeClassProperties } from '../sizeClass';
import { TypeDefinition } from '../typeDefinition';
import { BasicType } from '../types';
import { exists, isNullOrUndefined } from '../utilityFunctions';

import { GraphQLManager, IListQueryCacheEntry } from './graphQLManager';
import { traceListResult } from './graphQLSupport';
import {
  getFieldNameInfo,
  getTopLevelDirective,
  isInOperation,
  printGraphQLDocument,
} from './graphQLSupportAst';
import { IPage, PipelineExecutor } from './pipelineExecutor';
import { IQueryConnectionResult, PipelineManager } from './pipelineManager';
import { StageImpl } from './stageImpl';
import { StageQuery } from './stageQuery';
import { IStagePropertiesGraphQLMutation } from './stageTypes/graphQLMutation';
import { handleQueryReturn } from './stageTypes/graphQLQuery';

const logger = getLogger({ name: Loggers.PIPELINE_MANAGER });
const logBatching = getLogger({
  name: Loggers.PIPELINE_MANAGER_BATCHING,
  level: LogLevels.Warn,
});
const logQueryTiming = getLogger({
  name: Loggers.QUERY_TIMING,
  level: LogLevels.Warn,
});

export const MUTATION_COMPLETION_DESCRIPTION = 'GraphQL mutation completion';

const GRAPHQL_MULTI_LIMIT =
  getDatabaseProviderClient().getGraphQLBatchSizeLimit();

export const ROOT_QUERY = 'ROOT_QUERY';

export interface IMutateContext {
  stage: StageImpl;
  mutateOptions: MutationOptions;
  inputRecordSelectionSet: SelectionSetNode;
  updateType: UpdateType;
  entityId: string;
  mainVariable?: string;
  deletedObjects?: any[];
  executionId?: string;
}

export class GraphQLExecutor {
  private readonly pipelineExecutor: PipelineExecutor;
  private readonly graphQLManager: GraphQLManager;
  private readonly pipelineManager: PipelineManager;

  constructor(pipelineExecutor: PipelineExecutor) {
    this.pipelineExecutor = pipelineExecutor;
    this.pipelineManager = pipelineExecutor.pipelineManager;
    this.graphQLManager = pipelineExecutor.pipelineManager.graphQLManager;
  }

  private doActualMutate = async (params: {
    mutateContext: IMutateContext;
    vars: Record<string, any>;
    optResponse?: any;
    isBatchMutation?: boolean;
    batchId?: string;
  }) => {
    const { mutateContext, vars, optResponse, isBatchMutation, batchId } =
      params;
    const { mutateOptions, updateType, stage, inputRecordSelectionSet } =
      mutateContext;

    let result = null;
    if (mutateOptions.fetchPolicy !== FETCH_POLICY_NO_CACHE) {
      // Only no-cache fetch policy is supported on a mutation
      delete mutateOptions.fetchPolicy;
    }

    const localMutateOptions: MutationOptions = Object.assign(
      {},
      mutateOptions,
    );
    localMutateOptions.variables = vars;

    let invocationCount = 0;
    if (isBatchMutation) {
      // We handle the cache update in the batch mutation code
      localMutateOptions.fetchPolicy = FETCH_POLICY_NO_CACHE;
    }
    if (localMutateOptions.fetchPolicy !== FETCH_POLICY_NO_CACHE) {
      localMutateOptions.update = (cache, fetchResult) => {
        if (invocationCount > 1) {
          // We don't care about anything after the first two times, not sure why it calls it on subsequent
          // times, but the first two times seem to provide all the records.
          return;
        }
        // This is called multiple times, with the last time being when the real data comes back.
        const fetchResultRecord =
          fetchResult.data[stage.fieldNameInfo.fieldName];
        logger.debug(
          `Mutation update cache (delete/update) ${stage.fieldNameInfo.fieldName} inv#:${invocationCount} START`,
        );
        if (updateType === UpdateType.DELETE) {
          if (invocationCount === 0) {
            if (Array.isArray(fetchResultRecord)) {
              mutateContext.deletedObjects =
                mutateContext.deletedObjects.concat(fetchResultRecord);
            } else {
              mutateContext.deletedObjects.push(fetchResultRecord);
            }
          }
          this.deleteObjectsInCache({
            clientManager: this.pipelineManager.clientManager,
            incoming: mutateContext.deletedObjects,
            fieldName: stage.graphQLField.name.value,
          });
        } else {
          const data = optResponse[stage.fieldNameInfo.fieldName];
          const recordsToWrite = Array.isArray(data) ? data : [data];
          if (invocationCount > 0) {
            // Getting mutation results back from the server, merge that with optimistic response to update in cache
            const fetchResultRecords = Array.isArray(fetchResultRecord)
              ? fetchResultRecord
              : [fetchResultRecord];
            recordsToWrite.forEach((r, i) =>
              Object.assign(r, fetchResultRecords[i]),
            );
          }

          this.pipelineManager.graphQLManager.updateQueriesInCache({
            incoming: recordsToWrite,
            cache,
            inputRecordSelectionSet,
            entityType: stage.entity,
            optimistic: invocationCount === 0,
            suppressLocalNotify: stage.suppressLocalNotify,
          });
        }
        logger.debug(
          `Mutation update cache (delete/update) ${stage.fieldNameInfo.fieldName} inv#:${invocationCount} END`,
        );
        invocationCount++;
      };
    }

    const suppressLocalNotify = stage.suppressLocalNotify;
    try {
      if (suppressLocalNotify) {
        this.pipelineManager.processingSuppressLocalNotifyQueryId =
          this.pipelineManager.nextQueryId;
        this.pipelineManager.processingSuppressLocalNotifyCount++;
        logger.info(
          `Suppress local notify START: ${this.pipelineManager.processingSuppressLocalNotifyCount}`,
        );
      }
      logger.debug(
        localMutateOptions,
        `doActualMutate ${printGraphQLDocument(mutateOptions.mutation)}`,
      );
      await retry({
        command: async () => {
          result =
            await this.pipelineManager.apolloClient.mutate(localMutateOptions);
        },

        // Timeout is covered by the link timeout
        timeoutMs: 0,
        functionName: `mutate ${
          mutateContext.stage.fieldNameInfo.fieldName
        } ${PipelineManager.remoteIdString(
          mutateContext.stage.remoteContext,
        )}, batch: ${batchId}`,
      });
    } finally {
      if (suppressLocalNotify) {
        this.pipelineManager.processingSuppressLocalNotifyCount--;
        logger.info(
          `Suppress local notify END: ${this.pipelineManager.processingSuppressLocalNotifyCount}`,
        );
      }
    }

    if (result) {
      const resultKey = stage.fieldNameInfo.fieldName;
      let resultToRecord = {
        [resultKey]: result.data[resultKey],
      };

      if (isBatchMutation) {
        const prevResult =
          this.pipelineExecutor.output[stage.workingProperties._name];
        if (prevResult && prevResult[resultKey]) {
          prevResult[resultKey] = _.concat(
            prevResult[resultKey],
            result.data[resultKey],
          );
          resultToRecord = prevResult;
        }
      }
      this.pipelineExecutor.recordResult(stage, resultToRecord);
      return result;
    }
  };

  public async doBatchMutate(params: IMutateContext) {
    const { stage, mutateOptions, mainVariable, updateType } = params;
    const { pipelineManager } = this.pipelineExecutor;

    const workingProperties =
      stage.workingProperties as IStagePropertiesGraphQLMutation;
    const variables = mutateOptions.variables;
    const startTime = Date.now();
    const notDoneMap = {};
    let timer;

    const concurrency = pipelineManager.getBatchMutateConcurrencyLevel();
    const queue = new PQueue({
      concurrency,
    });

    const { fieldName } = stage.fieldNameInfo;
    let recordsToDo = variables[mainVariable];
    recordsToDo.forEach((r) => (notDoneMap[r.id] = r));
    let actualResponses = [];

    const errors = [];
    let batchCount = 0;
    while (recordsToDo.length) {
      const effectiveVariables = {};
      effectiveVariables[mainVariable] =
        recordsToDo.slice(-GRAPHQL_MULTI_LIMIT);
      recordsToDo = recordsToDo.slice(0, -GRAPHQL_MULTI_LIMIT);
      queue
        .add(async () => {
          const id = effectiveVariables[mainVariable][0].id;
          logger.debug(
            `Start batch: ${id} size: ${queue.size} pending: ${queue.pending} concurrency: ${concurrency} timeout: ${workingProperties.timeBudgetMs}`,
          );
          params.mutateOptions.context =
            pipelineManager.getGraphQLRequestContextBatch(stage, id);
          const batchStartTime = Date.now();
          const actualResponse = await this.doActualMutate({
            mutateContext: params,
            vars: effectiveVariables,
            isBatchMutation: true,
            batchId: id,
          });

          actualResponses = actualResponses.concat(
            actualResponse.data[fieldName],
          );
          effectiveVariables[mainVariable].forEach((r) => {
            delete notDoneMap[r.id];
          });
          remainingBatchCount--;
          if (!false) {
            logger.info(
              `Finished batch: ${id} time: ${
                Date.now() - batchStartTime
              }ms - records: ${
                effectiveVariables[mainVariable].length
              } left do to: ${
                Object.keys(notDoneMap).length
              } batches: ${remainingBatchCount} concurrency: ${concurrency}`,
            );
          }
        })
        .catch((error) => errors.push(error));

      batchCount++;
    }
    let remainingBatchCount = batchCount;

    await queue.onIdle();
    const recordsDone =
      variables[mainVariable].length - Object.keys(notDoneMap).length;
    logger.info(
      `Batch Mutation - finished with queue - records: ${recordsDone} concurrency: ${concurrency}`,
    );

    if (timer) {
      clearTimeout(timer);
    }

    if (errors.length > 0) {
      if (errors.length === 1) {
        throw errors[0];
      }
      const error = new Error(
        'Errors occurred during batch mutation (see errors field)',
      );
      (error as any).errors = errors;
      throw error;
    }

    if (mutateOptions.fetchPolicy !== FETCH_POLICY_NO_CACHE) {
      if (updateType === UpdateType.DELETE) {
        this.deleteObjectsInCache({
          clientManager: this.pipelineManager.clientManager,
          incoming: mutateOptions.optimisticResponse[fieldName],
          fieldName: stage.graphQLField.name.value,
        });
      } else {
        const obj = {};
        mutateOptions.optimisticResponse[fieldName].forEach(
          (i: IHasId) => (obj[i.id] = i),
        );
        actualResponses.forEach(
          (i: IHasId) => (obj[i.id] = Object.assign(obj[i.id], i)),
        );
        const objectsToUpdate = [];
        Object.values(obj).forEach((v) => objectsToUpdate.push(v));

        this.pipelineManager.graphQLManager.updateQueriesInCache({
          incoming: objectsToUpdate,
          cache: this.pipelineManager.apolloClient.cache as InMemoryCache,
          inputRecordSelectionSet: params.inputRecordSelectionSet,
          entityType: stage.entity,
          optimistic: false,
          suppressLocalNotify: stage.suppressLocalNotify,
        });
      }
    }

    // FIXME - move the logQueryTiming when this is done
    logger.info(
      `Batch mutation: ${
        workingProperties._name
      } records: ${recordsDone} batches: ${batchCount} time: ${
        Date.now() - startTime
      }ms`,
    );
  }

  public async doMutate(params: IMutateContext) {
    params.deletedObjects = [];

    const { mutateOptions, mainVariable, stage, updateType } = params;

    if (
      mainVariable &&
      Array.isArray(mutateOptions.variables[mainVariable]) &&
      mutateOptions.variables[mainVariable].length > GRAPHQL_MULTI_LIMIT
    ) {
      await this.doBatchMutate(params);
    } else {
      await this.doActualMutate({
        mutateContext: params,
        vars: mutateOptions.variables,
        optResponse: mutateOptions.optimisticResponse,
      });
    }
    // Cache updates for each mutation (doActualMutate) don't broadcast for delete, so we do it here
    if (updateType === UpdateType.DELETE && !stage.suppressLocalNotify) {
      this.graphQLManager.broadcastCacheWatches();
    }

    const result = stage.result[stage.fieldNameInfo.fieldName];
    logger.debug(result, 'doMutate result');
    if (
      !getTopLevelDirective(stage.getGraphQLOperation(), DIRECTIVE_BULK_UPDATE)
    ) {
      await this.graphQLManager.loadIntoMemorySupport.entityModified({
        input: (Array.isArray(result) ? result : [result]) as IHasId[],
        entityType: stage.entity,
        useTestData: stage.remoteContext.useTestData,
        requestId: this.pipelineExecutor.requestId,
      });
    }
  }

  public subscribeStageForQuery(stage: StageImpl) {
    const { stageQuery: stageQuery } = stage;
    const options = stageQuery.subscribeOptions;

    if (!stageQuery.queryStartTime) {
      stageQuery.queryStartTime = Date.now();
    }

    let {
      sizeClass,
      // eslint-disable-next-line prefer-const
      queryKeyFieldValue,
    } =
      this.pipelineManager.clientManager.entityKeySupport.getBestMatchQueryKeyFieldValue(
        {
          entity: stage.entity,
          requestArgs: stageQuery.args,
        },
      );

    if (!queryKeyFieldValue && !sizeClass) {
      sizeClass = stage.entity.sizeClass;
    }
    if (
      getTopLevelDirective(
        stage.getGraphQLOperation(),
        DIRECTIVE_IGNORE_SIZECLASS,
      )
    ) {
      sizeClass = undefined;
    }
    stageQuery.sizeClass = sizeClass;

    const doQueryForChunk = (chunkId) => {
      stageQuery.graphqlQueriesByChunk[chunkId] = fixListQueryForChunk(
        stage,
        chunkId,
      );
      const watchOptions = {
        ...options,
        query: stageQuery.graphqlQueriesByChunk[chunkId],
      };
      logger.debug(`watch ${chunkId}`);
      stageQuery.obsQueries[chunkId] =
        this.pipelineManager.apolloClient.watchQuery(watchOptions);
    };

    if (stageQuery.isListQuery && sizeClass && !stageQuery.args[CHUNK_ID]) {
      for (
        let chunkId = 0;
        chunkId < sizeClassProperties[sizeClass].numberOfBuckets;
        chunkId++
      ) {
        doQueryForChunk(chunkId);
      }
    } else {
      stageQuery.graphqlQueriesByChunk[0] = stage.graphQLDocument;
      stageQuery.obsQueries[0] = this.pipelineManager.apolloClient.watchQuery({
        ...options,
      });
    }

    const obsSubscriptions = [];

    try {
      Object.keys(stageQuery.obsQueries).forEach((chunkId: string) => {
        const observable = stageQuery.obsQueries[chunkId];
        stage.stageQuery.obsQuerySubscriptionHandlers[chunkId] =
          this.createSubscriptionHandler(stage, parseInt(chunkId, 10));
        const result = observable.subscribe(
          stage.stageQuery.obsQuerySubscriptionHandlers[chunkId],
        );
        obsSubscriptions.push(result);
      });
    } catch (error) {
      reThrow({ logger, message: 'Problem making subscription', error });
    }

    if (
      options.fetchPolicy !== FETCH_POLICY_NO_CACHE &&
      options.fetchPolicy !== 'cache-only' &&
      this.pipelineManager.enableNetworkSubscriptions
    ) {
      this.graphQLManager.hookupNetworkProcessor(stage.entity);
    }

    // Stage could have finished in the subscribe call
    if (!stage.closed) {
      stageQuery.obsQuerySubscriptionHandles = [
        ...stageQuery.obsQuerySubscriptionHandles,
        ...obsSubscriptions,
      ];
    }
  }

  private async handleSubscriptionNotification(params: {
    stage: StageImpl;
    handlerChunkId: number;
    queryResultInput: ApolloQueryResult<any>;
  }) {
    const { stage, handlerChunkId, queryResultInput } = params;
    if (stage.closed) {
      return;
    }

    if (
      this.pipelineManager.isSuppressingLocalNotifyQueries(
        stage.stageQuery.queryId,
      )
    ) {
      logger.debug('subscriptionHandler - ignoring notification');
      return;
    }

    if (!queryResultInput.data) {
      logger.debug('subscriptionHandler - ignoring empty result');
      return;
    }

    const { stageQuery } = stage;
    const { fieldName } = stage.fieldNameInfo;
    const queryResult = _.cloneDeep(queryResultInput);

    if (queryResult.partial) {
      // This can be caused by a mutation that updates a query where an associated entity is updated or
      // added, and that associated entity is not present in the cache. The only choice is to refetch the query
      // which will provide a notification when that's done.
      logger.info(
        queryResult,
        `subscriptionHandler - partial result refetching: ${stage.traceId()} (chunk ${handlerChunkId}): ${printGraphQLDocument(
          stage.graphQLDocument,
        )}`,
      );

      // Bug catcher
      if (!stageQuery.obsQueries[handlerChunkId]) {
        throw new Error(
          `Missing obs query for ${handlerChunkId} in ${stage.traceId()}`,
        );
      }
      const nextToken = queryResult.data?.[fieldName]?.[NEXT_TOKEN];
      if (nextToken) {
        logQueryTiming.info(
          `subscriptionHandler - partial result refetch nextToken: ${nextToken}`,
        );
        void this.doFetchMore({
          stage,
          nextToken,
          chunkId: handlerChunkId,
          executionIncarnation: stage.executor.executionIncarnation,
        });
      } else {
        void stageQuery.obsQueries[handlerChunkId].refetch();
      }
      return;
    }

    logger.trace(
      queryResult,
      `subscriptionHandler: ${handlerChunkId} ${stage.traceId()}`,
    );

    // loading happens when a query begins and the cache has complete data
    if (
      (queryResult.networkStatus !== NetworkStatus.ready &&
        queryResult.networkStatus !== NetworkStatus.loading) ||
      !queryResult.data
    ) {
      logger.debug(
        `subscriptionHandler network status: ${queryResult.networkStatus}/no data - ignoring`,
      );
      return;
    }

    const data = queryResult.data[fieldName];

    // Sadly the Apollo client can't handle scalar values in the client
    // https://github.com/apollographql/apollo-feature-requests/issues/368
    // So we have to fix them up here. This does it in place
    this.fixupFromNetwork({
      data: stageQuery.isListQuery ? data.items : data,
      stage,
      typeDefName: stage.typeDef.id,
      parentType: stage.typeDef,
    });

    this.pipelineExecutor.noMorePages = false;

    if (!stageQuery.isListQuery) {
      return handleQueryReturn({ stage, queryResult });
    }

    // FIXME2 - what about when the count comes from the cache, we probably should only
    // allow the count in the no-cache case.
    if (stageQuery.isConnectionCount) {
      if (data[NEXT_TOKEN]) {
        throw new Error('Unexpected nextToken in response to a count query');
      }
      stageQuery.cumCount += data[CONNECTION_COUNT];
      stageQuery.chunkDone[handlerChunkId] = true;
      return handleQueryReturn({ stage, queryResult });
    } else if (stage.workingProperties._paged) {
      stageQuery.cumQueryCount += data.items.length;
    }

    const items = data.items;
    let chunkId = data[CHUNK_ID];
    const hasChunkId = !isNullOrUndefined(chunkId);
    if (!hasChunkId) {
      chunkId = 0;
    }
    if (handlerChunkId !== 0 && chunkId !== handlerChunkId) {
      logger.error(
        queryResult,
        `Bug - handler chunkId ${handlerChunkId} !== message ${chunkId}, ${stage.toString()}`,
      );
      throw new Error(
        `Bug - handler chunkId ${handlerChunkId} !== message ${chunkId}`,
      );
    }

    if (logBatching.level === LogLevels.Debug) {
      traceListResult({
        logger: logBatching,
        message: `subscriptionHandler: ${stage.traceId()}`,
        fieldName,
        operationName: stageQuery.queryName,
        queryConnectionResult: data,
      });
    }

    // Getting new data on a chunk that's previously done, discard what was there before.
    // This happens if a query is updated by a mutation
    if (stageQuery.chunkDone[handlerChunkId]) {
      stageQuery.chunkDone[handlerChunkId] = false;
      delete stageQuery.cumChunkedQueryResult[handlerChunkId];
    }
    // Generally this us used to accumulate the nextToken responses for a query
    stageQuery.cumChunkedQueryResult[handlerChunkId] = stageQuery
      .cumChunkedQueryResult[handlerChunkId]
      ? [...stageQuery.cumChunkedQueryResult[handlerChunkId], ...items]
      : items;
    stageQuery.pageCount++;
    const nextToken = data.nextToken;
    if (!nextToken || stage.workingProperties._paged) {
      stageQuery.chunkDone[handlerChunkId] = true;
    }

    this.pushNextPage(
      !exists(stageQuery.limitArgumentValue) ||
        stageQuery.cumQueryCount < stageQuery.limitArgumentValue
        ? nextToken
        : null,
      hasChunkId,
      chunkId,
    );
    if (hasChunkId) {
      logQueryTiming.debug(
        {
          ...PipelineManager.remoteIdObject(stage.remoteContext),
          itemsLength: items.length,
          handlerChunkId,
          nextToken,
        },
        'Got result chunk',
      );
    }

    if (
      this.canHazFetchMore(
        stageQuery,
        stageQuery.cumChunkedQueryResult[handlerChunkId],
        nextToken,
      )
    ) {
      logQueryTiming.debug(
        `Query intermediate: records: ${items.length} nextToken: ${nextToken}`,
      );
      void this.doFetchMore({
        stage,
        nextToken,
        chunkId: handlerChunkId,
        executionIncarnation: stage.executor.executionIncarnation,
      });
      return;
    }

    await handleQueryReturn({ stage, queryResult });
  }

  public createSubscriptionHandler(stage: StageImpl, handlerChunkId: number) {
    const handler = {
      stage,
      handlerChunkId,
      stageQuery: stage.stageQuery,
      next: async (queryResultInput: ApolloQueryResult<any>) => {
        await this.handleSubscriptionNotification({
          stage,
          handlerChunkId,
          queryResultInput,
        });
      },
      complete: () => {
        // Nothing
      },
      error: async (error) => {
        analyzeError({ error });
        if (error.message.includes('Function not found:')) {
          const { clientManager } = this.pipelineManager;
          logger.warn(
            `Lambda function for stack is gone, stack is likely deleted ${clientManager.stackId}`,
          );
          clientManager.killMe();
        }
        await handleQueryReturn({ stage, error });
      },
    };
    return handler;
  }

  public canHazFetchMore(
    stageQuery: StageQuery,
    items: any,
    nextToken: string,
  ): boolean {
    const { limitArgumentValue } = stageQuery;
    if (stageQuery.stage.workingProperties._paged) {
      return false;
    }
    return !!(
      (limitArgumentValue > 0 ? items.length < limitArgumentValue : true) &&
      nextToken
    );
  }

  public async doFetchMore(params: {
    stage: StageImpl;
    nextToken: any;
    chunkId: number;
    executionIncarnation: number;
  }) {
    const { stage, nextToken, chunkId, executionIncarnation } = params;

    logger.debug(
      `doFetchMore start - stage: ${stage.executor.getTracingInfo()}  ex inc: ${executionIncarnation} chunkId: ${chunkId} next: ${nextToken}`,
    );
    const { stageQuery: stageQuery } = stage;
    if (stage.closed) {
      return;
    }
    if (stage.executor.executionIncarnation !== executionIncarnation) {
      logger.debug(`doFetchMore STALE - incarnation: ${executionIncarnation}`);
      return;
    }

    try {
      const queryResult: ApolloQueryResult<{
        [fieldName: string]: IQueryConnectionResult;
      }> = await retry({
        command: async () =>
          stageQuery.obsQueries[chunkId].fetchMore({
            variables: { nextToken },
            updateQuery:
              stageQuery.subscribeOptions.fetchPolicy === FETCH_POLICY_NO_CACHE
                ? () => undefined
                : undefined,
          }),
      });

      const resultData = queryResult.data[stage.fieldNameInfo.fieldName];

      if (stage.executor.executionIncarnation !== executionIncarnation) {
        logger.debug(
          `doFetchMore STALE - incarnation: ${executionIncarnation}`,
        );
        return;
      }

      const newNextToken = resultData[NEXT_TOKEN];
      logger.debug(
        `doFetchMore after - length: ${resultData.items.length} chunk: ${chunkId} newNextToken: ${newNextToken}`,
      );

      this.pushNextPage(newNextToken, !isNullOrUndefined(chunkId), chunkId);
      stage.stageQuery.obsQuerySubscriptionHandlers[chunkId].next(queryResult);
    } catch (error) {
      reThrow({
        logger,
        message: `Problem with fetchMore - stage: ${stage.executor.getTracingInfo()}  ex inc: ${executionIncarnation} chunkId: ${chunkId} next: ${nextToken}`,
        error,
      });
    }
  }

  private pushNextPage(
    nextToken: string,
    hasChunkId: boolean,
    chunkId: number,
  ) {
    const nextPage: IPage = { [NEXT_TOKEN]: nextToken };
    if (hasChunkId) {
      nextPage[CHUNK_ID] = chunkId;
    }
    // FIXME2 this does not handle size classes correctly
    this.pipelineExecutor.pushNextPage(nextToken ? nextPage : null);
  }

  private deleteObjectsInCache(params: {
    clientManager: ClientManager;
    incoming: IHasId[];
    fieldName: string;
  }) {
    const { clientManager, incoming, fieldName } = params;
    const fieldInfo = getFieldNameInfo(fieldName);
    const counters = clientManager.pipelineManager.graphQLManager.getCounters(
      fieldInfo.entityId,
    );
    counters.cacheDeletes += incoming.length;
    this.graphQLManager.evictFromCache(incoming, false);
  }

  private fixupFromNetwork(params: {
    data: any;
    stage: StageImpl;
    typeDefName: string;
    parentType: TypeDefinition;
  }) {
    const { data, stage, typeDefName, parentType } = params;
    if (!data) {
      return;
    }
    if (Array.isArray(data)) {
      data.forEach((m) => this.fixupFromNetwork({ ...params, data: m }));
      return;
    }

    const typeDef =
      this.pipelineExecutor.pipelineManager.schemaManager.getTypeDefinition({
        name: typeDefName,
        parentRecord: parentType,
      });
    for (const attr of typeDef.getAttributes()) {
      const objectField = data[attr.name];
      if (!objectField) {
        continue;
      }
      if (attr.itemInfo.type === BasicType.Object) {
        // See apolloHandler - objectScalarType for the serialization
        if (attr.itemInfo.collectionType === CollectionTypes.ARRAY) {
          data[attr.name] = data[attr.name].map((m) =>
            typeof m === 'string' ? JSON.parse(m) : m,
          );
        } else {
          data[attr.name] =
            typeof objectField === 'string'
              ? JSON.parse(objectField)
              : objectField;
        }
        continue;
      }
      if (typeof objectField === 'object') {
        if (
          this.pipelineExecutor.pipelineManager.metadataSupport.isObjectType({
            itemInfo: attr.itemInfo,
            parentType,
          })
        ) {
          this.fixupFromNetwork({
            data: objectField,
            stage,
            typeDefName: attr.itemInfo.type,
            parentType: typeDef,
          });
        }
      }
    }
  }
}

export function readQueryRoot(params: {
  cache: ApolloCache<any>;
  id: string;
  query: DocumentNode;
  fieldName: string;
  optimistic?: boolean;
  returnPartialData?: boolean;
  listQuery?: boolean;
}): {
  itemsObj: { [id: string]: IHasIdAndTypeName };
  // Returned only for a list query
  queryConnectionResult: IListQueryCacheEntry;
} {
  const {
    cache,
    id,
    query,
    fieldName,
    optimistic,
    returnPartialData,
    listQuery,
  } = params;
  let readQueryResult;
  const queryResult: { [id: string]: IHasIdAndTypeName } = {};
  let cacheResult: IListQueryCacheEntry;

  const PROVIDE_MISS_TRACING = true;
  if (!PROVIDE_MISS_TRACING) {
    // FIXME2 - this option does not seem to work (the query tests don't pass)
    readQueryResult = cache.readQuery(
      {
        id,
        query,
        returnPartialData,
      },
      optimistic,
    );

    if (!readQueryResult?.[fieldName]) {
      return;
    }
  } else {
    // Use this internal API because we want to understand why things are missing from
    // the store for diagnostic purposes. Other than that they are the same.
    const cacheAny = cache as any;
    const store = optimistic ? cacheAny.optimisticData : cacheAny.data;
    const diffResult = cacheAny.storeReader.diffQueryAgainstStore({
      store,
      query,
      rootId: id,
      config: cacheAny.config,
      returnPartialData: true,
    });
    let missing: boolean;
    if (diffResult.missing?.length > 0) {
      const missingArray = diffResult.missing.map((m) => ({
        message: m.message,
        path: m.path,
      }));
      if (
        !(
          missingArray.length === 1 &&
          missingArray[0].message.includes(ROOT_QUERY) &&
          Object.keys(diffResult?.result).length <= 1
        )
      ) {
        logger.debug(
          `readQuery - Missing fields length: ${missingArray.length}`,
        );
      }
      missing = missingArray.length > 0;
    }

    if (
      Object.keys(diffResult?.result).length > 0 &&
      (returnPartialData || !missing)
    ) {
      readQueryResult = diffResult.result;
    }
  }

  if (readQueryResult?.[fieldName]) {
    if (listQuery) {
      const localQueryResult: IQueryConnectionResult = readQueryResult;
      cacheResult = localQueryResult[fieldName];
      if (cacheResult) {
        const items = localQueryResult[fieldName].items;
        if (items) {
          for (const obj of items) {
            queryResult[obj.id] = obj;
          }
        }
      }
    } else {
      queryResult[readQueryResult[fieldName].id] = readQueryResult[fieldName];
    }
  }

  return { itemsObj: queryResult, queryConnectionResult: cacheResult };
}

function fixListQueryForChunk(stage: StageImpl, chunkId: number) {
  // Add the chunkId field in selection set
  let editedAst = visit(stage.graphQLDocument, {
    SelectionSet: {
      leave(node, key, parent, path, ancestors) {
        const maybeAddChunkId = () => {
          if (
            node.selections.find((s) => {
              return (s as FieldNode).name.value === CHUNK_ID;
            })
          ) {
            return undefined;
          }
          const fieldChunkId = {
            kind: 'Field',
            directives: [],
            name: { kind: 'Name', value: CHUNK_ID },
          };

          return {
            ...node,
            selections: [...node.selections, fieldChunkId],
          };
        };

        if (
          ancestors.length >= 5 &&
          isInOperation(ancestors) &&
          (ancestors[3] as SelectionSetNode).kind === 'SelectionSet'
        ) {
          if (ancestors.length === 5) {
            return maybeAddChunkId();
          }
          if (
            ancestors.length === 8 &&
            (ancestors[6] as SelectionSetNode).kind === 'SelectionSet'
          ) {
            return maybeAddChunkId();
          }
        }
      },
    },
  });

  let doBreak = false;

  // Add the argument for the chunkId with the specified value
  editedAst = visit(editedAst, {
    Field: {
      enter(node, key, parent, path, ancestors) {
        if (ancestors.length > 4 || !isInOperation(ancestors)) {
          return false;
        }
        if (doBreak) {
          return BREAK;
        }
        return undefined;
      },
      leave(node, key, parent, path, ancestors) {
        if (doBreak || ancestors.length !== 4 || !isInOperation(ancestors)) {
          return undefined;
        }
        const args = [];

        if (
          !(node as FieldNode).arguments.find((s) => {
            return s.name.value === CHUNK_ID;
          })
        ) {
          args.push({
            kind: 'Argument',
            name: { kind: 'Name', value: CHUNK_ID },
            value: {
              kind: 'IntValue',
              value: chunkId,
            },
          });
        }

        doBreak = true;
        const retVal = {
          ...node,
          arguments: [...(node as FieldNode).arguments, ...args],
        };
        return retVal;
      },
    },
  });

  return editedAst;
}
