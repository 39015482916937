import { CircularProgress, Typography } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import withStyles from '@mui/styles/withStyles';
import { Component } from 'react';
import { createBranch, getBranchNames } from 'universal/common/github';
import { getErrorString } from 'universal/errors/errorString';
import { loadConfiguration } from 'universal/loadStore/load';
import { APP_DEFS_DIR } from 'universal/loadStore/loadstore';
import { storeConfiguration } from 'universal/loadStore/store';

import CustomButton from '../atoms/CustomButton';
import CustomSelect from '../atoms/CustomSelect';
import CustomTextField from '../atoms/CustomTextField';

const styles = () =>
  ({
    fabProgress: {
      position: 'absolute',
      top: -5,
      left: -5,
      zIndex: 1,
    },

    buttonWrapper: {
      position: 'relative',
    },

    newBranchWrapper: {
      display: 'flex',
      flexDirection: 'row',
    },

    branchSelectWrapper: {
      position: 'relative',
    },

    branchProgress: {
      position: 'absolute',
      top: '0px',
      left: '30px',
      zIndex: 1,
    },
  }) as const;

class LoadSaveRow extends Component {
  public declare props;
  public declare state;

  constructor(props) {
    super(props);

    this.state = {
      selectedBranchName: '',
      branchNames: ['loading...'],
      loadingBranches: true,
      rowLoading: false,
      rowSaving: false,
      newBranchName: 'new-branch',
      creatingNewBranch: false,
    };

    this.handleBranchSelect = this.handleBranchSelect.bind(this);
    this.getBranches = this.getBranches.bind(this);
    this.handleSaveAllEntities = this.handleSaveAllEntities.bind(this);
    this.handleLoadAllEntities = this.handleLoadAllEntities.bind(this);
    this.createParams = this.createParams.bind(this);
    this.setSavingConfig = this.setSavingConfig.bind(this);
    this.setLoadingConfig = this.setLoadingConfig.bind(this);
    this.handleNewBranchChange = this.handleNewBranchChange.bind(this);
  }

  private static CREATE_BRANCH_MESSAGE = 'Create New Branch';

  public componentDidMount() {
    void this.getBranches().then(() => {
      this.setState({
        loadingBranches: false,
      });
    });
  }

  public setSavingConfig(value) {
    this.props.setSavingConfig(value);
    this.setState({
      rowSaving: value,
    });
  }

  public setLoadingConfig(value) {
    this.props.setLoadingConfig(value);
    this.setState({
      rowLoading: value,
    });
  }

  public createParams(commitMessage) {
    const commitMessageWithSkip = commitMessage
      ? commitMessage.concat(' [ci skip]')
      : '[ci skip]';

    const {
      props: { githubToken, configPath, clientManager },
      state: { creatingNewBranch, selectedBranchName, newBranchName },
    } = this;

    const branchName = creatingNewBranch ? newBranchName : selectedBranchName;

    return {
      commitMessage: commitMessageWithSkip,
      githubToken,
      branchName,
      path: configPath,
      clientManager,
    };
  }

  public handleBranchSelect(selectedBranchName) {
    if (selectedBranchName === LoadSaveRow.CREATE_BRANCH_MESSAGE) {
      this.setState({ creatingNewBranch: true });
    } else {
      this.setState({ selectedBranchName });
    }
  }

  public async getBranches() {
    const {
      props: { githubToken, buildBranch },
    } = this;

    const githubParams = {
      githubToken,
    };

    const branchNames = await getBranchNames(githubParams);

    const selectedBranchName = branchNames.includes(buildBranch)
      ? buildBranch
      : 'main';

    this.setState({
      branchNames,
      selectedBranchName,
    });
  }

  public handleNewBranchChange(newBranchName) {
    this.setState({ newBranchName });
  }

  public async handleSaveAllEntities() {
    const {
      props: { commitMessage, setError, githubToken, clientManager },
      state: { creatingNewBranch, newBranchName },
    } = this;

    this.setSavingConfig(true);
    setError(null);

    if (creatingNewBranch) {
      const githubParams = {
        githubToken,
        branchName: newBranchName,
      };

      await createBranch(githubParams);
    }

    const params = this.createParams(commitMessage);
    const storeParams = { ...params, clientManager };

    try {
      await storeConfiguration(storeParams);
    } catch (error) {
      const errorMessage = getErrorString(error);
      console.log('error saving entities', errorMessage);
      setError(`error saving entities: ${errorMessage}`);
    }

    this.setSavingConfig(false);
    this.setState({
      creatingNewBranch: false,
    });
  }

  public async handleLoadAllEntities() {
    const { commitMessage, setError, loadWidgetTrees, clientManager } =
      this.props;

    this.setLoadingConfig(true);
    setError(null);

    const params = this.createParams(commitMessage);

    try {
      await loadConfiguration({ ...params, clientManager });
    } catch (error) {
      const errorMessage = getErrorString(error);
      console.log('error loading entities', errorMessage);
      setError(`error loading entities: ${errorMessage}`);
    }

    loadWidgetTrees(true);
    this.setLoadingConfig(false);
  }

  public render() {
    const {
      handleNewBranchChange,
      handleBranchSelect,
      handleLoadAllEntities,
      handleSaveAllEntities,
      props: {
        index,
        configPath,
        loadingConfig,
        savingConfig,
        classes: {
          item,
          buttonWrapper,
          fabProgress,
          newBranchWrapper,
          branchSelectWrapper,
          branchProgress,
        },
      },
      state: {
        branchNames,
        selectedBranchName,
        rowLoading,
        rowSaving,
        newBranchName,
        creatingNewBranch,
        loadingBranches,
      },
    } = this;

    const { CREATE_BRANCH_MESSAGE } = LoadSaveRow;

    const items = [
      <TableCell key="path">
        <Typography className={item}>{configPath}</Typography>
      </TableCell>,
    ];

    if (creatingNewBranch) {
      const createBranchInput = (
        <TableCell className={newBranchWrapper} key="createBranch">
          <CustomTextField
            value={newBranchName}
            onChange={handleNewBranchChange}
            disabled={rowLoading || rowSaving}
          />
          <CustomButton
            icon="cancel"
            onClick={() =>
              this.setState({
                creatingNewBranch: false,
              })
            }
            disabled={rowLoading || rowSaving}
          />
        </TableCell>
      );

      items.push(createBranchInput);
    } else {
      const branchSelect = (
        <TableCell className={branchSelectWrapper} key="branchSelect">
          <CustomSelect
            style={{ 'min-width': '200px' }}
            options={[CREATE_BRANCH_MESSAGE, ...branchNames]}
            value={selectedBranchName}
            onChange={handleBranchSelect}
            disabled={loadingBranches || rowLoading || rowSaving}
          />
          {loadingBranches && (
            <CircularProgress size={40} className={branchProgress} />
          )}
        </TableCell>
      );

      items.push(branchSelect);
    }

    const loadButton = !creatingNewBranch ? (
      <div className={buttonWrapper}>
        <CustomButton
          onClick={handleLoadAllEntities}
          identifier={index}
          icon="cloud_upload"
          disabled={loadingConfig || savingConfig || loadingBranches}
        />
        {rowLoading && <CircularProgress size={58} className={fabProgress} />}
      </div>
    ) : (
      <CustomButton icon="cancel" disabled={true} />
    );

    const showStoreButton = configPath && configPath.slice(-7) === APP_DEFS_DIR;

    const canStore =
      showStoreButton &&
      ((selectedBranchName && selectedBranchName !== 'main') ||
        (creatingNewBranch && newBranchName));

    const storeButton = canStore ? (
      <div className={buttonWrapper}>
        <CustomButton
          onClick={handleSaveAllEntities}
          identifier={index}
          icon="save"
          disabled={loadingConfig || savingConfig || loadingBranches}
        />
        {rowSaving && <CircularProgress size={58} className={fabProgress} />}
      </div>
    ) : (
      <CustomButton icon="cancel" disabled={true} />
    );

    const buttons = [
      <TableCell key="load-button">{loadButton}</TableCell>,
      <TableCell key="save-button">{storeButton}</TableCell>,
    ];

    items.push(...buttons);

    return <TableRow>{items}</TableRow>;
  }
}

export default withStyles(styles, { withTheme: true })(LoadSaveRow);
