import _ from 'lodash';
import { v1 as uuid } from 'uuid';

import { ClientManager } from './clientManager';
import { SYSTEM } from './common/commonConstants';
import {
  CODE,
  CODE_MEMBERSHIP,
  CONFIG_SEPARATOR,
} from './metadataSupportConstants';
import { manglePreservingUniqueness } from './utilityFunctions';

export class CodeSupport {
  public clientManager: ClientManager;

  private static readonly CODE_SEPARATOR = '-';

  public initialize(clientManager: ClientManager) {
    this.clientManager = clientManager;
  }

  private async lookupMember(lookCode: string): Promise<any[]> {
    const members =
      await this.clientManager.pipelineManager.executeGraphqlQuery({
        query: `query { listCodeMembership(memberCode: "${lookCode}") { items {
          id memberCode { id } parentCode { id } exclude
        } } }`,
      });
    return members.filter(({ exclude }) => !exclude);
  }

  private async getChildren(lookCode: string): Promise<any[]> {
    const children =
      await this.clientManager.pipelineManager.executeGraphqlQuery({
        query: `query { listCodeMembership(parentCode: "${lookCode}") { items {
          id memberCode { id } parentCode { id } exclude
        } } }`,
      });
    return children.filter(({ exclude }) => !exclude);
  }

  private async getParentsOfChild(lookCode: string): Promise<any[]> {
    const parents =
      await this.clientManager.pipelineManager.executeGraphqlQuery({
        query: `query { listCodeMembership(memberCode: "${lookCode}") { items {
          id memberCode { id } parentCode { id } exclude
        } } }`,
      });
    return parents.filter(({ exclude }) => !exclude);
  }

  private async getExcludedChildren(parentCode: string): Promise<any[]> {
    const children =
      await this.clientManager.pipelineManager.executeGraphqlQuery({
        query: `query { listCodeMembership(parentCode: "${parentCode}") { items {
          id memberCode { id } parentCode { id } exclude
        } } }`,
      });
    return children.filter(({ exclude }) => exclude);
  }

  private async codeMemberOfInternal(
    code: string,
    ancestor: string,
    seenParents: any,
  ): Promise<boolean> {
    const members = await this.lookupMember(code);
    members.forEach((m) => {
      if (m.parentCode) {
        seenParents[m.parentCode.id] = true;
      }
    });
    const found = members.find((m) => m.parentCode?.id === ancestor);
    if (found) {
      return true;
    }
    for (const member of members) {
      if (
        member.parentCode &&
        (await this.codeMemberOfInternal(
          member.parentCode.id,
          ancestor,
          seenParents,
        ))
      ) {
        return true;
      }
    }
    return false;
  }

  /**
   * Returns true if the specified code is a descendent member of the ancestor.
   *
   * @param code - Code to check for membership
   * @param ancestor - Ancestor to check
   */
  public async codeMemberOf(code: string, ancestor: string): Promise<boolean> {
    const seenParents = {};
    const retVal = await this.codeMemberOfInternal(code, ancestor, seenParents);
    if (seenParents[ancestor] && retVal) {
      const excludedChildren = await this.getExcludedChildren(ancestor);

      return !excludedChildren
        .map(({ memberCode }) => memberCode.id)
        .includes(code);
    }
    return false;
  }

  /**
   * Returns any of the (direct) children of the ancestor that the specified
   * code is a member of.
   *
   * @param code - Code to check for membership.
   * @param ancestor - Parent of the code values to be returned.
   */
  public async getCodeMembersOf(
    code: string,
    ancestor: string,
  ): Promise<string[]> {
    const foundMembers = [];

    // We want to know which of these the code is a member of
    const children = await this.getChildren(ancestor);
    for (const child of children) {
      if (
        child.memberCode &&
        (await this.codeMemberOf(code, child.memberCode.id))
      ) {
        foundMembers.push(child);
      }
    }
    return foundMembers.map((m) => m.memberCode?.id);
  }

  /**
   * Returns any of the (direct) parents of the child that the specified code is
   * a member of.
   *
   * @param code - Code to check for membership.
   * @param ancestor - Parent of the code values to be returned.
   */
  public async getParents(child: string): Promise<string[]> {
    // We want to know which of these the code is a member of
    const parentRecords = await this.getParentsOfChild(child);
    return parentRecords.map((p) => p.parentCode.id);
  }

  public static getCodeTypeFromId(id: string) {
    const idArray = id.split(CodeSupport.CODE_SEPARATOR);
    if (idArray.length < 2) {
      throw new Error(
        `Attempting to get code type on an id without a code type: ${id}`,
      );
    }
    return idArray[0];
  }

  public static makeIdWithCodeType(code: string, codeType: string) {
    return `${codeType}${
      CodeSupport.CODE_SEPARATOR
    }${manglePreservingUniqueness(code)}`;
  }
  //FIXME below function required because we can't see Classes in app pipelines
  public makeIdWithCodeTypeFunction(code: string, codeType: string) {
    return CodeSupport.makeIdWithCodeType(code, codeType);
  }

  public async getCodesFromType(
    codeType: string,
    returnAsObject?: boolean,
  ): Promise<any[] | { [code: string]: any }> {
    let codes = await this.clientManager.pipelineManager.executeGraphqlQuery({
      query: `query { listCode (codeType:"${codeType}") { items { id code description sortOrder displayColor icon } } }`,
    });
    codes = _.sortBy(codes, 'sortOrder', 'code');
    if (returnAsObject) {
      const retObject = {};
      codes.forEach((c) => (retObject[c.id] = c));
      return retObject;
    }
    return codes;
  }

  public async addCode(params: {
    codeType: string;
    codeFields: { code: string };
  }): Promise<string> {
    const { codeType, codeFields } = params;
    const id = CodeSupport.makeIdWithCodeType(codeFields.code, codeType);
    const code = {
      id,
      codeType,
      ...codeFields,
    };
    await this.clientManager.pipelineManager.createRecord({
      entityName: `${SYSTEM}${CONFIG_SEPARATOR}${CODE}`,
      record: code,
    });
    return id;
  }

  public async addCodeMembership(params: {
    parentCodeType?: string;
    parentCode: string;
    memberCodeType?: string;
    memberCode: string;
  }) {
    const { parentCodeType, parentCode, memberCodeType, memberCode } = params;
    const parentCodeId = parentCodeType
      ? CodeSupport.makeIdWithCodeType(parentCode, parentCodeType)
      : parentCode;
    const memberCodeId = memberCodeType
      ? CodeSupport.makeIdWithCodeType(memberCode, memberCodeType)
      : memberCode;
    const children =
      await this.clientManager.pipelineManager.executeGraphqlQuery({
        query: `query { listCodeMembership(parentCode: "${parentCodeId}") { items {
           memberCode { id }
        } } }`,
      });
    if (!children?.find((child) => child.memberCode.id === memberCode)) {
      await this.clientManager.pipelineManager.createRecord({
        entityName: `${SYSTEM}${CONFIG_SEPARATOR}${CODE_MEMBERSHIP}`,
        record: {
          id: uuid(),
          parentCode: parentCodeId,
          memberCode: memberCodeId,
        },
      });
    }
  }

  public async deleteCode(params: {
    codeType?: string;
    code?: string;
    id?: string;
  }) {
    const { codeType, code, id } = params;
    const codeId = id ? id : CodeSupport.makeIdWithCodeType(code, codeType);
    await this.clientManager.pipelineManager.deleteRecord({
      entityName: `${SYSTEM}${CONFIG_SEPARATOR}${CODE}`,
      id: codeId,
    });
  }
}
