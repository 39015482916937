// Auth0 stuff that does not depend on any auth0 server code

import { COMPANY_DOMAIN, COMPANY_NAME } from './common/commonConstants';

export const PERMISSIONS_TEST_USER = `permissionstest@${COMPANY_DOMAIN}`;
export type Auth0TenantType = 'dev' | 'prod';

export function getTestUsername() {
  return PERMISSIONS_TEST_USER;
}

export function getDevRealm() {
  return 'dev';
}

export const devops = 'devops';

export const DEV_DEVOPS_USER = `${devops}@${COMPANY_DOMAIN}`;

export function auth0InfoFromDevopsId(devopsId: string): {
  connectionName: string;
  auth0Tenant: Auth0TenantType;
} {
  const userName = devopsId.split('@')[0];
  if (userName === devops) {
    return { connectionName: getDevRealm(), auth0Tenant: 'dev' };
  } else {
    const userNameArray = userName.split('-');
    return {
      connectionName: userNameArray[1],
      auth0Tenant: userNameArray[2] as Auth0TenantType,
    };
  }
}

export function getAuth0Domain(auth0Tenant: Auth0TenantType) {
  return auth0Tenant === 'dev'
    ? `${COMPANY_NAME}-dev.auth0.com`
    : `${COMPANY_NAME}-prod.us.auth0.com`;
}

export function getAudienceFromDomain(domain: string): string {
  return `${domain.split('.')[0]}.${COMPANY_DOMAIN}`;
}
