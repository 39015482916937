import { Popper, TextField, Tooltip, Typography } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { TypographyVariant } from '@mui/material/styles';
import _ from 'lodash';
import { forwardRef, useMemo } from 'react';

import { IElementAttributes } from '../widgets/types';

export type Option = string | any;

export interface IProps {
  id?: string;

  // Label to show for the field
  name?: string;

  // The field (could be multi-part, like a.b.c) in the options array entry that is to be rendered
  // if the options array entries are objects rather than strings
  optionLabelField?: string;

  // Array of objects to select from
  options: Array<Option>;

  // Allow a null selection, and show this value if it's null
  nullOption?: string;

  // Allows any text to be entered
  freeSolo?: boolean;

  // Currently selected option
  value: Option;

  // Text to show at the bottom of the field
  helperText?: string;

  fontSize?: TypographyVariant;
  style?: any;

  error?: boolean;

  // Passed on the onChange event
  identifier?: any;

  // Render the input field, but have it read only
  readOnly?: boolean;

  tooltip?: string;

  disabled?: boolean;
  required?: boolean;
  open?: boolean;
  onChange: (value: Option, identifier: any) => void;
  elementAttributes?: IElementAttributes;
}

// Options we give to Autocomplete
interface IOptionItem {
  label: string;
  value: Option;
}

function CustomSelect(props: IProps) {
  const {
    value,
    options,
    name,
    style,
    fontSize,
    error,
    helperText,
    optionLabelField,
    nullOption,
    disabled,
    freeSolo,
    readOnly,
    required,
    onChange,
    identifier,
    elementAttributes,
    tooltip,
    id,
  } = props;

  const optionItems = useMemo<IOptionItem[]>(() => {
    let workingOptionItems: Array<IOptionItem>;
    if (options?.length) {
      workingOptionItems = options.map((option) => {
        let displayOption = optionLabelField
          ? _.get(option, optionLabelField)
          : option;
        if (displayOption === undefined || displayOption === null) {
          displayOption = `!!! Missing value for ${JSON.stringify(
            option,
          )} accessor ${optionLabelField}`;
        }
        return { label: displayOption.toString(), value: option };
      });
    } else {
      workingOptionItems = [{ label: '!!!No options', value: null }];
    }

    if (nullOption) {
      workingOptionItems = [{ label: nullOption, value: null }].concat(
        workingOptionItems,
      );
    }
    return workingOptionItems;
  }, [nullOption, optionLabelField, options]);

  const foundRenderValue = optionItems.find((i) => i.value === value);
  let renderValue = foundRenderValue;
  if (freeSolo && foundRenderValue === undefined && typeof value === 'string') {
    renderValue = { label: value, value };
  }

  if (readOnly) {
    return (
      <TextField
        {...(id && { id })}
        {...(elementAttributes && elementAttributes)}
        InputProps={{
          readOnly: true,
        }}
        label={name}
        error={error}
        disabled={disabled}
        required={required}
        helperText={helperText}
        // Not sure why 'value' is required here, since it's not in the documentation, but this
        // addresses WORM-2961
        value={renderValue?.label}
        variant="standard"
        defaultValue={renderValue?.label}
      />
    );
  }

  const PopperAutocomplete = forwardRef((popperProps, ref) => (
    <Popper
      open={true}
      {...popperProps}
      style={{ maxWidth: 'fit-content' }}
      placement="bottom-start"
      ref={ref as any}
    />
  ));

  const component = (
    <Autocomplete
      {...(id && { id })}
      {...(elementAttributes && elementAttributes)}
      autoSelect={true}
      open={props.open}
      options={optionItems}
      PopperComponent={PopperAutocomplete}
      disableClearable={!freeSolo}
      disableCloseOnSelect={false}
      forcePopupIcon={true}
      freeSolo={freeSolo}
      // undefined makes it an uncontrolled component, we don't want that
      value={renderValue === undefined ? null : renderValue}
      getOptionLabel={(option) =>
        typeof option === 'string' ? option : option.label
      }
      style={style}
      disabled={disabled}
      renderOption={(propsParam, option) => (
        <Typography {...propsParam} variant={fontSize}>
          {typeof option === 'string' ? option : option.label}
        </Typography>
      )}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            variant="standard"
            value={renderValue?.label}
            label={name}
            error={error}
            required={required}
            helperText={helperText}
          />
        );
      }}
      onChange={(event, newValue, reason) => {
        switch (reason) {
          case 'blur':
          case 'clear':
          case 'createOption':
          case 'selectOption':
            if (typeof newValue === 'string') {
              // freeSolo case
              onChange(newValue, identifier);
            } else {
              onChange((newValue as IOptionItem)?.value, identifier);
            }
            event.stopPropagation();
            event.preventDefault();
            break;
        }
      }}
    />
  );

  if (tooltip) {
    return <Tooltip title={tooltip}>{component}</Tooltip>;
  }
  return component;
}

export default CustomSelect;
