import Typography, { TypographyProps } from '@mui/material/Typography';
import { Component } from 'react';

interface IProps {
  message?: string;
  variant?: TypographyProps['variant'];
  children: any;
}

interface IState {
  hasError: boolean;
}

export default class ErrorBoundary extends Component<IProps, IState> {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  public componentDidCatch(error, info) {
    this.setState({ hasError: true });
    console.log('ErrorBoundary caught error:', error);
    console.log('Info:', info);
  }

  public render() {
    const { message = 'Something went wrong', variant = 'h3' } = this.props;
    if (this.state.hasError) {
      return <Typography variant={variant}>{message}</Typography>;
    }
    return this.props.children;
  }
}
