import _ from 'lodash';
import { ClientManager } from 'universal/clientManager';
import { reThrow } from 'universal/errors/errorLog';
import { getErrorString } from 'universal/errors/errorString';
import { getLogger, Loggers } from 'universal/loggerSupport';
import { MetadataSupport } from 'universal/metadataSupport';
import { CONFIG_SEPARATOR } from 'universal/metadataSupportConstants';
import { stagePropertyConfiguration } from 'universal/pipeline/stageTypes/stageInfos';
import {
  IPropertyConfiguration,
  PropertyConfigurationHandler,
} from 'universal/propertySupport';
import { stringifyPretty } from 'universal/utilityFunctions';

import { widgetTypes } from '../components/widgetEngine/EventBinding';
import {
  configurationTypeCategories,
  IWidgetTree,
} from '../components/widgets/types';
import {
  configurationTypesDetails,
  widgetPropertyConfiguration,
  widgetTreeTransforms,
} from '../components/widgets/widgetTypes';

import extractSubscribeFields from './extractSubscribeFields';
import { includesAnyOf, setMatchProperties } from './widgetUtilities';

const logger = getLogger({ name: Loggers.CLIENT });

let widgetId = 0;

export function dbToClient(
  trees: IWidgetTree[] | IWidgetTree,
  clientManager,
): IWidgetTree[] {
  const fieldsToIgnore = ['tree', 'parentType', 'subscribeFields'];

  const newTrees = Array.isArray(trees)
    ? trees.map((tree) => {
        const newTree = _.cloneDeep(tree);
        return toClientRecurse(newTree, tree);
      })
    : toClientRecurse(_.cloneDeep(trees), trees);

  function toClientRecurse(subTree, tree) {
    try {
      if (Array.isArray(subTree)) {
        return subTree.map((branch) => toClientRecurse(branch, tree));
      }
      if (typeof subTree === 'object' && subTree !== null) {
        Object.keys(subTree).forEach((key) => {
          if (fieldsToIgnore.includes(key)) {
            return;
          }
          if (configurationTypeCategories[key] && Array.isArray(subTree[key])) {
            subTree[key].forEach((widget) => {
              transformWidgetLoad(widget, tree, clientManager);
              widgetId += 1;
              widget.widgetId = widgetId;
              widget.treeId = tree.id;
              widget.configName = tree.configName;
            });
            // for copy pasting fragments of trees
          } else if (widgetTypes[key]) {
            const widget = subTree[key];
            transformWidgetLoad(widget, tree, clientManager);
            widgetId += 1;
            widget.widgetId = widgetId;
            widget.treeId = tree.id;
            widget.configName = tree.configName;
          }
          subTree[key] = toClientRecurse(subTree[key], tree);
        });
        return subTree;
      }
      if (typeof subTree === 'string') {
        if (subTree === 'NULL') {
          return;
        }
        if (subTree === 'false') {
          return false;
        }
      }
      return subTree;
    } catch (error) {
      reThrow({
        logger,
        error,
        message: `Problem processing widget tree: ${tree.id}`,
      });
    }
  }
  return newTrees;
}

function addDefaultStageProperties(stage): void {
  const propertyHandler = new PropertyConfigurationHandler({
    node: stage,
    propertyConfiguration: stagePropertyConfiguration,
  });

  propertyHandler.addDefaults();
}

function addAllWidgetProperties({
  widget,
  parentType,
}: {
  widget;
  parentType?: string;
}) {
  const propertyHandler = new PropertyConfigurationHandler({
    node: widget,
    propertyConfiguration: widgetPropertyConfiguration,
    parentType,
  });

  propertyHandler.addAllProperties();
}

// will be run against every widget loaded to make any transformations in place
function transformWidgetLoad(
  widget: {
    [key: string]: any;
    properties: { [key: string]: string | boolean | number };
  },
  tree,
  clientManager: ClientManager,
): void {
  try {
    const widgetTransform = widgetTreeTransforms[widget.type];
    const widgetType = configurationTypesDetails[widget.type];
    if (!widgetType) {
      throw new Error(
        `Unable to find widgetType ${widget.type} for: ${stringifyPretty(
          widget,
        )}`,
      );
    }
    const parentType =
      widget.parentType && configurationTypesDetails[widget.parentType];
    const nestedConfigItem = widgetType.nestedConfigItem;

    if (widgetType.events) {
      if (!widget.events) {
        widget.events = [];
      }
      Object.keys(widgetType.events).forEach((eventName) => {
        if (!widget.events.find((event) => event.name === eventName)) {
          widget.events.push({
            name: eventName,
            stages: widgetType.events[eventName],
          });
        }
      });

      widget.events.forEach((event) => {
        event.stages.forEach((stage) => {
          addDefaultStageProperties(stage);
        });
      });
    }

    if (widgetType.category) {
      widget.category = widgetType.category;
    }

    // adds child fields to the widget
    Object.keys(widgetType.childTypes).forEach((childType) => {
      if (!widget[childType]) {
        widget[childType] = [];
      }
      widget[childType].forEach((child) => (child.parentType = widget.type));
    });

    addAllWidgetProperties({ widget });

    // run transform between properties and child properties, because transform
    // may need to add children based on properties that then need to have
    // properties added to
    if (widgetTransform) {
      widgetTransform(widget);
    }

    // adds childProperties to children from configurationTypesDetails
    const widgetDetailChildProperties =
      widgetType && widgetType.childProperties;
    if (widgetDetailChildProperties) {
      Object.keys(configurationTypeCategories).forEach((childCategory) => {
        if (widget[childCategory]) {
          widget[childCategory].forEach((child) => {
            addAllWidgetProperties({
              widget: child,
              parentType: widget.type,
            });

            child.parentProperties = Object.keys(
              widgetDetailChildProperties,
            ).reduce((acc, parentChildPropertyKey) => {
              return {
                ...acc,
                [parentChildPropertyKey]:
                  child.properties[parentChildPropertyKey],
              };
            }, {});
          });
        }
      });
    }

    // property converter
    const convertProperties = false;
    if (convertProperties) {
      const propertyValueReplacer = {
        '{cell': '{_cell',
        '{column': '{_column.label',
        '{row': '{_row',
      };

      const propertyReplacers: {
        [key: string]: any;
      } = [
        {
          replacerKey: 'form',
          replacerValues: [true, 'true'],
          newPropertyKey: 'form',
          newValue: '_self',
        },
        {
          replacerKey: 'inForm',
          replacerValues: [true, 'true'],
          newPropertyKey: 'dataBind',
          newValue: `form.${widget.name}_data`,
        },
      ];

      Object.entries(widget.properties).forEach(
        ([propertyKey, propertyValue]) => {
          Object.entries(propertyValueReplacer).forEach(
            ([replaceBefore, replaceAfter]) => {
              if (
                typeof propertyValue === 'string' &&
                propertyValue.includes(replaceBefore) &&
                !propertyValue.includes(replaceAfter)
              ) {
                const newValue = propertyValue.replace(
                  RegExp(replaceBefore, 'g'),
                  replaceAfter,
                );
                widget.properties[propertyKey] = newValue;
              }
            },
          );

          propertyReplacers.forEach(
            ({ replacerKey, replacerValues, newPropertyKey, newValue }) => {
              if (
                propertyKey === replacerKey &&
                (!replacerValues.length ||
                  replacerValues.includes(propertyValue))
              ) {
                widget.properties[newPropertyKey] = newValue;
              }
            },
          );
        },
      );

      const stringsThatFlagNonStrings = [
        'cell',
        'row',
        'column',
        'readOnly',
        'true',
        'false',
      ];

      const shouldAddQuotes = (propertyKey, propertyValue) => {
        const propertyDefinition =
          widgetType.properties[propertyKey] ||
          parentType?.childProperties?.[propertyKey];
        return (
          isNaN(propertyValue) &&
          typeof propertyValue === 'string' &&
          !propertyValue.startsWith("'") &&
          !propertyValue.endsWith("'") &&
          propertyDefinition?.pointer !== 'must' &&
          (propertyValue.includes('{{') ||
            !includesAnyOf(propertyValue, stringsThatFlagNonStrings))
        );
      };

      Object.keys(widget.properties).forEach((propertyKey) => {
        const propertyValue = widget.properties[propertyKey];

        if (shouldAddQuotes(propertyKey, propertyValue)) {
          const newPropertyValue = `'${propertyValue}'`;
          widget.properties[propertyKey] = newPropertyValue;
        }
      });
    }
    // end auto converter

    setMatchProperties(widget);

    if (!nestedConfigItem) {
      const { subscribeFields, requiredFields } = extractSubscribeFields(
        widget,
        tree,
        clientManager,
      );
      widget.subscribeFields = subscribeFields;
      widget.requiredFields = requiredFields;
    }
  } catch (error) {
    console.log('Problem transforming widget:', widget, getErrorString(error));
    throw error;
  }
}

export function clientToDb(tree) {
  const newTree = fromClient(tree);
  const { id } = newTree;

  if (!newTree.configName) {
    newTree.configName = MetadataSupport.getConfigName(id, true);
    if (!newTree.configName) {
      throw new Error(
        `clientToDb, widget tree must have configName field, or have configName embedded in the id as <configName>${CONFIG_SEPARATOR}<widgetName>`,
      );
    }
  }

  return newTree;
}

export function fromClient(tree) {
  const newTree = _.cloneDeep(tree);
  return fromClientRecurse({ tree: newTree });
}

function fromClientRecurse({ tree, parent }: { tree; parent? }) {
  if (Array.isArray(tree)) {
    return tree.map((branch) => fromClientRecurse({ tree: branch, parent }));
  }
  if (tree && typeof tree === 'object') {
    Object.keys(tree).forEach((key) => {
      if (configurationTypeCategories[key] && Array.isArray(tree[key])) {
        tree[key].forEach((widget) =>
          transformWidgetSave({ widget, parentType: tree.type }),
        );
      } else if (key === 'events') {
        // transformEventsSave(tree[key]);
        tree[key] = tree[key].filter((event) => event.stages.length);
        // for copy / pasting singular widgets
      } else if (widgetTypes[key]) {
        transformWidgetSave({ widget: tree[key] });
      }
      tree[key] = fromClientRecurse({ tree: tree[key] });
    });
    return tree;
  }
  if (typeof tree === 'string') {
    if (tree === '') {
      return 'NULL';
    }
    // @ts-ignore
    if (tree === false) {
      return 'false';
    }
  }
  return tree;
}

function transformWidgetSave({ widget, parentType }: { widget; parentType? }) {
  removeDefaultsAndBlanks({
    node: widget,
    parentType,
    propertyConfiguration: widgetPropertyConfiguration,
  });

  if (widget.events) {
    widget.events.forEach((event) => {
      event.stages.forEach((stage) => {
        removeDefaultsAndBlanks({
          node: stage,
          propertyConfiguration: stagePropertyConfiguration,
        });
      });
    });
  }

  Object.keys(configurationTypeCategories).forEach((widgetKey) => {
    if (widget[widgetKey] && !widget[widgetKey].length) {
      delete widget[widgetKey];
    }
  });

  delete widget.widgetId;
  delete widget.category;
  delete widget.treeId;
  delete widget.configName;
  delete widget.parentType;
  delete widget.matchProperties;
  delete widget.parentProperties;
  delete widget.parentType;
  delete widget.subscribeFields;
}

function removeDefaultsAndBlanks({
  node,
  parentType,
  propertyConfiguration,
}: {
  node;
  parentType?: string;
  propertyConfiguration: IPropertyConfiguration;
}): void {
  const propertyHandler = new PropertyConfigurationHandler({
    node,
    parentType,
    propertyConfiguration,
  });

  const properties = propertyHandler.getRawProperties();

  for (const propertyName in properties) {
    // Used to remove unused properties
    const propertyInfo = propertyHandler.getPropertyInfo(propertyName);
    if (!propertyInfo) {
      console.log(
        `WARNING: Removing undefined property: ${propertyName} in ${propertyHandler.getTypeName()}`,
      );
      delete properties[propertyName];
    }

    const defaultValue = propertyHandler.getDefaultValue(propertyName);

    if (
      properties[propertyName] === '' ||
      properties[propertyName] === defaultValue
    ) {
      delete properties[propertyName];
    }
  }
}
