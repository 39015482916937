import _ from 'lodash';

import { ClientManager } from './clientManager';
import { SYSTEM } from './common/commonConstants';
import {
  IMatchingQueryKey,
  IQueryKeyParams,
  IQueryKeyReturn,
} from './entityKeySupportConstants';
import {
  CODE,
  CODE_TYPE_FIELD,
  IEntityKeyType,
  IEntityType,
} from './metadataSupportConstants';
import { SchemaManager } from './schemaManager';
import { getChunkId } from './sizeClass';
import { SimpleType } from './types';
import {
  isNullOrUndefined,
  sequentialArray,
  stringify,
} from './utilityFunctions';

// Handles issues associated with supporting keys on entities
export class EntityKeySupport {
  // Note that this is not be available when this class is used
  // in installMetadata, for the low-level key/database creation stuff
  public schemaManager: SchemaManager;

  private static keyStringSeparator = '-';

  // This is called only when there is a ClientManager
  public initialize(clientManager: ClientManager) {
    this.schemaManager = clientManager.schemaManager;
  }

  public static getSortKeyNumbersFromNumber({
    numberOfSortKeys,
  }: {
    numberOfSortKeys: number;
  }): number[] {
    const sortKeyNumbers = sequentialArray(numberOfSortKeys);
    return sortKeyNumbers;
  }

  public getBestMatchQueryKeyFieldValue(
    params: IQueryKeyParams,
  ): IQueryKeyReturn {
    const { entity, requestArgs, recordId, searchChunkId } = params;
    const queryKeys = this.getQueryKeys({ entity });

    const emptyReturn = {
      fields: undefined,
      queryKeyFieldValue: undefined,
    };

    if (!queryKeys) {
      return emptyReturn;
    }

    const matchingQueryKeys = this.getMatchingQueryKeys(params);

    if (matchingQueryKeys.length === 0) {
      return emptyReturn;
    }

    const bestMatch = _.maxBy(
      matchingQueryKeys,
      (o) => o.queryKey.fields.length,
    );

    const queryKeyFieldValue = this.getQueryKeyFieldValue({
      queryKey: bestMatch.queryKey,
      entity,
      requestArgs,
      recordId,
      searchChunkId,
    });

    return {
      ...bestMatch.queryKey,
      queryKeyFieldValue,
      bestMatchQueryKey: bestMatch,
    };
  }

  public static makeFieldsKey(fields: string[]): string {
    return fields.sort().join(this.keyStringSeparator);
  }

  public static makeValuesKey({
    fields,
    record,
    alreadyOrdered,
    noThrowOnMissing,
  }: {
    fields: string[];
    record: { [key: string]: SimpleType };
    alreadyOrdered?: boolean;
    noThrowOnMissing?: boolean;
  }): string {
    const orderedFields = alreadyOrdered ? fields : fields.sort();

    const orderedValues = orderedFields.map((field) => {
      if (!record.hasOwnProperty(field)) {
        if (noThrowOnMissing) {
          return null;
        }
        throw new Error(
          `Field ${field} not found on record ${stringify({
            input: record,
            pretty: true,
          })}`,
        );
      }

      if (record[field] === '') {
        return null;
      }
      return record[field];
    });

    let hasValue = false;
    orderedValues.forEach((ov) => (hasValue ||= !isNullOrUndefined(ov)));
    if (!hasValue) {
      return null;
    }
    return orderedValues.join(this.keyStringSeparator);
  }

  private getQueryKeyFieldValue(params: {
    queryKey: IEntityKeyType;
    entity: IEntityType;
    requestArgs: any;
    searchChunkId: number;
    recordId: string;
  }): string {
    const { queryKey, entity, requestArgs, searchChunkId, recordId } = params;
    const queryKeyFields = queryKey.fields;

    const sizeClass =
      entity.isCode && queryKey.fields[0] === CODE_TYPE_FIELD
        ? entity.sizeClass
        : queryKey.sizeClass;

    const fieldsValue = EntityKeySupport.makeValuesKey({
      fields: queryKeyFields,
      record: requestArgs,
      alreadyOrdered: true,
    });

    if (isNullOrUndefined(fieldsValue)) {
      return fieldsValue;
    }

    if (sizeClass) {
      let chunkId = searchChunkId;
      if (chunkId === undefined && recordId) {
        chunkId = getChunkId({ id: recordId, sizeClass });
      }
      if (chunkId !== undefined) {
        return fieldsValue.toString() + '-' + chunkId;
      }
    }
    return fieldsValue;
  }

  public insertAllQueryKeyFieldValues(
    params: IQueryKeyParams,
    getSortKeyStringFunction: (sortKeyNumber: number) => string,
  ) {
    const { entity, requestArgs, recordId, searchChunkId } = params;

    if (!entity) {
      return [];
    }

    const matchingQueryKeys = this.getMatchingQueryKeys(params);

    matchingQueryKeys.forEach(({ sortKeyNumber, queryKey }) => {
      const queryKeyFieldValue = this.getQueryKeyFieldValue({
        queryKey,
        entity,
        requestArgs,
        recordId,
        searchChunkId,
      });

      if (!isNullOrUndefined(queryKeyFieldValue)) {
        const sortKey = getSortKeyStringFunction(sortKeyNumber);
        requestArgs[sortKey] = queryKeyFieldValue;
      }
    });
  }

  private getMatchingQueryKeys(params: IQueryKeyParams): IMatchingQueryKey[] {
    const { entity, requestArgs } = params;

    const matchingQueryKeys: IMatchingQueryKey[] = [];

    const queryKeys = this.getQueryKeys({ entity });
    if (!queryKeys) {
      return matchingQueryKeys;
    }

    const sortKeyNumbers = EntityKeySupport.getSortKeyNumbersFromNumber({
      numberOfSortKeys: queryKeys.length,
    });

    for (const [index, sortKeyNumber] of Object.entries(sortKeyNumbers)) {
      const queryKeyFieldArray = queryKeys[index].fields;
      let match = true;

      for (const queryKeyField of queryKeyFieldArray) {
        if (requestArgs[queryKeyField] === undefined) {
          match = false;
          break;
        }
      }

      if (match) {
        matchingQueryKeys.push({
          sortKeyNumber,
          queryKey: queryKeys[index],
        });
      }
    }
    return matchingQueryKeys;
  }

  private getQueryKeys({ entity }: { entity: IEntityType }): IEntityKeyType[] {
    let queryKeys;

    if (entity.isCode) {
      const codeEntityType = this.schemaManager.getEntityType({
        name: CODE,
        configName: SYSTEM,
      });

      queryKeys = codeEntityType.keys;
    } else {
      queryKeys = entity.keys;
    }

    return queryKeys;
  }
}
