import { Theme } from '@mui/material/styles';
import React from 'react';

export interface IThemeWrapperContext {
  theme: Theme;
  overrideTheme: (theme: Theme) => void;
}

export const ThemeWrapperContext = React.createContext<IThemeWrapperContext>(
  {} as IThemeWrapperContext,
);
