import { withStasis } from '../../util/Stasis';
import Loading from '../atoms/Loading';

import Widget from './Widget';

function TreeRenderer(props) {
  const { tree, initialPassThroughProps } = props;
  const { widgets } = tree;

  if (!tree) {
    return <Loading />;
  }

  const DynamicComponents = widgets.map((widget) => {
    return (
      <Widget
        component={widget}
        passThroughProps={initialPassThroughProps}
        key={widget.widgetId}
      />
    );
  });

  return (
    <div
      id="TreeRenderer"
      style={{
        height: '100%',
        width: '100%',
        boxSizing: 'border-box',
        overflow: 'hidden',
      }}
    >
      {DynamicComponents}
    </div>
  );
}

export default withStasis(TreeRenderer);
