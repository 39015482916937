/**
 * Basic type/constant definitions.
 *
 * This should not depend on anything else in the client.
 */

import keyMirror from 'keymirror';
import { IHasId } from 'universal/metadataSupportConstants';
import { AccessType } from 'universal/permissionManager';
import { IStageProperties } from 'universal/pipeline/stage';
import { IPropertyInfo, IPropertyInfos } from 'universal/propertySupport';
import { AdditionalEditor, BasicType } from 'universal/types';

import { ContextMap } from '../../store/context';
import { IRawUpdate } from '../../store/data';

export const ICON_DESCRIPTION =
  'the icon name (from https://material.io/tools/icons), or _config:[configName:]:iconName for an svg file in the configuration';

interface IConfigurationEditorDetails {
  [key: string]: { singular: string };
}

export interface IAction {
  anchor: {
    element: Element;
    left: number;
    top: number;
  };
  close: () => void;
  tree: IWidgetTree;
}

const widgetEditorFields: IConfigurationEditorDetails = {
  properties: {
    singular: 'property',
  },
  events: {
    singular: 'event',
  },
  stages: {
    singular: 'stage',
  },
};

interface IWidgetCategoryMetadataField {
  singular: string;
  aliasFor?: string;
  nestedConfigItem: boolean;
}

interface IWidgetCategoryMetadata {
  [key: string]: IWidgetCategoryMetadataField;
}

export interface IConfigurationProperties {
  [key: string]: IPropertyInfo;
}

export const overflow = {
  default: "'auto'",
  options: [
    "'visible'",
    "'hidden'",
    "'scroll'",
    "'auto'",
    "'initial'",
    "'inherit'",
  ],
  description: 'What to do with content that spills outside of the container',
};

export const entityProperties = {
  entityType: {
    description:
      'entity type that will be used to automatically configure the grid.',
  },
  entityQueryArgs: {
    description:
      'used with entityType to specify the arguments to the list query for this automatic grid.',
  },
};

export type TypeDefProperties = IWidgetProps<typeof typeDefProperties>;

export const typeDefProperties = {
  typeDefName: {
    description: 'type definition name (alternative to using typeDefObject).',
  },
  typeDefObject: {
    description:
      'type definition object pointer (alternative to using typeDefName).',
  },
};

export type EntityProperties = IWidgetProps<typeof entityProperties>;

export const linkProperties = {
  url: {
    description: 'if added, turns the display into a link to that url.',
  },
  relativeUrlDepth: {
    description:
      'creates a relative url with this number of paths sliced off from the right. Does nothing if absolute is set to true, or if there is no url',
    type: BasicType.Int,
    default: 0,
  },
  absolute: {
    default: false,
    options: [true, false],
    description:
      'only effects links, sets the url to navigate absolutely rather than relatively within the app',
  },
  external: {
    default: false,
    options: [true, false],
    description:
      'only effects links, set this to true if the link is to outside of the application',
  },
  newTab: {
    default: false,
    options: [true, false],
    description: 'whether or not to open the link in a new tab',
  },
};

export const buttonProperties = {
  label: {
    description: 'Button text',
  },
  icon: {
    description:
      'Optionally replaces text button with an icon button: ' +
      ICON_DESCRIPTION,
  },
  disabled: {},
  color: {
    additionalEditor: AdditionalEditor.Color,
  },
  backgroundColor: {
    additionalEditor: AdditionalEditor.Color,
  },
  variant: {
    default: "'outlined'",
    options: ["'contained'", "'outlined'", "'text'"],
    description: 'the importance of the button',
  },
};
export const duplicateBy: IConfigurationProperties = {
  duplicateBy: {
    pointer: 'must',
  },
  pluralName: {
    pointer: 'never',
  },
  instanceName: {
    pointer: 'never',
  },
};
export const containerProperties = {
  width: {
    default: "'100%'",
    required: false,
    description:
      'If empty, this container will fill all available space. Set a unit or percentage here to set width manually. ex: 20%, 400px, 3cm, min-content',
  },
  height: {
    default: "'100%'",
    required: false,
    description:
      'If empty, this container will fill all available space. set a unit or percentage here to override that ex: 20%, 400px, 3cm, min-content',
  },
  maxWidth: {},
  maxHeight: {},
  minWidth: {},
  minHeight: {},
  overflow,
  border: {
    default: "'none'",
    options: ["'paper'", "'solid'", "'dotted'", "'dashed'", "'none'"],
  },
  borderWidth: {
    description: 'Thickness of non paper border, units: px, pt, cm',
  },
  borderColor: {
    description: 'Color of non paper border',
    additionalEditor: AdditionalEditor.Color,
  },
  borderRadius: {
    description: 'Curved corners e.g. 25px',
  },
  gap: {
    default: "'10px 10px'",
  },
  boxSizing: {
    default: "'border-box'",
    options: ["'border-box'", "'context-box'"],
  },
  backgroundColor: {
    description: 'Change color of the background',
    additionalEditor: AdditionalEditor.Color,
  },
  title: {
    required: false,
    description: 'Title of the container',
  },
  form: {
    pointer: 'never',
    description:
      'Sets this container to be a form. Children inputs will have a form alias pointing to the address that is the value of this.',
  },
  updateOnFieldChange: {
    default: false,
    options: [true, false],
    description:
      "if true, when a field is updated, the container's update pipeline is executed",
  },
} as const;
export const stringFormat: IPropertyInfos = {
  prefix: {},
  suffix: {},
  decimalScale: {
    description:
      'Only applies to numbers. If defined it limits to given decimal scale',
  },
  fixedDecimalScale: {
    default: false,
    options: [true, false],
    description:
      'Only applies to numbers. If true it add 0s to match given decimalScale',
  },
  style: {
    default: "'decimal'",
    options: ["'decimal'", "'currency'", "'percent'", "'unit'", "'month'"],
  },
  currency: {},
  thousandSeparator: {
    default: false,
    options: [true, false],
  },
  notation: {
    default: "'standard'",
    options: [
      "'standard'",
      "'compact'",
      "'scientific'",
      "'engineering'",
      "'thousands'",
      "'millions'",
      "'billions'",
    ],
  },
  defaultValue: {
    description: 'What will be shown if the value is undefined or null',
  },
};
export const stringStyle: IPropertyInfos = {
  italic: {
    default: false,
  },
  bold: {
    default: false,
  },
};
export const mUiColorPrimary = {
  default: "'primary'",
  options: ["'primary'", "'secondary'"],
};
export const mUiColorSecondary = {
  ...mUiColorPrimary,
  default: "'secondary'",
};
export const textVariants = [
  "'h1'",
  "'h2'",
  "'h3'",
  "'h4'",
  "'h5'",
  "'h6'",
  "'subtitle1'",
  "'subtitle2'",
  "'body1'",
  "'body2'",
  "'caption'",
  "'button'",
  "'overline'",
] as const;

export const gridAlignOptions = [
  "'auto'",
  "'normal'",
  "'start'",
  "'end'",
  "'center'",
  "'stretch'",
  "'baseline'",
  "'first-baseline'",
  "'last-baseline'",
];
export const graphAxisScale = [
  "'auto'",
  "'linear'",
  "'pow'",
  "'sqrt'",
  "'log'",
  "'identity'",
  "'time'",
  "'band'",
  "'point'",
  "'ordinal'",
  "'quantile'",
  "'quantize'",
  "'utc'",
  "'sequential'",
  "'threshold'",
];
export const gridSizing = [
  false,
  "'auto'",
  true,
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
];
export const gridOptions = {
  xl: {
    default: false,
    options: gridSizing,
  },
  lg: {
    default: false,
    options: gridSizing,
  },
  md: {
    default: false,
    options: gridSizing,
  },
  sm: {
    default: false,
    options: gridSizing,
  },
  xs: {
    default: true,
    options: gridSizing,
  },
};
export const categories = keyMirror({
  container: null,
  popup: null,
  input: null,
});
export const configurationTypes = [
  'CardWidget',
  'CardHeader',
  'CardContent',
  'CardActions',
  'CardMedia',
  'Grid',
  'GridWidget',
  'Tree',
  'LayoutWidget',
  'Layout',
  'FlexArea',
  'WidgetSelector',
  'WidgetArray',
  'SelectorPool',
  'SelectorPoolSelector',
  'Tabs',
  'LogViewer',
  'OptionsOverlay',
  'PDFContainer',
  'CSVWidget',
  'CollapseWidget',
  'ActionGroup',
  'MenuItemWidget',
  'ModalWidget',
  'ModalWidgetStasis',
  'PopupWidget',
  'MenuWidget',
  'Chip',
  'CheckboxWidget',
  'Text',
  'SliderWidget',
  'DropdownSelect',
  'RichText',
  'Router',
  'TreeLink',
  'Button',
  'AttachmentButton',
  'Icon',
  'Image',
  'Display',
  'GridContainer',
  'FlexContainer',
  'DataVis',
  'Bar',
  'Line',
  'Area',
  'Legend',
  'XAxis',
  'YAxis',
  'ReferenceLine',
  'ReferenceDot',
  'Brush',
  'Tooltip',
  'CartesianGrid',
  'DataVisLabel',
  'LabelList',
  'ColumnGroup',
  'RowGroup',
  'List',
  'Column',
  'Upload',
  'Page',
  'MultiSelect',
  'AGGrid',
  'AGChart',
  'KendoExport',
  'KendoToolbar',
  'KendoGrid',
  'KendoColumn',
  'KendoCell',
  'KendoAggregate',
  'KendoExportButton',
] as const;
export type ConfigurationTypeName = (typeof configurationTypes)[number];

export interface IConfigurationType {
  properties: IConfigurationProperties;
  childProperties?: IConfigurationProperties;
  types: {
    [key in childTypeNames]?: boolean;
  };
  childTypes: {
    [key in childTypeNames]?: boolean | ConfigurationTypeName[];
  };
  events?: {
    [key: string]: any;
  };
  category?: string;
  nestedConfigItem?: boolean;
}

export type IConfigurationTypes = Record<
  ConfigurationTypeName,
  IConfigurationType
>;

const rawConfigChildCategories = {
  children: {
    singular: 'child',
    nestedConfigItem: false,
  },
  widgets: {
    singular: 'widget',
    aliasFor: 'children',
    nestedConfigItem: false,
  },
  actions: {
    singular: 'action',
    nestedConfigItem: false,
  },
  overlays: {
    singular: 'overlay',
    nestedConfigItem: false,
  },
  graphs: {
    singular: 'graph',
    nestedConfigItem: true,
  },
  labels: {
    singular: 'label',
    nestedConfigItem: true,
  },
  dataVisLabels: {
    singular: 'dataVisLabel',
    aliasFor: 'label',
    nestedConfigItem: true,
  },
  subHeader: {
    singular: 'subheader',
    aliasFor: 'children',
    nestedConfigItem: false,
  },
  columns: {
    nestedConfigItem: true,
    singular: 'column',
  },
  groupHeader: {
    nestedConfigItem: true,
    singular: 'groupHeader',
    aliasFor: 'children',
  },
  header: {
    nestedConfigItem: true,
    singular: 'header',
    aliasFor: 'children',
  },
  cell: {
    nestedConfigItem: true,
    singular: 'cell',
    aliasFor: 'children',
  },
  rowGroups: {
    nestedConfigItem: true,
    singular: 'rowGroup',
  },
  columnGroups: {
    nestedConfigItem: true,
    singular: 'columnGroup',
  },
  kendoColumns: {
    nestedConfigItem: true,
    singular: 'kendoColumn',
  },
  kendoCells: {
    nestedConfigItem: true,
    singular: 'kendoCell',
  },
  kendoColumnTemplates: {
    nestedConfigItem: true,
    singular: 'kendoColumnTemplate',
    aliasFor: 'kendoColumns',
  },
  kendoAggregates: {
    nestedConfigItem: true,
    singular: 'kendoAggregate',
  },
  kendoToolbarItems: {
    nestedConfigItem: false,
    singular: 'kendoToolbarItem',
  },
  exportConfig: {
    nestedConfigItem: true,
    singular: 'exportConfig',
  },
  widgetPool: {
    nestedConfigItem: true,
    singular: 'widgetPool',
    aliasFor: 'children',
  },
  layouts: {
    nestedConfigItem: true,
    singular: 'layout',
  },
  flexAreas: {
    nestedConfigItem: true,
    singular: 'flexArea',
  },
  widgetSelectors: {
    nestedConfigItem: true,
    singular: 'widgetSelector',
  },
  widgetArrays: {
    nestedConfigItem: true,
    singular: 'widgetArray',
  },
  selectorPools: {
    nestedConfigItem: true,
    singular: 'selectorPool',
  },
  selectorPoolSelectors: {
    nestedConfigItem: true,
    singular: 'selectorPoolSelector',
  },
  deprecated: {
    nestedConfigItem: false,
    singular: 'deprecated',
  },
  cardItems: {
    nestedConfigItem: true,
    singular: 'cardItem',
  },
  kendoToolbars: {
    nestedConfigItem: true,
    singular: 'kendoToolbar',
  },
} as const;

const configurationTypeCategories: IWidgetCategoryMetadata =
  rawConfigChildCategories;

interface IEvent {
  stages: IStageProperties[];
  name: string;
}

type childTypeNames = keyof typeof rawConfigChildCategories;

type IWidgetChildren = {
  [K in childTypeNames]?: IWidgetTree[];
};

export interface IWidgetTrees {
  [id: string]: IWidgetTree;
}

export interface IWidgetTree extends IHasId, IWidget {
  treeId: string;
}

export interface IWidget extends IWidgetChildren {
  type: string;
  parentType: string;
  widgetId: number;
  configName: string;
  events: IEvent[];
  category?: string;
  properties: { [key: string]: string | number | boolean };
  matchProperties: { [key: string]: string };
  name: string;
  actionReference?: IAction;
  subscribeFields?: string[];
  requiredFields: string[];
}

export interface IPassThroughProps {
  updateFormValidity?: (any) => void;
  updateFormFromWidget?: (params?: UpdateFromWidgetParams) => void;
  underBlockedPipeline: boolean;
  updateOnFieldChange?: boolean;
  inPopup: boolean;
  closePopup?: IAction['close'];
  activate: (tree, anchor, close) => void;
  deactivate: (any) => void;
  setRowHeight?: (index, height) => void;
  rowIndex?: number;
  formName?: string;
  formValid: boolean;
  formValidationMessage?: string;
  formSubmitted?: boolean;
  formValidationReasonCodes?: string[];
  formValidationReasonStoreLocation?: string;
  rejectedFormSubmit?: boolean;
  permission?: AccessType;
  logUpdate?: (any) => void;
  openingModal?: () => void;
  closingModal?: () => void;
}

export interface IEventBindingProps {
  instanceId: number;
  passThroughProps: IPassThroughProps;
  aliases: ContextMap;
  reloadLogger: boolean;
  loadPipelineLogger: boolean;
  name: string;
  graphqlKey: string;
  // FIXME - these are the possible events for the component (which pipelines maybe associated)
  // Need to have a type that defines these which is the same as the master list of events (which I can't find)
  context: {
    data: any;
    load: any;
    update: any;
    validate: any;
    render: any;
  };
  component: IWidgetTree;
  trees: IWidgetTree[];
  form: boolean;
  dataBind: string;
  submitForm: boolean;
  hideFormData: boolean;
  overrideIgnoreFormData?: boolean;
  renderOwnActions: boolean;
  properties: any;
  childProps: any;
  persistKey?: string;
  permissionId?: string;
  showReloading?: boolean;
  showUpdating?: boolean;
  renderer: (any) => any;
  events?: { name: string; stages: IStageProperties[] }[];
  subscriptionMapSelector: (any) => any;
}

export interface IElementAttributes {
  id?: any;
  // For cypress
  'data-test': string;
  onMouseEnter?: (event: any) => void;
  onMouseLeave?: () => void;
  onClick?: (event: any) => void;
  onContextMenu?: (event: any) => void;
}

export type UpdateFromWidgetParams = {
  data?: any;
  widgetDoesNotProvideData?: boolean;
  initialUpdate?: boolean;
  acceptConditionalValidation?: boolean;
  callback?: () => void;
  fromSubmitButton?: boolean;
  stages?: IStageProperties[];
};

export interface EventBindingChildWidgetProps {
  id: string;
  cursor?: string;
  persistKey: string;
  persistValue: any;
  elementAttributes: IElementAttributes;
  actionReference?: IAction;
  context: any;
  aliases: ContextMap;
  passThroughProps: IPassThroughProps;
  component: IWidgetTree;
  paged: boolean;
  pageForward: () => Promise<void>;
  tooltip: string;
  hasPageForward: () => boolean;
  setFilterModel: (filterModel: any) => void;
  getPermission: () => AccessType;
  updateFromWidget: (params?: UpdateFromWidgetParams) => void;
  runPipelineWithWidgetContext: (
    params?: UpdateFromWidgetParams,
  ) => Promise<void>;
  updateUiState: (data: any) => Promise<void>;
  batchUpdateContext: (updates: IRawUpdate[]) => void;
  renderingActions: IWidgetTree[];
  subHeader?: IWidgetTree[];
  overlays?: IWidgetTree[];
  renderer?: IEventBindingProps['renderer'];
  loading: boolean;
  reloading: boolean;
  updating: boolean;
}

export type IConfigProperties<properties> = { [K in keyof properties]: any };
export type IWidgetProps<properties> = IConfigProperties<properties> &
  EventBindingChildWidgetProps;

const rawUniversalProperties = {
  tooltip: {
    description: 'Text to be shown as a tooltip',
  },
  render: {
    default: true,
  },
  preventRender: {
    default: false,
  },
  showLoading: {
    default: false,
  },
  showUpdating: {
    default: false,
  },
  showReloading: {
    default: false,
  },
  readOnly: {},
  permissionId: {
    description:
      'Specify a Widget type permission for this widget. If no read access is granted the widget will not load or render, and if only read access is granted, it and its children will render in readOnly mode.',
  },
  persistKey: {
    description:
      'A key, that if present, will store the value of this input to the users data against this key. It does not need to be unique, multiple widgets can use the same key keeping in mind that they will overwrite each other.',
  },
  reloadLogger: {
    default: false,
    options: [true, false],
    description:
      'When enabled, the widget will log an array of all the load pipelines upto and including its own, for visualising dataflow and debugging',
  },
  loadPipelineLogger: {
    default: false,
    options: [true, false],
  },
} as const;

export type UniversalWidgetProps = IWidgetProps<typeof rawUniversalProperties>;

const universalProperties: IConfigurationProperties = rawUniversalProperties;

export const inputProperties: IConfigurationProperties = {
  label: {},
  inForm: {
    // default: true,
    options: [true, false],
  },
  // formName: {},
  initialValue: {
    description:
      'Must be a value that can fit the input. Can point to the value within context, come from a pipeline, or be hard coded.',
  },
  updateInitialValue: {
    default: false,
    options: [true, false],
    description:
      'If true, if the initial value property changes, it will be reinitialized',
  },
  dataBind: {
    description:
      'A pointer string like you would use for importing to or writing from the store, which makes the pointed to field the source of truth for the input.',
  },
  graphqlKey: {},
  disabled: {
    default: false,
    options: [true, false],
    description: 'This component is disabled (not editable)',
  },
};

export const checkLinkProperties: IConfigurationProperties = linkProperties;

export { configurationTypeCategories, universalProperties, widgetEditorFields };
