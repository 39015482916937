import { AutoSizer } from 'react-virtualized';

import { isTesting } from '../util/environment';

export default function TestSafeAutosizer(props) {
  const { children } = props;

  // autosizer does not function without a full dom. jsdom, used in jest, lacks some of the methods used in autosizer
  return isTesting() ? (
    children({ width: 500, height: 500 })
  ) : (
    <AutoSizer>{({ width, height }) => children({ width, height })}</AutoSizer>
  );
}
