/*
 Product code hooks that support testing. Not to be confused with commonTest.ts.
 */

import { v1 as uuidv1 } from 'uuid';

import { ClientManager } from './clientManager';
import { SYSTEM_TEST } from './common/commonConstants';
import { getLogger, TestLoggers } from './loggerSupport';
import { IRemoteContext } from './pipeline/pipelineManager';
import { sleep } from './utilityFunctions';

const logger = getLogger({ name: TestLoggers.COMMON });

export enum TestWhatsHappening {
  ASYNC_SEND,
  SERVER_REQUEST,
  BATCH_MUTATION_DATABASE_PUT,
}

export enum TestActions {
  NOTHING,
  DELAY,
  TIMEOUT,
  RESOURCE_NOT_FOUND_PERMANENT,
  RESOURCE_NOT_FOUND_ONCE,
}

// Identifies the things to do for a test
export interface ITestRequest {
  pipelineName?: string;
  pipelineArguments?: any;
  functionName?: string;
  whatsHappening?: TestWhatsHappening;
  condition?: string;
}

// Control behavior for testing in remote communications
export interface ITestContext {
  ignoreIncarnationInDb?: boolean;

  // Turns on or off full tracing in apolloHandler, tracing remains on until turned off
  fullTracing?: boolean;

  // A UUID which identifies the TestControlRecord
  testId?: string;
  testRequests?: ITestRequest[];

  lambdaFunctionTimeoutMs?: number;
}

export const TEST_CONTROL_RECORD = 'TestControlRecord';

// Database records, matches TestControlRecord type
export interface ITestControlRecord {
  // from the testId in the ITestContext
  id: string;
  events: ITestEvent[];
}

// Matches TestEvent type
export interface ITestEvent {
  id: string; // UUID
  timestamp: string; // ISO 8601 date
  functionName: string;
  whatsHappening: TestWhatsHappening;
  testAction: TestActions;
  retryCount?: number;
  delayMs?: number;
}

export interface ITestPipelineInput {
  remoteContext: IRemoteContext;
  testContext: ITestContext;
  functionName: string;
  condition: string;
  whatsHappening: TestWhatsHappening;
  pipelineArguments: any;
  testEvents: ITestEvent[];
}

export interface ITestPipelineOutput {
  action: TestActions;
  delayMs?: number;
}

export async function handleTestRequest(params: {
  clientManager: ClientManager;
  functionName?: string;
  whatsHappening: TestWhatsHappening;
  testContext: ITestContext;
  remoteContext?: IRemoteContext;
}): Promise<ITestPipelineOutput> {
  const {
    clientManager,
    remoteContext,
    testContext,
    functionName,
    whatsHappening,
  } = params;

  logger.info('Handle test request - should only see this during testing');
  if (!testContext?.testRequests) {
    return;
  }

  const { pipelineManager } = clientManager;
  const header = `Test request (${functionName} - ${whatsHappening}) -`;

  let testRequestToUse: ITestRequest;
  for (const testRequest of testContext.testRequests) {
    const {
      functionName: requestFunctionName,
      whatsHappening: requestWhatsHappening,
    } = testRequest;
    if (requestFunctionName && functionName !== requestFunctionName) {
      continue;
    }
    if (
      requestWhatsHappening !== undefined &&
      whatsHappening !== requestWhatsHappening
    ) {
      continue;
    }
    if (testRequestToUse) {
      throw new Error(
        `Multiple test requests for ${functionName}, ${whatsHappening}`,
      );
    }
    testRequestToUse = testRequest;
  }
  if (!testRequestToUse) {
    return;
  }

  const { pipelineName, pipelineArguments, condition } = testRequestToUse;

  const testControlRecord = (await pipelineManager.getRecord({
    entityName: TEST_CONTROL_RECORD,
    id: testContext.testId,
    configName: SYSTEM_TEST,
    noCache: true,
  })) as ITestControlRecord;

  const testEvents = testControlRecord?.events ? testControlRecord.events : [];

  logger.info(
    testEvents,
    `${header} executing test pipeline: ${pipelineName} - testId: ${testContext.testId}`,
  );
  const pipelineResult: ITestPipelineOutput =
    await pipelineManager.executeNamedPipeline({
      name: pipelineName,
      input: {
        remoteContext,
        functionName,
        whatsHappening,
        pipelineArguments,
        condition,
        testEvents,
      } as ITestPipelineInput,
    });

  if (pipelineResult?.action === TestActions.NOTHING) {
    return;
  }

  logger.info(pipelineResult, `${header} executing test pipeline return`);

  // Used to update the test record, since the events are a map, the new
  // event will just be added to the existing record
  const testControlRecordUpdate: ITestControlRecord = {
    id: testContext.testId,
    events: [
      {
        id: uuidv1(),
        functionName,
        whatsHappening,
        timestamp: new Date().toISOString(),
        testAction: pipelineResult.action,
        delayMs: pipelineResult.delayMs,
      },
    ],
  };

  const mutationText = clientManager.metadataSupport.makeGraphqlObjectMutation(
    testControlRecordUpdate,
    `${SYSTEM_TEST}:TestControlRecord`,
  );
  await pipelineManager.executeGraphqlMutation({
    mutation: `mutation @suppresslog { upsertTestControlRecord ( input: { ${mutationText} } ) { id } } `,
  });

  const { delayMs } = pipelineResult;

  if (delayMs !== undefined) {
    logger.info(`${header} - sleeping for ${delayMs}`);
    await sleep(delayMs);
    logger.info(`${header} - sleeping for ${delayMs} - done`);
  }
  return pipelineResult;
}
