import { isBrowser, isNode } from 'browser-or-node';
import diff from 'deep-diff';
import { DocumentNode, concatAST, parse } from 'graphql';
import gql from 'graphql-tag';
import _ from 'lodash';
import safeJsonStringify from 'safe-json-stringify';

import { ClientManager } from './clientManager';
import { SYSTEM } from './common/commonConstants';
import { exists } from './common/commonUtilities';
import { reThrow } from './errors/errorLog';
import { putConfigInDatabase } from './loadStore/load';
import { storeConfigItems } from './loadStore/store';
import {
  IEntityIdToConfigName,
  IResolverMap,
  ITypeDefIdToConfigName,
  ITypeDefsThatAreBoth,
  Loadgraphql,
} from './loadgraphql';
import { Loggers, getLogger } from './loggerSupport';
import { MetadataSupport } from './metadataSupport';
import {
  DATA_DEF_ENTITY_TYPE,
  DATA_DEF_TYPE_DEFINITION,
  IEntityType,
  INCARNATION,
  TYPE_NAME,
} from './metadataSupportConstants';
import {
  getFragmentNameFromEntityId,
  getFragmentTypeAndEntityIdFromFragmentName,
  makeGraphqlFieldList,
} from './pipeline/graphQLSupportAst';
import { StackInfo, StackInfoKeys } from './stackInfo';
import { TypeDefinition } from './typeDefinition';
import { StageType } from './types';

export enum FragmentType {
  SHALLOW_PLUS_ID,
  SHALLOW,
  DEEP,
  ONLY_INCARNATION,
}

const logger = getLogger({ name: Loggers.SCHEMA_MANAGER });

export type MetadataParentType = TypeDefinition | IEntityType;

type FragmentMapValueType = { document: DocumentNode; text: string };
type FragmentMapType = { [entityId: string]: FragmentMapValueType };

export interface IMetadataRequest {
  name: string;
  configName?: string;
  parentRecord?: MetadataParentType;

  // Additional information to be in the message if there is an error
  errorContext?: string;

  // Don't throw if there is an error
  noThrow?: boolean;
}

export interface IMetadataModificationRequest {
  record: IEntityType | TypeDefinition | { id: string };
  recordType?: string;
  create: boolean;

  // Commit the changes to the metadata store (true if not specified)
  commitToStore?: boolean;

  // If to be committed to source repo
  branchName?: string;

  // Required if branchName is set
  configName?: string;
}

export interface ISchemaManagerInitParams {
  readSchema?: boolean;

  buildNow?: boolean;

  // Provided (during installMetadata) to allow these to be passed in
  entityTypes?: IEntityType[];

  typeDefs?: TypeDefinition[];

  clientManager?: ClientManager;

  useTestData?: UseTestData;
}

export interface ISchemaInfo {
  schema: string;
  entityIdToConfigName: { [key: string]: string };
  typeDefIdToConfigName: { [key: string]: string };
}

export interface ISchemaInfoServer {
  resolverMap: IResolverMap;
  parsedSchema: DocumentNode;
}

export enum UseTestData {
  NONE,
  ONLY_SUPPORTED_ENTITIES,
  ALL,
  // Always goes to test database, even for neverUseTestData
  PERMISSION_MANAGER_TEST,
}

export class SchemaManager {
  // This might not be available
  public clientManager: ClientManager;

  // Used by nodeConnections when creating a client manager
  public metadataSupport: MetadataSupport;

  private initParams: ISchemaManagerInitParams;

  private metadataTableName: string;
  // To be used only by DynamoAccess
  public dataTableName: string;

  private graphQLSchema: string;

  // Used during the boot to write the configuration to the database once the PipelineManager is available
  private writeConfigurationFunction: () => Promise<void>;

  // Map of the graphql fieldNames to strings that name the functions
  // Converts to real functions in apolloHandler
  // Used only for server environments
  private resolverMap: IResolverMap;
  // Used only for server environments
  private parsedGraphQLSchema: DocumentNode;

  private entityIdToConfigName: IEntityIdToConfigName;
  private typeDefIdToConfigName: ITypeDefIdToConfigName;

  private typeDefsThatAreBoth: ITypeDefsThatAreBoth;

  public executionConfigName: string;
  public availableExecConfigNames: string[];
  public useTestData: UseTestData = UseTestData.NONE;
  public appDatabaseSuffix: string;

  // Each entity will have a fragment when needed
  // Index is defined in FragmentType
  private fragmentMap: FragmentMapType[] = [];
  private fragmentAssociatedEntities: { [entityId: string]: string[] } = {};

  // The usage of "enriched" === false is only by the SchemaManager in order that it can
  // re-initialized from the entity types and type definitions as they were originally
  // provided.

  public getAllEntityTypes(enriched = true): IEntityType[] {
    if (enriched) {
      return Object.values(this[DATA_DEF_ENTITY_TYPE]);
    }

    const entityTypes = _.cloneDeep(
      Object.values(this[DATA_DEF_ENTITY_TYPE]),
    ) as IEntityType[];

    this.unenrichEntityTypes(entityTypes);
    return entityTypes;
  }

  public getAllTypeDefinitions(enriched = true): TypeDefinition[] {
    if (enriched) {
      return Object.values(this[DATA_DEF_TYPE_DEFINITION]);
    }

    const typeDefinitions = _.cloneDeep(
      Object.values(this[DATA_DEF_TYPE_DEFINITION]),
    ) as TypeDefinition[];

    this.unenrichTypeDefinitions(typeDefinitions);
    return typeDefinitions;
  }

  public getEntityType(request: IMetadataRequest): IEntityType {
    return this.qualifyGetMetadata({ ...request }, DATA_DEF_ENTITY_TYPE);
  }

  public getTypeDefinition(request: IMetadataRequest): TypeDefinition {
    return this.qualifyGetMetadata({ ...request }, DATA_DEF_TYPE_DEFINITION);
  }

  public async initialize(
    clientManager: ClientManager,
    params: ISchemaManagerInitParams,
  ) {
    logger.debug('SchemaManager initialize');

    if (!clientManager) {
      throw new Error('SchemaManager initialized without ClientManager');
    }

    if (!params) {
      params = {};
    }
    if (params.readSchema === undefined) {
      params.readSchema = true;
    }
    if (params.buildNow) {
      params.readSchema = false;
    }

    this.clientManager = clientManager;
    this.initParams = params;
    this.metadataSupport = clientManager.metadataSupport;
    this.graphQLSchema = undefined;
    this.parsedGraphQLSchema = undefined;

    const {
      appDatabaseSuffix,
      executionConfigName,
      availableExecConfigNames,
      useTestData,
    } = clientManager.getStackConfig();
    logger.debug('SchemaManager read stackConfig');

    if (params.useTestData !== undefined) {
      this.useTestData = params.useTestData;
    } else {
      this.useTestData = useTestData;
    }
    this.appDatabaseSuffix = appDatabaseSuffix;
    this.availableExecConfigNames = availableExecConfigNames;
    this.executionConfigName = executionConfigName;
    this.metadataTableName = clientManager.stackInfo.getConfigTableName();
    this.dataTableName = getCustomerDatabaseName({
      stackInfo: clientManager.stackInfo,
      configName: executionConfigName,
      suffix: appDatabaseSuffix,
    });

    await this.initializeSchemaInfo();
    logger.info(
      `Schema manager initialized for client ${this.clientManager.clientId} execution config: ${this.executionConfigName} from (${this.availableExecConfigNames}), metadata table ${this.metadataTableName}, data table ${this.dataTableName}, test data: ${this.useTestData}`,
    );
  }

  public async initializeAfterPipelineManager() {
    if (this.writeConfigurationFunction) {
      await this.writeConfigurationFunction();
    }
  }

  private initializeRecords(params: {
    recordType: string;
    records?: TypeDefinition[] | IEntityType[];
  }) {
    const { recordType } = params;
    let { records } = params;

    if (!records) {
      records = this.clientManager.stackInfo.getObject(recordType);
    }

    this[recordType] = {};
    if (recordType === DATA_DEF_TYPE_DEFINITION) {
      records.forEach((record) => {
        this[recordType][record.id] = new TypeDefinition(
          record as TypeDefinition,
        );
      });
    } else {
      records.forEach((record) => {
        this[recordType][record.id] = record;
      });
    }
    logger.debug(`SchemaManager initialized ${recordType}`);
  }

  public async initializeSchemaInfo() {
    // The EntityTypes and TypeDefinitions can come from the following
    //  1) Passed in. This is used in installMetadata when creating everything. The objects
    //     come directly from the configuration files.
    //  2) The StackInfo object which is created when the schema is built or modified.

    try {
      this.initializeRecords({
        recordType: DATA_DEF_ENTITY_TYPE,
        records: this.initParams.entityTypes,
      });
      this.initializeRecords({
        recordType: DATA_DEF_TYPE_DEFINITION,
        records: this.initParams.typeDefs,
      });

      this.initializeTypeDefInheritanceComputedField();
      this.initializeFragmentMap();

      const { stackInfo } = this.clientManager;
      const { readSchema, buildNow } = this.initParams;

      if (!buildNow) {
        if (readSchema) {
          this.graphQLSchema = stackInfo.getObject(StackInfoKeys.SCHEMA);
          this.entityIdToConfigName = stackInfo.getObject(
            StackInfoKeys.ENTITYIDTOCONFIGNAME,
          );
          this.typeDefIdToConfigName = stackInfo.getObject(
            StackInfoKeys.TYPEDEFIDTOCONFIGNAME,
          );
          this.typeDefsThatAreBoth = stackInfo.getObject(
            StackInfoKeys.TYPEDEFS_THAT_ARE_BOTH,
          );
          if (isNode) {
            this.resolverMap = stackInfo.getObject(StackInfoKeys.RESOLVERS);
            this.parsedGraphQLSchema = parse(this.graphQLSchema);
          }
        }
        this.initializeComputedFields();
        return;
      }

      logger.info('Building schema right now');

      const entityTypesUnenriched = this.getAllEntityTypes(false);
      const typeDefsUnenriched = this.getAllTypeDefinitions(false);

      stackInfo.addOutputObject(
        StackInfoKeys.ENTITY_TYPE,
        entityTypesUnenriched,
      );
      stackInfo.addOutputObject(
        StackInfoKeys.TYPE_DEFINITION,
        typeDefsUnenriched,
      );

      const loadgraphql = new Loadgraphql();
      const {
        schema,
        resolvers,
        parsedSchema,
        entityIdToConfigName,
        typeDefIdToConfigName,
        typeDefsThatAreBoth,
      } = await loadgraphql.createSchema({
        schemaManager: this,
      });
      this.graphQLSchema = schema;
      this.entityIdToConfigName = entityIdToConfigName;
      this.typeDefIdToConfigName = typeDefIdToConfigName;
      this.typeDefsThatAreBoth = typeDefsThatAreBoth;

      this.initializeComputedFields();

      if (isNode) {
        this.resolverMap = resolvers;
        this.parsedGraphQLSchema = parsedSchema;
        // Verify the schema is good - will throw if there is a problem
        // graphql-tools can't be used in the browser
        const { makeExecutableSchema } = await import('@graphql-tools/schema');
        makeExecutableSchema({ typeDefs: this.parsedGraphQLSchema });
      }

      // No change from what was stored, no need to write the schema
      if (!this.initParams.entityTypes && !this.initParams.typeDefs) {
        return;
      }

      logger.info('Writing StackInfo');

      await stackInfo.writeStackInfo();

      const writeConfsToDatabase = async () => {
        logger.info('Writing schema to database');

        // Have to do these in order, TypeDefs can refer to EntityTypes as an associated entity
        await putConfigInDatabase({
          clientManager: this.clientManager,
          configItems: {
            [MetadataSupport.getQualifiedName(DATA_DEF_ENTITY_TYPE, SYSTEM)]:
              entityTypesUnenriched,
          },
        });

        await putConfigInDatabase({
          clientManager: this.clientManager,
          configItems: {
            [MetadataSupport.getQualifiedName(
              DATA_DEF_TYPE_DEFINITION,
              SYSTEM,
            )]: typeDefsUnenriched,
          },
        });
        logger.info('Schema built, StackInfo and config database updated');
      };

      // During startup/installMetadata there won't be a PipelineManager at this point
      if (this.clientManager.pipelineManager) {
        await writeConfsToDatabase();
      } else {
        // Do it later
        this.writeConfigurationFunction = writeConfsToDatabase;
      }
    } catch (error) {
      reThrow({
        logger,
        error,
        message: 'Problem creating/loading schema information',
      });
    }
  }

  private initializeTypeDefInheritanceComputedField() {
    const typeDefs = this.getAllTypeDefinitions();
    typeDefs.forEach((typeDef) => typeDef.initializeComputedFields(this));
  }

  private initializeComputedFields() {
    const entityTypes = this.getAllEntityTypes();
    const typeDefs = this.getAllTypeDefinitions();

    const entityTypeDefs: { [qualifiedTypeDefName: string]: string } = {};

    entityTypes.forEach((entityType) => {
      const typeDef = this.getTypeDefinition({
        name: entityType.typeDefinition,
        parentRecord: entityType,
      });
      entityType.typeDefinitionObject = typeDef;
      entityType.unqualifiedId = MetadataSupport.getUnqualifiedName(
        entityType.id,
      );
      entityTypeDefs[typeDef.id] = entityType.id;
    });

    // FIXME- this goes away with the fix to WORM-2474
    typeDefs.forEach((typeDef) => {
      typeDef.useAlternateTypeNameWhenNested =
        this.typeDefsThatAreBoth[typeDef.id];
      typeDef.isEntitysType = !!entityTypeDefs[typeDef.id];
    });
    logger.debug('SchemaManager initialized computed fields');
  }

  private unenrichEntityTypes(entityTypes: IEntityType[]) {
    entityTypes.forEach((entityType) => {
      this.unenrichEntityType(entityType);
    });
  }

  private unenrichTypeDefinitions(typeDefs: TypeDefinition[]) {
    typeDefs.forEach((typeDef) => {
      typeDef.unenrich();
    });
  }

  private unenrichEntityType(entityType: IEntityType) {
    delete (entityType as any)[TYPE_NAME];
    delete entityType.typeDefinitionObject;
    delete entityType.unqualifiedId;
  }

  public compareEntityType(entityType1: IEntityType, entityType2: IEntityType) {
    const et1 = _.cloneDeep(entityType1);
    const et2 = _.cloneDeep(entityType2);
    this.unenrichEntityType(et1);
    this.unenrichEntityType(et2);
    const result = diff(et1, et2);
    return result;
  }

  public compareTypeDef(
    typeDefinition1: TypeDefinition,
    typeDefinition2: TypeDefinition,
  ) {
    const td1 = _.cloneDeep(typeDefinition1).unenrich();
    const td2 = _.cloneDeep(typeDefinition2).unenrich();
    const result = diff(td1, td2);
    return result;
  }

  private removeComputedFields(
    record: IEntityType | TypeDefinition,
    recordType: string,
  ) {
    if (recordType === DATA_DEF_TYPE_DEFINITION) {
      (record as TypeDefinition).unenrich();
    } else if (recordType === DATA_DEF_ENTITY_TYPE) {
      this.unenrichEntityType(record as IEntityType);
    } else {
      throw new Error(`Unexpected recordType: ${recordType}`);
    }
  }

  private initializeFragmentMap() {
    this.fragmentMap[FragmentType.DEEP] = {};
    this.fragmentMap[FragmentType.SHALLOW_PLUS_ID] = {};
    this.fragmentMap[FragmentType.SHALLOW] = {};
    this.fragmentMap[FragmentType.ONLY_INCARNATION] = {};

    // Create the fragment map
    this.getAllEntityTypes().forEach((entityType) => {
      const entityId = MetadataSupport.getUnqualifiedName(entityType.id);

      const {
        fragment: shallowPlusIdFragment,
        fragmentText: shallowPlusIdText,
      } = this.makeFragment({
        entityId: entityType.id,
        configName: entityType.configName,
        fragmentType: FragmentType.SHALLOW_PLUS_ID,
      });
      this.fragmentMap[FragmentType.SHALLOW_PLUS_ID][entityId] = {
        document: shallowPlusIdFragment,
        text: shallowPlusIdText,
      };

      const { fragment: shallowFragment, fragmentText: shallowText } =
        this.makeFragment({
          entityId: entityType.id,
          configName: entityType.configName,
          fragmentType: FragmentType.SHALLOW,
        });
      this.fragmentMap[FragmentType.SHALLOW][entityId] = {
        document: shallowFragment,
        text: shallowText,
      };

      const { fragment, fragmentText, associatedEntities } = this.makeFragment({
        entityId: entityType.id,
        configName: entityType.configName,
        fragmentType: FragmentType.DEEP,
      });
      this.fragmentAssociatedEntities[entityId] = associatedEntities;
      this.fragmentMap[FragmentType.DEEP][entityId] = {
        document: fragment,
        text: fragmentText,
      };

      const {
        fragment: onlyIncarnationFragment,
        fragmentText: onlyIncarnationText,
      } = this.makeFragment({
        entityId: entityType.id,
        configName: entityType.configName,
        fragmentType: FragmentType.ONLY_INCARNATION,
      });
      this.fragmentMap[FragmentType.ONLY_INCARNATION][entityId] = {
        document: onlyIncarnationFragment,
        text: onlyIncarnationText,
      };
    });

    const getAllAssociatedEntities = (
      entityId: string,
      associatedEntities: { [entityId: string]: boolean },
    ) => {
      const associatedEntityIds =
        this.fragmentAssociatedEntities[
          MetadataSupport.getUnqualifiedName(entityId)
        ];

      if (!associatedEntityIds) {
        throw new Error(
          `Error getting associated entities for entity ${entityId}`,
        );
      }

      associatedEntityIds.forEach((assocEntityId) => {
        if (!associatedEntities[assocEntityId]) {
          associatedEntities[assocEntityId] = true;
          getAllAssociatedEntities(assocEntityId, associatedEntities);
        }
      });
    };

    // Hook up all of each fragment's associated entities (transitively)
    const newFragmentMap: FragmentMapType = {};
    Object.keys(this.fragmentMap[FragmentType.DEEP]).forEach((entityId) => {
      const associatedDocs = [];
      const associatedTexts = [];
      const associatedEntities = {};
      getAllAssociatedEntities(entityId, associatedEntities);
      Object.keys(associatedEntities).forEach((assocEntityId) => {
        associatedDocs.push(
          this.fragmentMap[FragmentType.DEEP][assocEntityId].document,
        );
        associatedTexts.push(
          this.fragmentMap[FragmentType.DEEP][assocEntityId].text,
        );
      });
      const newFragmentDoc = concatAST([
        this.fragmentMap[FragmentType.DEEP][entityId].document,
        ...associatedDocs,
      ]);
      const newFragmentText =
        this.fragmentMap[FragmentType.DEEP][entityId].text +
        associatedTexts.join('\n');
      newFragmentMap[entityId] = {
        document: newFragmentDoc,
        text: newFragmentText,
      };
    });
    this.fragmentMap[FragmentType.DEEP] = newFragmentMap;
    logger.debug('SchemaManager initialized FragmentMap');
  }

  private makeFragment(params: {
    entityId: string;
    configName: string;
    fragmentType: FragmentType;
  }): {
    fragment: DocumentNode;
    fragmentText: string;
    associatedEntities: string[];
  } {
    const { entityId, configName, fragmentType } = params;

    const entity = this.getEntityType({
      name: entityId,
      configName,
    });

    const typeDef = this.getTypeDefinition({
      name: entity.typeDefinition,
      configName: entity.configName,
    });

    const associatedEntities = [];
    let fieldList;
    switch (fragmentType) {
      case FragmentType.DEEP:
      case FragmentType.SHALLOW:
      case FragmentType.SHALLOW_PLUS_ID:
        fieldList = makeGraphqlFieldList({
          typeDef,
          fragmentType,
          associatedEntities,
          schemaManager: this,
          metadataSupport: this.metadataSupport,
          schemaManagerInit: true,
        });
        break;
      case FragmentType.ONLY_INCARNATION:
        fieldList = '';
        break;
    }
    const fragmentText = ` fragment ${getFragmentNameFromEntityId(
      MetadataSupport.getUnqualifiedName(entityId),
      fragmentType,
    )} on ${MetadataSupport.getUnqualifiedName(
      typeDef.id,
    )} { ${fieldList} ${INCARNATION} }`;
    return { fragment: gql(fragmentText), fragmentText, associatedEntities };
  }

  public async setStackUseTestData(value: UseTestData) {
    const stackConfig = this.clientManager.getStackConfig();
    stackConfig.useTestData = value;
    await this.clientManager.stackInfo.updateStackInfo();
  }

  public setUseTestData(value: UseTestData) {
    this.useTestData = value;
  }

  public isTestData(
    entityType: IEntityType,
    useTestData: UseTestData,
  ): boolean {
    if (entityType.isConfiguration) {
      return false;
    }
    switch (useTestData) {
      case UseTestData.ALL:
        return !entityType.neverUseTestData;
      case UseTestData.NONE:
        return false;
      case UseTestData.ONLY_SUPPORTED_ENTITIES:
        return entityType.supportsTestData;
      case UseTestData.PERMISSION_MANAGER_TEST:
        return true;
    }
  }

  public getSchemaInfo(): ISchemaInfo {
    return {
      schema: this.graphQLSchema,
      entityIdToConfigName: this.entityIdToConfigName,
      typeDefIdToConfigName: this.typeDefIdToConfigName,
    };
  }

  public getSchemaInfoServer(): ISchemaInfoServer {
    if (!isNode) {
      throw new Error('getServerSchemaInfo cannot be called in the browser');
    }
    return {
      resolverMap: this.resolverMap,
      parsedSchema: this.parsedGraphQLSchema,
    };
  }

  public getEnvironmentString() {
    return `Execution Config: ${this.executionConfigName}, Data Table: ${this.dataTableName}, Metadata Table: ${this.metadataTableName}`;
  }

  public qualifyGetMetadata(params: IMetadataRequest, recordType) {
    const { name, configName: inputConfigName, parentRecord, noThrow } = params;

    if (!exists(name)) {
      throw new Error(`Cannot get ${recordType} ${name}`);
    }

    let qualifiedName: string;

    if (MetadataSupport.isQualifiedName(name)) {
      qualifiedName = name;
    } else if (inputConfigName) {
      qualifiedName = MetadataSupport.getQualifiedName(
        name,
        inputConfigName,
        noThrow,
      );
    } else if (parentRecord && parentRecord.configName) {
      qualifiedName = MetadataSupport.getQualifiedName(
        name,
        parentRecord.configName,
        noThrow,
      );
    } else if (this.entityIdToConfigName[name]) {
      qualifiedName = MetadataSupport.getQualifiedName(
        name,
        this.entityIdToConfigName[name],
        noThrow,
      );
    } else if (this.typeDefIdToConfigName[name]) {
      qualifiedName = MetadataSupport.getQualifiedName(
        name,
        this.typeDefIdToConfigName[name],
        noThrow,
      );
    } else {
      if (noThrow) {
        return undefined;
      }
      throw new Error(
        `When trying to get ${recordType} ${name}, can't figure out configName from params ${safeJsonStringify(
          params,
          null,
          2,
        )}`,
      );
    }

    const result = this[recordType][qualifiedName];
    if (!result && !noThrow) {
      throw new Error(
        `Error getting ${recordType} ${name}. params: ${safeJsonStringify(
          params,
          null,
          2,
        )}`,
      );
    }
    return result;
  }

  // Used in the case where the config might have changed in a development stack
  // but we are running in some lambda server that does not know about the change
  // and we need the current one.
  public async refreshExecutionConfig() {
    await this.clientManager.stackInfo.refreshStackInfo(true);
    const { executionConfigName } = this.clientManager.getStackConfig();

    if (this.executionConfigName !== executionConfigName) {
      await this.changeExecutionConfig(executionConfigName);
    }
  }

  public async changeExecutionConfig(
    configName: string,
    updateDatabase?: boolean,
  ) {
    if (!this.availableExecConfigNames.find((acn) => acn === configName)) {
      throw new Error(
        `Cannot change the execution config to ${configName} as it's not an available config name`,
      );
    }

    const stackInfo = this.clientManager.stackInfo;
    if (this.executionConfigName !== configName || updateDatabase) {
      if (updateDatabase) {
        logger.info(
          `Execution configuration in configuration database updated to: ${configName}`,
        );
        if (isBrowser) {
          try {
            await this.clientManager.pipelineManager.executePipelineRemote({
              stages: [
                {
                  _stageType: StageType.javaScript,
                  code: `clientManager.getStackConfig().executionConfigName = '${configName}';
                       await clientManager.stackInfo.updateStackInfo();`,
                },
              ],
            });
          } catch (error) {
            reThrow({
              logger,
              error,
              message: 'Problem setting execution config',
            });
          }
        } else {
          this.clientManager.getStackConfig().executionConfigName = configName;
          await stackInfo.updateStackInfo();
        }
      }
      this.executionConfigName = configName;

      this.clientManager.setDefaultLocale(
        stackInfo.getStackConfig().systemConfigs[this.executionConfigName]
          .defaultLocale,
      );

      // No database for the system config, but that can sometimes be OK
      // if we are in a process that does not care about that
      if (configName !== SYSTEM) {
        this.dataTableName = getCustomerDatabaseName({
          stackInfo,
          configName,
          suffix: this.appDatabaseSuffix,
        });
      }

      logger.info(
        `Execution configuration changed to: ${configName} - data table changed to ${this.dataTableName}`,
      );
    }
  }

  public async createOrDeleteEntityType(
    params: IMetadataModificationRequest,
  ): Promise<IEntityType> {
    return this.modifyMetadataType({
      ...params,
      recordType: DATA_DEF_ENTITY_TYPE,
    }) as unknown as IEntityType;
  }

  public async createOrDeleteTypeDefinition(
    params: IMetadataModificationRequest,
  ): Promise<TypeDefinition> {
    return this.modifyMetadataType({
      ...params,
      recordType: DATA_DEF_TYPE_DEFINITION,
    }) as unknown as TypeDefinition;
  }

  private async modifyMetadataType(
    params: IMetadataModificationRequest,
  ): Promise<IEntityType | TypeDefinition> {
    const {
      record,
      recordType,
      create,
      commitToStore = true,
      branchName,
    } = params;

    logger.debug(
      `${create ? 'creating' : 'deleting'} ${recordType} ${record.id}`,
    );

    let retVal = record;
    record[TYPE_NAME] = recordType;
    if (create) {
      if (record.id) {
        (record as any).configName = MetadataSupport.getConfigName(record.id);
      }
      if (recordType === DATA_DEF_TYPE_DEFINITION) {
        let typeDef;
        if (!(record instanceof TypeDefinition)) {
          typeDef = new TypeDefinition(record);
          retVal = typeDef;
        } else {
          typeDef = record;
          this.removeComputedFields(typeDef, recordType);
        }
        typeDef.initializeComputedFields(this);
        record[TYPE_NAME] = recordType;
        if (typeDef.id) {
          this[recordType][record.id] = typeDef;
        }
      } else {
        this.removeComputedFields(record as IEntityType, recordType);
        record[TYPE_NAME] = recordType;
        this[recordType][record.id] = record;
      }
    } else {
      delete this[recordType][record.id];
    }

    if (commitToStore) {
      await this.commitToStore();
    }

    if (branchName) {
      logger.info('Committed change to source');
      const githubToken = process.env.GITHUB_TOKEN;
      const commitMessage = `Add ${record.id} ${recordType} [ci skip]`;

      const storeParams = {
        githubToken,
        branchName,
        commitMessage,
        configItems: [record],
        clientManager: this.clientManager,
      };

      await storeConfigItems(storeParams);
      logger.info('Committed change to source');
    }
    return retVal as TypeDefinition | IEntityType;
  }

  public async commitToStore() {
    logger.info('Reinitializing schema manager due to metadata modification');

    await this.initialize(this.clientManager, {
      buildNow: true,
      entityTypes: this.getAllEntityTypes(false),
      typeDefs: this.getAllTypeDefinitions(false),
    });
    await this.clientManager.lambdaSupport.restartLambdaFunctions();
  }

  public getAttributeNames({
    name,
    configName,
  }: {
    name: string;
    configName?: string;
  }) {
    const entity = this.getEntityType({
      name,
      configName,
    });

    const typeDefinition = this.getTypeDefinition({
      name: entity.typeDefinition,
      parentRecord: entity,
    });

    return typeDefinition.getAttributes().map((attribute) => attribute.name);
  }

  private getFragmentEntry(
    entityId,
    fragmentType: FragmentType,
  ): FragmentMapValueType {
    const unqualEntityId = MetadataSupport.getUnqualifiedName(entityId);
    const fragmentEntry = this.fragmentMap[fragmentType][unqualEntityId];
    if (!fragmentEntry) {
      throw new Error(`Fragment not found for entity: ${unqualEntityId}`);
    }
    return fragmentEntry;
  }

  public getFragment(
    entityId: string,
    fragmentType: FragmentType,
  ): DocumentNode {
    return this.getFragmentEntry(entityId, fragmentType).document;
  }

  public getFragmentText(entityId: string, fragmentType: FragmentType): string {
    return this.getFragmentEntry(entityId, fragmentType).text;
  }

  public getFragmentFromName(fragmentName: string): DocumentNode {
    const { fragmentType, entityId } =
      getFragmentTypeAndEntityIdFromFragmentName(fragmentName);
    return this.getFragmentEntry(entityId, fragmentType).document;
  }
}

export function getCustomerDatabaseName(params: {
  stackInfo: StackInfo;
  configName: string;
  suffix?: string;
}) {
  const { configName, stackInfo, suffix } = params;

  if (configName === SYSTEM) {
    throw new Error('system does not have a database');
  }

  const { databaseOverride } = stackInfo.getStackConfig();
  if (databaseOverride) {
    return databaseOverride;
  }

  if (suffix) {
    return `${configName}${suffix}`;
  } else {
    return configName;
  }
}
