import pino from 'pino/browser';

import { levelToDatadogStatus, pinoLogOptions } from './loggerSupport';

//"debug" | "warning" | "error" | "critical" | "info"
const LEVEL_STYLES = {
  warning:
    'background: light-dark(#fef6d5, #413c26); color: light-dark(#000, #fdf3aa)',
  error:
    'background: light-dark(#fcecec, #4e3534); color: light-dark(#000, #f9dedc)',
  critical:
    'background: light-dark(#fcecec, #4e3534); color: light-dark(#000, #f9dedc)',
};

const LEVEL_ICONS = {
  warning: '⚠️ ',
  error: '❌ ',
  critical: '❌ ',
};

// Don't add the Logger type to this function as it will import the 'pino' module which is not
// available in the browser (even though it's importing it just to get the Typescript definition)

// This is referred to only by loggerSupport, and the import for it is rewritten by the build
// tooling for the client and svelte client
export default function getPino() {
  return pino({
    ...pinoLogOptions,
    // Browser requires the maximum level to be set here
    level: 'trace',
    browser: {
      write: (o: any) => {
        const payload = Object.assign({}, o) as any;
        delete payload.level;
        delete payload.name;
        delete payload.msg;
        delete payload.time;

        const date = new Date(o.time).toLocaleTimeString('en-US', {
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
          hour12: false,
          // @ts-ignore
          fractionalSecondDigits: 3,
        });

        const displayPayload = Object.keys(payload).length > 0 ? payload : '';

        const datadogStatus = levelToDatadogStatus(o.level);
        const style = LEVEL_STYLES[datadogStatus] ?? '';

        const icon = LEVEL_ICONS[datadogStatus] ?? '';

        const logMessage: any[] = [
          `%c${icon}${date} [${datadogStatus}/${o.name}] %c${o.msg}`,
          style,
          `font-weight: normal; ${style};`,
        ].filter((it) => it !== undefined);

        console.groupCollapsed(...logMessage, displayPayload);
        if (!window['jsdom']) {
          // don't print a stack trace in jsdom, it makes the console output too noisy
          console.trace('Stack Trace');
        }
        console.groupEnd();
      },
    },
  });
}
