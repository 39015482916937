import { Menu } from '@mui/material';

import {
  generateChildren,
  makeThemedStyles,
  withActions,
} from '../dependencies';

import { MenuWidgetProps, menuWidgetTypeInfo } from './MenuWidgetTypeInfo';

export { menuWidgetTypeInfo };

const useStyles = makeThemedStyles<MenuWidgetProps>(() => ({
  menu: (props) => ({
    width: props.width,
    height: props.height,
    maxWidth: props.maxWidth,
    maxHeight: props.maxHeight,
  }),
}));

const MenuWidget: React.FC<React.PropsWithChildren<MenuWidgetProps>> = (
  props,
) => {
  function handleClickAway() {
    const { actionReference } = props;
    const { close } = actionReference;
    close();
  }

  const {
    actionReference,
    anchorReference,
    anchorOriginVertical,
    anchorOriginHorizontal,
    transFormOriginVertical,
    transFormOriginHorizontal,
    component: { children },
    aliases,
    context,
    passThroughProps,
  } = props;
  const { anchor } = actionReference;
  const { element, left, top } = anchor;
  const Children = generateChildren({
    childWidgets: children,
    aliases,
    childContext: context.childContext,
    passThroughProps,
  });

  const classes = useStyles(props);

  return (
    <Menu
      className={classes.menu}
      onClose={handleClickAway}
      anchorEl={element}
      anchorPosition={{ left, top }}
      anchorReference={anchorReference}
      anchorOrigin={{
        vertical: anchorOriginVertical,
        horizontal: anchorOriginHorizontal,
      }}
      transformOrigin={{
        vertical: transFormOriginVertical,
        horizontal: transFormOriginHorizontal,
      }}
      open={true}
    >
      {Children}
    </Menu>
  );
};

export default withActions(MenuWidget);
