// import { getLogger, Loggers } from './loggerSupport';

import { ClientManager } from './clientManager';
import { SYSTEM } from './common/commonConstants';
import { MetadataSupport } from './metadataSupport';
import { APP_DEF_ICON, IHasIdAndData } from './metadataSupportConstants';

// const logger = getLogger({ name: Loggers.CONFIGURATION_OBJECT_MANAGER });

export class AppDefConfigManager {
  public clientManager: ClientManager;

  private cache: { [appDefName: string]: { [name: string]: IHasIdAndData } } =
    {};
  private initPromise: { [appDefName: string]: Promise<any> } = {};
  private initPromiseResolver: { [appDefName: string]: any } = {};

  public initialize(clientManager: ClientManager) {
    this.clientManager = clientManager;
  }

  // FIXME - right now, only supporting icons, move other appdef things to this at some point
  public async getAppDefObject(params: {
    appDefName: string;
    name: string;
    configName?: string;
  }): Promise<IHasIdAndData> {
    const { name, appDefName, configName } = params;
    if (appDefName !== APP_DEF_ICON) {
      throw new Error(`This is current supported only for ${APP_DEF_ICON}`);
    }

    const nameToUse = MetadataSupport.getQualifiedName(name, configName);

    let cacheEntry = this.cache[appDefName];
    if (!cacheEntry) {
      if (this.initPromise[appDefName]) {
        await this.initPromise[appDefName];
        cacheEntry = this.cache[appDefName];
      } else {
        this.initPromise[appDefName] = new Promise((resolve) => {
          this.initPromiseResolver[appDefName] = resolve;
        });

        const result = await this.clientManager.pipelineManager.listRecords({
          entityName: appDefName,
          configName: SYSTEM,
        });
        this.cache[appDefName] = cacheEntry = {};
        result.items.forEach((r) => (cacheEntry[r.id] = r));
        if (this.initPromiseResolver[appDefName]) {
          this.initPromiseResolver[appDefName]();
        }
      }
    }
    return cacheEntry[nameToUse];
  }

  //
  // Convenience methods
  //

  public async getIconObject(name): Promise<IHasIdAndData> {
    return this.getAppDefObject({ appDefName: APP_DEF_ICON, name });
  }
}
