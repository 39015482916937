import { AdditionalEditor, BasicType } from 'universal/types';

import {
  buttonProperties,
  entityProperties,
  IConfigProperties,
  IConfigurationType,
  IWidgetProps,
  stringFormat,
  stringStyle,
  textVariants,
} from '../types';

export const kendoToolbarTypeInfo: IConfigurationType = {
  nestedConfigItem: true,
  types: {
    kendoToolbars: true,
  },
  childTypes: {
    children: [
      'KendoExportButton',
      'Button',
      'Text',
      'DropdownSelect',
      'CheckboxWidget',
      'Chip',
      'LayoutWidget',
      'TreeLink',
    ],
  },
  properties: {},
};

const exportButtonProperties = {
  fileName: {},
  ...buttonProperties,
  closePopupOnClick: {
    default: true,
    options: [true, false],
    description: 'if true, this will close the popup this is within',
  },
  label: { default: "'export'" },
} as const;

export type ExportButtonProps = IWidgetProps<typeof exportButtonProperties>;

export const kendoExportButtonTypeInfo = {
  types: {
    children: true,
  },
  childTypes: {
    exportConfig: true,
  },
  properties: exportButtonProperties,
} as const;

export const kendoColumnProperties = {
  field: {},
  title: {},
  order: {
    type: BasicType.Int,
  },
  type: {
    default: "'text'",
    options: ["'text'", "'numeric'", "'boolean'", "'date'"],
  },
  locked: {
    default: false,
    options: [true, false],
  },
  groupable: {
    default: true,
    options: [true, false],
    description: 'If the grid is groupable, the column can opt out',
  },
  filterable: {
    default: true,
    options: [true, false],
    description: 'If the grid is filterable, the column can opt out',
  },
  aggregate: {
    default: false,
    options: [false, "'sum'", "'count'", "'average'", "'min'", "'max'"],
    description:
      'If set, this aggregation will automatically be calculated and made available to the GROUPFOOTER only',
  },
  width: {
    pointer: 'can',
  },
  minWidth: {
    description:
      'The width of the column (in pixels) below which the user is not able to resize the column through the UI',
  },
  mergeLikeCells: {
    default: false,
    options: [true, false],
    description:
      'If true, will merge cells across rows if the values are identical. Disabled with virtualization.',
  },
  dynamicColumns: {
    pointer: 'must',
    description:
      'An optional pointer to an array or nested array that represent dynamic columns. The member of the array that represents each column must be an object with field and title, or optionally template, which points to a column defined in column template, whose properties will be resolved against the contents of the source object. To a group column with nested columns underneath it, it must have its own dynamicColumns field that represents its sub columns as well as either title or template with fields to resolve to both title and dynamicColumns.',
  },
  dynamicColumnsInstanceName: {
    pointer: 'never',
    description:
      'Only relevent for dynamic columns with child columns, this is the name you can refer to the instance of the dynamic columns by within the configuration of the children columns',
  },
  duplicateBy: { pointer: 'must' },
  instanceName: { pointer: 'never' },
  cellInstanceName: {
    pointer: 'never',
    description:
      'Will create an alias pointing to the cell data, same as _cell for single generation, usefull for accessing group cells from cells within it.',
  },
  cellPropertyAccessor: {
    description:
      'A accessor that will select a value from row for the cell to compile properties against. It will be available to cells as _cellProperties. Allows custom values while keeping field pointed to the sortable and filterable value.',
  },
  permissionId: {},
  render: {},
  preventRender: {},
  align: {
    default: "'auto'",
    options: ["'left'", "'right'", "'center'", "'auto'"],
  },
  headerFontSize: {
    default: "'body2'",
    options: textVariants,
  },
  headerVerticalAlign: {
    options: ["'top'", "'middle'", "'bottom'"],
  },
  defaultCellFontSize: {
    default: "'body2'",
    options: textVariants,
  },
} as const;

export const kendoColumnTypeInfo: IConfigurationType = {
  nestedConfigItem: true,
  types: {
    kendoColumns: true,
  },
  childTypes: {
    kendoColumns: true,
    kendoCells: true,
  },
  properties: kendoColumnProperties,
};

export const kendoCellTypeInfo: IConfigurationType = {
  nestedConfigItem: true,
  types: {
    kendoCells: true,
  },
  childTypes: {
    children: true,
  },
  properties: {
    cellType: {
      default: "'cell'",
      options: ["'cell'", "'headerCell'", "'footerCell'", "'groupFooter'"],
      description:
        'Only one of each cell type should be configured on each column, and on the grid. A cell of a certain type will override the cell of the same time configured on the grid.',
    },
    customAggregation: {
      description:
        'Only for footer and groupFooter cells, this must a string refering to a function in javascriptStageFunctions. Footers will be passed _rows, and _column, group footers will be passed _group rows in addition.',
    },
    customAggregationParameter: {
      description:
        'Additional data to be made available within the custom aggregator function named above',
    },
    excelFormat: {
      description: 'Sets the format that Excel uses to display the cell value.',
    },
    ...stringFormat,
    ...stringStyle,
    textWrap: {
      default: true,
      options: [true, false],
    },
    color: {
      additionalEditor: AdditionalEditor.Color,
    },
    backgroundColor: {
      additionalEditor: AdditionalEditor.Color,
    },
    tooltip: {
      description: 'Text to be shown as a tooltip',
    },
    display: {
      description:
        'Final value that will be shown in the cell. Defaults to _widget.cellContext, which is a value available only at render. Can be replaced or decorated here. Works on footers, but not cells.',
      default: '_widget.cellContent',
    },
  },
};

const kendoRowProperties = {
  rows: {
    pointer: 'must',
  },
  groups: {
    pointer: 'never',
    description:
      'a comma separated string representing an array of fields to group by.',
  },
  sortBy: {
    description:
      'A comma separated string, representing an array of fields to sort by. Set sortable to false if you want this sort order to be static.',
  },
  dynamicColumns: {
    pointer: 'must',
    description:
      'An optional pointer to an array or nested array that represent dynamic columns. The member of the array that represents each column must be an object with field and title, or optionally template, which points to a column defined in column template, whose properties will be resolved against the contents of the source object. To a group column with nested columns underneath it, it must have its own dynamicColumns field that represents its sub columns as well as either title or template with fields to resolve to both title and dynamicColumns.',
  },
} as const;

const kendoExportProperties = {
  ...kendoRowProperties,
} as const;

export type KendoExportProperties = IConfigProperties<
  typeof kendoExportProperties
>;

export const kendoExportTypeInfo: IConfigurationType = {
  nestedConfigItem: true,
  types: {
    exportConfig: true,
  },
  childTypes: {
    kendoColumns: true,
    kendoColumnTemplates: true,
    kendoAggregates: true,
  },
  properties: kendoExportProperties,
};

const kendoGridProperties = {
  ...kendoRowProperties,
  ...entityProperties,
  rowInstanceName: {
    pointer: 'can',
    description: 'optional user defined alias name give to rows.',
  },
  rowHeight: {
    description:
      'Number, optional, if not specified, rows and groupFooters will resize to fit context. Required if scrolling is virtualized',
    type: BasicType.Float,
  },
  footerHeight: {
    description:
      'Number, optional, sets the footer height, not the groupFooter height.',
    type: BasicType.Float,
  },
  headerHeight: {
    description:
      'Number, optional, the size of all of the header elements of the grid, used only to have the grid size to the number of actual rows, if possible.',
    type: BasicType.Float,
  },
  rowHeightForMaxCalculation: {
    description:
      'Number, optional, the height of the row to be used in the size calculation, oddly different from the rowHeight.',
    type: BasicType.Float,
  },
  maxRows: {
    description:
      'Number, optional, the maximum number of rows to be displayed in the scrollable area, otherwise, it will fill the available space.',
    type: BasicType.Float,
  },
  dynamicColumnsInstanceName: {
    pointer: 'never',
    description:
      'Only relevant for dynamic columns with child columns, this is the name you can refer to the instance of the dynamic columns by within the configuration of the children columns',
  },
  reorderable: {
    default: true,
    options: [true, false],
  },
  resizable: {
    default: true,
    options: [true, false],
  },
  groupable: {
    default: true,
    options: [true, false],
    description: 'Whether the user has control of the grouping.',
  },
  groupFooters: {
    default: "'none'",
    options: ["'always'", "'visible'", "'none'"],
    description:
      'set if there are group footers, and if they should be visible when the group is collapsed.',
  },
  sortable: {
    default: true,
    options: [true, false],
  },
  allowUnsort: {
    default: true,
    options: [true, false],
  },
  sortMode: {
    default: "'single'",
    options: ["'multiple'", "'single'"],
  },
  filterable: {
    default: true,
    options: [true, false],
  },
  excelExport: {
    default: false,
    options: [true, false],
    description: 'deprecated',
  },
  exportFileName: {
    default: "'export'",
  },
  padding: {
    description:
      'will set the padding for the while cell if paddingSides is not set, or for the top and botton if it is',
  },
  paddingSides: {
    description: 'sets the padding for the sides',
  },
  cellBorder: {
    default: "'none'",
    options: ["'paper'", "'solid'", "'dotted'", "'dashed'", "'none'"],
  },
  cellBorderWidth: {
    default: '_defaultGridCellBorderWidth',
    description: 'Thickness of non paper border, units: px, pt, cm',
  },
  cellBorderColor: {
    default: '_defaultGridCellBorderColor',
    description: 'Color of non paper border',
    additionalEditor: AdditionalEditor.Color,
  },
  headerBorder: {
    default: "'solid'",
    options: ["'paper'", "'solid'", "'dotted'", "'dashed'", "'none'"],
  },
  headerBorderWidth: {
    default: '_defaultHeaderCellBorderWidth',
    description: 'Thickness of non paper border, units: px, pt, cm',
  },
  headerBorderColor: {
    default: '_defaultHeaderCellBorderColor',
    description: 'Color of non paper border',
    additionalEditor: AdditionalEditor.Color,
  },
  oddRowColor: {
    default: '_defaultOddRowColor',
    additionalEditor: AdditionalEditor.Color,
  },
  evenRowColor: {
    default: '_defaultEvenRowColor',
    description:
      'Disabled with virtualization, will take oddRowColor if virtualization is turned on.',
    additionalEditor: AdditionalEditor.Color,
  },
  hoverRowColor: {
    additionalEditor: AdditionalEditor.Color,
  },
  groupRowColor: {
    additionalEditor: AdditionalEditor.Color,
  },
  filterRowColor: {
    additionalEditor: AdditionalEditor.Color,
  },
  headerColor: {
    additionalEditor: AdditionalEditor.Color,
  },
  lockedHeaderColor: {
    additionalEditor: AdditionalEditor.Color,
  },
  groupingHeaderColor: {
    additionalEditor: AdditionalEditor.Color,
  },
  scrollable: {
    default: "'scrollable'",
    options: ["'scrollable'", "'virtual'", "'none'"],
  },
  debounceCells: {
    default: false,
    options: [true, false],
    description:
      'Setting this to true will render only the basic kendo cells during scrolling, with a loading indicator if the cell does not resolve to a string. Improves virtualized scrolling performance.',
  },
  pageSize: {
    default: 20,
    description:
      'Only used if scrollable is set to virtual, sets the paging size used for the virtualization.',
  },
} as const;

export type KendoGridProps = IWidgetProps<typeof kendoGridProperties>;
export const kendoGridTypeInfo: IConfigurationType = {
  types: {
    children: true,
  },
  childTypes: {
    kendoColumns: true,
    kendoColumnTemplates: true,
    kendoAggregates: true,
    exportConfig: true,
    overlays: true,
    kendoToolbars: true,
  },
  properties: kendoGridProperties,
  childProperties: {},
  events: {
    load: [],
  },
};

export const kendoAggregateTypeInfo: IConfigurationType = {
  nestedConfigItem: true,
  types: {
    kendoAggregates: true,
  },
  childTypes: {},
  properties: {
    field: {
      description: 'accessor to any field on the rows to aggregate by',
    },
    aggregate: {
      default: "'sum'",
      options: ["'sum'", "'count'", "'average'", "'min'", "'max'"],
    },
  },
};
