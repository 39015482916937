import { BaseLogger } from 'pino';

import { ClientManager } from './clientManager';

export interface IJavascriptBaseArgs {
  clientManager?: ClientManager;
  libraries?: any;
  _?: any;
  ml?: any;
  dayjs?: any;
  dayjsOriginal?: any;
  logger?: BaseLogger;
}

interface IExecuteJavascriptArgs {
  jsCodeToExecute: string;
  jsArgs?: IJavascriptBaseArgs;
  maybeAddReturnStatement?: boolean;
  evaluationErrorMessage?: string;
}

const fixupCodeForReturn = (code: string): string => {
  const trimmed = code.trim();
  return trimmed.startsWith('{') ? trimmed.slice(1, -1) : `return ${trimmed}`;
};

export const executeJavascript = async (
  params: IExecuteJavascriptArgs,
): Promise<any> => {
  const { jsArgs = {}, evaluationErrorMessage } = params;
  let { jsCodeToExecute } = params;
  if (params.maybeAddReturnStatement) {
    jsCodeToExecute = fixupCodeForReturn(jsCodeToExecute);
  }
  try {
    // tslint:disable-next-line:only-arrow-functions
    const asyncFunction = Object.getPrototypeOf(
      async function () {},
    ).constructor;
    return await asyncFunction(
      ...Object.keys(jsArgs),
      jsCodeToExecute,
    )(...Object.values(jsArgs));
  } catch (error) {
    handleError(error, jsCodeToExecute, evaluationErrorMessage);
  }
};

export const executeJavascriptSync = (params: IExecuteJavascriptArgs): any => {
  const { jsArgs = {}, evaluationErrorMessage } = params;
  let { jsCodeToExecute } = params;
  if (params.maybeAddReturnStatement) {
    jsCodeToExecute = fixupCodeForReturn(jsCodeToExecute);
  }
  try {
    // eslint-disable-next-line @typescript-eslint/no-implied-eval
    return new Function(...Object.keys(jsArgs), jsCodeToExecute)(
      ...Object.values(jsArgs),
    );
  } catch (error) {
    handleError(error, jsCodeToExecute, evaluationErrorMessage);
  }
};

export const handleError = (
  error,
  jsCodeToExecute: string,
  evaluationErrorString?: string,
) => {
  let origLine;
  let origCol;

  let lineNumLength;
  const jsCodeLines = (jsCodeArray: string[]): string[] => {
    lineNumLength = `${jsCodeArray.length}`.length;
    const pad = (num) => ('000000000' + num).substr(-lineNumLength);
    return jsCodeArray.map((line, index) => `${pad(index + 1)} | ${line}`);
  };

  const codeArray = jsCodeLines(jsCodeToExecute.split('\n'));
  if (error.lineNumber && error.column) {
    origLine = error.lineNumber;
    origCol = error.column;
  } else if (error.loc) {
    // Acorn
    origLine = error.loc.line;
    origCol = error.loc.column;
  } else if (error.stack) {
    const stackArray = error.stack.split('\n');

    // First is v8 (node/chrome), Second is MDN standard (all others)
    [/<anonymous>:(.*):(.*)\)/, / > Function:(.*):(.*)/].find((e) => {
      return stackArray.find((line) => {
        const location = line.match(e);
        if (location && location.length >= 3) {
          const [lineStr, colStr] = location.slice(-2);
          origLine = parseInt(lineStr, 10) - 2;
          origCol = parseInt(colStr, 10);
          return true;
        }
        return false;
      });
    });
  }

  if (origLine !== undefined) {
    const startLineIndex = Math.max(0, origLine - 3);
    const endLineIndex = Math.min(codeArray.length, origLine + 2);

    const errorLines = [];
    for (let i = startLineIndex; i < endLineIndex; i++) {
      errorLines.push(codeArray[i]);
      if (i === origLine - 1) {
        errorLines.push(`${new Array(origCol + lineNumLength + 3).join(' ')}^`);
      }
    }
    const errorLine = errorLines.join('\n');
    const errorLocation = ` [executing Javascript - line ${origLine}:col ${origCol}]\n${errorLine}`;
    error.message = `${error.message}${errorLocation}`;
    throw error;
  }

  // This can provide better information (because the evaluation is done by a different pre-processor)
  // in the SyntaxError case with node, since node does not provide location information
  if (evaluationErrorString) {
    error.message = `${error.message}\n${evaluationErrorString}`;
    throw error;
  }

  // We don't have any error location (happens with node syntax errors) - so just provide the whole code so
  // there is some hope of finding the problem
  error.message = `${error.message}\n [executing Javascript code]:\n${jsCodeToExecute}`;
  throw error;
};
