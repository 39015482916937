import {
  CellClassParams,
  ExcelStyle,
  ICellRendererParams,
  IRowNode,
  RowEvent,
} from '@ag-grid-community/core';
import { DragHandle } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import _ from 'lodash';
import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { getLogger, Loggers } from 'universal/loggerSupport';
import {
  IGridCssStyling,
  IPresentationInfo,
} from 'universal/metadataSupportConstants';
import { IItemType, TypeDefinition } from 'universal/typeDefinition';
import { BasicType } from 'universal/types';

import { IAliases } from '../../../store/context';
import { getIcon } from '../../../util/clientUtilities';
import Widget from '../../widgetEngine/Widget';
import { IElementAttributes } from '../types';

import {
  getNestedGroupParents,
  getRowIdFromData,
  IGridContext,
  IGroupData,
  IProps,
} from './AGGrid';
import { NumericEditor } from './AGGridCellEditors';
import './styles.css';

const logger = getLogger({ name: Loggers.CLIENT });

export interface IWidgetCellRendererParams {
  widget: any;
  props: IProps;
  rowAlias: string;
  attr: IItemType;
}

export interface IIconCellRendererParams {
  attr: IItemType;
  configName: string;
}

export interface IRowFormatParams {
  field: string;
  formatting: ICellFormatParams;
}

export interface ICellFormatParams {
  [key: string]: string;
}
export const standardFormats = {
  highlight: { backgroundColor: '#c3ebeb' },
  editable: { backgroundColor: '#f5f5d0' },
};
/* The StandardCssFormats can either be a CSS class, or be a function that takes parameters and returns a CSS class */
export const standardCssFormats = {
  editable: 'editable',
  heatMap3: {
    ['heatMap-null']: (params) => {
      const value = heatMapValues(params);
      return [undefined, null].includes(value);
    },
    ['heatMap-red']: (params) => {
      const value = heatMapValues(params);
      if (_.isNumber(value)) return value <= params.bucket1;
      return value === params.bucket1;
    },
    ['heatMap-yellow']: (params) => {
      const value = heatMapValues(params);
      if (_.isNumber(value)) {
        return value > params.bucket1 && value < params.bucket2;
      }
      return value === params.bucket2;
    },
    ['heatMap-green']: (params) => {
      const value = heatMapValues(params);
      if ([undefined, null].includes(value)) {
        return false;
      }
      if (_.isNumber(value)) return value >= params.bucket2;
      return value === params.bucket3;
    },
  },
};

function heatMapValues(params): any {
  return _.isObject(params.value) ? params.value.value : params.value;
}
export const excelStyleList: ExcelStyle[] = [
  {
    id: 'header',
    alignment: {
      vertical: 'Center',
      wrapText: true,
    },
    interior: {
      color: '#f8f8f8',
      pattern: 'Solid',
    },
    font: {
      bold: true,
      size: 12,
      color: '#000000',
    },
    borders: {
      borderTop: {
        color: '#d2d2d2',
        weight: 1,
      },
      borderBottom: {
        color: '#d2d2d2',
        weight: 1,
      },
      borderLeft: {
        color: '#d2d2d2',
        weight: 1,
      },
      borderRight: {
        color: '#d2d2d2',
        weight: 1,
      },
    },
  },
  {
    id: 'heatMap-green',
    interior: {
      color: '#8fbc8f',
      pattern: 'Solid',
    },
  },
  {
    id: 'heatMap-yellow',
    interior: {
      color: '#ffff00',
      pattern: 'Solid',
    },
  },
  {
    id: 'heatMap-red',
    interior: {
      color: '#ff7f50',
      pattern: 'Solid',
    },
  },
  {
    id: 'heatMap-null',
    interior: {
      color: '#ffffff',
      pattern: 'Solid',
    },
  },
  {
    id: 'booleanType',
    dataType: 'Boolean',
  },
  {
    id: 'stringType',
    dataType: 'String',
  },
  {
    id: 'dateType',
    dataType: 'DateTime',
  },
  {
    id: 'integerType',
    numberFormat: {
      format: '#,##0',
    },
  },
  {
    id: 'percent0Type',
    numberFormat: {
      format: '0%',
    },
  },
  {
    id: 'percent1Type',
    numberFormat: {
      format: '0.0%',
    },
  },
  {
    id: 'percent2Type',
    numberFormat: {
      format: '0.00%',
    },
  },
  {
    id: 'decimal1Type',
    numberFormat: {
      format: '#,#.0',
    },
  },
  {
    id: 'decimal2Type',
    numberFormat: {
      format: '#,#.00',
    },
  },
];

export const ROW_FORMATTING_FIELD = '_formatting';

export const groupRowRenderer = memo(
  (params: ICellRendererParams<any, any, IGridContext>) => {
    const { node, value, context } = params;
    const { typeDef, groupData, widgetsByName, props, rowData } = context;
    const [expanded, setExpanded] = useState(node.expanded);
    const attr = typeDef.getAttribute(node.field);
    const widgetName = attr?.presentationInfo?.gridFormat?.groupWidgetName;
    const level = getGroupLevel({ typeDef, field: node.field });
    let className = `group-row group-level-${level}`;
    let dragAllowed = attr?.presentationInfo?.itemEnableDrag;
    let valueClassName = null;
    let index = -1;
    let arraySource = props.component.properties.rows as string;

    if (widgetName && !groupData) {
      index = getRowIndexFromGroup({
        context,
        node,
        rows: rowData,
      });
      arraySource = props.component.properties.rows as string;
    }
    /* Check and see if passed in groupData */
    if (groupData) {
      const groupIndex = getGroupIndex({
        node,
        groupData,
      });
      if (groupIndex !== -1) {
        if (groupData[groupIndex]._groupInfo?.noDrag) {
          dragAllowed = false;
        }
        arraySource = props.component.properties.groupData as string;
        index = groupIndex;
        if (groupData[groupIndex]._groupInfo?.className?.length > 0) {
          className =
            className + ' ' + groupData[groupIndex]._groupInfo?.className;
          valueClassName = groupData[groupIndex]._groupInfo?.className;
        }
      }
    }
    if (widgetName && index === -1) {
      logger.info(
        `Group ${node.field} with value ${node.key} does not exist in either GroupData or Rows`,
      );
    }
    if (widgetName && !widgetsByName[widgetName]) {
      throw new Error(`Widget ${widgetName} not found in the widget pool`);
    }
    const dragHandleRef = useRef(null);

    useEffect(() => {
      params.registerRowDragger(dragHandleRef.current);
    });
    useEffect(() => {
      const expandListener = (event: RowEvent) => {
        setExpanded(event.node.expanded);
      };
      node.addEventListener('expandedChanged', expandListener);

      return () => {
        node.removeEventListener('expandedChanged', expandListener);
      };
    }, [node]);

    const onClick = useCallback(() => node.setExpanded(!node.expanded), [node]);

    const { groupChildrenCountFilter } = typeDef.presentationInfo.gridFormat;
    const childCount = node.childrenAfterGroup.filter(
      (child) =>
        !child.data ||
        (!child.data._isPlaceholder &&
          (!groupChildrenCountFilter || groupChildrenCountFilter(child.data))),
    ).length;
    const dragZone = () => {
      if (dragAllowed) {
        return (
          <div
            ref={dragHandleRef}
            className="ag-icon .ag-icon-grip"
            style={{
              display: 'inline-block',
              width: '30px',
              cursor: 'grab',
              marginTop: '-10px',
              margin: '2px',
            }}
          >
            <DragHandle fontSize={'inherit'} />{' '}
          </div>
        );
      }
      return null;
    };
    return (
      <div className={className} style={{ alignItems: 'center' }}>
        {dragZone()}
        <div
          style={{
            cursor: 'pointer',
            transform: expanded ? 'rotate(90deg)' : 'rotate(0deg)',
            display: 'inline-block',
          }}
          onClick={onClick}
        >
          <span style={{ fontSize: '12px' }}>&#x276f; </span>
        </div>
        <div
          style={{
            paddingLeft: '10px',
            paddingRight: '15px',
            display: 'inline-block',
            width: '100%',
            marginRight: '15px',
          }}
        >
          &nbsp;
          <span
            className={valueClassName}
            style={{
              display: 'inline-block',
              width: '300px',
              height: '100%',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {value}&nbsp;({childCount})
          </span>
          <span
            style={{
              display: 'inline-flex',
              alignItems: 'center',
              width: '100%',
              height: '100%',
            }}
          >
            {widgetName && index !== -1
              ? groupWidgetRenderer({
                  arraySource,
                  index,
                  context,
                  widget: widgetsByName[widgetName],
                  passThroughParams: params,
                  field: node.field,
                })
              : null}
          </span>
        </div>
      </div>
    );
  },
);

export const widgetRenderer = (
  agGridProps: IWidgetCellRendererParams &
    ICellRendererParams<any, any, IGridContext>,
) => {
  const { data, attr, colDef, props, widget, rowAlias, node, context } =
    agGridProps;
  if (node.group || data._isPlaceholder) {
    return null;
  }
  const rows = props.component.properties.rows as string;
  const rowId = getRowIdFromData(props.typeDefObject, data);
  const rowIndex = context.rowIdToIndexMap[rowId];
  if (rowIndex === undefined) {
    // Seems to happen sometimes when data that used to be in the grid is no longer in the grid, just ignore
    return null;
  }
  const subscribePaths = {
    _row: [rows, rowIndex],
    data: [rows, rowIndex, colDef.field],
    _cell: [rows, rowIndex, colDef.field],
  };
  if (rowAlias) {
    subscribePaths[rowAlias] = subscribePaths._row;
  }

  if (context.scrolling) {
    if (attr.itemInfo?.type === BasicType.String) {
      return agGridProps.data[attr.name] || null;
    }
    return null;
  }
  const childAliases: IAliases = Object.keys(subscribePaths).map((pathKey) => ({
    [pathKey]: { subscribePath: subscribePaths[pathKey] },
  })) as unknown as IAliases;

  const intermediateNodes = [rowIndex.toString(), colDef.field];

  return (
    <Widget
      aliases={props.aliases}
      childAliases={childAliases}
      intermediateNodes={intermediateNodes}
      subscribePaths={subscribePaths}
      component={widget}
      passThroughProps={{
        ...props.passThroughProps,
        openingModal: context.openingModal,
        closingModal: context.closingModal,
      }}
      key={props.component.widgetId + colDef.colId + rowIndex}
    />
  );
};

/**
 * FIXME Handling widgets in groups is a little messy. The reason is that in
 * order to pass data down to a child widget it must be in the store but the
 * data at the group level is not in the store directly. It can be in one of two
 * places 1) In the groupData prop that is passed to the grid (for when there
 * are groups that may not exist in the data) 2) In the rows themselves, where
 * the group does exist in the data.
 */
export const groupWidgetRenderer = (params: {
  arraySource: string;
  index: number;
  widget: any;
  context: IGridContext;
  passThroughParams: ICellRendererParams;
  field: string;
}) => {
  const { arraySource, index, widget, context } = params;
  // FIXME - this does not work because the ag-grid refreshCells(), called when scrolling stops
  // does not refresh the group headers consistently.
  if (false && context.scrolling) {
    return null;
  }
  const { props } = context;
  const subscribePaths = {
    _group: [arraySource, index],
  };
  const childAliases: IAliases = Object.keys(subscribePaths).map((pathKey) => ({
    [pathKey]: { subscribePath: subscribePaths[pathKey] },
  })) as unknown as IAliases;

  const intermediateNodes = [index.toString()];

  return (
    <Widget
      aliases={props.aliases}
      childAliases={childAliases}
      intermediateNodes={intermediateNodes}
      subscribePaths={subscribePaths}
      component={widget}
      passThroughProps={{
        ...props.passThroughProps,
        openingModal: context.openingModal,
        closingModal: context.closingModal,
      }}
      key={props.component.widgetId + arraySource + index}
    />
  );
};

export const iconCellRenderer = (
  params: IIconCellRendererParams & ICellRendererParams,
) => {
  const { attr, configName, data } = params;
  const context: IGridContext = params.context;
  if (context.scrolling) {
    return null;
  }

  const updatePipeline = attr.presentationInfo.updatePipeline;
  const onClick = () => {
    void context.props.runPipelineWithWidgetContext({
      data: { _row: data },
      stages: updatePipeline,
    });
  };
  const elementAttributes: IElementAttributes = {
    'data-test': '',
    onClick: updatePipeline ? onClick : null,
  };
  const IconComponent = getIcon(
    attr.presentationInfo.icon,
    configName,
    elementAttributes,
  );
  return (
    <Tooltip
      className={updatePipeline ? 'clickable-icon' : null}
      title={attr.nameInfo?.shortDescription || null}
    >
      <span>
        <IconComponent {...elementAttributes} />
      </span>
    </Tooltip>
  );
};

export const getCssCellClass = (
  attr: IItemType,
  verticalGridLines: boolean,
) => {
  const formats: IGridCssStyling[] =
    attr.presentationInfo?.gridFormat?.formatStandardStyling || [];
  formats.forEach((format) => {
    const formatToUse = standardCssFormats[format.standardStyle];
    if (!formatToUse) {
      throw new Error(
        `Cell Formatting error: ${format.standardStyle} not found in standardCssFormats`,
      );
    }
  });
  return (): any => {
    const cellClass = getExcelCssClass(attr) || [];
    formats.forEach((format) => {
      const formatToUse = standardCssFormats[format.standardStyle];
      if (_.isString(formatToUse)) {
        cellClass.push(formatToUse);
      }
    });
    if (verticalGridLines) {
      cellClass.push('vertical-grid-lines');
    }
    return cellClass;
  };
};
const getExcelCssClass = (attr: IItemType): string[] => {
  const style = attr.presentationInfo?.presentationFormat?.style;
  const decimals =
    attr.presentationInfo?.presentationFormat?.maximumFractionDigits;
  switch (attr.itemInfo?.type) {
    case BasicType.String:
    case 'ID':
      return ['stringType'];
    case BasicType.Boolean:
      return ['booleanType'];
    case BasicType.Date:
    case BasicType.DateTime:
      return ['dateTimeType'];
    case BasicType.Int:
      return ['integerType', 'right-align'];
    case BasicType.Decimal:
    case BasicType.Float:
      if (style === 'percent') {
        switch (decimals) {
          case 1:
            return ['percent1Type', 'right-align'];
          case 2:
            return ['percent2Type', 'right-align'];
          default:
            return ['percent0Type', 'right-align'];
        }
      } else {
        switch (decimals) {
          case 1:
            return ['decimal1Type', 'right-align'];
          case 2:
            return ['decimal2Type', 'right-align'];
          default:
            return ['integerType', 'right-align'];
        }
      }
    default:
      return null;
  }
};
export const getCssCellClassRules = (presentationInfo: IPresentationInfo) => {
  const cellClassRules = {};
  const formats: IGridCssStyling[] =
    presentationInfo.gridFormat.formatStandardStyling;
  formats.forEach((format) => {
    const formatToUse = standardCssFormats[format.standardStyle];
    if (!formatToUse) {
      throw new Error(
        `Cell Formatting error: ${format.standardStyle} not found in standardCssFormats`,
      );
    }
    if (_.isString(formatToUse)) {
      return;
    } else {
      for (const rule in standardCssFormats[format.standardStyle]) {
        const cssParams = {};
        for (const cssParam of format.parameters) {
          cssParams[cssParam.key] = cssParam.value;
        }
        cellClassRules[rule] = (params) => {
          return standardCssFormats[format.standardStyle][rule]({
            ...params,
            ...cssParams,
          });
        };
      }
    }
  });
  return Object.keys(cellClassRules).length > 0 ? cellClassRules : null;
};

export const formattedCellStyle: any = (
  presentationInfo: IPresentationInfo,
) => {
  return (params: CellClassParams): any => {
    const { data, colDef, context } = params;
    const { presentationSupport } = context;
    const field = colDef.field;
    let style: any = {};
    if (!data) {
      return null;
    }

    if (colDef.cellDataType === 'boolean') {
      style.display = 'flex';
      style.alignItems = 'center';
      style.justifyContent = 'center';
    }
    if (!params.node.rowPinned && colDef.cellEditor === NumericEditor) {
      style.borderBottom = 'navy solid 0.5px';
    }
    const formatString = presentationInfo?.gridFormat?.formatString;
    let container;
    if (field.includes('.')) {
      container = field.slice(0, field.lastIndexOf('.'));
    }
    let formatting =
      formatString || data[ROW_FORMATTING_FIELD]?.[field]?.formatting;
    if (presentationInfo?.gridFormat?.formatStringCode) {
      formatting = presentationSupport.runJsCode({
        context: {
          field: _.get(params.data, field),
          ...params.data,
          container: _.get(params.data, container),
        },
        code: `${presentationInfo?.gridFormat?.formatStringCode}`,
      });
    }
    let formatCondition = presentationInfo?.gridFormat?.formatCondition
      ? presentationSupport.runJsCode({
          context: {
            field: _.get(params.data, field),
            ...params.data,
            container: _.get(params.data, container),
          },
          code: `${presentationInfo?.gridFormat?.formatCondition}`,
        })
      : true;
    if (!(formatting && formatCondition) && presentationInfo?.editable) {
      formatting = { editable: true };
      formatCondition = true;
    }
    if (formatting && formatCondition) {
      for (const format in formatting) {
        if (standardFormats[format]) {
          style = { ...style, ...standardFormats[format] };
        } else {
          style = { ...style, [format]: formatting[format] };
        }
      }
      return style;
    } else {
      return style;
    }
  };
};

export function getGroupIndex(params: {
  node: IRowNode;
  groupData: IGroupData[];
}): number {
  const { node, groupData } = params;
  const fieldsToCheck = getNestedGroupParents(node);
  fieldsToCheck.push({ name: node.field, value: node.key });
  return groupData?.findIndex((group) => {
    if (
      Object.keys(group).filter((key) => group[key] !== null).length - 1 !==
      fieldsToCheck.length
    ) {
      return false;
    }
    for (const field of fieldsToCheck) {
      if (field.value !== group[field.name]) {
        return false;
      }
    }
    return true;
  });
}

function getRowIndexFromGroup(params: {
  context: IGridContext;
  node: IRowNode;
  rows: any[];
}): number {
  const { context, node, rows } = params;
  const parents = getNestedGroupParents(node);
  const returnRow = rows
    .filter((row) => !row._isPlaceholder)
    .find((row) => {
      if (_.get(row, node.field) !== node.key) {
        return false;
      }
      for (const p of parents) {
        if (row[p.name] !== p.value) {
          return false;
        }
      }
      return true;
    });
  return returnRow ? context.rowIdToIndexMap[returnRow.id] : -1;
}
/* we need the level so we can format the background color of the group row */

function getGroupLevel(params: { typeDef: TypeDefinition; field: string }) {
  const { typeDef, field } = params;
  let level = -1;
  const attributes = typeDef.getPresentationAttributes();
  for (const attr of attributes) {
    if (attr.name === field) {
      return level + 1;
    }
    level++;
  }
  return -1;
}
