import deepEqual from 'fast-deep-equal';
import { IUserInfo } from 'universal/permissionManager';
import { stringifyPretty } from 'universal/utilityFunctions';

import { PresentationSupport } from '../util/presentationSupport';

import { store } from './store';
import { UI_STATE, updateUIState } from './uiState';

let presentationSupport: PresentationSupport;
let userInfo: IUserInfo;

export const setupUIStatePersistence = (params: {
  presentationSupport: PresentationSupport;
  userInfo: IUserInfo;
}) => {
  ({ presentationSupport, userInfo } = params);
};

export async function persistUIState(stateEntry: { [key: string]: any }) {
  if (Object.keys(stateEntry).length !== 1) {
    throw new Error(`Invalid props: ${stringifyPretty(stateEntry)}`);
  }
  const key = Object.keys(stateEntry)[0];
  const value = Object.values(stateEntry)[0];

  const state = store.getState();
  const uiState = state[UI_STATE];

  if (deepEqual(uiState[key], value)) {
    return;
  }
  store.dispatch(
    updateUIState({
      key,
      value,
    }),
  );

  await presentationSupport.saveUiState({ userId: userInfo.id, key, value });
}
