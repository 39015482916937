import { MenuItem } from '@mui/material';
import Typography from '@mui/material/Typography';
import { FunctionComponent, useContext, useEffect, useState } from 'react';
import {
  AccessType,
  IMPERSONATEROLE,
  PermissionType,
} from 'universal/permissionManager';

import { useCheckPermission } from '../../hooks/useCheckPermissions';
import { AuthenticatorContext } from '../../wrappers/AuthenticatorContext';
import { ClientContext } from '../../wrappers/ClientContext';
import CustomSelect from '../atoms/CustomSelect';

const ImpersonateRole: FunctionComponent<
  React.PropsWithChildren<unknown>
> = () => {
  const { clientManager, userInfo } = useContext(ClientContext);
  const { permissionManager } = clientManager;
  const allowed =
    useCheckPermission({
      name: IMPERSONATEROLE,
      type: PermissionType.Programmatic,
      accessType: AccessType.Read,
    }) || userInfo.impersonatedRole;

  const [role] = useState(userInfo.roles[0]);
  const [newRole, setNewRole] = useState(role);
  const [roles, setRoles] = useState([]);
  const [needsLogout, setNeedsLogout] = useState(false);

  const { logout } = useContext(AuthenticatorContext);

  useEffect(() => {
    if (!allowed) {
      return;
    }
    if (roles.length === 0) {
      void permissionManager
        .getAllRoles({ visibleOnly: true })
        .then((allRoles) => setRoles(allRoles));
    }

    // newRole is null if no longer impersonating
    if (!needsLogout && role.id !== newRole?.id) {
      setNeedsLogout(true);
      void permissionManager
        .setImpersonatedRole({
          userInfo,
          role: newRole?.id,
        })
        .then(() => {
          setNeedsLogout(true);
          logout();
        })
        .catch();
    }
  }, [
    allowed,
    roles.length,
    needsLogout,
    role.id,
    newRole?.id,
    permissionManager,
    userInfo,
    logout,
  ]);

  if (!allowed) {
    return null;
  }

  function handleChangeRole(changedRole) {
    setNewRole(roles.find((r) => (r.id = changedRole.id)));
  }

  function resetRole() {
    setNewRole(null);
  }

  if (roles.length === 0) {
    return null;
  }
  if (needsLogout) {
    return <Typography>Logging you out for new role</Typography>;
  }
  if (userInfo.impersonatedRole) {
    return <MenuItem onClick={resetRole}>RESET IMPERSONATED ROLE</MenuItem>;
  }
  return (
    <CustomSelect
      name="Set Role to Impersonate"
      options={roles}
      optionLabelField={'nameInfo.displayName'}
      value={roles.find((r) => r.id === role.id)}
      onChange={handleChangeRole}
    />
  );
};

export default ImpersonateRole;
