import { Button, Icon, IconButton } from '@mui/material';
import { Component } from 'react';

/*
Now accepts an icon prop. If it has an icon prop, it will render an icon button with the named material ui icon instead of a regular button
*/

interface IProps {
  style?;
  icon?: string;
  identifier?;
  onClick?;
  className?;
  disabled?;
}

class CustomButton extends Component<IProps> {
  public declare props;
  constructor(props: IProps) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(event) {
    const { identifier, onClick } = this.props;
    onClick(identifier, event);
  }

  render() {
    const { children, icon, disabled, style, iconStyle } = this.props;
    const isDisabled = disabled || false;

    if (icon) {
      return (
        <IconButton
          onClick={this.handleClick}
          disabled={isDisabled}
          style={style}
          size="large"
        >
          <Icon style={iconStyle}>{icon}</Icon>
        </IconButton>
      );
    } else {
      return (
        <Button onClick={this.handleClick} disabled={isDisabled} style={style}>
          {children}
        </Button>
      );
    }
  }
}

export default CustomButton;
