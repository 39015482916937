import {
  FormatBold,
  FormatItalic,
  FormatListBulleted,
  FormatListNumbered,
  FormatUnderlined,
  OpenInNew,
} from '@mui/icons-material';
import { Button, IconButton, Modal } from '@mui/material';
import Box from '@mui/material/Box';
import Bold from '@tiptap/extension-bold';
import BulletList from '@tiptap/extension-bullet-list';
import Document from '@tiptap/extension-document';
import HardBreak from '@tiptap/extension-hard-break';
import Heading from '@tiptap/extension-heading';
import Highlight from '@tiptap/extension-highlight';
import Italic from '@tiptap/extension-italic';
import ListItem from '@tiptap/extension-list-item';
import OrderedList from '@tiptap/extension-ordered-list';
import Paragraph from '@tiptap/extension-paragraph';
import Text from '@tiptap/extension-text';
import Underline from '@tiptap/extension-underline';
import { Editor, EditorContent } from '@tiptap/react';
import _ from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { compose } from 'redux';
import './styles.css';

import { withUiState } from '../../../store/withUiState';
import { withActions } from '../../widgetEngine/ActionEnabler';

const MenuBar = (props) => {
  const editor = props.editor;
  if (!editor) {
    return null;
  }
  function modalButton(allowModal) {
    if (allowModal) {
      return (
        <IconButton
          onClick={() => {
            props.setCreateModal(true);
            props.setContent(editor.getJSON());
          }}
        >
          <OpenInNew fontSize={'small'}></OpenInNew>
        </IconButton>
      );
    }
  }
  return (
    <>
      <Button
        style={{ maxWidth: '25px', minWidth: '25px', padding: '2px' }}
        onClick={() => {
          editor.chain().focus().toggleHeading({ level: 2 }).run();
        }}
        className={editor.isActive('heading', { level: 2 }) ? 'is-active' : ''}
      >
        h1
      </Button>
      <Button
        style={{ maxWidth: '25px', minWidth: '25px', padding: '2px' }}
        onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()}
        className={editor.isActive('heading', { level: 3 }) ? 'is-active' : ''}
      >
        h2
      </Button>
      <Button
        style={{ maxWidth: '25px', minWidth: '25px', padding: '2px' }}
        onClick={() => editor.chain().focus().toggleHeading({ level: 4 }).run()}
        className={editor.isActive('heading', { level: 4 }) ? 'is-active' : ''}
      >
        h3
      </Button>
      <IconButton
        onClick={() => {
          editor.chain().focus().toggleBold().run();
        }}
      >
        <FormatBold fontSize={'small'}></FormatBold>
      </IconButton>
      <IconButton
        onClick={() => {
          editor.chain().focus().toggleItalic().run();
        }}
      >
        <FormatItalic fontSize={'small'}></FormatItalic>
      </IconButton>
      <IconButton
        onClick={() => {
          editor.chain().focus().toggleUnderline().run();
        }}
      >
        <FormatUnderlined fontSize={'small'}></FormatUnderlined>
      </IconButton>
      <IconButton
        onClick={() => {
          editor.chain().focus().toggleBulletList().run();
        }}
      >
        <FormatListBulleted fontSize={'small'}></FormatListBulleted>
      </IconButton>
      <IconButton
        onClick={() => {
          editor.chain().focus().toggleOrderedList().run();
        }}
      >
        <FormatListNumbered fontSize={'small'}></FormatListNumbered>
      </IconButton>
      {modalButton(props.allowModal)}
    </>
  );
};
const modalStyle = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};
const ModalRichText = (props) => {
  const [modalContent, setModalContent] = useState(
    props.richTextProps.initialValue,
  );
  return (
    <Modal open={props.createModal}>
      <Box sx={{ ...modalStyle, width: '60vw', height: '60vh' }}>
        <div style={{ height: '90%' }}>
          <RichText
            parentProps={{ ...props.richTextProps, setModalContent }}
          ></RichText>
        </div>
        <div style={{ textAlign: 'center', marginBottom: '9rem' }}>
          <Button
            onClick={() => {
              props.close(modalContent);
            }}
          >
            Close
          </Button>
        </div>
      </Box>
    </Modal>
  );
};

// define your extension array
const RichText: React.FC<React.PropsWithChildren<any>> = (props) => {
  const propsToUse = props.parentProps
    ? { ...props.parentProps, allowModal: false }
    : props;
  const {
    updateFromWidget,
    data,
    initialValue,
    persistValue,
    width,
    height,
    allowModal,
  } = propsToUse;
  const [initialized, setInitialized] = useState(false);
  const inToolbar = useRef(false);
  const [content, setContent] = useState({ type: 'doc', content: [] });
  const [initialData, setInitialData] = useState(null);
  const [createModal, setCreateModal] = useState(false);
  if (!initialized) {
    let initData;
    if (persistValue !== undefined) {
      initData = persistValue;
    } else if (initialValue !== undefined) {
      initData = initialValue;
    } else if (data !== undefined) {
      initData = data;
    }
    setContent(initData);
    setInitialData(initData);
    setInitialized(true);
  }
  if (initialized && !_.isEqual(data, initialData)) {
    setContent(data);
    setInitialData(data);
  }
  const [updateParams, setUpdateParams] = useState<{
    data: any;
  }>(undefined);

  useEffect(() => {
    if (updateParams) {
      updateFromWidget(updateParams);
    }
  }, [updateFromWidget, updateParams]);

  const editor = new Editor({
    extensions: [
      Highlight,
      Heading,
      Document,
      Paragraph,
      Underline,
      Text,
      Bold,
      Italic,
      OrderedList,
      ListItem,
      BulletList,
      HardBreak,
    ],
    content,
    onBlur() {
      if (!inToolbar.current) {
        // Need to reparse to make sure all of the objects wind up being proper objects with a
        // prototype. Failing to do this gets this error: https://github.com/ueberdosis/tiptap/issues/4697
        const newContent = JSON.parse(JSON.stringify(editor.getJSON() as any));
        setUpdateParams({ data: newContent });
        setContent(newContent);
        if (props.parentProps) {
          props.parentProps.setModalContent(newContent);
        }
      }
    },
  });

  const handleMouseDown = () => {
    inToolbar.current = true;
  };
  const handleToolbarBlur = () => {
    inToolbar.current = false;
  };
  return (
    <div
      style={{
        width: width || '100%',
        height: height || '100%',
      }}
    >
      <div onMouseDown={handleMouseDown} onBlur={handleToolbarBlur}>
        <MenuBar
          editor={editor}
          allowModal={allowModal}
          setCreateModal={setCreateModal}
          setContent={setContent}
        />
      </div>
      <div
        style={{
          minWidth: '95%',
          height: '70%',
          display: 'block',
          overflow: 'auto',
          padding: '5px',
        }}
      >
        <EditorContent editor={editor} />
      </div>
      <ModalRichText
        createModal={createModal}
        close={(newContent) => {
          setContent(newContent);
          setCreateModal(false);
        }}
        richTextProps={{ ...props, initialValue: content, height: '100%' }}
      />
    </div>
  );
};
export default compose(withUiState, withActions)(RichText);
