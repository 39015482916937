import { IWidgetProps, UniversalWidgetProps } from '../types';

const imagePropertyInfo = {
  fileId: {
    description:
      'the identifier of the image file to be displayed (specify a variable)',
  },
} as const;

// FIXME - this needs to be handled better - but will go away with the typescript change
type Props = IWidgetProps<typeof imagePropertyInfo> & UniversalWidgetProps;

export const imageTypeInfo = {
  types: {
    children: true,
  },
  properties: imagePropertyInfo,
  events: {},
  childTypes: {},
};

const ImageWidget: React.FC<React.PropsWithChildren<Props>> = () => {
  return <image />;
};

export default ImageWidget;
