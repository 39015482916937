import { IPassThroughProps, IWidgetTree } from '../components/widgets/types';
import { ContextMap } from '../store/context';

import { compileProperties, compileProperty } from './widgetUtilities';

export function previewWidget(metaComponent): {
  name: string;
  properties: any;
  metaComponent: any;
} {
  const { component, context } = metaComponent;
  const { properties, name } = component;

  if (!component) {
    throw new Error('no component provided to the previewWidget function');
  }

  const compiledProperties = compileProperties(properties, context);

  return {
    name,
    properties: compiledProperties,
    metaComponent,
  };
}

export function previewChildren({
  childWidgets,
  aliases: { widgetPath },
  childContext,
  passThroughProps,
}: {
  childWidgets: IWidgetTree[];
  aliases: ContextMap;
  childContext: any;
  passThroughProps: IPassThroughProps;
}) {
  const newContext = Object.assign({}, childContext);
  const Children = [];
  childWidgets.forEach((child) => {
    const duplicateBy = compileProperty(
      'duplicateBy',
      child.properties.duplicateBy,
      childContext,
    );
    const { duplicateBy: matchDuplicateBy } = child.matchProperties;
    const { instanceName } = child.matchProperties;

    if (duplicateBy && Array.isArray(duplicateBy)) {
      duplicateBy.forEach((instance, index) => {
        const previewContext = Object.assign({}, newContext, {
          [instanceName as string]: instance,
        });

        Children.push(
          previewWidget({
            component: child,
            context: previewContext,
            passThroughProps,
            index,
          }),
        );
      });
    } else {
      if (matchDuplicateBy) {
        throw new Error(
          `a duplicateBy property ${matchDuplicateBy} was defined but was not found in context`,
        );
      }
      Children.push(
        previewWidget({
          widgetPath,
          component: child,
          context: newContext,
          passThroughProps,
        }),
      );
    }
  });
  return Children;
}
