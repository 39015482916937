import {
  categories,
  IConfigurationType,
  inputProperties,
  IWidgetProps,
  textVariants,
} from '../types';

const multiSelectProperties = {
  ...inputProperties,
  label: {},
  initialValue: {
    description:
      'Must be a value that can fit the input. Can point to the value within context, come from a pipeline, or be hard coded.',
  },
  options: {
    pointer: 'must',
    required: true,
    description:
      'Array of options for selection. Can be strings or objects. If objects, an optionLabel property is required which points to a string withing the object to be used as a label, and an optionData field is optional',
  },
  optionLabel: {
    required: false,
    description:
      'If options are objects, this must point to the field to display as the label',
  },
  optionData: {
    description:
      'If options are objects, this points to the data field within the object, which will be passed to any form or update pipelines and be used to match the current selection to the options. If not specified, the whole object is passed.',
  },
  required: {
    default: false,
    options: [true, false],
    description: 'Will indicate if this field is required',
  },
  selectAll: {
    default: true,
    options: [true, false],
    description: 'Include Select All option',
  },
  noSort: {
    default: false,
    options: [true, false],
    description: 'List will be sorted alphabetically unless this is specified',
  },
  width: {
    default: "'300px'",
    description: '%, px, cm',
  },
  fontSize: {
    default: "'body1'",
    options: textVariants,
  },
  helperText: {
    description: 'Displays the text below the input box',
  },
} as const;

export type MultiSelectProps = IWidgetProps<typeof multiSelectProperties>;
export const multiSelectTypeInfo: IConfigurationType = {
  types: {
    children: true,
  },
  childTypes: {},
  category: categories.input,
  properties: multiSelectProperties,
  childProperties: {},
  events: {
    load: [],
    update: [],
    validate: [],
  },
};
