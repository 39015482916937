import _ from 'lodash';
import { compose } from 'redux';

import { withUiState } from '../../../store/withUiState';
import CustomSelect from '../../atoms/CustomSelect';
import { withActions } from '../../widgetEngine/ActionEnabler';
import Input from '../../widgetEngine/Input';

class DropdownSelect extends Input {
  public declare props;
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  initializeValue() {
    const { initialValue, persistValue, nullOption, data } = this.props;
    const initialUndefined = initialValue
      ? initialValue
      : nullOption
        ? null
        : undefined;
    if (persistValue !== undefined) {
      const { currentValue } = this.getOptionsStuff(persistValue);
      if (
        currentValue === undefined ||
        (!nullOption && currentValue === null)
      ) {
        this.update(initialUndefined, true);
      } else {
        this.update(persistValue, true);
      }
    } else if (![undefined, '', null].includes(initialValue)) {
      const { currentValue } = this.getOptionsStuff(initialValue);
      if (currentValue !== undefined) {
        this.update(initialValue, true);
      } else {
        this.update(initialUndefined, true);
      }
    } else if (data !== undefined) {
      this.update(data, true);
    } else {
      this.update(initialUndefined, true);
    }
  }

  update(value, initialUpdate?) {
    const { updateFromWidget, freeSolo } = this.props;

    const newData = {
      data: value,
      initialUpdate,
    };
    // Sometimes we have a no-longer relevant value in the UI state so we should return null, not that value
    const currentValue = this.getOptionsStuff(newData.data).currentValue;
    updateFromWidget(
      currentValue || freeSolo ? newData : { data: null, initialUpdate },
    );
  }

  handleChange(optionsValue) {
    const { optionData, boolean, freeSolo } = this.props;

    let newData;
    if (freeSolo && typeof optionsValue === 'string') {
      newData = optionsValue;
    } else {
      if (boolean) {
        const booleanValue = optionsValue?.value;
        if (booleanValue === true || booleanValue === false) {
          newData = booleanValue;
        } else {
          newData = null;
        }
      } else {
        if (optionData && optionsValue !== null) {
          newData = _.get(optionsValue, optionData);
        } else {
          newData = optionsValue;
        }
      }
    }

    this.update(newData);
  }

  getOptionsStuff(dataValue): { currentValue; optionsToUse; optionLabelToUse } {
    const { boolean, options, optionLabel, optionData, yesNo } = this.props;

    let currentValue;
    let optionsToUse = options;
    let optionLabelToUse = optionLabel;
    if (boolean) {
      optionsToUse = [
        { value: true, label: yesNo ? 'Yes' : 'True' },
        { value: false, label: yesNo ? 'No' : 'False' },
      ];
      optionLabelToUse = 'label';
      if (dataValue) {
        // True
        currentValue = optionsToUse[0];
      } else if (dataValue === false) {
        // False
        currentValue = optionsToUse[1];
      }
    } else {
      if (!options) {
        optionsToUse = [];
        currentValue = dataValue;
      } else if (optionData) {
        currentValue = options.find((o) => {
          const value = _.get(o, optionData);
          return value === dataValue;
        });
      } else {
        currentValue = options.find((o) => o === dataValue);
      }
    }
    if (currentValue === undefined) {
      currentValue = null;
    }
    return { currentValue, optionLabelToUse, optionsToUse };
  }

  render() {
    const {
      name,
      label,
      data,
      width,
      backgroundColor,
      backgroundColorIfEmpty,
      fontSize,
      valid,
      validationMessage,
      helperText,
      nullOption,
      readOnly,
      required,
      freeSolo,
      tooltip,
      passThroughProps: { rejectedFormSubmit },
    } = this.props;

    const { currentValue, optionLabelToUse, optionsToUse } =
      this.getOptionsStuff(data);

    const renderBackgroundColor =
      backgroundColorIfEmpty && [undefined, null, '', -1].includes(currentValue)
        ? backgroundColorIfEmpty
        : backgroundColor;

    const showRequiredAndEmpty =
      rejectedFormSubmit && required && [undefined, null, ''].includes(data);

    const showError = !valid || showRequiredAndEmpty;

    const elementAttributes = this.props.elementAttributes;
    const helperTextToUse =
      (valid === false && validationMessage) || helperText;
    return (
      <CustomSelect
        style={{ width, backgroundColor: renderBackgroundColor }}
        optionLabelField={optionLabelToUse}
        options={optionsToUse}
        freeSolo={freeSolo}
        fontSize={fontSize}
        name={label}
        value={currentValue}
        onChange={this.handleChange}
        error={showError}
        helperText={helperTextToUse}
        nullOption={nullOption}
        readOnly={readOnly}
        required={required}
        key={name}
        tooltip={tooltip}
        elementAttributes={elementAttributes}
      />
    );
  }
}

export default compose(withUiState, withActions)(DropdownSelect);
