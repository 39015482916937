import { ColDef } from '@ag-grid-community/core';
import { AgGridReact } from '@ag-grid-community/react';
import _ from 'lodash';
import {
  forwardRef,
  memo,
  RefObject,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { getLogger, Loggers } from 'universal/loggerSupport';
import { IStageProperties } from 'universal/pipeline/stage';
import { IItemInfo, TypeDefinition } from 'universal/typeDefinition';
import { BasicType } from 'universal/types';

import { IRawUpdate } from '../../../store/data';
import {
  prepNumericInput,
  processInputString,
} from '../../../util/widgetUtilities';
import CustomSelect from '../../atoms/CustomSelect';

import { getRowIdFromData, IProps, RowsInfo } from './AGGrid';

const logger = getLogger({ name: Loggers.CLIENT });
const KEY_BACKSPACE = 'Backspace';
const KEY_DELETE = 'Delete';
const KEY_ENTER = 'Enter';
const KEY_TAB = 'Tab';

export const NumericEditor = memo(
  forwardRef((props: any, ref) => {
    const { itemInfo, presentationFormat } = props;
    const createInitialState = () => {
      let startValue;
      let highlightAllOnFocus = true;
      if (props.charPress) {
        // if a number was pressed, we start with the number
        startValue = props.charPress;
        highlightAllOnFocus = false;
      } else {
        // otherwise we start with the current value
        startValue = prepNumericInput({
          input: props.value,
          presentationFormat,
        });
      }
      return {
        value: startValue,
        highlightAllOnFocus,
      };
    };
    const initialState = createInitialState();
    const [value, setValue] = useState(initialState.value);
    const [editing, setEditing] = useState(true);
    const [highlightAllOnFocus, setHighlightAllOnFocus] = useState(
      initialState.highlightAllOnFocus,
    );
    const refInput = useRef<HTMLInputElement>(null);

    // focus on the input
    useEffect(() => {
      // get ref from React component
      const eInput = refInput.current;
      eInput.focus();
      if (highlightAllOnFocus) {
        eInput.select();

        setHighlightAllOnFocus(false);
      } else {
        // when we started editing, we want the caret at the end, not the start.
        // this comes into play in two scenarios:
        //   a) when user hits F2
        //   b) when user hits a printable character
        const length = eInput.value ? eInput.value.length : 0;
        if (length > 0) {
          eInput.setSelectionRange(length, length);
        }
      }
    }, [highlightAllOnFocus]);

    const isCharNumeric = (charStr: string) => {
      if ([BasicType.Decimal, BasicType.Float].includes(props.itemInfo.type)) {
        return !!/\d|\./.test(charStr); // Allow digits and decimal points
      } else {
        return !!/\d/.test(charStr); // Allow only digits
      }
    };

    const isKeyPressedNumeric = (event: any) => {
      const charStr = event.key;
      return isCharNumeric(charStr);
    };

    const deleteOrBackspace = (event: any) => {
      return [KEY_DELETE, KEY_BACKSPACE].indexOf(event.key) > -1;
    };

    const finishedEditingPressed = (event: any) => {
      const key = event.key;
      return key === KEY_ENTER || key === KEY_TAB;
    };

    const onKeyDown = (event: any) => {
      if (deleteOrBackspace(event)) {
        event.stopPropagation();
        return;
      }
      if (!finishedEditingPressed(event) && !isKeyPressedNumeric(event)) {
        if (event.preventDefault) event.preventDefault();
      }
      if (finishedEditingPressed(event)) {
        props.stopEditing();
      }
    };

    /* Component Editor Lifecycle methods */
    useImperativeHandle(ref, () => {
      return {
        // the final value to send to the grid, on completion of editing
        getValue() {
          if ([null, undefined, ''].includes(value) || isNaN(value)) {
            updateGrid({
              ...props,
              value: null,
            });
            return null;
          }
          const newValue = processInputString({
            newValue: value,
            presentationFormat,
            itemInfo,
          });
          updateGrid({
            ...props,
            value: newValue,
          });
          return null;
        },
      };
    });
    useEffect(() => {
      if (!editing) {
        props.stopEditing();
      }
    }, [editing, props]);
    return (
      <input
        ref={refInput}
        value={value}
        className={'simple-input-editor'}
        onChange={(event: any) => {
          setValue(event.target.value);
        }}
        onKeyDown={(event: any) => {
          onKeyDown(event);
        }}
        onBlur={() => setEditing(false)}
        style={{ width: '90%', border: 'none' }}
      />
    );
  }),
);

export const StringEditor = memo(
  forwardRef((props: any, ref) => {
    const [value, setValue] = useState(props.value);
    const [editing, setEditing] = useState(true);

    const refInput = useRef<HTMLInputElement>(null);
    // focus on the input
    useEffect(() => {
      // get ref from React component
      const eInput = refInput.current;
      eInput.focus();

      const length = eInput.value ? eInput.value.length : 0;
      if (length > 0) {
        eInput.setSelectionRange(length, length);
      }
    }, []);

    const deleteOrBackspace = (event: any) => {
      return [KEY_DELETE, KEY_BACKSPACE].indexOf(event.key) > -1;
    };

    const finishedEditingPressed = (event: any) => {
      const key = event.key;
      return key === KEY_ENTER || key === KEY_TAB;
    };

    const onKeyDown = (event: any) => {
      if (deleteOrBackspace(event)) {
        event.stopPropagation();
        return;
      }
      if (finishedEditingPressed(event)) {
        props.stopEditing();
      }
    };

    /* Component Editor Lifecycle methods */
    useImperativeHandle(ref, () => {
      return {
        // the final value to send to the grid, on completion of editing
        getValue() {
          if ([null, undefined, ''].includes(value)) {
            updateGrid({
              ...props,
              value: null,
            });
            return null;
          }
          updateGrid({
            ...props,
            value: value,
          });
          return null;
        },
      };
    });
    useEffect(() => {
      if (!editing) {
        props.stopEditing();
      }
    }, [editing, props]);
    return (
      <input
        ref={refInput}
        value={value}
        className={'simple-input-editor'}
        onChange={(event: any) => {
          setValue(event.target.value);
        }}
        onKeyDown={(event: any) => {
          onKeyDown(event);
        }}
        onBlur={() => setEditing(false)}
        style={{ width: '90%', border: 'none' }}
      />
    );
  }),
);

export const DropdownSelect = memo(
  forwardRef((props: any, ref) => {
    const { options } = props;
    const [value, setValue] = useState(
      options.find((o) => o.code === props.value),
    );
    const [editing, setEditing] = useState(true);
    const [visible, setVisible] = useState(true);

    useImperativeHandle(ref, () => {
      return {
        // the final value to send to the grid, on completion of editing
        getValue() {
          setVisible(null);
          updateGrid({
            ...props,
            displayValue: value.code,
            value: value.id,
          });
        },
      };
    });

    useEffect(() => {
      if (!editing) {
        props.stopEditing();
      }
    }, [editing, props]);

    return visible ? (
      <div
        onBlur={() => {
          setEditing(false);
        }}
      >
        <CustomSelect
          style={{ width: 150 }}
          open={true}
          options={options}
          optionLabelField="code"
          value={value}
          onChange={(option: any) => {
            setValue(option);
            setEditing(false);
          }}
        />
      </div>
    ) : null;
  }),
);

export function updateGrid(params: {
  value: any;
  displayValue?: any;
  colDef: ColDef;
  data: any;
  updatePipeline: IStageProperties[];
  props: IProps;
  paged: boolean;
  gridRef: RefObject<AgGridReact>;
  rowsInfo: RefObject<RowsInfo>;
  batchUpdateContext: (updates: IRawUpdate[]) => void;
  rowsPropName: string;
  typeDefObject: TypeDefinition;
}) {
  const {
    colDef,
    displayValue,
    value,
    data,
    updatePipeline,
    props,
    paged,
    gridRef,
    typeDefObject,
  } = params;

  if (paged) {
    throw new Error('Cell editing is not yet supported in paged mode');
  }

  const api = gridRef.current.api;
  const field = colDef.field;
  let container = null;
  const newItem = _.cloneDeep(data);
  _.set(newItem, field, displayValue || value);
  const sep = field.lastIndexOf('.');

  if (sep !== -1) {
    container = _.get(newItem, field.substring(0, sep));
  }
  logger.info('onCellEditRequest, updating ' + field + ' to ' + value);

  const rowNode = api.getRowNode(getRowIdFromData(typeDefObject, data));
  rowNode.setData(newItem);
  if (updatePipeline) {
    void props.runPipelineWithWidgetContext({
      data: { row: rowNode.data, container, value },
      stages: updatePipeline,
    });
  }

  if (api.getModel().getType() === 'serverSide') {
    // FIXME - this seems to much, verify we need this
    api.refreshServerSide();
  }
}

export async function getDropdownValues(params: {
  itemInfo: IItemInfo;
  clientManager: any;
}) {
  const { itemInfo, clientManager } = params;
  if (itemInfo.associatedEntity === 'system:Code') {
    if (!itemInfo.allowedCodeTypes) {
      throw new Error(
        'editable field of type Code must have allowedCodeTypes specified',
      );
    }
    let codeList = [];
    for (const codeType of itemInfo.allowedCodeTypes) {
      codeList = codeList.concat(
        await clientManager.codeSupport.getCodesFromType(codeType),
      );
    }
    return codeList.map((c) => c.code);
  }
  throw new Error('Must provide option list if entity is not Code');
}
