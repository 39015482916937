import { Button, Modal, Paper, TextField, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import React, { Component } from 'react';

const styles = () => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '10%',
    outline: 'none',
  },
  modalPaper: {
    padding: '10px',
  },
});

// Can be passed an identifier
// It's default child is a button that says paste. Any other child can be passed, to which this will pass it's own onClick handler, buttons work best.
// If an onClick function is passed, it will be run before the paste takes place with the event and identifier as arguments
// It must be provided an onPaste function that will be called with the text and identifier as arguments

interface IProps {
  identifier;
  className;
  component;
  onPaste;
  onClick;
  // FIXME - had to add this in the mui 5 conversion
  children?;
}

class PasteButton extends Component<IProps> {
  public declare props;
  public declare state;
  public closeModal;
  constructor(props: IProps) {
    super(props);
    this.state = {
      altPaste: false,
    };
    this.handlePaste = this.handlePaste.bind(this);
    this.handleAlternatePaste = this.handleAlternatePaste.bind(this);
  }

  handleAlternatePaste(event) {
    const { altPaste } = this.state;
    const text = event.target.value;
    altPaste(text);
    this.setState({ altPaste: false });
  }

  alternatePaste() {
    return new Promise((resolve) => {
      this.setState({ altPaste: resolve });
    });
  }

  async handlePaste(event) {
    const { onPaste, onClick, identifier } = this.props;
    if (onClick) {
      onClick(event, identifier);
    }

    const text =
      navigator && navigator.clipboard && navigator.clipboard.readText
        ? await navigator.clipboard.readText()
        : await this.alternatePaste();

    onPaste(text, identifier);
  }

  render() {
    const { altPaste } = this.state;
    const { classes, children = <Button>Paste</Button> } = this.props;

    return (
      <React.Fragment>
        <Modal
          open={!!altPaste}
          className={classes.modal}
          onClose={this.closeModal}
        >
          <Paper className={classes.modalPaper}>
            <Typography>Paste Here</Typography>
            <TextField
              variant="standard"
              autoFocus
              onChange={this.handleAlternatePaste}
              fullWidth
            />
          </Paper>
        </Modal>
        {React.cloneElement(children, { onClick: this.handlePaste })}
      </React.Fragment>
    );
  }
}

export default withStyles(styles, { withTheme: true })(PasteButton);
