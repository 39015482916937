import axios from 'axios';
import { isBrowser } from 'browser-or-node';
import GitHub from 'github-api';
import JSZip, { JSZipObject } from 'jszip';
import safeJsonStringify from 'safe-json-stringify';

import { reThrow } from '../errors/errorLog';
import { getErrorString } from '../errors/errorString';
import { retry } from '../errors/retry';
import { getLogger, Loggers } from '../loggerSupport';
import { sha1HashHex } from '../utilityFunctions';

import { BRANCH_MAIN } from './commonConstants';

const logger = getLogger({ name: Loggers.GITHUB });

export const GITHUB_USERNAME = 'snapstrat';
export const GITHUB_REPONAME = 'product';
export const GITHUB_TEST_USERNAME = 'snapstrat';
export const GITHUB_TEST_REPONAME = 'wormtestrepo';

export interface IFile {
  contents: string;
  path: string;
}

export interface IFileInfo {
  name?: string;
  path: string;
  type?: string;
  sha?: string;
  configName?: string;
}

export interface IGithubParams {
  userName?: string;
  repoName?: string;
  githubToken: string;
  branchName?: string;
  tagName?: string;
  path: string;
  files: IFile[];
  commitMessage?: string;
  noRetry?: boolean;
  logRetryErrors?: boolean;
  retryNotFound?: boolean;
  fullRepo?: JSZip;
  computeHash?: boolean;
}

// FIXME - since Octokit is a dynamic import we don't have the type information
let Octokit;

const getOctokit = async (githubToken: string): Promise<any> => {
  if (!Octokit) {
    const octokitModule = await import(
      isBrowser ? 'https://cdn.skypack.org/octokit' : '@octokit/rest'
    );
    Octokit = octokitModule.Octokit;
  }
  return new Octokit({ auth: githubToken });
};

function checkGHParams(
  params: Partial<IGithubParams>,
  requiredParams: string[] = [],
): void {
  const allRequiredParams = [...requiredParams, 'githubToken'];

  allRequiredParams.forEach((requiredParam) => {
    if (!params[requiredParam]) {
      throw new Error(`Required parameter ${requiredParam} not found`);
    }
  });
}

export async function getRateLimit(params: Pick<IGithubParams, 'githubToken'>) {
  const gh = new GitHub({
    token: params.githubToken,
  });

  return retry({ command: () => gh.getRateLimit().getRateLimit() });
}

export async function readRepoBytesFromGitHub(
  params: Pick<
    IGithubParams,
    'githubToken' | 'repoName' | 'branchName' | 'logRetryErrors'
  >,
): Promise<ArrayBuffer> {
  checkGHParams(params);

  const {
    githubToken,
    repoName,
    branchName = BRANCH_MAIN,
    logRetryErrors,
  } = params;

  if (isBrowser) {
    throw new Error('Not supported by GitHub in the browser');
  }

  let errorInfo;
  let arrayBuffer;
  try {
    const octokit = await getOctokit(githubToken);

    const octoResponse = await retry({
      command: () =>
        octokit.request('GET /repos/{owner}/{repo}/{archive_format}/{ref}', {
          owner: GITHUB_USERNAME,
          repo: repoName,
          archive_format: 'zipball',
          ref: branchName,
        }),
      logErrors: logRetryErrors,
    });

    errorInfo = octoResponse.url;

    const response = await retry({
      command: () =>
        axios.get(octoResponse.url, {
          responseType: 'arraybuffer',
        }),
      logErrors: logRetryErrors,
    });

    arrayBuffer = response.data;
  } catch (error) {
    reThrow({
      logger,
      message: `Problem accessing github at ${errorInfo}. Make sure the branch ${branchName} has been pushed for repo ${repoName}`,
      error,
    });
  }

  return arrayBuffer;
}

export async function getPartialRepoBytes(
  params: Pick<
    IGithubParams,
    'githubToken' | 'repoName' | 'branchName' | 'logRetryErrors' | 'path'
  >,
): Promise<any> {
  const { path } = params;

  const bytes = await readRepoBytesFromGitHub(params);

  const zip = await JSZip.loadAsync(bytes);

  const pathToUse = getFullRepoPath(zip, path);

  const newZip = new JSZip();

  const files = zip.file(new RegExp(pathToUse + '/.*'));

  const promises = [];
  for (const file of files) {
    promises.push(
      file.async('text').then((text) => newZip.file(file.name, text)),
    );
  }

  await Promise.all(promises);

  const zipBytes = await newZip.generateAsync({
    type: 'array',
    compression: 'DEFLATE',
    compressionOptions: { level: 9 },
  });

  return zipBytes;
}

export async function getFullRepoForBranch(
  params: Pick<
    IGithubParams,
    'githubToken' | 'repoName' | 'branchName' | 'logRetryErrors'
  >,
): Promise<JSZip> {
  const zip = await JSZip.loadAsync(await readRepoBytesFromGitHub(params));
  return zip;
}

export async function getRepo(
  params: Pick<
    IGithubParams,
    'githubToken' | 'userName' | 'repoName' | 'logRetryErrors'
  >,
): GitHub.Repository {
  checkGHParams(params);

  const {
    githubToken,
    userName = GITHUB_USERNAME,
    repoName = GITHUB_REPONAME,
    logRetryErrors,
  } = params;

  const gh = new GitHub({
    token: githubToken,
  });

  const repoResult = await retry({
    command: () => gh.getRepo(userName, repoName),
    logErrors: logRetryErrors,
  });

  logger.debug(`Found repo ${repoName}`);
  return repoResult;
}

export async function getLog(
  params: Pick<
    IGithubParams,
    'githubToken' | 'repoName' | 'branchName' | 'tagName'
  >,
): Promise<any> {
  const { githubToken, repoName, branchName, tagName } = params;

  if (!tagName && !branchName) {
    throw new Error("Specify one of 'tagName' or 'branch'");
  }

  const ref = tagName ? `tags/${tagName}` : `heads/${branchName}`;

  const octokit = await getOctokit(githubToken);

  try {
    const log = await retry({
      command: () =>
        octokit.git.getRef({
          owner: GITHUB_USERNAME,
          repo: repoName,
          ref,
        }),
    });
    return log.data;
  } catch (error) {
    throw new Error(`${ref} not found`);
  }
}

export async function tag(
  params: Pick<
    IGithubParams,
    'githubToken' | 'repoName' | 'branchName' | 'tagName'
  >,
) {
  const { githubToken, repoName, branchName, tagName } = params;

  const octokit = await getOctokit(githubToken);
  const octoRef = await retry({
    command: () =>
      octokit.git.getRef({
        owner: GITHUB_USERNAME,
        repo: repoName,
        ref: `heads/${branchName}`,
      }),
  });

  await retry({
    command: () =>
      octokit.git.createRef({
        owner: GITHUB_USERNAME,
        repo: repoName,
        ref: `refs/tags/${tagName}`,
        sha: octoRef.data.object.sha,
      }),
  });
}

export async function removeTag(
  params: Pick<IGithubParams, 'githubToken' | 'repoName' | 'tagName'>,
) {
  const { githubToken, repoName, tagName } = params;

  const octokit = await getOctokit(githubToken);

  try {
    await retry({
      command: () =>
        octokit.git.deleteRef({
          owner: GITHUB_USERNAME,
          repo: repoName,
          ref: `tags/${tagName}`,
        }),
    });
  } catch (error) {
    reThrow({
      logger,
      message: `problem removing: ${tagName}`,
      error,
    });
  }
}

export async function getBranchNames(
  params: Pick<
    IGithubParams,
    'githubToken' | 'userName' | 'repoName' | 'logRetryErrors'
  >,
): Promise<[string]> {
  checkGHParams(params);

  const { logRetryErrors } = params;

  const repo = await getRepo(params);

  let listBranches;

  if (repo._requestAllPages) {
    // Returns all results.
    // In a future version of github-api this may not work.
    listBranches = () =>
      repo._requestAllPages(`/repos/${repo.__fullname}/branches`);
  } else {
    // Simple version only returns 30 results.
    // In a future version of github-api this will return all results.
    listBranches = () => repo.listBranches();
  }

  const results = await retry({
    command: listBranches,
    logErrors: logRetryErrors,
  });

  logger.debug(
    `Found ${results.data.length} branches in repo ${
      params.repoName || GITHUB_REPONAME
    }`,
  );

  return results.data.map((branch) => branch.name);
}

export async function createBranch(
  params: Pick<
    IGithubParams,
    'githubToken' | 'branchName' | 'userName' | 'repoName' | 'logRetryErrors'
  >,
): Promise<void> {
  checkGHParams(params, ['branchName']);

  const { branchName: newBranchName, logRetryErrors } = params;

  const existingBranchNames = await getBranchNames(params);

  if (existingBranchNames.includes(newBranchName)) {
    return;
  }

  const repo = await getRepo(params);

  const createResult = await retry({
    command: () => repo.createBranch(BRANCH_MAIN, newBranchName),
    logErrors: logRetryErrors,
  });

  if (createResult.status !== 201) {
    throw new Error(
      `Create branch ${newBranchName} failed with ${createResult.status} ${createResult.statusText}`,
    );
  }
  logger.info(`Created branch ${newBranchName}`);
}

export async function deleteBranch(
  params: Pick<
    IGithubParams,
    'githubToken' | 'branchName' | 'userName' | 'repoName' | 'logRetryErrors'
  >,
): Promise<void> {
  checkGHParams(params, ['branchName']);

  const { branchName, logRetryErrors } = params;

  const existingBranchNames = await getBranchNames(params);

  if (!existingBranchNames.includes(branchName)) {
    return;
  }

  const repo = await getRepo(params);

  const deleteResult = await retry({
    command: () => repo.deleteRef(`heads/${branchName}`),
    logErrors: logRetryErrors,
  });

  logger.info(`Deleted branch ${branchName}`);

  return deleteResult;
}

function getFullRepoPath(fullRepo: JSZip, path: string) {
  const files = fullRepo.files;
  const rootName = Object.keys(files)[0];
  return `${rootName}${path}`;
}

export async function getFileContentsFullRepo(
  params: Pick<IGithubParams, 'path' | 'fullRepo'>,
): Promise<string> {
  const { path, fullRepo } = params;

  const p = getFullRepoPath(fullRepo, path);

  const fileObj = fullRepo.file(p);
  if (!fileObj) {
    throw new Error(`File at path ${path} not found`);
  }

  return fileObj.async('text');
}

export async function getFileContents(
  params: Pick<
    IGithubParams,
    | 'githubToken'
    | 'branchName'
    | 'path'
    | 'userName'
    | 'repoName'
    | 'noRetry'
    | 'logRetryErrors'
    | 'retryNotFound'
  >,
): Promise<string> {
  checkGHParams(params, ['path']);

  const {
    branchName = BRANCH_MAIN,
    path,
    noRetry,
    logRetryErrors,
    retryNotFound = true,
  } = params;

  const repo = await getRepo(params);

  let fileInfoResult;

  const getContents = () => repo.getContents(branchName, path);

  try {
    fileInfoResult = noRetry
      ? await getContents()
      : await retry({
          command: getContents,
          logErrors: logRetryErrors,
          retryNotFound,
        });
  } catch (error) {
    throw new Error(`Error getting file ${path}: ${getErrorString(error)}`);
  }

  logger.debug(`Got file info for file ${path}`);

  const fileSha = fileInfoResult.data.sha;

  const getBlob = () => repo.getBlob(fileSha);

  const blobResult = noRetry
    ? await getBlob()
    : await retry({
        command: getBlob,
        logErrors: logRetryErrors,
        retryNotFound,
      });

  logger.debug(`Got file contents for file ${path}`);

  return blobResult.data;
}

export async function getDirContentsFullRepo(
  params: Pick<IGithubParams, 'path' | 'fullRepo' | 'computeHash'>,
): Promise<IFileInfo[]> {
  const { path, fullRepo, computeHash } = params;

  const p = getFullRepoPath(fullRepo, path);

  const fileInfos = [];

  const folder1 = fullRepo.folder(p);

  const makeFileInfo = (
    filePath: string,
    zipObj: JSZipObject,
    data?: string,
  ) => {
    const pathElements = filePath.split('/').filter((pe) => pe !== '');
    if (pathElements.length > 1) {
      return;
    }
    fileInfos.push({
      name: pathElements[0],
      path: zipObj.name.split('/').slice(1).join('/'),
      type: zipObj.dir ? 'dir' : 'file',
      sha: !zipObj.dir && data && gitHash(data),
    });
  };

  if (computeHash) {
    const textPromises = [];
    folder1.forEach((filePath, zipObj) => {
      textPromises.push(
        zipObj.async('text').then((data) => {
          makeFileInfo(filePath, zipObj, data);
        }),
      );
    });
    await Promise.all(textPromises);
  } else {
    folder1.forEach((filePath, zipObj) => makeFileInfo(filePath, zipObj));
  }

  return fileInfos;
}

export async function getDirContents(
  params: Pick<
    IGithubParams,
    | 'githubToken'
    | 'branchName'
    | 'path'
    | 'userName'
    | 'repoName'
    | 'logRetryErrors'
  >,
): Promise<IFileInfo[]> {
  checkGHParams(params);

  const { branchName, path = '.', logRetryErrors } = params;

  const repo = await getRepo(params);

  let contentsResult;
  try {
    contentsResult = await retry({
      command: () => repo.getContents(branchName, path),
      logErrors: logRetryErrors,
    });
  } catch (error) {
    reThrow({
      logger,
      message: `Problem getting branch contents - make sure branch ${branchName} exists in GitHub`,
      error,
    });
  }

  logger.debug(
    `Got directory contents for branch ${
      branchName || BRANCH_MAIN
    } path ${path}`,
  );

  return contentsResult.data;
}

export async function checkIfDirectoryExists(
  params: Required<Pick<IGithubParams, 'githubToken'>> &
    Pick<IGithubParams, 'branchName' | 'userName' | 'repoName' | 'path'>,
): Promise<boolean> {
  checkGHParams(params);

  const { path = '' } = params;

  if (path === '') {
    return true;
  }

  const parentPath = getParentPath(path);
  const directoryName = getDirectoryName(path);

  const parentExists = await checkIfDirectoryExists({
    ...params,
    path: parentPath,
  });

  if (!parentExists) {
    return false;
  }

  const parentContents = await getDirContents({
    ...params,
    path: parentPath,
  });

  return !!parentContents.find(
    (file) => file.name === directoryName && file.type === 'dir',
  );
}

function getParentPath(path: string): string {
  const splitPath = path.split('/');

  return splitPath.slice(0, -1).join('/');
}

function getDirectoryName(path: string): string {
  const splitPath = path.split('/');

  return splitPath.slice(-1)[0];
}

export async function putFiles(
  params: Required<
    Pick<IGithubParams, 'githubToken' | 'files' | 'branchName'>
  > &
    Pick<
      IGithubParams,
      'commitMessage' | 'userName' | 'repoName' | 'logRetryErrors'
    >,
): Promise<any> {
  checkGHParams(params, ['files', 'branchName']);

  const {
    branchName,
    files,
    commitMessage = 'Add files',
    githubToken,
    logRetryErrors,
  } = params;

  if (branchName === BRANCH_MAIN) {
    throw new Error('Saving to branch main is forbidden');
  }

  if (files.length === 0) {
    return;
  }

  const repo = await getRepo(params);

  const filesToCommit = [];

  interface ICurrentBranch {
    name: string;
    commitSHA: string;
    treeSHA: string;
  }

  const currentBranch = {} as ICurrentBranch;

  interface INewCommit {
    treeSHA: string;
    sha: string;
  }

  const newCommit = {} as INewCommit;

  try {
    await retry({
      command: async () =>
        getBranchNames({ githubToken }).then(async (branchNames) => {
          const branchExists = branchNames.find(
            (testBranchName) => testBranchName === branchName,
          );
          if (!branchExists) {
            await retry({ command: async () => createBranch(params) });
          }
          currentBranch.name = branchName;
        }),
      logErrors: logRetryErrors,
    });

    await retry({
      command: () =>
        repo.getRef('heads/' + currentBranch.name).then((ref) => {
          currentBranch.commitSHA = ref.data.object.sha;
        }),
      logErrors: logRetryErrors,
    });

    await retry({
      command: () =>
        repo.getCommit(currentBranch.commitSHA).then((commit) => {
          currentBranch.treeSHA = commit.data.tree.sha;
        }),
      logErrors: logRetryErrors,
    });

    for (const fileInfo of files) {
      const { contents, path } = fileInfo;

      await retry({
        command: () =>
          repo.createBlob(contents).then((blob) => {
            filesToCommit.push({
              sha: blob.data.sha,
              path,
              mode: '100644',
              type: 'blob',
            });
          }),
        logErrors: logRetryErrors,
      });
    }

    await retry({
      command: () =>
        repo.createTree(filesToCommit, currentBranch.treeSHA).then((tree) => {
          newCommit.treeSHA = tree.data.sha;
        }),
      logErrors: logRetryErrors,
    });

    await retry({
      command: () =>
        repo
          .commit(currentBranch.commitSHA, newCommit.treeSHA, commitMessage)
          .then((commit) => {
            newCommit.sha = commit.data.sha;
          }),
      logErrors: logRetryErrors,
    });

    const updateResult = await retry({
      command: () =>
        repo.updateHead('heads/' + currentBranch.name, newCommit.sha),
      logErrors: logRetryErrors,
    });

    logger.info(`Put ${files.length} files in github`);

    return updateResult;
  } catch (error) {
    throw new Error(
      `Error committing params: ${safeJsonStringify(params)} ${getErrorString(
        error,
      )}`,
    );
  }
}

export async function deleteFile(
  params: Required<Pick<IGithubParams, 'githubToken' | 'branchName' | 'path'>> &
    Pick<IGithubParams, 'userName' | 'repoName' | 'noRetry' | 'logRetryErrors'>,
): Promise<any> {
  checkGHParams(params, ['path', 'branchName']);

  const { branchName, path, noRetry, logRetryErrors } = params;

  if (branchName === BRANCH_MAIN) {
    throw new Error(`Deleting from branch ${BRANCH_MAIN} is forbidden`);
  }

  const repo = await getRepo(params);

  const doDelete = () => repo.deleteFile(branchName, path);

  const deleteResult = noRetry
    ? await doDelete()
    : await retry({ command: doDelete, logErrors: logRetryErrors });

  logger.info(`File ${path} deleted`);

  return deleteResult;
}

export function gitHash(inputString) {
  const stringToHash = 'blob ' + inputString.length + '\0' + inputString;
  return sha1HashHex(stringToHash);
}
