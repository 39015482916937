import { Paper } from '@mui/material';
import { useContext, useMemo } from 'react';

import { ThemeWrapperContext } from '../../../wrappers/ThemeWrapperContext';
import { TitleWrapper } from '../ContainerTitleWrapper';
import {
  generateSimpleChildren,
  makeThemedStyles,
  withActions,
} from '../dependencies';

import {
  FlexContainerProps,
  flexContainerTypeInfo,
} from './FlexContainerTypeInfo';
export { flexContainerTypeInfo };

const FlexContainer: React.FC<React.PropsWithChildren<FlexContainerProps>> = (
  props,
) => {
  const { border = false, title } = props;
  const classes = useStyles(props);
  const { theme } = useContext(ThemeWrapperContext);

  const Children = generateSimpleChildren(props);
  const Wrapper = border === 'paper' ? Paper : 'div';

  const Container = useMemo(
    () => (
      <Wrapper className={classes.outer} {...props.elementAttributes}>
        {Children}
      </Wrapper>
    ),
    [Children, Wrapper, classes, props.elementAttributes],
  );

  if (title) {
    return TitleWrapper(Container, title, theme);
  }

  return Container;
};

const useStyles = makeThemedStyles<FlexContainerProps>(() => ({
  outer: (props) => ({
    display: 'flex',
    flexDirection: props.flexDirection,
    flexWrap: props.flexWrap,
    flexShrink: props.flexShrink,
    flexGrow: props.flexGrow,
    justifyContent: props.justifyContent,
    alignContent: props.alignContent,
    alignItems: props.alignItems,
    overflow: props.overflow,
    boxSizing: props.boxSizing,
    cursor: props.cursor,
    gap: props.gap,
    borderStyle: props.border,
    width: props.width,
    height: props.height,
    maxWidth: props.maxWidth,
    maxHeight: props.maxHeight,
    minHeight: props.minHeight || 0,
    minWidth: props.minWidth,
    borderWidth: props.borderWidth,
    borderColor: props.borderColor,
    borderRadius: props.borderRadius,
    padding: props.padding,
    margin: props.margin,
    backgroundColor: props.backgroundColor,
  }),
}));

export default withActions(FlexContainer);
