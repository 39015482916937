import {
  ApolloQueryResult,
  ObservableQuery,
  WatchQueryOptions,
} from '@apollo/client/core';
import { DocumentNode } from 'graphql';
import { Observer } from 'zen-observable-ts';

import { getLogger, Loggers } from '../loggerSupport';

/*
Information about a query stage
 */
import { SizeClass } from '../sizeClass';

import { IOutputPipelineInfo } from './pipelineManager';
import { StageImpl } from './stageImpl';

const logger = getLogger({ name: Loggers.PIPELINE_MANAGER });

export class StageQuery {
  public stage: StageImpl;

  // Saved output at the time of the query. This is what's given to the pipeline
  // before executing the callback.
  public savedOutput: any;

  public tracingInfo: string;

  public queryStartTime: number;

  // limit argument value
  public limitArgumentValue: number;

  public subscribeOptions: WatchQueryOptions;

  public isListQuery: boolean;

  public isConnectionCount: boolean;

  // Used when isConnectionCount, sum of all of the counts
  public cumCount: number;

  // Name of GraphQL query
  public queryName: string;

  public queryId: number;

  // Arguments from query as an object
  public args: any;

  // Filters to check for each object going into the cache
  public filters: string[];

  public firstFetchMore: { [chunkId: number]: boolean };

  public sizeClass: SizeClass;

  public outputPipelineInfo: IOutputPipelineInfo;

  // Accumulates the results in chunked queries to return to satisfy the
  // main query. Once per chunk, they are combined at the end. These will always
  // reflect what was last read for the query, so that if any chunk was updated due to
  // a mutation, the other chunks will have complete data (and still be marked done) so that the
  // entire query is reported as having been updated
  public cumChunkedQueryResult: { [chunkId: string]: Record<string, any>[] };
  public chunkDone: { [chunkId: string]: boolean };

  // Handle for the subscription to the queries (multiple queries are possible with chunks)
  public obsQueries: { [chunkId: string]: ObservableQuery };

  // Subscriptions to ObservableQuery that correspond to this stage when
  // it's active. These are cleaned up when the stage closes.
  public obsQuerySubscriptionHandles: any[];

  public obsQuerySubscriptionHandlers: {
    [chunkId: string]: Observer<ApolloQueryResult<any>>;
  };

  // This is used both for a chunkId which is part of the size class mechanism and also
  // the values for a query argument when it's a list (and the key to be used with the
  // database)
  public graphqlQueriesByChunk: { [chunkId: string]: DocumentNode };

  // Waiting for the query to come back the first time
  public obsQueryPromise: Promise<any>;
  public obsQueryPromiseResolve: any;

  // Used for diagnostic purposes
  public pageCount: number;

  // The number of items seen so far, used for paging support
  public cumQueryCount = 0;

  // For diagnostics;
  public unsubscribeCount = 0;

  public timestamp: number;

  constructor() {
    this.timestamp = Date.now();
  }

  public reset() {
    this.cumChunkedQueryResult = {};
    this.chunkDone = {};
    this.obsQueries = {};
    this.obsQuerySubscriptionHandlers = {};
    this.obsQuerySubscriptionHandles = [];
    this.graphqlQueriesByChunk = {};
    this.firstFetchMore = {};
    this.pageCount = 0;
    this.cumCount = 0;
    this.cumQueryCount = 0;
    this.stage.closed = false;
  }

  public unsubscribeFromObservableQueries(close?: boolean) {
    if (!this.obsQueries) {
      return;
    }

    this.unsubscribeCount++;
    const { _name } = this.stage.workingProperties;

    const queryStrings = [];
    Object.keys(this.obsQueries).forEach((k) =>
      queryStrings.push(k + ' id: ' + this.obsQueries[k].queryId),
    );
    const queryString = queryStrings.join(', ');

    logger.debug(
      `StageQuery close - ${this.stage.executor.getTracingInfo()} ${_name} - apollo queryIds: ${queryString}`,
    );

    this.obsQuerySubscriptionHandles.forEach((h) => {
      logger.debug(`pipelineStage unsubscribe: ${_name}/${this.queryName}`);
      h.unsubscribe();
      logger.debug(
        `pipelineStage unsubscribe DONE: ${_name}/${this.queryName}`,
      );
    });

    if (close) {
      this.stage.closed = true;
    }
  }
}
