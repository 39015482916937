import { ButtonBase, Icon, Paper, Slide, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import classNames from 'classnames';
import { Component } from 'react';

const styles = (theme) =>
  ({
    outer: {
      position: 'relative',
      height: '100%',
      width: '100%',
      display: 'grid',
      gridTemplateRows: 'auto 24px',
      overflow: 'hidden',
    },

    content: {
      overflow: 'auto',
    },

    trigger: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
    },

    triggerClosed: {
      gridRow: '2/3',
    },

    paper: {
      overflowY: 'auto',
      zIndex: 1200,
      position: 'absolute',
      outline: 'none',
    },

    paperAnchorBottom: {
      bottom: 0,
      maxHeight: '100%',
    },

    paperAnchorDockedBottom: {
      borderTop: `1px solid ${theme.palette.divider}`,
    },
  }) as const;

interface IProps {
  overlayContent?;
  height?;
  label?;
  open?;
  onClick?;
  containerWidth?;
  overflow?;
}

class Overlay extends Component<IProps> {
  public declare props;
  public declare state;
  public mounted;
  constructor(props) {
    super(props);
    this.state = {
      in: false,
    };
    this.toggle = this.toggle.bind(this);
  }

  toggle(event) {
    event.stopPropagation();

    const { onClick, open } = this.props;
    if (onClick) {
      onClick(!open);
    } else {
      this.setState((prevState: any) => {
        return {
          in: !prevState.in,
        };
      });
    }
  }

  render() {
    const {
      classes,
      label = 'open',
      height = '50%',
      width = '100%',
      containerHeight = '100%',
      containerWidth = '100%',
      overflow = 'auto',
      overlayContent,
      open,
    } = this.props;

    const isOpen = open || this.state.in;

    const toggleHeader = (
      <ButtonBase
        className={classNames(classes.trigger, {
          [classes.triggerClosed]: !isOpen,
        })}
        onClick={this.toggle}
      >
        <Icon>{isOpen ? 'expand_more' : 'expand_less'}</Icon>
        <Typography>{label.toUpperCase()}</Typography>
      </ButtonBase>
    );

    return (
      <div
        className={classes.outer}
        key="options overlay"
        style={{ height: containerHeight, width: containerWidth }}
      >
        <div className={classes.content}>{this.props.children}</div>
        {!isOpen && toggleHeader}
        <Slide
          in={isOpen}
          direction="up"
          appear={this.mounted}
          mountOnEnter
          unmountOnExit
        >
          <Paper
            elevation={10}
            className={classNames(classes.paper, classes.paperAnchorBottom, {
              [classes.paperAnchorDockedBottom]: isOpen,
            })}
            style={{ height, width, overflow }}
          >
            {toggleHeader}
            <div>{overlayContent}</div>
          </Paper>
        </Slide>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(Overlay);
