import { Modal } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { Component } from 'react';

import Loading from '../atoms/Loading';

const styles = () => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '10%',
    outline: 'none',
  },
});

class LoadingModal extends Component {
  public declare props;
  public handleClickAway;
  render() {
    const { classes } = this.props;
    return (
      <Modal
        onClose={this.handleClickAway}
        open={true}
        className={classes.modal}
      >
        <div
          style={{
            maxHeight: window.innerHeight,
            maxWidth: window.innerWidth,
            outline: 'none',
          }}
        >
          <Loading />
        </div>
      </Modal>
    );
  }
}

export default withStyles(styles, { withTheme: true })(LoadingModal);
