// WARNING - this is low-level code and cannot depend on the ClientManager or anything that depends on that

import { Readable } from 'stream';

import {
  DeleteObjectCommand,
  DeleteObjectCommandInput,
  DeleteObjectCommandOutput,
  DeleteObjectsCommand,
  GetBucketLocationCommand,
  GetBucketLocationCommandInput,
  GetBucketLocationCommandOutput,
  GetObjectCommand,
  GetObjectCommandInput,
  GetObjectCommandOutput,
  HeadBucketCommand,
  HeadBucketCommandOutput,
  HeadObjectCommand,
  HeadObjectCommandOutput,
  ListObjectsV2Command,
  ListObjectsV2CommandInput,
  ListObjectsV2CommandOutput,
  PutObjectCommand,
  PutObjectCommandInput,
  PutObjectCommandOutput,
  S3Client,
} from '@aws-sdk/client-s3';
import { isBrowser } from 'browser-or-node';

import { reThrow } from '../errors/errorLog';
import { retry } from '../errors/retry';
import { getLogger, Loggers } from '../loggerSupport';
import { StackInfo } from '../stackInfo';

import { COMPANY_DOMAIN, DEV_STACK_TYPE, SYSTEM_TEST } from './commonConstants';

const logger = getLogger({ name: Loggers.S3SUPPORT });

const S3_TEMPORARY_STORAGE_BUCKET = 'temporarystorage';

// Standard S3 paths for the customer/data buckets

export const S3_INGEST = 'ingest';
export const S3_RAW = 'raw';
export const S3_DUMP_RESTORE = 'dumpRestore';
export const S3_TRANSFORM = 'transform';
export const S3_WORKING = 'working';

// Images
export const S3_IMAGE = 'image';

// Attachments
export const S3_ATTACHMENTS = 'attachments';

const protocolToUse = isBrowser ? location.protocol : 'http:';

export class S3Support {
  private stackInfo: StackInfo;

  // Do not use this directly, always use getS3ClientForBucket
  private readonly s3Client: S3Client;

  // Set for real by ClientManagerServer
  public pathSep: string;

  private s3ClientsMap: { [bucketName: string]: S3Client } = {};

  constructor(stackInfo: StackInfo, pathSep: string) {
    this.pathSep = pathSep;
    this.stackInfo = stackInfo;
    this.s3Client = new S3Client(stackInfo.getAwsConfig());

    if (false) {
      this.s3ClientsMap['globexp.snapstrat.com'] = new S3Client({
        ...stackInfo.getAwsConfig(),
        region: 'ap-southeast-2',
      });
    }
  }

  async getS3ClientForBucket(bucket: string): Promise<S3Client> {
    if (this.s3ClientsMap[bucket]) {
      return this.s3ClientsMap[bucket];
    }

    try {
      await retry<HeadBucketCommandOutput>({
        command: () =>
          this.s3Client.send(new HeadBucketCommand({ Bucket: bucket })),
      });
      this.s3ClientsMap[bucket] = this.s3Client;
      return this.s3Client;
    } catch (error) {
      // AWS insists the S3 client be in the region that matches the bucket.
      // When using a dev stack, we don't know which region the production bucket is in
      // thanks to the limitations of how we manage configs, so just do what it says
      if (error.name === '301') {
        const region = error.$response.headers['x-amz-bucket-region'];
        const s3ClientParams = {
          ...this.stackInfo.getAwsConfig(),
          region,
        };
        const s3Client = new S3Client(s3ClientParams);
        this.s3ClientsMap[bucket] = s3Client;
        return s3Client;
      }
      throw error;
    }
  }

  public async getBucketLocation(
    params: GetBucketLocationCommandInput,
    s3Client: S3Client,
  ): Promise<GetBucketLocationCommandOutput> {
    const result = await retry<GetBucketLocationCommandOutput>({
      command: () => s3Client.send(new GetBucketLocationCommand(params)),
    });
    return result;
  }

  public async getObject(
    params: GetObjectCommandInput,
    noErrorLog?: boolean,
  ): Promise<GetObjectCommandOutput> {
    try {
      const result = await retry<GetObjectCommandOutput>({
        command: async () =>
          (await this.getS3ClientForBucket(params.Bucket)).send(
            new GetObjectCommand(params),
          ),
      });
      return result;
    } catch (error) {
      reThrow({
        logger: noErrorLog ? undefined : logger,
        error,
        message: 'Problem with getting S3 Object',
        logObject: params,
      });
    }
  }

  public async putObject(
    params: PutObjectCommandInput,
  ): Promise<PutObjectCommandOutput> {
    try {
      const result = await retry<PutObjectCommandOutput>({
        command: async () =>
          (await this.getS3ClientForBucket(params.Bucket)).send(
            new PutObjectCommand(params),
          ),
      });
      return result;
    } catch (error) {
      reThrow({
        logger,
        error,
        message: 'Problem with putting S3 Object',
        logObject: params,
      });
    }
  }

  public async deleteObject(
    params: DeleteObjectCommandInput,
  ): Promise<DeleteObjectCommandOutput> {
    try {
      const result = await retry<DeleteObjectCommandOutput>({
        command: async () =>
          (await this.getS3ClientForBucket(params.Bucket)).send(
            new DeleteObjectCommand(params),
          ),
      });
      return result;
    } catch (error) {
      reThrow({
        logger,
        error,
        message: 'Problem with deleting S3 Object',
        logObject: params,
      });
    }
  }

  private removePrefix({
    prefix,
    pathParam,
  }: {
    prefix: string;
    pathParam: string;
  }): string {
    const fixedPrefix = prefix.endsWith('/') ? prefix : prefix + '/';

    return pathParam.slice(fixedPrefix.length);
  }

  public parseS3FilePath(filePath: string): {
    bucket: string;
    key: string;
  } {
    let splitPath;
    if (filePath.indexOf(this.pathSep) === -1) {
      splitPath = filePath.split('/');
    } else {
      splitPath = filePath.split(this.pathSep);
    }
    const bucket = splitPath[0];
    const key = splitPath.slice(1).join('/');

    return {
      bucket,
      key,
    };
  }

  public async getS3FileSize(params: {
    filePath?: string;
    bucket?: string;
    key?: string;
  }): Promise<number> {
    const { filePath } = params;
    let { bucket, key } = params;

    if (!filePath && (!bucket || !key)) {
      throw new Error('You must specify either a file path or a bucket && key');
    }

    if (filePath) {
      ({ bucket, key } = this.parseS3FilePath(filePath));
    }

    const s3Params = {
      Bucket: bucket,
      Key: key,
    };

    let headResponse;

    try {
      headResponse = await retry<HeadObjectCommandOutput>({
        command: async () =>
          (await this.getS3ClientForBucket(s3Params.Bucket)).send(
            new HeadObjectCommand(s3Params),
          ),
      });
    } catch (error) {
      reThrow({
        logger,
        error,
        message: `Error getting file information from bucket: ${bucket} key: ${key}`,
      });
    }

    return headResponse.ContentLength;
  }

  public async getS3ObjectAsBytes(params: {
    filePath?: string;
    configName?: string;
    start?: number;
    end?: number;
  }): Promise<Buffer> {
    const { bucket, key } = this.parseS3FilePath(params.filePath);

    let { start, end } = params;

    if (start !== undefined && end === undefined) {
      const fileSize = await this.getS3FileSize({ bucket, key });
      end = fileSize - 1;
    } else if (start === undefined && end !== undefined) {
      start = 0;
    }

    const s3Params = {
      Bucket: bucket,
      Key: key,
    };

    if (start !== undefined && end !== undefined) {
      const range = `bytes=${start}-${end}`;
      Object.assign(s3Params, { Range: range });
    }

    const result = await retry({
      command: () => this.getObject(s3Params),
    });
    return Buffer.from(await result.Body.transformToByteArray());
  }

  public async getS3ObjectAsString(s3Path: string): Promise<string> {
    logger.info(`Reading ${s3Path} File`);
    const inputStream = await retry({
      command: () =>
        this.getObjectAsStream({
          s3Path,
        }),
    });
    const chunks = [];
    for await (const chunk of inputStream) {
      chunks.push(Buffer.from(chunk));
      if (chunks.length % 1000 == 0) {
        logger.info(`${s3Path} - got chunk ${chunks.length}`);
      }
    }
    const buffer = Buffer.concat(chunks);
    const outputString = buffer.toString('utf-8');
    return outputString;
  }

  // Keep in sync with calculateS3BucketName in buildUtils

  public getS3StackBucketName() {
    return S3Support.getS3StackBucketNameWithStackId(
      this.stackInfo.getStackId(),
    );
  }

  public getS3DataBucket(configName: string): string {
    return configName === SYSTEM_TEST
      ? `${configName}${'-dev'}.${COMPANY_DOMAIN}`
      : `${configName}.${COMPANY_DOMAIN}`;
  }

  public getS3DataPath({
    configName,
    path,
  }: {
    configName: string;
    path?: string;
  }): string {
    if (path) {
      return `${this.getS3DataBucket(configName)}/${path}`;
    }
    return this.getS3DataBucket(configName);
  }

  public getS3Path(params: {
    configName: string;
    basePath?: string;
    filePath?: string;
  }): string {
    const { configName, filePath, basePath } = params;
    const ingestPath = this.getS3DataPath({ configName, path: basePath });

    if (filePath) {
      return `${ingestPath}/${filePath}`;
    }

    return ingestPath;
  }

  public async getObjectAsStream(params: {
    s3Path: string;
  }): Promise<Readable> {
    const { s3Path } = params;
    const s3PathArray = s3Path.split('/');
    const bucket = s3PathArray[0];

    const command = new GetObjectCommand({
      Bucket: bucket,
      Key: s3PathArray.slice(1).join('/'),
    });

    const item = await retry<GetObjectCommandOutput>({
      command: async () =>
        (await this.getS3ClientForBucket(bucket)).send(command),
    });
    return item.Body as Readable;
  }

  public async headObject(params: { s3Path: string }): Promise<boolean> {
    const { s3Path } = params;
    const s3PathArray = s3Path.split('/');
    const bucket = s3PathArray[0];

    const command = new HeadObjectCommand({
      Bucket: bucket,
      Key: s3PathArray.slice(1).join('/'),
    });

    try {
      await retry<HeadObjectCommandOutput>({
        command: async () =>
          (await this.getS3ClientForBucket(bucket)).send(command),
      });
      return true;
    } catch (error) {
      if (error.name === 'NotFound') {
        return false;
      }
      reThrow({
        logger,
        error,
        message: `Problem with headObject on ${s3Path}`,
      });
    }
  }

  public async listS3Contents(params: {
    s3Path: string;
    showAllContents?: boolean;
  }): Promise<Array<{ fileName: string; size: number }>> {
    const { s3Path, showAllContents } = params;
    const { bucket, key } = this.parseS3FilePath(s3Path);

    const listParams: ListObjectsV2CommandInput = {
      Bucket: bucket,
      Prefix: key,
      MaxKeys: 10000,
    };

    const allContents = [];

    let result: ListObjectsV2CommandOutput;
    const s3Client = await this.getS3ClientForBucket(listParams.Bucket);
    do {
      result = await retry<ListObjectsV2CommandOutput>({
        command: () => s3Client.send(new ListObjectsV2Command(listParams)),
      });
      if (result.KeyCount === 0) {
        return [];
      }
      result.Contents.forEach((file) => allContents.push(file));
      if (result.NextContinuationToken) {
        listParams.ContinuationToken = result.NextContinuationToken;
      }
    } while (result.NextContinuationToken);

    const allContentsPathFixed = allContents.map(
      ({ Key: contentPath, Size: size }) => {
        const fileName = this.removePrefix({
          prefix: key,
          pathParam: contentPath,
        });

        return {
          fileName,
          size,
        };
      },
    );

    if (showAllContents) {
      return allContentsPathFixed;
    }

    return allContentsPathFixed.filter(
      (file) => file.fileName !== '' && !file.fileName.includes('/'),
    );
  }

  public async deleteS3DataObject(params: {
    configName: string;
    path: string;
  }): Promise<void> {
    const { configName, path } = params;

    const s3params = {
      Bucket: this.getS3DataBucket(configName),
      Key: path,
    };

    try {
      await retry<DeleteObjectCommandOutput>({
        command: async () =>
          (await this.getS3ClientForBucket(s3params.Bucket)).send(
            new DeleteObjectCommand(s3params),
          ),
      });
    } catch (error) {
      reThrow({
        logger,
        message: 'Problem with deleteObject',
        logObject: { s3params },
        error,
      });
    }
  }

  public static getS3StackBucketNameWithStackId(stackId: string) {
    return `${stackId}.${COMPANY_DOMAIN}`;
  }

  public static getS3StackBucketUrl(
    stackId: string,
    awsRegion: string,
    path?: string,
  ) {
    const baseUrl = `${protocolToUse}//s3.${awsRegion}.amazonaws.com/${stackId}.${COMPANY_DOMAIN}`;
    if (path) {
      return `${baseUrl}/${path}`;
    }
    return baseUrl;
  }

  public getS3DataUrl({
    configName,
    path,
  }: {
    configName: string;
    path: string;
  }) {
    const bucketName = this.getS3DataBucket(configName);
    const baseUrl = `${protocolToUse}//s3.${this.stackInfo.baseStackInfo.region}.amazonaws.com/${bucketName}`;
    if (path) {
      return `${baseUrl}/${path}`;
    }
    return baseUrl;
  }

  public static getS3TempStorageBucketName(stackType: string, region: string) {
    if (!region) {
      throw new Error('Region not specified');
    }
    return (
      S3_TEMPORARY_STORAGE_BUCKET +
      (stackType === DEV_STACK_TYPE ? '-dev' : '') +
      '-' +
      region +
      '.' +
      COMPANY_DOMAIN
    );
  }

  public static getS3BucketAndKeyWithStackType(params: {
    purpose: string;
    id?: string;
    region: string;
    stackType: string;
  }) {
    const { purpose, id, region, stackType } = params;
    const keyPrefix = `${purpose}/`;

    return {
      keyPrefix,
      bucket: S3Support.getS3TempStorageBucketName(stackType, region),
      key: `${keyPrefix}${id}`,
    };
  }

  public getS3BucketAndKey(params: { purpose: string; id?: string }) {
    return S3Support.getS3BucketAndKeyWithStackType({
      ...params,
      stackType: this.stackInfo.getStackType(),
      region: this.stackInfo.getAwsConfig().region || process.env.AWS_REGION,
    });
  }

  public async cleanupS3ByKey(params: {
    bucket: string;
    keyPrefix: string;
    checkTime?: number;
  }) {
    return S3Support.deleteS3ObjectsByKey({
      ...params,
      s3Client: await this.getS3ClientForBucket(params.bucket),
    });
  }

  public static async deleteS3ObjectsByKey(params: {
    bucket: string;
    keyPrefix: string;
    checkTime?: number;
    s3Client: S3Client;
  }) {
    const { bucket, keyPrefix, checkTime, s3Client } = params;
    logger.info({ bucket, keyPrefix, checkTime }, 'deleteS3ObjectsByKey');
    let moreToDo = true;
    let contToken;
    while (moreToDo) {
      const s3Objects = await retry<ListObjectsV2CommandOutput>({
        command: () =>
          s3Client.send(
            new ListObjectsV2Command({
              Bucket: bucket,
              ContinuationToken: contToken,
            }),
          ),
      });
      moreToDo = s3Objects.IsTruncated;
      contToken = s3Objects.NextContinuationToken;
      const keys = [];
      if (s3Objects.KeyCount > 0) {
        for (const obj of s3Objects.Contents) {
          if (
            obj.Key.startsWith(keyPrefix) &&
            (checkTime ? obj.LastModified.getTime() < checkTime : true)
          ) {
            keys.push({ Key: obj.Key });
          }
        }
      }
      if (keys.length > 0) {
        logger.info(`Deleting ${keys.length} objects `);
        await retry({
          command: () =>
            s3Client.send(
              new DeleteObjectsCommand({
                Bucket: bucket,
                Delete: {
                  Objects: keys,
                },
              }),
            ),
        });
      }
    }
  }
}
