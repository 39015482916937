/*
 Code common to all tests. Not to be confused with testSupport.ts

 WARNING - don't include any test assertion stuff, like from node or chai as this
 is part of the actual build (it's refered to by the ClientManager)

 */

import {
  DeleteMessageCommand,
  ReceiveMessageCommandOutput,
} from '@aws-sdk/client-sqs';
import { Logger } from 'pino';
import safeJsonStringify from 'safe-json-stringify';

import { ClientManager } from './clientManager';
import { SKIP_INTERMITTENT, SYSTEM_TEST } from './common/commonConstants';
import { randomString } from './common/commonUtilities';
import { LogLevels, Loggers, getLogger } from './loggerSupport';
import { UseTestData } from './schemaManager';
import { StageType } from './types';
import { sleep } from './utilityFunctions';

const logQueryTiming = getLogger({
  name: Loggers.QUERY_TIMING,
  level: LogLevels.Warn,
});

let logger: Logger;
let clientManager: ClientManager;
let queueAttrParams;

export let initialLogLevel;
export const initialQueryTimingLogLevel = logQueryTiming.level;

const testRecords = {};

export function initCommonTest(params: {
  clientManager: ClientManager;
  logger: Logger;
}) {
  clientManager = params.clientManager;
  logger = params.logger;
  initialLogLevel = logger.level;
}

const wasDebug: boolean[] = [];
const wasQueryTiming: boolean[] = [];

export function pushLoggerDebug() {
  wasDebug.push(logger.isLevelEnabled(LogLevels.Debug));
  logger.level = LogLevels.Debug;
}

export function popLoggerDebug() {
  logger.level = wasDebug.pop() ? LogLevels.Debug : initialLogLevel;
}

export function pushLoggerQueryTiming() {
  wasQueryTiming.push(logQueryTiming.isLevelEnabled(LogLevels.Info));
  logQueryTiming.level = LogLevels.Info;
}

export function popLoggerQueryTiming() {
  logQueryTiming.level = wasQueryTiming.pop()
    ? initialQueryTimingLogLevel
    : LogLevels.None;
}

export async function clientManagerBeforeEach(
  clientManagerLocal: ClientManager,
) {
  await clientManagerLocal.pipelineManager.graphQLManager.resetAllNetworkProcessors(
    true,
  );
}

export async function clientManagerAfterEach(
  loggerLocal: Logger,
  clientManagerName: string,
  clientManagerLocal: ClientManager,
) {
  loggerLocal.info(
    `${
      clientManagerName ? clientManagerName + ': ' : ''
    }${clientManagerLocal.pipelineManager.graphQLManager.allCountersString()}`,
  );
  await clientManagerLocal.shutdown();
  clientManagerLocal.pipelineManager.batchMutationConcurrencyOverride = null;
}

export async function switchToSystemTest(
  clientManagerToUse: ClientManager = clientManager,
) {
  clientManagerToUse.schemaManager.setUseTestData(UseTestData.ALL);
  await clientManagerToUse.schemaManager.changeExecutionConfig(SYSTEM_TEST);
}

export function makeGeneralTestRecordsArray(params: {
  idPrefix: string;
  recordCount: number;
  recordSize: number;
}): any[] {
  const { idPrefix, recordCount, recordSize } = params;

  const testData = [];
  const stringData = 'blah'.repeat(recordSize / 4);
  for (let i = 0; i < recordCount; i++) {
    const iString = ('0000000' + i).slice(-6);
    testData.push({
      id: `${idPrefix}_${iString}`,
      testId: idPrefix,
      someOtherField: stringData,
    });
  }
  return testData;
}

export async function makeGeneralTestRecords(params: {
  entityName: string;
  idPrefix: string;
  recordCount: number;
  recordSize: number;
  forceCreate?: boolean;
  noCacheForMutation?: boolean;
}): Promise<{ rps: number }> {
  const {
    entityName,
    idPrefix,
    recordCount,
    recordSize,
    forceCreate,
    noCacheForMutation = true,
  } = params;

  const testRecordKey = entityName + idPrefix;
  if (testRecords[testRecordKey] && !forceCreate) {
    return;
  }

  const { pipelineManager } = clientManager;

  // See if the data is in the database - this allows for multiple runs of the test
  // without recreating the data
  const result = await pipelineManager.countRecords({
    entityName,
    configName: SYSTEM_TEST,
    queryArguments: { testId: idPrefix },
  });
  logger.info(`Found ${result} records - ${entityName}/${idPrefix}`);
  if (!forceCreate && result === recordCount) {
    logger.info(`Using existing test records: ${entityName}/${idPrefix}`);
    return;
  }

  logger.info(`Deleting previous test records: ${entityName} - ${idPrefix}`);
  await pipelineManager.deleteAllRecords({
    entityName,
    configName: SYSTEM_TEST,
    queryArguments: { testId: idPrefix },
  });

  logger.info(
    `Creating ${recordCount} records using remote pipeline - ${entityName}/${idPrefix}`,
  );
  const pipelineResult = await pipelineManager.executePipelineRemote({
    stages: [
      {
        _stageType: StageType.javaScript,
        code: `
  await clientManager.commonTest.switchToSystemTest(clientManager);
  const testData = clientManager.commonTest.makeGeneralTestRecordsArray({
    idPrefix: '${idPrefix}',
    recordCount: ${recordCount},
    recordSize: ${recordSize},
  });

  const retVal = await clientManager.utilityFunctions.performanceTimes({
    logger,
    recordCount: ${recordCount},
    func: async () => {
      logger.info(\`Creating test records: ${entityName} - ${idPrefix}\`);
      await pipelineManager.createRecords({
        entityName: '${entityName}',
        configName: '${SYSTEM_TEST}',
        mutationDirectives: '@suppresslog @suppressnotify',
        records: testData,
        upsert: true,
        noCache: ${noCacheForMutation},
      });
    },  
  });

  output.rps = retVal;

      `,
      },
    ],
  });

  await pipelineManager.waitForRecords(recordCount, {
    entityName,
    configName: SYSTEM_TEST,
    queryArguments: { testId: idPrefix },
  });

  testRecords[testRecordKey] = true;
  const rps = (pipelineResult as any).rps;
  logger.info(
    `Creating ${recordCount} records using remote pipeline - ${entityName}/${idPrefix} - done rps: ${rps}`,
  );
  return rps;
}

export function intermittent(jiraComment, desc, func) {
  if (process.env[SKIP_INTERMITTENT] === 'true') {
    console.log(
      `INTERMITTENT (skipping): ${desc}: Skipping because Jira: ${jiraComment}`,
    );
    it.skip(desc);
    return {
      timeout: () => {
        // nothing
      },
    };
  }
  return it(desc, func);
}

export function randomYear() {
  let rando;
  while (!rando || (rando >= '2000' && rando <= '2100')) {
    rando = randomString(4);
  }
  return rando;
}

export const setupQueueAttrParams = async (params?: {
  queueNameExtension?: string;
}): Promise<string> => {
  const queueUrl = clientManager.getSqsQueueUrl(params);
  queueAttrParams = {
    AttributeNames: ['All'],
    QueueUrl: queueUrl,
  };
  return queueUrl;
};

export const clearOutQueue = async (params?: {
  queueNameExtension?: string;
}) => {
  const queueUrl = await setupQueueAttrParams(params);
  let queueAttributes =
    await clientManager.sqsClient.getQueueAttributes(queueAttrParams);
  while (queueAttributes.Attributes.ApproximateNumberOfMessages !== '0') {
    logger.info(
      queueAttributes.Attributes,
      `Waiting for queue: ${queueUrl} to become empty`,
    );
    const purgeResponse = await clientManager.sqsClient.purgeQueue({
      QueueUrl: queueUrl,
    });
    logger.info(purgeResponse, 'queue purge response');
    await sleep(2000);
    queueAttributes =
      await clientManager.sqsClient.getQueueAttributes(queueAttrParams);
  }
};

export const getSqsMessages = async (params?: {
  queueName?: string;
  queueNameExtension?: string;
}): Promise<ReceiveMessageCommandOutput> => {
  const queueUrl = await setupQueueAttrParams(params);

  const result = await clientManager.sqsClient.receiveMessage({
    MaxNumberOfMessages: 10,
    MessageAttributeNames: ['All'],
    QueueUrl: queueUrl,
    WaitTimeSeconds: 1,
  });

  return result;
};

export const deleteSqsMessage = async (params?: {
  queueName?: string;
  queueNameExtension?: string;
  receiptHandle: string;
}): Promise<void> => {
  const queueUrl = await setupQueueAttrParams(params);
  await clientManager.sqsClient.send(
    new DeleteMessageCommand({
      QueueUrl: queueUrl,
      ReceiptHandle: params.receiptHandle,
    }),
  );
};

export const checkQueueEmpty = async (params?: {
  queueNameExtension?: string;
}): Promise<boolean> => {
  await setupQueueAttrParams(params);

  let queueAttributes;
  for (let i = 0; i < 20; i++) {
    queueAttributes =
      await clientManager.sqsClient.getQueueAttributes(queueAttrParams);

    const messages = parseInt(
      queueAttributes.Attributes.ApproximateNumberOfMessages,
      10,
    );
    if (messages === 0) {
      return true;
    }
    await sleep(1000);
  }
  logger.error(
    `Queue not empty: ${safeJsonStringify(queueAttributes.Attributes)}`,
  );
  return false;
};
