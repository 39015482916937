import Handlebars from 'handlebars';
import {
  FunctionComponent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { HotKeys } from 'react-hotkeys';
import { Route, Switch } from 'react-router';
import { Redirect } from 'react-router-dom';
import { IApplication } from 'universal/applicationManager';
import { getLogger, Loggers, LogLevels } from 'universal/loggerSupport';
import { findConfigItem, MetadataSupport } from 'universal/metadataSupport';
import { AccessType, PermissionType } from 'universal/permissionManager';

import Loading from '../components/atoms/Loading';
import { ClientDebugPanelWrapper } from '../components/molecules/ClientDebugPanel';
import NavLayout, {
  NavContext,
  navReducerTypes,
} from '../components/navigation/NavLayout';
import TreeRenderer from '../components/widgetEngine/TreeRenderer';
import { IWidgetTrees } from '../components/widgets/types';
import { updateContext } from '../store/data';
import { store } from '../store/store';
import BreadCrumb from '../util/BreadCrumbs';
import { escapeUrlPath } from '../util/clientUtilities';
import PipelineExternalAdaptor from '../util/pipelineExternalAdaptor';
import { previewWidget } from '../util/previewWidget';
import { widgetCan } from '../util/widgetUtilities';
import DataRoute from '../views/DataViewer/DataRoute';
import GraphQlContainer from '../views/DataViewer/GraphQlContainer';
import QueryList from '../views/DataViewer/QueryList';
import DefaultHome from '../views/DefaultHome';
import EditPage from '../views/EditPage/EditPage';
import LoadSaveConfiguration from '../views/LoadSaveConfiguration';

import {
  ApplicationContext,
  IApplicationContext,
  IInitialContext,
  INavigation,
  INavItem,
} from './ApplicationContext';
import { AuthenticatorContext } from './AuthenticatorContext';
import { ClientContext } from './ClientContext';
import { ClientLogDebugContext } from './ClientLogDebugContext';

const keyMap = {
  SAVE: 'command+s',
};

const logger = getLogger({
  name: Loggers.CLIENT_INITIALIZE,
  level: LogLevels.Info,
});

const Application: FunctionComponent<React.PropsWithChildren<unknown>> = () => {
  const { clientManager, userInfo, widgetTrees, navigations } =
    useContext(ClientContext);
  const { clientDebugMode, logs, logUpdate } = useContext(
    ClientLogDebugContext,
  );

  // The explicit initialization field is used to handle the applicationUpdated case
  const [applicationContext, setApplicationContext] = useState<
    IApplicationContext & { initialized: boolean }
  >({ initialized: false });

  // Callback from ApplicationManager
  const applicationUpdated = (application: IApplication) => {
    setApplicationContext({
      ...applicationContext,
      application,
      initialized: false,
    });
  };

  let { homePage, navigation } = applicationContext;
  const { initialContext } = applicationContext;

  useEffect(() => {
    const initApplication = async () => {
      logger.debug('Application initialization - start');
      const { applicationManager } = clientManager;

      let application = applicationManager.getCurrentApplication();
      if (!application) {
        const applications =
          applicationManager.getAvailableApplications(userInfo);
        if (applications.length === 0) {
          throw new Error(`No applications found for user ${userInfo.id}`);
        }
        application = applications[0];
        applicationManager.setApplication({
          applicationId: application.id,
        });
      }

      // Register the callback after setting the initial application above
      applicationManager.registerCallback(applicationUpdated);

      const initialInitialContext: IInitialContext = { _userInfo: userInfo };

      const { loginPipeline } = application;
      if (loginPipeline) {
        const external = new PipelineExternalAdaptor();
        const output = await clientManager.pipelineManager.executeNamedPipeline(
          {
            name: loginPipeline,
            input: { _userInfo: userInfo },
            external,
          },
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
        homePage = output.system_homepage;
        Object.assign(initialInitialContext, output);
      }

      if (initialInitialContext?._valueHelpers) {
        Object.keys(initialInitialContext._valueHelpers).forEach(
          (helperKey) => {
            Handlebars.registerHelper(
              helperKey,
              initialInitialContext._valueHelpers[helperKey],
            );
          },
        );
        delete initialInitialContext._valueHelpers;
      }

      store.dispatch(
        updateContext({
          // FIXME - make a constant for context
          update: { data: initialInitialContext, rawPath: 'context' },
        }),
      );

      // eslint-disable-next-line react-hooks/exhaustive-deps
      navigation = findConfigItem<INavigation>(
        navigations,
        application.navigation,
        application.configName,
      );
      if (!navigation) {
        throw new Error(
          `Navigation ${application.navigation} not found in application ${application.id}`,
        );
      }

      setApplicationContext({
        application,
        navigation,
        initialContext: initialInitialContext,
        homePage,
        initialized: true,
      });
      logger.debug('Application initialization - done');
    };

    if (!applicationContext.initialized) {
      void initApplication();
    }
  }, [applicationContext.initialized]);

  const { permissionManager } = clientManager;
  const { permissions } = userInfo;

  const initialPassThroughProps = useMemo(
    () => ({
      ...(clientDebugMode && { logUpdate }),
    }),
    [clientDebugMode, logUpdate],
  );

  const navModel = useMemo(() => {
    function buildNavModel(members: INavItem[], nav: INavigation): INavItem[] {
      const result = members.reduce((acc, member) => {
        member.configName = nav.configName;

        // Use the link target as the name to check for, which allows permissions to be effectively
        // set for nav items in different configs
        const qualifiedNavName = MetadataSupport.getQualifiedName(
          member.link ? member.link : member.name,
          nav.configName,
        );
        const navPermission = permissionManager.getAccess(
          permissions,
          PermissionType.Navigation,
          qualifiedNavName,
        );
        const navCan = widgetCan(AccessType.Read, navPermission);

        if (navCan) {
          if (member.link) {
            const linkItem = findConfigItem(
              navigations,
              member.link,
              nav.configName,
            );
            const subModel = buildNavModel(linkItem.members, linkItem);
            return subModel.length ? acc.concat(subModel) : acc;
          }
          if (member.internalLink) {
            if (member.internalLink !== 'widgetTrees') {
              throw new Error(
                `internalLink property can only be 'widgetTrees' found ${member.internalLink}`,
              );
            }
            return acc.concat([
              {
                ...member,
                members: buildWidgetTreeRoutes(widgetTrees),
              },
            ]);
          }
          if (member.internalView) {
            return acc.concat([member]);
          }
        }

        if (member.members) {
          const subMembers = buildNavModel(member.members, nav);
          return subMembers.length
            ? acc.concat([
                {
                  ...member,
                  members: subMembers,
                },
              ])
            : acc;
        }

        const { widgetTreeName } = member;

        if (!widgetTreeName && !navCan) {
          return acc;
        }

        // Widget permissions control access to navigation items that point to widgets,
        // navigation permissions are not necessary
        const tree =
          widgetTreeName &&
          findConfigItem(widgetTrees, widgetTreeName, nav.configName);

        const widgetPreview =
          tree &&
          tree.widgets &&
          tree.widgets[0] &&
          previewWidget({
            component: tree.widgets[0],
            context: initialContext,
          });

        const widgetPermissionId =
          widgetPreview && widgetPreview.properties.permissionId;
        if (!widgetPermissionId) {
          return acc;
        }

        const qualifiedWidgetPermissionId =
          permissionManager.getQualifiedPermissionId(
            tree.id,
            tree.configName,
            widgetPermissionId,
          );
        const widgetPermission =
          qualifiedWidgetPermissionId &&
          permissionManager.getAccess(
            permissions,
            PermissionType.Widget,
            qualifiedWidgetPermissionId,
          );

        if (!widgetCan(AccessType.Read, widgetPermission)) {
          return acc;
        }
        return acc.concat([member]);
      }, [] as INavItem[]);
      return result;
    }

    if (navigation) {
      return buildNavModel(navigation.members, navigation);
    }
  }, [
    navigation,
    permissionManager,
    permissions,
    widgetTrees,
    initialContext,
    navigations,
  ]);

  const navRoutes = useMemo(() => {
    function extractRoutesFromNavModel(navMod: INavItem[]): INavItem[] {
      return navMod.reduce((acc, member) => {
        if (member.members) {
          return acc.concat(extractRoutesFromNavModel(member.members));
        }
        if (member.url) {
          return acc.concat([member]);
        }
        return acc;
      }, []);
    }
    if (navModel) {
      return extractRoutesFromNavModel(navModel);
    }
  }, [navModel]);

  function buildWidgetTreeRoutes(trees: IWidgetTrees) {
    return Object.keys(trees).map((treeName) => {
      return {
        name: treeName,
        nameInfo: { displayName: treeName },
        widgetTreeName: treeName,
        url: treeName,
        configName: trees[treeName].configName,
      };
    });
  }

  const routeModel = useMemo(
    () =>
      navRoutes?.map((member) => {
        const { internalView, widgetTreeName, configName } = member;

        const tree =
          widgetTreeName &&
          findConfigItem(widgetTrees, widgetTreeName, configName);

        return {
          ...member,
          ...(tree && { tree }),
          ...(internalView && {
            internalViewComponent: {
              editTrees: (
                // @ts-ignore
                <EditPage initialPassThroughProps={initialPassThroughProps} />
              ),
              loadSaveConfiguration: <LoadSaveConfiguration />,
              graphQl: <GraphQlContainer />,
              // @ts-ignore
              queryList: <QueryList />,
              // @ts-ignore
              data: <DataRoute />,
            }[internalView],
          }),
        };
      }),
    [navRoutes, widgetTrees, initialPassThroughProps],
  );

  const routes = useMemo(
    () =>
      routeModel?.map((member) => {
        const {
          url,
          nameInfo: { displayName },
          tree,
          internalViewComponent,
        } = member;

        const component = tree ? (
          clientDebugMode ? (
            <ClientDebugPanelWrapper logs={logs}>
              <TreeRenderer
                tree={tree}
                key={displayName}
                initialPassThroughProps={initialPassThroughProps}
              />
            </ClientDebugPanelWrapper>
          ) : (
            <TreeRenderer
              tree={tree}
              key={displayName}
              initialPassThroughProps={initialPassThroughProps}
            />
          )
        ) : (
          internalViewComponent
        );
        const escapedUrl = escapeUrlPath(url);
        return (
          <Route key={`/${url}`} path={`/${escapedUrl}`}>
            <SecuredWithBreadCrumb
              url={`/${url}`}
              component={component}
              name={displayName}
            />
          </Route>
        );
      }),
    [clientDebugMode, initialPassThroughProps, logs, routeModel],
  );

  if (!applicationContext.initialized) {
    return <Loading />;
  }

  logger.debug('Application render');
  return (
    <ApplicationContext.Provider value={applicationContext}>
      <NavLayout navModel={navModel} passThroughProps={initialPassThroughProps}>
        <HotKeys keyMap={keyMap} style={{ height: '100%', outline: 'none' }}>
          <Switch>
            {routes}
            <Route
              key="home"
              path="/home"
              render={() => <DefaultHome homePage={homePage} />}
            />
            <Route>
              <Redirect to={homePage || '/home'} />
            </Route>
          </Switch>
        </HotKeys>
      </NavLayout>
    </ApplicationContext.Provider>
  );
};

export default Application;

function SecuredWithBreadCrumb(props) {
  const { url, component, name } = props;
  const { isAuthenticated } = useContext(AuthenticatorContext);
  const { dispatchNavState } = useContext(NavContext);
  const setBreadCrumbs = useCallback(
    (breadCrumbs: any) =>
      dispatchNavState({
        type: navReducerTypes.BREADCRUMBS_SET,
        payload: breadCrumbs,
      }),
    [dispatchNavState],
  );
  if (isAuthenticated()) {
    return (
      <BreadCrumb label={name} url={url} setBreadCrumbs={setBreadCrumbs}>
        {component}
      </BreadCrumb>
    );
  }
  return <Redirect to="/login" />;
}
