import { Typography } from '@mui/material';
import { Link } from 'react-router-dom';

function TestContainer() {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        height: '100%',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          flex: 1,
        }}
      >
        <Typography component={Link} to="/">
          This is for testing purposes only, if you got here by mistake, click
          here.
        </Typography>
      </div>
    </div>
  );
}

export default TestContainer;
