import _ from 'lodash';
import hash from 'object-hash';

import { ClientManager } from '../clientManager';

import { Loggers, getLogger } from 'universal/loggerSupport';

const logger = getLogger({ name: Loggers.AGGREGATE });
export class HashSupport {
  public clientManager: ClientManager;

  public initialize(clientManager: ClientManager) {
    this.clientManager = clientManager;
  }

  public getHashKeyArrayFromTypeDef(params: {
    entityType: string;
    configName?: string;
  }): string[] {
    const { entityType, configName } = params;
    const typeDefinition = this.clientManager.schemaManager.getTypeDefinition({
      name: entityType,
      configName,
    });
    const hashKeys = [];
    const attributes = typeDefinition.getAttributes();
    attributes
      .filter((attr) => attr.itemInfo.isAggregationMatchKey)
      .forEach((attr) => {
        hashKeys.push(attr.name);
      });
    return hashKeys;
  }
  public getAggregationValueFieldsFromTypeDef(params: {
    entityType: string;
    configName?: string;
  }): string[] {
    const { entityType, configName } = params;
    const typeDefinition = this.clientManager.schemaManager.getTypeDefinition({
      name: entityType,
      configName,
    });
    const valueFields = [];
    const attributes = typeDefinition.getAttributes();
    attributes
      .filter(
        (attr) =>
          attr.itemInfo.aggregationType ||
          attr.itemInfo.collectionType === 'MAP',
      )
      .forEach((attr) => {
        valueFields.push(attr.name);
      });
    return valueFields;
  }
  public getHashValue(params: { record: any; hashKeys: string[] }): string {
    const { record, hashKeys } = params;
    const hashValues = [];
    hashKeys.forEach((key) => {
      if (_.isObject(record[key])) {
        hashValues.push(_.get(record, `${key}.id`));
      } else {
        hashValues.push(record[key]);
      }
    });
    return hash(hashValues);
  }

  public async updateAggregation(params: {
    aggRecords: any;
    args: any;
    entityName: string;
    hashKeys: string[];
    valueFields: string[];
    configName: string;
    forceUpdate?: boolean;
    noDeletes?: boolean;
  }) {
    const {
      aggRecords,
      args,
      entityName,
      hashKeys,
      valueFields,
      configName,
      noDeletes,
    } = params;
    const queryArgsArray = this.buildQueryArguments(args);
    const currentRecordMap = {};
    for (const queryArguments of queryArgsArray) {
      logger.info(
        `getting current ${entityName} records ${JSON.stringify(
          queryArguments,
        )}`,
      );
      const recordsToChange = (
        await this.clientManager.pipelineManager.listRecords({
          entityName,
          queryArguments,
          configName,
        })
      ).items;
      logger.info(`Got ${recordsToChange.length} records`);
      recordsToChange.forEach((rec) => {
        const hashValue = this.clientManager.hashSupport.getHashValue({
          record: rec,
          hashKeys,
        });
        if (currentRecordMap[hashValue]) {
          if (currentRecordMap[hashValue].id === hashValue) {
            currentRecordMap[rec.id] = rec;
          } else {
            currentRecordMap[currentRecordMap[hashValue].id] =
              currentRecordMap[hashValue];
            currentRecordMap[hashValue] = rec;
          }
        } else {
          currentRecordMap[hashValue] = rec;
        }
      });
    }
    let newRecordCount = 0;
    let recordsToDelete = 0;
    let recordsToUpdate = 0;
    let goodRecords = 0;
    let hashUpdate = 0;
    const deleteIds = [];
    const newRecordsMap = {};
    aggRecords.forEach((agg) => {
      const currentRecord = currentRecordMap[agg.id];
      if (!currentRecord) {
        newRecordsMap[agg.id] = agg;
        newRecordCount++;
      } else if (
        !this.valuesMatch({ rec1: agg, rec2: currentRecord, valueFields })
      ) {
        recordsToUpdate++;
        currentRecord.processed = true;
        newRecordsMap[agg.id] = agg;
      } else {
        goodRecords++;
        currentRecord.processed = true;
        if (params.forceUpdate) {
          newRecordsMap[agg.id] = agg;
        }
      }
      if (currentRecord && currentRecord?.id !== agg.id) {
        deleteIds.push(currentRecord.id);
        newRecordsMap[agg.id] = agg;
        currentRecord.processed = true;
        hashUpdate++;
      }
    });
    for (const rec in currentRecordMap) {
      if (!currentRecordMap[rec].processed) {
        deleteIds.push(currentRecordMap[rec].id);
        recordsToDelete++;
      }
    }
    const newRecords = [];
    for (const rec in newRecordsMap) {
      newRecords.push(newRecordsMap[rec]);
    }
    logger.info(
      `Done: New: ${newRecordCount}, Update: ${recordsToUpdate}, good: ${goodRecords}, hash update ${hashUpdate}, delete: ${recordsToDelete}`,
    );
    if (deleteIds.length > 0 && !noDeletes) {
      await this.clientManager.pipelineManager.deleteRecords({
        entityName,
        configName,
        ids: deleteIds,
      });
    }
    if (newRecords.length > 0) {
      await this.clientManager.pipelineManager.upsertRecords({
        entityName,
        configName,
        records: newRecords,
      });
    }
  }
  private valuesMatch(params: {
    rec1: any;
    rec2: any;
    valueFields: string[];
    isMap?: boolean;
  }): boolean {
    const { rec1, rec2, valueFields } = params;
    for (const field of valueFields) {
      if (_.isPlainObject(rec1[field]) || Array.isArray(rec1[field])) {
        rec1[field] = this.stripSystemFields(rec1[field]);
        if (_.isPlainObject(rec2[field]) || Array.isArray(rec2[field])) {
          rec2[field] = this.stripSystemFields(rec2[field]);
        }
        if (!_.isEqual(rec1[field], rec2[field])) {
          return false;
        }
      } else if (
        typeof rec1[field] === 'number' ||
        typeof rec2[field] === 'number'
      ) {
        if (
          !_.isEqual(Math.round(rec1[field]), Math.round(rec2[field])) &&
          ![null, undefined].includes(rec1[field]) &&
          ![null, undefined].includes(rec2[field])
        ) {
          return false;
        }
      } else if (
        rec1[field] !== rec2[field] &&
        ![null, undefined].includes(rec1[field]) &&
        ![null, undefined].includes(rec2[field])
      ) {
        return false;
      }
    }
    return true;
  }
  private stripSystemFields(rec: any): any {
    if (Array.isArray(rec)) {
      for (let element of rec) {
        element = this.stripSystemFields(element);
      }
    }
    for (const field in rec) {
      if (field.includes('__')) {
        delete rec[field];
      }
    }
    return rec;
  }
  public buildQueryArguments(args): any[] {
    const queryArgsArray = [];
    for (const key in args) {
      if (Array.isArray(args[key])) {
        const newRecords = [];
        args[key].forEach((value, i) => {
          if (queryArgsArray.length === 0) {
            newRecords.push({ [key]: value });
          } else {
            queryArgsArray
              .filter((qArg) => !qArg[key])
              .forEach((qArg) => {
                if (i === 0) {
                  qArg[key] = value;
                } else {
                  newRecords.push({ ...qArg, [key]: value });
                }
              });
          }
        });
        newRecords.forEach((rec) => {
          queryArgsArray.push(rec);
        });
      } else {
        if (queryArgsArray.length === 0) {
          queryArgsArray.push({ [key]: args[key] });
        } else {
          queryArgsArray.forEach((qArg) => {
            qArg[key] = args[key];
          });
        }
      }
    }
    return queryArgsArray;
  }
}
