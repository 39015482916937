import { Typography } from '@mui/material';
import { Link } from 'react-router-dom';

export default function EntityCellLink(props) {
  const { column, entityName, entityId, cell, normalizedAttributes } = props;
  const { cellData } = cell;

  if (column === 'id') {
    return (
      <Typography
        component={Link}
        to={`/data/${entityName}/${entityId}`}
        style={{ textDecoration: 'none', color: 'blue' }}
      >
        {cellData}
      </Typography>
    );
  }
  if (cellData !== null && typeof cellData === 'object' && 'id' in cellData) {
    const associatedEntity =
      normalizedAttributes[column].itemInfo.associatedEntity;
    return (
      <Typography
        component={Link}
        to={`/data/${associatedEntity}/${cellData.id}`}
        style={{ textDecoration: 'none', color: 'blue' }}
      >
        {cellData.id}
      </Typography>
    );
  }
  if (typeof cellData === 'string') {
    return <Typography>{cellData}</Typography>;
  }
  return null;
}
