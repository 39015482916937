import { v1 as uuid } from 'uuid';

import { ClientManager } from 'universal/clientManager';
import { Loggers, getLogger } from 'universal/loggerSupport';
import { MetadataSupport } from 'universal/metadataSupport';
import { IEntityType } from 'universal/metadataSupportConstants';
import { stripAllButLettersAndNumbers as mangleOnlyLettersAndNumbers } from 'universal/utilityFunctions';

export interface IValidationMapParams {
  sourceEntity: string;
  sourceField?: string;
  getMatched?: boolean;
  getAliased?: boolean;
}

export interface IAliasMap {
  matched?: any;
  aliased?: any;
  unresolved: any;
}

export interface IAliasManage {
  entity: string;
  fromName: string;
  toId: string;
}

export interface IValidationConfigMap {
  mapEntity: string;
  sourceField?: string;
  codeType?: string;
}

const logger = getLogger({ name: Loggers.VALIDATION_SUPPORT });

export class ValidationSupport {
  public clientManager: ClientManager;

  public initialize(clientManager: ClientManager) {
    this.clientManager = clientManager;
  }

  public async getValidationConfig(params: IValidationMapParams): Promise<any> {
    const { sourceEntity, sourceField } = params;
    const validationList =
      await this.clientManager.pipelineManager.executeGraphqlQuery({
        query: `query { listValidationConfig(sourceEntity:"${sourceEntity}") { items { id configMappings { mapEntity {id} sourceField codeType } }} }`,
      });
    const validationConfig = validationList[0];
    if (validationConfig && sourceField) {
      validationConfig.configMappings = validationConfig.configMappings.filter(
        (cm) => cm.sourceField === sourceField,
      );
    }
    if (!validationConfig?.configMappings) {
      return undefined;
    }
    validationConfig.configMappings.forEach((config) => {
      config.mapEntity = config.mapEntity.id;
    });
    return validationConfig;
  }

  // This does not handle aliasing of nested source fields right now -- Fine for CSVs, but perhaps not for more complex structures
  public async getValidationMaps(params: IValidationMapParams): Promise<any> {
    const { sourceEntity, getMatched = false, getAliased = true } = params;

    const validationConfig = await this.getValidationConfig(params);
    // Check to see if this functionality exists
    const sourceItems = `id ${validationConfig.configMappings
      .map((c) => c.sourceField)
      .join(' ')}`;
    const sourceRecords =
      await this.clientManager.pipelineManager.executeGraphqlQuery({
        query: `query { list${sourceEntity}  { items {${sourceItems}
        } } }`,
      });
    const matched = {};
    const unresolved = {};
    const aliased = {};
    for (const mapping of validationConfig.configMappings) {
      const { mapField, mappingRecords } =
        await this.getAliasMappingRecords(mapping);
      if (!mapField) {
        throw new Error(
          `Attempting to alias an entity without an aliasMappingField specified: ${mapping.mapEntity.id}`,
        );
      }
      const { aliasRecords: aliases } = await this.getAliasMapping(
        mapping.mapEntity,
      );
      aliases.forEach((alias) => {
        alias.toName = mappingRecords.find((m) => m.id === alias.toId)[
          mapField
        ];
      });
      if (mappingRecords) {
        const mapSourceField = mapping.sourceField;
        aliased[mapSourceField] = { numFound: 0, values: {} };
        matched[mapSourceField] = { numFound: 0, values: {} };
        unresolved[mapSourceField] = { numFound: 0, values: {} };
        sourceRecords.forEach((record) => {
          const matchValue = mangleOnlyLettersAndNumbers(
            record[mapSourceField],
          );
          const originalValue = record[mapSourceField];
          if (matched[mapSourceField].values[originalValue]) {
            matched[mapSourceField].values[originalValue].numFound++;
            matched[mapSourceField].numFound++;
            return;
          } else if (aliased[mapSourceField].values[originalValue]) {
            aliased[mapSourceField].values[originalValue].numFound++;
            aliased[mapSourceField].numFound++;
            return;
          } else if (unresolved[mapSourceField].values[originalValue]) {
            unresolved[mapSourceField].values[originalValue].numFound++;
            unresolved[mapSourceField].numFound++;
          } else {
            if (
              mappingRecords.find(
                (match) =>
                  mangleOnlyLettersAndNumbers(match[mapField]) === matchValue,
              )
            ) {
              matched[mapSourceField].values[originalValue] = {
                value: originalValue,
                numFound: 1,
              };
              matched[mapSourceField].numFound++;
            } else {
              const alias = aliases.find(
                (a) => mangleOnlyLettersAndNumbers(a.fromName) === matchValue,
              );
              if (alias) {
                aliased[mapSourceField].values[originalValue] = {
                  id: alias.id,
                  value: originalValue,
                  numFound: 1,
                  toName: alias.toName,
                  toId: alias.toId,
                };
                aliased[mapSourceField].numFound++;
              } else {
                unresolved[mapSourceField].values[originalValue] = {
                  value: originalValue,
                  numFound: 1,
                };
                unresolved[mapSourceField].numFound++;
              }
            }
          }
        });
      } else {
        logger.info(`No records found for ${mapping.sourceField}`);
      }
    }
    const returnObj: IAliasMap = {
      unresolved: unresolved,
    };
    if (getMatched) {
      returnObj.matched = matched;
    }
    if (getAliased) {
      returnObj.aliased = aliased;
    }
    return returnObj;
  }

  public async getAliasMappingRecords(
    params: IValidationConfigMap,
  ): Promise<{ mappingRecords: any[]; mapField: string; recordMap: any }> {
    const { mapEntity, codeType } = params;
    const mapField = this.clientManager.schemaManager.getEntityType({
      name: mapEntity,
    }).aliasMappingField;
    const mapItems = mapField === 'id' ? 'id' : `id ${mapField}`;

    const entityName = MetadataSupport.getUnqualifiedName(mapEntity);
    let configName = '';
    if (MetadataSupport.isQualifiedName(mapEntity)) {
      configName = MetadataSupport.getConfigName(mapEntity);
    }
    const mappingRecords =
      await this.clientManager.pipelineManager.executeGraphqlQuery({
        query: `query { list${entityName} ${
          mapEntity === 'system:Code' ? `(codeType: "${codeType}")` : ''
        }
           { items {${mapItems}
        } } }`,
        configName,
      });
    const recordMap = {};
    mappingRecords.forEach((record) => {
      recordMap[mangleOnlyLettersAndNumbers(record[mapField])] = record;
    });
    return {
      mappingRecords: mappingRecords,
      mapField: mapField,
      recordMap: recordMap,
    };
  }

  public async addAlias(params: IAliasManage): Promise<any> {
    const { entity, fromName, toId } = params;
    const { aliasRecords } = await this.getAliasMapping(entity);

    const map = aliasRecords?.find((m) => m.fromName === fromName);
    if (map) {
      if (map.toId === toId) {
        logger.info('alias exists, no record created');
      } else {
        throw new Error(
          `Attempting to create an alias where an alias from: ${entity} => ${fromName} exists (${map.toId})`,
        );
      }
    } else {
      const record = {
        id: uuid(),
        entity: entity,
        toId: toId,
        fromName: fromName,
      };
      return this.clientManager.pipelineManager.createRecord({
        entityName: 'system:AliasMapping',
        record: record,
      });
    }
  }

  public async deleteAlias(id: string): Promise<void> {
    return this.clientManager.pipelineManager.deleteRecord({
      entityName: 'system:AliasMapping',
      id: id,
    });
  }

  public async getAliasMapping(
    entity: string,
  ): Promise<{ aliasRecords: any[]; aliasMap: any }> {
    const aliasMap = {};
    const aliasRecords =
      await this.clientManager.pipelineManager.executeGraphqlQuery({
        query: `query { listAliasMapping (entity:"${entity}") { items { id  toId fromName}} }`,
        noCache: true,
      });

    aliasRecords.forEach((record) => {
      aliasMap[mangleOnlyLettersAndNumbers(record.fromName)] = record;
    });
    return { aliasRecords: aliasRecords, aliasMap: aliasMap };
  }

  public async findEntityId(entity: IEntityType, fieldToMap: string) {
    const { aliasMappingField } = entity;
    const matchField = mangleOnlyLettersAndNumbers(fieldToMap);
    const entityName = MetadataSupport.getUnqualifiedName(entity.id);
    if (!aliasMappingField) {
      throw new Error(
        `Attempting to alias ${entityName} without an aliasMappingField )`,
      );
    }
    const mapItems =
      aliasMappingField === 'id' ? 'id' : `id ${aliasMappingField}`;
    const mappingRecords =
      await this.clientManager.pipelineManager.executeGraphqlQuery({
        query: `query { list${entityName} { items {${mapItems}
        } } }`,
      });
    const matchRecord = mappingRecords.find(
      (r) => mangleOnlyLettersAndNumbers(r[aliasMappingField]) === matchField,
    );
    if (matchRecord) {
      return matchRecord.id;
    } else {
      const { aliasMap } = await this.getAliasMapping(entityName);
      if (aliasMap[matchField]) {
        return aliasMap[matchField].toId;
      } else {
        return null;
      }
    }
  }
}
