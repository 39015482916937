import { isBrowser } from 'browser-or-node';
import JSZip from 'jszip';
import _ from 'lodash';

import { ClientManager } from '../clientManager';
import { CONFIGURATIONS_DIR, SYSTEM } from '../common/commonConstants';
import {
  GITHUB_REPONAME,
  IFile,
  IFileInfo,
  checkIfDirectoryExists,
  getDirContents,
  getDirContentsFullRepo,
  getFileContentsFullRepo,
  getFullRepoForBranch,
  gitHash,
} from '../common/github';
import { reThrow } from '../errors/errorLog';
import { Loggers, getLogger } from '../loggerSupport';
import { MetadataSupport } from '../metadataSupport';
import {
  APP_DEFS,
  APP_DEF_APPLICATION,
  APP_DEF_NAVIGATION,
  APP_DEF_PERMISSION,
  CONFIG_SEPARATOR,
  DATA_DEFS,
  DATA_DEF_ENTITY_TYPE,
  DATA_DEF_TYPE_DEFINITION,
  IConfigItem,
  IConfigItemsByType,
  IEntityType,
  INavigation,
} from '../metadataSupportConstants';
import { SchemaManager } from '../schemaManager';
import { StackInfoKeys } from '../stackInfo';
import { StageType } from '../types';

import path from 'path';
import type { IApplication } from '../applicationManager';
import { IDBPermission } from '../permissionManager';
import { TypeDefinition } from '../typeDefinition';
import {
  convertConfigFromYml,
  getConfigFileInfosFromGithub,
  putConfigInDatabase,
} from './load';
import { convertConfigItemToFile, getConfigFromDatabase } from './store';

const logger = getLogger({ name: Loggers.LOAD_STORE });

export const DATA_DEFS_DIR = 'dataDefs';
export const APP_DEFS_DIR = 'appDefs';

export const configTypeEntityTypes = {
  [DATA_DEFS_DIR]: DATA_DEFS,
  [APP_DEFS_DIR]: APP_DEFS,
};

// Codes have a special format for their YAML files to allow all of the codes to
// be specified in a single file
export interface ICodeTypeConfigItem {
  codeType: string;
  // The codes that belong to the code type
  codes: any[];
  [field: string]: any;
}

const EXCLUDED_CONFIG_SUB_DIRS = ['python'];

export async function getDifferences(params: {
  githubToken: string;
  path: string;
  branchName: string;
  repoName?: string;
  fullRepo: any;
  clientManager: ClientManager;
}): Promise<{
  githubOnly: IFileInfo[];
  databaseOnly: IFileInfo[];
  changed: IFileInfo[];
}> {
  const ghFileInfos: IFileInfo[] = await getConfigFileInfosFromGithub({
    ...params,
    computeHash: true,
  });

  const dbFileInfos: IFileInfo[] = await getConfigFileInfosFromDb(params);

  const githubOnly: IFileInfo[] = _.differenceBy(
    ghFileInfos,
    dbFileInfos,
    (fileInfo) => fileInfo.path,
  );

  const databaseOnly: IFileInfo[] = _.differenceBy(
    dbFileInfos,
    ghFileInfos,
    (fileInfo) => fileInfo.path,
  );

  const changed: IFileInfo[] = _.intersectionWith(
    ghFileInfos,
    dbFileInfos,
    (ghFileInfo, dbFileInfo) =>
      ghFileInfo.path === dbFileInfo.path && ghFileInfo.sha !== dbFileInfo.sha,
  );

  return {
    githubOnly,
    databaseOnly,
    changed,
  };
}

async function getConfigFileInfosFromDb(params: {
  githubToken: string;
  path: string;
  branchName: string;
  repoName?: string;
  clientManager?: ClientManager;
  schemaManager?: SchemaManager;
}): Promise<IFileInfo[]> {
  const { clientManager } = params;
  const configPath = params.path;
  logger.info(`getting config ${configPath} from database`);

  const { configName, configType } = parseConfigPath(configPath);

  const configItems: IConfigItem[] = await getConfigFromDatabase({
    configName,
    configType,
    clientManager,
  });

  const configFiles: IFile[] = configItems.map((configItem) => {
    return convertConfigItemToFile({
      configItem,
      clientManager,
    });
  });

  return configFiles.map((file) => ({
    path: file.path,
    sha: gitHash(file.contents),
  }));
}

export async function getFullConfigRepoForBranch(params: {
  githubToken: string;
  branchName: string;
  repoName?: string;
  clientManager?: ClientManager;
}): Promise<JSZip> {
  const { githubToken, branchName, clientManager } = params;

  const repoName = params.repoName || GITHUB_REPONAME;

  let fullRepo;
  if (isBrowser) {
    // GitHub does not support downloading the repo from the browser
    try {
      const response =
        await clientManager.pipelineManager.executePipelineRemote({
          stages: [
            {
              _stageType: StageType.javaScript,
              code: `output.fullRepoBytes = await clientManager.github.getPartialRepoBytes({ 
                 githubToken: '${githubToken}', 
                 repoName: '${repoName}', 
                 branchName: '${branchName}', 
                 path: '${CONFIGURATIONS_DIR}' })`,
            },
          ],
        });
      fullRepo = await JSZip.loadAsync(response.fullRepoBytes);
    } catch (error) {
      reThrow({
        logger,
        error,
        message: 'Problem getting fullRepo remotely',
      });
    }
  } else {
    fullRepo = await getFullRepoForBranch({
      repoName,
      ...params,
    });
  }
  return fullRepo;
}

// Returns the paths under the CONFIGURATIONS_DIR, the first element being the configName
export async function getConfigurationPaths(params: {
  githubToken: string;
  configNames?: Set<string>;
  branchName: string;
  fullRepo?: any;
  clientManager?: ClientManager;
}): Promise<string[]> {
  const { githubToken, configNames, branchName, clientManager } = params;

  const fullRepo = await getFullConfigRepoForBranch({
    ...params,
    clientManager,
  });

  const configPaths: string[] = [];

  const githubParams = {
    githubToken,
    branchName,
    fullRepo,
    path: CONFIGURATIONS_DIR,
  };

  const configDirs = await getDirContentsFullRepo(githubParams);

  for (const configDir of configDirs) {
    if (configDir.type === 'dir') {
      if (configNames && !configNames.has(configDir.name)) {
        continue;
      }
      const configSubDirs = await getDirContentsFullRepo({
        ...githubParams,
        path: `${CONFIGURATIONS_DIR}/${configDir.name}`,
      });

      for (const configSubDir of configSubDirs) {
        if (configSubDir.type === 'dir') {
          if (EXCLUDED_CONFIG_SUB_DIRS.includes(configSubDir.name)) {
            continue;
          }

          configPaths.push(
            `${CONFIGURATIONS_DIR}/${configDir.name}/${configSubDir.name}`,
          );
        }
      }
    }
  }

  return configPaths;
}

export function getEntityTypeForRecordType(params: {
  clientManager: ClientManager;
  // Qualified
  recordType: string;
  noThrow?: boolean;
}): IEntityType {
  const { schemaManager } = params.clientManager;
  const { recordType, noThrow } = params;

  // There must be an entityType defined for each configuration sub-directory, this can
  // either be defined in the configuration itself, or in the system config
  let entity = schemaManager.getEntityType({
    name: recordType,
    noThrow: true,
  });

  if (!entity) {
    entity = schemaManager.getEntityType({
      name: MetadataSupport.getUnqualifiedName(recordType),
      configName: SYSTEM,
      noThrow: true,
    });
  }

  if (!entity && !noThrow) {
    throw new Error(
      `EntityType definition for ${recordType} or not found (checked ${SYSTEM} too)`,
    );
  }
  return entity;
}

async function getConfigNames(params: {
  githubToken: string;
  branchName: string;
}): Promise<string[]> {
  const { githubToken, branchName } = params;

  const githubParams = {
    githubToken,
    branchName,
    path: CONFIGURATIONS_DIR,
  };

  const configDirs = await getDirContents(githubParams);

  return configDirs
    .filter((configDir) => configDir.type === 'dir')
    .map((configDir) => configDir.name);
}

export async function getEntityTypesFromGH(params: {
  githubToken: string;
  branchName: string;
  fullRepo: any;
}): Promise<IEntityType[]> {
  const allEntityFileInfos: IFileInfo[] = [];
  const configNames = await getConfigNames(params);

  for (const configName of configNames) {
    const entityTypePath = `${CONFIGURATIONS_DIR}/${configName}/${DATA_DEFS_DIR}/${DATA_DEF_ENTITY_TYPE}`;

    const directoryExists = await checkIfDirectoryExists({
      ...params,
      path: entityTypePath,
    });

    if (!directoryExists) {
      continue;
    }

    const entityFileInfos = await getDirContentsFullRepo({
      ...params,
      path: entityTypePath,
    });

    allEntityFileInfos.push(
      ...entityFileInfos
        .filter((fileInfo) => fileInfo.type === 'file')
        .map((fileInfo) => ({ ...fileInfo, configName })),
    );
  }

  const files = await Promise.all(
    allEntityFileInfos
      .filter((fileInfo) => fileInfo.type === 'file')
      .map(async (fileInfo) => {
        const fPath = fileInfo.path;

        const fileContents = getFileContentsFullRepo({
          ...params,
          path: fPath,
        });

        return fileContents.then((contents) => ({
          contents,
          path: fPath,
          configName: fileInfo.configName,
        }));
      }),
  );

  return files.map((file) => ({
    ...convertConfigFromYml(file),
    configName: file.configName,
  })) as IEntityType[];
}

export function parseConfigPath(fPath: string): {
  configName: string;
  configType: string;
  recordType?: string;
  recordName?: string;
} {
  const splitPath = fPath.split('/');

  if (splitPath[0] !== CONFIGURATIONS_DIR) {
    throw new Error(
      `Expected path ${fPath} to begin with "${CONFIGURATIONS_DIR}".`,
    );
  }

  const fileName = splitPath[4];

  const recordName = fileName ? fileName.split('.')[0] : undefined;

  return {
    configName: splitPath[1],
    configType: splitPath[2],
    recordType: splitPath[3],
    recordName,
  };
}

export function makeConfigPath({
  configName,
  recordType,
  entityName,
}: {
  configName: string;
  recordType: string;
  entityName: string;
}): string {
  const returnVal = [CONFIGURATIONS_DIR, configName];
  for (const ct in configTypeEntityTypes) {
    if (configTypeEntityTypes[ct].find((rt) => rt === recordType)) {
      returnVal.push(ct);
      break;
    }
  }
  returnVal.push(recordType);
  returnVal.push(entityName);
  return returnVal.join(path.sep);
}

export type AnyAppDef = (typeof APP_DEFS)[number];
export type AnyDataDef = (typeof DATA_DEFS)[number];
export type AnyDef = AnyAppDef | AnyDataDef;

export type AppDefTypes = {
  [APP_DEF_NAVIGATION]: INavigation;
  [APP_DEF_APPLICATION]: IApplication;
  [APP_DEF_PERMISSION]: IDBPermission;
  [key: string]: IConfigItem;
};

export type DataDefTypes = {
  [DATA_DEF_ENTITY_TYPE]: IEntityType;
  [DATA_DEF_TYPE_DEFINITION]: TypeDefinition;
};

export type ConfigItemTypes = AppDefTypes & DataDefTypes;

// This returns a single list with the configItems for all configurations with a given
// configItemType
export function getConfigItemsForType<T extends AnyDef>(
  source: IConfigItemsByType,
  type: T,
): ConfigItemTypes[T][] {
  const items: ConfigItemTypes[T][] = [];
  Object.keys(source)
    .filter((it) => it.endsWith(type))
    .forEach((it) => items.push(...(source[it] as ConfigItemTypes[T][])));
  return items;
}

// Updates the configItems with a new version of the specified configItem
function mergeConfigItems(
  baseConfigItems: IConfigItemsByType,
  configItemsToMerge: IConfigItemsByType,
  deleteItems = false,
) {
  Object.keys(configItemsToMerge).forEach((ciType) => {
    const configItemsKey = ciType.includes(CONFIG_SEPARATOR)
      ? ciType
      : `${configItemsToMerge[ciType][0].configName}${CONFIG_SEPARATOR}${ciType}`;
    const configItemsForTypeMap = {};
    baseConfigItems[configItemsKey] &&
      baseConfigItems[configItemsKey].forEach(
        (i) => (configItemsForTypeMap[i.id] = i),
      );
    configItemsToMerge[ciType].forEach((i) =>
      deleteItems
        ? delete configItemsForTypeMap[i.id]
        : (configItemsForTypeMap[i.id] = i),
    );
    baseConfigItems[configItemsKey] = [];
    Object.keys(configItemsForTypeMap).forEach((k) =>
      baseConfigItems[configItemsKey].push(configItemsForTypeMap[k]),
    );
  });
}

export async function updateConfigItemsToStackInfo(params: {
  clientManager: ClientManager;
  stackInfoKey: string;
  configItems: IConfigItemsByType;
  deleteItem?: boolean;
}) {
  const {
    clientManager,
    stackInfoKey,
    configItems,
    deleteItem = false,
  } = params;

  if (isBrowser) {
    const { stackInfo } = clientManager;
    const expectedIncarnation = stackInfo.baseStackInfo.incarnation + 1;
    await clientManager.pipelineManager.executePipelineRemote({
      stages: [
        {
          _stageType: StageType.javaScript,
          code: `
        const { stackInfo, loadStore } = clientManager;
        await loadStore.updateConfigItemsToStackInfo({clientManager, stackInfoKey: '${StackInfoKeys.APP_DEFS}', configItems: input.configItems, deleteItem: ${deleteItem}});
          `,
        },
      ],
      input: { configItems },
    });
    // There can be a delay after the remote update before we get the right version
    await stackInfo.refreshStackInfo(true, expectedIncarnation);
    return;
  }

  const { stackInfo } = clientManager;
  const appDefs = stackInfo.getObject(stackInfoKey);
  mergeConfigItems(appDefs, configItems, deleteItem);
  stackInfo.addOutputObject(stackInfoKey, appDefs);
  await stackInfo.updateStackInfo(true);
}

// Updates the configItems with a new version of the specified configItem, intended to be used
// by the widget editor in the browser
export async function updateAppDefConfigItemsRemote(
  clientManager: ClientManager,
  configItems: IConfigItemsByType,
) {
  const promises = [];
  promises.push(
    updateConfigItemsToStackInfo({
      clientManager,
      stackInfoKey: StackInfoKeys.APP_DEFS,
      configItems,
    }),
  );
  promises.push(
    putConfigInDatabase({
      clientManager,
      configItems,
    }),
  );
  await Promise.all(promises);
}

// Deletes the item, intended to be used by the widget editor in the browser
export async function deleteAppDefConfigItemsRemote(
  clientManager: ClientManager,
  configItemType: string,
  configItemName: string,
) {
  const promises = [];
  promises.push(
    updateConfigItemsToStackInfo({
      clientManager,
      stackInfoKey: StackInfoKeys.APP_DEFS,
      configItems: { [configItemType]: [{ id: configItemName }] },
      deleteItem: true,
    }),
  );
  promises.push(
    clientManager.pipelineManager.executeGraphqlMutation({
      mutation: `mutation { delete${configItemType}( input: { id: "${configItemName}" } ) }`,
    }),
  );
  await Promise.all(promises);
}
