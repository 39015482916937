import dayjs, { Dayjs } from 'dayjs';
import dayus_enau from 'dayjs/locale/en-au.js';
import customParseFormat from 'dayjs/plugin/customParseFormat.js';
import duration from 'dayjs/plugin/duration.js';
import localeData from 'dayjs/plugin/localeData.js';
import localizedFormat from 'dayjs/plugin/localizedFormat.js';
import quarterOfYear from 'dayjs/plugin/quarterOfYear.js';
import relativeTime from 'dayjs/plugin/relativeTime.js';
import utc from 'dayjs/plugin/utc.js';

dayjs.extend(duration);
dayjs.extend(relativeTime);
dayjs.extend(customParseFormat);
dayjs.extend(localizedFormat);
dayjs.extend(quarterOfYear);
dayjs.extend(utc);
dayjs.extend(localeData);

let currentDefaultLocaleToUse;
// Assign it initially to a plan dayjs so things boot correctly
let dayjsLocale: typeof dayjs = dayjs;

const importedLocales: { [locale: string]: any } = {};

// Can't use dynamic imports because esbuild currently does not support them
importedLocales['en-AU'] = dayus_enau;

// Used only by the ClientManager
export function setDayjsDefaultLocale(locale: string) {
  currentDefaultLocaleToUse = locale.toLowerCase();
  if (currentDefaultLocaleToUse === 'en-us') {
    return;
  }
  const importedLocale = importedLocales[locale];
  dayjs.locale(currentDefaultLocaleToUse);
  // @ts-ignore
  dayjsLocale = (constructorArg: any) => {
    const dateToParse = fuckDayJs(
      constructorArg,
      getDateFormatString(importedLocale),
    );
    return dayjs(
      dateToParse,
      getDateFormatString(importedLocale),
      currentDefaultLocaleToUse,
    );
  };
}

function getDateFormatString(importedLocale: any) {
  // Could be null is the locale is not found
  const LFormat = importedLocale?.formats.L || 'YYYY-MM-DD';
  // This is what the localizedFormat (dayus) plugin uses to make the lower case format values
  const lFormat = LFormat.replace(
    /(\[[^\]]+])|(MMMM|MM|DD|dddd)/g,
    (_, a, b) => a || b.slice(1),
  );
  return lFormat;
}

// See WORM-3631
function fuckDayJs(dateString: string, formatString: string): string {
  let dateToParse = dateString;
  // FIXME - for now all we care about is dates in this format (bapcor)
  if (formatString === 'D/M/YYYY' && dateToParse) {
    const parts = dateString.split('/');
    if (parseInt(parts[0], 10) > 31) {
      dateToParse = 'Invalid Date';
    }
    if (parseInt(parts[1], 10) > 12) {
      dateToParse = 'Invalid Date';
    }
  }
  return dateToParse;
}

export async function dayjsForLocale(
  constructorArg: string,
  locale: string,
): Promise<Dayjs> {
  const localeToUse = locale.toLowerCase();
  if (!importedLocales[locale]) {
    throw new Error(
      `Locale ${locale} not available - need to add an import for it`,
    );
  }

  const dateToParse = fuckDayJs(
    constructorArg,
    getDateFormatString(importedLocales[locale]),
  );

  const newDayjsDate = dayjs(
    dateToParse,
    getDateFormatString(importedLocales[locale]),
    localeToUse,
  );
  return newDayjsDate;
}

export function parseDatesUsingCustomFormats(
  dateString: string,
  formats: string[],
) {
  return dayjs(dateString, formats, true);
}

export default (...args) => dayjsLocale(...args);

export { dayjs as dayjsOriginal };
