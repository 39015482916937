import produce from 'immer';
import _ from 'lodash';
import React, { useMemo, useState } from 'react';
import isEqual from 'react-fast-compare';

import {
  ContextMap,
  IAliases,
  IData,
  SubscribePath,
  WidgetPath,
  hasAlias,
  resolvePath,
} from '../../store/context';
import { IPassThroughProps, IWidgetTree } from '../widgets/types';

export default React.memo(Widget, isEqual);

let EventBindingContainer;

// Can't import EventBindingContainer directly as that will create a cycle in the widget imports,
// so have to do it this way
export function setEventBindingContainer(ebc) {
  EventBindingContainer = ebc;
}

let nextInstanceId = 0;

// factory function for widgets
function Widget({
  component,
  passThroughProps,
  addonProps,
  index,
  properties: newProperties = {},
  aliases,
  subscribePaths,
  childAliases,
  renderer,
  intermediateNodes,
}: {
  component: IWidgetTree;
  passThroughProps: IPassThroughProps;
  addonProps?: any;
  index?: number | string;
  properties?: { [key: string]: string };
  aliases?: ContextMap;
  subscribePaths?: { [key: string]: SubscribePath };
  childAliases?: IAliases;
  renderer?: () => void;
  intermediateNodes?: WidgetPath;
}) {
  const {
    properties: originalProperties,
    events,
    name,
    actionReference,
    matchProperties: { pluralName, instanceName, form, dataBind, duplicateBy },
  } = component;

  const currentGen = useMemo(() => {
    return intermediateNodes && aliases
      ? _.flatten(intermediateNodes).reduce(
          (contextMap, nodeName) =>
            contextMap.createDescendent({
              nodeName,
              notes: { builtBy: component.type },
            }),
          aliases,
        )
      : aliases;
  }, [aliases, component.type, intermediateNodes]);

  const nextGenAliases = useMemo(() => {
    return (
      currentGen?.createDescendent({
        aliases: childAliases,
        nodeName: `#${name}`,
        subscribePaths,
        notes: { builtBy: component.type },
      }) ||
      ContextMap.create({
        aliases: childAliases,
        nodeName: `#${name}`,
        subscribePaths,
      })
    );
  }, [childAliases, component.type, currentGen, name, subscribePaths]);

  const generatedAliases = useMemo(
    () =>
      generateWidgetAliases({
        aliases: nextGenAliases,
        form,
        duplicateBy,
        pluralName,
        index,
        instanceName,
        childAliases,
        dataBind,
      }),
    [
      nextGenAliases,
      form,
      duplicateBy,
      pluralName,
      index,
      instanceName,
      childAliases,
      dataBind,
    ],
  );

  nextGenAliases.addAliases(generatedAliases);

  // existing properties take precedent, an incoming property will only be applied if the existing property is undefined
  const properties = useMemo(
    () =>
      produce(originalProperties, (draft) => {
        Object.keys(newProperties).forEach((propertyKey) => {
          if (originalProperties[propertyKey] === undefined) {
            draft[propertyKey] = newProperties[propertyKey];
          }
        });
      }),
    [newProperties, originalProperties],
  );

  if (!component) {
    throw new Error('no component provided to the BuildWidget function');
  }

  // childProps are used in render overlay to create new generations
  // appending the next generation to children of the current generation
  // assembling multiple overlays into a hierarchy
  const childProps = useMemo(() => Object.assign({}, addonProps), [addonProps]);

  const [instanceId] = useState(nextInstanceId++);

  return (
    <EventBindingContainer
      instanceId={instanceId}
      actionReference={actionReference}
      name={name}
      passThroughProps={passThroughProps}
      aliases={nextGenAliases}
      properties={properties}
      component={component}
      events={events}
      childProps={childProps}
      index={index}
      renderer={renderer}
      key={`${instanceId}ebc`}
    />
  );
}

function generateWidgetAliases({
  aliases,
  form,
  duplicateBy,
  pluralName,
  index,
  instanceName,
  childAliases,
  dataBind,
}: {
  aliases: ContextMap;
  form: string;
  duplicateBy?: string;
  pluralName?: string;
  index?: string | number;
  instanceName?: string;
  childAliases: IAliases;
  dataBind: string;
}): IAliases {
  const { widgetPath } = aliases;
  const formPath =
    hasAlias('form', aliases) && resolvePath(['form'], aliases).path;

  return {
    // every widget gets a self alias
    _self: {
      subscribePath: ([`/${widgetPath[0]}`] as WidgetPath).concat(
        widgetPath.slice(1),
        'context',
      ),
    },
    '': { subscribePath: ['_self'] },
    data: {
      subscribePath: (dataBind && _.toPath(dataBind)) || ['_self', 'data'],
    },
    // if its a form it creates a form alias pointing to self
    ...(form && { form: { subscribePath: _.toPath(form) } }),
    ...(duplicateBy &&
      pluralName &&
      formPath &&
      !(formPath as IData).data && {
        form: {
          subscribePath: (formPath as SubscribePath).concat([
            pluralName,
            index,
          ]),
        },
      }),
    // if duplicated, appends
    // index to duplicateBy to create the instance alias
    ...(duplicateBy &&
      instanceName && {
        [instanceName]: { subscribePath: [..._.toPath(duplicateBy), index] },
      }),
    ...childAliases,
  };
}
