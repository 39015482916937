import { ClientManager } from './clientManager';
import { MetadataSupport } from './metadataSupport';
import { CollectionTypes } from './metadataSupportConstants';
import { MetadataParentType, SchemaManager } from './schemaManager';
import { IItemType, TypeDefinition } from './typeDefinition';
import { isBuiltin } from './types';

export interface IVisitorFunctionParams {
  enclosingObject: any;
  enclosingTypeDef: TypeDefinition;

  // The object to process
  objectField: any;

  // Attribute for objectField, not specified if top-level object
  attr: IItemType;

  // Type name for object field, always specified
  objectFieldTypeName: string;
  objectFieldTypeDef: TypeDefinition;

  // Paths up the tree
  path: string[];

  // The attribute in the enclosing type for objectField
  parentAttribute: IItemType;

  // The last attribute in the given parentAttribute
  finalAttributeInParentAttribute: boolean;

  // array index (which can also be used to represent map elements)
  index: number;

  // key to the map element (corresponds to the index, when visiting map elements)
  indexKey: string;

  // True if this is an object described by a type def and can be processed
  // further by the visitor
  objectCanBeProcessed: boolean;

  // Root object
  rootObject: any;

  // Context specified when the visitor is called
  context: any;

  metadataSupport: MetadataSupport;
}

export interface IVisitorFunctionReturn {
  newValue?: any;

  // Stop all processing
  stop?: boolean;

  // Ignore the children (only used when processing an array element)
  ignoreChildren?: boolean;
}

export type VisitorFunction = (
  params: IVisitorFunctionParams,
) => IVisitorFunctionReturn;

export class MetadataVisitor {
  private schemaManager: SchemaManager;
  private metadataSupport: MetadataSupport;

  public initialize(clientManager: ClientManager) {
    this.schemaManager = clientManager.schemaManager;
    this.metadataSupport = clientManager.metadataSupport;
  }

  public initializeFromMetadataSupport(metadataSupport: MetadataSupport) {
    this.schemaManager = metadataSupport.schemaManager;
    this.metadataSupport = metadataSupport;
  }

  public objectCanBeProcessed(
    obj: Record<string, any>,
    attr: IItemType,
    typeDef: TypeDefinition,
  ): boolean {
    return (
      (obj &&
        typeof obj === 'object' &&
        (!attr || !attr.itemInfo.associatedEntity) &&
        this.metadataSupport.isObjectType({
          itemInfo: attr?.itemInfo,
          typeDef,
          parentType: typeDef,
        })) ||
      Array.isArray(obj)
    );
  }

  // Used to have in-place processing of an object with its type information
  public visitObjectFieldsWithTypeDef(params: {
    obj: any;
    context?: any;
    typeDefName: string;
    parentType: MetadataParentType;
    parentAttributes?: IItemType[];
    path?: string[];
    index?: number;
    depth?: number;
    rootObject?: any;
    visitorFunctions: VisitorFunction[];
  }) {
    let { obj } = params;

    const {
      context,
      typeDefName,
      parentType,
      visitorFunctions,
      parentAttributes = [],
      index,
      depth = 0,
      path = [],
      rootObject = obj,
    } = params;

    let typeDef: TypeDefinition;
    if (!isBuiltin(typeDefName)) {
      typeDef = this.schemaManager.getTypeDefinition({
        name: typeDefName,
        parentRecord: parentType,
      });
    }

    const parentAttribute = parentAttributes?.[parentAttributes.length - 1];

    const callVf = (callVfparams: {
      objectToVisit: any;
      objectCanBeProcessed: boolean;
      // undefined if this is the top-level object
      attr?: IItemType;
      index?: number;
      indexKey?: string;
      finalAttributeInParentAttribute?: boolean;
    }): {
      cumReturn: IVisitorFunctionReturn;
      objectCanBeProcessed: any;
      objectField: any;
    } => {
      const {
        attr,
        index: vfIndex,
        indexKey,
        finalAttributeInParentAttribute,
      } = callVfparams;
      let { objectToVisit, objectCanBeProcessed } = callVfparams;
      const cumReturn: IVisitorFunctionReturn = {};

      let enclosingTypeDef: TypeDefinition;
      let enclosingObject;
      let objectFieldTypeName: string;
      let objectFieldTypeDef: TypeDefinition;
      if (!attr) {
        objectFieldTypeName = typeDefName;
        objectFieldTypeDef = typeDef;
      } else {
        enclosingTypeDef = typeDef;
        enclosingObject = obj;
        objectFieldTypeName = attr.itemInfo.type;
        if (attr.itemInfo.associatedEntity) {
          const assocEntity = this.schemaManager.getEntityType({
            name: attr.itemInfo.associatedEntity,
            parentRecord: typeDef,
          });
          objectFieldTypeDef = assocEntity.typeDefinitionObject;
        } else if (!isBuiltin(objectFieldTypeName)) {
          objectFieldTypeDef = this.schemaManager.getTypeDefinition({
            name: objectFieldTypeName,
            parentRecord: typeDef,
          });
        }
      }

      visitorFunctions.forEach((v) => {
        const innerRet = v({
          enclosingObject,
          context,
          objectField: objectToVisit,
          parentAttribute,
          finalAttributeInParentAttribute,
          enclosingTypeDef,
          objectFieldTypeName,
          objectFieldTypeDef,
          attr,
          path,
          index: vfIndex,
          indexKey,
          rootObject,
          metadataSupport: this.metadataSupport,
          objectCanBeProcessed,
        });
        Object.assign(cumReturn, innerRet);
        if (innerRet?.newValue !== undefined) {
          objectToVisit = obj[attr.name] = innerRet.newValue;
          objectCanBeProcessed = this.objectCanBeProcessed(
            objectToVisit,
            attr,
            typeDef,
          );
        }
      });
      return {
        cumReturn,
        objectCanBeProcessed,
        objectField:
          cumReturn.newValue !== undefined ? objectToVisit : undefined,
      };
    };

    let collectionObj;
    let arrayCollection;
    if (Array.isArray(obj)) {
      collectionObj = obj.map((m) => ({ obj: m }));
      arrayCollection = true;
    } else if (
      index === undefined &&
      parentAttribute?.itemInfo.collectionType === CollectionTypes.MAP
    ) {
      collectionObj = Object.keys(obj).map((k) => ({ key: k, obj: obj[k] }));
      arrayCollection = false;
    }

    if (collectionObj) {
      let i = 0;
      for (const m of collectionObj) {
        let collectionMember = m.obj;
        let objectCanBeProcessed = this.objectCanBeProcessed(
          collectionMember,
          parentAttribute,
          typeDef,
        );

        // Visitor gets called on array members/map elements
        const callVfReturn = callVf({
          objectToVisit: collectionMember,
          objectCanBeProcessed,
          attr: parentAttribute,
          index: i,
          indexKey: m.key,
        });
        if (callVfReturn.objectField !== undefined) {
          collectionMember = callVfReturn.objectField;
          if (arrayCollection) {
            obj[i] = collectionMember;
          } else {
            obj[collectionMember.id] = collectionMember;
          }
        }
        if (callVfReturn.cumReturn.ignoreChildren) {
          continue;
        }
        objectCanBeProcessed = callVfReturn.objectCanBeProcessed;

        if (!callVfReturn.cumReturn.stop) {
          this.visitObjectFieldsWithTypeDef({
            obj: collectionMember,
            context,
            typeDefName,
            parentType,
            parentAttributes,
            path,
            depth: depth + 1,
            index: i,
            rootObject,
            visitorFunctions,
          });
        }
        i++;
      }
      return;
    }

    if (path.length === 0) {
      const callVfReturn = callVf({
        objectToVisit: obj,
        objectCanBeProcessed: true,
      });
      if (callVfReturn.cumReturn.stop) {
        return;
      }
      if (callVfReturn.objectField !== undefined) {
        obj = callVfReturn.objectField;
      }
    }

    if (typeDef) {
      const finalAttrName =
        typeDef.getAttributes()[typeDef.getAttributes().length - 1].name;
      for (const attr of typeDef.getAttributes()) {
        let objectField = obj ? obj[attr.name] : undefined;
        let objectCanBeProcessed = this.objectCanBeProcessed(
          objectField,
          attr,
          typeDef,
        );

        const callVfReturn = callVf({
          objectToVisit: objectField,
          objectCanBeProcessed,
          attr,
          finalAttributeInParentAttribute: finalAttrName === attr.name,
        });
        objectCanBeProcessed = callVfReturn.objectCanBeProcessed;
        if (callVfReturn.objectField !== undefined) {
          objectField = callVfReturn.objectField;
        }

        if (objectCanBeProcessed && !callVfReturn.cumReturn.stop) {
          this.visitObjectFieldsWithTypeDef({
            obj: objectField,
            context,
            typeDefName: attr.itemInfo.type,
            parentType: typeDef,
            parentAttributes: parentAttributes.concat(attr),
            path: path.concat(attr.name),
            depth: depth + 1,
            rootObject,
            visitorFunctions,
          });
        }
      }
    }
  }
}
