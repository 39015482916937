import {
  buttonProperties,
  categories,
  IWidgetProps,
  linkProperties,
} from '../types';

const menuItemPropertyInfo = {
  ...buttonProperties,
  ...linkProperties,
  closePopupOnClick: {
    default: true,
    options: [true, false],
    description: 'if true, this will close the popup this is within',
  },
  renderOwnActions: {
    default: false,
    options: [true, false],
    description:
      'By default, a widget of the popup category and all of its children will not render their own popup actions, so that if that popup closes the new one remains mounted. This overrides this behavior, allowing context to be passed as normal, but causing all of this popups actions to be closed if it closes.',
  },
};

export type MenuItemProps = IWidgetProps<typeof menuItemPropertyInfo>;

export const menuItemTypeInfo = {
  types: {
    children: true,
  },
  childTypes: {
    actions: true,
  },
  category: categories.popup,
  properties: menuItemPropertyInfo,
  events: {
    load: [],
    update: [],
  },
};
