import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import {
  BodyScrollEndEvent,
  BodyScrollEvent,
  CellClickedEvent,
  ColDef,
  ColGroupDef,
  ColumnGroupShowType,
  ExcelExportParams,
  GridApi,
  IAggFuncParams,
  ICellRendererParams,
  IDetailCellRendererParams,
  IRowNode,
  IServerSideGetRowsParams,
  KeyCreatorParams,
  ProcessCellForExportParams,
  RowDragCallbackParams,
  RowDragEvent,
  ValueFormatterParams,
} from '@ag-grid-community/core';
import { CsvExportModule } from '@ag-grid-community/csv-export';
import { AgGridReact } from '@ag-grid-community/react';
import { GridChartsModule } from '@ag-grid-enterprise/charts';
import { ClipboardModule } from '@ag-grid-enterprise/clipboard';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import { ExcelExportModule } from '@ag-grid-enterprise/excel-export';
import { FiltersToolPanelModule } from '@ag-grid-enterprise/filter-tool-panel';
import { MasterDetailModule } from '@ag-grid-enterprise/master-detail';
import { MenuModule } from '@ag-grid-enterprise/menu';
import { MultiFilterModule } from '@ag-grid-enterprise/multi-filter';
import { RangeSelectionModule } from '@ag-grid-enterprise/range-selection';
import { RichSelectModule } from '@ag-grid-enterprise/rich-select';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import { SideBarModule } from '@ag-grid-enterprise/side-bar';
import { StatusBarModule } from '@ag-grid-enterprise/status-bar';
import {
  DragStartedEvent,
  IRichCellEditorParams,
} from 'ag-grid-charts-enterprise';
import { debounce } from 'debounce';
import deepEqual from 'fast-deep-equal';
import _ from 'lodash';
import hash from 'object-hash';
import React, {
  RefObject,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { connect } from 'react-redux';

import '@ag-grid-community/styles/ag-grid.css';
import '@ag-grid-community/styles/ag-theme-balham.css';
import '@ag-grid-community/styles/agGridMaterialFont.css';
import { ClientManager } from 'universal/clientManager';
import dayjs from 'universal/common/dayjsSupport';
import { reThrow } from 'universal/errors/errorLog';
import { getLogger, Loggers, LogLevels } from 'universal/loggerSupport';
import { findConfigItem } from 'universal/metadataSupport';
import {
  CollectionTypes,
  IPresentationFormat,
  IPresentationGridFormat,
  IPresentationInfo,
} from 'universal/metadataSupportConstants';
import { IStageProperties } from 'universal/pipeline/stage';
import { IItemInfo, IItemType, TypeDefinition } from 'universal/typeDefinition';
import { BasicType, isScalar, StageType } from 'universal/types';
import { exists } from 'universal/utilityFunctions';
import { v1 as uuid } from 'uuid';
import diff from 'variable-diff';
import './styles.css';

import { persistUIState } from '../../../store/persistUIState';
import { PresentationSupport } from '../../../util/presentationSupport';
import { decorateString } from '../../../util/widgetUtilities';
import { ClientContext } from '../../../wrappers/ClientContext';
import Loading from '../../atoms/Loading';
import { IWidgetTrees, UniversalWidgetProps } from '../types';

import { getDropdownValues, NumericEditor } from './AGGridCellEditors';

import {
  excelStyleList,
  formattedCellStyle,
  getCssCellClass,
  getCssCellClassRules,
  getGroupIndex,
  groupRowRenderer,
  iconCellRenderer,
  IIconCellRendererParams,
  IWidgetCellRendererParams,
  widgetRenderer,
} from './AGGridCellRenderers';
import { getExcelFormula } from './AGGridExcel';
import { topToolBar } from './AGGridTopToolbar';
import { AGGridProps } from './AGGridTypeInfo';

export const SELECT_COLID = '_select';
const logger = getLogger({ name: Loggers.CLIENT });

export type IProps = AGGridProps & UniversalWidgetProps;

export type RowsInfo = {
  allRows: any[];
  notificationCallback;
  requestedStartRow: number;
  requestedEndRow: number;
};

interface IUiState {
  filterModel?: any;
  columnModel?: any;
  groupModel?: any;
}
/* TODO - The context in a ColDef is in newer releases of ag-grid but adding a field to ag-grid coldef works now, so we use this
to suppress the error. It should be removed when the ag-grid version is updated.
 */
declare module '@ag-grid-community/core' {
  interface ColDef {
    context?: any;
  }
}
/**
 * This structure is necessary so we have an array of group rows for each group
 * that a child widget can get in context. It is only used where we are adding
 * groups that might not exist in the row data. To the child widget the received
 * data will be indistinguishable from a grid row. When we can pass maps and
 * nested arrays in subscription paths, let's relook at this.
 */
export interface IGroupData {
  _groupInfo: {
    group: string;
    sortOrder?: number;
    noDrag?: boolean;
    hideWidget?: boolean;
    height?: number;
    className?: string;
  };
  /* Below fields is the group and its parents (if any) */
  [key: string]: any;
}

const figureOutFilterType = (attr: IItemType, paged: boolean) => {
  const filterTypes = {
    [BasicType.String]: paged ? 'agTextColumnFilter' : 'agSetColumnFilter',
    [BasicType.Boolean]: 'agSetColumnFilter',
    [BasicType.Int]: 'agNumberColumnFilter',
    [BasicType.Decimal]: 'agNumberColumnFilter',
    [BasicType.Float]: 'agNumberColumnFilter',
    [BasicType.Date]: 'agDateColumnFilter',
    [BasicType.DateTime]: null,
    [BasicType.Time]: null,
    [BasicType.Duration]: null,
    [BasicType.ID]: paged ? 'agTextColumnFilter' : 'agSetColumnFilter',
    [BasicType.JSON]: null,
    [BasicType.Object]: null,
    [BasicType.Function]: null,
  };
  if (attr.presentationInfo?.presentationFormat?.style === 'month') {
    // FIXME - for the paged case the month values should be setup in the ssrmFilter Code
    // but no time now
    return paged ? 'agTextColumnFilter' : 'agSetColumnFilter';
  }
  if (attr.itemInfo?.associatedEntity) {
    return 'agSetColumnFilter';
  }
  return (
    filterTypes[attr.itemInfo?.type] ||
    (paged ? 'agTextColumnFilter' : 'agSetColumnFilter')
  );
};

export interface IGridContext {
  typeDef: TypeDefinition;
  groupData: IGroupData[];
  scrolling: boolean;
  presentationSupport: PresentationSupport;
  props: IProps;
  uniqueId: string;
  widgetsByName: { [widgetName: string]: any };
  rowData: any[];
  rowIdToIndexMap: { [id: string]: number };
  persistGroups: any[];
  openingModal: () => void;
  closingModal: () => void;
  rowSelectionKey: string;
  detailRowGetterPipeline: IStageProperties[];
}

interface IColumnInfo {
  columnTypes: { [p: string]: ColDef };
  columnDefs: ColDef[];
  totalColumns: string[];
}

const AGGrid: React.FC<React.PropsWithChildren<IProps>> = (props) => {
  const { typeDefObject, typeDefName, component } = props;

  const { clientManager } = useContext(ClientContext);

  let typeDef: TypeDefinition;
  if (typeDefName) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    typeDef = useMemo(
      () =>
        clientManager.schemaManager.getTypeDefinition({
          name: typeDefName,
          configName: component.configName,
        }),
      [typeDefName, component, clientManager.schemaManager],
    );
  } else if (typeDefObject) {
    typeDef = typeDefObject;
  }

  typeDef = useMemo(
    () => typeDef,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [exists(typeDef), exists(typeDef) ? hash(typeDef) : 0],
  );

  // FIXME - not sure why this is sometimes not found, need to debug this. Once this is fixed
  // then no longer need to have the AGGrid/AGGridInternal split.
  if (!typeDef) {
    return null;
  }
  return <AGGridInternal {...props} typeDef={typeDef} />;
};

const AGGridInternal: React.FC<
  React.PropsWithChildren<IProps & { typeDef: TypeDefinition }>
> = (props) => {
  const {
    id,
    allowPivots,
    typeDef,
    showSidebar,
    showHeader,
    showColumnMenu,
    columnHeaderStyle,
    groupDisplayType,
    showGroupPanel,
    rows,
    rowHeight,
    topRowButtons,
    hideTopRowButtons,
    paged,
    pageForward,
    hasPageForward,
    setFilterModel,
    batchUpdateContext,
    maxRowsForAutoHeight,
    component,
    suppressPersistFilters,
    suppressContextMenu,
    groupTotalRow,
    suppressPersistGroups,
    suppressPersistColumns,
    collapseGroups,
  } = props;
  const { rowSelectionKey } = props;
  const persistKey = calcPersistKey(props);
  const persistValue: IUiState = props.persistValue;
  if (!rows) {
    console.log(props);
    throw new Error('rows property has no value');
  }
  logger.debug(rows, `agGrid enter: ${component.name}`);
  const rowsInfo = useRef<RowsInfo>({
    allRows: [],
    notificationCallback: null,
    requestedStartRow: null,
    requestedEndRow: null,
  });

  const gridRef = useRef<AgGridReact>();

  const topRowButtonsToUse: any[] = topRowButtons
    ? _.cloneDeep(topRowButtons)
    : rows.length > 20
      ? [{ name: '_excel', params: { fileName: 'Export' } }]
      : [];

  const [api, setApi] = useState<GridApi>(null);
  const [waitingForPage, setWaitingForPage] = useState(false);
  const [prevRows, setPrevRows] = useState(rows);
  // FIXME - finish this implementation, see WORM-3522
  //const [modalSaveRows, setModalSaveRows] = useState(null);
  const [columnInfo, setColumnInfo] = useState<IColumnInfo>(null);
  const [detailColumnInfo, setDetailColumnInfo] = useState<IColumnInfo>(null);
  const [groupData, setGroupData] = useState(props.groupData);
  const [groupDataHolder] = useState<{ groupData: any }>({ groupData: null });
  const [prevTypeDef, setPrevTypeDef] = useState<TypeDefinition>(null);
  const [filterColumnDefs, setFilterColumnDefs] = useState([]);
  // const [modalIsOpen, setModalIsOpen] = useState(false);

  const {
    clientManager,
    presentationSupport,
    registerWidget,
    deregisterWidget,
    findWidget,
    widgetTrees,
  } = useContext(ClientContext);
  const { widgetPool } = component;
  const hasGroups = useMemo(() => {
    return typeDef
      ?.getAttributes()
      .find(
        (attr) =>
          attr.presentationInfo?.gridFormat?.enableRowGroup ||
          attr.presentationInfo?.gridFormat?.rowGroup,
      );
  }, [typeDef]);
  const sideBar = useMemo(
    () => ({
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
          toolPanelParams: {
            suppressRowGroups: !allowPivots && !showGroupPanel,
            suppressValues: !allowPivots && !showGroupPanel,
            suppressPivotMode: !allowPivots || !hasGroups,
            suppressPivots: !allowPivots || !hasGroups,
          },
        },
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel',
          toolPanelParams: {
            suppressSyncLayoutWithGrid: true,
          },
        },
      ],
    }),
    [allowPivots, showGroupPanel, hasGroups],
  );

  const widgetsByName = widgetPool.reduce(
    (acc, widget) => Object.assign(acc, { [widget.name]: widget }),
    {},
  );

  if (groupData !== props.groupData) {
    setGroupData(props.groupData);
    groupDataHolder.groupData = groupData;
  } else if (groupData && !groupDataHolder.groupData) {
    groupDataHolder.groupData = groupData;
  }

  if (
    prevTypeDef &&
    typeDef !== prevTypeDef &&
    !typeDef.deepEqual(prevTypeDef)
  ) {
    if (logger.isLevelEnabled(LogLevels.Debug)) {
      const typeDefsToCompare = typeDef.setupForComparison(prevTypeDef);
      const diffTypeDef = diff(
        typeDefsToCompare.typeDef,
        typeDefsToCompare.otherTypeDef,
      );
      if (!diffTypeDef.changed) {
        throw new Error('This must be different');
      }
      logger.debug(diffTypeDef, 'Type def changed');
    }
    setPrevTypeDef(typeDef);
    setColumnInfo(null);
    setDetailColumnInfo(null);
  }
  if (!prevTypeDef) {
    setPrevTypeDef(typeDef);
  }

  useEffect(
    () => {
      if (!columnInfo) {
        const groupComparator = (a, b, nodeA, nodeB) => {
          const gd = groupDataHolder.groupData;
          if (
            !gd ||
            !nodeA?.group ||
            !nodeB?.group ||
            nodeA?.group !== nodeB.group
          ) {
            return 0;
          }
          const idx1 = getGroupIndex({
            node: nodeA,
            groupData: gd,
          });
          const idx2 = getGroupIndex({
            node: nodeB,
            groupData: gd,
          });
          if (idx1 === -1 || idx2 === -1) {
            return 0;
          }
          return gd[idx1]._groupInfo.sortOrder - gd[idx2]._groupInfo.sortOrder;
        };

        setupColumns({
          typeDefObject: typeDef,
          component,
          batchUpdateContext,
          showColumnMenu,
          gridRef,
          paged,
          rowsInfo,
          props,
          clientManager,
          widgetTrees,
          presentationSupport,
          widgetsByName,
          persistColumnDefs: persistValue?.columnModel,
          groupComparator,
          groupData,
          setFilterColumnDefs,
        })
          .then((result) => {
            setColumnInfo(result);
          })
          .catch((error) => {
            reThrow({
              logger,
              error,
              message: 'Problem setting up column definitions',
            });
          });
      }
    }, // eslint-disable-next-line
    [columnInfo],
  );

  useEffect(
    () => {
      if (
        typeDef.presentationInfo?.gridFormat?.useMasterDetail &&
        !detailColumnInfo
      ) {
        context.detailRowGetterPipeline =
          typeDef.presentationInfo.gridFormat.detailRowGetterPipeline || null;

        const detailAttr = typeDef.getAttribute('lines');
        if (!detailAttr) {
          throw new Error(
            'AGGrid: useMasterDetail was specified but the "lines" attribute is not present in the type definition',
          );
        }
        const detailTypeDefName = detailAttr.itemInfo?.type;

        const detailTypeDef = clientManager.schemaManager.getTypeDefinition({
          name: detailTypeDefName,
          configName: component.configName,
        });
        if (!detailTypeDef) {
          throw new Error(
            'AGGrid: useMasterDetail was specified but the "lines" attribute did not have a valid type definition name in "type"',
          );
        }
        setupColumns({
          typeDefObject: detailTypeDef,
          component,
          batchUpdateContext,
          showColumnMenu,
          gridRef,
          paged,
          rowsInfo,
          props,
          clientManager,
          widgetTrees,
          presentationSupport,
          widgetsByName,
          setFilterColumnDefs,
          isDetailGrid: true,
        })
          .then((result) => {
            setDetailColumnInfo(result);
          })
          .catch((error) => {
            reThrow({
              logger,
              error,
              message: 'Problem setting up detail column definitions',
            });
          });
      }
    }, // eslint-disable-next-line
    [detailColumnInfo],
  );

  useEffect(
    () => {
      if (!api) {
        return;
      }
      if (!props.noRegister) {
        registerWidget({
          treeName: component.treeId,
          widgetName: id,
          widgetContext: { targetApi: api },
        });
      }
      if (typeDef.presentationInfo?.itemEnableDrag) {
        const callback = (widgetContext) => {
          // @ts-ignore
          if (widgetContext.targetApi.destroyCalled || api.destroyCalled) {
            return;
          }
          const dropZoneParams = widgetContext.targetApi.getRowDropZoneParams();
          api.addRowDropZone(dropZoneParams);
          registeredTypeDefs.push({
            typeDef: widgetContext.typeDef,
            widgetName: widgetContext.widgetName,
          });
        };
        typeDef.presentationInfo?.gridFormat?.destinationWidgets?.forEach(
          (widget) => {
            findWidget({
              treeName: component.treeId,
              widgetName: widget,
              callback,
            });
          },
        );
      }
      return function cleanup() {
        if (!props.noRegister) {
          deregisterWidget({ treeName: component.treeId, widgetName: id });
        }
      };
    }, // eslint-disable-next-line
    [api],
  );
  const groupHeights = useMemo(() => {
    const heights = {};
    groupData?.forEach((group) => {
      if (!heights[group._groupInfo.group]) {
        heights[group._groupInfo.group] = group._groupInfo.height || null;
      }
    });
    return heights;
  }, [groupData]);

  const getNextPage = useCallback(() => {
    logger.info('Requesting next page');
    setWaitingForPage(true);
    pageForward()
      .catch((error) =>
        reThrow({ error, logger, message: 'Problem processing pageForward' }),
      )
      .finally(() => setWaitingForPage(false));
  }, [pageForward]);

  const getRowId = useCallback(
    (params: any) => getRowIdFromData(typeDef, params.data),
    [typeDef],
  );

  const isRowSelectable = useCallback(
    (node: IRowNode) => {
      if (node.group) {
        const attr = typeDef.getAttribute(node.field);
        return attr?.presentationInfo?.gridFormat?.groupCheckboxOn;
      }
      return true;
    },
    [typeDef],
  );

  /**
   * FIXME this is only used in specific grids (groups with placeholders, or
   * specified size) We should only use this callback in those cases
   */
  const getRowHeight = useCallback(
    (params: any) => {
      if (params.data?._isPlaceholder) {
        return 0;
      }
      if (params.node.group && groupHeights[params.node.field]) {
        return groupHeights[params.node.field];
      }
    },
    [groupHeights],
  );
  const getRowStyle = useCallback((params: any) => {
    if (params.node.rowPinned) {
      return { fontWeight: 'bold' };
    }
  }, []);

  const isGroupOpenByDefault = useCallback(
    (params: any) => {
      const { field, key, context } = params;
      const persistGroup = context.persistGroups?.find(
        (p) => p.field === field && p.key === key,
      );
      if (!persistGroup || persistGroup.expanded) {
        return !collapseGroups;
      }
      return false;
    },
    [collapseGroups],
  );

  const getColumnPersist = useCallback(
    (params: { colDef: ColGroupDef | ColDef; isChild: boolean }): any => {
      const { colDef, isChild } = params;
      if (!api) {
        return;
      }
      const columnPersist = [];
      let nextCol;
      // FIXME shouldn't have to recast here
      if ((colDef as ColGroupDef).groupId) {
        (colDef as ColGroupDef).children.forEach((child) => {
          columnPersist.push(
            getColumnPersist({ colDef: child, isChild: true }),
          );
        });
      } else {
        // @ts-ignore
        const column = api.getColumn(colDef.colId);
        nextCol = _.pick(colDef, ['colId', 'field', 'width', 'hide', 'pinned']);
        nextCol.hide = !!nextCol.hide && !column.isRowGroupActive();
        if (!isChild) {
          columnPersist.push(nextCol);
        }
      }
      return isChild ? nextCol : columnPersist;
    },
    [api],
  );

  const getPersistState = useCallback((): IUiState => {
    let columnModel = [];
    if (!api) {
      return;
    }
    if (!suppressPersistColumns) {
      const colDefs = api
        .getColumnDefs()
        .filter((cd: ColDef) => cd.colId !== SELECT_COLID);
      colDefs.forEach((colDef) => {
        columnModel = columnModel.concat(
          getColumnPersist({ colDef, isChild: false }),
        );
      });
    }
    return {
      filterModel: suppressPersistFilters ? null : api.getFilterModel(),
      columnModel: suppressPersistColumns ? null : columnModel,
      groupModel: suppressPersistGroups
        ? null
        : api.getRowGroupColumns().map((col) => col.getColId()),
    };
  }, [
    api,
    getColumnPersist,
    suppressPersistColumns,
    suppressPersistFilters,
    suppressPersistGroups,
  ]);

  const onFilterChanged = useCallback(() => {
    if (!props.suppressPersistFilters) {
      void persistUIState({ [persistKey]: getPersistState() });
    }
  }, [getPersistState, persistKey, props.suppressPersistFilters]);

  const onPersistStateChanged = useCallback(() => {
    void persistUIState({ [persistKey]: getPersistState() });
  }, [getPersistState, persistKey]);

  const onColumnVisible = useCallback(() => {
    onPersistStateChanged();
  }, [onPersistStateChanged]);

  const onColumnResized = useCallback(
    (event: any) => {
      if (!['uiColumnDragged', 'uiColumnResized'].includes(event.source)) {
        return;
      }
      if (!event.column) {
        return;
      }
      const colDef = event.column.getColDef();
      if (colDef.maxWidth) {
        delete colDef.maxWidth;
        const colDefs = api.getColumnDefs();
        const idx = colDefs.findIndex((c: ColDef) => c.colId === colDef.colId);
        colDefs[idx] = colDef;
        api.setGridOption('columnDefs', colDefs);
      }
      event.finished ? onPersistStateChanged() : null;
    },
    [api, onPersistStateChanged],
  );

  const onColumnMoved = useCallback(
    (event: any) => {
      event.finished ? onPersistStateChanged() : null;
    },
    [onPersistStateChanged],
  );

  const excelExportParams: ExcelExportParams = {
    processHeaderCallback: (params): string => {
      const { column } = params;
      const displayName = params.api.getDisplayNameForColumn(column, 'csv');
      const parent = column.getParent();
      if (parent && parent.getDisplayedChildren().length > 1) {
        return parent.getColGroupDef().headerName + ' ' + displayName;
      }
      return displayName;
    },
    processCellCallback(params: ProcessCellForExportParams): string {
      if (
        [null, undefined].includes(params.value) ||
        (params.column.getColDef().context.suppressRender && !params.node.group)
      ) {
        return null;
      }
      const attr = typeDef
        ?.getAttributes()
        .find((a) => a.name === params.column.getColId());
      if (
        attr?.presentationInfo?.gridFormat?.suppressRender &&
        !params.node.group
      ) {
        return null;
      }
      if (
        attr?.itemInfo?.collectionType === CollectionTypes.ARRAY &&
        attr?.itemInfo?.associatedEntity === 'system:Code'
      ) {
        const retValue = params.value.map((v) => v.code).join(', ');
        return retValue;
      }
      return params.value;
    },
    skipColumnGroupHeaders: true,
  };

  const hasExcelFormula = typeDef
    ?.getAttributes()
    .find((attr) => attr.presentationInfo?.gridFormat?.excelFormula);
  if (hasExcelFormula) {
    excelExportParams.autoConvertFormulas = true;
    excelExportParams.processCellCallback = (params) => {
      return getExcelFormula(params);
    };
  }

  const reRender = !deepEqual(rows, prevRows);
  if (reRender) {
    logger.debug('reRender - prev rows content changed', diff(rows, prevRows));
  }
  if (reRender && !paged) {
    logger.info('Rows input changed, discarding allRows');
    setPrevRows(rows);
  }

  const dataSource = useMemo(() => {
    const getRows = (paramsDataSource: IServerSideGetRowsParams) => {
      const { request } = paramsDataSource;
      const { startRow, endRow } = request;
      logger.info(paramsDataSource, `getRows ${startRow}/${endRow}`);

      if (!paged) {
        paramsDataSource.success({
          rowData: rowsInfo.current.allRows.slice(startRow, endRow),
          rowCount: rowsInfo.current.allRows.length,
        });
        return;
      }

      setFilterModel(request.filterModel);

      const { allRows } = rowsInfo.current;

      logger.debug('getRows - paged - requesting notification');
      if (allRows.length >= endRow + 1) {
        logger.debug(
          `getRows - notifying for ${startRow}/${endRow} (immediate)`,
        );
        paramsDataSource.success({
          rowData: allRows.slice(startRow, endRow),
        });
      } else {
        rowsInfo.current.notificationCallback = paramsDataSource.success;
        rowsInfo.current.requestedStartRow = startRow;
        rowsInfo.current.requestedEndRow = endRow;
        if (hasPageForward()) {
          if (!waitingForPage) {
            logger.debug('getRows - Calling getNextPage');
            getNextPage();
          } else {
            logger.debug('getRows - Already waiting for next page');
          }
          // Give it what we have so far
          paramsDataSource.success({
            rowData: allRows.slice(startRow, allRows.length),
          });
        } else {
          const endRowUse = Math.min(endRow, allRows.length);
          logger.debug(
            `getRows - notifying for end of data - ${startRow}/${endRowUse}`,
          );
          const rowData = allRows.slice(startRow, endRowUse);
          paramsDataSource.success({
            rowData,
            rowCount: allRows.length,
          });
        }
      }
    };

    const ds = {
      getRows: paged ? debounce(getRows, 1000) : getRows,
    };

    return ds;
  }, [getNextPage, hasPageForward, paged, setFilterModel, waitingForPage]);

  useMemo(() => {
    const { allRows } = rowsInfo.current;
    if (!allRows.find((row) => row === rows[0])) {
      const prevRowMap = {};
      allRows.forEach(
        (row) => (prevRowMap[getRowIdFromData(typeDef, row)] = row),
      );
      let foundPrev = 0;
      const rowsToAdd = rows.filter((row) => {
        const match = !!prevRowMap[getRowIdFromData(typeDef, row)];
        if (match) {
          foundPrev++;
        }
        return !match;
      });
      if (foundPrev > 0) {
        logger.warn(`Found duplicate previous rows: ${foundPrev}`);
      }
      allRows.push(...rowsToAdd);
      logger.debug(
        `Added new rows ${rowsToAdd.length} to allRows ${allRows.length}`,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rows]);

  useMemo(() => {
    const {
      requestedStartRow,
      requestedEndRow,
      allRows,
      notificationCallback,
    } = rowsInfo.current;

    if (notificationCallback) {
      if (allRows.length >= requestedEndRow) {
        logger.debug(`Notifying for ${requestedStartRow}/${requestedEndRow}`);
        notificationCallback({
          rowData: allRows.slice(requestedStartRow, requestedEndRow),
        });
        rowsInfo.current.notificationCallback = null;
      } else if (!hasPageForward()) {
        logger.debug(
          `Notifying for ${requestedStartRow}/${allRows.length} - last page`,
        );
        notificationCallback({
          rowData: allRows.slice(requestedStartRow, allRows.length),
        });
      } else {
        logger.debug(
          `Waiting for more rows allRows ${allRows.length} requested: ${requestedStartRow}/${requestedEndRow}`,
        );
        notificationCallback({
          rowData: allRows.slice(requestedStartRow, allRows.length),
        });
        if (!waitingForPage) {
          getNextPage();
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    rowsInfo.current.allRows,
    rowsInfo.current.notificationCallback,
    rowsInfo.current.requestedEndRow,
    rowsInfo.current.requestedEndRow,
    hasPageForward,
    getNextPage,
  ]);

  const rowData = useMemo(() => {
    let workingRowData = paged ? undefined : rows;
    if (groupData) {
      workingRowData = workingRowData.concat(
        handleGroups({
          rows: workingRowData,
          groupData,
          idField: getRowIdAttribute(typeDef),
        }),
      );
    }
    return workingRowData;
  }, [groupData, paged, rows, typeDef]);

  const onGridReady = useCallback(
    (params: any) => {
      if (api !== params.api) {
        setApi(params.api);
      }

      // Need to stash the GridAPI for the tests.
      const windowAny = window as any;
      if (windowAny.Cypress) {
        if (!windowAny.gridApi) {
          windowAny.gridApi = {};
        }
        windowAny.gridApi[component.name] = params.api;
      }
      if (!suppressPersistFilters && persistValue?.filterModel) {
        params.api.setFilterModel(persistValue.filterModel);
      }
      if (!suppressPersistGroups && persistValue?.groupModel) {
        /* If statement is because we used to store expand/collapse detail in groupModel */
        if (_.isString(persistValue.groupModel[0])) {
          params.api.setRowGroupColumns(persistValue?.groupModel);
        }
      }
      if (showSidebar) {
        const filtersToolPanel = params.api.getToolPanelInstance('filters');
        filtersToolPanel.setFilterLayout(filterColumnDefs);
      }
    },
    [
      api,
      persistValue,
      suppressPersistFilters,
      suppressPersistGroups,
      showSidebar,
      component.name,
      filterColumnDefs,
    ],
  );

  const onBodyScroll = useCallback(
    (event: BodyScrollEvent<any, IGridContext>) => {
      event.context.scrolling = true;
    },
    [],
  );

  const onBodyScrollEnd = useCallback(
    (event: BodyScrollEndEvent<any, IGridContext>) => {
      event.context.scrolling = false;
      const refreshCells = () => event.api.refreshCells({ force: true });
      debounce(refreshCells, 200)();
    },
    [],
  );

  const onCellEditRequest = useCallback(
    async (event: CellClickedEvent) => {
      const { colDef, value, node } = event;
      if (
        !['editableBoolean', 'dropdown', BasicType.String].includes(
          colDef.type as string,
        ) ||
        node.rowPinned
      ) {
        return;
      }
      const attr = typeDef.getAttribute(colDef.field);
      const itemInfo = attr.itemInfo;
      const presentationInfo = attr.presentationInfo;
      let newValue = value;
      if (colDef.type === BasicType.String && [undefined, ''].includes(value)) {
        newValue = null;
      }
      if (colDef.type === 'dropdown') {
        if (
          !(presentationInfo.options || presentationInfo.optionValue) &&
          (itemInfo.associatedEntity === 'system:Code' ||
            ![undefined, null].includes(presentationInfo?.options[0].code))
        ) {
          let codeList = [];
          for (const codeType of itemInfo.allowedCodeTypes) {
            codeList = codeList.concat(
              await clientManager.codeSupport.getCodesFromType(codeType),
            );
          }
          newValue = codeList.find((c) => c.code === value).id;
        } else if (
          presentationInfo?.optionLabel &&
          presentationInfo?.optionValue &&
          presentationInfo?.options.length > 0
        ) {
          newValue = presentationInfo.options.find(
            (o) => o[presentationInfo.optionLabel] === value,
          )[presentationInfo.optionValue];
        }
      }
      const updatePipeline =
        attr.presentationInfo.updatePipeline ||
        typeDef.presentationInfo.updatePipeline;

      const row = _.cloneDeep(node.data);
      _.set(row, colDef.field, newValue);
      if (updatePipeline) {
        void props.runPipelineWithWidgetContext({
          data: { row, value: newValue },
          stages: updatePipeline,
        });
      }
    },
    [clientManager.codeSupport, props, typeDef],
  );

  const onSelectionChanged = useCallback(
    async (event: any) => {
      const groupNodes = event.api.getSelectedNodes().filter((n) => n.group);
      const groups = groupNodes.map((node) => {
        return { group: node.field, value: node.key };
      });
      const rowSelectionObj = {
        rows: event.api.getSelectedRows(),
        groups,
        reset: () => event.api.deselectAll('apiSelectAll'),
        rowSelectionKey: props.rowSelectionKey,
      };
      void event.context.props.runPipelineWithWidgetContext({
        data: { rowSelectionObj },
        stages: [
          {
            _stageType: StageType.javaScript,
            code: `const {rowSelectionObj} = input.data;
            external.write(rowSelectionObj.rowSelectionKey, rowSelectionObj);`,
          },
        ],
      });
    },
    [props.rowSelectionKey],
  );
  const onColumnRowGroupChanged = useCallback(
    (p: any) => {
      if (
        !api ||
        p.context.detailRowGetterPipeline ||
        props.suppressPersistGroups
      ) {
        return;
      }
      void persistUIState({
        [persistKey]: getPersistState(),
      });
    },
    [api, getPersistState, persistKey, props.suppressPersistGroups],
  );

  const openingModal = useCallback(() => {
    // FIXME - finish this implementation, see WORM-3522
    //setModalSaveRows(rows);
    //setModalIsOpen(true);
  }, []);

  const closingModal = useCallback(() => {
    //setModalIsOpen(false);
  }, []);

  const onFirstDataRendered = useCallback(
    (params: any) => {
      if (!columnInfo) {
        return;
      }
      const context: IGridContext = params.context;
      if (
        context.props.columnSizing === 'autoSizeColumns' &&
        !persistValue?.columnModel?.find((col) => col.width)
      ) {
        params.api.autoSizeAllColumns();
      } else {
        params.api.sizeColumnsToFit();
        const cd = columnInfo.columnDefs.find((cdef) => cdef.rowGroup);
        if (cd) {
          params.api.setColumnWidths([
            {
              key: 'ag-Grid-AutoColumn',
              newWidth: cd.width,
            },
          ]);
        }
      }
    },
    [columnInfo, persistValue?.columnModel],
  );

  const registeredTypeDefs = [];

  const rowModelType = paged ? 'serverSide' : 'clientSide';

  const domLayout =
    paged || rowData.length > maxRowsForAutoHeight ? 'normal' : 'autoHeight';

  const context: IGridContext = useMemo(() => {
    const rowIdToIndexMap: { [id: string]: number } = {};
    rowData.forEach((row, i) => {
      const rowId = getRowIdFromData(typeDef, row);
      if (rowId === undefined) {
        const message =
          'Cannot get the rowId from a data record, check the rowId in presentationInfo.gridFormat';
        logger.error({ typeDef, row }, message);
        throw new Error(message);
      }
      rowIdToIndexMap[rowId] = i;
    });

    return {
      typeDef,
      groupData,
      scrolling: false,
      presentationSupport,
      uniqueId: component.treeId + id,
      widgetsByName,
      rowData,
      rowIdToIndexMap,
      persistGroups: persistValue?.groupModel || null,
      props,
      openingModal,
      closingModal,
      rowSelectionKey,
      detailRowGetterPipeline:
        typeDef.presentationInfo?.gridFormat?.detailRowGetterPipeline || null,
    };
  }, [
    component.treeId,
    groupData,
    id,
    persistValue?.groupModel,
    presentationSupport,
    props,
    rowData,
    typeDef,
    widgetsByName,
    openingModal,
    closingModal,
    rowSelectionKey,
  ]);

  const excelStyles = useMemo(() => {
    return excelStyleList;
  }, []);

  const groupRowRendererParams = useMemo(
    () => ({
      suppressCount: true,
      suppressPadding: true,
    }),
    [],
  );
  const autoGroupColumnDef = useMemo<ColDef>(() => {
    return {
      minWidth: 150,
    };
  }, []);

  const detailCellRendererParams = useMemo(() => {
    return (rp) => {
      const res = {} as IDetailCellRendererParams;

      res.getDetailRowData = async function (p) {
        if (rp.context.detailRowGetterPipeline) {
          const output = (await props.runPipelineWithWidgetContext({
            data: { _row: p.data },
            stages: rp.context.detailRowGetterPipeline,
          })) as any;
          p.successCallback(output.detailRecords);
        } else {
          p.successCallback(p.data.lines);
        }
      };
      res.detailGridOptions = {
        columnDefs: detailColumnInfo?.columnDefs,
        pagination: true,
        paginationAutoPageSize: true,
        defaultColDef: {
          flex: 1,
        },
      };
      return res;
    };
  }, [detailColumnInfo?.columnDefs, props]);

  if (!columnInfo) {
    return <Loading />;
  }

  if (columnInfo.columnDefs.find((col) => col.rowGroup) || showGroupPanel) {
    topRowButtonsToUse.unshift({ name: '_expandCollapse' });
  }
  if (api?.isAnyFilterPresent()) {
    topRowButtonsToUse.unshift({ name: '_resetFilters' });
  }
  if (hideTopRowButtons !== true) {
    topRowButtonsToUse.unshift({ name: '_resetColumns' });
  }

  const topRow =
    topRowButtonsToUse.length > 0 && hideTopRowButtons !== true
      ? topToolBar({
          topRowButtons: topRowButtonsToUse,
          api,
          props,
          groupModel: persistValue?.groupModel,
          persistKey,
          setColumnInfo,
        })
      : null;
  const outerClass = `container ${showHeader ? '' : 'hide-header'}`;

  /* If useMasterDetail is utilized setup a leading column
     just for use as the folding and unfolding mechanism.
     This needs to be the first column to work correctly.
     Additionally, this locks the column to the left so it cannot be moved,
     and unhides the column in the event someone accidentally hid from view.
  */
  if (
    columnInfo.columnDefs &&
    typeDef.presentationInfo?.gridFormat?.useMasterDetail
  ) {
    const c = columnInfo.columnDefs.find((cd) => cd.field === 'masterColumn');
    if (!c) {
      throw new Error(
        `AGGrid  useMasterDetail toggled on, but no typeDef with displayName: masterDetail found.
         Please check your typeDef and ensure it has the following attribute  
         { name: 'masterColumn', nameInfo: { displayName: 'masterColumn' }, itemInfo: { type: 'String', presentationInfo: {order: 0 }} }
        `,
      );
    }
    c.cellRenderer = 'agGroupCellRenderer';
    c.hide = false;
    c.filter = null;
    c.lockPosition = 'left';
    c.headerClass = 'hide-header-text';
    c.maxWidth = 50;
  }
  const mainClassName = `ag-theme-balham ${
    columnHeaderStyle === 'dark' ? 'dark-header' : ''
  }`;

  logger.debug('agGrid render');
  return (
    <div style={{ height: '100%', width: '100%' }}>
      <div className={outerClass}>
        {props.title && <div className="gridTitle"> {props.title} </div>}
        {props.subtitle && (
          <div className="gridSubtitle"> {props.subtitle} </div>
        )}
        {props.title && <br />}
        {topRow}
        {/* On div wrapping Grid a: specify theme CSS Class and b: sets Grid size */}
        <div
          {...props.elementAttributes}
          className={mainClassName}
          style={{ height: '100%', width: '100%' }}
        >
          {' '}
          <AgGridReact
            ref={gridRef}
            masterDetail={typeDef.presentationInfo?.gridFormat?.useMasterDetail}
            detailCellRendererParams={detailCellRendererParams}
            context={context}
            onGridReady={onGridReady}
            onFilterChanged={onFilterChanged}
            onBodyScroll={onBodyScroll}
            onBodyScrollEnd={onBodyScrollEnd}
            onCellEditRequest={onCellEditRequest}
            onColumnVisible={onColumnVisible}
            onColumnPinned={onPersistStateChanged}
            onColumnRowGroupChanged={onColumnRowGroupChanged}
            onColumnResized={onColumnResized}
            onSelectionChanged={rowSelectionKey ? onSelectionChanged : null}
            onColumnMoved={onColumnMoved}
            onFirstDataRendered={onFirstDataRendered}
            // onCellEditingStopped={onCellEditingStopped}
            onRowDragEnd={
              typeDef.presentationInfo?.gridFormat?.allowRowPaste
                ? onRowDragEnd
                : null
            }
            onDragStarted={onDragStart}
            onDragStopped={onDragEnd}
            columnDefs={columnInfo.columnDefs}
            columnTypes={columnInfo.columnTypes}
            domLayout={domLayout}
            excelStyles={excelStyles}
            defaultExcelExportParams={excelExportParams}
            enableCellTextSelection={true}
            enableCharts={true}
            enableRangeSelection={true}
            grandTotalRow={
              typeDef.presentationInfo?.gridFormat?.totalFooter
                ? 'bottom'
                : null
            }
            rowModelType={rowModelType}
            rowData={rowData}
            rowHeight={rowHeight}
            headerHeight={props.headerHeight || null}
            rowSelection={
              typeDef.presentationInfo?.itemEnableCheckboxSelect
                ? 'multiple'
                : undefined
            }
            rowDragMultiRow={
              !!typeDef.presentationInfo?.itemEnableCheckboxSelect
            }
            getRowId={getRowId}
            getRowHeight={getRowHeight}
            getRowStyle={getRowStyle}
            groupDisplayType={groupDisplayType}
            rowGroupPanelShow={showGroupPanel ? 'always' : null}
            isGroupOpenByDefault={isGroupOpenByDefault}
            isRowSelectable={isRowSelectable}
            groupAllowUnbalanced={true}
            autoGroupColumnDef={autoGroupColumnDef}
            groupRowRendererParams={groupRowRendererParams}
            groupRowRenderer={groupRowRenderer}
            groupTotalRow={groupTotalRow}
            alwaysAggregateAtRootLevel={true}
            modules={[
              ClientSideRowModelModule,
              ServerSideRowModelModule,
              ColumnsToolPanelModule,
              ClipboardModule,
              CsvExportModule,
              ExcelExportModule,
              FiltersToolPanelModule,
              GridChartsModule,
              MasterDetailModule,
              MenuModule,
              MultiFilterModule,
              RangeSelectionModule,
              RichSelectModule,
              RowGroupingModule,
              SetFilterModule,
              SideBarModule,
              StatusBarModule,
            ]}
            singleClickEdit={true}
            readOnlyEdit={true}
            rowDragManaged={false}
            serverSideDatasource={
              rowModelType === 'serverSide' ? dataSource : undefined
            }
            skipHeaderOnAutoSize={true}
            suppressContextMenu={suppressContextMenu}
            suppressRowClickSelection={true}
            suppressScrollOnNewData={true}
            suppressAggFuncInHeader={true}
            suppressDragLeaveHidesColumns={true}
            sideBar={showSidebar ? sideBar : null}
          />
        </div>
      </div>
    </div>
  );
};

const onDragStart = (ev: any) => {
  const event = ev as DragStartedEvent;

  // see WORM-3897 and WORM-4008
  if (
    event.target.classList.contains('ag-drag-handle') ||
    event.target.classList.contains('.ag-icon-grip')
  ) {
    document.body.classList.add('disable-user-select');
  }
};

export const onDragEnd = () => {
  document.body.classList.remove('disable-user-select');
};

export const getRowIdAttribute = (typeDefObject: TypeDefinition): string =>
  typeDefObject.presentationInfo?.gridFormat?.rowId || 'id';

export const getRowIdFromData = (
  typeDefObject: TypeDefinition,
  data: any,
): string => _.get(data, getRowIdAttribute(typeDefObject));

const createGridFormatColumnDefs = async (
  params,
  attr: IItemType,
  gridFormat: IPresentationGridFormat,
  presentationInfo: IPresentationInfo,
  presentationSupport: PresentationSupport,
): Promise<ColDef | ColDef[]> => {
  if (!attr.itemInfo.attributes) {
    throw new Error(
      'Using PresentationInfo.columns requires inline nested attributes (at this time)',
    );
  }
  if (!gridFormat.columnId || !gridFormat.columnArrayFieldName) {
    throw new Error(
      'Using PresentationInfo.columns requires columnId and columnArrayFieldName to be specified',
    );
  }
  const colDefs = [];
  for (const member of gridFormat.columns) {
    const columnId = _.get(member, gridFormat.columnId);
    const headerName =
      presentationInfo.labelCode || presentationInfo.label
        ? presentationSupport.getLabelFromRecord({
            record: member,
            presentationInfo,
          })
        : columnId;
    const children = [];
    const childAttributes = attr.itemInfo.attributes;
    if (childAttributes) {
      for (const childAttr of childAttributes) {
        children.push({
          ...(await createColumnDefFromAttribute({
            ...params,
            attr: childAttr,
            fieldPrefix: `${gridFormat.columnArrayFieldName}.${_.get(
              member,
              gridFormat.columnId,
            )}`,
          })),
          ...(childAttributes.length === 1 && {
            headerName,
            headerTooltip: headerName,
            // The headerClass is set to hide the header if the headerName was not computed in createColumnDefFromAttribute
            headerClass: undefined,
          }),
        });
      }
    }
    if (!children.find((c) => c.columnGroupShow !== 'open')) {
      children[0].columnGroupShow = null;
    }
    if (children.length === 1) {
      // In the case of a single nested attribute, then don't do the nesting, and just use the
      // headerName for the column name (see above)
      colDefs.push(children[0]);
    } else {
      colDefs.push({
        headerName,
        headerTooltip: headerName,
        colId: columnId,
        marryChildren: true,
        openByDefault: true,
        children,
      });
    }
  }
  return colDefs;
};

const createArrayColumnDefs = async (
  params,
  attr: IItemType,
): Promise<ColDef | ColDef[]> => {
  const colDefs = [];
  const children = [];
  if (attr.itemInfo.attributes) {
    for (const childAttr of attr.itemInfo.attributes) {
      children.push(
        await createColumnDefFromAttribute({
          ...params,
          attr: childAttr,
          fieldPrefix: `${attr.name}`,
        }),
      );
    }
  }
  colDefs.push({
    headerName: attr.nameInfo.displayName,
    colId: attr.name,
    children,
  });
  return colDefs;
};
const createColumnDefFromAttribute = async (params: {
  typeDefObject: TypeDefinition;
  attr: IItemType;
  fieldPrefix?: string;
  component;
  batchUpdateContext;
  showColumnMenu: boolean;
  paged: boolean;
  gridRef: RefObject<AgGridReact>;
  rowsInfo: RefObject<RowsInfo>;
  props: IProps;
  widgetsByName: { [widgetName: string]: any };
  clientManager: ClientManager;
  widgetTrees: IWidgetTrees;
  presentationSupport: PresentationSupport;
  persistColumnDefs?: ColDef[];
  groupComparator?;
  groupData?: IGroupData[];
  verticalGridLines?: boolean;
}): Promise<ColDef | ColDef[]> => {
  const {
    attr,
    typeDefObject,
    fieldPrefix,
    component,
    showColumnMenu,
    paged,
    props,
    widgetsByName,
    clientManager,
    widgetTrees,
    presentationSupport,
    persistColumnDefs,
    groupComparator,
    groupData,
  } = params;
  logger.debug(`Creating column: ${attr.name}`);
  const { presentationInfo } = attr;
  const gridFormat =
    presentationInfo?.gridFormat || ({} as IPresentationGridFormat);

  // Column for each member of the array, nested under this attribute
  if (gridFormat.columns) {
    return createGridFormatColumnDefs(
      params,
      attr,
      gridFormat,
      presentationInfo,
      presentationSupport,
    );
  } else if (
    attr.itemInfo?.collectionType === CollectionTypes.ARRAY &&
    attr.itemInfo?.type !== BasicType.String &&
    attr.itemInfo?.attributes?.length > 0 &&
    !presentationInfo?.presentationFormat?.widgetTreeName &&
    !presentationInfo.gridFormat.widgetName
  ) {
    return createArrayColumnDefs(params, attr);
  }

  let valueFormatter;
  let ssrmFilterParams;
  let filterTypeOverride;
  const presentationFormat = {
    ...typeDefObject.presentationInfo?.presentationFormat,
    ...attr.presentationInfo?.presentationFormat,
  };
  let fieldName = attr.presentationInfo?.attributeNameOverride || attr.name;

  if (attr.itemInfo?.associatedEntity === 'system:Code') {
    fieldName =
      attr.itemInfo?.collectionType === CollectionTypes.ARRAY
        ? attr.name
        : `${attr.name}.code`;
  } else if (attr.itemInfo?.associatedEntity) {
    const nameParts = attr.name.split('.');
    let namePartIndex = 0;
    let leafAttr: IItemType = attr;
    let aeTypeDef: TypeDefinition;
    let foundDirectReferenceToAssocEntity;

    // Go through the associated entity(s) to match the name
    while (true) {
      const aeEntity = clientManager.schemaManager.getEntityType({
        name: leafAttr.itemInfo.associatedEntity,
        configName: typeDefObject.configName,
      });
      aeTypeDef = clientManager.schemaManager.getTypeDefinition({
        name: aeEntity.typeDefinition,
        parentRecord: aeEntity,
      });

      if (nameParts.length - 1 === namePartIndex) {
        foundDirectReferenceToAssocEntity = true;
        if (paged) {
          if (aeEntity.loadIntoMemory) {
            ssrmFilterParams = await getServerSideFilterParams({
              attr: leafAttr,
              clientManager,
              label: aeTypeDef.presentationInfo?.label || 'id',
              configName: typeDefObject.configName,
            });
          } else {
            filterTypeOverride = 'agTextColumnFilter';
          }
        }
        break;
      }
      namePartIndex++;
      leafAttr = aeTypeDef.getAttribute(nameParts[namePartIndex]);
      if (!leafAttr) {
        throw new Error(
          `Attribute name ${nameParts[namePartIndex]} not found in ${aeTypeDef.id} when processing ${attr.name} in ${typeDefObject.id}`,
        );
      }
      if (!leafAttr.itemInfo.associatedEntity) {
        break;
      }
    }

    if (foundDirectReferenceToAssocEntity) {
      // Reference to only the associated entity without referencing a field in the entity
      if (!aeTypeDef.presentationInfo?.label) {
        fieldName = `${attr.name}.id`;
      } else {
        fieldName = `${attr.name}.${aeTypeDef.presentationInfo.label}`;
      }
    } else if (
      !leafAttr.itemInfo.associatedEntity &&
      nameParts.length - 1 === namePartIndex
    ) {
      // Normal reference to a field in some associated entity
      fieldName = attr.name;
    } else {
      throw new Error(
        `Attribute name ${attr.name} cannot be resolved through the associated entity chain in ${typeDefObject.id}`,
      );
    }
  }

  const hide = !!(presentationInfo?.presentationVisibility === 'allowed');

  const minWidth = attr.presentationInfo?.minWidth
    ? attr.presentationInfo.minWidth
    : gridFormat.hideHeader || !attr.nameInfo?.displayName
      ? 50
      : showColumnMenu
        ? 80
        : 70;

  const field = fieldPrefix ? `${fieldPrefix}.${fieldName}` : fieldName;
  let colId = field;
  if (
    attr.itemInfo?.associatedEntity &&
    attr.itemInfo.associatedEntity !== 'system:Code'
  ) {
    colId = field.split('.').slice(0, -1).join('.') + '.id';
  }
  const colDefParams: ColDef = {
    colId,
    sortable: !!props.showHeader && !paged,
    wrapHeaderText: true,
    autoHeaderHeight: !!props.showHeader && !props.headerHeight,
    resizable: !attr.presentationInfo?.fixedWidth,
    suppressSizeToFit:
      !!attr.presentationInfo?.fixedWidth ||
      !!attr.presentationInfo?.defaultWidth,
    rowGroupIndex: gridFormat?.rowGroupIndex,
    hide,
    width:
      attr.presentationInfo?.fixedWidth || attr.presentationInfo?.defaultWidth,
    enableRowGroup: !!gridFormat.enableRowGroup && !paged,
    // TODO we can be smarter about minWidth vis-a-vis header length and field length
    minWidth: attr.presentationInfo?.fixedWidth || minWidth,
    pinned: gridFormat.pinned || false,
    field,
    headerName: attr.nameInfo?.displayName,
    editable: (p) =>
      p.node.rowPinned ? false : attr.presentationInfo?.editable,
    filter: filterTypeOverride || figureOutFilterType(attr, paged),
    rowGroup: !!gridFormat.rowGroup && !paged,
    rowDrag: attr.presentationInfo?.itemEnableDrag ? allowDragHandle : null,
    autoHeight: !!gridFormat.autoHeight,
    wrapText: !!gridFormat.wrapText,
    context: { suppressRender: !!gridFormat.suppressRender },
    headerTooltip:
      attr.nameInfo?.shortDescription || attr.nameInfo?.displayName || null,
    columnGroupShow: [null, 'open', 'closed'].includes(
      gridFormat.columnGroupShow,
    )
      ? (gridFormat.columnGroupShow as ColumnGroupShowType)
      : 'open',
    valueFormatter,
  };
  if (
    gridFormat.useFormatting ||
    gridFormat.formatString ||
    gridFormat.formatStringCode ||
    attr.presentationInfo?.editable
  ) {
    colDefParams.cellStyle = formattedCellStyle(attr.presentationInfo);
  }
  colDefParams.cellClass = getCssCellClass(attr, props.verticalGridLines);
  if (gridFormat.formatStandardStyling) {
    // @ts-ignore
    colDefParams.cellClassRules = getCssCellClassRules(attr.presentationInfo);
  }

  if (paged && ssrmFilterParams) {
    colDefParams.filterParams = ssrmFilterParams;
  }
  if (!showColumnMenu) {
    colDefParams.menuTabs = [];
  }
  if (Number.isInteger(attr.presentationInfo?.maxWidth)) {
    colDefParams.maxWidth = attr.presentationInfo?.maxWidth;
  } else if (gridFormat.hideHeader && !attr.presentationInfo?.fixedWidth) {
    colDefParams.width = 50;
    colDefParams.maxWidth = 50;
  }
  if (attr.itemInfo?.type === BasicType.String) {
    colDefParams.tooltipField = colDefParams.field;
    colDefParams.type = BasicType.String;
  }
  if (colDefParams.rowGroup) {
    colDefParams.hide =
      attr.presentationInfo?.presentationVisibility !== 'show';
    colDefParams.rowDrag = false; // we have our own row dragger for groups
    if (groupData?.find((g) => g._groupInfo.group === colDefParams.field)) {
      colDefParams.sort = 'asc';
      colDefParams.comparator = groupComparator;
    }
  }
  if (!colDefParams.headerName) {
    colDefParams.headerClass = ['hide-header-text'];
  }
  if (gridFormat.totalFooterCode) {
    colDefParams.aggFunc = customAggFunc(gridFormat.totalFooterCode);
  }

  const notation =
    attr.presentationInfo?.presentationFormat?.notation ||
    typeDefObject.presentationInfo?.presentationFormat?.notation;
  const presentationStyle =
    attr.presentationInfo?.presentationFormat?.style ||
    typeDefObject.presentationInfo?.presentationFormat?.style;
  const displayOnFooter = () => {
    return (
      !['standard'].includes(notation) &&
      !['year', 'month'].includes(presentationStyle)
    );
  };
  const updatePipeline =
    attr.presentationInfo?.updatePipeline ||
    typeDefObject.presentationInfo?.updatePipeline;
  if (presentationStyle === 'ratio') {
    colDefParams.valueGetter = ratioValueGetter(
      attr.presentationInfo.presentationFormat,
      attr.itemInfo,
    );
    colDefParams.comparator = numericStringComparator;
  }
  if (presentationStyle === 'margin') {
    colDefParams.valueGetter = marginValueGetter(
      attr.presentationInfo.presentationFormat,
      attr.itemInfo,
    );
    colDefParams.comparator = numericStringComparator;
  }
  if (
    attr.itemInfo?.collectionType === CollectionTypes.ARRAY &&
    attr.itemInfo?.type === BasicType.String
  ) {
    colDefParams.valueGetter = (p) => {
      return Array.isArray(p.data[p.colDef.field])
        ? p.data[p.colDef.field]?.join(', ')
        : p.data[p.colDef.field];
    };
  }
  if (
    attr.itemInfo?.collectionType === CollectionTypes.ARRAY &&
    attr.itemInfo?.associatedEntity === 'system:Code'
  ) {
    colDefParams.filter = 'agSetColumnFilter';
    colDefParams.keyCreator = (p: KeyCreatorParams) => p.value?.code;
    colDefParams.filterParams = {
      valueFormatter: (p: ValueFormatterParams) =>
        p.value ? p.value.code : '(Blanks)',
      comparator: (a, b) => {
        return a?.code > b?.code ? 1 : -1;
      },
    };
  }
  if (attr.presentationInfo?.fieldCode) {
    const fieldCode = attr.presentationInfo.fieldCode;
    colDefParams.filter = 'agSetColumnFilter';
    colDefParams.keyCreator = (p: KeyCreatorParams) => {
      if ([null, undefined].includes(p.value)) {
        return null;
      }
      const context = {};
      context[p.column.getColId()] = { ...p.value };
      const value = presentationSupport.runJsCode({
        context,
        code: `${fieldCode}`,
      });
      return value;
    };
  }
  if (gridFormat.suppressRender) {
    colDefParams.cellRenderer = (p) => {
      return p.node.group ? p.valueFormatted : null;
    };
  } else if (attr.presentationInfo?.icon) {
    const cellRendererParams: IIconCellRendererParams = {
      attr,
      configName: typeDefObject.configName,
    };
    colDefParams.cellRendererParams = cellRendererParams;
    colDefParams.cellRenderer = iconCellRenderer;
  }
  if (attr.presentationInfo?.fieldCode) {
    colDefParams.valueFormatter = fieldCodeValueFormatter(
      attr.presentationInfo?.fieldCode,
      presentationSupport,
    );
  } else if (attr.presentationInfo?.renderReactElements) {
    colDefParams.cellRenderer = (crparams: ICellRendererParams) => {
      return attr.presentationInfo.renderReactElements(
        attr,
        crparams.data,
        React,
      );
    };
  } else if (
    [BasicType.Float, BasicType.Int, BasicType.Decimal].includes(
      attr.itemInfo?.type as BasicType,
    )
  ) {
    if (!displayOnFooter()) {
      colDefParams.enableValue = false;
      colDefParams.aggFunc = () => '';
    }
    if (
      displayOnFooter() &&
      gridFormat.totalFooter !== null &&
      !gridFormat.totalFooterCode
    ) {
      colDefParams.enableValue = true;
      if (presentationStyle === 'ratio') {
        colDefParams.aggFunc = ratioAggFunc(
          attr.presentationInfo.presentationFormat,
          attr.itemInfo,
        );
      }
      if (presentationStyle === 'margin') {
        colDefParams.aggFunc = marginAggFunc(
          attr.presentationInfo.presentationFormat,
          attr.itemInfo,
        );
      }
      colDefParams.aggFunc = colDefParams.aggFunc || 'sum';
    }
    colDefParams.type = 'numericColumn';
    colDefParams.valueFormatter = numericValueFormatter(
      presentationFormat,
      attr.itemInfo,
    );
    colDefParams.headerClass = 'ag-numeric-header';
  } else if (attr.itemInfo?.type === BasicType.Boolean) {
    colDefParams.cellDataType = 'boolean';
    colDefParams.valueGetter = booleanValueGetter;
    colDefParams.cellClass = attr.presentationInfo?.editable
      ? 'editable-boolean'
      : 'boolean-cell';
  } else if (attr.itemInfo?.type === BasicType.Date) {
    colDefParams.valueFormatter = dateValueFormatter;
  } else if (attr.itemInfo?.type === BasicType.DateTime) {
    colDefParams.valueFormatter = dateTimeValueFormatter;
  }

  let widget;
  const widgetName = gridFormat.widgetName;
  if (widgetName) {
    widget = widgetsByName[widgetName];
    if (!widget) {
      throw new Error(
        `Widget ${widgetName} not found for attribute: ${attr.name}`,
      );
    }
  }
  const widgetTreeName =
    attr.presentationInfo?.presentationFormat?.widgetTreeName;
  if (widgetTreeName) {
    const childTree = findConfigItem(
      widgetTrees,
      widgetTreeName,
      component.configName,
    );
    widget = childTree?.widgets?.[0];
  }
  if (widget) {
    const cellRendererParams: IWidgetCellRendererParams = {
      props,
      widget,
      rowAlias: gridFormat.rowAlias,
      attr,
    };
    colDefParams.cellRendererParams = cellRendererParams;
    colDefParams.type = 'widget';
    colDefParams.cellRenderer = widgetRenderer;
  }

  if (attr.presentationInfo?.editable) {
    if (attr.itemInfo?.type === 'ID' || presentationInfo?.options) {
      colDefParams.cellEditor = 'agRichSelectCellEditor';
      const cellEditorParams: IRichCellEditorParams = {
        values: null,
        searchType: 'matchAny',
        allowTyping: true,
        filterList: true,
        highlightMatch: true,
        valueListMaxHeight: 220,
      };
      if (!presentationInfo?.options) {
        cellEditorParams.values = await getDropdownValues({
          itemInfo: attr.itemInfo,
          clientManager,
        });
      } else {
        cellEditorParams.values = presentationInfo.options.map(
          (o) => o[presentationInfo.optionLabel],
        );
      }
      colDefParams.cellEditorParams = cellEditorParams;
      colDefParams.type = 'dropdown';
    } else if (BasicType.String === (attr.itemInfo?.type as BasicType)) {
      colDefParams.cellEditor = 'agTextCellEditor';
    } else if (BasicType.Boolean === (attr.itemInfo?.type as BasicType)) {
      colDefParams.cellEditor = 'agCheckboxCellEditor';
      colDefParams.type = 'editableBoolean';
    } else if (
      [BasicType.Float, BasicType.Int, BasicType.Decimal].includes(
        attr.itemInfo?.type as BasicType,
      )
    ) {
      colDefParams.cellEditorParams = {
        ...params,
        presentationFormat,
        itemInfo: attr.itemInfo,
        updatePipeline,
        rowsPropName: component.properties.rows as string,
      };
      colDefParams.cellEditor = NumericEditor;
      colDefParams.cellStyle = formattedCellStyle(attr.presentationInfo);
    } else {
      throw new Error(
        `editable field of type ${attr.itemInfo.type} not supported`,
      );
    }
  } else if (BasicType.Boolean === (attr.itemInfo?.type as BasicType)) {
    colDefParams.cellStyle = formattedCellStyle(attr.presentationInfo);
  }
  const persistentColDef =
    persistColumnDefs?.find((p) => p?.field === colDefParams.field) || {};
  /**
   * FIXME: This was an attempt to avoid too-wide columns but it ended up not
   * allowing users to widen columns once they were in the ui-state We use
   * maxWidth as a way of bounding a column so it doesn't start off too wide. If
   * a user overrides that, we let them and change our maxWidth
   */
  /* if (
    !colDefParams.maxWidth ||
    colDefParams.maxWidth < persistentColDef.width
  ) {
    colDefParams.maxWidth = persistentColDef.width;
  } */
  if (persistentColDef.width) {
    colDefParams.suppressSizeToFit = true;
    colDefParams.width = persistentColDef.width;
  }
  colDefParams.hide = exists(persistentColDef.hide)
    ? persistentColDef.hide
    : colDefParams.hide;
  if (colDefParams.width < 60) {
    colDefParams.suppressHeaderMenuButton = true;
  }

  // If column is detail grid content, hide from view as the data will be present as a detail grid child of the master grid
  if (
    attr?.itemInfo?.typeDefinitionObject?.presentationInfo?.gridFormat
      ?.isDetailGridContent
  ) {
    colDefParams.hide = true;
  }
  return colDefParams;
};

const fieldCodeValueFormatter = (
  fieldCode: string,
  presentationSupport: PresentationSupport,
) => {
  return (params) => {
    if ([null, undefined].includes(params.value)) {
      return null;
    }
    const context = params.data || {
      [params.column.getColId()]: { ...params.value },
    };
    return presentationSupport.runJsCode({
      context,
      code: `${fieldCode}`,
    });
  };
};
/* const dropdownValueFormatter = (params) => {
  return params.node.rowPinned
    ? null
    : params.value === undefined
      ? 'Click to Add'
      : `${params.value} ${String.fromCharCode(9660)}`;
}; */

const booleanValueGetter = (p) => {
  const value = _.get(p.data, p.colDef.field);
  return !!value;
};
const dateValueFormatter = (params) => {
  const { value } = params;
  if (!value || !dayjs(value).isValid()) {
    return '';
  }
  return dayjs(value).format('DD MMM YY');
};
const dateTimeValueFormatter = (params) => {
  const { value } = params;
  if (!value || !dayjs(value).isValid()) {
    return '';
  }
  return dayjs(value).local().format('DD MMM YY hh:mm:ssA');
};
const numericValueFormatter = (
  presentationFormat: IPresentationFormat,
  itemInfo: IItemInfo,
) => {
  return (params) => {
    if (itemInfo.type === 'String' || !isScalar(itemInfo.type)) {
      return params.value || null;
    }
    let valueToUse = params.value;
    let presentationFormatToUse = presentationFormat;
    let itemInfoToUse = itemInfo;
    if (typeof params.value === 'object' && valueToUse !== null) {
      valueToUse = params.value.value;
      presentationFormatToUse = params.value.presentationFormat;
      itemInfoToUse = params.value.itemInfo;
    }
    return presentationFormatToUse
      ? `${decorateString({
          input: valueToUse,
          presentationFormat: presentationFormatToUse,
          itemInfo: itemInfoToUse,
        })}`
      : [null, undefined].includes(params.value)
        ? ''
        : `${params.value}`;
  };
};
const marginValueGetter = (
  presentationFormat: IPresentationFormat,
  itemInfo,
) => {
  return (params) => {
    if (!params.node.group) {
      const cost = params.data[presentationFormat.margin.cost];
      const revenue = params.data[presentationFormat.margin.revenue];
      const costQuantity = params.data[presentationFormat.margin.costQuantity];
      const revenueQuantity =
        params.data[presentationFormat.margin.revenueQuantity];
      let margin;
      if (!cost || !revenue || !costQuantity || !revenueQuantity) {
        margin = 0;
      } else {
        margin =
          (revenue / revenueQuantity - cost / costQuantity) /
            (revenue / revenueQuantity) || 0;
      }
      const retString = `${decorateString({
        input: margin,
        presentationFormat,
        itemInfo,
      })}`;
      return {
        cost,
        revenue,
        costQuantity,
        revenueQuantity,
        toString: () => retString,
      };
    }
  };
};

const numericStringComparator = (valueA, valueB) => {
  return parseFloat(valueA) > parseFloat(valueB) ? 1 : -1;
};
const marginAggFunc = (presentationFormat: IPresentationFormat, itemInfo) => {
  return (params) => {
    let costSum = 0;
    let revenueSum = 0;
    let costQuantitySum = 0;
    let revenueQuantitySum = 0;
    params.values
      .filter(
        (v) => v && v.cost && v.costQuantity && v.revenue && v.revenueQuantity,
      )
      .forEach((value) => {
        if (value && value.cost) {
          costSum += value.cost;
        }
        if (value && value.revenue) {
          revenueSum += value.revenue;
        }
        if (value && value.costQuantity) {
          costQuantitySum += value.costQuantity;
        }
        if (value && value.revenueQuantity) {
          revenueQuantitySum += value.revenueQuantity;
        }
      });
    const margin =
      (revenueSum / revenueQuantitySum - costSum / costQuantitySum) /
        (revenueSum / revenueQuantitySum) || 0;
    const retString = `${decorateString({
      input: margin,
      presentationFormat,
      itemInfo,
    })}`;
    return {
      cost: costSum,
      revenue: revenueSum,
      costQuantity: costQuantitySum,
      revenueQuantity: revenueQuantitySum,
      toString: () => retString,
    };
  };
};
const ratioValueGetter = (
  presentationFormat: IPresentationFormat,
  itemInfo,
) => {
  return (params) => {
    if (!params.node.group) {
      let valX, valY;
      const x = presentationFormat.ratio?.x;
      const y = presentationFormat.ratio?.y;
      if (!x || !y) {
        throw new Error(
          'Ratio specified but no ratio.x or ratio.y values included',
        );
      }
      const field = params.colDef.field;
      const sep = field.lastIndexOf('.');
      if (sep !== -1) {
        const prefix = field.substring(0, sep);
        const vals = _.get(params.data, prefix);
        valX = vals[x];
        valY = vals[y];
      } else {
        valX = params.data[x];
        valY = params.data[y];
      }
      const retString = `${decorateString({
        input: valX && valY ? valX / valY : 0,
        presentationFormat,
        itemInfo,
      })}`;
      return {
        [x]: valX,
        [y]: valY,
        value: valX && valY ? valX / valY : 0,
        toString: () => retString,
      };
    }
  };
};
const ratioAggFunc = (presentationFormat: IPresentationFormat, itemInfo) => {
  return (params) => {
    const x = presentationFormat.ratio?.x;
    const y = presentationFormat.ratio.y;
    let xSum = 0;
    let ySum = 0;
    params.values.forEach((value) => {
      if (value && value[x]) {
        xSum += value[x];
      }
      if (value && value[y]) {
        ySum += value[y];
      }
    });

    const retString = `${decorateString({
      input: xSum && ySum ? xSum / ySum : 0,
      presentationFormat,
      itemInfo,
    })}`;
    return {
      [x]: xSum,
      [y]: ySum,
      value: xSum && ySum ? xSum / ySum : 0,
      toString: () => retString,
    };
  };
};
const allowDragHandle = (params: RowDragCallbackParams) => {
  if (params.node.data?._isPlaceholder) {
    return false;
  }
  return !(
    params.node.group && params.context.props.groupDisplayType === 'groupRows'
  );
};

const setupColumns = async (params: {
  batchUpdateContext: any;
  paged: any;
  presentationSupport: PresentationSupport;
  rowsInfo: React.MutableRefObject<RowsInfo>;
  groupComparator?: any;
  typeDefObject: TypeDefinition;
  gridRef: React.MutableRefObject<AgGridReact | undefined>;
  props: any;
  component: any;
  showColumnMenu: boolean;
  persistColumnDefs?: any;
  clientManager: ClientManager;
  widgetTrees: IWidgetTrees;
  groupData?: IGroupData[];
  widgetsByName: { [widgetName: string]: any };
  setFilterColumnDefs: any;
  isDetailGrid?: boolean;
}): Promise<IColumnInfo> => {
  const {
    typeDefObject,
    persistColumnDefs,
    widgetsByName,
    clientManager,
    component,
    rowsInfo,
    setFilterColumnDefs,
  } = params;

  const columnTypes = {
    [BasicType.ID]: {},
    [BasicType.String]: {},
    [BasicType.Int]: {},
    [BasicType.Decimal]: {},
    [BasicType.Boolean]: {},
    ['widget']: {},
    ['editableBoolean']: {},
    ['dropdown']: {},
  };
  let cols = [];
  let filterColumnDefs = [];
  if (
    typeDefObject.presentationInfo?.itemEnableDrag ||
    typeDefObject.presentationInfo?.itemEnableCheckboxSelect
  ) {
    cols.push({
      colId: SELECT_COLID,
      pinned: 'left',
      rowDrag: typeDefObject.presentationInfo?.itemEnableDrag
        ? allowDragHandle
        : null,
      suppressSizeToFit: true,
      width: typeDefObject.presentationInfo.itemEnableCheckboxSelect ? 70 : 45,
      maxWidth: typeDefObject.presentationInfo.itemEnableCheckboxSelect
        ? 70
        : 45,
      suppressHeaderMenuButton: true,
      checkboxSelection:
        typeDefObject.presentationInfo.itemEnableCheckboxSelect &&
        typeDefObject.presentationInfo?.itemEnableDrag
          ? allowDragHandle
          : typeDefObject.presentationInfo.itemEnableCheckboxSelect,
      headerCheckboxSelection: !typeDefObject.presentationInfo?.itemEnableDrag,
      headerCheckboxSelectionFilteredOnly:
        !!typeDefObject.presentationInfo.itemEnableCheckboxSelect,
    });
  }

  const orderedAttributes =
    typeDefObject.getVisiblePresentationAttributesInOrder();
  for (const attr of orderedAttributes) {
    const result = await createColumnDefFromAttribute({
      ...params,
      widgetsByName,
      attr,
    });
    if (Array.isArray(result)) {
      cols = cols.concat(result);
    } else {
      cols.push(result);
    }
    const newDefs = await addColumnToFilterLayout({
      typeDef: typeDefObject,
      cols: result as ColDef[],
      columnDefs: cols,
      attr,
      clientManager,
      configName: component.configName,
      rows: rowsInfo.current.allRows,
      filterColumnDefs,
    });
    filterColumnDefs = newDefs.filterDefs;
    cols = newDefs.columnDefs;
  }
  if (!params.isDetailGrid) {
    setFilterColumnDefs(filterColumnDefs);
  }
  const sortedColumns = persistColumnDefs
    ? getSortedColumns({ columns: cols, persistColumnDefs })
    : cols;
  const totalColumns: string[] = [];
  const addTotalCols = (colsToAdd: ColDef[]) => {
    for (const colDef of colsToAdd) {
      if ((colDef as any).children) {
        addTotalCols((colDef as any).children);
      } else {
        if (colDef.aggFunc) {
          totalColumns.push(colDef.field);
        }
      }
    }
  };

  addTotalCols(sortedColumns);
  logger.debug(sortedColumns, 'Column Defs');
  return { columnTypes, columnDefs: sortedColumns, totalColumns };
};
const getSortedColumns = (params: {
  columns: any[];
  persistColumnDefs: any;
}): ColDef[] => {
  const { columns, persistColumnDefs } = params;
  const sortedColumns = columns.sort((col1, col2) => {
    return getPersistIndex(col1, persistColumnDefs, columns) <=
      getPersistIndex(col2, persistColumnDefs, columns)
      ? -1
      : 1;
  });
  return sortedColumns;
};

function getPersistIndex(col, persistColumnDefs, columns) {
  const exactMatch = persistColumnDefs.findIndex(
    (pCol) => pCol.colId === col.colId,
  );
  if (exactMatch !== -1) {
    return exactMatch;
  }
  const nestedMatch = persistColumnDefs.findIndex(
    (pCol) => pCol.colId.includes('.') && pCol.colId.includes(col.colId),
  );
  if (nestedMatch !== -1) {
    return nestedMatch;
  }
  // If we get here it is because a column was added to the typeDef since our last persist state
  // FIXME if we add a new nested column we should have some mechanism to have it show up in a reasonable spot.
  return columns.findIndex((c) => c.colId === col.colId);
}

const calcPersistKey = (props: IProps): string =>
  // FIXME - this won't work if the typeDef is specified by name
  props.typeDefObject?.presentationInfo?.persistKey ||
  props.persistKey ||
  `${props.component.treeId.replace(':', '-')}-${props.id}`;

const mapStateToProps = (state, props) => {
  return {
    persistValue: state.uiState[calcPersistKey(props)],
  };
};

const handleGroups = (params: {
  rows: any[];
  groupData: IGroupData[];
  idField: string;
}): any[] => {
  const { rows, idField, groupData } = params;
  const newRows = [];

  groupData.forEach((group) => {
    for (const field in group) {
      if (field === '_groupInfo') {
        continue;
      }
      if (!rows.find((row) => group[field] === row[field])) {
        logger.debug(`Adding placeholder for group ${group._groupInfo.group}`);
        newRows.push({
          _isPlaceholder: true,
          [idField]: uuid(),
          ...group,
        });
        return;
      }
    }
  });
  return newRows;
};

const onRowDragEnd = async (rowDragData): Promise<RowDragEvent> => {
  const { nodes, overNode } = rowDragData;
  const { typeDef, presentationSupport, props } = rowDragData.context;
  const valid = isValid({
    typeDef,
    sourceData: nodes,
    destinationNode: overNode,
  });
  if (!valid) {
    return;
  }
  const destinationGroup = getGroupNameFromNode(overNode);
  const sourceGroup = nodes[0].group && nodes[0].key;
  let stages = typeDef.presentationInfo?.gridFormat.pasteRowCode;
  let typeName = nodes[0]?.data?.__typename;
  if (overNode?.group) {
    const attr = typeDef.attributes.find((a) => a.name === overNode.field);
    stages = attr.presentationInfo?.gridFormat?.pasteRowCode || stages;
    if (!typeName) {
      const srcAttr = typeDef.getAttribute(nodes[0].field);
      typeName = srcAttr.presentationInfo?.pasteType;
    }
  }
  const rowsToPaste = [];
  nodes.forEach((node) => {
    const nextRow = node.group
      ? { group: node.field, groupValue: node.key }
      : node.data;
    rowsToPaste.push(nextRow);
  });
  await presentationSupport.pasteData({
    data: {
      rowsToPaste,
      pasteLocation: ![undefined, null].includes(overNode?.data)
        ? overNode.data
        : overNode?.key || null,
      sourceGroup,
      destinationGroup,
      typeName,
      /**
       * Most apps will be fine with the above data, but with nested groups we
       * need more detail on the parents of the source and destination
       */
      sourceParents: sourceGroup ? getNestedGroupParents(nodes[0]) : null,
      destinationParents: destinationGroup
        ? getNestedGroupParents(overNode)
        : null,
    },
    stages,
    runPipelineWithWidgetContext: props.runPipelineWithWidgetContext,
  });
};

export function getNestedGroupParents(node): any[] {
  const parents = [];
  let activeNode = node;
  while (activeNode.parent && activeNode.parent.field) {
    activeNode = activeNode.parent;
    parents.push({ name: activeNode.field, value: activeNode.key });
  }
  return parents;
}
/**
 * TODO We would like to be able to change the drag icon displayed during a move
 * but, so far, we don't know of a way to change the content of the icon during
 * a drag We may be able to instead highlight the underlying row when a drop is
 * legal See https://www.ag-grid.com/react-data-grid/row-dragging/ highlighted
 * tree example.
 */
/* const onRowDragMove = (rowDragData) => {
  const { nodes, overNode } = rowDragData;
  const { typeDef } = rowDragData.context;
  const valid = isValid({
    typeDef,
    sourceData: nodes,
    destinationNode: overNode,
  });
};*/

function isValid(params: {
  typeDef: TypeDefinition;
  sourceData: any[];
  destinationNode: IRowNode;
}): boolean {
  const { typeDef, sourceData, destinationNode } = params;

  let typeName = sourceData[0]?.group
    ? sourceData[0].field
    : sourceData[0]?.data?.__typename || sourceData[0]?.data?.pasteType;
  if (!typeName) {
    const srcAttr = typeDef.getAttribute(sourceData[0].field);
    typeName = srcAttr?.presentationInfo?.pasteType;
  }
  if (!typeName) {
    throw new Error('Attempt to drag element with no __typeName or pasteType');
  }
  const outerAllowed = !!(
    !typeDef.presentationInfo?.allowedPasteTypes ||
    typeDef.presentationInfo.allowedPasteTypes.find((apt) => apt === typeName)
  );
  if (destinationNode?.group) {
    const attr = typeDef.getAttribute(destinationNode.field);
    return !!(
      outerAllowed ||
      (attr.presentationInfo.allowedPasteTypes &&
        attr.presentationInfo.allowedPasteTypes.find((apt) => apt === typeName))
    );
  } else {
    return outerAllowed;
  }
}

function getGroupNameFromNode(node: IRowNode): string {
  return node?.group ? node?.key : node?.parent ? node.parent.key : null;
}
const customAggFunc = (totalFooterCode: string) => {
  return (params: IAggFuncParams) => {
    const field = params.colDef.field;
    const sep = field.lastIndexOf('.');
    const codeContext = {
      _isRoot: params.rowNode.id === 'ROOT_NODE_ID',
      _container: {},
      _row: params.rowNode.aggData,
      _children: params.rowNode.childrenAfterFilter
        .filter((c) => c.data)
        .map((c) => c.data),
      _field: params.rowNode.field,
      _colId: params.colDef.colId,
      _values: params.values,
      _key: params.rowNode.key,
      _childrenAggData: params.rowNode.childrenAfterFilter
        .filter((c) => c.aggData)
        .map((c) => c.aggData),
    };
    if (sep !== -1) {
      const prefix = field.substring(0, sep + 1);
      for (const item in params.rowNode.aggData) {
        if (item.startsWith(prefix)) {
          codeContext._container[item.substring(sep + 1)] =
            params.rowNode.aggData[item];
        }
      }
    }
    const value = params.context.presentationSupport.runJsCode({
      context: codeContext,
      code: `${totalFooterCode}`,
    });
    return value;
  };
};
async function getServerSideFilterParams(params: {
  attr: IItemType;
  clientManager: ClientManager;
  label: string;
  configName: string;
}): Promise<any> {
  const { attr, clientManager, label, configName } = params;

  if (attr.itemInfo.type === BasicType.Boolean) {
    return {
      values: ['true', 'false'],
    };
  }

  const entityName = attr.itemInfo.associatedEntity;
  const fields = label === 'id' ? ['id'] : ['id', label];
  const filterParams: any = {
    keyCreator: (p) => p.value.id,
    valueFormatter: (p) => {
      return p.value[label];
    },
  };
  filterParams.values = _.orderBy(
    (
      await clientManager.pipelineManager.listRecords({
        entityName,
        fields,
        configName,
      })
    ).items,
    label,
  );
  return filterParams;
}
async function addColumnToFilterLayout(params: {
  typeDef: TypeDefinition;
  cols: ColDef[];
  columnDefs: ColDef[];
  attr: IItemType;
  filterColumnDefs: ColDef[];
  clientManager: ClientManager;
  configName: string;
  rows: any[];
}) {
  const { filterColumnDefs, cols, columnDefs, rows } = params;
  const newRows = _.cloneDeep(rows);
  const newColumnDefs = {
    filterDefs: _.cloneDeep(filterColumnDefs),
    columnDefs,
    rows: newRows,
  };
  const columnsToUse = Array.isArray(cols) ? cols : [cols];
  for (const col of columnsToUse) {
    if (!col.field) {
      return newColumnDefs;
    }
    newColumnDefs.filterDefs.push(col);
  }
  newColumnDefs.rows = newRows;
  return newColumnDefs;
}

export default connect(mapStateToProps)(AGGrid);
