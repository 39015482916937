import { Typography } from '@mui/material';
import { buildSchema } from 'graphql';
import { Component } from 'react';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { List } from 'react-virtualized';
import 'react-virtualized/styles.css';
import { compose } from 'redux';

import { withClient } from '../../wrappers/ClientContext';
import TestSafeAutosizer from '../../wrappers/TestSafeAutosizer';

class QueryList extends Component {
  public declare props;
  public declare state;
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
    };

    this.queryRowRenderer = this.queryRowRenderer.bind(this);
  }

  async componentDidMount() {
    const { clientManager } = this.props;
    const { schemaManager } = clientManager;
    const schemaInfo = await schemaManager.getSchemaInfo();
    const schema = buildSchema(schemaInfo.schema);
    const queryType = schema.getQueryType();
    const queryFields = queryType && queryType.getFields();

    const listQueries = Object.keys(queryFields)
      .filter(
        (fieldName) => fieldName.startsWith('list') && !fieldName.includes('_'),
      )
      .sort()
      .map((queryField) => ({
        queryField,
        entityName: queryField.replace(/list/, ''),
      }));

    this.setState({
      listQueries,
    });
  }

  queryRowRenderer(row) {
    const { index, style } = row;
    const { listQueries } = this.state;
    const queryField = listQueries[index];

    return (
      <div style={style}>
        <Typography
          component={Link}
          to={`/data/${queryField.entityName}`}
          style={{ textDecoration: 'none', color: 'blue' }}
        >
          {queryField.entityName}
        </Typography>
      </div>
    );
  }

  render() {
    const { listQueries } = this.state;

    if (!listQueries) {
      return null;
    }

    return (
      <TestSafeAutosizer disableHeight>
        {({ width, height }) => (
          <List
            width={width}
            height={height}
            rowCount={listQueries.length}
            rowHeight={30}
            rowRenderer={this.queryRowRenderer}
            style={{ outline: 'none' }}
          />
        )}
      </TestSafeAutosizer>
    );
  }
}

export default compose(withRouter, withClient)(QueryList);
