import { InvokeCommandOutput } from '@aws-sdk/client-lambda';
import safeJsonStringify from 'safe-json-stringify';

import { ClientManager } from './clientManager';
import { getLambdaFunctionFullName } from './common/commonUtilities';
import { retry } from './errors/retry';
import { ALLOCATION_OPTIMIZER } from './lambdaSupport';
import { Loggers, getLogger } from './loggerSupport';

const logger = getLogger({ name: Loggers.OPTIMIZER });

export enum OptimizationType {
  Allocation = 1,
}

// WARNING - these definitions must match those in server/optimizers

export interface IConstraint {
  allocationMin: number;
  allocationMax: number;
  parentConstraints: [id: string];
}

export interface IOptimizerParams {
  type: OptimizationType;
  options: {
    scoreLevelPercent: number;
  };
  constraints: { [id: string]: IConstraint };
  planningUnits: {
    [id: string]: {
      constraint: IConstraint;
      score: number;
      unitSize: number;
    };
  };
}

export enum OptimizationReturn {
  // Definitions from the OR-tools (cp_model_pb2)
  Unknown = 0,
  ModelInvalid = 1,
  Feasible = 2,
  Infeasible = 3,
  Optimal = 4,
}

export interface IOptimizerResult {
  returnValue: OptimizationReturn;
  solution: { [planningUnitId: string]: number };
  totalAllocated: number;
}

export class OptimizerSupport {
  public clientManager: ClientManager;

  public initialize(clientManager: ClientManager) {
    this.clientManager = clientManager;
  }

  public async callOptimizer(
    params: IOptimizerParams,
  ): Promise<IOptimizerResult> {
    const fullFunctionName = getLambdaFunctionFullName(
      this.clientManager.stackId,
      ALLOCATION_OPTIMIZER,
    );

    if (!params.planningUnits || !params.constraints) {
      throw new Error('Optimizer failed, missing input parameters');
    }

    const rawResponse = await retry<InvokeCommandOutput>({
      command: async () =>
        this.clientManager.lambdaSupport
          .getLambdaClient(ALLOCATION_OPTIMIZER)
          .invoke({
            FunctionName: fullFunctionName,
            Payload: new TextEncoder().encode(
              safeJsonStringify({ body: safeJsonStringify(params) }),
            ),
          }),
    });

    logger.debug(rawResponse, `After: ${fullFunctionName}`);

    const response = JSON.parse(new TextDecoder().decode(rawResponse.Payload));
    if (rawResponse.FunctionError) {
      throw new Error(
        `Optimizer error: function error ${
          rawResponse.FunctionError
        }: ${safeJsonStringify(response)}`,
      );
    }

    if (response.body && typeof response.body === 'string') {
      response.body = JSON.parse(response.body);
    }

    const result: IOptimizerResult = response.body;
    if (result.returnValue === 4 /*OptimizationReturn.Optimal*/) {
      return result;
    }
    if (result.returnValue === 2 /*OptimizationReturn.Feasible*/) {
      return result;
    }

    if (
      result.returnValue === 3 /*OptimizationReturn.Infeasible*/ ||
      result.returnValue === 0 /*OptimizationReturn.Unknown*/
    ) {
      throw new Error(
        'Optimization failed: could not find solution. Check that the constraints can be satisfied',
      );
    }
    throw new Error(`Optimization failed: other reason: ${result.returnValue}`);
  }
}
