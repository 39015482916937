import _ from 'lodash';
import printTree from 'print-tree';

import { exists } from '../common/commonUtilities';
import { getLogger, Loggers } from '../loggerSupport';

import {
  EXECUTION_ID,
  IPipelineExecution,
  makeExecutionIdUnique,
  PipelineExecutionType,
  PipelineManager,
} from './pipelineManager';

const logger = getLogger({ name: Loggers.PIPELINE_MANAGER });

interface IPipelineExecutionStatusTree extends Partial<IPipelineExecution> {
  aggregateRead?: number;
  aggregateWritten?: number;
  children: IPipelineExecutionStatusTree[];
}

interface ICount {
  read: number;
  written: number;
}

interface ICountSummary {
  totalCount: ICount;
  chunkCounts: IChunkCounts;
  pageCounts: IPageCounts;
}

interface IChunkCounts {
  [chunk: number]: ICount;
}

interface IPageCounts {
  [chunk: number]: { [page: number]: ICount };
}

export function getRemoteAsyncPipelineStatusTree(
  statuses: IPipelineExecution[],
): IPipelineExecutionStatusTree {
  const tree = arrayToTree(statuses);

  addCountsToStatusTree(tree);

  return tree;
}

function addCountsToStatusTree(tree: IPipelineExecutionStatusTree) {
  const { children, pipelineExecutionType, inputSummary, outputSummary } = tree;

  const inputRecordCount = exists(inputSummary?.recordCount)
    ? inputSummary.recordCount
    : 0;
  const outputRecordCount = exists(outputSummary?.recordCount)
    ? outputSummary.recordCount
    : 0;

  tree.aggregateWritten = outputRecordCount;

  if (
    [
      PipelineExecutionType.COMPLETE_MUTATION_AFTER_TIMEOUT,
      // FIXME: should retry execution be here?
      PipelineExecutionType.TO_DEAD_LETTER_QUEUE,
    ].includes(pipelineExecutionType)
  ) {
    tree.aggregateRead = 0;
  } else {
    tree.aggregateRead = inputRecordCount;
  }

  if (children) {
    // We can't just add the child totals because there may be duplicates due to
    // SQS retries. So they are all stored in this hash table and at the end if
    // there are duplicates the one with the highest write count is used.

    const childCountsHash: {
      [executionId: string]: Array<{
        aggregateRead: number;
        aggregateWritten: number;
      }>;
    } = {};

    children.forEach((childTree) => {
      addCountsToStatusTree(childTree);

      const { executionId, aggregateRead, aggregateWritten } = childTree;

      if (!childCountsHash[executionId]) {
        childCountsHash[executionId] = [];
      }

      childCountsHash[executionId].push({
        aggregateRead,
        aggregateWritten,
      });
    });

    Object.values(childCountsHash).forEach((countArray) => {
      const maxCount = _.maxBy(countArray, 'aggregateWritten');

      tree.aggregateRead += maxCount.aggregateRead;
      tree.aggregateWritten += maxCount.aggregateWritten;
    });
  }
}

export function printRemoteAsyncPipelineStatusTree({
  statusTree,
  requestId,
  chunkId,
  debugCounts,
}: {
  statusTree: IPipelineExecutionStatusTree;
  requestId: string;
  chunkId?: number;
  debugCounts?: boolean;
}): void {
  logger.info(
    `Pipeline Execution Chain of Causality requestId: ${requestId}${
      exists(chunkId) ? ` chunkId: ${chunkId}` : ''
    }`,
  );

  printTree(
    statusTree,
    (node, branchGraphic) => {
      if (debugCounts) {
        logger.info(`${branchGraphic}${statusToStringSimple(node)}`);
      } else {
        const statusString = PipelineManager.statusToString(node);

        logger.info(`${branchGraphic}${statusString}`);
      }
    },
    (node) => node.children,
  );
}

function statusToStringSimple(status: IPipelineExecutionStatusTree): string {
  const {
    executionId,
    pipelineExecutionType,
    chunkId,
    pageNumber,
    sqsTryCount,
    aggregateRead,
    aggregateWritten,
    inputSummary,
    outputSummary,
  } = status;

  const inputCount = exists(inputSummary?.recordCount)
    ? inputSummary.recordCount
    : 0;
  const outputCount = exists(outputSummary?.recordCount)
    ? outputSummary.recordCount
    : 0;

  return (
    `${executionId?.split('-')[0]} ` +
    `${pipelineExecutionType} ` +
    `chunk: ${chunkId} ` +
    `page: ${pageNumber} ` +
    `try: ${sqsTryCount} ` +
    `inCount: ${inputCount} outCount: ${outputCount} ` +
    `aggRead: ${aggregateRead} aggWritten: ${aggregateWritten}`
  );
}

export function getCountSummary(
  statusTree: IPipelineExecutionStatusTree,
): ICountSummary {
  // FIXME: This assumes it is many chunks. Also handle case where it is a
  //  single chunk or it is not chunked.

  const totalCount: ICount = {
    read: statusTree.aggregateRead,
    written: statusTree.aggregateWritten,
  };

  const chunkCounts: IChunkCounts = {};

  statusTree.children.forEach(
    ({ chunkId, aggregateRead, aggregateWritten }) => {
      if (!chunkCounts[chunkId]) {
        chunkCounts[chunkId] = {
          read: 0,
          written: 0,
        };
      }

      chunkCounts[chunkId].read += aggregateRead;
      chunkCounts[chunkId].written += aggregateWritten;
    },
  );

  const pageCounts: IPageCounts = {};
  const statuses = treeToArray(statusTree);

  statuses.forEach(
    ({
      chunkId,
      pageNumber,
      aggregateRead,
      aggregateWritten,
      pipelineExecutionType,
      executionId,
    }) => {
      if (!executionId) {
        return;
      }

      if (!pageCounts[chunkId]) {
        pageCounts[chunkId] = {};
      }

      if (!pageCounts[chunkId][pageNumber]) {
        pageCounts[chunkId][pageNumber] = {
          read: 0,
          written: 0,
        };
      }

      if (
        pipelineExecutionType === PipelineExecutionType.REGULAR_PIPELINE &&
        aggregateWritten > pageCounts[chunkId][pageNumber].written
      ) {
        pageCounts[chunkId][pageNumber].read = aggregateRead;
        pageCounts[chunkId][pageNumber].written = aggregateWritten;
      }
    },
  );

  return {
    totalCount,
    chunkCounts,
    pageCounts,
  };
}

export function printCountSummary({
  totalCount,
  chunkCounts,
  pageCounts,
}: ICountSummary): void {
  logger.info('');
  logger.info(`Total ${getCountString(totalCount)}`);
  logger.info('');

  Object.keys(chunkCounts).forEach((chunk) => {
    const chunkCount = chunkCounts[chunk];

    logger.info(`Chunk ${chunk} ${getCountString(chunkCount)}`);
  });

  logger.info('');

  Object.keys(pageCounts).forEach((chunk) => {
    const chunkPageCounts = pageCounts[chunk];

    logger.info(`Chunk ${chunk}`);

    Object.keys(chunkPageCounts).forEach((page) => {
      const pageCount = chunkPageCounts[page];

      logger.info(`\tPage ${page} ${getCountString(pageCount)}`);
    });
  });
}

function getCountString({ read, written }: ICount): string {
  return `read: ${read} written: ${written}${read !== written ? ' !!!!!' : ''}`;
}

function arrayToTree(
  inputStatuses: IPipelineExecution[],
): IPipelineExecutionStatusTree {
  const statuses = _.cloneDeep(inputStatuses) as IPipelineExecutionStatusTree[];

  const executionIdTable = _.keyBy(statuses, EXECUTION_ID);
  const uniqueExecutionIdTable = _.keyBy(statuses, (status) => {
    const { executionId, sqsTryCount } = status;

    return makeExecutionIdUnique({
      executionId,
      sqsTryCount,
    });
  });

  Object.assign(executionIdTable, uniqueExecutionIdTable);

  const rootNodes: IPipelineExecutionStatusTree[] = [];

  statuses.forEach((status) => {
    const parentStatus = executionIdTable[status.callingExecutionId];

    if (parentStatus) {
      if (!parentStatus.children) {
        parentStatus.children = [];
      }

      parentStatus.children.push(status);
    } else {
      rootNodes.push(status);
    }
  });

  if (rootNodes.length === 1) {
    return rootNodes[0];
  } else {
    return {
      children: rootNodes,
    };
  }
}

function treeToArray(
  inputStatusTree: IPipelineExecutionStatusTree,
): IPipelineExecutionStatusTree[] {
  const statusTree = _.clone(inputStatusTree);
  const statuses: IPipelineExecutionStatusTree[] = [];

  walkTree({
    statusTree,
    callback: (nestedStatusTree) => {
      statuses.push(nestedStatusTree);
    },
  });

  statuses.forEach((status) => {
    delete status.children;
  });

  return statuses;
}

function walkTree({
  statusTree,
  callback,
}: {
  statusTree: IPipelineExecutionStatusTree;
  callback: (statusTree: IPipelineExecutionStatusTree) => void;
}): void {
  callback(statusTree);

  if (statusTree.children) {
    statusTree.children.forEach((nestedStatusTree) => {
      walkTree({
        statusTree: nestedStatusTree,
        callback,
      });
    });
  }
}
