import { getLogger, Loggers } from 'universal/loggerSupport';
import { PipelineExecutor } from 'universal/pipeline/pipelineExecutor';
import { IPipelineExternal } from 'universal/pipeline/pipelineManager';

import { IRawUpdate } from '../store/data';

const loggerData = getLogger({ name: Loggers.CLIENT_DATA });

export default class PipelineExternalAdaptor implements IPipelineExternal {
  public commits: IRawUpdate[];
  public executor: PipelineExecutor;
  constructor() {
    this.commits = [];
  }

  // raw path has not been processed, might have decorator symbols or aliases still in it
  // and does not contain the widget address portion
  public write(rawPath, data) {
    loggerData.debug(
      { path: rawPath, data },
      `pipelineExternalAdaptor ${this.executor.getTracingInfo()} - write`,
    );
    const commit: IRawUpdate = { data, rawPath };
    this.commits.push(commit);
  }

  // for paging, this will add to an array rather than replace it
  public append(rawPath, data: any[]) {
    const page: IRawUpdate = {
      data,
      rawPath,
      append: true,
    };
    this.commits.push(page);
  }

  // internal use only, a widget will clear its commits after digesting them to the store
  public clearStore() {
    loggerData.debug(
      `pipelineExternalAdaptor ${this.executor.getTracingInfo()} - clear commits (for executor)`,
    );
    this.commits = [];
  }

  // convenience methods, would like them to be dynamically generated, but they won't be typed
  public writeGlobal(rawPath, data) {
    this.write(`/${rawPath}`, data);
  }

  public writeParent(widgetName, rawPath, data) {
    this.write(`#${widgetName}.${rawPath}`, data);
  }

  public writeSelf(rawPath, data) {
    this.write(`_self.${rawPath}`, data);
  }

  public appendGlobal(rawPath, data) {
    this.append(`/${rawPath}`, data);
  }

  public appendParent(widgetName, rawPath, data) {
    this.append(`#${widgetName}.${rawPath}`, data);
  }

  public appendSelf(rawPath, data) {
    this.append(`_self.${rawPath}`, data);
  }
}
