import _ from 'lodash';
import { ClientManager } from 'universal/clientManager';
import {
  extractHandlebarsKeys,
  isNullOrUndefined,
} from 'universal/utilityFunctions';

import { configurationTypeCategories } from '../components/widgets/types';
import { widgetSubscribeMapBuilders } from '../components/widgets/widgetTypes';
// import { EVENT_BINDING_CONTAINER } from '../store/data';

import { includesAnyOf } from './widgetUtilities';

const ignoreSubscribeFields = ['_widget'];

export const REQUIRED = 'required';

function extractProperty(property) {
  if (property === undefined) {
    return undefined;
  }

  if (typeof property === 'object' && property?.type === 'range') {
    const valuePointers = property.ranges.reduce((acc, range) => {
      if (range.valuePointer) {
        const extractedValuePointer = extractProperty(range.valuePointer);
        if (extractedValuePointer) {
          return acc.concat(range.valuePointer);
        }
      }
      return acc;
    }, []);
    if (property.baseField === undefined) {
      return undefined;
    }
    return property.rangesPointer
      ? [property.baseField, property.rangesPointer, ...valuePointers]
      : [property.baseField, ...valuePointers];
  }

  if (isNaN(property) && !['true', 'false'].includes(property)) {
    if (property.includes('{{')) {
      const hbKeys = extractHandlebarsKeys(property);
      return hbKeys;
    }

    if (!property.startsWith("'")) {
      return property;
    }
  }

  return undefined;
}

function extractProperties(properties) {
  return Object.keys(properties).reduce((acc, key) => {
    const property = properties[key];
    const extractedProperty = extractProperty(property);
    if (!isNullOrUndefined(extractedProperty)) {
      return acc.concat(extractedProperty);
    }

    return acc;
  }, []);
}

export function getPropertyFields(component) {
  const { properties, category } = component;

  const parsedProperties = extractProperties(properties);

  // any inputs automatically subscribe to data to store their input state
  if (category === 'input') {
    return _.uniq([...parsedProperties, 'data']);
  }

  return parsedProperties;
}

export function getChildPropertyFields(component) {
  let childPropertyFields = [];
  Object.keys(configurationTypeCategories).forEach((childCategory) => {
    if (component[childCategory]) {
      component[childCategory].forEach((child) => {
        if (child.parentProperties) {
          const extractedParentProperties = extractProperties(
            child.parentProperties,
          );

          childPropertyFields = _.uniq(
            childPropertyFields.concat(extractedParentProperties),
          );
        }
      });
    }
  });
  return childPropertyFields;
}

export function getNestedConfigFields(component, splitByTypes?): any {
  if (!splitByTypes) {
    const configFields = Object.keys(configurationTypeCategories).reduce(
      (acc, childCategory) => {
        if (!configurationTypeCategories[childCategory].nestedConfigItem) {
          return acc;
        }
        if (component[childCategory]) {
          const childFields = component[childCategory].reduce(
            (childAcc, child) => {
              return childAcc.concat(
                getNestedConfigFields(child),
                extractProperties(child.properties),
              );
            },
            [],
          );
          return acc.concat(childFields);
        }
        return acc;
      },
      [],
    );
    return _.uniq(configFields).filter(
      (field) => !includesAnyOf(field, ignoreSubscribeFields),
    );
  }
  return Object.keys(configurationTypeCategories).reduce(
    (acc, childCategory) => {
      if (!configurationTypeCategories[childCategory].nestedConfigItem) {
        return acc;
      }
      if (component[childCategory]) {
        const childFields = component[childCategory].reduce(
          (childAcc, child) => {
            return childAcc.concat(
              getNestedConfigFields(child),
              extractProperties(child.properties),
            );
          },
          [],
        );

        return {
          ...acc,
          [childCategory]: [...childFields, ...(acc?.[childCategory] || [])],
        };
      }
      return acc;
    },
    {},
  );
}

function getPipelineFields(component, tree, clientManager: ClientManager) {
  const { pipelineManager } = clientManager;
  const { events = [], configName } = component;
  let requiredFields;

  const pipelineRequestsByType = events
    .filter((e) => e.name === 'load')
    .reduce((acc, event) => {
      const executor = pipelineManager.createPipelineExecutor({
        stages: event.stages,
        tracingIdentifier: `${tree.id}/${component.name}`,
        configName,
      });
      requiredFields = executor.requiredFields;
      const retVal = Object.assign(acc, {
        [event.name]: [
          ...executor.requestedFields /*, EVENT_BINDING_CONTAINER*/,
        ],
      });
      executor.close();
      return retVal;
    }, {});

  return { pipelineRequestsByType, requiredFields: requiredFields || [] };
}

export default function getSubscribeFields(
  component,
  tree,
  clientManager: ClientManager,
): { subscribeFields: string[]; requiredFields: string[] } {
  const { pipelineRequestsByType, requiredFields } = getPipelineFields(
    component,
    tree,
    clientManager,
  );

  const widgetSubscribeMap =
    widgetSubscribeMapBuilders[component.type] || defaultSubscribeMapBuilder;

  const renderFields = widgetSubscribeMap(component);

  const subscribeFields = {
    render: renderFields,
    ...pipelineRequestsByType,
  };

  return { subscribeFields, requiredFields };
}

function defaultSubscribeMapBuilder(component) {
  return {
    properties: getPropertyFields(component),
    childContext: getChildPropertyFields(component),
    nestedConfigContext: getNestedConfigFields(component),
  };
}
