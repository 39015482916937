import {
  FormControlLabel,
  Icon,
  Switch,
  Tooltip,
  Typography,
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import produce from 'immer';
import { PureComponent } from 'react';
import { compose } from 'redux';
import { exists } from 'universal/common/commonUtilities';
import {
  IRange,
  IRanges,
  PathArray,
  PropertyConfigurationHandler,
} from 'universal/propertySupport';
import { AdditionalEditor, EditorType } from 'universal/types';

import CustomSelect from '../atoms/CustomSelect';
import CustomTextField from '../atoms/CustomTextField';

import ColorEditor from './ColorEditor';
import { specialPropertyEditors } from './specialPropertyEditors';

interface IPropertyEditorProps {
  updateField;
  classes;
  propertyConfigurationHandler: PropertyConfigurationHandler;
  propertyName: string;
  propertyInfo: any;
  pathInWidgetTree: PathArray;
  value;
  propertyDisplayName;
  inRange?: boolean;
}

const styles = {
  editField: {
    margin: 25,
  },
  rowWrapper: { display: 'flex', width: '100%', alignItems: 'baseline' },
  leftPart: {
    display: 'flex',
    alignItems: 'baseline',
    flexBasis: '25%',
    justifyContent: 'end',
  },
  specialEditorWrapper: {
    display: 'flex',
    width: '100%',
    alignItems: 'baseline',
    flexDirection: 'column',
  },
};

class PropertyEditor extends PureComponent<IPropertyEditorProps> {
  constructor(props) {
    super(props);

    this.handleUpdateField = this.handleUpdateField.bind(this);
    this.handleSelectType = this.handleSelectType.bind(this);
    this.handleChangeRangeCount = this.handleChangeRangeCount.bind(this);
    this.getRangeCountFromValue = this.getRangeCountFromValue.bind(this);
  }

  // this is to transform input values based on property details, like type
  public handleUpdateField(value: any = '', identifier) {
    const { updateField } = this.props;

    updateField(value, identifier);
  }

  public handleSelectType(type) {
    this.setState({ type });
  }

  public getRangeCountFromValue(value) {
    if (typeof value === 'object' && value.ranges) {
      return value.ranges.length;
    }
    return 0;
  }

  public handleChangeRangeCount(newRangeVountValue) {
    const { value, updateField, pathInWidgetTree } = this.props;
    const previouseRangeCount = this.getRangeCountFromValue(value);
    const newRangeCount = Number(newRangeVountValue);
    if (newRangeCount < 0) {
      return;
    }
    if (previouseRangeCount === 0 && newRangeCount > 0) {
      const newRanges = new Array(newRangeCount)
        .fill(1)
        .map(() => ({ min: 0, max: 1, valuePointer: '' }));
      newRanges[0].valuePointer = value;
      const newValue: IRanges = {
        type: 'range',
        baseField: '',
        ranges: newRanges,
        dynamic: false,
      };
      updateField(newValue, pathInWidgetTree);
    } else if (newRangeCount === 0 && previouseRangeCount > 0) {
      const firstRangeValue = value.ranges[0].valuePointer;
      updateField(firstRangeValue, pathInWidgetTree);
    } else {
      const rangeDiff = newRangeCount - previouseRangeCount;
      if (rangeDiff > 0) {
        const newRanges = new Array(rangeDiff)
          .fill(1)
          .map(() => ({ min: 0, max: 1, valuePointer: '' }));
        const newRangeValue = produce(value, (draftValue) => {
          draftValue.ranges = [...draftValue.ranges, ...newRanges];
        });
        updateField(newRangeValue, pathInWidgetTree);
      } else {
        const newRanges = value.ranges.slice(0, newRangeCount);
        const newRangeValue = produce(value, (draftValue) => {
          draftValue.ranges = newRanges;
        });
        updateField(newRangeValue, pathInWidgetTree);
      }
    }
  }

  public render() {
    const {
      classes,
      propertyName,
      propertyInfo,
      pathInWidgetTree,
      value,
      propertyDisplayName,
      updateField,
      inRange,
      propertyConfigurationHandler,
    } = this.props;
    const {
      propertyConfiguration: { type: editingType },
    } = propertyConfigurationHandler;

    const {
      options,
      description,
      type,
      types,
      editor: specialEditor,
      required,
      additionalEditor,
    } = propertyInfo;

    const keyPart = (
      <Typography style={{ margin: 10 }} align={'right'}>
        {propertyDisplayName}
      </Typography>
    );

    const additionalEditors = {
      [AdditionalEditor.Color]: ColorEditor,
    };

    let rightEditor = null;

    if (types && types.options) {
      rightEditor = (
        <CustomSelect
          style={{ margin: 10, flex: 1 }}
          onChange={this.handleSelectType}
          value={type}
          name={'type'}
          options={types.options}
        />
      );
    }

    if (additionalEditor && additionalEditors[additionalEditor]) {
      const Editor = additionalEditors[additionalEditor];
      rightEditor = (
        <div style={{ margin: 10, flex: 1 }}>
          <Editor
            onChange={this.handleUpdateField}
            identifier={pathInWidgetTree}
            value={value}
          />
        </div>
      );
    }

    const infoIcon = description ? (
      <Tooltip title={description}>
        <Icon>info</Icon>
      </Tooltip>
    ) : null;

    const isRequiredPart = required ? (
      <div className={classes.isRequiredWrapper}>
        <Icon>fiber_manual_record</Icon>
      </div>
    ) : null;

    const leftPart = (
      <span className={classes.leftPart}>
        {isRequiredPart}
        {keyPart}
        {infoIcon}
      </span>
    );

    let editor;

    if (typeof value === 'object') {
      if (value.type === 'range') {
        const rangeCount = (
          <CustomTextField
            onChange={this.handleChangeRangeCount}
            type="number"
            value={this.getRangeCountFromValue(value)}
            name="range"
            identifier={pathInWidgetTree}
            sx={{ width: '50px' }}
          />
        );

        const rangeValue = (
          <CustomTextField
            sx={{ margin: 2, flex: 1 }}
            onChange={this.handleUpdateField}
            identifier={['baseField', ...pathInWidgetTree]}
            value={value.baseField}
            name={'range based on'}
          />
        );

        const dynamicRangeIdentifier = ['dynamic', ...pathInWidgetTree];
        const dynamicRange = (
          <FormControlLabel
            label="dynamic"
            control={
              <Switch
                checked={value.dynamic}
                onChange={(event) =>
                  this.handleUpdateField(
                    event.target.checked,
                    dynamicRangeIdentifier,
                  )
                }
              />
            }
          />
        );

        const rangeEditors = value.dynamic ? (
          <CustomTextField
            sx={{ margin: 2, flex: 1 }}
            onChange={this.handleUpdateField}
            identifier={['rangesPointer', ...pathInWidgetTree]}
            value={value.rangesPointer}
            name={'Dynamic Pointer'}
          />
        ) : (
          value.ranges.map((range: IRange, index) => {
            const rangeMin = (
              <CustomTextField
                type="number"
                sx={{ margin: 2, flex: 1 }}
                onChange={this.handleUpdateField}
                identifier={['min', index, 'ranges', ...pathInWidgetTree]}
                value={value.ranges[index].min}
                name={'Min'}
              />
            );
            const rangeMax = (
              <CustomTextField
                type="number"
                sx={{ margin: 2, flex: 1 }}
                onChange={this.handleUpdateField}
                identifier={['max', index, 'ranges', ...pathInWidgetTree]}
                value={value.ranges[index].max}
                name={'Max'}
              />
            );

            const rangeValueEditor = (
              <PropertyEditor
                propertyConfigurationHandler={propertyConfigurationHandler}
                classes={classes}
                updateField={updateField}
                propertyName={propertyName}
                key={index}
                propertyInfo={propertyInfo}
                pathInWidgetTree={[
                  'valuePointer',
                  index,
                  'ranges',
                  ...pathInWidgetTree,
                ]}
                value={value.ranges[index].valuePointer}
                propertyDisplayName={propertyDisplayName}
                inRange={true}
              />
            );
            return (
              <span className={classes.rowWrapper}>
                {rangeMin}
                {rangeMax}
                <span style={{ width: '70%' }}>{rangeValueEditor}</span>
              </span>
            );
          })
        );

        editor = (
          <span key={pathInWidgetTree.join()}>
            <span className={classes.rowWrapper}>
              {leftPart}
              {rangeValue}
              {dynamicRange}
              {rangeCount}
            </span>
            <div style={{ paddingLeft: '25px' }}>{rangeEditors}</div>
          </span>
        );
      }
    } else if (exists(specialEditor)) {
      if (
        !exists(EditorType[specialEditor]) ||
        !exists(specialPropertyEditors[specialEditor])
      ) {
        throw new Error(`Unrecognized editor type ${specialEditor}`);
      }

      const StageEditor = specialPropertyEditors[specialEditor];

      const valuePart = (
        <StageEditor
          updateField={this.handleUpdateField}
          identifier={pathInWidgetTree}
          key={pathInWidgetTree.join()}
          value={value}
        />
      );

      editor = (
        <span
          className={classes.specialEditorWrapper}
          key={pathInWidgetTree.join()}
        >
          <span className={classes.rowWrapper}>
            {leftPart}
            {rightEditor}
          </span>
          {valuePart}
        </span>
      );
    } else {
      const rangeCount = !inRange && (
        <CustomTextField
          onChange={this.handleChangeRangeCount}
          type="number"
          value={this.getRangeCountFromValue(value) || ''}
          name="ranges"
          identifier={pathInWidgetTree}
          sx={{ width: '50px' }}
        />
      );

      let valuePart;
      if (options) {
        valuePart = (
          <CustomSelect
            style={{ margin: 10, flex: 1 }}
            onChange={this.handleUpdateField}
            identifier={pathInWidgetTree}
            freeSolo
            value={value}
            name={'value'}
            options={options}
          />
        );
      } else {
        valuePart = (
          <CustomTextField
            sx={{ margin: 2, flex: 1 }}
            onChange={this.handleUpdateField}
            identifier={pathInWidgetTree}
            value={value}
            name={'value'}
          />
        );
      }

      editor = (
        <span className={classes.rowWrapper} key={pathInWidgetTree.join()}>
          {leftPart}
          {valuePart}
          {rightEditor}
          {editingType === 'widget' && rangeCount}
        </span>
      );
    }

    return editor;
  }
}

export default compose(
  // @ts-ignore
  withStyles(styles, { withTheme: true }),
  // @ts-ignore
)(PropertyEditor);
// @ts-ignore
