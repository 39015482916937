import { Avatar, Chip, Tooltip } from '@mui/material';
import { compose } from 'redux';

import { getIcon } from '../../util/clientUtilities';
import { withActions } from '../widgetEngine/ActionEnabler';

function ChipWidget(props) {
  const {
    label,
    icon,
    width,
    color,
    textColor,
    avatarColor,
    iconColor,
    textDecoration,
    variant,
    tooltip,
  } = props;

  if (typeof label === 'object') {
    throw new Error(
      'received object as chip label, must be a string or number',
    );
  }

  const IconComponent = icon
    ? getIcon(icon, props.component.configName, props.elementAttributes)
    : null;
  const avatarIcon = icon ? (
    <IconComponent style={{ color: iconColor }} />
  ) : null;

  const avatar = avatarIcon ? (
    <Avatar style={{ backgroundColor: avatarColor, color: iconColor }}>
      {avatarIcon}
    </Avatar>
  ) : null;

  const colorStyle =
    variant === 'filled'
      ? { backgroundColor: color }
      : {
          borderColor: color,
          borderWidth: '3px',
          backgroundColor: 'white',
        };

  const chip = (
    <Tooltip title={tooltip}>
      <Chip
        avatar={avatar}
        variant={variant}
        label={label}
        sx={{
          ...colorStyle,
          color: textColor,
          width,
          justifyContent: 'start',
          overflow: 'hidden',
          textDecoration,
          boxSizing: 'border-box',
        }}
        {...props.elementAttributes}
      />
    </Tooltip>
  );

  return <div key="chip">{chip}</div>;
}

export default compose(withActions)(ChipWidget);
