import { PipelineExecutor } from '../pipelineExecutor';
import { IStageInfo, IStageProperties } from '../stage';
import { StageImpl } from '../stageImpl';

import { makeJsArgs } from './javascript';

export interface IStagePropertiesTypescript extends IStageProperties {
  moduleName: string;
  functionName: string;
}

const execute = async (executor: PipelineExecutor, stage: StageImpl) => {
  // This is setup by the client code
  // const userInfo = executor.input._userInfo;

  const workingProperties =
    stage.workingProperties as IStagePropertiesTypescript;
  const { moduleName, functionName } = workingProperties;

  const module =
    await executor.pipelineManager.clientManager.configsImportMap[moduleName];
  if (!module) {
    throw new Error(
      `Unable to find module: ${moduleName}, did you run devsetup after adding your file?`,
    );
  }

  if (!module[functionName]) {
    throw new Error(
      `Unable to find function: ${functionName} in module: ${moduleName}`,
    );
  }

  const jsArgs = await makeJsArgs(executor, stage);
  const result = await module[functionName](jsArgs);
  executor.recordResult(stage, result);
};

export function initialize(stageInfo: IStageInfo) {
  stageInfo.executor = execute;
}
