import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { Component } from 'react';
import { compose } from 'redux';
import { getConfigurationPaths } from 'universal/loadStore/loadstore';

import CustomTextField from '../components/atoms/CustomTextField';
import Loading from '../components/atoms/Loading';
import LoadSaveRow from '../components/molecules/LoadSaveRow';
import { IClientContext, withClient } from '../wrappers/ClientContext';

interface IState {
  loadingConfigPaths: boolean;
  loadingConfig: boolean;
  savingConfig: boolean;
  commitMessage: string;
  error: any;
  configPaths: null;
}

class LoadSaveConfiguration extends Component {
  public declare props: IClientContext & { classes };
  public declare state: IState;

  constructor(props) {
    super(props);
    this.state = {
      loadingConfigPaths: true,
      loadingConfig: false,
      savingConfig: false,
      commitMessage: '',
      error: null,
      configPaths: null,
    };
    this.loadConfigPaths = this.loadConfigPaths.bind(this);
    this.setLoadingConfig = this.setLoadingConfig.bind(this);
    this.setSavingConfig = this.setSavingConfig.bind(this);
    this.setError = this.setError.bind(this);
  }

  public componentDidMount() {
    void this.loadConfigPaths();
  }

  public setLoadingConfig(value) {
    this.setState({
      loadingConfig: value,
    });
  }

  public setSavingConfig(value) {
    this.setState({
      savingConfig: value,
    });
  }

  public setError(value) {
    this.setState({
      error: value,
    });
  }

  public updateMessage = (commitMessage) => {
    this.setState({ commitMessage });
  };

  public async loadConfigPaths() {
    const {
      userInfo: { githubToken },
      clientManager,
    } = this.props;

    const { buildBranch } = clientManager.stackInfo.getStackConfig();

    let configPaths;
    try {
      configPaths = await getConfigurationPaths({
        githubToken,
        branchName: buildBranch,
        clientManager,
      });
    } catch (error) {
      // Fall back to main because it's possible the build branch might not be present
      configPaths = await getConfigurationPaths({
        githubToken,
        branchName: 'main',
        clientManager,
      });
    }

    this.setState({
      configPaths,
      loadingConfigPaths: false,
    });
  }

  public render() {
    const {
      loadWidgetTrees,
      userInfo: { githubToken },
      clientManager,
    } = this.props;

    const { buildBranch, buildId } = clientManager.stackInfo.getStackConfig();

    const {
      configPaths,
      loadingConfigPaths,
      commitMessage,
      loadingConfig,
      savingConfig,
      error,
    } = this.state;

    if (loadingConfigPaths) {
      return <Loading />;
    }

    const headers = ['path', 'branch', 'load', 'save'].map((title) => {
      return (
        <TableCell key={title}>
          <Typography>{title.toString()}</Typography>
        </TableCell>
      );
    });

    // @ts-ignore // ts2531 incorrect null check
    const configRows = configPaths.map((configPath, index) => (
      <LoadSaveRow
        configPath={configPath}
        index={index}
        loadingConfig={loadingConfig}
        savingConfig={savingConfig}
        commitMessage={commitMessage}
        setLoadingConfig={this.setLoadingConfig}
        setSavingConfig={this.setSavingConfig}
        setError={this.setError}
        loadWidgetTrees={loadWidgetTrees}
        githubToken={githubToken}
        key={index}
        buildBranch={buildBranch}
        clientManager={clientManager}
      />
    ));

    const displayError = error ? (
      <Typography>{`Error: ${error}`}</Typography>
    ) : null;

    return (
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <TableContainer sx={{ maxHeight: 800 }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>{headers}</TableRow>
            </TableHead>
            <TableBody>{configRows}</TableBody>
          </Table>
        </TableContainer>
        <CustomTextField
          value={commitMessage}
          onChange={this.updateMessage}
          name="commit message"
          sx={{
            width: '100%',
            maxWidth: '50em',
          }}
        />
        <Typography>{displayError}</Typography>
        <Typography>{`Build: ${buildId} - ${clientManager.schemaManager.getEnvironmentString()}`}</Typography>
      </Box>
    );
  }
}

export default compose(withClient)(LoadSaveConfiguration);
