export function isTesting() {
  return process.env.NODE_ENV === 'test';
}

export function isBrowserUnderTest() {
  // @ts-ignore
  return window.Cypress;
}

export function isDev() {
  return process.env.NODE_ENV === 'development';
}
