import { Icon, IconButton } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import classNames from 'classnames';
import _ from 'lodash';
import { PureComponent } from 'react';
import isEqual from 'react-fast-compare';
import { compose } from 'redux';
import { stagePropertyConfiguration } from 'universal/pipeline/stageTypes/stageInfos';
import { StageType } from 'universal/types';
import { getEnumKeys } from 'universal/utilityFunctions';

import CopyButton from '../atoms/CopyButton';
import CustomButton from '../atoms/CustomButton';
import PasteButton from '../atoms/PasteButton';
import {
  configurationTypeCategories,
  configurationTypes,
} from '../widgets/types';
import {
  canBeChild,
  widgetPropertyConfiguration,
} from '../widgets/widgetTypes';

import ScrollingMenu from './ScrollingMenu';

interface IProps {
  classes;
  children;
  keyPath;
  canEditNode;
  editingNodePath;
  pasteNode;
  copyNode;
  node;
  selectNode;
  addNode;
  moveNode;
  deleteNode;
  getNode;
}

const styles = (theme) => {
  return {
    button: {
      height: 25,
      width: 25,
      marginRight: 2,
      marginLeft: 2,
      padding: '0px',
    },
    selected: {
      color: theme.palette.secondary.main,
    },
    icon: {
      fontSize: 18,
    },
  };
};

class JSONTreeLabelRenderer extends PureComponent<IProps> {
  public declare props;
  public declare state;
  constructor(props) {
    super(props);
    this.handleSelectNode = this.handleSelectNode.bind(this);
    this.handleDeleteNode = this.handleDeleteNode.bind(this);
    this.handleWidgetTypeSelect = this.handleWidgetTypeSelect.bind(this);
    this.handleStageTypeSelect = this.handleStageTypeSelect.bind(this);
    this.handleMoveNode = this.handleMoveNode.bind(this);
    this.doNothing = this.doNothing.bind(this);
    this.handleLabelClick = this.handleLabelClick.bind(this);
    this.isStage = this.isStage.bind(this);
  }

  public handleSelectNode(event) {
    event.stopPropagation();
    const { keyPath, selectNode } = this.props;
    selectNode(keyPath);
  }

  public handleMoveNode(identifier, event) {
    event.stopPropagation();
    const { index, direction } = identifier;
    const { keyPath, moveNode } = this.props;
    moveNode(keyPath, index + direction);
  }

  public handleDeleteNode(event) {
    const { keyPath, deleteNode } = this.props;
    event.stopPropagation();
    deleteNode(keyPath);
  }

  public handleWidgetTypeSelect(type) {
    const { keyPath, addNode } = this.props;
    addNode({
      path: keyPath,
      type,
      propertyConfiguration: widgetPropertyConfiguration,
    });
  }

  public handleStageTypeSelect(stageTypeKey) {
    const { keyPath, addNode } = this.props;
    const stageTypeValue = StageType[stageTypeKey];
    addNode({
      path: keyPath,
      type: stageTypeValue,
      propertyConfiguration: stagePropertyConfiguration,
    });
  }

  public handleLabelClick(event) {
    if (!this.isStage()) {
      event.preventDefault();
    }
  }

  public doNothing(event) {
    event.preventDefault();
    event.stopPropagation();
  }

  public isStage() {
    const { keyPath } = this.props;
    return keyPath[1] === 'stages';
  }

  public render() {
    const {
      classes,
      children,
      keyPath,
      canEditNode,
      editingNodePath,
      pasteNode,
      copyNode,
      node,
      getNode,
    } = this.props;

    let label = children;

    if (typeof keyPath[0] === 'number' && node) {
      label = node.name || node.type;
    }

    if (this.isStage()) {
      label = `${node._name}: ${StageType[node._stageType]}`;
    }

    const pasteButton = (
      <PasteButton
        identifier={{ path: keyPath }}
        className={classes.button}
        component={PasteButton}
        onPaste={pasteNode}
        onClick={(event) => event.stopPropagation()}
      >
        <IconButton className={classes.button} size="large">
          <Icon className={classes.icon}>add_to_photos</Icon>
        </IconButton>
      </PasteButton>
    );

    if (configurationTypeCategories[keyPath[0]]) {
      const parentNode = getNode(keyPath.slice(1));
      const parentTypeName = parentNode.type;

      const category = keyPath[0];
      const widgetOptions = configurationTypes.filter((childTypeName) =>
        canBeChild(parentTypeName, category, childTypeName),
      );

      return (
        <span onClick={this.handleLabelClick}>
          {label}
          <ScrollingMenu
            options={_.sortBy(widgetOptions)}
            onSelect={this.handleWidgetTypeSelect}
          >
            <IconButton className={classes.button} size="large">
              <Icon className={classes.icon}>add_circle</Icon>
            </IconButton>
          </ScrollingMenu>
          {pasteButton}
        </span>
      );
    }

    if (keyPath[0] === 'stages') {
      return (
        <span onClick={this.handleLabelClick}>
          {label}
          <ScrollingMenu
            options={getEnumKeys(StageType)}
            onSelect={this.handleStageTypeSelect}
          >
            <IconButton className={classes.button} size="large">
              <Icon className={classes.icon}>add_circle</Icon>
            </IconButton>
          </ScrollingMenu>
          {pasteButton}
        </span>
      );
    }

    if (canEditNode(keyPath[1])) {
      const deleteButton =
        keyPath[1] === 'events' ? null : (
          <IconButton
            onClick={this.handleDeleteNode}
            className={classes.button}
            size="large"
          >
            <Icon className={classes.icon}>delete</Icon>
          </IconButton>
        );
      const editButton = isEqual(keyPath, editingNodePath) ? (
        <IconButton
          className={classNames(classes.button, classes.selected)}
          onClick={this.doNothing}
          size="large"
        >
          <Icon>arrow_back</Icon>
        </IconButton>
      ) : (
        <IconButton
          onClick={this.handleSelectNode}
          className={classes.button}
          size="large"
        >
          <Icon
            className={classNames(classes.icon, {
              [classes.currentlyEditing]: isEqual(keyPath, editingNodePath),
            })}
          >
            edit
          </Icon>
        </IconButton>
      );
      const copyButton =
        keyPath[1] === 'events' ? null : (
          <CopyButton
            onClick={(event) => event.stopPropagation()}
            getText={copyNode}
            identifier={keyPath}
          >
            <IconButton className={classes.button} size="large">
              <Icon className={classes.icon}>file_copy</Icon>
            </IconButton>
          </CopyButton>
        );

      const moveUpButton = (
        <CustomButton
          style={{
            height: 25,
            width: 25,
            marginRight: 2,
            marginLeft: 2,
            padding: '0px',
          }}
          icon="expand_less"
          onClick={this.handleMoveNode}
          className={classes.button}
          identifier={{ index: keyPath[0], direction: -1 }}
        />
      );

      const moveDownButton = (
        <CustomButton
          style={{
            height: 25,
            width: 25,
            marginRight: 2,
            marginLeft: 2,
            padding: '0px',
          }}
          icon="expand_more"
          onClick={this.handleMoveNode}
          className={classes.button}
          identifier={{ index: keyPath[0], direction: 1 }}
        />
      );

      return (
        <span onClick={this.handleLabelClick}>
          {label}
          {editButton}
          {copyButton}
          {deleteButton}
          {moveUpButton}
          {moveDownButton}
        </span>
      );
    }
    return label || null;
  }
}

export default compose(withStyles(styles, { withTheme: true }))(
  JSONTreeLabelRenderer,
);
