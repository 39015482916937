import GraphiQL from 'graphiql';
import GraphiQLExplorer from 'graphiql-explorer';
import 'graphiql/graphiql.css';
import { buildSchema } from 'graphql';
import { Component } from 'react';
import 'react-virtualized/styles.css';
import { compose } from 'redux';

import { persistUIState } from '../../store/persistUIState';
import { withClient } from '../../wrappers/ClientContext';

class GraphQlContainer extends Component {
  public declare props;
  public declare state;
  public _graphiql;
  constructor(props) {
    super(props);
    this.state = {
      schema: null,
      query: null,
    };

    this.handleEditQuery = this.handleEditQuery.bind(this);
  }

  componentDidMount() {
    const { graphQlQuery, clientManager } = this.props;
    const schemaInfo = clientManager.schemaManager.getSchemaInfo();
    const schema = buildSchema(schemaInfo.schema);
    this.setState({
      schema,
      schemaInfo,
      explorerIsOpen: true,
      query: graphQlQuery,
    });
  }

  handleEditQuery(query) {
    this.setState({ query });
    void persistUIState({ graphQlQuery: query });
  }

  handleToggleExplorer = () => {
    this.setState({ explorerIsOpen: !this.state.explorerIsOpen });
  };

  render() {
    const { query, schema } = this.state;

    const fetcher = async (params) => {
      const result =
        await this.props.clientManager.pipelineManager.executeGraphqlWithParams(
          params,
        );
      return result;
    };

    return (
      <div className="graphiql-container">
        <GraphiQLExplorer
          schema={schema}
          query={query}
          onEdit={this.handleEditQuery}
          onRunOperation={(operationName) =>
            this._graphiql.handleRunQuery(operationName)
          }
          explorerIsOpen={this.state.explorerIsOpen}
          onToggleExplorer={this.handleToggleExplorer}
        />
        <GraphiQL
          ref={(ref) => (this._graphiql = ref)}
          fetcher={fetcher}
          schema={schema}
          query={query}
          onEditQuery={this.handleEditQuery}
        >
          <GraphiQL.Toolbar>
            <GraphiQL.Button
              onClick={() => this._graphiql.handlePrettifyQuery()}
              label="Prettify"
              title="Prettify Query (Shift-Ctrl-P)"
            />
            <GraphiQL.Button
              onClick={() => this._graphiql.handleToggleHistory()}
              label="History"
              title="Show History"
            />
            <GraphiQL.Button
              onClick={this.handleToggleExplorer}
              label="Explorer"
              title="Toggle Explorer"
            />
          </GraphiQL.Toolbar>
        </GraphiQL>
      </div>
    );
  }
}

export default compose(withClient)(GraphQlContainer);
