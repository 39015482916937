import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import IndeterminateCheckBoxOutlinedIcon from '@mui/icons-material/IndeterminateCheckBoxOutlined';
import { TreeItem } from '@mui/lab';
import { LinearProgress, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { ClientManager } from 'universal/clientManager';
import { MetadataSupport } from 'universal/metadataSupport';
import { PipelineManager } from 'universal/pipeline/pipelineManager';

const styles = {};

interface IProps {
  clientManager: ClientManager;
  entityName: string;
  entityId: string;
}

const StyledTreeItem = withStyles(() => ({
  iconContainer: {
    '& .close': {
      opacity: 0.3,
    },
  },
  group: {
    marginLeft: 12,
    paddingLeft: 12,
    borderLeft: '1px dashed',
  },
}))(TreeItem);

class EntityTree extends Component<IProps> {
  public declare props;
  public declare state;
  constructor(props: IProps) {
    super(props);
    this.state = {
      result: null,
      loading: true,
      normalizedTypeDefAttributes: null,
    };
  }

  componentDidMount() {
    void this.queryEntity();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.entityName !== this.props.entityName ||
      prevProps.entityId !== this.props.entityId
    ) {
      void this.queryEntity();
    }
  }

  async queryEntity() {
    const { loading } = this.state;
    if (!loading) {
      this.setState({ loading: true });
    }

    const {
      clientManager: { metadataSupport },
      entityName,
      entityId,
    } = this.props;
    const pipelineManager: PipelineManager =
      this.props.clientmanager.pipelineManager;

    const typeDef =
      metadataSupport.getTypeDefFromUnqualifiedEntityName(entityName);

    const normalizedTypeDefAttributes = typeDef
      .getAttributes()
      .reduce((acc, attribute) => {
        return Object.assign(acc, { [attribute.name]: attribute });
      }, {});

    const query = pipelineManager.buildGetQuery(entityName, entityId);
    const result = await pipelineManager.executeGraphqlWithParams({ query });
    this.setState({ result, normalizedTypeDefAttributes, loading: false });
  }

  buildTreeItemsFromEntity(entity) {
    const { clientManager } = this.props;
    const { normalizedTypeDefAttributes } = this.state;

    return Object.keys(entity).map((key, index) => {
      const value = entity[key];
      const displayKey = Array.isArray(entity)
        ? (typeof value === 'object' &&
            typeof Object.values(value)[0] !== 'object' &&
            Object.values(value)[0]) ||
          key
        : (normalizedTypeDefAttributes[key] &&
            normalizedTypeDefAttributes[key].nameInfo.displayName) ||
          key;

      const associatedEntity =
        normalizedTypeDefAttributes[key] &&
        normalizedTypeDefAttributes[key].itemInfo.associatedEntity;
      if (associatedEntity && value && value.id) {
        const childEntity =
          MetadataSupport.getUnqualifiedName(associatedEntity);
        return (
          <StyledTreeItem
            nodeId={`${displayKey}: ${index}`}
            key={`${displayKey}: ${index}`}
            expandIcon={<AddBoxOutlinedIcon style={{ color: 'blue' }} />}
            collapseIcon={
              <IndeterminateCheckBoxOutlinedIcon style={{ color: 'blue' }} />
            }
            label={
              <div
                style={{ columnCount: 2, borderBottom: '1px solid #E3E2E3' }}
              >
                <Typography>{displayKey}</Typography>
                <Typography
                  component={Link}
                  to={`/data/${childEntity}/${value.id}`}
                  style={{
                    textDecoration: 'none',
                    color: 'blue',
                  }}
                  onClick={(event) => {
                    // event.preventDefault();
                    event.stopPropagation();
                    console.log('events prevented');
                  }}
                >
                  {value.id}
                </Typography>
              </div>
            }
            style={{
              borderLeft: '1px dashed blue',
            }}
          >
            <EntityTree
              clientManager={clientManager}
              entityName={childEntity}
              entityId={value.id}
            />
          </StyledTreeItem>
        );
      }

      if (value && typeof value === 'object') {
        return (
          <StyledTreeItem
            nodeId={`${displayKey}: ${index}`}
            key={`${displayKey}: ${index}`}
            label={
              <Typography style={{ borderBottom: '1px solid #E3E2E3' }}>
                {displayKey}
              </Typography>
            }
          >
            {this.buildTreeItemsFromEntity(value)}
          </StyledTreeItem>
        );
      }
      return (
        <StyledTreeItem
          nodeId={`${displayKey}-${value}-${index}`}
          key={`${displayKey}: ${index}`}
          label={
            <div
              style={{
                columnCount: 2,
                borderBottom: '1px solid #E3E2E3',
                paddingLeft: '26px',
              }}
            >
              <Typography>{displayKey}</Typography>
              <Typography>{value}</Typography>
            </div>
          }
        />
      );
    });
  }

  render() {
    const { result, loading } = this.state;
    if (!result || loading) {
      return <LinearProgress />;
    }

    return (
      <React.Fragment>{this.buildTreeItemsFromEntity(result)}</React.Fragment>
    );
  }
}

export default withStyles(styles, { withTheme: true })(EntityTree);
