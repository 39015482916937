import { AwsCredentialIdentity } from '@aws-sdk/types';
import seedrandom from 'seedrandom';

import { DEV_COMPANY_DOMAIN } from './commonConstants';

export interface IAWSConfig {
  region?: string;
  maxAttempts?: number;
  requestHandler?: any;
  credentials?: AwsCredentialIdentity;
}

export function getAwsBaseConfig(): IAWSConfig {
  return {
    // We do our own
    maxAttempts: 0,
  };
}

export function getInternalStackDomain(stackId: string) {
  return `${stackId}.${DEV_COMPANY_DOMAIN}`;
}

export function randomString(digits = 10) {
  if (digits > 16) {
    return randomString(16).concat(randomString(digits - 16));
  }

  return Math.random().toString().slice(-digits);
}

export function getRandom(seed) {
  return seedrandom(seed);
}

export function randBetween(params: {
  rng: any;
  min: number;
  max: number;
}): number {
  const { rng, min, max } = params;
  return min + Math.floor(rng() * (max + 1 - min));
}

export function exists(input: any) {
  if (input !== undefined && input !== null) {
    return true;
  }
  return false;
}

// The horror: https://stackoverflow.com/questions/10265208/node-js-process-env-assigning-process-env-property-to-undefined-results-in-stri
export function copyToProcessEnv(vars) {
  Object.assign(process.env, vars);
  Object.keys(vars).forEach((k) => {
    if (vars[k] === undefined) {
      delete process.env[k];
    }
  });
}

export function getLambdaFunctionFullName(
  stackId: string,
  functionName: string,
) {
  return `${stackId}-${functionName}`;
}
