import { configureStore } from '@reduxjs/toolkit';

import dataReducer, { STATE_DATA } from './data';
import uiStateReducer from './uiState';

export const store = configureStore({
  reducer: {
    [STATE_DATA]: dataReducer,
    uiState: uiStateReducer,
  },
  middleware: [],
});

const windowAny = window as any;
if (windowAny.Cypress) {
  windowAny.store = store;
}

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
