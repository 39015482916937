import { ApolloLink, NextLink } from '@apollo/client/core/index.js';
import { PipelineManager } from '../pipeline/pipelineManager';

export class DynamicTransportLink extends ApolloLink {
  private readonly localLink: ApolloLink;
  private readonly lambdaLink: ApolloLink;

  constructor(lambdaLink?: ApolloLink, localLink?: ApolloLink) {
    super();
    this.lambdaLink = lambdaLink;
    this.localLink = localLink;
  }

  public request(operation, forward?: NextLink) {
    const context = PipelineManager.getRemoteContextFromHeaders(
      operation.getContext().headers,
    );
    if ((context?.useLocalLink && this.localLink) || !this.lambdaLink) {
      return this.localLink.request(operation, forward);
    }
    return this.lambdaLink.request(operation, forward);
  }
}
