import React from 'react';
import { StackInfo } from 'universal/stackInfo';

// This is only used by the Client wrapper
export interface IAuthenticatorContext {
  // Use this instead of the one from useAuth0 so the returnTo is set correctly
  logout?: () => void;

  // Use this instead of auth0 to that the testing case is handled
  isAuthenticated?: () => boolean;

  getIdToken?: () => Promise<string>;

  // Even though StackInfo is in the ClientManager, we don't have a ClientManager
  // at this point, and the StackInfo is required to get through authentication.
  // This same StackInfo object is used later to create the ClientManager to
  // avoid reading it from the network twice.
  stackInfo: StackInfo;

  history: any;
}

export const AuthenticatorContext = React.createContext<IAuthenticatorContext>(
  {} as IAuthenticatorContext,
);
