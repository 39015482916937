import { AppBar, Icon, Tabs as MaterialTabs, Tab } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { compose } from 'redux';

import { withActions } from '../../widgetEngine/ActionEnabler';
import Container from '../../widgetEngine/Container';
import Widget from '../../widgetEngine/Widget';

const styles = () => {
  return {
    wrapper: {
      width: '100%',
      height: '100%',
    },
    inner: {
      width: '100%',
      height: '100%',
    },
  };
};

class Tabs extends Container {
  public declare props;
  public declare state;
  constructor(props) {
    super(props);
    this.state = {
      value: null,
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    const ChildPreviews = this.previewChildren();
    const defaultValue = ChildPreviews.findIndex(
      (ChildPreview) => ChildPreview.properties.defaultTab,
    );
    if (defaultValue !== -1) {
      this.setState({ value: defaultValue });
    }
  }

  handleChange(event, value) {
    this.setState({ value });
  }

  render() {
    const { classes, indicatorColor, textColor, aliases } = this.props;
    const { value } = this.state;
    const ChildPreviews = this.previewChildren();
    const RenderTabs = ChildPreviews.map((ChildPreview, index) => {
      const { metaComponent, properties } = ChildPreview;
      const { tabName = index, tabIcon, disabled, tabColor } = properties;
      const key = `${metaComponent.component.widgetId}tab${tabName}`;

      const StyledTab = withStyles({
        root: {
          ...(tabColor && { backgroundColor: tabColor }),
        },
      })(Tab);

      return (
        <StyledTab
          key={key}
          value={index}
          {...(!tabIcon && { label: tabName })}
          {...(tabIcon && { icon: <Icon>{tabIcon}</Icon> })}
          disabled={disabled}
        />
      );
    });

    return (
      <div className={classes.wrapper}>
        <AppBar position="static" color="default">
          <MaterialTabs
            onChange={this.handleChange}
            value={value}
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            {...(indicatorColor && { indicatorColor })}
            {...(textColor && { textColor })}
          >
            {RenderTabs}
          </MaterialTabs>
        </AppBar>
        <div className={classes.inner}>
          {ChildPreviews[value] && (
            <Widget
              {...ChildPreviews[value].metaComponent}
              aliases={aliases}
              key={value}
            />
          )}
        </div>
      </div>
    );
  }
}

export default compose(
  withActions,
  withStyles(styles, { withTheme: true }),
)(Tabs);
