import { FunctionComponent, useContext, useEffect, useState } from 'react';
import { IApplication } from 'universal/applicationManager';

import { persistUIState } from '../../store/persistUIState';
import { ApplicationContext } from '../../wrappers/ApplicationContext';
import { ClientContext } from '../../wrappers/ClientContext';
import CustomSelect from '../atoms/CustomSelect';

const ApplicationSelect: FunctionComponent<
  React.PropsWithChildren<unknown>
> = () => {
  const { clientManager, userInfo } = useContext(ClientContext);
  const { application } = useContext(ApplicationContext);
  const [localApplication, setLocalApplication] = useState(
    application.nameInfo.displayName,
  );

  const { applicationManager } = clientManager;

  const availableApplications =
    applicationManager.getAvailableApplications(userInfo);

  useEffect(() => {
    if (localApplication !== application.nameInfo.displayName) {
      const newApplication = availableApplications.find(
        (a) => a.nameInfo.displayName === localApplication,
      );
      if (!newApplication) {
        return;
      }
      // This causes the ApplicationContext to change thanks to a callback
      // from the ApplicationManager
      applicationManager.setApplication({
        applicationId: newApplication.id,
        userInfo,
      });
      void persistUIState({
        applicationId: newApplication.id,
      });
    }
  }, [
    application.nameInfo.displayName,
    applicationManager,
    availableApplications,
    localApplication,
    userInfo,
  ]);

  if (availableApplications.length <= 1) {
    return null;
  }

  function handleChangeApplicationId(app: IApplication) {
    setLocalApplication(app.nameInfo.displayName);
  }

  return (
    <CustomSelect
      name="Application"
      options={availableApplications}
      optionLabelField={'nameInfo.displayName'}
      value={application}
      onChange={handleChangeApplicationId}
    />
  );
};

export default ApplicationSelect;
