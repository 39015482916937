import _ from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { compose } from 'redux';

import { withUiState } from '../../../store/withUiState';
import { withActions } from '../dependencies';
import { UniversalWidgetProps } from '../types';

import { MultiSelectProps } from './MultiSelectTypeInfo';
import ReactMultiSelect, { Option } from './ReactMultiSelect';

type IProps = MultiSelectProps & UniversalWidgetProps;

const MultiSelect: React.FC<React.PropsWithChildren<IProps>> = (props) => {
  const {
    initialValue,
    persistValue,
    options,
    selectAll,
    optionData,
    optionLabel,
    width,
    label,
    updateFromWidget,
    // @ts-ignore
    data,
  } = props;
  const initialized = useRef(false);
  const [optionSelected, setSelected] = useState<Option[] | null>();
  const [optionsToUse, setOptionsToUse] = useState<Option[] | null>();
  const [updateParams, setUpdateParams] = useState<{
    data: any;
    initialUpdate?: boolean;
  }>(undefined);
  useEffect(() => {
    if (updateParams) {
      const newData = {
        data: updateParams.data?.map((v) => v.value),
        initialUpdate: updateParams.initialUpdate,
      };
      updateFromWidget(newData);
      setUpdateParams(null);
    }
  }, [updateFromWidget, updateParams]);

  function getOptionsStuff(dataValue, opts): any[] {
    return opts.filter((o) => dataValue.includes(o.value)) || [];
  }

  //  useMemo(() => {
  if (!initialized.current) {
    initialized.current = true;
    const opts = options?.map((o) => {
      return {
        label: _.get(o, optionLabel),
        value: _.get(o, optionData),
      };
    });
    let currentValues = [];
    if (persistValue?.length > 0) {
      currentValues = getOptionsStuff(persistValue, opts);
      if (currentValues.length > 0) {
        setUpdateParams({
          data: currentValues,
          initialUpdate: true,
        });
      }
    } else if (![undefined, '', null].includes(initialValue)) {
      currentValues = getOptionsStuff(initialValue, opts);
      if (currentValues.length > 0) {
        setUpdateParams({
          data: currentValues,
          initialUpdate: true,
        });
      }
    } else if (![undefined, '', null].includes(data)) {
      currentValues = getOptionsStuff(data, opts);
      setUpdateParams({
        data: currentValues,
        initialUpdate: true,
      });
    } else {
      setUpdateParams({
        data: null,
        initialUpdate: true,
      });
    }
    setSelected(currentValues);
    setOptionsToUse(opts || []);
  }

  const setChange = (selected: Option[]) => {
    setSelected(selected);
    setUpdateParams({
      data: selected,
      initialUpdate: false,
    });
  };
  return (
    <div
      style={{
        width: width,
        fontFamily: 'Roboto, Helvetica, Arial',
        color: 'rgba(0,0,0,0.6)',
        fontSize: '12px',
      }}
    >
      <div
        style={{
          width: width,
          fontFamily: 'Roboto, Helvetica, Arial',
          color: 'rgb(128,128,128)',
          backgroundColor: '#ffffff',
          fontSize: '12px',
        }}
      >
        {label}
      </div>
      <ReactMultiSelect
        key="example_id"
        options={optionsToUse}
        onChange={setChange}
        value={optionSelected}
        isSelectAll={selectAll}
      />
    </div>
  );
};

export default compose(withActions, withUiState)(MultiSelect);
