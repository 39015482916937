import { BasicType } from 'universal/types';

import { IConfigurationType, IWidgetProps, typeDefProperties } from '../types';

const agGridProperties = {
  ...typeDefProperties,
  rows: {
    description: 'pointer to the row data',
    pointer: 'must',
  },
  groupData: {
    description:
      'Pointer to group row data - used if there is a predefined set of groups. Records must contain the group: (the group field name) and then members containing value: (the specific group value),' +
      'fields which specifies the parents (all levels) of this group object (if the groups are nested) is an object that has {parent: value} for each level of the hierarchy ' +
      'optionally noDrag: boolean, if the the group column is specified as draggable but this specific group object is not.',
  },
  maxRowsForAutoHeight: {
    description:
      'Numeric value that sets the maximum number of rows for the grid to use it auto-height feature ',
    default: 10,
    type: BasicType.Int,
  },
  showSidebar: {
    description:
      'If true, will show the sidebar with filters and column selection',
    default: true,
    type: BasicType.Boolean,
  },
  showHeader: {
    description: 'If true, will show header row',
    default: true,
    type: BasicType.Boolean,
  },
  showGroupPanel: {
    description:
      'Will display group panel, only columns with enableRowGroup set can be dragged to group panel',
    default: false,
    type: BasicType.Boolean,
  },
  groupDisplayType: {
    description:
      'Way to display groups, ignored if no groups specified in typedef',
    options: ["'singleColumn'", "'multipleColumns'", "'groupRows'"],
  },
  groupTotalRow: {
    description: 'Way to display a total row for each group',
    options: ["'top'", "'bottom'"],
  },
  columnSizing: {
    description:
      'SizeColumnsToFit will set the total grid width to the viewport and size columns proportionally, autoSizeColumns will size each column to display the widest visible data ',
    options: ["'sizeColumnsToFit'", "'autoSizeColumns'"],
    type: BasicType.String,
    default: "'autoSizeColumns'",
  },
  columnHeaderStyle: {
    description: 'Set a specific column header style',
    options: ["'standard'", "'dark'"],
    default: "'standard'",
  },
  sortable: {
    description: 'Allow column sort on grid',
    type: BasicType.Boolean,
    default: true,
  },
  topRowButtons: {
    description:
      'An array of buttons or widgets to include in the top row of the grid, format for each object is {name: string params?: {}}' +
      ' name can either be a widget from the pool, or a standardized button: _excel, _showHideGroups. parameters are specific to the button, for example,' +
      '_excel takes a filename in params, widgets will pass their own props ',
  },
  hideTopRowButtons: {
    description: 'If true, topRowButtons will not show',
    type: BasicType.Boolean,
    options: [true, false],
    default: false,
  },
  showColumnMenu: {
    description:
      'Has a clickable column menu (show/filter/pin/etc.), May want to suppress this for grids where compact columns are desirable',
    type: BasicType.Boolean,
    default: true,
  },
  allowPivots: {
    description: 'If false, the pivot toolbar will be disabled',
    type: BasicType.Boolean,
    options: [true, false],
    default: true,
  },
  suppressPersistFilters: {
    description: 'If true, filters are not included in persistent state',
    type: BasicType.Boolean,
    options: [true, false],
    default: true,
  },
  suppressPersistGroups: {
    description: 'If true, groups are not included in persistent state',
    type: BasicType.Boolean,
    options: [true, false],
    default: false,
  },
  suppressPersistColumns: {
    description:
      'If true, columns (widths/show or hide) are not included in persistent state',
    type: BasicType.Boolean,
    options: [true, false],
    default: false,
  },
  suppressContextMenu: {
    description: 'If true, context menu will be disabled',
    type: BasicType.Boolean,
    options: [true, false],
    default: false,
  },
  collapseGroups: {
    description:
      'If true, groups will initialized as collapsed (unless persisted)',
    type: BasicType.Boolean,
    options: [true, false],
    default: false,
  },
  rowHeight: {
    description:
      'Should only be required if there are widgets in cell that do not fit in standard row height',
    type: BasicType.Int,
  },
  title: {
    description: 'If present, a title will be displayed at the top of the grid',
    type: BasicType.String,
  },
  subtitle: {
    description:
      'If present, a subtitle will be displayed at the top of the grid',
    type: BasicType.String,
  },
  rowSelectionKey: {
    description:
      'If present, selected rows will be saved to this storage location whenever row selection changes in the grid',
    type: BasicType.String,
  },
  noRegister: {
    description:
      'If true, will not register grid in register widget (used for drag and drop), causes issues if the same widget appears twice on a page',
    type: BasicType.Boolean,
  },
  verticalGridLines: {
    description: 'If true will add vertical lines to the grid',
    type: BasicType.Boolean,
  },
  headerHeight: {
    description:
      'If you want to manually specify the height in pixels of the header (normally is auto calculated) ',
    type: BasicType.Int,
  },
} as const;

export type AGGridProps = IWidgetProps<typeof agGridProperties>;
export const agGridTypeInfo: IConfigurationType = {
  types: {
    children: true,
  },
  childTypes: {
    widgetPool: true,
  },
  properties: agGridProperties,
  childProperties: {},
  events: {
    load: [],
  },
};
