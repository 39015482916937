import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Icon,
  Typography,
} from '@mui/material';

import { generateSimpleChildren, withActions } from '../dependencies';

import { CollapseProps, collapseTypeInfo } from './CollapseTypeInfo';
export { collapseTypeInfo };

const CollapseContainer: React.FC<React.PropsWithChildren<CollapseProps>> = (
  props,
) => {
  const { headerText, defaultExpanded, headerVariant } = props;

  const Children = generateSimpleChildren(props);

  return (
    <div {...props.elementAttributes}>
      <Accordion defaultExpanded={defaultExpanded}>
        <AccordionSummary expandIcon={<Icon>expand_more</Icon>}>
          <Typography variant={headerVariant}>{headerText}</Typography>
        </AccordionSummary>
        <AccordionDetails>{Children}</AccordionDetails>
      </Accordion>
    </div>
  );
};

export default withActions(CollapseContainer);
