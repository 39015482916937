import { Icon, Typography } from '@mui/material';
import { Component } from 'react';
import {
  IPropertyConfiguration,
  PathArray,
  PropertyConfigurationHandler,
} from 'universal/propertySupport';

import CustomTextField from '../atoms/CustomTextField';

import PropertyEditor from './PropertyEditor';

interface IProps {
  updateField;
  propertyConfiguration: IPropertyConfiguration;
  node;
  pathInWidgetTree: PathArray;
  parentType?: string;
}

class PropertiesEditor extends Component<IProps> {
  public propertyConfigurationHandler: PropertyConfigurationHandler;

  public render() {
    const {
      updateField,
      propertyConfiguration,
      node,
      pathInWidgetTree,
      parentType,
    } = this.props;

    const propertyConfigurationHandler = new PropertyConfigurationHandler({
      propertyConfiguration,
      node,
      pathInWidgetTree,
      parentType,
    });

    const propertyEdits = propertyConfigurationHandler
      .getEditablePropertyNames()
      .map((propertyName) => {
        const propertyInfo =
          propertyConfigurationHandler.getPropertyInfo(propertyName);
        const propertyPathInWidgetTree =
          propertyConfigurationHandler.getPropertyPathInWidgetTree(
            propertyName,
          );
        const value =
          propertyConfigurationHandler.getPropertyValue(propertyName);
        const propertyDisplayName =
          propertyConfigurationHandler.getPropertyDisplayName(propertyName);
        return (
          <PropertyEditor
            updateField={updateField}
            propertyConfigurationHandler={propertyConfigurationHandler}
            propertyName={propertyName}
            key={propertyName}
            propertyInfo={propertyInfo}
            pathInWidgetTree={propertyPathInWidgetTree}
            value={value}
            propertyDisplayName={propertyDisplayName}
          />
        );
      });

    const name = propertyConfigurationHandler.getName();
    const namePathInWidgetTree =
      propertyConfigurationHandler.getNamePathInWidgetTree();
    const typePathInWidgetTree =
      propertyConfigurationHandler.getTypePathInWidgetTree();
    const type = propertyConfigurationHandler.getTypeName();

    return (
      <div>
        <span style={{ display: 'flex', height: '100%' }}>
          <CustomTextField
            sx={{ margin: 1, width: 400 }}
            onChange={updateField}
            identifier={namePathInWidgetTree}
            value={name}
            name="Name"
            key={namePathInWidgetTree.join()}
          />
          <CustomTextField
            sx={{ margin: 1, width: 400 }}
            value={type}
            name="Type"
            identifier={typePathInWidgetTree}
          />
          <Typography style={{ flexBasis: '35%' }}>
            <Icon>fiber_manual_record</Icon> = required field
          </Typography>
        </span>
        <div
          style={{
            display: 'flex',
            // FIXME - this is not right, but I can't figure out how to do this with
            // a relative height
            height: '1000px',
            flexDirection: 'column',
            overflow: 'auto',
          }}
        >
          {propertyEdits}
        </div>
      </div>
    );
  }
}

export default PropertiesEditor;
