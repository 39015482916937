import { v1 as uuid } from 'uuid';
import { ClientManager } from './clientManager';
import { S3Support } from './common/s3Support';
import { getLogger, Loggers } from './loggerSupport';
import { sleep } from './utilityFunctions';

const logger = getLogger({ name: Loggers.WAIT_NOTIFY });

const WAIT_NOTIFY = 'waitnotify';

// FIXME - this will not work in the browser currently because the s3 permissions are not setup correctly
// to use this; we would have to change the client role to be specific about creating objects with
// these keys.
export class WaitNotify {
  private s3Support: S3Support;

  public initialize(clientManager: ClientManager) {
    this.s3Support = clientManager.s3Support;
  }

  public getWaitToken(): string {
    return uuid();
  }

  public async wait(token: string) {
    const { bucket, key } = this.s3Support.getS3BucketAndKey({
      purpose: WAIT_NOTIFY,
      id: token,
    });

    while (
      !(await this.s3Support.headObject({
        s3Path: bucket + '/' + key,
      }))
    ) {
      logger.debug(`Waiting - token: ${token}`);
      await sleep(2000);
    }

    logger.debug(`Wait ending - token: ${token}`);
    await this.s3Support.deleteObject({
      Bucket: bucket,
      Key: key,
    });
  }

  public async notify(token: string) {
    const { bucket, key } = this.s3Support.getS3BucketAndKey({
      purpose: WAIT_NOTIFY,
      id: token,
    });

    await this.s3Support.putObject({
      Bucket: bucket,
      Key: key,
      Body: 'doesnotmatter',
    });
    logger.debug(`Notified - token: ${token}`);
  }
}
