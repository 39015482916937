import { ICON_DESCRIPTION } from '../types';

export const iconPropertyInfo = {
  icon: {
    description: ICON_DESCRIPTION,
  },
} as const;

export const iconTypeInfo = {
  types: {
    children: true,
  },
  properties: iconPropertyInfo,
  events: {},
  childTypes: {},
};
