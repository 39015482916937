/*
 Handles stack information, which is put into a zip file.

 This supports updates and refreshes.

 This cannot depend on the ClientManager (or anything above that), as the ClientManager requires this to start.
 */

//
// WARNING
// WARNING Everything in the StackInfo is public, it's put in a public S3 bucket so the browser
// WARNING knows what it needs. DO NOT put anything secret in StackInfo or any of its contained objects
// WARNING
//

import { S3 } from '@aws-sdk/client-s3';
import axios from 'axios';
import { isBrowser } from 'browser-or-node';
import JSZip from 'jszip';
import safeJsonStringify from 'safe-json-stringify';
import {
  COMPANY_NAME,
  getConfigTableName,
  getTestTableName,
} from './common/commonConstants';
import { IAWSConfig, getAwsBaseConfig } from './common/commonUtilities';
import { S3Support } from './common/s3Support';
import { reThrow } from './errors/errorLog';
import { retry } from './errors/retry';
import { Loggers, getLogger } from './loggerSupport';
import {
  DATA_DEF_ENTITY_TYPE,
  DATA_DEF_TYPE_DEFINITION,
  ISystemConfig,
} from './metadataSupportConstants';
import { UseTestData } from './schemaManager';
import { sleep } from './utilityFunctions';

const logger = getLogger({ name: Loggers.STACK_INFO });

const BROWSER_PREFIX_URL = isBrowser
  ? `${location?.protocol}//${location?.host}/`
  : undefined;

// devsetup/createStack needs to know about these
export const STACKINFO = 'stackInfo.zip';
export const BASE_STACK_INFO = 'stackInfo.json';

const REFRESH_TIME_MS = 30000;

export interface IBaseStackInfo {
  stackId: string;
  stackType: string;
  region: string;
  incarnation: number;
}

export interface ITestLambda {
  [functionName: string]: boolean;
}

// WARNING - this is all public, no secrets allowed here
export interface IStackConfig {
  // Immutable
  awsAccountId?: string;
  product?: string;
  appDatabaseSuffix?: string;
  availableExecConfigNames?: string[];
  otherConfigNames?: string[];
  apiGatewayBaseUrl?: string;
  mqttEndpoint?: string;
  graphqlTopic?: string;
  auth0Domain?: string;
  auth0ClientId?: string;
  buildBranch?: string;
  buildId?: string;
  githubToken?: string;
  kendoUILicense?: string;
  agGridLicense?: string;
  agChartsLicense?: string;
  fusionChartsLicense?: string;
  stackType?: string;
  systemConfigs?: { [configName: string]: ISystemConfig };

  // Mutable
  useTestLambda?: ITestLambda;
  useTestData?: UseTestData;
  localTestEndpointUrl?: string;
  localTestApiGatewayUrl?: string;
  executionConfigName?: string;
  ingestConcurrency?: number;
  chunkGetConcurrency?: number;
  adjustIngestConcurrency?: boolean;
  concurrencyUpdateIntervalMs?: number;
  concurrencyUpdateIncrement?: number;
  numberOfSortKeysByConfig?: { [configName: string]: number };
  databaseOverride?: string;
  downForMaintenance?: boolean;
  maintenanceMessage?: string;
}

interface IStackInfoObjects {
  [key: string]: any;
}

export const StackInfoKeys = {
  BASE_STACK_INFO: 'base',
  STACK_CONFIG: 'stackConfig',
  SCHEMA: 'schema',
  RESOLVERS: 'resolvers',
  ENTITYIDTOCONFIGNAME: 'entityIdToConfigName',
  TYPEDEFIDTOCONFIGNAME: 'typeDefIdToConfigName',
  TYPEDEFS_THAT_ARE_BOTH: 'typeDefsThatAreBoth',
  LAMBDAFUNCTIONS: 'lambdaFunctions',
  ENTITY_TYPE: DATA_DEF_ENTITY_TYPE,
  TYPE_DEFINITION: DATA_DEF_TYPE_DEFINITION,
  APP_DEFS: 'appDefs',
};

export function createStackInfo(isNode?: boolean): StackInfo {
  return new StackInfo(isNode);
}

export class StackInfo {
  private readonly isNode: boolean;

  // This is set once there is a ClientManager
  public awsConfig: IAWSConfig;

  // Force is to be the node environment (used for client testing)
  constructor(isNode?: boolean) {
    this.isNode = isNode;
  }

  // The objects read as part of the stack information
  public objects: IStackInfoObjects = {};
  public baseStackInfo: IBaseStackInfo;

  private initialized: boolean;

  private lastRetrievedTime: number;

  private isBrowser(): boolean {
    return isBrowser && !this.isNode;
  }

  // The way to initialize this, should be called before anything is done
  public async readFromStack(params?: {
    force?: boolean;
    noThrow?: boolean;
    baseStackInfo?: IBaseStackInfo;
  }): Promise<StackInfo> {
    const { force, noThrow, baseStackInfo } = params || {};
    if (this.initialized && !force) {
      return;
    }

    try {
      const arrayBuffer = await this.readStackInfoFile(force, baseStackInfo);
      const zip = new JSZip();
      await zip.loadAsync(arrayBuffer);
      const promises = [];
      Object.keys(StackInfoKeys).forEach((k) => {
        const stackInfoId = StackInfoKeys[k];
        if (!zip.files[stackInfoId]) {
          return;
        }
        promises.push(
          zip
            .file(stackInfoId)
            .async('text')
            .then((data) => (this.objects[stackInfoId] = JSON.parse(data))),
        );
      });
      await Promise.all(promises);

      this.initialized = true;
      this.lastRetrievedTime = Date.now();
      this.baseStackInfo = this.getObject(StackInfoKeys.BASE_STACK_INFO);
    } catch (error) {
      if (noThrow) {
        return;
      }
      reThrow({
        logger,
        error,
        logObject: this.baseStackInfo,
        message: `Problem reading StackInfo - this could mean the stack is not present, or if you are developer the wrong STACK_ID is set in the ${COMPANY_NAME}-dev file, or yarn devsetup was not run`,
      });
    }
  }

  private async readStackInfoFile(
    force?: boolean,
    baseStackInfo?: IBaseStackInfo,
  ): Promise<ArrayBuffer> {
    let requestInit;
    if (!this.baseStackInfo) {
      if (baseStackInfo) {
        this.baseStackInfo = baseStackInfo;
      } else if (this.isBrowser()) {
        requestInit = force ? { cache: 'reload' } : undefined;
        try {
          const url = `${BROWSER_PREFIX_URL}${STACKINFO}`;
          // Try the main file first, as in a normal environment this should be there
          const mainFile = await retry({
            command: () =>
              fetch(url + `?timestamp=${new Date().getTime()}`, requestInit),
          });
          if (!mainFile.ok) {
            throw new Error(`Fetch ${url} failed`);
          }
          const arrayBuffer = await mainFile.arrayBuffer();
          // Sigh, using the fetch() with the webpack devServer helpfully? returns a 200 with some HTML
          // explaining the file is not there. We expect our zip file to be well over 10K, so we have to
          // check for that to see if it's valid.
          if (arrayBuffer.byteLength > 10000) {
            logger.info(`Read ${url} using fetch`);
            return arrayBuffer;
          }
          throw new Error(
            `Fetch ${url} returned unhelpful devServer result of ${arrayBuffer.byteLength}`,
          );
        } catch (error) {
          // This is in the yarn start environment, the BASE_STACK_INFO will be there
          const baseUrl = `${BROWSER_PREFIX_URL}${BASE_STACK_INFO}`;
          try {
            const baseFile = await retry({
              command: () => fetch(baseUrl, requestInit),
            });
            const text = await baseFile.text();
            this.baseStackInfo = JSON.parse(text);
            // If not forced, the browser will return the cached version
            force = true;
            // Fall through to read main file
          } catch (error2) {
            throw new Error(
              `Cannot find ${baseUrl} in browser. If in development, make sure you ran 'yarn devsetup'`,
            );
          }
        }
      } else {
        this.baseStackInfo = await this.readBaseStackInfo();
      }
    }

    const s3Url = S3Support.getS3StackBucketUrl(
      this.baseStackInfo.stackId,
      this.baseStackInfo.region,
      STACKINFO,
    );

    // Adding the timestamp is a hack to force the browser not to use the disk cache
    // https://stackoverflow.com/questions/49263559/using-javascript-axios-fetch-can-you-disable-browser-cache
    const urlToRead = force
      ? s3Url + `?timestamp=${new Date().getTime()}`
      : s3Url;
    const response = await retry({
      command: () =>
        axios.get(urlToRead, {
          responseType: 'arraybuffer',
        }),
    });

    if (false) {
      logger.info(`Read ${STACKINFO} from ${s3Url}`);
    }
    return response.data;
  }

  public initializeBaseStackInfo() {
    this.baseStackInfo = {
      stackId: process.env.STACK_ID,
      stackType: process.env.STACK_TYPE,
      region: process.env.AWS_REGION,
      incarnation: 0,
    };
  }

  public async readBaseStackInfo(): Promise<IBaseStackInfo> {
    if (this.isBrowser()) {
      throw new Error(
        'Cannot readBaseStackInfo from the browser using this method',
      );
    }

    const s3Url = S3Support.getS3StackBucketUrl(
      process.env.STACK_ID,
      process.env.AWS_REGION,
      BASE_STACK_INFO,
    );

    try {
      const baseText = await retry({
        command: () =>
          axios.get(s3Url, {
            responseType: 'json',
          }),
      });
      return baseText.data;
    } catch (error) {
      reThrow({
        logger,
        error,
        message: `Problem reading base stackInfo at: ${s3Url}`,
      });
    }
  }

  public async refreshStackInfo(
    force?: boolean,
    expectedIncarnation?: number,
  ): Promise<StackInfo> {
    if (Date.now() - this.lastRetrievedTime < REFRESH_TIME_MS && !force) {
      return this;
    }

    while (true) {
      await this.readFromStack({ force: true });
      if (
        expectedIncarnation === undefined ||
        this.baseStackInfo.incarnation >= expectedIncarnation
      ) {
        break;
      }
      logger.info(
        `refreshStackInfo - Waiting for incarnation ${expectedIncarnation} - got ${this.baseStackInfo.incarnation}`,
      );
      await sleep(2000);
    }
    logger.info(
      `refreshStackInfo (force: ${force}) got incarnation ${this.baseStackInfo.incarnation}`,
    );
    return this;
  }

  public async updateStackInfo(force?: boolean) {
    if (this.isBrowser()) {
      throw new Error('Cannot update StackInfo from the browser');
    }
    const savedIncarnation = this.baseStackInfo.incarnation;
    await this.readBaseStackInfo();
    if (!force && savedIncarnation !== this.baseStackInfo.incarnation) {
      throw new Error(
        `Cannot update StackInfo, requested incarnation: ${savedIncarnation}, found: ${this.baseStackInfo.incarnation}`,
      );
    }
    // There is still a very small window here, but the incarnation stuff is only approximate to avoid
    // too many fast updates
    this.baseStackInfo.incarnation++;
    await this.writeStackInfo();
  }

  /**
   * Before the ClientManager is available, this needs enough configuration to
   * be able to read the S3 objects, which essentially means the base config +
   * region. Once the ClientManager is up, the actual awsConfig is set so that
   * writing is possible.
   */
  public getAwsConfig(): IAWSConfig | any {
    return (
      this.awsConfig || {
        ...getAwsBaseConfig(),
        region: this.baseStackInfo?.region || undefined,
      }
    );
  }

  public getStackId(): string {
    return this.baseStackInfo.stackId;
  }

  public getStackType(): string {
    return this.baseStackInfo.stackType;
  }

  public getConfigTableName() {
    return getConfigTableName(this.getStackId());
  }

  public getTestTableName() {
    return getTestTableName(this.getStackId());
  }

  public isInitialized(): boolean {
    return this.initialized;
  }

  public getObject(objectName: string): any {
    if (!this.initialized) {
      throw new Error('StackInfo was not initialized');
    }
    if (!this.objects[objectName]) {
      throw new Error(`${objectName} does not exist in stack info`);
    }
    return this.objects[objectName];
  }

  public getStackConfig(): IStackConfig {
    return this.getObject(StackInfoKeys.STACK_CONFIG);
  }

  // WARNING - this object can only contain public information, no secrets
  public addOutputObject(objectName: string, content: any) {
    this.initialized = true;
    this.objects[objectName] = content;
  }

  private async makeStackInfoZip(): Promise<any> {
    const zip = new JSZip();

    Object.keys(this.objects).forEach((k) => {
      // Make it pretty so it's easy to read
      const json =
        k === StackInfoKeys.STACK_CONFIG
          ? safeJsonStringify(this.objects[k], null, 2)
          : safeJsonStringify(this.objects[k]);
      zip.file(k, json);
    });
    return zip.generateAsync({ type: 'array' });
  }

  public async writeStackInfo() {
    if (!this.baseStackInfo) {
      // Happens when this is a new StackInfo, when the stack is being created
      this.initializeBaseStackInfo();
    }

    const { stackId } = this.baseStackInfo;

    this.addOutputObject(StackInfoKeys.BASE_STACK_INFO, this.baseStackInfo);

    const s3Client = new S3(this.getAwsConfig());

    const zipBytes = await this.makeStackInfoZip();

    logger.info(
      `Writing StackInfo and BaseStackInfo to S3 - incarnation ${this.baseStackInfo.incarnation}`,
    );

    // StackInfo zip file
    const params = {
      Body: Buffer.from(zipBytes),
      Bucket: S3Support.getS3StackBucketNameWithStackId(stackId),
      Key: STACKINFO,
    };
    await retry({
      command: () => s3Client.putObject(params),
    });

    // Base stack info
    params.Body = Buffer.from(JSON.stringify(this.baseStackInfo));
    params.Key = BASE_STACK_INFO;
    await retry({
      command: () => s3Client.putObject(params),
    });
  }
}
