import withStyles from '@mui/styles/withStyles';
import produce from 'immer';
import _ from 'lodash';
import React from 'react';

import Widget from '../../widgetEngine/Widget';
import { IPassThroughProps } from '../types';

function Cell(props) {
  const {
    // row,
    backgroundColor,
    rowsGetter,
    rowDataIndex,
    columnField,
    formPath,
    cellWidget,
    cellData,
    rowName,
    rowIndex,
    adjustableRowHeight,
    setRowHeight,
    aliases,
    rowInstanceName,
    classes,
  } = props;

  const passThroughProps: IPassThroughProps = props.passThroughProps;

  const childProperties = {
    backgroundColor,
  };

  const rowAlias = [..._.toPath(rowsGetter), rowDataIndex];

  const cellAliases = {
    _row: rowAlias,
    ...(rowInstanceName && { [rowInstanceName]: rowAlias }),
    _cell: [..._.toPath(rowsGetter), rowDataIndex, ..._.toPath(columnField)],
    // TODO: this needs to use a row instance name instead of rowsGetter. The logic is already in dynamic subscriptions
    ...(formPath && {
      form: [
        ...formPath,
        ..._.toPath(rowsGetter),
        rowDataIndex,
        ..._.toPath(columnField),
      ],
    }),
  };

  const columnContextMap = aliases.createDescendent({
    nodeName: `_column_${columnField}`,
  });

  const cellContextMap = columnContextMap.createDescendent({
    nodeName: `_row_${rowIndex}`,
  });

  cellContextMap.addSubscribePaths(cellAliases);

  const newPassThroughProps = produce(
    passThroughProps,
    (draftPassThroughProps) => {
      if (adjustableRowHeight) {
        draftPassThroughProps.setRowHeight = setRowHeight;
        draftPassThroughProps.rowIndex = rowIndex;
      }
    },
  );

  const cell = cellWidget ? (
    <Widget
      {...{
        component: cellWidget,
        passThroughProps: newPassThroughProps,
        index: cellData || rowName,
        properties: childProperties,
        aliases: cellContextMap,
      }}
    />
  ) : null;

  return (
    <div key={`${rowIndex}cell`} className={classes.cell}>
      {cell}
    </div>
  );
}

const styles = {
  cell: (props) =>
    ({
      height: '100%',
      width: '100%',
      overflow: props.overflow,
      display: 'flex',
      alignItems: 'center',
      ...(props.cellTextWrap && ({ whiteSpace: 'normal' } as const)),
    }) as const,
};

export default React.memo(withStyles(styles)(Cell));
