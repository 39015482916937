import { AgChartOptions } from 'ag-charts-community';
import _ from 'lodash';
import { ClientManager } from 'universal/clientManager';
import { AggregationTypes } from 'universal/data/aggregate';
import { IExecuteQueryParams } from 'universal/pipeline/pipelineManager';

export type IChartType = 'bar' | 'stackedBar' | 'treemap' | 'line';
export type IAxisType = 'number' | 'percent' | 'time';
export interface IChartBuilderParams {
  graphQl?: IExecuteQueryParams;
  chartData?: any[];
  type: IChartType;
  categoryField?: string;
  categoryName?: string;
  seriesField?: string;
  valueField: string;
  chartOptions: AgChartOptions;
  aggregate?: boolean;
  aggMethod?: AggregationTypes;
  useOther?: boolean;
  showTotal?: boolean;
  axes: {
    xAxisType?: IAxisType;
    yAxisType?: IAxisType;
    yAxisMin?: number;
    yAxisMax?: number;
    xAxisTitle?: string;
    yAxisTitle?: string;
  };
  barOptions?: IBarOptions;
}
export interface IBarOptions {
  horizontal?: boolean;
  stacked?: boolean;
}
const paletteColors = [
  '#0E2746',
  '#0f385b',
  '#0f4a6f',
  '#105b84',
  '#106d98',
  '#117ead',
  '#1190c1',
  '#12a1d6',
  '#12b3ea',
  '#13c4ff',
];

export class ChartSupport {
  public clientManager: ClientManager;

  public initialize(clientManager: ClientManager) {
    this.clientManager = clientManager;
  }

  public async buildChart(chartParams: IChartBuilderParams): Promise<any> {
    const {
      graphQl,
      chartData,
      aggregate,
      aggMethod,
      categoryField,
      seriesField,
      valueField,
      type,
    } = chartParams;
    let aggData;
    const chartDataToUse =
      chartData ||
      (await this.clientManager.pipelineManager.executeGraphqlQuery(graphQl));
    if (!(chartDataToUse.length > 0)) {
      return null;
    }
    if (aggregate) {
      const matchKeys = [];
      if (categoryField) {
        matchKeys.push(categoryField);
      }
      if (seriesField) {
        matchKeys.push(seriesField);
      }
      aggData = this.clientManager.aggregate({
        records: chartDataToUse,
        matchKeys,
        fields: {
          [valueField]: {
            sourceField: valueField,
            method: aggMethod || AggregationTypes.sum,
          },
        },
      }).aggregatedRecords;
    }
    switch (type) {
      case 'stackedBar':
      case 'bar':
        if (!chartParams.barOptions) {
          chartParams.barOptions = {};
        }
        chartParams.barOptions.stacked = type === 'stackedBar';
        return this.buildBar({ chartParams, data: aggData || chartData });
        break;
      case 'treemap':
        return this.buildTreemap({ chartParams, data: aggData || chartData });
        break;
      case 'line':
        return this.buildLine({ chartParams, data: aggData || chartData });
        break;
    }
  }
  private flattenData(params: {
    data: any[];
    chartParams: IChartBuilderParams;
  }) {
    const dataMap = {};
    const { data, chartParams } = params;
    data.forEach((record) => {
      const categoryField = _.get(record, chartParams.categoryField);
      const seriesField = _.get(record, chartParams.seriesField);
      if (!dataMap[categoryField]) {
        dataMap[categoryField] = {
          [chartParams.categoryName]: _.get(record, chartParams.categoryField),
        };
        if (chartParams.showTotal) {
          dataMap[categoryField].total = 0;
        }
      }
      const value = _.get(record, chartParams.valueField);
      if (chartParams.showTotal) {
        dataMap[categoryField].total += value;
      }
      dataMap[categoryField][seriesField] = value;
    });
    const outputData = [];
    for (const category in dataMap) {
      outputData.push(dataMap[category]);
    }
    return outputData;
  }
  private buildBar(params: { chartParams: IChartBuilderParams; data: any[] }) {
    // const OTHER_THRESHOLD = 0.05;
    const { chartParams, data } = params;
    const seriesMap = {};
    const series = [];
    let chartDataToUse = data;
    if (!chartParams.seriesField) {
      series.push({
        type: 'bar',
        xKey: chartParams.categoryName || chartParams.categoryField,
        yKey: chartParams.valueField,
        label: {
          formatter: this.labelFormatters[chartParams.axes.yAxisType],
        },
      });
    } else {
      chartDataToUse = this.flattenData({ chartParams, data });
      chartDataToUse.forEach((record) => {
        for (const s in record) {
          if (s !== chartParams.categoryName && !seriesMap[s]) {
            seriesMap[s] = {
              type: 'bar',
              stacked: chartParams.barOptions.stacked,
              xKey: chartParams.categoryName,
              yKey: s,
              yName: s,
            };
          }
        }
      });

      for (const s in seriesMap) {
        series.push(seriesMap[s]);
      }
    }
    return {
      ...chartParams.chartOptions,
      series,
      data: chartDataToUse,
      axes: this.buildAxes({ chartParams }),
    };
  }
  private buildTreemap(params: {
    chartParams: IChartBuilderParams;
    data: any[];
  }) {
    // const OTHER_THRESHOLD = 0.05;
    const { chartParams, data } = params;

    const chartDataToUse = this.buildTreemapData({ chartParams, data });

    const series = [
      {
        type: 'treemap',
        labelKey: 'title',
        sizeKey: chartParams.valueField,
        sizeName: chartParams.valueField,
        fills: paletteColors,
        group: {
          label: {
            fontSize: 14,
            spacing: 2,
            formatter: (p) => {
              const total = p.datum.children.reduce(
                (acc, c) => acc + c[chartParams.valueField],
                0,
              );
              return `${p.datum.title}: ${Math.round(total).toLocaleString()}`;
            },
          },
        },
        tooltip: {
          renderer: (p) => {
            if (p.datum.children) {
              const total = p.datum.children.reduce(
                (acc, c) => acc + c[chartParams.valueField],
                0,
              );
              return {
                title: p.title,
                content: Math.round(total).toLocaleString(),
              };
            }
            return {
              title: p.title,
              content: Math.round(
                p.datum[chartParams.valueField],
              ).toLocaleString(),
            };
          },
        },
      },
    ];
    return {
      ...chartParams.chartOptions,
      series,
      data: chartDataToUse,
    };
  }
  private buildTreemapData(params: {
    data: any[];
    chartParams: IChartBuilderParams;
  }) {
    const dataMap = {};
    const { data, chartParams } = params;
    data.forEach((record) => {
      const categoryField = _.get(record, chartParams.categoryField);
      const seriesField = _.get(record, chartParams.seriesField);
      if (!dataMap[categoryField]) {
        dataMap[categoryField] = {
          title: _.get(record, chartParams.categoryField),
          children: [],
          total: 0,
        };
      }
      const value = _.get(record, chartParams.valueField);
      dataMap[categoryField].children.push({
        title: seriesField,
        [chartParams.valueField]: value,
      });
      dataMap[categoryField].total += value;
    });
    const outputData = [];
    for (const category in dataMap) {
      outputData.push(dataMap[category]);
    }
    return _.orderBy(outputData, 'total', 'desc');
  }

  private buildLine(params: { chartParams: IChartBuilderParams; data: any[] }) {
    const { chartParams, data } = params;
    const seriesMap = chartParams.showTotal
      ? {
          total: {
            type: 'line',
            xKey: chartParams.categoryName,
            yKey: 'total',
            yName: 'Total',
          },
        }
      : {};
    const chartDataToUse = this.flattenData({ chartParams, data });
    chartDataToUse.forEach((record) => {
      for (const series in record) {
        if (series !== chartParams.categoryName && !seriesMap[series]) {
          seriesMap[series] = {
            type: 'line',
            xKey: chartParams.categoryName,
            yKey: series,
            yName: series,
          };
        }
      }
    });
    const series = [];
    for (const s in seriesMap) {
      series.push(seriesMap[s]);
    }
    return {
      ...chartParams.chartOptions,
      series,
      data: chartDataToUse,
      axes: this.buildAxes({ chartParams }),
    };
  }
  labelFormatters = {
    number: (p) => Math.round(p.value).toLocaleString(),

    percent: (p) => {
      return (p.value * 100).toFixed(1) + '%';
    },
  };
  private buildAxes(params: { chartParams: IChartBuilderParams }): any[] {
    const { chartParams } = params;
    const axes = [];
    axes.push({
      type: 'number',
      position: 'left',
      label: {
        formatter: this.labelFormatters[chartParams.axes.yAxisType],
      },
      title: chartParams.axes.yAxisTitle
        ? { text: chartParams.axes.yAxisTitle }
        : null,
    });
    if (!isNaN(chartParams.axes.yAxisMin)) {
      axes[0].min = chartParams.axes.yAxisMin;
    }
    axes.push({
      type: 'category',
      position: 'bottom',
      title: chartParams.axes.xAxisTitle
        ? { text: chartParams.axes.xAxisTitle }
        : null,
    });
    return axes;
  }
}
