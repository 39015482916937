import { Breadcrumbs, Icon, Typography } from '@mui/material';
import React, { FunctionComponent, useContext } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

import { NavContext } from './NavLayout';

const BreadCrumbs: FunctionComponent<React.PropsWithChildren<unknown>> = () => {
  const { breadCrumbs, title } = useContext(NavContext);

  const crumbs = breadCrumbs
    .filter((breadCrumb) => breadCrumb.url !== '/')
    .map((breadCrumb, index, array) => {
      const url = array
        .slice(0, index + 1)
        .map((item) => item.url)
        .join('');
      const isLink = index + 1 < array.length;
      return (
        <Typography
          color="textPrimary"
          variant="h6"
          key={breadCrumb.label + url}
          {...(isLink && { component: Link, to: url })}
        >
          {breadCrumb.label}
        </Typography>
      );
    });

  return (
    <React.Fragment>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Breadcrumbs
        maxItems={3}
        sx={{ ml: 1 }}
        key="breadCrumbs"
        separator={<Icon>navigate_next</Icon>}
      >
        {crumbs}
      </Breadcrumbs>
    </React.Fragment>
  );
};

export default BreadCrumbs;
