import { Operation } from '@apollo/client/core';
import { FieldNode, IntValueNode, StringValueNode } from 'graphql';
import gql from 'graphql-tag';
import Handlebars from 'handlebars';
import _ from 'lodash';
import { Logger } from 'pino';
import safeJsonStringify from 'safe-json-stringify';

import { ClientManager } from '../clientManager';
import { SYSTEM } from '../common/commonConstants';
import { exists } from '../common/commonUtilities';
import { reThrow } from '../errors/errorLog';
import {
  executeJavascriptSync,
  IJavascriptBaseArgs,
} from '../javascriptSupport';
import { NESTED_SUFFIX } from '../loadgraphql';
import { getLogger, Loggers, LogLevels } from '../loggerSupport';
import { MetadataSupport } from '../metadataSupport';
import {
  ACTION_CREATED,
  ACTION_DELETED,
  ACTION_UPDATED,
  ActionType,
  CHUNK_ID,
  CollectionTypes,
  CONFIG_SEPARATOR,
  DIRECTIVE_ACTIVEASOF,
  DIRECTIVE_ACTIVEASOF_DATE,
  DIRECTIVE_ACTIVEASOF_DURATION,
  ID_NOT_FOUND,
  IEntityType,
  IHasIdAndTypeName,
  NEXT_TOKEN,
  TYPE_NAME,
  UpdateType,
} from '../metadataSupportConstants';
import {
  IVisitorFunctionParams,
  IVisitorFunctionReturn,
} from '../metadataVisitor';
import { getChunkId } from '../sizeClass';
import { IItemInfo, TypeDefinition } from '../typeDefinition';
import { basicToGraphQL, BasicType, GraphQLType, isNumeric } from '../types';
import { calculatePeriod, modifyLeaves } from '../utilityFunctions';

import {
  getFieldNameInfo,
  getGraphqlOperationFieldFromDocument,
  getGraphqlQueryFieldNameFromDocument,
} from './graphQLSupportAst';
import { IQueryConnectionResult, PipelineManager } from './pipelineManager';
import { StageQuery } from './stageQuery';

import { GraphQLResponse } from '@apollo/server';
import dayjs from 'universal/common/dayjsSupport';

export interface ISubscriptionMessage {
  data: any;
  action: ActionType;
  requestingClientId: string;
}

const fixTypename = (typename: string) => {
  return typename?.endsWith('Input') ? typename.replace(/Input/, '') : typename;
};

export function getGraphqlQueryFieldName(query: string, context?: any) {
  if (context) {
    query = Handlebars.compile(query)(context);
  }
  const graphqlDocument = gql(query);
  return getGraphqlQueryFieldNameFromDocument(graphqlDocument);
}

export function getGraphqlTypeName(
  typeDef: TypeDefinition,
  nested = false,
): string {
  const typeName = MetadataSupport.getUnqualifiedName(typeDef.id);
  if (nested && typeDef.useAlternateTypeNameWhenNested) {
    return `${typeName}${NESTED_SUFFIX}`;
  }
  return typeName;
}

export function getGraphqlResponseBody(response: Partial<GraphQLResponse>) {
  if (response.body.kind !== 'single') {
    throw new Error('Only single responses are supported');
  }
  return response.body.singleResult;
}

export function requiresGraphqlQuotes(typeName: string): boolean {
  return !(isNumeric(typeName) || typeName === BasicType.Boolean);
}

export function okToLog(entity: IEntityType): boolean {
  return !(
    entity.id === `${SYSTEM}${CONFIG_SEPARATOR}_Log` ||
    entity.id === `${SYSTEM}${CONFIG_SEPARATOR}_EntityIncarnations`
  );
}

export function remoteSubscriptionTypeToUpdateType(
  queryType: string,
): UpdateType {
  switch (queryType) {
    case ACTION_CREATED:
      return UpdateType.CREATE;
    case ACTION_UPDATED:
      return UpdateType.UPDATE;
    case ACTION_DELETED:
      return UpdateType.DELETE;
  }
  throw new Error(`Unknown updateType: ${queryType}`);
}

export function makeRemoteSubscriptionEventName(
  unqualifiedEntityId: string,
  action: ActionType,
): string {
  return `${unqualifiedEntityId}${action}`;
}

export function actionFromRemoteSubscriptionEventName(
  eventName: string,
): ActionType {
  if (eventName.endsWith(ACTION_CREATED)) {
    return ACTION_CREATED;
  }
  if (eventName.endsWith(ACTION_UPDATED)) {
    return ACTION_UPDATED;
  }
  if (eventName.endsWith(ACTION_DELETED)) {
    return ACTION_DELETED;
  }
  throw new Error(`Unknown type of eventName: ${eventName}`);
}

export function removeTypeNames(obj) {
  for (const key in obj) {
    if (obj[key] && typeof obj[key] === 'object') {
      removeTypeNames(obj[key]);
    }
  }
  delete obj[TYPE_NAME];
}

export function toMapObjectVisitor(
  params: IVisitorFunctionParams,
): IVisitorFunctionReturn {
  const { objectField, attr } = params;

  if (!exists(objectField)) {
    return;
  }
  if (
    attr?.itemInfo.collectionType === CollectionTypes.MAP &&
    Array.isArray(objectField)
  ) {
    const mapObj = {};
    objectField.forEach((m) => (mapObj[m.id] = m));
    return { newValue: mapObj };
  }
  return {};
}

export function fromMapObjectVisitor(
  params: IVisitorFunctionParams,
): IVisitorFunctionReturn {
  const { objectField, attr, index } = params;

  if (!exists(objectField)) {
    return;
  }
  if (
    attr?.itemInfo.collectionType === CollectionTypes.MAP &&
    index === undefined
  ) {
    const arrayObj = [];
    Object.keys(objectField).forEach((k) => arrayObj.push(objectField[k]));
    return { newValue: arrayObj };
  }

  return {};
}

export function addMissingFieldsVisitor(
  params: IVisitorFunctionParams,
): IVisitorFunctionReturn {
  const { enclosingObject, objectField } = params;

  if (typeof enclosingObject === 'object' && objectField === undefined) {
    return { newValue: null };
  }
  return {};
}

export function addTypeNameEntityVisitor(
  params: IVisitorFunctionParams,
): IVisitorFunctionReturn {
  const { objectField, objectFieldTypeDef, rootObject, attr } = params;

  if (
    objectField &&
    typeof objectField === 'object' &&
    !Array.isArray(objectField) &&
    !objectField[TYPE_NAME] &&
    objectFieldTypeDef
  ) {
    objectField[TYPE_NAME] = getGraphqlTypeName(
      objectFieldTypeDef,
      objectField !== rootObject && !attr?.itemInfo.associatedEntity,
    );
  }
  return {};
}

export function getEffectiveChunkId(params: { queryInfo: StageQuery; id }) {
  const { queryInfo, id } = params;
  // For queries when we don't use a chunkId, the chunkId is 0
  if (Object.keys(queryInfo.graphqlQueriesByChunk).length === 1) {
    return 0;
  } else {
    return getChunkId({
      id,
      sizeClass: queryInfo.sizeClass,
    });
  }
}

export function toGraphQLType(itemInfo: IItemInfo /* enums: Set<string> */) {
  // A reference to another type
  if (itemInfo.associatedEntity) {
    return GraphQLType.ID;
  }
  return basicToGraphQL[itemInfo.type] || itemInfo.type;
}

export function makeObjectKey(obj: IHasIdAndTypeName) {
  return `${fixTypename(obj[TYPE_NAME])}:${obj.id}`;
}

// Gets the id of an object which might be a data return object, so the id
// might be associated with one of the keys of the data object.
export function getDataTracingId(obj: any) {
  if (!obj) {
    return '<no object>';
  }
  if (obj.id) {
    if (obj[TYPE_NAME]) {
      return `${obj[TYPE_NAME]}:${obj.id}`;
    }
    return obj.id;
  }
  const ids = Object.keys(obj)
    .map((k) => {
      let objToUse;
      if (Array.isArray(obj[k]) && obj[k].length > 0) {
        objToUse = obj[k][0];
      } else {
        objToUse = obj[k];
      }
      if (objToUse && objToUse.id) {
        return `${k}:${objToUse.id}`;
      }
      return null;
    })
    .filter((e) => !!e);
  if (ids.length > 0) {
    return ids[0];
  }

  return '<unknown id>';
}

export function removeDoubleUnderscoreStuff(obj) {
  Object.keys(obj).forEach((k) => {
    if (k.startsWith('__')) {
      try {
        delete obj[k];
      } catch (error) {
        // console.log('failed to delete', k, obj);
        // Ignore this, if the object is read only or something
      }
      return;
    }
    if (obj[k] && typeof obj[k] === 'object') {
      removeDoubleUnderscoreStuff(obj[k]);
    }
  });
}

export function removeIdNotFoundPrefix(input: any): void {
  modifyLeaves({
    collection: input,
    callback: (value) => {
      if (typeof value === 'string' && value.startsWith(ID_NOT_FOUND)) {
        return value.slice(ID_NOT_FOUND.length);
      } else {
        return value;
      }
    },
  });
}

export function getMutationInfo(fieldName: string) {
  const returnVal = { entityId: null, updateType: null };
  let upper = 0;
  for (; upper < fieldName.length; upper++) {
    if (/[A-Z]/.test(fieldName.charAt(upper))) {
      break;
    }
  }
  returnVal.entityId = fieldName.substring(upper);
  // REMOVEME - when all of the Internal stuff goes away
  if (returnVal.entityId.endsWith('Internal')) {
    returnVal.entityId = returnVal.entityId.substring(
      0,
      returnVal.entityId.length - 8,
    );
  }
  returnVal.updateType = fieldName.substring(0, 6);
  return returnVal;
}

interface IJavascriptFilterArgs extends IJavascriptBaseArgs {
  item: string;
}

export interface IFilterExecutionContext {
  filters: string[];
  item: any;
  clientManager: ClientManager;
  logger: Logger;
}

export const executeFilters = (params: IFilterExecutionContext): boolean => {
  const { filters, item, clientManager, logger } = params;

  for (const f of filters) {
    try {
      const retVal = executeJavascriptSync({
        jsCodeToExecute: f,
        maybeAddReturnStatement: true,
        jsArgs: {
          clientManager,
          libraries: clientManager.pipelineManager.javascriptStageLibraries,
          _,
          item,
          logger: getLogger({ name: Loggers.PIPELINE_JAVASCRIPT }),
        } as IJavascriptFilterArgs,
      });
      if (retVal === undefined) {
        reThrow({
          logger,
          message:
            "Filter returned 'undefined': Filters should be either a single statement (with no return), or, " +
            'if multiple statements, be enclosed in {}',
        });
      }
      //logger.debug({ filter: f, itemId: item.id }, `Filtered: ${retVal}`);
      if (!retVal) {
        return false;
      }
    } catch (error) {
      reThrow({
        logger,
        message: `Error executing filter '${f} on ${safeJsonStringify(item)}`,
        error,
      });
    }
  }
  return true;
};

export interface IActiveAsOfInfo {
  activeAsOfStartDate?: string; // YYYY-MM-DD
  activeAsOfEndDate?: string; // YYYY-MM-DD
  ignoreDeactivationDate?: boolean;
}

export function getActiveAsOfInfo(
  field: FieldNode,
  clientLocalTimestamp: string,
): IActiveAsOfInfo {
  const activeAsOf = field.directives.find(
    (d) => d.name.value === DIRECTIVE_ACTIVEASOF,
  );
  const returnVal: Partial<IActiveAsOfInfo> = {};
  if (activeAsOf) {
    if (activeAsOf.arguments.length === 0) {
      returnVal.ignoreDeactivationDate = true;
    } else {
      const dateNameNode = activeAsOf.arguments.find(
        (argument) => argument.name.value === DIRECTIVE_ACTIVEASOF_DATE,
      );
      if (dateNameNode) {
        const dateNameNodeValue = dateNameNode.value as StringValueNode;
        if (dateNameNodeValue) {
          returnVal.activeAsOfStartDate = dateNameNodeValue.value;
        }
      }

      const durationNameNode = activeAsOf.arguments.find(
        (argument) => argument.name.value === DIRECTIVE_ACTIVEASOF_DURATION,
      );
      if (durationNameNode) {
        const durationNameNodeValue = durationNameNode.value as StringValueNode;
        if (durationNameNodeValue) {
          returnVal.activeAsOfEndDate = calculatePeriod(
            returnVal.activeAsOfStartDate,
            durationNameNodeValue.value,
          ).endDate;
        }
      }
    }
  } else {
    // End date is UTC (because that's how creationTime is stored)
    returnVal.activeAsOfEndDate = dayjs(clientLocalTimestamp)
      .utc()
      .format()
      .slice(0, 10);
    // Start date is local
    returnVal.activeAsOfStartDate = clientLocalTimestamp.slice(0, 10);
  }

  if (!returnVal.ignoreDeactivationDate && !returnVal.activeAsOfEndDate) {
    returnVal.activeAsOfEndDate = '9999-12-31';
  }
  return returnVal;
}

export function traceLinkRequestData(params: {
  clientManager: ClientManager;
  logger: Logger;
  message: string;
  operation: Operation;
}): number {
  const { logger, message, operation } = params;

  const context = operation.getContext();

  const remoteContext = PipelineManager.getRemoteContextFromHeaders(
    operation.getContext().headers,
  );
  const operationName = operation.operationName
    ? ' ' + operation.operationName
    : '';
  const field = getGraphqlOperationFieldFromDocument(operation.query);
  const fieldName = field.name.value;
  const chunkId = (
    field.arguments?.find((a) => a.name.value === CHUNK_ID)
      ?.value as IntValueNode
  )?.value;
  const chunkIdString = chunkId !== undefined ? ` chunkId: ${chunkId}` : ' ';
  const fieldInfo = getFieldNameInfo(fieldName);
  if (!context.headers && fieldInfo.queryType !== 'Subscription') {
    throw new Error(
      'No context header - this is a problem - make sure the ApolloClient is patched to remove the delete context (s/b in 3.3.11 or higher)',
    );
  }

  logger.debug(
    `${message}:${operationName} ${
      fieldInfo.queryType === 'Subscription' ? 'subscription ' : ''
    }${fieldName}${chunkIdString} ${PipelineManager.remoteIdString({
      remoteContext,
    })}`,
  );
  return Date.now();
}

export function traceLinkResultData(params: {
  clientManager: ClientManager;
  logger: Logger;
  message: string;
  bodyObj: any;
  operation: Operation;
  startTime: number;
}) {
  const { logger, message, bodyObj, operation, startTime } = params;
  const remoteContext = PipelineManager.getRemoteContextFromHeaders(
    operation.getContext().headers,
  );
  const operationName = operation.operationName
    ? ' ' + operation.operationName
    : '';
  let remoteIdString = '';
  if (remoteContext) {
    remoteIdString = PipelineManager.remoteIdString({ remoteContext });
  }
  const requestTime = `time: ${Date.now() - startTime} ms`;
  const { data } = bodyObj;

  // extensions is present for subscriptions
  if (!data || bodyObj.extensions) {
    return;
  }
  const keys = Object.keys(data);
  if (keys.length !== 1) {
    return;
  }
  const fieldName = keys[0];
  const fieldInfo = getFieldNameInfo(fieldName);

  switch (fieldInfo.queryType) {
    case 'List':
      traceListResult({
        ...params,
        fieldName,
        operationName,
        remoteIdString,
        requestTime,
        queryConnectionResult: data[fieldName],
      });
      break;
    case 'Mutation_Batch': {
      const items = data[fieldName];
      logger.debug(
        `${message}:${operationName} ${fieldName}: ${remoteIdString} ${requestTime} length: ${
          items?.length
        }: first: ${items?.length > 0 ? items[0].id : ''}`,
      );
      break;
    }
    case 'Mutation':
    case 'Get':
      {
        const id = data[fieldName] ? data[fieldName].id : '';
        logger.debug(
          `${message}:${operationName} ${fieldName}: ${remoteIdString} ${requestTime} ${id} `,
        );
      }
      break;
    case 'Subscription':
      logger.debug(`${message}: subscription ${fieldName}: ${remoteIdString}`);
      break;
    case 'Not_Query':
      break;
  }
}

export function traceListResult(params: {
  logger: Logger;
  message: string;
  remoteIdString?: string;
  requestTime?: string;
  operationName?: string;
  fieldName: string;
  queryConnectionResult: IQueryConnectionResult;
}) {
  const {
    logger,
    message,
    queryConnectionResult,
    operationName = '',
    fieldName,
    remoteIdString,
    requestTime = '',
  } = params;
  const { items } = queryConnectionResult;
  if (logger.isLevelEnabled(LogLevels.Debug)) {
    logger.debug(
      `${message}${operationName} ${fieldName} ${
        remoteIdString ? remoteIdString : ''
      } chunk: ${queryConnectionResult[CHUNK_ID]} next: ${
        queryConnectionResult[NEXT_TOKEN]
      } items: ${items ? items.length : '<none>'} ${
        items?.length > 0 ? items[0].id : ''
      } ${requestTime}`,
    );
  }
}
