// import type {} from '@mui/x-date-pickers/themeAugmentation';
import { TextField, Tooltip } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { forwardRef, useEffect, useRef, useState } from 'react';
import NumberFormat from 'react-number-format';
import { compose } from 'redux';
import { Loggers, getLogger } from 'universal/loggerSupport';

import { withUiState } from '../../../store/withUiState';
import { typographyTranslate } from '../../../util/typographyTranslate';
import { withActions } from '../../widgetEngine/ActionEnabler';

const logger = getLogger({ name: Loggers.CLIENT });

const compactMap = {
  thousands: {
    suffix: 'K',
    multiplier: 1000,
  },
  millions: {
    suffix: 'M',
    multiplier: 1000000,
  },
  billions: {
    suffix: 'B',
    multiplier: 1000000000,
  },
  percent: {
    suffix: '%',
    multiplier: 0.01,
  },
};

// These correspond to the property 'type' values for the Text widget
const noSelectionRangeTypes = {
  date: true,
  datetime: true,
  'datetime-local': true,
  time: true,
};

const styles = () => ({});
function Text(props) {
  const {
    disabled,
    readOnly,
    multiline,
    label,
    width,
    height,
    type,
    required,
    rows,
    rowsMax,
    backgroundColor,
    backgroundColorIfEmpty,
    variant,
    valid,
    validationMessage,
    thousandSeparator,
    prefix,
    suffix,
    decimalScale,
    fixedDecimalScale,
    format,
    mask,
    pattern,
    patternFailText,
    allowEmptyFormatting,
    allowNegative,
    textAlign,
    fontSize,
    fontWeight,
    color,
    passThroughProps: { rejectedFormSubmit },
    compact,
    initialValue,
    updateInitialValue,
    persistValue,
    tooltip,
    data,
    updateFromWidget,
    helperText,
  } = props;

  const [initialized, setInitialized] = useState(false);
  const [dataToRender, setDataToRender] = useState(undefined);
  const [dataInProgress, setDataInProgress] = useState(false);
  const [savedInitialValue, setSavedInitialValue] = useState(undefined);
  const [savedData, setSavedData] = useState(undefined);
  const [patternFailed, setPatternFailed] = useState(false);

  const inputElement = useRef<any>();
  const caretPosition = useRef(0);

  const [updateParams, setUpdateParams] = useState<{
    data: any;
    initialUpdate?: boolean;
  }>(undefined);

  if (Number.isNaN(data)) {
    logger.error(
      `NaN received as data in Text: ${props.component.treeId}/${props.id}`,
    );
  }

  if (!initialized) {
    let initData;
    if (persistValue !== undefined) {
      initData = persistValue;
    } else if (initialValue !== undefined) {
      initData = initialValue;
    } else if (data !== undefined) {
      initData = data;
    }
    // Save before we mess with it
    setSavedData(initData);
    setInitialized(true);
    initData = initData || processFalsyValue(initData);
    setUpdateParams({
      data: initData,
      initialUpdate: true,
    });
    setDataToRender(initData);
  } else if (!dataInProgress) {
    if (updateInitialValue && initialValue !== savedInitialValue) {
      setSavedInitialValue(initialValue);
      setSavedData(initialValue);
      setDataToRender(initialValue || processFalsyValue(initialValue));
    } else if (data !== savedData && !Number.isNaN(data)) {
      setSavedData(data);
      setDataToRender(data || processFalsyValue(data));
    }
  }

  useEffect(() => {
    if (updateParams) {
      updateFromWidget(updateParams);
      setDataInProgress(false);
      setUpdateParams(null);
    }
  }, [updateFromWidget, updateParams]);

  function processFalsyValue(value) {
    const { nullable = true } = props;
    const isNumber = type === 'number';

    // this is essentially just turning undefined into either 0 or null for numbers based on nullability
    // but leave the structure in case it needs to be expanded
    const results = {
      true: {
        // nullable number
        true: (val) => {
          if (val === 0) {
            return 0;
          }
          return null;
        },
        // non nullable number
        false: () => 0,
      },
      false: {
        // nullable non number
        true: () => (type === 'date' ? null : ''),
        // non nullable non number
        false: () => (type === 'date' ? null : ''),
      },
    };

    return results[(!!isNumber).toString()][!!nullable](value);
  }

  function update(newData) {
    const { maxLength } = props;
    if (maxLength === undefined || newData.length <= Number(maxLength)) {
      setUpdateParams({
        data: typeof newData === 'string' ? newData.trim() : newData,
      });
    }
  }

  function handleInputSetup(input) {
    if (!input) {
      return;
    }
    inputElement.current = input;
    if (!noSelectionRangeTypes[type]) {
      input.setSelectionRange(caretPosition.current, caretPosition.current);
    }
  }

  function handleBlur() {
    update(dataToRender);
  }

  function handleKeyDown(event) {
    // Enter key
    if (event.keyCode === 13) {
      handleBlur();
    }
  }

  function handleChange(event) {
    handleChangeValue(event.target.value);
  }

  function handleChangeValue(value) {
    const parsedValue = value || processFalsyValue(value);
    const valueToUse =
      compact && typeof parsedValue === 'number'
        ? parsedValue * compactMap[compact].multiplier
        : parsedValue;
    setDataInProgress(true);
    if (inputElement.current) {
      caretPosition.current = inputElement.current.selectionEnd;
    }
    setDataToRender(valueToUse);
    if (!props.updateOnChange) {
      return;
    }
    update(valueToUse);
  }

  function shouldShrinkLabel() {
    const shrinkLabelByType = {
      date: true,
      time: true,
      datetime: true,
      'datetime-local': true,
    };

    const shouldShrink =
      shrinkLabelByType[type] ||
      !['', null, undefined].includes(dataToRender) ||
      !!fixedDecimalScale ||
      (allowEmptyFormatting && (!!prefix || !!suffix)) ||
      !!mask;
    return shouldShrink;
  }

  if (multiline && type !== 'text') {
    throw new Error('multiline can only be set for text type inputs');
  }

  if (!initialized) {
    return null;
  }

  const showRequiredAndEmpty =
    (rejectedFormSubmit || dataInProgress) &&
    required &&
    [undefined, null, ''].includes(dataToRender);
  const showError = !valid || showRequiredAndEmpty;

  const renderBackgroundColor =
    backgroundColorIfEmpty && !dataToRender
      ? backgroundColorIfEmpty
      : backgroundColor;

  const compactSetting = compactMap[compact];
  const localDataIsNumber = typeof dataToRender === 'number';

  const conditionalProps =
    type === 'number'
      ? {
          InputProps: {
            inputComponent: FormatInput,
            inputProps: {
              ...(thousandSeparator && { thousandSeparator }),
              ...(format && { format }),
              decimalScale,
              prefix: (localDataIsNumber && prefix) || '',
              suffix:
                (localDataIsNumber &&
                  ((compact && compactSetting.suffix) || suffix)) ||
                '',
              fixedDecimalScale,
              mask,
              allowEmptyFormatting,
              allowNegative,
              readOnly,
              style: {
                textAlign,
                fontWeight,
                ...(fontSize && { fontSize: typographyTranslate[fontSize] }),
                ...(color && { color }),
              },
            },
          },
        }
      : {
          InputProps: {
            inputProps: {
              readOnly,
              pattern,
              ...(pattern && {
                onInput: (event) => {
                  if (event.target.value?.length > 0) {
                    const re = new RegExp(pattern);
                    setPatternFailed(!re.test(event.target.value));
                  } else {
                    setPatternFailed(false);
                  }
                },
              }),
              style: {
                textAlign,
                ...(fontSize && { fontSize: typographyTranslate[fontSize] }),
                ...(color && { color }),
              },
            },
          },
        };

  const multipliedData =
    compact && localDataIsNumber
      ? dataToRender / compactSetting.multiplier
      : dataToRender;
  let valueToRender = ![undefined, null].includes(multipliedData)
    ? multipliedData
    : '';
  if (type === 'date') {
    if (
      valueToRender === '' ||
      valueToRender === null ||
      valueToRender === undefined
    ) {
      valueToRender = null;
    } else if (typeof valueToRender === 'string') {
      // Make sure the date object is in local time to display correctly
      valueToRender = new Date(`${valueToRender}T00:00`);
    }
  }

  const baseTextProps = {
    onBlur: handleBlur,
    onKeyDown: handleKeyDown,
    style: {
      width,
      height,
      backgroundColor: renderBackgroundColor,
    },
    required: !!required,
    label,
    value: valueToRender,
    disabled: !!disabled || (type === 'date' && readOnly),
    helperText:
      (patternFailed && patternFailText) ||
      (valid === false && validationMessage) ||
      helperText,
    error: showError || patternFailed,
  };
  const component =
    type === 'date' ? (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          onChange={(date: Date) => {
            try {
              if (date === null && props.nullable) {
                handleChangeValue(date);
              } else {
                // onChange is necessary to respond to the text events, as the picker
                // does not use this function
                // This date object is in the local timezone, so make a UTC one
                const utcDate = new Date(
                  date.getTime() - date.getTimezoneOffset() * 60 * 1000,
                );
                handleChangeValue(utcDate.toISOString().split('T')[0]);
              }
            } catch (error) {
              // We don't care since this might be a partial date
            }
          }}
          renderInput={(params) => (
            <TextField
              label={label}
              variant="standard"
              helperText={helperText}
              {...params}
            />
          )}
          onAccept={(date: Date) => {
            // This responds to date changes from the picker
            // This date object is already UTC
            const dateString = date.toISOString();
            update(dateString.split('T')[0]);
          }}
          disableToolbar
          variant="inline"
          margin="normal"
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
          {...baseTextProps}
          {...props.elementAttributes}
          key={`${props.name}date`}
        />
      </LocalizationProvider>
    ) : (
      <TextField
        onChange={handleChange}
        //onFocus={() =>
        // logger.info(`Text focus ${props.aliases?.widgetPath.toString()}`)
        //}
        variant={variant}
        maxRows={rowsMax}
        rows={rows}
        type={type}
        multiline={!!multiline}
        inputRef={handleInputSetup}
        InputLabelProps={{
          shrink: shouldShrinkLabel(),
        }}
        key={`${props.name}text`}
        {...baseTextProps}
        {...props.elementAttributes}
        {...conditionalProps}
      />
    );

  if (tooltip) {
    return <Tooltip title={tooltip}>{component}</Tooltip>;
  }
  return component;
}

const FormatInput = forwardRef((props, ref) => {
  const { onChange, ...otherProps } = props as any;
  const value = useRef();
  return (
    <NumberFormat
      {...otherProps}
      getInputRef={ref}
      onValueChange={(values: any) => {
        value.current = values.floatValue;
      }}
      onChange={() => {
        onChange({
          target: {
            value: value.current,
          },
        });
      }}
      type="text"
    />
  );
});

export default compose(
  withUiState,
  withActions,
  withStyles(styles, { withTheme: true }),
)(Text);
