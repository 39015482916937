import gqlPrettier from 'graphql-prettier';
import { Component } from 'react';
import MonacoEditor from 'react-monaco-editor';

interface IProps {
  updateField;
  identifier;
  value;
  height;
  language;
}

export default class CustomMonacoEditor extends Component<IProps> {
  constructor(props) {
    super(props);
    this.handleUpdate = this.handleUpdate.bind(this);
  }

  public handleUpdate(value) {
    const { updateField, identifier } = this.props;
    updateField(value, identifier);
  }

  public editorWillMount(monaco) {
    monaco.languages.registerDocumentFormattingEditProvider('javascript', {
      async provideDocumentFormattingEdits(model) {
        const prettier = await import('prettier/standalone');
        // @ts-ignore
        const babel = await import('prettier/parser-babel');
        const text = prettier.format(model.getValue(), {
          parser: 'babel',
          plugins: [babel],
          singleQuote: true,
          trailingComma: 'all',
        });

        return [
          {
            range: model.getFullModelRange(),
            text,
          },
        ];
      },
    });

    monaco.languages.registerDocumentFormattingEditProvider('graphql', {
      provideDocumentFormattingEdits(model) {
        const originalText = model.getValue();
        const { textWithoutHandlebars, backToHandlebarsMap } =
          removeHandlebars(originalText);
        const prettierTextWithoutHandlebars = gqlPrettier(
          textWithoutHandlebars,
        );
        const text = restoreHandlebars({
          textWithoutHandlebars: prettierTextWithoutHandlebars,
          backToHandlebarsMap,
        });

        return [
          {
            range: model.getFullModelRange(),
            text,
          },
        ];
      },
    });
  }

  public render() {
    const { value, height, language } = this.props;

    return (
      <MonacoEditor
        height={height || '400'}
        language={language || 'javascript'}
        theme="vs-dark"
        value={value}
        onChange={this.handleUpdate}
        editorWillMount={this.editorWillMount}
        options={{
          minimap: {
            enabled: false,
          },
          parameterHints: {
            enabled: false,
          },
          quickSuggestions: {
            other: false,
            comments: false,
            strings: false,
          },
        }}
      />
    );
  }
}

interface IBackToHandlebarsMap {
  [key: string]: string;
}

function removeHandlebars(text: string): {
  textWithoutHandlebars: string;
  backToHandlebarsMap: IBackToHandlebarsMap;
} {
  let replacerIndex = 0;
  const backToHandlebarsMap = {};

  function replacer(originalHandlebars) {
    const replaceWith = `___${replacerIndex.toString().padStart(4, '0')}`;
    replacerIndex++;

    backToHandlebarsMap[replaceWith] = originalHandlebars;

    return replaceWith;
  }

  const regex = /{{{(#[a-z]+ )?[a-z]+.[a-z]*}}}/gi;

  const textWithoutHandlebars = text.replace(regex, replacer);

  return {
    textWithoutHandlebars,
    backToHandlebarsMap,
  };
}

function restoreHandlebars({
  textWithoutHandlebars,
  backToHandlebarsMap,
}: {
  textWithoutHandlebars: string;
  backToHandlebarsMap: IBackToHandlebarsMap;
}): string {
  let restoredText = textWithoutHandlebars;

  Object.entries(backToHandlebarsMap).forEach(
    ([replacedText, originalText]) => {
      restoredText = restoredText.replace(replacedText, originalText);
    },
  );

  return restoredText;
}
