import withStyles from '@mui/styles/withStyles';
import { compose } from 'redux';

import SlideUp from '../molecules/SlideUp';
import { withActions } from '../widgetEngine/ActionEnabler';
import Container from '../widgetEngine/Container';

const styles = {};

class OptionsOverlay extends Container {
  render() {
    const { label, height, overflow } = this.props;

    const Children = this.generateChildren();

    return (
      <SlideUp
        overlayContent={Children}
        label={label}
        height={height}
        overflow={overflow}
      >
        {this.props.children}
      </SlideUp>
    );
  }
}

export default compose(
  withActions,
  withStyles(styles, { withTheme: true }),
)(OptionsOverlay);
