import { Icon, TextField } from '@mui/material';
import React from 'react';

interface IProps {
  sx?: any;
  value: string | number;
  identifier?: any;
  name?: string;
  disabled?: boolean;
  key?: string;
  multiline?: boolean;
  icon?: string;
  type?: any;
  className?: string;
  onChange?: (value, identifier) => void;
}

const CustomTextField: React.FC<React.PropsWithChildren<IProps>> = (props) => {
  const {
    value = '',
    identifier,
    name,
    sx,
    disabled,
    key,
    multiline = false,
    icon,
    type = 'text',
    className,
    onChange,
  } = props;

  const label = (
    <span>
      {name || identifier}
      {icon && (
        <span>
          {' '}
          <Icon fontSize="inherit">{icon}</Icon>
        </span>
      )}
    </span>
  );
  return (
    <TextField
      {...props}
      sx={sx}
      label={label}
      value={value}
      onChange={(event) => onChange(event.target.value, identifier)}
      multiline={multiline}
      disabled={disabled}
      key={key}
      type={type}
      variant="standard"
      className={className}
    />
  );
};

export default CustomTextField;
