import { FetchPolicy } from '@apollo/client/core';
import { MutationFetchPolicy } from '@apollo/client/core/watchQueryOptions';
import { isBrowser } from 'browser-or-node';
import {
  ArgumentNode,
  DocumentNode,
  FieldNode,
  Kind,
  ObjectFieldNode,
  SelectionSetNode,
  StringValueNode,
  VariableDefinitionNode,
  visit,
} from 'graphql';
import gql from 'graphql-tag';
import { FragmentDefinitionNode } from 'graphql/language/ast';
import _ from 'lodash';
import { v1 as uuidv1 } from 'uuid';

import { ClientManager } from '../../clientManager';
import { exists } from '../../common/commonUtilities';
import { INPUT_SUFFIX, NESTED_SUFFIX } from '../../loadgraphql';
import { Loggers, getLogger } from '../../loggerSupport';
import { MetadataSupport } from '../../metadataSupport';
import {
  CHUNK_ID,
  CODE_TYPE_FIELD,
  DIRECTIVE_DOLOG,
  DIRECTIVE_SUPPRESSLOCALNOTIFY,
  DIRECTIVE_SUPPRESSPIPELINE,
  DIRECTIVE_SUPPRESSPIPELINE_PIPELINES,
  FETCH_POLICY_NO_CACHE,
  IEntityType,
  IFieldNameInfo,
  IHasId,
  IHasIdAndTypeName,
  INCARNATION,
  KEY_FIELD,
  TYPE_NAME,
  UpdateType,
} from '../../metadataSupportConstants';
import { FragmentType } from '../../schemaManager';
import { getChunkId } from '../../sizeClass';
import { TypeDefinition } from '../../typeDefinition';
import { isNullOrUndefined } from '../../utilityFunctions';
import { ConvertType } from '../graphQLManager';
import {
  addMissingFieldsVisitor,
  addTypeNameEntityVisitor,
  removeDoubleUnderscoreStuff,
} from '../graphQLSupport';
import {
  addMissingFields,
  getFieldNameInfo,
  getGraphqlOperation,
  getTopLevelDirective,
  isInOperation,
  printGraphQLDocument,
} from '../graphQLSupportAst';
import {
  makeMutationSelectionSet,
  objectHasAllSelectionSetFields,
} from '../graphQLSupportSelectionSets';
import { PipelineExecutor } from '../pipelineExecutor';
import { PipelineManager } from '../pipelineManager';
import { IStageInfo, IStageProperties } from '../stage';
import { StageImpl } from '../stageImpl';

const logger = getLogger({ name: Loggers.PIPELINE_MANAGER });

export interface IStagePropertiesGraphQLMutation extends IStageProperties {
  mutation: string;
  variables: any;
  queryId: string;
  entityTypeId: string;
  data: Record<string, any>;
  noCache?: boolean;
}

interface IMutationInfo {
  fieldNameInfo: IFieldNameInfo;
  fieldName: string;
  entityType: IEntityType;
  typeDef: TypeDefinition;
  mainVariable: string;
  allVariables: string[];
  suppressLocalNotify: boolean;
}

const execute = async (executor: PipelineExecutor, stage: StageImpl) => {
  const workingProperties =
    stage.workingProperties as IStagePropertiesGraphQLMutation;
  const { pipelineManager, graphQLExecutor } = executor;
  const { clientManager, metadataSupport, schemaManager } = pipelineManager;
  const { mutation } = workingProperties;

  if (clientManager.serverExecutionContext) {
    const { remoteContext, testContext } = clientManager.serverExecutionContext;

    if (!workingProperties._testContext) {
      workingProperties._testContext = testContext;
    }
    if (!workingProperties._requestId) {
      workingProperties._requestId = remoteContext.requestId;
    }
  }

  stage.setupGraphqlInfo(gql(mutation));

  if (stage.getGraphQLOperation().operation !== 'mutation') {
    throw new Error(
      `Unexpected operation for GraphQL mutation: ${
        stage.getGraphQLOperation().operation
      }`,
    );
  }

  const {
    fieldNameInfo,
    fieldName,
    typeDef,
    entityType,
    mainVariable,
    allVariables: allVariableNames,
    suppressLocalNotify,
  } = getMutationInfo(executor, stage.graphQLDocument);

  const unqualifiedTypeName = MetadataSupport.getUnqualifiedName(typeDef.id);

  stage.fieldNameInfo = fieldNameInfo;
  stage.typeDef = typeDef;
  stage.entity = entityType;
  stage.suppressLocalNotify = suppressLocalNotify;

  const { updateType, entityId } = fieldNameInfo;

  const configName =
    schemaManager.getSchemaInfo().entityIdToConfigName[entityId];
  if (!configName) {
    throw new Error(`${entityId} not found in schema info`);
  }

  stage.configName = configName;

  const variables = stage.getGraphQLVariables();
  allVariableNames.forEach((name) => {
    if (variables[name] === undefined) {
      throw new Error(
        `Variable '${name}' in the mutation not found in 'variables' or '_forEachItem'`,
      );
    }
  });

  const fixResults = fixupMutation({
    clientManager,
    stage,
    typeDef,
    fieldNameInfo,
    entityId,
    unqualifiedTypeName,
  });

  const { fetchPolicy, inputField, editedAst, returnSelectionSet } = fixResults;
  if (!inputField) {
    throw new Error('input argument not specified for mutation');
  }

  stage.setupGraphqlInfo(editedAst);

  removeDoubleUnderscoreStuff(variables);

  // Convert the values from the mutation to an object
  const mutationObjects = executor.convertNestedObject(
    inputField,
    _.cloneDeep(variables),
  );

  let inputRecordSelectionSet: SelectionSetNode;

  switch (updateType) {
    case UpdateType.CREATE: {
      const fragment = schemaManager.getFragment(
        entityId,
        FragmentType.SHALLOW_PLUS_ID,
      );
      inputRecordSelectionSet = (
        fragment.definitions[0] as FragmentDefinitionNode
      ).selectionSet;
      inputRecordSelectionSet.selections =
        inputRecordSelectionSet.selections.filter(
          (sel) => (sel as FieldNode).name.value !== INCARNATION,
        );
      break;
    }
    case UpdateType.UPDATE:
    case UpdateType.UPSERT:
      inputRecordSelectionSet = makeMutationSelectionSet({
        mutation: stage.graphQLDocument,
        typeDef,
        clientManager,
        obj: mutationObjects,
      });
      break;
  }

  const codeType = MetadataSupport.getUnqualifiedName(entityType.id);
  mutationObjects.forEach((obj) => {
    // This corresponds to the code in the database provider to automatically
    // populate this; it needs to be done locally so the cache is up to date.
    if (entityType.isCode) {
      (obj as IHasIdAndTypeName)[CODE_TYPE_FIELD] = codeType;
    }

    const visitorFunctions = [addTypeNameEntityVisitor];
    if (updateType === UpdateType.CREATE) {
      // Don't add nulls for update/upsert because those nulls will overwrite
      // valid data in the cache.
      visitorFunctions.push(addMissingFieldsVisitor);
      obj[INCARNATION] = 0;
    } else {
      handleIncarnationForObject({
        pipelineManager,
        obj: obj as IHasIdAndTypeName,
        entityId,
        unqualifiedTypeName,
        fetchPolicy,
      });
    }
    pipelineManager.metadataSupport.metadataVisitor.visitObjectFieldsWithTypeDef(
      {
        obj,
        typeDefName: typeDef.id,
        parentType: typeDef,
        visitorFunctions,
      },
    );

    // Update the chunk id so this ends up in the right place in the cache
    if (exists(entityType.sizeClass)) {
      obj[CHUNK_ID] = getChunkId({
        id: obj.id,
        sizeClass: entityType.sizeClass,
      });
    }
  });

  // FIXME2 why so many clones? - REMOVEME
  const objectsToOptimisticCache = _.cloneDeep(mutationObjects);
  const objectsToOptimisticCacheMap = {};
  for (const obj of objectsToOptimisticCache) {
    metadataSupport.convertObjectReferences({
      obj,
      typeDef,
      convertType: ConvertType.ID_TO_OBJECT_WITH_ONLY_ID,
    });

    // Need to make sure the optimistic response has all fields specified in the mutation
    // return (otherwise the cache mechanism complains). If any fields are missing, just
    // get the object from the cache. If it's not found in the cache, then just add the
    // missing fields as null
    if (
      updateType !== UpdateType.DELETE &&
      returnSelectionSet &&
      !objectHasAllSelectionSetFields(returnSelectionSet, obj)
    ) {
      const cacheData = pipelineManager.apolloClient.cache.readFragment({
        id: `${unqualifiedTypeName}:${obj.id}`,
        fragment: pipelineManager.clientManager.schemaManager.getFragment(
          entityId,
          FragmentType.SHALLOW,
        ),
      });
      objectsToOptimisticCacheMap[obj.id] = Object.assign({}, cacheData, obj);
      if (!cacheData) {
        addMissingFields({
          typeDef,
          selectionSet: returnSelectionSet,
          data: objectsToOptimisticCacheMap[obj.id],
          schemaManager,
        });
      }
    } else {
      objectsToOptimisticCacheMap[obj.id] = obj;
    }
  }

  const objectsToOptimisticCacheArray: Record<string, any>[] = Object.values(
    objectsToOptimisticCacheMap,
  );

  const optimisticResponse = {
    [TYPE_NAME]: 'Mutation',
  };

  optimisticResponse[fieldName] =
    stage.fieldNameInfo.queryType === 'Mutation_Batch'
      ? objectsToOptimisticCacheArray
      : objectsToOptimisticCacheArray[0];

  // Incarnations were updated for the optimistic response; need to have them sent to the server in the batch case
  let newVariables;
  if (updateType !== UpdateType.CREATE && allVariableNames.length === 1) {
    const variableName = allVariableNames[0];
    newVariables = _.cloneDeep(variables);
    if (Array.isArray(optimisticResponse[fieldName])) {
      optimisticResponse[fieldName].forEach(
        (record) =>
          (newVariables[variableName][INCARNATION] = record[INCARNATION]),
      );
    } else {
      // We are assuming if the id (KEY_FIELD) is specified, then this variable represents a whole
      // record, so provide the incarnation
      if (newVariables[variableName]?.[KEY_FIELD]) {
        newVariables[variableName][INCARNATION] =
          optimisticResponse[fieldName][INCARNATION];
      } else {
        newVariables = variables;
      }
    }
  } else {
    newVariables = variables;
  }

  const mutateOptions = {
    mutation: stage.graphQLDocument,
    variables: newVariables,
    optimisticResponse,
    fetchPolicy,
    context: pipelineManager.getGraphQLRequestContext(stage),
  };

  if (fetchPolicy === FETCH_POLICY_NO_CACHE) {
    delete mutateOptions.optimisticResponse;
  }
  logger.debug(
    `Mutation starting: ${printGraphQLDocument(stage.graphQLDocument)}`,
  );

  await graphQLExecutor.doMutate({
    stage,
    mutateOptions,
    inputRecordSelectionSet,
    mainVariable,
    updateType,
    entityId,
  });

  logger.debug(
    `Mutation done: ${printGraphQLDocument(mutateOptions.mutation)}`,
  );
};

function getMutationInfo(
  executor: PipelineExecutor,
  graphqlMutation: DocumentNode,
): IMutationInfo {
  const { pipelineManager } = executor;
  const { schemaManager } = pipelineManager;

  const operation = getGraphqlOperation(graphqlMutation);

  // noinspection JSObjectNullOrUndefined (inspection is wrong)
  const fieldSelection: FieldNode = operation.selectionSet
    .selections[0] as FieldNode;

  const variables = [];
  operation.variableDefinitions.forEach((vd) => {
    variables.push(vd.variable.name.value);
  });

  const returnVal: IMutationInfo = {
    fieldNameInfo: getFieldNameInfo(fieldSelection.name.value),
    fieldName: fieldSelection.name.value,
    entityType: null,
    typeDef: null,
    mainVariable: null,
    allVariables: variables,
    suppressLocalNotify: !!getTopLevelDirective(
      operation,
      DIRECTIVE_SUPPRESSLOCALNOTIFY,
    ),
  };

  const inputArg: ArgumentNode = fieldSelection.arguments[0];
  if (inputArg) {
    if (inputArg.value.kind === 'Variable') {
      returnVal.mainVariable = inputArg.value.name.value;
    }
  }

  const entityType =
    executor.pipelineManager.metadataSupport.getEntityTypeFromUnqualifiedEntityName(
      returnVal.fieldNameInfo.entityId,
    );

  const typeDef = schemaManager.getTypeDefinition({
    name: entityType.typeDefinition,
    configName: entityType.configName,
  });
  returnVal.entityType = entityType;
  returnVal.typeDef = typeDef;

  return returnVal;
}

export function initialize(stageInfo: IStageInfo) {
  stageInfo.executor = execute;
}

function fixupMutation(params: {
  clientManager: ClientManager;
  stage: StageImpl;
  typeDef: TypeDefinition;
  fieldNameInfo: IFieldNameInfo;
  entityId: string;
  unqualifiedTypeName: string;
}): {
  editedAst: DocumentNode;
  refQueryName: string;
  fetchPolicy: MutationFetchPolicy;
  inputField: ArgumentNode;
  returnSelectionSet: SelectionSetNode;
} {
  let refQueryName = null;
  let noCache = false;
  let inputField = null;
  let returnSelectionSet;
  let editedAst;

  const {
    clientManager,
    stage,
    fieldNameInfo: { updateType, queryType },
    typeDef,
    entityId,
    unqualifiedTypeName,
  } = params;

  const { suppressPipelinesOnMutations } = clientManager.pipelineManager;

  const graphqlMutation = stage.graphQLDocument;

  const nestedVariableTypes: { [variableType: string]: boolean } = {};
  visit(graphqlMutation, {
    Variable: {
      enter(node, key, parent, path, ancestors) {
        if (!isInOperation(ancestors)) {
          return;
        }
        const variableName = node.name.value;
        const error = `The variable ${variableName} is used for both a nested type and not. This is not permitted, please split this into two variables`;
        if ((parent as ObjectFieldNode).kind === 'ObjectField') {
          // FIXME2 - this needs to determine if this is a reference to an associated
          // entity, in that case, the reference is not nested, this means we need to look up
          // the attribute by path from the top-level type definition.
          if (nestedVariableTypes[variableName] === false) {
            throw new Error(error);
          }

          const attributePath = [(parent as ObjectFieldNode).name.value];
          for (let a = ancestors.length - 1; a >= 0; a--) {
            if ((ancestors[a] as ObjectFieldNode).kind === 'ObjectField') {
              attributePath.push((ancestors[a] as ObjectFieldNode).name.value);
            }
          }
          attributePath.reverse();
          const itemType = clientManager.metadataSupport.getItemTypeFromPath({
            typeDef,
            path: attributePath,
          });

          if (!itemType.itemInfo.associatedEntity) {
            nestedVariableTypes[variableName] = true;
          }
        } else if (
          (parent as VariableDefinitionNode).kind !== 'VariableDefinition'
        ) {
          if (nestedVariableTypes[variableName]) {
            throw new Error(error);
          }
          nestedVariableTypes[variableName] = false;
        }
      },
    },
  });

  // Update the variable types based on any found nested references
  editedAst = visit(graphqlMutation, {
    NamedType: {
      enter(node, key, parent, path, ancestors) {
        const variableDef =
          ancestors.length > 0 &&
          (ancestors[ancestors.length - 1] as VariableDefinitionNode).kind ===
            'VariableDefinition'
            ? (ancestors[ancestors.length - 1] as VariableDefinitionNode)
            : null;

        const typeName = node.name.value;
        if (
          variableDef &&
          nestedVariableTypes[variableDef.variable.name.value]
        ) {
          let typeDefLocal =
            clientManager.metadataSupport.getTypeDefFromUnqualifiedName(
              typeName,
              true,
            );
          if (!typeDefLocal && typeName.endsWith(INPUT_SUFFIX)) {
            typeDefLocal =
              clientManager.metadataSupport.getTypeDefFromUnqualifiedName(
                typeName.slice(0, -5),
                true,
              );
          }
          if (typeDefLocal && typeDefLocal.useAlternateTypeNameWhenNested) {
            return {
              ...node,
              name: { kind: 'Name', value: node.name.value + NESTED_SUFFIX },
            };
          }
        }
      },
    },
  });
  // Add or remove directives
  editedAst = visit(editedAst, {
    OperationDefinition: {
      enter(node) {
        const newDirectives = node.directives.slice();
        const queryDirective = newDirectives.find(
          (d) => d.name.value === 'query',
        );
        if (queryDirective) {
          _.remove(newDirectives, (d) => d.name.value === 'query');

          if (!queryDirective.arguments[0]) {
            throw new Error('Query directive must include arguments.');
          }

          refQueryName = (queryDirective.arguments[0].value as StringValueNode)
            .value;
        }
        const noCacheDirective = newDirectives.find(
          (d) => d.name.value === 'nocache',
        );
        if (noCacheDirective) {
          _.remove(newDirectives, (d) => d.name.value === 'nocache');
          noCache = true;
        }

        if (
          isBrowser &&
          !newDirectives.find((d) => d.name?.value === DIRECTIVE_DOLOG)
        ) {
          newDirectives.push({
            kind: Kind.DIRECTIVE,
            name: { kind: Kind.NAME, value: DIRECTIVE_DOLOG },
          });
        }

        if (suppressPipelinesOnMutations === true) {
          newDirectives.push({
            kind: Kind.DIRECTIVE,
            name: { kind: Kind.NAME, value: DIRECTIVE_SUPPRESSPIPELINE },
          });
        } else if (Array.isArray(suppressPipelinesOnMutations)) {
          const directive = {
            kind: Kind.DIRECTIVE,
            name: { kind: Kind.NAME, value: DIRECTIVE_SUPPRESSPIPELINE },
            arguments: [
              {
                kind: Kind.ARGUMENT,
                name: {
                  kind: Kind.NAME,
                  value: DIRECTIVE_SUPPRESSPIPELINE_PIPELINES,
                },
                value: {
                  kind: Kind.STRING,
                  value: suppressPipelinesOnMutations.join(','),
                },
              },
            ],
          } as const;
          newDirectives.push(directive);
        } else if (suppressPipelinesOnMutations) {
          throw new Error(
            `Don't understand the value for ${suppressPipelinesOnMutations} - it should be a boolean or an array of strings`,
          );
        }

        return {
          ...node,
          directives: newDirectives,
        };
      },
    },
    // Replace add id if there is no selection set; add system fields that could be updated
    // in the mutation
    Field: {
      enter(node, key, parent, path, ancestors) {
        if (
          ancestors.length === 4 &&
          (ancestors[3] as SelectionSetNode).kind === 'SelectionSet'
        ) {
          const newSelections = node.selectionSet?.selections || [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          ];

          if (!returnSelectionSet) {
            returnSelectionSet = node.selectionSet;
          }
          return {
            ...node,
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                ...newSelections,
                { kind: 'Field', name: { kind: 'Name', value: INCARNATION } },
              ],
            },
          };
        }
      },
    },
    ObjectValue: {
      leave(node, kay, parent, path, ancestors) {
        if (
          ancestors.length === 7 &&
          Array.isArray(ancestors[6]) &&
          (ancestors[6][0] as ArgumentNode).kind === 'Argument'
        ) {
          // FIXME - add id if missing - this is never actually used, see if we should keep this
          let idField;
          if (
            // This only applies to a single create, not a batch create
            queryType === 'Mutation' &&
            updateType === UpdateType.CREATE &&
            !node.fields.find((f) => f.name.value === 'id')
          ) {
            idField = {
              kind: 'ObjectField',
              name: {
                kind: 'Name',
                value: 'id',
              },
              value: {
                kind: 'StringValue',
                value: uuidv1(),
              },
            };
          }
          if (idField) {
            return {
              ...node,
              fields: [...node.fields, idField],
            };
          }
        }
      },
    },
  });

  // Find the input field
  visit(editedAst, {
    Argument: {
      enter(node, kay, parent, path, ancestors) {
        if (
          ancestors.length === 6 &&
          isInOperation(ancestors) &&
          (ancestors[3] as SelectionSetNode).kind === 'SelectionSet' &&
          (ancestors[5] as FieldNode).kind === 'Field'
        ) {
          inputField = node;
        }
      },
    },
  });

  const fetchPolicy =
    noCache || stage.workingProperties.noCache
      ? 'no-cache'
      : stage.getFetchPolicy();
  logger.debug(`mutation fetchPolicy: ${fetchPolicy}`);

  if (
    updateType !== UpdateType.CREATE &&
    fetchPolicy !== FETCH_POLICY_NO_CACHE
  ) {
    // Add the incarnation field/value from the cache
    editedAst = visit(editedAst, {
      ObjectValue: {
        leave(node, key, parent, path, ancestors) {
          if (!isInOperation(ancestors) || ancestors.length !== 7) {
            return;
          }
          const incarnationField: ObjectFieldNode = node.fields.find(
            (f) => f.name.value === INCARNATION,
          );
          if (incarnationField) {
            throw new Error(
              `The ${INCARNATION} field may not be specified, it's for internal use only`,
            );
          }
          const idField: ObjectFieldNode = node.fields.find(
            (f) => f.name.value === 'id',
          );
          if (!idField) {
            return;
          }

          const newIncarnationField = {
            kind: 'ObjectField',
            name: {
              kind: 'Name',
              value: INCARNATION,
            },
            value: {
              kind: 'IntValue',
              value: getNextIncarnationFromCache({
                pipelineManager: clientManager.pipelineManager,
                unqualifiedTypeName,
                entityId,
                objId: (idField.value as StringValueNode).value,
              }),
            },
          };
          return {
            ...node,
            fields: [...node.fields, newIncarnationField],
          };
        },
      },
    });
  }
  return {
    editedAst,
    refQueryName,
    fetchPolicy,
    inputField,
    returnSelectionSet,
  };
}

function getNextIncarnationFromCache(params: {
  pipelineManager: PipelineManager;
  entityId: string;
  unqualifiedTypeName: string;
  objId: string;
}): number | undefined {
  const incarnation = params.pipelineManager.graphQLManager.readIncarnation(
    params.unqualifiedTypeName,
    params.entityId,
    params.objId,
  );
  if (incarnation === undefined) {
    return 0;
  }
  return incarnation + 1;
}

// Returns the new incarnation number, and updates the object with it
function handleIncarnationForObject(params: {
  pipelineManager: PipelineManager;
  entityId: string;
  unqualifiedTypeName: string;
  fetchPolicy: FetchPolicy;
  obj: IHasId;
}) {
  const { pipelineManager, obj, entityId, unqualifiedTypeName, fetchPolicy } =
    params;
  if (!isNullOrUndefined(obj[INCARNATION])) {
    ++obj[INCARNATION];
    return;
  }

  // Don't look in the cache in this case. This is important because in the no-cache
  // case there maybe be updates that want the feature of not actually updating
  // if nothing changed. So we don't provide the incarnation number in that case,
  // this that would count as a change. It's also used to make the testing easier
  // by doing the update with the noCache option.
  if (fetchPolicy !== FETCH_POLICY_NO_CACHE) {
    const incarnation = getNextIncarnationFromCache({
      pipelineManager,
      unqualifiedTypeName,
      entityId,
      objId: obj.id,
    });
    if (incarnation === undefined) {
      obj[INCARNATION] = 0;
    } else {
      obj[INCARNATION] = incarnation;
    }
  }
}
