import React from 'react';
import { ClientManager } from 'universal/clientManager';
import { IUserInfo } from 'universal/permissionManager';

import { IWidgetTrees } from '../components/widgets/types';
import { ChartSupport } from '../util/chartSupport';
import { PresentationSupport } from '../util/presentationSupport';

import { INavigation } from './ApplicationContext';

// We make a class for these fields which are used as part of IClientContext so that we can iterate over them
class ClientContextFields {
  clientManager: ClientManager = null;
  presentationSupport: PresentationSupport = null;
  chartSupport: ChartSupport = null;
  history: any = null;
  userInfo: IUserInfo = null;
  widgetTrees: IWidgetTrees = null;
  navigations: { [id: string]: INavigation } = null;
}

// Used to iterate over these
export const clientContextFields = new ClientContextFields();

export interface IClientContext extends ClientContextFields {
  loadWidgetTrees?: () => Promise<void>;

  // Registers a widget globally
  registerWidget: (params: {
    treeName: string;
    widgetName: string;
    widgetContext: any;
  }) => void;

  // Call this in the cleanup function returned by a useEffect() hook in the component
  // being registered
  deregisterWidget: (params: { treeName: string; widgetName: string }) => void;

  // Executes a callback when the given widget appears
  findWidget: (params: {
    treeName: string;
    widgetName: string;
    callback: (widgetContext: any) => void;
  }) => void;
}

export const ClientContext = React.createContext<IClientContext>(
  {} as IClientContext,
);

// REMOVEME - when all components move to hooks
export function withClient(Comp) {
  return function InitializedComponent(props) {
    return (
      <ClientContext.Consumer>
        {(values) => <Comp {...props} {...values} />}
      </ClientContext.Consumer>
    );
  };
}
