import {
  categories,
  containerProperties,
  duplicateBy,
  IWidgetProps,
  overflow,
} from '../types';

const flexContainerPropertyInfo = {
  ...containerProperties,
  flexDirection: {
    default: "'row'",
    options: ["'row'", "'column'"],
    description: 'Direction that content will flow',
    link: 'https://css-tricks.com/snippets/css/a-guide-to-flexbox/',
  },
  flexWrap: {
    default: "'wrap'",
    options: ["'nowrap'", "'wrap'", "'wrap-reverse'"],
    description: 'How and if to wrap context that overflows the flex direction',
    link: 'https://css-tricks.com/snippets/css/a-guide-to-flexbox/',
  },
  flexGrow: {
    default: 1,
  },
  flexShrink: {
    default: 1,
  },
  justifyContent: {
    default: "'flex-start'",
    options: [
      "'flex-start'",
      "'flex-end'",
      "'center'",
      "'space-between'",
      "'space-around'",
      "'space-evenly'",
    ],
    link: 'https://css-tricks.com/snippets/css/a-guide-to-flexbox/',
  },
  alignItems: {
    default: "'stretch'",
    options: [
      "'flex-start'",
      "'flex-end'",
      "'center'",
      "'stretch'",
      "'baseline'",
    ],
    link: 'https://css-tricks.com/snippets/css/a-guide-to-flexbox/',
  },
  alignContent: {
    default: "'stretch'",
    options: [
      "'flex-start'",
      "'flex-end'",
      "'center'",
      "'space-between'",
      "'space-around'",
      "'stretch'",
    ],
    link: 'https://css-tricks.com/snippets/css/a-guide-to-flexbox/',
  },
  padding: {},
  margin: {},
  overflow,
} as const;

export type FlexContainerProps = IWidgetProps<typeof flexContainerPropertyInfo>;

export const flexContainerTypeInfo = {
  types: {
    children: true,
  },
  childTypes: {
    actions: true,
    overlays: true,
    children: true,
  },
  category: categories.container,
  properties: flexContainerPropertyInfo,
  events: {
    load: [],
    update: [],
    validate: [],
  },
  childProperties: {
    ...duplicateBy,
  },
};
