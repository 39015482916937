import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import { getLogger, Loggers } from 'universal/loggerSupport';
import { stringifyPretty } from 'universal/utilityFunctions';

import {
  ContextMap,
  IData,
  resolveGlobalAddress,
  resolveRawPath,
  SubscribePath,
  WidgetPath,
} from './context';

const loggerData = getLogger({ name: Loggers.CLIENT_DATA });

export interface IRawUpdate {
  rawPath: string;
  data: any;
  append?: boolean;
}

interface IUpdateActionPayload {
  update: IRawUpdate;
  aliases?: ContextMap;
  logger?: () => void;
}

const initialState = {};

export const STATE_DATA = 'data';

const doUpdate = (state, payload: IUpdateActionPayload) => {
  const { update, aliases } = payload;
  const { rawPath, data, append = false } = update;
  const { fullPath } = calculatePathForUpdate({
    rawPath,
    aliases,
    state,
  });
  if (!fullPath) {
    // Error has already been logged
    return;
  }

  if (append) {
    if (!Array.isArray(data)) {
      throw new Error(
        `Append only supports arrays, it was used for non array data ${data}`,
      );
    }
    const existing = _.get(state, fullPath, []);
    _.set(state, fullPath, [...existing, ...data]);
    loggerData.debug(
      {
        path: Array.isArray(fullPath) ? fullPath.join('.') : fullPath,
        data,
      },
      'store - doUpdate (append)',
    );
    return;
  }

  loggerData.debug(
    {
      path: Array.isArray(fullPath) ? fullPath.join('.') : fullPath,
      data,
    },
    'store - doUpdate',
  );
  _.set(state, fullPath, data);
};

export const dataSlice = createSlice({
  name: STATE_DATA,
  initialState,
  reducers: {
    updateContext: (state, action: PayloadAction<IUpdateActionPayload>) => {
      doUpdate(state, action.payload);
      const { logger } = action.payload;
      if (logger) {
        logger();
      }
    },

    updateContextBatch: (
      state,
      action: PayloadAction<{
        updates: IRawUpdate[];
        aliases?: ContextMap;
        logger?: () => void;
      }>,
    ) => {
      const { updates, aliases, logger } = action.payload;
      updates.forEach((update) => {
        doUpdate(state, { update, aliases });
      });

      if (logger) {
        logger();
      }
    },

    clearContext: (state, action: PayloadAction<{ address: WidgetPath }>) => {
      const { address } = action.payload;
      if (_.has(state, address)) {
        _.unset(state, address);
      }
    },
  },
});

export const { updateContext, updateContextBatch, clearContext } =
  dataSlice.actions;

export default dataSlice.reducer;

function findPathWithData(path, widgetPath, state) {
  const getPath = widgetPath.concat('context', path);

  if (_.has(state, getPath)) {
    return getPath;
  }
  if (widgetPath.length <= 1) {
    return null;
  }

  return findPathWithData(
    path,
    widgetPath.slice(0, widgetPath.length - 1),
    state,
  );
}

// takes raw string path, passes that to resolvePath to turn it into an array path with resolved aliases
// returns address which points to the exact store location, defaulting to widgetPath
// and path which represents the location of data at that address
export function calculatePathForUpdate(params: {
  rawPath: string;
  aliases?: ContextMap;
  state: any;
}): { fullPath: string | SubscribePath } {
  const { rawPath, aliases, state } = params;

  if (!aliases) {
    return {
      fullPath: rawPath,
    };
  }
  let fullPath;

  const {
    path,
    aliases: { widgetPath: perspectiveWidgetPath },
  } = resolveRawPath(rawPath, aliases);

  if ((path as IData).data) {
    throw new Error(
      `Tried to do a write on an immutable alias value at: ${stringifyPretty(
        rawPath,
      )}`,
    );
  }

  const tag = (path as SubscribePath).length && path[0][0];

  if (tag && ['/'].includes(tag)) {
    fullPath = resolveGlobalAddress(path as SubscribePath);
  } else {
    fullPath = findPathWithData(path, perspectiveWidgetPath, state);
    if (!fullPath) {
      fullPath = perspectiveWidgetPath.concat('context', path as any[]);
    }
  }

  return {
    fullPath,
  };
}
