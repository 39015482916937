import { AgCharts, AgGauge } from 'ag-charts-react';
import _ from 'lodash';
import { RouteComponentProps } from 'react-router';
import { compose } from 'redux';
import { MapKeys } from 'universal/utilityFunctions';

import { withUiState } from '../../../store/withUiState';
import { withActions } from '../../widgetEngine/ActionEnabler';
import { EventBindingChildWidgetProps, UniversalWidgetProps } from '../types';

import { properties } from './AGChartTypeInfo';

type IProperties = MapKeys<typeof properties>;

type IProps = IProperties &
  EventBindingChildWidgetProps &
  RouteComponentProps &
  UniversalWidgetProps;

const AGChart: React.FC<React.PropsWithChildren<IProps>> = (props) => {
  const { chartOptions } = props;
  switch (chartOptions.type) {
    case 'radial-gauge':
      return renderGague(chartOptions);
      break;
    default:
      return renderStandardChart(chartOptions);
  }
};

function renderStandardChart(chartOptions: any) {
  const thousandsFormatter = (params) => {
    return `${Math.round(parseInt(params.value) / 1000).toLocaleString()}K`;
  };
  const chartOptionsToUse = { ..._.cloneDeep(chartOptions) };
  chartOptionsToUse.axes?.forEach((axis) => {
    if (axis.label?.formatter) {
      switch (axis.label.formatter) {
        case 'thousands':
          axis.label.formatter = thousandsFormatter;
          break;
      }
    }
  });

  return (
    // AgChartsReact component with options passed as prop
    <div
      style={{
        display: 'inline-grid',
        height: '99%' /* FIXME, 100% causes a scrollbar in outer container */,
        width: '100%',
        overflow: 'hidden',
      }}
    >
      <AgCharts options={chartOptionsToUse} />
    </div>
  );
}

function renderGague(chartOptions: any) {
  return (
    // AgChartsReact component with options passed as prop
    <div style={{ height: '100%', width: '100%' }}>
      <AgGauge options={chartOptions} />
    </div>
  );
}
export default compose(withUiState, withActions)(AGChart);
