import React from 'react';

export interface IClientLogDebugContext {
  logs: any;
  logUpdate: (name: string) => void;
  clientDebugMode: boolean;
  setClientDebugMode: (clientDebugMode: boolean) => void;
}

export const ClientLogDebugContext =
  React.createContext<IClientLogDebugContext>({} as IClientLogDebugContext);
