import { SYSTEM } from './common/commonConstants';
import { IFilterInfo } from './filterConstants';
import { IStageProperties } from './pipeline/stage';
import { SizeClass } from './sizeClass';
import { IItemType, TypeDefinition } from './typeDefinition';
export const CONFIG_SEPARATOR = ':';
export const CONFIG_SEPARATOR_ESCAPED = '%3A';

export const DATA_DEFS_DIR = 'dataDefs';
export const APP_DEFS_DIR = 'appDefs';

// Data Defs - keep the constants and array in sync
export const DATA_DEF_ENTITY_TYPE = 'EntityType';
export const DATA_DEF_TYPE_DEFINITION = 'TypeDefinition';
export const DATA_DEFS = [
  DATA_DEF_ENTITY_TYPE,
  DATA_DEF_TYPE_DEFINITION,
] as const;

// App Defs - keep the constants and array in sync - these are exactly the entity types
// FIXME - we should not have these, we should derive these from the entity definitions
export const APP_DEF_APPLICATION = 'Application';
export const APP_DEF_ICON = 'Icon';
export const APP_DEF_INGEST_CONFIG_SET = 'IngestConfigSet';
export const APP_DEF_NAVIGATION = 'Navigation';
export const APP_DEF_PERMISSION = 'Permission';
export const APP_DEF_PIPELINE = 'Pipeline';
export const APP_DEF_ROLE = 'Role';
export const APP_DEF_SYSTEM_CONFIG = 'SystemConfig';
export const APP_DEF_TEST_CONFIG_ENTITY = 'TestConfigEntity';
export const APP_DEF_TEST_DATA_ENTITY = 'TestDataEntity';
export const APP_DEF_TEST_SUPPORTS_TEST_DATA_ENTITY =
  'TestSupportsTestDataEntity';
export const APP_DEF_TRANSFORM = 'Transform';
export const APP_DEF_TRANSFORM_INGEST_DATASET = 'TransformIngestDataset';
export const APP_DEF_VALIDATION_CONFIG = 'ValidationConfig';
export const APP_DEF_WIDGET_TREE = 'WidgetTree';
export const APP_DEFS = [
  APP_DEF_APPLICATION,
  APP_DEF_ICON,
  APP_DEF_INGEST_CONFIG_SET,
  APP_DEF_NAVIGATION,
  APP_DEF_PERMISSION,
  APP_DEF_PIPELINE,
  APP_DEF_ROLE,
  APP_DEF_SYSTEM_CONFIG,
  APP_DEF_TEST_CONFIG_ENTITY,
  APP_DEF_TEST_DATA_ENTITY,
  APP_DEF_TEST_SUPPORTS_TEST_DATA_ENTITY,
  APP_DEF_TRANSFORM,
  APP_DEF_TRANSFORM_INGEST_DATASET,
  APP_DEF_VALIDATION_CONFIG,
  APP_DEF_WIDGET_TREE,
] as const;

// Per-record database fields
// FIXME - these all need to get underscores
export const SORT_KEY = 'system_sortKey';
export const CONFIG_NAME = 'configName';
export const CONFIG_TYPE = 'configType';
export const RECORD_TYPE = 'recordType';
export const KEY_FIELD = 'id';
export const CHUNK_ID = '_chunkId';
export const INCARNATION = '_inc';
export const DEACTIVATION_DATE = '_deactivationDate';
export const CREATION_TIME = '_creationTime';
export const UPDATE_TIME = '_updateTime';

// These are not included in typedefs, but they are included in the GraphQL for each type so they
// may be read or written
export const SYSTEM_QUERYABLE_FIELDS = {
  [INCARNATION]: 'Int',
  [DEACTIVATION_DATE]: 'String',
  [CREATION_TIME]: 'String',
  [UPDATE_TIME]: 'String',
};

// System entity names
export const SYSTEM_INGEST_CONFIG_SET = `${SYSTEM}${CONFIG_SEPARATOR}${APP_DEF_INGEST_CONFIG_SET}`;

export const SYSTEM_DATA_OPERATION_ACTION_INSTANCE = `${SYSTEM}${CONFIG_SEPARATOR}DataOperationActionInstance`;
export const SYSTEM_DATA_OPERATION_UNIT = `${SYSTEM}${CONFIG_SEPARATOR}DataOperationUnit`;
export const SYSTEM_DATA_OPERATION_CHUNK = `${SYSTEM}${CONFIG_SEPARATOR}DataOperationChunk`;

export const CODE = 'Code';
export const SYSTEM_CODE = `${SYSTEM}${CONFIG_SEPARATOR}Code`;
export const CODE_MEMBERSHIP = 'CodeMembership';

export const ENTITY_INCARNATIONS = '_EntityIncarnations';
export const PIPELINE_EXECUTION = '_PipelineExecution';

// The root code type
export const CODE_TYPES_TYPE = '_Type';

// Code support
export const CODE_TYPE_FIELD = 'codeType';
export const CODE_FIELD = 'code';

// Ingest/transform
export const CHUNK_NUMBER = '_chunkNumber';
export const INGEST_INSTANCE_FIELD = '_ingestInstance';
export const VALUE_CHUNK_FIELD = '_valueChunk';
export const RECORD_NUMBER_FIELD = '_recordNumber';

export const SINGLETON_ID = '1';

// This should match the names in attributes array in dataUtilities
export const ingestAttributes = [
  CHUNK_NUMBER,
  KEY_FIELD,
  INGEST_INSTANCE_FIELD,
  RECORD_NUMBER_FIELD,
];

// Query
export const DIRECTIVE_IDIFNOTFOUND = 'idifnotfound';
export const DIRECTIVE_IGNORE_SIZECLASS = 'ignoresizeclass';
export const DIRECTIVE_OUTPUTPIPELINE = 'outputpipeline';
export const DIRECTIVE_OUTPUTPIPELINE_NAME = 'name';
export const DIRECTIVE_OUTPUTPIPELINE_ARGUMENTS = 'arguments';
export const DIRECTIVE_OUTPUTPIPELINE_LOGOUTPUT = 'logoutput';
export const DIRECTIVE_OUTPUTPIPELINE_LOGEXECUTION = 'logexecution';
export const DIRECTIVE_OUTPUTPIPELINE_AGGREGATE_PIPELINE = 'aggregatepipeline';
export const DIRECTIVE_PAGED = 'paged';

// Mutation
export const DIRECTIVE_FORCEUPDATE = 'forceupdate';
export const DIRECTIVE_MAYBE = 'maybe';
export const DIRECTIVE_OVERWRITE = 'overwrite';
export const DIRECTIVE_DOLOG = 'dolog';
export const DIRECTIVE_REASON = 'reason';
export const DIRECTIVE_REASON_CODE_ID = 'codeid';
export const DIRECTIVE_SUPPRESSLOG = 'suppresslog';
export const DIRECTIVE_SUPPRESSLOCALNOTIFY = 'suppresslocalnotify';
export const DIRECTIVE_SUPPRESSNOTIFY = 'suppressnotify';
export const DIRECTIVE_SUPPRESSPIPELINE = 'suppresspipeline';
export const DIRECTIVE_SUPPRESSPIPELINE_PIPELINES = 'pipelines';
// Turns off loadintomemory, does suppresslog, suppresspipelines
export const DIRECTIVE_BULK_UPDATE = 'bulkupdate';

// Field
export const DIRECTIVE_ACTIVEASOF = 'activeasof';
export const DIRECTIVE_ACTIVEASOF_DATE = 'date';
export const DIRECTIVE_ACTIVEASOF_DURATION = 'duration';
export const DIRECTIVE_FILTER = 'filter';
export const DIRECTIVE_FILTER_EXPR = 'expr';
export const DIRECTIVE_REPLACE = 'replace';
export const DIRECTIVE_DELETE = 'delete';
export const DIRECTIVE_REPLACE_DELETE_PATH = 'path';
// Internal use only
export const DIRECTIVE_QUERYID = 'queryid';
export const DIRECTIVE_QUERYID_ID = 'id';

// Schema Field
export const DIRECTIVE_ENTITY_TYPE = 'entitytype';
export const DIRECTIVE_ENTITY_TYPE_NAME = 'name';

// Specified as variable name for a query
export const FILTER_MODEL = '_filterModel';

export const ID_NOT_FOUND = '__idNotFound__';
export const TYPE_NAME = '__typename';

// Used on an update to indicate this object fully replaces the database object
export const MARKER_REPLACE = '_replace';

// Used on an update to indicate this object is to be deleted
export const MARKER_DELETE = '_delete';

// Constants for IQueryConnectionResult
export const ITEMS = 'items';
export const NEXT_TOKEN = 'nextToken';
export const AGGREGATE_PIPELINE_OUTPUT = 'aggregatePipelineOutput';
export const CONNECTION_COUNT = 'count';
export const PAGE_NUMBER = 'pageNumber';
export const SCANNED_COUNT = 'scannedCount';

export const FETCH_POLICY_NO_CACHE = 'no-cache';

// Predefined query arguments
export const QUERY_ARG_LIMIT = '_limit';
// Limits the size of a page when the @paged directive is used
export const QUERY_ARG_PAGE_LIMIT = '_pageLimit';
// FIXME - this should have an _, change that when there is time - see WORM-3481
export const QUERY_ARG_NEXT_TOKEN = NEXT_TOKEN;
export const QUERY_ARG_TEST_DATA = '_testData';

export const QUERY_LIST_SUFFIX = 'List';

// Graphql server errors
export const GRAPHQL_ERROR_RECORD_ALREADY_EXISTS = 'RecordAlreadyExists';

// The 'data' attribute is used in a TypeDefinition for a configuration object that is represented
// by text, rather than YAML, like an Icon SVG file.
export const TEXT_DATA_ATTRIBUTE = 'data';

export const GRAPHQL_DIRECTIVE_PUNCTUATOR = '@';

export interface IHasId {
  id: string;
}

export interface IHasIdAndData extends IHasId {
  [TEXT_DATA_ATTRIBUTE]: string;
}

export interface IHasIdAndTypeName extends IHasId {
  [TYPE_NAME]: string;
}

export enum ConfigUpdateReasons {
  THROTTLE_EVENT = 'THROTTLE_EVENT',
  THROTTLE_REQUEST = 'THROTTLE_REQUEST',
  INCREASE = 'INCREASE',
}

export interface IConfigUpdateLog {
  id: string;
  searchKey: string;
  fieldUpdated: string;
  reason: ConfigUpdateReasons;
  newIntValue: number;
  deltaIntValue: number;
  timestamp: string;
}

export interface IEntityKeyType {
  fields: string[];
  sizeClass?: SizeClass;
  unique?: boolean;
  primaryKey?: boolean;
}

export interface IHasConfigName extends IHasId {
  configName: string;
}

// Any object that is in the configuration
export interface IConfigItem extends IHasId {
  [configFieldType: string]: string | any;
}

export interface IConfigItems<Type extends IConfigItem> {
  [id: string]: Type;
}

export interface IConfigItemsByType {
  [recordType: string]: IConfigItem[];
}

export interface IConfigItemText extends IConfigItem {
  [TEXT_DATA_ATTRIBUTE]: string;
}

export interface IPipelineQueueReference {
  pipelineName: string;
  queueName: string;
}

export type DatabaseProviderType = 'dynamo' | 's3';

export interface IEntityType extends IHasConfigName {
  keys?: IEntityKeyType[];
  databaseType?: DatabaseProviderType;
  loadIntoMemory?: boolean;
  typeDefinition: string;
  includeConfigNameInId?: boolean;
  isConfiguration: boolean;
  createPipelines?: IPipelineQueueReference[];
  updatePipelines?: IPipelineQueueReference[];
  deletePipelines?: IPipelineQueueReference[];
  nameInfo: INameInfo;
  isCode?: string;
  useProductionExecConfig?: boolean;
  supportsTestData?: boolean;
  neverUseTestData?: boolean;
  sizeClass?: SizeClass;
  fileExtension?: string;
  aliasMappingField?: string;
  useUpsertOnConfigLoad?: boolean;
  useInDumpRestore?: boolean;
  restApiDefaultPageLimit?: number;
  // Only if database provider is s3
  s3Path?: string;
  // Computed
  typeDefinitionObject?: TypeDefinition;
  unqualifiedId?: string;
}

export enum CollectionTypes {
  ARRAY = 'ARRAY',
  MAP = 'MAP',
}

export interface IRegExpReplacement {
  regexp: string;
  replacement: string;
}

export interface IDerivedField {
  fieldName: string;
  toDerivedField: IRegExpReplacement;
  fromDerivedField: IRegExpReplacement;
}

export interface INameInfo {
  abbreviation?: string;
  displayName?: string;
  shortDescription?: string;
  longDescription?: string;
  originalNames?: string[];
}

// Matches system:SystemConfig
export interface ISystemConfig extends IHasConfigName {
  id: string;
  userMaintConfig: ISystemUserMaintConfig;
  defaultLocale: string;
  displayName: string;
}

export interface ISystemUserMaintConfig {
  editWidgetTreeName: string;
  // FIXME - below is DateTime
  usageReportLastUpdated: string;
  userReportTypeDef: string;
  userReportPipeline: string;
}

export type PresentationVisibility =
  | 'default'
  | 'show'
  | 'allowed'
  | 'exclude'
  | 'internalOnly';

// Matches Navigation typedef
export interface INavigation extends IHasConfigName {
  showIcons: boolean;
  members: INavItem[];
}

export interface INavItem {
  name: string;
  nameInfo: INameInfo;
  // Link to another Navigation
  link?: string;
  internalView?: string;
  internalLink?: 'widgetTrees';
  url?: string;
  icon?: string;
  members?: INavItem[];
  defaultOpen?: boolean;
  widgetTreeName?: string;
  // enriched with this
  configName: string;
}

export interface IPresentationInfo {
  filterInfo?: IFilterInfo[];

  /**
   * When specifying whether a column should be visible or not default renders
   * the column, allowed adds the column to the tool panel but does not render
   * the column, exclude does not render the column and internal only TODO hide
   * the column by default for internal users but add it to the tool panel
   */
  presentationVisibility?: PresentationVisibility;

  /**
   * When making a list, this column is included, If the entity's type has a
   * label, that's always included
   */
  includeAsListColumn?: boolean;

  /** Specifies the order in which this attribute is shown (like for list columns) */
  order?: number;

  /**
   * Used at the typedef level, specifies the field to be shown as the label for
   * this object
   */
  label?: string;

  /**
   * Used at the typedef level, specifies Javascript code to calculate the label
   * for this object
   */
  labelCode?: string;

  /** GraphQL code that provides the label of this instance. */
  labelCodeGraphql?: string;

  /**
   * Javascript code to calculate the field value for this object's presentation
   * based on the value of the attribute of the typedef
   */
  fieldCode?: string;

  /**
   * Function to render HTML (by means of React elements) for this value. Returns
   * a ReactElement
   */
  renderReactElements?: (attr: IItemType, data: any, react: any) => any;

  /**
   * Used for an additional presentation attribute to indicate the actual
   * attribute name in the data that to be used.
   */
  attributeNameOverride?: string;

  /** This attribute is editable in a grid for example. */
  editable?: boolean;

  /** Stages for an update pipeline for this attribute or typedef. */
  updatePipeline?: IStageProperties[];

  /** Stages for a pipeline for a cut action. */
  cutCode?: IStageProperties[];

  /** Stages for a pipeline for a paste action.. */
  pasteCode?: IStageProperties[];

  /** Code snippet for permitting a paste */
  isPasteAllowedCode?: string;

  /** Code snippet for permitting a drag start */
  isDragAllowedCode?: string;

  /** Don't show this attribute in the log viewer. */
  suppressLogViewer?: boolean;

  /** Formatting of this item */
  presentationFormat?: IPresentationFormat;

  /** Grid Info */
  gridFormat?: IPresentationGridFormat;

  /** Options for dropdown */
  options?: any[];
  optionLabel?: string;
  optionValue?: string;

  /** Allow a copy action on this object */
  enableCopy?: boolean;

  /** Allow a paste action on this object */
  enablePaste?: boolean;
  /**
   * For pages with multiple potential sources of data, the data block must
   * contain one of these strings in __typeName field to be accepted, this is
   * required for groups to accept data
   */
  allowedPasteTypes?: string[];

  // FIXME Below field does not work across widgets until we are able to get the source typedef
  /**
   * On a paste we try and get the type of the __typeName field, but sometimes
   * within the same object we don't have any data (a group drag)
   */
  pasteType?: string;

  /** Allow an item within the object to be dragged */
  itemEnableDrag?: boolean;

  /** Allow an item within the object to be selected via checkbox */
  itemEnableCheckboxSelect?: boolean;

  /** Max column width in pixels */

  maxWidth?: number;

  /** Min column width in pixels */

  minWidth?: number;
  /**
   * The grid can set column width normally, but, when we have a widget in a
   * column it doesn't know how wide it is
   */
  fixedWidth?: number;

  /**
   * Allows setting a default width for a column, unlike min/max/fixed it can be
   * overridden by the user
   */
  defaultWidth?: number;
  /** Icon to display See ICON_DESCRIPTION in widgets.ts for icon syntax */

  icon?: string;

  /**
   * Use this for items that we typically only want to show to SnapStrat
   * internal users, not a permission, this is a preference
   */
  internalOnly?: boolean;

  /** Overrides the persistKey in the widget properties. */
  persistKey?: string;
}

export interface IPresentationFormat {
  minimumFractionDigits?: number;
  maximumFractionDigits?: number;
  style?: PresentationStyleType;
  currency?: string;
  useGrouping?: boolean;
  notation?: NotationType;

  /** If style is ratio, we need the field names below to calculate */
  ratio?: {
    x: string;
    y: string;
  };
  /** If style is ratio, we need the field names below to calculate */
  margin?: {
    cost: string;
    revenue: string;
    revenueQuantity?: string;
    costQuantity?: string;
  };
  /** The widget tree to render this attribute */
  widgetTreeName?: string;
}

export interface IPresentationGridFormat {
  /** Specifies if AGGrid presentation is utilizing masterDetail formatting */
  useMasterDetail?: boolean;
  /** Specifies if IItemType child content is a detail grid, to hide column from display */
  isDetailGridContent?: boolean;
  /** Specifies standard function fot total aggregation */
  totalFooter?: gridAggregationType;
  /** Specifies js code for total footer (rest of row in context) */
  totalFooterCode?: string;
  /** Notation for footer -- may be different than the notation of a cell in the column */
  footerPresentationFormat?: IPresentationFormat;

  /** The unique identification of a row in a grid, defaults to 'id' */
  rowId?: string;
  /**
   * Used with a grid to indicate that there is a column to be associated with
   * each member of this array.
   */
  columns?: any[];

  /** Used with a grid to identify the name of the column array within the main array. */
  columnArrayFieldName?: string;

  /** Used with a grid to identify each column when 'columns' is specified. */
  columnId?: string;

  /** Pin column */
  pinned?: 'left' | 'right';

  /** Group column by default */
  rowGroup?: boolean;

  /** Allow column to be grouped by user in group panel */
  enableRowGroup?: boolean;
  /** Grid by default groups by column sequence, this allows a specific group sequence */
  rowGroupIndex?: number;
  /**
   * TODO we need a interface for groups. Right now see pitch organizer Used
   * with a grid to indicate that there is a set of predefined groups that
   * (master/detail) that we want to display regardless of whether they exist in
   * the rows. For groups that are derived from the data, use rowGroup only.
   */
  groups?: any[];
  /**
   * If we have nested groups and some are specified with the groups object, we
   * will need to manually sort other groups but may not want to add new members
   * of that group
   */
  groupsSortOnly?: boolean;

  /**
   * Filters objects that are the children of a group to include them in the
   * child count as shown in the group header
   *
   * @param rowData The row that's a child of the group
   */
  groupChildrenCountFilter?: (rowData: any) => boolean;

  /** If true put a checkbox in the group header (single/multi column only) TODO: Does not work for rowGroups */
  groupCheckboxOn?: boolean;

  /** Tells grid whether to allow a paste on a row */
  allowRowPaste?: boolean;

  /** Code to run on a paste row */
  pasteRowCode?: IStageProperties[];

  /** Ag-grid requires destination widgets to be registered */
  destinationWidgets?: string[];

  /** Filter type for a column */
  filterType?: IFilterType;

  /** Set true if column should wrap text */
  wrapText?: boolean;

  /** Use in conjunction with wrapText to autosize columnHeight */
  autoHeight?: boolean;
  /** Hide column on initial render */

  hide?: boolean;

  /**
   * For columns with icons or widgets we may want to have no header to keep
   * column widths narrow, but we still want the name of the field to appear in
   * column selecting and filtering which comes from the display name.
   */
  hideHeader?: boolean;

  /** Name of the widget in the widgetPool to render for cells in this column */
  widgetName?: string;

  /**
   * Name of the widget in the widgetPool to render in the group header if this
   * is a grouped column
   */
  groupWidgetName?: string;

  /** When we give data to a widget it may expect the data in a different field */
  rowAlias?: string;

  /**
   * For a paged query, we get the data from the associated entity, but may want
   * to filter the potential values
   */
  filterFilterDirective?: string;

  /** A comma separated list of fields required to execute the filter directive */
  filterFilterDirectiveFields;

  /**
   * If this is in a set of child columns we need at least 1 column to be always
   * visible in order to allow show/hide of column groups. The grid will set it
   * to the leftmost column if all columns are set to open. See
   * https://www.ag-grid.com/react-data-grid/column-groups/#showing--hiding-columns
   */
  columnGroupShow?: string;
  /* In the master grid, run this pipeline to dynamically get detail rows (return in detailRows) */

  detailRowGetterPipeline?: IStageProperties[];

  /**
   * If we want to allow an app to inject CSS or standard formats into this cell
   * via the _formatting field in the row, set this to true
   */
  useFormatting?: boolean;

  /**
   * If the formatting always applies, or we can provide a simple condition
   * specify the format string below
   */
  formatString?: string;

  /** If we want to specify the formatting programatically then use a JS code snippet */
  formatStringCode?: string;

  /**
   * JS code, with the row in context to determine when to apply the formatting
   * must return a boolean
   */
  formatCondition?: string;

  /*  The preferred way of styling is through Standardized styles */
  formatStandardStyling?: IGridCssStyling[];

  /* TODO: implement this. if we want Excel styling that is different than the cell we can put it here https://ag-grid.com/react-data-grid/excel-export-styles/ */
  excelStyles?: any;

  /** excelFormula will have the grid export a formula instead of a value in a column. To insert a cell reference in a formula use '_{name}_'
   * You do not need to insert the leading '=' in a formula
   */
  excelFormula?: string;

  /** Do not display the underlying value in the cell -- this is used primarily for heat maps */

  suppressRender?: boolean;
}

export interface IGridCssStyling {
  /* This class must be in the AGGridCellRenderers standardCSSFormats table and the associated style(s) in styles.css file */
  standardStyle: string;
  /* some classes require parameters (e.g heat map) */
  parameters?: IKeyValueType[];
}

export interface IKeyValueType {
  key: string;
  value: string;
}

export interface IFieldNameInfo {
  fieldName: string;
  entityId: string;
  updateType: UpdateType;
  queryType?: QueryType;
}

export interface IAggregationInfo {
  entityType: string;
  sourceEntityType?: string;
  sourceKeys?: IAggregationInfoSourceKey[];
  order?: number;
  mappedFields?: IAggregationInfoFieldMapping[];
  originalField?: string;
}
export interface IAggregationInfoFieldMapping {
  sourceField: string;
  mappedField: string;
}

export interface IAggregationInfoSourceKey {
  sourceKey: string;
  sourceKeyLookup: string;
}

export const ACTION_CREATED = 'Created';
export const ACTION_UPDATED = 'Updated';
export const ACTION_DELETED = 'Deleted';

// Must match above constants
export type ActionType = 'Created' | 'Updated' | 'Deleted';

export enum UpdateType {
  CREATE,
  UPDATE,
  UPSERT,
  DELETE,
  NOT_UPDATE,
}
export type gridAggregationType =
  | 'sum'
  | 'min'
  | 'max'
  | 'count'
  | 'avg'
  | 'first'
  | 'last';
export type PresentationStyleType =
  | 'decimal'
  | 'currency'
  | 'percent'
  | 'ratio'
  | 'margin'
  | 'unit'
  | 'month'
  | 'year';

export type NotationType =
  | 'standard'
  | 'comma'
  | 'compact'
  | 'scientific'
  | 'engineering'
  | 'thousands'
  | 'millions'
  | 'billions';

export type QueryType =
  | 'Mutation'
  | 'Mutation_Batch'
  | 'Get'
  | 'List'
  | 'Subscription'
  | 'Not_Query';

export type IFilterType = 'agTextFilter' | 'agSetColumnFilter';

export type QualifiedName = string;
