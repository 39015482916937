import { FunctionComponent, useContext } from 'react';
import { Route, Switch } from 'react-router';
import { Redirect } from 'react-router-dom';

import TreeRenderer from '../../components/widgetEngine/TreeRenderer';
import { IPassThroughProps } from '../../components/widgets/types';
import BreadCrumb from '../../util/BreadCrumbs';
import { escapeUrlPath } from '../../util/clientUtilities';
import { ClientContext } from '../../wrappers/ClientContext';

const Preview: FunctionComponent<
  React.PropsWithChildren<{
    initialPassThroughProps: IPassThroughProps;
    localEditTreeName?: string;
  }>
> = (props) => {
  const { initialPassThroughProps, localEditTreeName } = props;
  const { widgetTrees } = useContext(ClientContext);

  const previewRoutes = Object.keys(widgetTrees).map((treeName) => {
    const tree = widgetTrees[treeName];
    return (
      <Route path={`/editTrees/${escapeUrlPath(treeName)}`} key={treeName}>
        <BreadCrumb url={'/' + escapeUrlPath(treeName)} label={treeName}>
          <TreeRenderer
            tree={tree}
            key={treeName}
            initialPassThroughProps={initialPassThroughProps}
          />
        </BreadCrumb>
      </Route>
    );
  });

  return (
    <Switch>
      {previewRoutes}
      {localEditTreeName && (
        <Route>
          <Redirect to={`/editTrees/${escapeUrlPath(localEditTreeName)}`} />
        </Route>
      )}
    </Switch>
  );
};

export default Preview;
