import { useContext, useEffect } from 'react';
import { withRouter } from 'react-router';
import { compose } from 'redux';

import { NavContext, navReducerTypes } from '../navigation/NavLayout';

import { generateSimpleChildren, withActions } from './dependencies';

const Page = (props) => {
  const { pageTitle } = props;

  const { breadCrumbs, dispatchNavState } = useContext(NavContext);

  const newTitle = pageTitle || createTitleFromBreadCrumbs(breadCrumbs);

  useEffect(() => {
    dispatchNavState({
      type: navReducerTypes.TITLE_UPDATE,
      payload: newTitle,
    });
  }, [newTitle, dispatchNavState]);

  return generateSimpleChildren(props);
};

function createTitleFromBreadCrumbs(breadCrumbs) {
  return breadCrumbs
    .filter((breadCrumb) => breadCrumb.url !== '/')
    .map((breadCrumb) => breadCrumb.label)
    .reverse()
    .join(' ');
}

export default compose<any>(withActions, withRouter)(Page);
