// FIXME - for some reason just importing from exceljs does not consider the index.d.ts file,
// and importing the index.d.ts file works for the compiler, but fails when it is run. Need to figure this out
//import ExcelJs from 'exceljs/index.d.ts';
import ExcelJs from 'exceljs';

import { ExcelSupport } from './excelSupport';
import { SchemaManager } from './schemaManager';
import { TypeDefinition } from './typeDefinition';

export class ExcelWorkbook {
  public schemaManager: SchemaManager;

  public workbook: ExcelJs.Workbook;

  public sheet: ExcelJs.Worksheet;

  constructor(params: {
    excelSupport: ExcelSupport;
    typeDef: TypeDefinition;
    sheetName: string;
  }) {
    const { typeDef, sheetName } = params;

    this.workbook = new ExcelJs.Workbook();
    this.workbook.created = new Date();
    this.sheet = this.workbook.addWorksheet(sheetName, {
      pageSetup: {
        orientation: 'landscape',
        showGridLines: true,
      },
    });

    this.sheet.columns = [];
    let columnIndex = 1;
    for (const attr of typeDef.getVisiblePresentationAttributesInOrder()) {
      const column = this.sheet.getColumn(columnIndex++);
      column.header = attr.nameInfo.displayName;
      column.key = attr.name;
    }

    //console.log(this.sheet);
  }

  public addRow(content) {
    this.sheet.addRow(content);
  }

  public async writeFile(fileName: string) {
    await this.workbook.xlsx.writeFile(fileName);
  }
}
