import safeJsonStringify from 'safe-json-stringify';

import { getLambdaFunctionFullName } from '../../common/commonUtilities';
import { retry } from '../../errors/retry';
import { getLogger, Loggers } from '../../loggerSupport';
import { PipelineExecutor } from '../pipelineExecutor';
import { IStageInfo, IStageProperties } from '../stage';
import { StageImpl } from '../stageImpl';

const logger = getLogger({ name: Loggers.PIPELINE_MANAGER });

export interface IStagePropertiesLambda extends IStageProperties {
  functionName: string;
  payload: any;
}

const execute = async (executor: PipelineExecutor, stage: StageImpl) => {
  const { functionName, payload } =
    stage.workingProperties as IStagePropertiesLambda;
  const fullFunctionName = getLambdaFunctionFullName(
    executor.pipelineManager.clientManager.stackId,
    functionName,
  );

  let body;
  if (payload) {
    if (typeof payload === 'string') {
      body = JSON.parse(payload);
    } else {
      body = payload;
    }
  }

  logger.debug(body, `Before: ${fullFunctionName}: `);

  const rawResponse = await retry({
    command: async () =>
      executor.pipelineManager.clientManager.lambdaSupport
        .getLambdaClient(functionName)
        .invoke({
          FunctionName: fullFunctionName,
          Payload: safeJsonStringify({ body: safeJsonStringify(body) }),
        }),
  });

  logger.debug(rawResponse, `After: ${fullFunctionName}`);

  const response = JSON.parse(new TextDecoder().decode(rawResponse.Payload));
  if (response.body && typeof response.body === 'string') {
    response.body = JSON.parse(response.body);
  }
  executor.recordResult(stage, response);
};

export function initialize(stageInfo: IStageInfo) {
  stageInfo.executor = execute;
}
