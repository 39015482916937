import { Paper } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import html2canvas from 'html2canvas';
import jspdf from 'jspdf';
import { compose } from 'redux';

import CustomButton from '../atoms/CustomButton';
import { withActions } from '../widgetEngine/ActionEnabler';
import Container from '../widgetEngine/Container';

const styles = () => ({});

class PDFContainer extends Container {
  public declare props;
  constructor(props) {
    super(props);
    this.state = {
      width: 816,
      height: 1056,
    };
    this.printPDF = this.printPDF.bind(this);
  }

  async printPDF() {
    const { overSample, orientation } = this.props;

    const dimentions = {
      portrait: {
        width: 816,
        height: 1056,
      },
      landscape: {
        width: 1056,
        height: 816,
      },
    };

    const { height, width } = dimentions[orientation];

    const date = new Date();
    const shortDateString = date.toLocaleDateString('en-US', {
      month: 'numeric',
      day: 'numeric',
      year: 'numeric',
    });

    const element = document.getElementById('content');
    const currentCanvas = await html2canvas(element, { scale: overSample });
    const dataUrl = currentCanvas.toDataURL('image/jpeg', 0.92);

    const pxToMM = 0.2646;
    const heightInMM = Math.round(height * pxToMM);
    const widthInMM = Math.round(width * pxToMM);
    // const orientation = height > width ? 'p' : 'l';

    const pdf = new jspdf({
      orientation,
      unit: 'mm',
      format: [heightInMM, widthInMM],
    });

    pdf.addImage(dataUrl, 'PNG', 0, 0, widthInMM, heightInMM);
    pdf.save(`${shortDateString}.pdf`);
  }

  render() {
    const { orientation } = this.props;

    const dimentions = {
      portrait: {
        width: 816,
        height: 1056,
      },
      landscape: {
        width: 1056,
        height: 816,
      },
    };

    const { height, width } = dimentions[orientation];

    const Children = this.generateChildren();

    const controls = (
      <div>
        <CustomButton icon="save" onClick={this.printPDF} />
      </div>
    );

    return (
      <div style={{ height: '100%', width: '100%' }}>
        {controls}
        <Paper
          style={{
            height,
            width,
            overflow: 'hidden',
          }}
        >
          <div
            style={{
              height,
              width,
            }}
            id="content"
          >
            {Children}
          </div>
        </Paper>
      </div>
    );
  }
}

export default compose(
  withActions,
  withStyles(styles, { withTheme: true }),
)(PDFContainer);
