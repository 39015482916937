import { LicenseManager } from '@ag-grid-enterprise/core';
import { AgCharts } from 'ag-charts-enterprise';
import LogRocket from 'logrocket';
import {
  Component,
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from 'react';
import { ClientManager, createClientManager } from 'universal/clientManager';
import { COMPANY_NAME } from 'universal/common/commonConstants';
import { reThrow } from 'universal/errors/errorLog';
import { getConfigItemsForType } from 'universal/loadStore/loadstore';
import { LogLevels, Loggers, getLogger } from 'universal/loggerSupport';
import { APP_DEF_NAVIGATION } from 'universal/metadataSupportConstants';
import { StackInfoKeys } from 'universal/stackInfo';

import Loading from '../components/atoms/Loading';
import { IWidgetTrees } from '../components/widgets/types';
import { setupUIStatePersistence } from '../store/persistUIState';
import { useAppDispatch } from '../store/storeHooks';
import { setInitialState } from '../store/uiState';
import { ChartSupport } from '../util/chartSupport';
import { readWidgetTrees } from '../util/clientUtilities';
import { downloadFile } from '../util/downloads';
import { isDev, isTesting } from '../util/environment';
import { PresentationSupport } from '../util/presentationSupport';
import { dbToClient } from '../util/transformWidgetTree';
import { ITestEnvironment } from '../util/widgetUtilities';

import configsImportMap from '../../configImports';
import Application from './Application';
import { INavigation } from './ApplicationContext';
import { AuthenticatorContext } from './AuthenticatorContext';
import { ClientContext, IClientContext } from './ClientContext';
import ClientLogDebug from './ClientLogDebug';

const logger = getLogger({
  name: Loggers.CLIENT_INITIALIZE,
  level: LogLevels.Info,
});

export const Client: FunctionComponent<
  React.PropsWithChildren<{
    testEnvironment?: ITestEnvironment;
  }>
> = (props) => {
  const { testEnvironment, children } = props;

  const dispatch = useAppDispatch();
  const { stackInfo, history, isAuthenticated, getIdToken, logout } =
    useContext(AuthenticatorContext);

  const stackConfig = stackInfo.getStackConfig();
  process.env.KENDO_UI_LICENSE = stackConfig.kendoUILicense;

  LicenseManager.setLicenseKey(stackConfig.agGridLicense);
  AgCharts.setLicenseKey(stackConfig.agChartsLicense);

  const isAuthenticatedValue = isAuthenticated();
  const [clientContext, setClientContext] = useState<IClientContext>(null);

  const loadWidgetTrees = async (
    clientManager: ClientManager,
    force = false,
  ): Promise<IWidgetTrees> => {
    await clientManager.stackInfo.refreshStackInfo(force);
    const widgetTreesFromConfig = readWidgetTrees(clientManager);
    // FIXME - this takes over 3 seconds. This will go away when we encode the widget trees
    // as real components
    const convertedWidgetTrees = dbToClient(
      widgetTreesFromConfig,
      clientManager,
    );
    const widgetTrees = {} as IWidgetTrees;
    convertedWidgetTrees.forEach((tree) => (widgetTrees[tree.id] = tree));
    return widgetTrees;
  };

  useEffect(() => {
    const testing = isTesting();

    const createClientContext = (
      contextObject: Omit<
        IClientContext,
        'registerWidget' | 'deregisterWidget' | 'findWidget'
      >,
    ) => {
      const makeKey = (treeName: string, widgetName: string) =>
        `${treeName}_${widgetName}`;

      const internalContextObject: IClientContext & {
        widgetRegistry: { [key: string]: Component };
        findCallersRegistry: { [key: string]: any[] };
      } = {
        ...contextObject,
        widgetRegistry: {},
        findCallersRegistry: {},
      } as any;

      internalContextObject.registerWidget = (params: {
        treeName: string;
        widgetName: string;
        widgetContext: any;
      }) => {
        const { treeName, widgetName, widgetContext } = params;
        const key = makeKey(treeName, widgetName);
        const { widgetRegistry, findCallersRegistry } = internalContextObject;

        if (widgetRegistry[key]) {
          throw new Error(`There is already a Widget registered at ${key}`);
        }
        widgetRegistry[key] = widgetContext;
        if (findCallersRegistry[key]) {
          for (const caller of findCallersRegistry[key]) {
            caller(widgetContext);
          }
        }
      };

      internalContextObject.deregisterWidget = (params: {
        treeName: string;
        widgetName: string;
      }) => {
        const { treeName, widgetName } = params;
        const key = makeKey(treeName, widgetName);

        const { widgetRegistry /* findCallersRegistry */ } =
          internalContextObject;
        // Do nothing if not registered
        if (widgetRegistry[key]) {
          delete widgetRegistry[key];
          // delete findCallersRegistry[key];
        }
      };

      internalContextObject.findWidget = (params: {
        treeName: string;
        widgetName: string;
        callback: (widgetContext: any) => void;
      }) => {
        const { treeName, widgetName, callback } = params;
        const key = makeKey(treeName, widgetName);
        const { widgetRegistry, findCallersRegistry } = internalContextObject;
        if (widgetRegistry[key]) {
          callback(widgetRegistry[key]);
          // return;
        }

        let callerList = findCallersRegistry[key];
        if (!callerList) {
          callerList = findCallersRegistry[key] = [];
        }
        callerList.push(callback);
      };

      internalContextObject.loadWidgetTrees = async () => {
        const widgetTreesLocal = await loadWidgetTrees(
          internalContextObject.clientManager,
          true,
        );
        setClientContext({
          ...internalContextObject,
          widgetTrees: widgetTreesLocal,
        } as IClientContext);
      };

      setClientContext(internalContextObject);
    };

    const initialSetup = async () => {
      logger.debug('Client initialization - start');
      let idToken: string, clientManager: ClientManager;

      if (testing) {
        ({ testIdToken: idToken, clientManager } = testEnvironment);
      } else {
        idToken = await getIdToken();
        try {
          clientManager = await createClientManager({
            stackInfo,
            idToken,
            configsImportMap,
          });
        } catch (error) {
          reThrow({
            logger,
            error,
            message: 'Problem during client manager creation',
            noThrow: true,
          });
          // This could be a problem that logging out will fix
          logout();
        }
        clientManager.pipelineManager.setRefreshCallback(() => {
          window.location.reload();
        });
      }

      clientManager.setDefaultLocale(navigator.language);
      clientManager.pipelineManager.javascriptStageLibraries.downloadFile =
        downloadFile;
      const presentationSupport = new PresentationSupport();
      presentationSupport.initialize(clientManager);
      clientManager.pipelineManager.javascriptStageLibraries.presentationSupport =
        presentationSupport;

      const chartSupport = new ChartSupport();
      chartSupport.initialize(clientManager);
      clientManager.pipelineManager.javascriptStageLibraries.chartSupport =
        chartSupport;

      const userInfo = await clientManager.permissionManager.getUserInfo({
        idToken,
      });
      if (!testing) {
        void clientManager.userSupport.updateUserLoginTimestamp(userInfo.email);
      }

      setupUIStatePersistence({
        presentationSupport,
        userInfo,
      });

      const versionString = `${window[COMPANY_NAME]}/${clientManager.stackId}`;
      if (!testing && !isDev()) {
        LogRocket.identify(userInfo.id, {
          email: userInfo.id,
          subscriptionType: 'pro',
        });
        LogRocket.log('Versions:', versionString);
      }
      if (
        window[COMPANY_NAME] &&
        window[COMPANY_NAME] !== clientManager.stackId
      ) {
        logger.error(`Version mismatch: ${versionString} - reloading`);
        window.location.reload();
      }

      const uiState = await presentationSupport.userGetAllUiState(userInfo.id);
      const uiStateObject: any = {};
      if (uiState) {
        uiState.forEach((item) => (uiStateObject[item.id] = item.value));
        dispatch(setInitialState(uiStateObject));
      }

      if (uiStateObject.applicationId) {
        clientManager.applicationManager.setApplication({
          applicationId: uiStateObject.applicationId,
          userInfo,
        });
      }
      if (uiStateObject.useTestData) {
        clientManager.schemaManager.setUseTestData(uiStateObject.useTestData);
      }

      const widgetTrees = await loadWidgetTrees(clientManager);

      const appDefs = clientManager.stackInfo.getObject(StackInfoKeys.APP_DEFS);
      const navigationArray = getConfigItemsForType(
        appDefs,
        APP_DEF_NAVIGATION,
      );
      const navigations = {} as { [id: string]: INavigation };
      navigationArray.forEach((nav) => (navigations[nav.id] = nav));

      createClientContext({
        clientManager,
        presentationSupport,
        chartSupport,
        history,
        userInfo,
        widgetTrees,
        navigations,
      });
      logger.debug('Client initialization - done');
    };

    if ((isAuthenticatedValue || testing) && !clientContext) {
      void initialSetup();
    }
  }, [
    clientContext,
    dispatch,
    getIdToken,
    history,
    isAuthenticatedValue,
    logout,
    stackInfo,
    testEnvironment,
  ]);

  if (!clientContext) {
    return <Loading />;
  }

  // The children property is only specified when used with the TestApp which takes the
  // place of Application
  function ChildComponents(): JSX.Element {
    return children ? (children as JSX.Element) : <Application />;
  }

  return (
    <ClientContext.Provider value={clientContext}>
      <ClientLogDebug>
        <ChildComponents />
      </ClientLogDebug>
    </ClientContext.Provider>
  );
};

// @ts-ignore
Client.whyDidYouRender = true;

export default Client;
