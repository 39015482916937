import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from 'react';

export interface BreadCrumb {
  label: string;
  url: string;
}

export type BreadCrumbs = BreadCrumb[];

interface IBreadCrumbContext {
  breadCrumbs: BreadCrumbs;
  cleanupCrumb: () => void;
  setBreadCrumbs: (breadCrumbs) => void;
}

const BreadCrumbContext = React.createContext<IBreadCrumbContext>(null);

interface BreadCrumbProps extends BreadCrumb {
  setBreadCrumbs?: (breadCrumbs) => void;
  children: any;
}

const BreadCrumbNode: React.FC<React.PropsWithChildren<BreadCrumbProps>> = (
  props,
) => {
  const context = useContext(BreadCrumbContext);

  const {
    label,
    url,
    children,
    setBreadCrumbs = context.setBreadCrumbs,
  } = props;

  const savedSetBreadCrumbs = useRef<(breadCrumbs) => void>(setBreadCrumbs);

  useEffect(() => {
    savedSetBreadCrumbs.current = setBreadCrumbs;
  }, [setBreadCrumbs]);

  const breadCrumbs = context?.breadCrumbs;

  const myBreadCrumbs = useMemo(() => {
    const parentBreadCrumbs = breadCrumbs || [];
    return parentBreadCrumbs.concat({ label, url });
  }, [breadCrumbs, label, url]);

  const cleanupCrumb = context?.cleanupCrumb;

  useEffect(() => {
    savedSetBreadCrumbs.current(myBreadCrumbs);
    return cleanupCrumb || (() => savedSetBreadCrumbs.current([]));
  }, [cleanupCrumb, myBreadCrumbs]);

  const childCleanup = useCallback(() => {
    savedSetBreadCrumbs.current(myBreadCrumbs);
  }, [myBreadCrumbs]);

  const childContext = {
    cleanupCrumb: childCleanup,
    breadCrumbs: myBreadCrumbs,
    setBreadCrumbs: savedSetBreadCrumbs.current,
  };

  return (
    <BreadCrumbContext.Provider value={childContext}>
      {children}
    </BreadCrumbContext.Provider>
  );
};

export default React.memo(BreadCrumbNode);
