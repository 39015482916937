import { Route, Switch, withRouter } from 'react-router';
import { Redirect } from 'react-router-dom';
import { compose } from 'redux';
import { AccessType } from 'universal/permissionManager';

import BreadCrumb from '../../util/BreadCrumbs';
import { escapeUrlPath } from '../../util/clientUtilities';
import { previewChildren } from '../../util/previewWidget';
import { widgetCan } from '../../util/widgetUtilities';
import { withActions } from '../widgetEngine/ActionEnabler';
import Widget from '../widgetEngine/Widget';

const Router = (props) => {
  const {
    match,
    location,
    getPermission,
    aliases,
    childWidgets,
    context: { childContext },
    passThroughProps,
  } = props;

  const ChildrenPreviews = previewChildren({
    childWidgets,
    aliases,
    childContext,
    passThroughProps,
  });

  const routes = ChildrenPreviews.filter((ChildPreview) => {
    const {
      metaComponent: { component },
    } = ChildPreview;
    const {
      properties: { permissionId },
    } = component;

    const widgetPermission = getPermission(permissionId);

    return widgetCan(AccessType.Read, widgetPermission);
  }).map((ChildPreview) => {
    const { metaComponent, properties } = ChildPreview;
    const { label, url = '' } = {
      label: properties.routeLabel,
      url: properties.url,
    };

    const key = metaComponent.component.widgetId + 'router' + url;

    return (
      <Route exact={!url} key={key} path={escapeUrlPath(match.url + '/' + url)}>
        <BreadCrumb url={`/${url}`} label={label}>
          <Widget {...metaComponent} aliases={aliases} />
        </BreadCrumb>
      </Route>
    );
  });

  routes.sort((a, b) => b.props.path.length - a.props.path.length);

  const atMatchUrl = location.pathname === match.url;

  const parentUrl = match.url.split('/').slice(0, -1).join('/');

  return (
    <Switch key="router">
      {routes}
      {!atMatchUrl && (
        <Route>
          <Redirect to={escapeUrlPath(match.url)} />
        </Route>
      )}
      <Route>
        <Redirect to={escapeUrlPath(parentUrl)} />
      </Route>
    </Switch>
  );
};

export default compose<any>(withActions, withRouter)(Router);
