import _ from 'lodash';
import { v1 as uuid } from 'uuid';

import { ClientManager } from './clientManager';
import {
  COMPANY_DOMAIN,
  DEV_STACK_TYPE,
  SYSTEM,
} from './common/commonConstants';
import {
  CONFIG_SEPARATOR,
  CREATION_TIME,
  IKeyValueType,
} from './metadataSupportConstants';
import { SERVERUSER } from './permissionManager';
import { getUtcIsoTimestamp } from './utilityFunctions';

import dayjs from 'universal/common/dayjsSupport';
import { Loggers, getLogger } from 'universal/loggerSupport';

export interface IManageUserParams {
  user: IUser;
  roles?: any[];
  properties?: IKeyValueType[];
  upsert?: boolean;
  replace?: boolean;
  configName: string;
}
export interface IGetUserParams {
  role?: string;
  excludeRole?: string;
  property?: IKeyValueType;
  users: IUser[]; // FIXME - need to pass it users so screens will refresh on change (need query in GUI) This will change with new caching, but then make it optional so you can filter a set of existing users
}
export interface IUserManageProperties {
  property: IKeyValueType;
  user: IUser;
  configName: string;
  noUpdateRecord?: boolean;
}

export interface IGetUsageData {
  periods: IUsageTimePeriod[];
  noStore?: boolean;
}

export interface IUsageTimePeriod {
  numPeriods: number;
  periodType: timePeriodType;
}

export type timePeriodType =
  | 'millisecond'
  | 'second'
  | 'minute'
  | 'hour'
  | 'day'
  | 'week'
  | 'month'
  | 'quarter'
  | 'year'
  | 'forever';

// Must match typedef
export interface IUser {
  id?: string;
  email: string;
  permissions?: any[];
  properties?: any[];
  roles?: any[];
  userName?: string;
  configName?: string;
  apiKey?: string;
}

export interface IUserRole {
  role: string;
}
export interface IManageUserResult {
  created?: boolean;
  returnMessage?: string; // Since this may be called from a GUI or batch we let the caller decide whether to log or display
}
const logger = getLogger({ name: Loggers.USER_SUPPORT });

export class UserSupport {
  public clientManager: ClientManager;

  public initialize(clientManager: ClientManager) {
    this.clientManager = clientManager;
  }

  public async manageUser(params: IManageUserParams): Promise<void> {
    const { upsert, replace, user, properties, roles, configName } = params;
    if (!user.email) {
      throw new Error('No email specified');
    }
    if (user.email.indexOf('@') === -1) {
      throw new Error('Invalid email specified');
    }
    if (user.properties && properties) {
      throw new Error(
        'Specified properties in both record and properties argument',
      );
    } else {
      user.properties = user.properties || properties || [];
    }
    if (user.roles && roles) {
      throw new Error('Specified roles in both record and properties argument');
    } else {
      user.roles = user.roles || _.cloneDeep(roles) || [];
    }
    if (user.roles) {
      await this.checkRoleProperties(user.roles, user.properties);
    }
    user.email = user.email.toLowerCase().trim();
    let userRecord =
      await this.clientManager.pipelineManager.executeGraphqlQuery({
        query: `query { getUser (id:"${user.email}") { id userName email roles {role {id }} properties {key value }} }`,
        noCache: true,
      });
    if (!replace && !upsert && userRecord) {
      throw new Error('User exists');
    }
    user.id = user.email;
    if (!upsert) {
      user.apiKey = uuid();
    }
    userRecord =
      replace || !userRecord ? user : this.mergeUsers(user, userRecord);
    await this.clientManager.pipelineManager.createRecord({
      entityName: `${SYSTEM}${CONFIG_SEPARATOR}User`,
      record: userRecord,
      upsert,
      configName,
    });
  }

  public async updateUser(params: {
    user: IUser;
    configName: string;
  }): Promise<void> {
    const { user, configName } = params;
    if (!user.email) {
      throw new Error('No email specified');
    }

    await this.clientManager.pipelineManager.updateRecord({
      entityName: `${SYSTEM}${CONFIG_SEPARATOR}User`,
      record: user,
      configName,
    });
  }

  public async addUser(params: {
    user: IUser;
    configName: string;
  }): Promise<IUser> {
    const { user, configName } = params;
    const cloneUser = _.cloneDeep(user);
    cloneUser.email = user.email.toLowerCase().trim();
    cloneUser.id = cloneUser.email;
    cloneUser.apiKey = uuid();
    await this.clientManager.pipelineManager.upsertRecord({
      entityName: `${SYSTEM}${CONFIG_SEPARATOR}User`,
      record: cloneUser,
      configName,
    });
    return cloneUser;
  }

  /** This should only be used by test code, use deactivateUser instead */

  public async deleteUser(email: string) {
    await this.clientManager.pipelineManager.executeGraphqlMutation({
      mutation: `mutation { deleteUser ( input: { id: "${email}" } ) { id } }`,
    });
  }

  public async getUserName(email: string) {
    const userRecord =
      await this.clientManager.pipelineManager.executeGraphqlQuery({
        query: `query { getUser (id:"${email}") { id userName } }`,
        noCache: true,
      });
    return userRecord.userName;
  }
  private mergeUsers(target: IUser, source: IUser): IUser {
    const mergedUser: IUser = source;
    const newRoles: any[] =
      source.roles?.map((role) => {
        return {
          role: role.role.id,
        };
      }) || [];
    target.properties
      ?.filter(
        (t) =>
          !source.properties?.find(
            (s) => s.key === t.key && s.value === t.value,
          ),
      )
      .forEach((prop) => mergedUser.properties.push(prop));
    target.roles
      ?.filter((t) => !newRoles?.find((n) => t.role === n.role))
      .forEach((role) => newRoles.push(role));
    mergedUser.roles = newRoles;
    mergedUser.userName = target.userName || source.userName;
    return mergedUser;
  }

  public async checkRoleProperties(roles: any[], properties: any[]) {
    for (const role of roles) {
      const roleRecord =
        await this.clientManager.pipelineManager.executeGraphqlQuery({
          query: `query { getRole (id:"${role.role}") { id requiredProperties {entity {id} property}} }`,
          noCache: true,
        });
      if (!roleRecord) {
        throw new Error(
          `Role Properties Check Failed: Role ${role.role} not found`,
        );
      }
      if (!roleRecord.requiredProperties) {
        return true;
      }
      for (const reqProp of roleRecord.requiredProperties) {
        const matchProp = properties?.find(
          (property) => property.key === reqProp.property,
        );
        if (!matchProp) {
          throw new Error(
            `Property ${reqProp.property} required, but not specified`,
          );
        } else {
          const sourceRecord =
            await this.clientManager.pipelineManager.executeGraphqlQuery({
              query: `query { get${reqProp.entity.id} (id:"${matchProp.value}")  { id   }  }`,
            });
          if (!sourceRecord) {
            throw new Error(
              `Property ${reqProp.property}: No match for entity: ${reqProp.entity.id} on ${matchProp.value}`,
            );
          }
        }
      }
    }
  }
  public getSpecifiedUsers(params: IGetUserParams): IUser[] {
    const { role, property, users, excludeRole } = params;
    let userRecords: IUser[] = _.cloneDeep(users);
    if (role) {
      userRecords = userRecords.filter((user) => {
        return user.roles.find((userRole) => userRole.role?.id === role);
      });
    }
    if (excludeRole) {
      userRecords = userRecords.filter((user) => {
        return !user.roles.find(
          (userRole) => userRole.role?.id === excludeRole,
        );
      });
    }
    if (property) {
      userRecords = userRecords.filter((user) =>
        user.properties?.find(
          (userProperty) =>
            userProperty.key === property.key &&
            userProperty.value === property.value,
        ),
      );
    }
    return userRecords;
  }

  public async getUsersWithRole(role: any): Promise<IUser[]> {
    let userRecords =
      await this.clientManager.pipelineManager.executeGraphqlQuery({
        query:
          'query { listUser { items {  id email userName roles {role {id}} properties {key value}  } } }',
      });
    userRecords = userRecords.filter((user) => {
      return user.roles.find((userRole) => userRole.role?.id === role);
    });
    return userRecords;
  }

  public async userAddProperty(params: IUserManageProperties) {
    const { property, user, configName, noUpdateRecord } = params;
    const editUser = _.cloneDeep(user);
    if (
      editUser.properties?.find(
        (prop) => prop.key === property.key && prop.value === property.value,
      )
    ) {
      logger.info(
        `Tried to add property that already existed: key: ${property.key}, value: ${property.value}`,
      );
      return editUser;
    }
    if (!editUser.properties) {
      editUser.properties = [];
    }
    editUser.properties.push(property);

    if (!noUpdateRecord) {
      const record = { id: editUser.id, properties: editUser.properties };
      await this.clientManager.pipelineManager.updateRecord({
        entityName: `${SYSTEM}${CONFIG_SEPARATOR}User`,
        record,
        configName,
      });
    }
    return editUser;
  }
  public async userDeleteProperty(params: IUserManageProperties) {
    const { property, user, configName, noUpdateRecord } = params;
    const editUser = _.cloneDeep(user);
    const idx = editUser.properties.findIndex(
      (p) => p.key === property.key && p.value === property.value,
    );
    if (idx !== -1) {
      editUser.properties.splice(idx, 1);
      if (!noUpdateRecord) {
        const record = { id: editUser.id, properties: editUser.properties };
        await this.clientManager.pipelineManager.updateRecord({
          entityName: `${SYSTEM}${CONFIG_SEPARATOR}User`,
          record,
          configName,
        });
      }
    } else {
      logger.info(
        `Tried to delete property that did not exist, key: ${property.key}, value: ${property.value}`,
      );
    }
    return editUser;
  }
  public userGetProperty(params: { user: IUser; key: string }) {
    /* return array of values for a key */
    const { key, user } = params;
    const values =
      user.properties?.filter((p) => p.key === key).map((p) => p.value) || [];
    return values;
  }

  public async validateNewUser(email: string) {
    const emailClean = email.trim().toLowerCase();
    const duplicateUser =
      await this.clientManager.pipelineManager.executeGraphqlQuery({
        query: `query { getUser (id:"${emailClean}") { id } }`,
        noCache: true,
      });
    if (duplicateUser) {
      return { valid: false, error: 'User exists' };
    }
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const valid = re.test(String(emailClean).toLowerCase());
    return { valid, error: valid ? '' : 'Invalid Email' };
  }

  public async updateUserLoginTimestamp(email: string) {
    return this.clientManager.pipelineManager.executeGraphqlMutation({
      mutation: `mutation { updateUser ( input: { id: "${email}" mostRecentLogin: "${getUtcIsoTimestamp()}" } ) { id } }`,
    });
  }

  public async deactivateUser(user: IUser) {
    return this.clientManager.pipelineManager.updateRecord({
      record: {
        id: user.id,
        _deactivationDate:
          this.clientManager.utilityFunctions.getLocalIsoDate(),
      },
      entityName: 'system:User',
    });
  }

  // Used only for DynamoDB initialization, note that it's returning raw DynamoDB database
  // records
  public static getInitialUsers(configName: string, stackType: string): any[] {
    const suffix = `@${COMPANY_DOMAIN}`;

    const userId =
      (stackType === DEV_STACK_TYPE ? 'devops' : 'devops-prod') + suffix;

    const baseUser = {
      otherInfo: [],
      recordType: 'User',
      permissions: [
        {
          permission: `Permission-${SERVERUSER}`,
        },
      ],
      roles: [
        {
          role: `Role-${configName}${CONFIG_SEPARATOR}sysadmin`,
        },
      ],
      userName: 'Snapstrat Devops',
      [CREATION_TIME]: getUtcIsoTimestamp(),
    };

    return [
      {
        email: userId,
        id: `User-${userId}`,
        ...baseUser,
      },
      // Cover all the bases for the users required by auth0
      // FIXME - do we really need these?
      {
        email: `devops-${configName}-prod${suffix}`,
        id: `User-devops-${configName}-prod${suffix}`,
        ...baseUser,
      },
      {
        email: `devops-${configName}-dev${suffix}`,
        id: `User-devops-${configName}-dev${suffix}`,
        ...baseUser,
      },
    ];
  }
  public async getUsageData(params: IGetUsageData): Promise<any> {
    const { periods } = params;
    const logRecords =
      await this.clientManager.pipelineManager.executeGraphqlQuery({
        query:
          'query {   list_Log ( entityType: "User") { items {id user timestamp entityType before mutation}}}',
      });
    const userData = {};
    const now = dayjs();
    logRecords.forEach((l) => {
      if (
        l.before?.mostRecentLogin !== l.mutation?.mostRecentLogin ||
        !l.mutation?.mostRecentLogin
      ) {
        const user = l.user;
        if (!userData[user]) {
          userData[user] = {
            periods: periods.map((p) => {
              return { ...p, logins: 0, activity: 0 };
            }),
          };
        }
        const logDate = dayjs(l.timestamp);
        userData[user].periods.forEach((p) => {
          if (
            p.periodType === 'forever' ||
            p.numPeriods === -1 ||
            now.diff(logDate, p.periodType) <= p.numPeriods
          ) {
            p.logins++;
          }
        });
      }
    });
    return userData;
  }
  public async updateUsageData(params: IGetUsageData) {
    const { noStore } = params;
    const userData = await this.getUsageData(params);
    const userUsage = [];
    for (const user in userData) {
      const nextUser = { id: uuid(), user, periods: [] };
      for (const period in userData[user].periods) {
        nextUser.periods.push({ ...userData[user].periods[period] });
      }
      userUsage.push(nextUser);
    }
    if (!noStore) {
      await this.clientManager.pipelineManager.deleteAllRecords({
        entityName: 'system:UserUsage',
      });
      await this.clientManager.pipelineManager.createRecords({
        entityName: 'system:UserUsage',
        records: userUsage,
      });
    }
    return userUsage;
  }
  public async addApiKeyToUser(user: IUser) {
    const apiKey = uuid();
    await this.clientManager.pipelineManager.upsertRecord({
      record: { id: user.id, apiKey },
      entityName: 'system:User',
    });
    return apiKey;
  }
  public async getUserForApiKey(apiKey: string): Promise<IUser> {
    const result = await this.clientManager.pipelineManager.listRecords({
      queryArguments: { apiKey },
      entityName: 'system:User',
    });
    if (result.items.length === 0) {
      throw new Error('User not found for specified apiKey');
    } else if (result.items.length !== 1) {
      throw new Error('More than one user for an apiKey found (this is bad)');
    }
    return result.items[0];
  }
}
