import axios, { Method } from 'axios';
import { isBrowser } from 'browser-or-node';
import { toBase64 } from 'js-base64';
import safeJsonStringify from 'safe-json-stringify';

import { ClientManager } from './clientManager';
import { COMPANY_NAME } from './common/commonConstants';
import { reThrow } from './errors/errorLog';
import { retry } from './errors/retry';
import { getLogger, Loggers } from './loggerSupport';
import { IUserInfo } from './permissionManager';
import { StageType } from './types';
import { sleep, stringifyPretty } from './utilityFunctions';

export interface ITicketInfo {
  userInfo: IUserInfo;
  subject: string;
  body: string;
  ticketId?: string;
}

interface IZendeskUser {
  email: string;
  id: number;
  name: string;
}

const logger = getLogger({ name: Loggers.ZENDESK_SUPPORT });

export class ZendeskSupport {
  private zendeskToken: string;
  private clientManager: ClientManager;

  private organizations: any;

  constructor() {}

  public initialize(clientManager: ClientManager) {
    this.clientManager = clientManager;
    this.zendeskToken = process.env.ZENDESK_TOKEN;
  }

  private makeRequest(params: {
    method?: Method;
    url?: string;
    urlPart?: string;
    body?: any;
    query?: string;
    paged?: boolean;
  }) {
    const { method = 'GET', urlPart, url, body, query, paged } = params;
    if (!this.zendeskToken) {
      throw new Error('Zendesk token missing from stack config');
    }
    const authHeader = toBase64(
      `devops@snapstrat.com/token:${this.zendeskToken}`,
    );
    const queryString = query ? `?query=${query}` : '';
    const request = {
      method,
      url:
        url ||
        `https://${COMPANY_NAME}.zendesk.com/api/v2/${urlPart}${
          paged ? '?page[size]=100' : ''
        }${queryString}`,
      headers: {
        Authorization: `Basic ${authHeader}`,
      },
      data: body,
    };
    return request;
  }

  private getOrganization(userInfo: IUserInfo): any {
    const organization = this.organizations.data.organizations.find(
      (o) => o.name === userInfo.configName,
    );
    if (!organization) {
      throw new Error(
        `Zendesk organization ${userInfo.configName} not found, user: ${userInfo.email}`,
      );
    }
    return organization;
  }

  public async createOrUpdateUsers(
    userInfos: IUserInfo[],
  ): Promise<IZendeskUser[]> {
    let existingUsers = [];

    try {
      let args: any = {
        urlPart: 'users',
        paged: true,
      };
      while (true) {
        const response = await retry({
          command: () => axios.request(this.makeRequest(args)),
        });

        existingUsers = existingUsers.concat(response.data.users);
        if (!response.data?.meta?.has_more) {
          break;
        }
        args = { url: response.data.links.next };
      }

      // Return all of the users, even if we did not add them
      let returnUsers = [];

      // Make sure we don't overwrite any agents
      const usersToProcess = userInfos.filter((userInfo) => {
        const found = existingUsers.find((eu) => eu.email === userInfo.email);
        const processUser = !found || found.role === 'end-user';
        if (!processUser && found) {
          logger.info(
            `Skipping ${userInfo.email} because it's not an end-user`,
          );
          returnUsers.push(found);
        }
        return processUser;
      });

      if (!isBrowser) {
        if (!this.organizations) {
          this.organizations = await retry({
            command: () =>
              axios.request(
                this.makeRequest({
                  urlPart: 'organizations',
                }),
              ),
          });
        }
      }

      const userArray = usersToProcess.map((userInfo) => {
        const organization = this.getOrganization(userInfo);
        logger.info(
          `Adding/updating ${userInfo.email} org: ${
            organization.name
          } tags: ${safeJsonStringify(userInfo.tags)}`,
        );
        return {
          email: userInfo.email,
          name: userInfo.userName,
          organization_id: organization.id,
          role: 'end-user',
          tags: userInfo.tags,
          verified: true,
        };
      });

      const useMany = userArray.length > 1;
      logger.info(`${userArray.length} synchronized with Zendesk`);
      if (useMany) {
        const response = await retry({
          command: () =>
            axios.request(
              this.makeRequest({
                method: 'post',
                urlPart: 'users/create_or_update_many',
                body: {
                  users: userArray,
                },
              }),
            ),
        });

        while (true) {
          const jobStatus = await retry({
            command: () =>
              axios.request(
                this.makeRequest({
                  urlPart: `job_statuses/${response.data.job_status.id}`,
                  body: {
                    users: userArray,
                  },
                }),
              ),
          });
          if (jobStatus.data.job_status.status === 'completed') {
            returnUsers = returnUsers.concat(jobStatus.data.job_status.results);
            break;
          }
          await sleep(2000);
        }
      } else {
        // The loop is here in case the above API is not available, you have to ask Zendesk for it
        for (const userToUse of userArray) {
          const response = await retry({
            command: () =>
              axios.request(
                this.makeRequest({
                  method: 'post',
                  urlPart: 'users/create_or_update',
                  body: {
                    user: userToUse,
                  },
                }),
              ),
          });
          returnUsers.push(response.data.user);
        }
      }
      return returnUsers;
    } catch (error) {
      reThrow({ logger, error, message: 'Problem with zendesk request' });
    }
  }

  public async syncUser(email: string) {
    const testUserInfo = await this.clientManager.permissionManager.getUserInfo(
      {
        email,
      },
    );
    await this.createOrUpdateUsers([testUserInfo]);
  }

  public async syncAllUsers() {
    const userInfos = await this.clientManager.permissionManager.getUsers();
    logger.info(`syncAllUsers: starting to sync ${userInfos.length} users`);

    await this.createOrUpdateUsers(userInfos);
  }

  public async createTicket(ticketInfo: ITicketInfo) {
    if (isBrowser) {
      const result =
        await this.clientManager.pipelineManager.executePipelineRemote({
          stages: [
            {
              _stageType: StageType.javaScript,
              code: `
        const { ticketInfo } = input;
        await clientManager.zendeskSupport.createTicket(ticketInfo);
        output.ticketInfo = ticketInfo;
          `,
            },
          ],
          input: { ticketInfo },
        });
      Object.assign(ticketInfo, result.ticketInfo);
      return;
    }

    const zenDeskUser = (
      await this.createOrUpdateUsers([ticketInfo.userInfo])
    )[0];

    let response;
    try {
      response = await retry({
        command: () =>
          axios.request(
            this.makeRequest({
              method: 'POST',
              urlPart: 'tickets',
              body: {
                ticket: {
                  requester_id: zenDeskUser.id,
                  submitter_id: zenDeskUser.id,
                  subject: ticketInfo.subject,
                  comment: { body: ticketInfo.body },
                },
              },
            }),
          ),
      });
      ticketInfo.ticketId = response.data.ticket.id;
      logger.info(ticketInfo, 'ticket created');
    } catch (error) {
      reThrow({
        logger,
        message: `Problem creating ticket: ${stringifyPretty(
          ticketInfo,
        )} in Zendesk: ${stringifyPretty(error.response.data)}`,
        error,
      });
    }
  }
}
