import { Popover } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { compose } from 'redux';

import { withActions } from '../widgetEngine/ActionEnabler';
import Container from '../widgetEngine/Container';

const styles = () =>
  ({
    popoverHover: {
      pointerEvents: 'none',
    },

    popover: {},
  }) as const;

class PopupWidget extends Container {
  public declare props;
  public declare state;
  constructor(props) {
    super(props);
    this.handleClickAway = this.handleClickAway.bind(this);
  }

  handleClickAway() {
    const { name, actionReference } = this.props;
    const { close } = actionReference;
    close(name);
  }

  render() {
    const {
      classes,
      actionReference,
      anchorOriginVertical,
      anchorOriginHorizontal,
      transFormOriginVertical,
      transFormOriginHorizontal,
      anchorPositionTop,
      anchorPositionLeft,
      mouseTrigger,
      anchorReference,
    } = this.props;
    const { anchor } = actionReference;
    const { element } = anchor;
    const Children = this.generateChildren();
    const className = mouseTrigger === 'hover' ? 'popoverHover' : 'popover';

    return (
      <Popover
        key="popover"
        PaperProps={{
          style: {
            padding: '10px',
          },
        }}
        onClose={this.handleClickAway}
        anchorEl={element}
        anchorPosition={{ left: anchorPositionLeft, top: anchorPositionTop }}
        anchorReference={anchorReference}
        open={true}
        className={classes[className]}
        anchorOrigin={{
          vertical: anchorOriginVertical,
          horizontal: anchorOriginHorizontal,
        }}
        transformOrigin={{
          vertical: transFormOriginVertical,
          horizontal: transFormOriginHorizontal,
        }}
      >
        {Children}
      </Popover>
    );
  }
}

export default compose(
  withActions,
  withStyles(styles, { withTheme: true }),
)(PopupWidget);
