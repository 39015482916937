import { SvgIcon } from '@mui/material';
import { useContext, useEffect, useState } from 'react';

import { getIconFromPath } from '../../util/clientUtilities';
import { ClientContext } from '../../wrappers/ClientContext';
import { IElementAttributes } from '../widgets/types';

type IProps = {
  iconPath: string;
  configName: string;
  elementAttributes: IElementAttributes;
};

const CustomIcon: React.FC<React.PropsWithChildren<IProps>> = (props) => {
  const { iconPath, configName } = props;

  const icon = useGetIcon(iconPath, configName);
  const className = icon ? 'customIcon' : 'iconLoading';

  return (
    <SvgIcon {...props.elementAttributes} className={className}>
      {icon}
    </SvgIcon>
  );
};

function useGetIcon(iconPath, configName) {
  const { clientManager } = useContext(ClientContext);
  const [icon, setIcon] = useState('');
  useEffect(() => {
    const getIcon = async () => {
      const fetchedIcon = await getIconFromPath(
        iconPath,
        configName,
        clientManager,
      );
      setIcon(() => fetchedIcon);
    };
    void getIcon();
  }, [iconPath, configName, clientManager]);

  return icon;
}

export default CustomIcon;
