//
// Low-level utility functions, not to depend on ClientManager or anything from that.
//

import validbarcode from 'barcode-validator';
import barhandles from 'barhandles';
import lookup from 'country-code-lookup';
import cryptoJs_enc_hex from 'crypto-js/enc-hex.js';
import cryptoJs_hmacSha256 from 'crypto-js/hmac-sha256.js';
import cryptoJs_sha1 from 'crypto-js/sha1.js';
import cryptoJs_sha256 from 'crypto-js/sha256.js';
import { Dayjs } from 'dayjs';
import { OperationDefinitionNode } from 'graphql';
import _ from 'lodash';
import { BaseLogger } from 'pino';
import safeJsonStringify from 'safe-json-stringify';
import stringSimilarity from 'string-similarity';
import { v1 as uuid } from 'uuid';

import seedrandom from 'seedrandom';
import { exists } from './common/commonUtilities';
import dayjs, { dayjsOriginal } from './common/dayjsSupport';
import { getErrorString } from './errors/errorString';
import { FilterType, IFilter, IFilterData } from './filterConstants';
import { LogLevels, Loggers, getLogger } from './loggerSupport';
import { Duration } from './types';

export { exists } from './common/commonUtilities';

const logger = getLogger({ name: Loggers.UTILITY_FUNCTIONS });

export type MapKeys<T> = { [K in keyof T]: any };

export type MaybePromise<T> = T | Promise<T>;

export const GIVE_UP_CODE = 'GAVE UP';
export const NO_RESULT = 'NO RESULT';

export const sleep = async (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const removeEmpty = (obj): any => {
  return processNullOrUndefined({ obj, empty: true, doNull: false });
};

export const removeNullOrEmpty = (obj): any => {
  return processNullOrUndefined({ obj, empty: true });
};

export const removeNullOrUndefined = (obj): any => {
  return processNullOrUndefined({ obj });
};

export const removeFunctions = (obj): any => {
  return processNullOrUndefined({ obj, deleteFunctions: true });
};

export const checkNullOrUndefined = (obj): any => {
  return processNullOrUndefined({ obj, check: true });
};
export interface IColorGradient {
  startColor: string;
  endColor: string;
  gradient: number;
}

export function processNullOrUndefined(params: {
  obj: Record<string, any>;
  empty?: boolean;
  deleteFunctions?: boolean;
  doNull?: boolean;
  check?: boolean;
  seenObjects?: Record<string, any>[];
  pathPrefix?: string;
}): any {
  if (!params.seenObjects) {
    params.seenObjects = [];
  }

  const {
    obj,
    empty,
    deleteFunctions,
    doNull = true,
    check,
    seenObjects,
    pathPrefix = '',
  } = params;

  if (!obj) {
    return;
  }

  const foundSeen = seenObjects.find((o) => o === obj);
  if (!foundSeen) {
    seenObjects.push(obj);
  }
  if (Array.isArray(obj)) {
    obj
      .filter((o) => !seenObjects.find((so) => so === o))
      .forEach((o, i) =>
        processNullOrUndefined({
          ...params,
          obj: o,
          pathPrefix: `${pathPrefix}[${i}]`,
        }),
      );
    return;
  }
  Object.keys(obj).forEach((key) => {
    const propertyObj = obj[key];
    if (
      propertyObj &&
      (typeof propertyObj === 'object' || Array.isArray(propertyObj)) &&
      !seenObjects.find((o) => o === propertyObj)
    ) {
      processNullOrUndefined({
        ...params,
        obj: propertyObj,
        pathPrefix: `${pathPrefix}/${key}`,
      });
    } else if (
      (doNull && propertyObj === null) ||
      propertyObj === undefined ||
      (empty && propertyObj === '') ||
      (deleteFunctions && typeof propertyObj === 'function')
    ) {
      if (check) {
        throw new Error(`Found null or undefined: ${pathPrefix}/${key}`);
      }
      try {
        delete obj[key];
      } catch (error) {
        // Can't be deleted because of a property permission issue - ignore
      }
    }
  });
  return obj;
}

export function omitDeep(inputTree, fieldsToDelete) {
  const newTree = _.cloneDeep(inputTree);
  let omitter;

  if (Array.isArray(fieldsToDelete)) {
    omitter = (omitterTree) => _.omit(omitterTree, fieldsToDelete);
  } else if (fieldsToDelete && typeof fieldsToDelete === 'object') {
    omitter = (omitterTree) =>
      _.omitBy(omitterTree, (value, key) => fieldsToDelete[key]);
  } else {
    throw new Error(
      'omitDeep called with a second arguement that is not an array or object',
    );
  }

  return omitRecurse(newTree, omitter);
}

// might mutate original, use above entrypoint to clone first
function omitRecurse(tree, omitter) {
  if (Array.isArray(tree)) {
    return tree.map((branch) => omitRecurse(branch, omitter));
  } else if (typeof tree === 'object') {
    tree = omitter(tree);

    Object.keys(tree).forEach((key) => {
      tree[key] = omitRecurse(tree[key], omitter);
    });
    return tree;
  }

  return tree;
}

// might be a better lodash method for this
// noinspection JSUnusedGlobalSymbols - used in client
export function isEqualFromRight(outerPath, innerPath) {
  const lengthDifference = outerPath.length - innerPath.length;
  const compare = outerPath.slice(lengthDifference);
  if (!compare.length) {
    return false;
  }
  return _.isEqual(compare, innerPath);
}

export function isNullOrUndefined(obj: any) {
  return obj === null || obj === undefined;
}

export function transFormToCSV(params: {
  columns: string | string[];
  columnLabels: string | string[];
  rows;
  sortFields: string[];
}) {
  const { columns, columnLabels, sortFields } = params;
  let { rows } = params;

  const dataKeys = Array.isArray(columns) ? columns : columns.split('_');
  const headerFields = Array.isArray(columnLabels)
    ? columnLabels
    : columnLabels.split('_');
  const header = [headerFields.map((label) => escapeString({ string: label }))];

  if (sortFields) {
    const sortMap = sortFields.map(
      (field) => (row) => _.get(row, field.split('.')),
    );
    rows = sortBy({ items: rows, sortFields: sortMap });
  }

  const content = rows.map((row) => {
    const cells = dataKeys.map((dataKey) => {
      const cell = _.get(row, dataKey.split('.')) || '';
      return escapeString({ string: cell.toString() });
    });
    return cells.join(',');
  });

  return header.concat(content).join('\r\n');
}

export function truncatedObjectString(obj) {
  const str = safeJsonStringify(obj);
  if (str?.length > 2000) {
    return str.slice(0, 2000) + '... (truncated) ...';
  }
  return str;
}

export function escapeString({ string }) {
  if (string.replace(/ /g, '').match(/[\s,"]/)) {
    return '"' + string.replace(/"/g, '""') + '"';
  }
  return string;
}

export function escapeGraphqlString(string) {
  if (!string) {
    return '""';
  }
  const s = string
    .replace(/\\/g, '\\\\')
    .replace(/"/g, '\\"')
    .replace(/\n/g, '\\n');
  return `"${s}"`;
}

export function removeExtraWhiteSpace(string) {
  return string.replace(/\s+/g, ' ').replace(/\\n/g, ' ');
}

// Each key is an array of the components of the key, like ['foo', 'bar']
export function extractHandlebarsKeys(template): string[][] {
  const result = [];
  barhandles.extract(template, (hbKey) => {
    result.push(hbKey);
  });
  return result;
}

export function sortBy(params: { items; sortFields }) {
  const { items, sortFields } = params;
  let sorter;
  if (Array.isArray(sortFields)) {
    sorter = sortFields;
  } else if (typeof sortFields === 'string') {
    sorter = [sortFields];
  } else {
    throw new Error('sortFields must be a string or array of strings');
  }

  return _.sortBy(items, sorter);
}

export function isPrimitive(object: any) {
  const t = typeof object;
  return t === 'string' || t === 'number' || t === 'boolean' || t === 'bigint';
}

export function changeToType(value: any, type: string) {
  if (type === 'Int') {
    return parseInt(value, 10);
  } else if (type === 'Float') {
    return parseFloat(value);
  } else {
    return value;
  }
}

export const performanceTimes = async (params: {
  logger: BaseLogger;
  recordCount?: number;
  recordSize?: number;
  suppressErrorLogging?: boolean;
  func: () => any;
}): Promise<{ rps: number; result: any }> => {
  const {
    logger: loggerFunc,
    func,
    recordCount,
    recordSize,
    suppressErrorLogging,
  } = params;
  const startTime = Date.now();
  try {
    const result = await func();
    const elapsedTime = Date.now() - startTime;
    const totalBytes = recordCount * recordSize;
    const rps = Math.round(recordCount / (elapsedTime / 1000));
    loggerFunc.info(
      `Executed ${elapsedTime}ms RPS: ${rps} BPS: ${
        recordSize ? Math.round(totalBytes / (elapsedTime / 1000)) : 'unknown'
      }`,
    );
    return { rps, result };
  } catch (error) {
    if (!suppressErrorLogging) {
      loggerFunc.error(error, `Failed after ${Date.now() - startTime}ms`);
    }
    throw error;
  }
};

// Used to account for the eventual consistency in DynamoDB reads
export const waitForGoodResult = async (params: {
  func: () => any;
  logger: BaseLogger;
}) => {
  const { func } = params;
  const maxTries = 4;
  let tryCount = 1;
  while (tryCount <= maxTries) {
    try {
      await func();
      break;
    } catch (error) {
      if (tryCount === maxTries) {
        throw error;
      }
      logger.warn(`Failed try #${tryCount}: ${getErrorString(error)}`);
      await sleep(1000);
      tryCount++;
    }
  }
};

const waitLogger = logger.child({ level: LogLevels.Info });

// returns a function which waits for callback function to return a result, which
// could be truthy or NO_RESULT if the waited for result wants to be null
export function waitFor<T>(
  callback: ((...args: T[]) => Promise<any>) | ((...args: T[]) => any),
  waitTime = 2000,
  timesToRetry = 100,
): (...args: T[]) => Promise<any> {
  return async (...args) => {
    let numberOfRetries = 0;

    while (true) {
      const result = await callback(...args);
      numberOfRetries++;
      if (result === NO_RESULT) {
        return null;
      }
      if (result !== undefined && result !== false && result !== null) {
        return result;
      }
      if (numberOfRetries > timesToRetry) {
        break;
      }
      await wait(waitTime);
    }

    waitLogger.info(`Giving up after ${numberOfRetries} times`);
    return GIVE_UP_CODE;
  };
}

export async function wait(timeout = 2000) {
  waitLogger.debug(`Waiting ${timeout} ms`);
  return sleep(timeout);
}

export function adjustRelativeUrl(url, depth) {
  const urlArray = url.split('/');
  return urlArray.slice(0, urlArray.length - depth).join('/');
}

export function isValidUrl(url) {
  let urlPattern =
    "(https?|ftp)://(www\\.)?(((([a-zA-Z0-9.-]+\\.){1,}[a-zA-Z]{2,4}|localhost))|((\\d{1,3}\\.){3}(\\d{1,3})))(:(\\d+))?(/([a-zA-Z0-9-._~!$&'()*+,;=:@/]|%[0-9A-F]{2})*)?(\\?([a-zA-Z0-9-._~!$&'()*+,;=:/?@]|%[0-9A-F]{2})*)?(#([a-zA-Z0-9._-]|%[0-9A-F]{2})*)?";

  urlPattern = '^' + urlPattern + '$';
  const regex = new RegExp(urlPattern);

  return regex.test(url);
}

// because targetArray.push(...arrayToAdd) doesn't work if arrayToAdd is really big
export function extendArray(targetArray: any[], arrayToAdd: any[]): void {
  arrayToAdd.forEach((element) => targetArray.push(element));
}

export function isOperationIdempotent(
  operationDefinition: OperationDefinitionNode,
): boolean {
  if (operationDefinition.operation === 'mutation') {
    return true;
  } else {
    return true;
  }
}

export function removeExtensionFromFileName(fileName: string): string {
  if (!fileName.includes('.')) {
    return fileName;
  }

  return fileName.split('.').slice(0, -1).join('.');
}

export function removeSurroundingQuotes(input: string): string {
  const quoteTypes = ["'", '"'];

  for (const quote of quoteTypes) {
    if (input.startsWith(quote) && input.endsWith(quote)) {
      return input.slice(1, -1);
    }
  }

  return input;
}

export function getFileNameFromPath(filePath: string) {
  return filePath.slice(filePath.lastIndexOf('/') + 1);
}

export async function filterAsync<T>({
  target,
  callback,
}: {
  target: T[];
  callback: (element: T, index: number, target: T[]) => Promise<boolean>;
}): Promise<T[]> {
  const filteredArray = [];

  for (let index = 0; index < target.length; index++) {
    const element = target[index];

    const filterResult = await callback(element, index, target);

    if (filterResult) {
      filteredArray.push(element);
    }
  }

  return filteredArray;
}

export function stripAllButLettersAndNumbers(input: string): string {
  return input?.replace(/[^a-zA-Z0-9]/g, '').toLowerCase();
}

// This function is used since hyphens are not allowed in ids
export function manglePreservingUniqueness(input: string): string {
  return input
    ?.toString()
    .replace('\\', '\\\\')
    .replace('%', '\\%')
    .replace('-', '%2D');
}

export function isObject(item) {
  return item && typeof item === 'object' && !Array.isArray(item);
}

export function deepAssign(target, ...sources) {
  if (!sources.length) {
    return target;
  }

  const source = sources.shift();

  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key]) {
          target[key] = {};
        }
        deepAssign(target[key], source[key]);
      } else {
        target[key] = source[key];
      }
    }
  }

  return deepAssign(target, ...sources);
}

// This should not be called directly, only through the ClientManager.utilityFunctions, so the right
// version is used
export function readFileIfExists(path: string): string {
  throw new Error(`Attempting readFileIfExists in browser with path: ${path}`);
}

export function parseJsonRecords(inputString: string): Record<string, any>[] {
  return inputString.split('\n').map((line) => JSON.parse(line));
}

export function stringifyPretty(input: any): string {
  return stringify({ input, pretty: true });
}

// Used for case when printing in logging or error messages. There are sometimes
// JSON.stringify() fails and in logging or error handling we don't want to throw
// as that will prevent reporting of the underlying problem.
// For logging, this also bypasses the stringify when the logger is not enabled
// for performance purposes.
// Don't use this if you really need the string for something (like in the data), because if
// something goes wrong there you want to throw
export function stringify(params: {
  input: any;
  // Does not throw if an error in stringifying, used when stringifying for error messages, implied by logger
  noThrow?: boolean;
  pretty?: boolean;
  limit?: number;
}): string {
  // tslint:disable-next-line:no-shadowed-variable
  const { input, noThrow, pretty, limit } = params;

  let output;
  try {
    if (pretty) {
      output = safeJsonStringify(input, null, 2);
    } else {
      output = safeJsonStringify(input);
    }
  } catch (error) {
    if (noThrow) {
      return `(unable to stringify): ${error}`;
    }
    throw new Error(`Unable to stringify object: ${error}`);
  }
  if (limit) {
    output =
      output.length > limit
        ? output.slice(0, limit) + '...(truncated)'
        : output;
  }
  return output;
}

export function sortById(obj) {
  const compare = (p1, p2) => {
    if (p1.id < p2.id) {
      return -1;
    }
    if (p1.id > p2.id) {
      return 1;
    }
    return 0;
  };
  return obj.sort(compare);
}

export function getEnumValues(theEnum) {
  return getEnumKeys(theEnum).map((key) => theEnum[key]);
}

export function getEnumKeys(theEnum) {
  return Object.keys(theEnum).filter((key) => !isStringNumber(key));
}

export function getEnumStringValue(theEnum, enumValue): string {
  return theEnum[enumValue];
}

export function isStringNumber(value: string) {
  return isNaN(Number(value)) === false;
}

export function sha1HashHex(inputString: string): string {
  return cryptoJs_sha1(inputString).toString(cryptoJs_enc_hex);
}

export function sha256HashHex(inputString: string): string {
  return cryptoJs_sha256(inputString).toString(cryptoJs_enc_hex);
}

export function sha256HashBytes(inputString: string): string {
  return cryptoJs_sha256(inputString, { asBytes: true });
}

export function hmacSha256(inputString: string, key: string): any {
  return cryptoJs_hmacSha256(inputString, key);
}

export function hmacSha256HashHex(inputString: string, key: string): string {
  return cryptoJs_hmacSha256(inputString, key).toString(cryptoJs_enc_hex);
}

export function hmacSha256HashBytes(inputString: string, key: string): string {
  return cryptoJs_hmacSha256(inputString, key, { asBytes: true });
}

export function parseBooleanString(
  value: string | boolean,
): boolean | undefined {
  if (value === 'true' || value === true) {
    return true;
  }

  if (value === 'false' || value === false) {
    return false;
  }

  return undefined;
}

export const getCircularReplacer = () => {
  const seen = new WeakSet();
  return (key, value) => {
    if (typeof value === 'object' && value !== null) {
      if (seen.has(value)) {
        return;
      }
      seen.add(value);
    }
    return value;
  };
};

export function isCollection(possibleCollection: any): boolean {
  if (
    Array.isArray(possibleCollection) ||
    (typeof possibleCollection === 'object' && possibleCollection !== null)
  ) {
    return true;
  } else {
    return false;
  }
}

export function modifyLeaves({
  collection,
  callback,
}: {
  collection: Record<string, any> | any[] | any;
  callback: (value: any) => any;
}) {
  if (isCollection(collection)) {
    Object.keys(collection).forEach((key) => {
      if (isCollection(collection[key])) {
        modifyLeaves({ collection: collection[key], callback });
      } else {
        collection[key] = callback(collection[key]);
      }
    });
  }
}

export function mergeDeep(target: any, source: any, arrayDuplicates = false) {
  return _.mergeWith(target, source, (targetValue, sourceValue) => {
    let nonDuplicates = [];
    if (Array.isArray(targetValue)) {
      if (!arrayDuplicates) {
        if (sourceValue[0].__id) {
          // we use __id as an unique array identifier so transform arrays get aggregated at the element level, if not there run function 'normally'
          sourceValue.forEach((s) => {
            const targetMatch = targetValue.find((t) => t.__id === s.__id);
            if (targetMatch) {
              mergeDeep(targetMatch, s);
            } else {
              targetValue.push(s);
            }
          });
          return targetValue;
        } else {
          nonDuplicates = sourceValue.filter(
            (value) => targetValue.indexOf(value) === -1,
          );
          return targetValue.concat(nonDuplicates);
        }
      }
      return targetValue.concat(sourceValue);
    }
  });
}

// Adds decimal numbers of a given precision without floating point issues
export function addDecimals(numbers: number[], decimalPoints: number): number {
  if (!exists(decimalPoints)) {
    decimalPoints = 2;
  }

  if (decimalPoints % 1 !== 0) {
    throw new Error(
      `Specified number of decimal points ${decimalPoints} is invalid`,
    );
  }

  const multiplier = 10 ** decimalPoints;
  const addReducer = (accumulator, currentValue) => accumulator + currentValue;

  return (
    numbers.map((n) => Math.round(n * multiplier)).reduce(addReducer) /
    multiplier
  );
}

export function sequentialArray(size: number): number[] {
  return [...Array(size).keys()];
}

export function sortStrings({
  array,
  numbers,
}: {
  array: string[];
  numbers?: boolean;
}): string[] {
  if (numbers) {
    return array
      .map(Number)
      .sort()
      .map((number) => number.toString());
  } else {
    return array.sort();
  }
}

export const months = [
  { short: 'Jan', full: 'January', number: 1 },
  { short: 'Feb', full: 'February', number: 2 },
  { short: 'Mar', full: 'March', number: 3 },
  { short: 'Apr', full: 'April', number: 4 },
  { short: 'May', full: 'May', number: 5 },
  { short: 'Jun', full: 'June', number: 6 },
  { short: 'Jul', full: 'July', number: 7 },
  { short: 'Aug', full: 'August', number: 8 },
  { short: 'Sep', full: 'September', number: 9 },
  { short: 'Oct', full: 'October', number: 10 },
  { short: 'Nov', full: 'November', number: 11 },
  { short: 'Dec', full: 'December', number: 12 },
];

export function convertMonthToNumber(monthString: string) {
  // handles Jan. Jan January case agnostic
  const compareString = monthString.toLowerCase().replace('.', '');
  let month = months.find((m) => m.short.toLowerCase() === compareString);
  if (month) {
    return month.number;
  }
  month = months.find((m) => m.full.toLowerCase() === compareString);
  if (month) {
    return month.number;
  }
  return null;
}

export function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min) + min); //The maximum is exclusive and the minimum is inclusive
}

export const getLocalIsoDate = () => {
  return getLocalIsoTimestamp().slice(0, 10);
};

export const getLocalIsoTimestamp = () => {
  return dayjsOriginal.utc().local().format();
};

export const getUtcIsoTimestamp = () => {
  return dayjsOriginal.utc().format();
};

export const isYYYYMMDDDate = (date: string): boolean =>
  /^\d\d\d\d-\d\d-\d\d$/.test(date);

export function getCurrentYear() {
  return parseInt(getLocalIsoDate().slice(0, 4), 10);
}

export function getUuid() {
  return uuid();
}
/**
 * Calculate a date range based on a start date a duration
 *
 * @param startDate YYYY-MM-DD, or YYYY-MM, or YYYY
 */
export function calculatePeriod(
  startDate: string,
  duration: Duration | string,
): { startDate: string; endDate: string } {
  let startDateToUse;
  switch (startDate.length) {
    case 10:
      startDateToUse = startDate;
      break;
    case 4:
      startDateToUse = `${startDate}-01-01`;
      break;
    case 7:
      startDateToUse = `${startDate}-01`;
      break;
    default:
      throw new Error(`Invalid abbreviated startDate: ${startDate}`);
  }

  const dayjsStartDate = dayjs(startDateToUse);
  if (!dayjsStartDate.isValid()) {
    throw new Error(`Invalid startDate: ${startDate}`);
  }
  let endDate: Dayjs;
  switch (duration) {
    case Duration.Year:
      endDate = dayjsStartDate.add(1, 'year');
      break;
    case Duration.Quarter:
      endDate = dayjsStartDate.add(1, 'quarter');
      break;
    case Duration.Month:
      endDate = dayjsStartDate.add(1, 'month');
      break;
    case Duration.Week:
      endDate = dayjsStartDate.add(1, 'week');
      break;
    case Duration.Day:
      endDate = dayjsStartDate.add(1, 'day');
      break;
    default:
      throw new Error(`Invalid duration: ${duration}`);
  }
  endDate = endDate.subtract(1, 'day');
  return {
    startDate: startDateToUse,
    endDate: endDate.toISOString().slice(0, 10),
  };
}

function filterMultiSelect(params: {
  filter: IFilter;
  inputData: any[];
  filterResults: any;
}) {
  const { inputData, filter, filterResults } = params;
  const { name, id } = filter;

  const result = filterResults ? filterResults[id]?.result : null;
  if (!result || result.length === 0) {
    return inputData;
  }
  inputData
    .filter((f) => !f.filter)
    .forEach((record) => {
      let nextAccessor = _.get(record, name);
      const segments = name.split('.');

      if (!nextAccessor) {
        nextAccessor = record;
        for (const segment of segments) {
          if (Array.isArray(nextAccessor)) {
            nextAccessor = nextAccessor.map((item) => item[segment]);
            break;
          } else {
            nextAccessor = nextAccessor ? nextAccessor[segment] : undefined;
          }
        }
        if (Array.isArray(nextAccessor)) {
          nextAccessor = nextAccessor.filter(Boolean).join(', ');
        }
      }

      if (!nextAccessor && !result?.includes('(None)')) {
        record.filter = true;
      } else if (
        nextAccessor &&
        Array.isArray(result) &&
        !result?.includes(nextAccessor)
      ) {
        record.filter = true;
      } else if (
        nextAccessor &&
        !Array.isArray(result) &&
        nextAccessor !== result
      ) {
        record.filter = true;
      }
    });
  return inputData.filter((f) => !f.filter);
}
function filterText(params: {
  filter: IFilter;
  inputData: any[];
  filterResults: any;
}) {
  const { inputData, filter, filterResults } = params;
  const { name, id } = filter;
  const result =
    filterResults && filterResults[id]?.result.length > 0
      ? filterResults[id]?.result
      : '';
  inputData
    .filter((f) => !f.filter)
    .forEach((record) => {
      const nextAccessor = _.get(record, name);
      if (filter.matchCase) {
        if (result && !nextAccessor?.includes(result)) {
          record.filter = true;
        }
      } else {
        if (
          typeof result === 'string' &&
          !nextAccessor?.toLowerCase().includes(result.toLowerCase())
        ) {
          record.filter = true;
        } else if (
          Array.isArray(result) &&
          result.some(
            (r) => !nextAccessor?.toLowerCase().includes(r?.toLowerCase()),
          )
        ) {
          record.filter = true;
        }
      }
    });
  return inputData.filter((f) => !f.filter);
}

function filterNumericRange(params: {
  filter: IFilter;
  inputData: any[];
  filterResults: any;
}) {
  const { inputData, filter, filterResults } = params;
  const { name, id } = filter;

  const result = filterResults ? filterResults[id]?.result : null;
  if (!result) {
    return inputData;
  }
  const { min, max, lowerBound, upperBound } = result;

  const filterMin = min || lowerBound;
  const filterMax = max || upperBound;
  inputData
    .filter((f) => !f.filter)
    .forEach((record) => {
      const nextAccessor = _.get(record, name);
      if (!nextAccessor || nextAccessor < filterMin) {
        record.filter = true;
      } else if (nextAccessor > filterMax) {
        record.filter = true;
      }
    });
  return inputData.filter((f) => !f.filter);
}

function filterDateRange(params: {
  filter: IFilter;
  inputData: any[];
  filterResults: any;
}) {
  const { inputData, filter, filterResults } = params;
  const { name, id } = filter;
  const result = filterResults ? filterResults[id]?.result : null;
  if (!result) {
    return inputData;
  }
  const { start, end } = result;

  inputData
    .filter((f) => !f.filter)
    .forEach((record) => {
      const nextAccessor = _.get(record, name);
      if (!nextAccessor || nextAccessor < start) {
        record.filter = true;
      } else if (nextAccessor > end) {
        record.filter = true;
      }
    });
  return inputData.filter((f) => !f.filter);
}

function filterBoolean(params: {
  filter: IFilter;
  inputData: any[];
  filterResults: any;
}) {
  const { inputData, filter, filterResults } = params;
  const { name, id } = filter;
  const result = filterResults ? filterResults[id]?.result : null;
  if (!result || result.length === 0) {
    return inputData;
  }
  inputData
    .filter((f) => !f.filter)
    .forEach((record) => {
      const nextAccessor = _.get(record, name);
      if (
        (nextAccessor === false && !result?.includes('false')) ||
        (filter.filterType === FilterType.Boolean && !nextAccessor)
      ) {
        record.filter = true;
      } else if (
        !nextAccessor &&
        !nextAccessor === false &&
        filter.filterType === FilterType.BooleanWithNotSelected &&
        !result?.includes('(None)')
      ) {
        record.filter = true;
      } else if (nextAccessor === true && !result?.includes('true')) {
        record.filter = true;
      }
    });
  return inputData.filter((f) => !f.filter);
}

export function filterData(params: IFilterData) {
  const { inputData, filters, filterResults } = params;
  let filteredData = _.cloneDeep(inputData);
  filters.forEach((filter) => {
    const { filterType, added } = filter;
    if (added) {
      switch (filterType) {
        case FilterType.Multi:
        case FilterType.Single:
        case FilterType.Month:
          filteredData = filterMultiSelect({
            filter,
            inputData: filteredData,
            filterResults,
          });
          break;
        case FilterType.Text:
          filteredData = filterText({
            filter,
            inputData: filteredData,
            filterResults,
          });
          break;
        case FilterType.NumericRange:
          filteredData = filterNumericRange({
            filter,
            inputData: filteredData,
            filterResults,
          });
          break;
        case FilterType.DateRange:
          filteredData = filterDateRange({
            filter,
            inputData: filteredData,
            filterResults,
          });
          break;
        case FilterType.Boolean:
        case FilterType.BooleanWithNotSelected:
          filteredData = filterBoolean({
            filter,
            inputData: filteredData,
            filterResults,
          });
          break;
      }
    }
  });
  return filteredData;
}

export function getColorGradient(params: IColorGradient) {
  const { startColor, endColor, gradient } = params;

  let outputColor = '#';
  for (let i = 1; i < 6; i += 2) {
    const start = parseInt(startColor.substring(i, i + 2), 16);
    const end = parseInt(endColor.substring(i, i + 2), 16);
    const nextGradient = Math.round(start + ((end - start) * gradient) / 100);
    outputColor +=
      nextGradient.toString(16).length === 1
        ? `0${nextGradient.toString(16)}`
        : nextGradient.toString(16);
  }
  return outputColor;
}

export function getStringSimilarity(params: {
  /**
   * The compareTwoStrings method (default) returns a number between 0 and 1,
   * higher indicates a better match. Strings are stringToCompareFrom and stringToCompare
   *
   * The findBestMatch method compares stringToCompareFrom with a set of strings
   * (compareSet) Returns (Object): An object with a ratings property, which
   * gives a similarity rating for each target string, a bestMatch property,
   * which specifies which target string was most similar to the main string,
   * and a bestMatchIndex property, which specifies the index of the bestMatch
   * in the targetStrings array.
   */
  compareMethod?: 'compareTwoStrings' | 'findBestMatch';
  stringToCompareFrom: string;
  stringToCompare?: string;
  compareSet?: string[];
  ignoreCase?: boolean;
}) {
  const {
    stringToCompareFrom,
    compareMethod,
    compareSet,
    ignoreCase,
    stringToCompare,
  } = params;
  if (compareMethod === 'findBestMatch') {
    return stringSimilarity.findBestMatch(
      ignoreCase ? stringToCompareFrom.toLowerCase() : stringToCompareFrom,
      ignoreCase ? compareSet.map((c) => c.toLowerCase) : compareSet,
    );
  } else {
    return stringSimilarity.compareTwoStrings(
      ignoreCase ? stringToCompareFrom.toLowerCase() : stringToCompareFrom,
      ignoreCase ? stringToCompare.toLowerCase() : stringToCompare,
    );
  }
}

export function getCountryData(): any[] {
  return lookup.countries.map((c) => {
    return { iso2: c.iso2, country: c.country };
  });
}

export function validateBarCode(barCode): boolean {
  return validbarcode(barCode);
}

export function getChecksum(stringValue, buckets) {
  const rng = seedrandom(stringValue);
  return Math.abs(rng.int32() % buckets);
}

export function associateBy<K extends string | number | symbol, V>(
  array: V[],
  block: (v: V) => K,
): Record<K, V> {
  const object: Record<K, V> = {} as Record<K, V>;
  for (const value of array) {
    const key = block(value);
    object[key] = value;
  }
  return object;
}

export function associateByMap<K, V>(
  array: V[],
  block: (v: V) => K,
): Map<K, V> {
  const map: Map<K, V> = new Map();
  for (const value of array) {
    const key = block(value);
    map.set(key, value);
  }
  return map;
}

export const mapToObject = <K extends string, V>(map: Map<K, V>) =>
  Object.fromEntries(map.entries());
