import { Auth0Provider, useAuth0 } from '@auth0/auth0-react';
import { IdToken } from '@auth0/auth0-spa-js';
import Typography from '@mui/material/Typography';
import LogRocket from 'logrocket';
import { FunctionComponent, useEffect, useState } from 'react';
import { getAudienceFromDomain } from 'universal/auth0UtilitiesUniversal';
import { COMPANY_NAME } from 'universal/common/commonConstants';
import {
  LogLevels,
  Loggers,
  getLogger,
  setErrorLogWrapper,
} from 'universal/loggerSupport';
import { StackInfo, createStackInfo } from 'universal/stackInfo';

import Loading from '../components/atoms/Loading';
import { isBrowserUnderTest, isDev, isTesting } from '../util/environment';
import { ITestEnvironment } from '../util/widgetUtilities';

import {
  AuthenticatorContext,
  IAuthenticatorContext,
} from './AuthenticatorContext';

const logger = getLogger({
  name: Loggers.CLIENT_INITIALIZE,
  level: LogLevels.Info,
});

let testingIdToken;

export const Authenticator: FunctionComponent<
  React.PropsWithChildren<{
    isNode?: boolean;
    testEnvironment?: ITestEnvironment;
    history: any;
  }>
> = (props) => {
  const [authContext, setAuthContext] = useState<IAuthenticatorContext>(null);
  const { isNode, history, testEnvironment, children } = props;

  useEffect(() => {
    const setup = async () => {
      if (!isTesting() && !isDev()) {
        LogRocket.init(`d6vxko/${COMPANY_NAME}-dev`, {
          release: window[COMPANY_NAME],
        });

        setErrorLogWrapper((logFunction: (...args) => void) => {
          return (...args) => {
            const message = typeof args[0] === 'string' ? args[0] : args[1];
            // The point of this is to trigger an error condition in LR. The actual logging data
            // will appear through the normal mechanism
            LogRocket.error(message);
            logFunction(...args);
          };
        });
      }

      let stackInfo: StackInfo;
      if (testEnvironment) {
        stackInfo = testEnvironment.clientManager.stackInfo;
      } else {
        stackInfo = createStackInfo(isNode);
        await stackInfo.readFromStack();
      }

      const urlParams = new URLSearchParams(window.location.search);
      testingIdToken = testEnvironment
        ? testEnvironment.testIdToken
        : urlParams.get('idToken');
      if (testingIdToken) {
        logger.info('Found test idToken');
      }

      setAuthContext({
        stackInfo,
        history,
      });
    };
    if (!authContext) {
      logger.info('Authenticator SETUP');
      void setup();
    }
  }, [isNode, authContext, testEnvironment, history]);

  const setupAuthContext = (
    auth0Logout: (LogoutOptions) => void,
    getIdTokenClaims: () => Promise<IdToken>,
  ) => {
    setAuthContext({
      ...authContext,
      logout: () =>
        auth0Logout({ logoutParams: { returnTo: window.location.origin } }),
      isAuthenticated: () => true,
      getIdToken: async () =>
        isBrowserUnderTest()
          ? testingIdToken
          : (await getIdTokenClaims()).__raw,
    });
    logger.debug('Authenticator SETUP AuthContext');
  };

  if (authContext) {
    const stackInfo = authContext.stackInfo;
    const { auth0Domain: domain, auth0ClientId: clientId } =
      stackInfo.getStackConfig();

    const audience = getAudienceFromDomain(domain);

    if (isBrowserUnderTest()) {
      if (!testingIdToken) {
        logger.error('idToken not specified for browser under test');
        throw new Error('idToken not specified for browser under test');
      }
      logger.info('Using browser test mode');
      return (
        <AuthenticatorContext.Provider value={authContext}>
          <AuthenticatorWrapper setupAuthContext={setupAuthContext}>
            {children}
          </AuthenticatorWrapper>
        </AuthenticatorContext.Provider>
      );
    }

    logger.debug('Authenticator RENDERING');
    return (
      <AuthenticatorContext.Provider value={authContext}>
        <Auth0Provider
          domain={domain}
          clientId={clientId}
          authorizationParams={{
            audience,
            redirect_uri: window.location.origin,
          }}
        >
          <AuthenticatorWrapper setupAuthContext={setupAuthContext}>
            {children}
          </AuthenticatorWrapper>
        </Auth0Provider>
      </AuthenticatorContext.Provider>
    );
  }

  return <Loading />;
};

export const AuthenticatorWrapper: FunctionComponent<
  React.PropsWithChildren<{
    setupAuthContext: (
      logout: (LogoutOptions) => void,
      getIdTokenClaims: () => Promise<IdToken>,
    ) => void;
  }>
> = (props) => {
  const {
    isLoading,
    isAuthenticated,
    loginWithRedirect,
    error,
    logout,
    getIdTokenClaims,
  } = useAuth0();

  const [authContextSetup, setAuthContextSetup] = useState(false);

  useEffect(() => {
    if (
      !authContextSetup &&
      (isAuthenticated || isBrowserUnderTest() || isTesting())
    ) {
      props.setupAuthContext(logout, getIdTokenClaims);
      setAuthContextSetup(true);
    }
  }, [authContextSetup, logout, isAuthenticated, getIdTokenClaims, props]);

  if (isTesting() || isBrowserUnderTest()) {
    if (authContextSetup) {
      return <>{props.children}</>;
    }
    return <Loading />;
  }

  if (!isAuthenticated && !isLoading) {
    void loginWithRedirect();
  }
  if (!authContextSetup || isLoading) {
    return <Loading />;
  }
  if (error) {
    return <Typography>{error.message}</Typography>;
  }
  return <>{props.children}</>;
};
