import {
  categories,
  duplicateBy,
  IConfigurationType,
  IWidgetProps,
} from '../types';

const menuWidgetPropertyInfo = {
  mouseTrigger: {
    default: "'click'",
    options: ["'click'", "'rightClick'"],
  },
  anchorReference: {
    options: ["'anchorEl'", "'anchorPosition'"],
    default: "'anchorEl'",
    description:
      'switches between html element and xy coords as the anchoring point of the popup, anchor position breaks within scroll',
  },
  anchorOriginVertical: {
    description: 'sets the contact corner of the click trigger element',
    default: "'top'",
    options: ["'top'", "'center'", "'bottom'"],
  },
  anchorOriginHorizontal: {
    description: 'sets the contact corner of the click trigger element',
    default: "'right'",
    options: ["'left'", "'center'", "'right'"],
  },
  transFormOriginVertical: {
    description: 'sets the contact corner of the popup',
    default: "'left'",
    options: ["'top'", "'center'", "'bottom'"],
  },
  transFormOriginHorizontal: {
    description: 'sets the contact corner of the popup',
    default: "'top'",
    options: ["'left'", "'center'", "'right'"],
  },
  width: {},
  height: {},
  maxWidth: {},
  maxHeight: {},
} as const;

export type MenuWidgetProps = IWidgetProps<typeof menuWidgetPropertyInfo>;

export const menuWidgetTypeInfo: IConfigurationType = {
  types: {
    actions: true,
  },
  childTypes: {
    children: ['MenuItemWidget', 'Button', 'KendoExportButton'],
  },
  category: categories.popup,
  childProperties: {
    ...duplicateBy,
  },
  properties: menuWidgetPropertyInfo,
  events: {
    load: [],
  },
};
