export interface IFilterInfo {
  filterType?: FilterType;
  autoComplete?: FilterAutoCompleteType;
  order?: number;
  nestedField?: string;
  defaultOn?: boolean;
  filterOptions?: boolean;
  width?: string;
}

export enum FilterType {
  Text = 'Text',
  Single = 'Single',
  NumericRange = 'NumericRange',
  DateRange = 'DateRange',
  Multi = 'Multi',
  Boolean = 'Boolean',
  BooleanWithNotSelected = 'BooleanWithNotSelected',
  Month = 'Month',
}
export enum FilterAutoCompleteType {
  On = 'On',
  Off = 'Off',
  Auto = 'Auto',
}

export interface IFilter {
  matchCase: boolean;
  name: string;
  id: string;
  displayName: string;
  label?: string;
  labelCode?: string;
  nestedField?: string;
  order: number;
  filterType: FilterType;
  autoComplete: FilterAutoCompleteType;
  array?: boolean;
  enumTypeDef?: string;
  defaultOn?: boolean;
  filterOptions?: boolean;
  added?: boolean;
}

export interface IFilterData {
  filters: IFilter[];
  inputData: any[];
  filteredData: any[];
  filterResults: any;
}
