import { IConfigurationType, IWidgetProps, textVariants } from '../types';

const collapsePropertyInfo = {
  headerText: {},
  headerVariant: {
    default: "'body1'",
    description:
      'Applies the style and sizing, see examples of each here in link',
    link: 'https://material-ui.com/style/typography/',
    options: textVariants,
  },
  defaultExpanded: {
    default: true,
    options: [true, false],
  },
} as const;

export type CollapseProps = IWidgetProps<typeof collapsePropertyInfo>;

export const collapseTypeInfo: IConfigurationType = {
  types: {
    children: true,
  },
  childTypes: {
    children: true,
  },
  properties: collapsePropertyInfo,
};
