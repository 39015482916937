import { BasicType } from 'universal/types';

import {
  categories,
  IConfigurationType,
  inputProperties,
  IWidgetProps,
  typeDefProperties,
} from '../types';

const richTextProperties = {
  ...typeDefProperties,
  ...inputProperties,
  width: {
    description: 'px or %',
  },
  height: {
    default: 200,
    description: 'must be in pixels',
  },
  allowModal: {
    type: BasicType.Boolean,
    description: 'If true, will provide a button to open editor in a modal',
  },
} as const;

export type RichTextProps = IWidgetProps<typeof richTextProperties>;
export const richTextTypeInfo: IConfigurationType = {
  types: {
    children: true,
  },
  category: categories.input,
  childTypes: {},
  properties: richTextProperties,
  childProperties: {},
  events: {
    load: [],
    validate: [],
    update: [],
  },
};
