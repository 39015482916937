import Widget from '../components/widgetEngine/Widget';
import { IPassThroughProps, IWidgetTree } from '../components/widgets/types';
import { ContextMap } from '../store/context';

import { compileProperty } from './widgetUtilities';

export function generateChildren({
  childWidgets,
  aliases,
  childContext,
  passThroughProps,
  options,
}: {
  childWidgets: IWidgetTree[];
  aliases: ContextMap;
  childContext: any;
  passThroughProps: IPassThroughProps;
  options?: any;
}) {
  const Children = [];
  childWidgets.forEach((child) => {
    const duplicateBy = compileProperty(
      'duplicateBy',
      child.properties.duplicateBy,
      childContext,
    );
    const { duplicateBy: matchDuplicateBy } = child.matchProperties;
    const { instanceName } = child.matchProperties;

    if (duplicateBy && Array.isArray(duplicateBy)) {
      duplicateBy.forEach((instance, index) => {
        const nodeName = instanceName
          ? `_${instanceName}_${index}`
          : `_${index}`;
        Children.push(
          <Widget
            {...options}
            component={child}
            passThroughProps={passThroughProps}
            index={index}
            aliases={aliases.createDescendent({ nodeName })}
            key={`${child.widgetId}-${index}`}
          />,
        );
      });
    } else {
      if (matchDuplicateBy) {
        throw new Error(
          `a duplicateBy property ${matchDuplicateBy} was defined but was not found in context or not an array`,
        );
      }
      Children.push(
        <Widget
          {...options}
          component={child}
          passThroughProps={passThroughProps}
          aliases={aliases}
          key={child.widgetId}
        />,
      );
    }
  });
  return Children;
}

export function generateSimpleChildren(props) {
  const {
    childWidgets,
    context: { childContext },
    passThroughProps,
    aliases,
  } = props;

  return generateChildren({
    childWidgets,
    aliases,
    childContext,
    passThroughProps,
  });
}
