import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { FunctionComponent, useContext } from 'react';
import { findConfigItem } from 'universal/metadataSupport';

import { externalUrl, getUrlFromImagePath } from '../../util/clientUtilities';
import { ApplicationContext } from '../../wrappers/ApplicationContext';
import { ClientContext } from '../../wrappers/ClientContext';
import CustomPopover from '../molecules/CustomPopover';
import TreeRenderer from '../widgetEngine/TreeRenderer';

import ApplicationSelect from './ApplicationSelect';
import { appBarHeight } from './NavLayout';
import UserOptions from './UserOptions';

interface IProps {
  sx: any;
  navDrawerIsOpen: boolean;
  passThroughProps;
  openNavDrawer: () => void;
  closeNavDrawer: () => void;
}

const NavBar: FunctionComponent<React.PropsWithChildren<IProps>> = (props) => {
  const {
    sx,
    navDrawerIsOpen,
    openNavDrawer,
    closeNavDrawer,
    passThroughProps,
  } = props;

  const { clientManager, userInfo, widgetTrees } = useContext(ClientContext);
  const { application } = useContext(ApplicationContext);

  const applicationTitle = application.nameInfo.displayName;
  const {
    configName,
    primaryLogo,
    secondaryLogo,
    helpUrl,
    secondaryLogoHeight,
    primaryLogoHeight,
    navbarWidgetTree: navbarWidgetTreeName,
  } = application;
  const { userName, impersonatedRole } = userInfo;

  const sxTitle = { p: 2, whiteSpace: 'noWrap' };

  const primaryLogoUrl =
    primaryLogo && getUrlFromImagePath(primaryLogo, configName, clientManager);
  const secondaryLogoUrl =
    secondaryLogo &&
    getUrlFromImagePath(secondaryLogo, configName, clientManager);

  const externalHelpUrl = externalUrl(helpUrl);
  const helpIcon = helpUrl && (
    <Icon
      style={{ textDecoration: 'none', paddingRight: '10px' }}
      color="secondary"
      component="a"
      href={externalHelpUrl}
      target="_blank"
    >
      help_outline
    </Icon>
  );

  const toggleDrawer = navDrawerIsOpen ? closeNavDrawer : openNavDrawer;

  const userControls = [
    <CustomPopover content={<UserOptions />} key="accountButton">
      <Box
        sx={{
          pr: 2,
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Typography variant="subtitle1">{userName}</Typography>
      </Box>
    </CustomPopover>,
  ];

  const titles = [];
  let stackTypeName = clientManager.getStackTypeName();
  if (stackTypeName === 'training') {
    stackTypeName = 'TRAINING SYSTEM - YOUR CHANGES WILL BE DISCARDED';
  }
  if (stackTypeName) {
    titles.push(
      <Typography key={stackTypeName} sx={sxTitle} variant="h5">
        {stackTypeName}
      </Typography>,
    );
  }
  if (impersonatedRole) {
    titles.push(
      <Typography
        key="impersonate"
        sx={sxTitle}
        variant="h5"
      >{`[Impersonating: ${impersonatedRole.nameInfo.displayName}]`}</Typography>,
    );
  }
  titles.push(
    <Typography key={applicationTitle} sx={sxTitle} variant="h6">
      {applicationTitle}
    </Typography>,
  );
  const navbarWidgetTree =
    navbarWidgetTreeName &&
    findConfigItem(widgetTrees, navbarWidgetTreeName, configName);

  return (
    <AppBar sx={{ ...sx, 'z-index': (theme) => theme.zIndex.drawer + 1 }}>
      <Toolbar
        sx={{
          // Have to override the @media query because the standard is 64px
          '@media (min-width: 0px)': {
            minHeight: `${appBarHeight}px`,
          },
          // minHeight: `${appBarHeight}px`,
          height: `${appBarHeight}px`,
          backgroundColor: 'white',
          color: 'black',
        }}
        disableGutters
      >
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={toggleDrawer}
          size="large"
        >
          <Icon>{navDrawerIsOpen ? 'chevron_left' : 'menu'}</Icon>
        </IconButton>
        {primaryLogo && (
          <Box sx={{ pr: 4 }}>
            <img
              src={primaryLogoUrl}
              height={primaryLogoHeight || appBarHeight}
              alt="Primary logo"
            />
          </Box>
        )}
        {secondaryLogo && (
          <Box sx={{ pr: 4 }}>
            <img
              height={secondaryLogoHeight || appBarHeight}
              src={secondaryLogoUrl}
              alt="Secondary logo"
            />
          </Box>
        )}
        <Box
          sx={{
            flex: 1,
            alignItems: 'baseline',
            display: 'flex',
          }}
        >
          {titles}
        </Box>
        {applicationTitle && (
          <Box
            sx={{
              width: '20em',
              margin: '2em',
            }}
          >
            <ApplicationSelect />
          </Box>
        )}
        {navbarWidgetTree && (
          <Box sx={{ flex: 0 }}>
            <TreeRenderer
              tree={navbarWidgetTree}
              key={'navbarWidgetTree'}
              initialPassThroughProps={passThroughProps}
            />
          </Box>
        )}
        {helpIcon}
        {userControls}
      </Toolbar>
    </AppBar>
  );
};

export default NavBar;
