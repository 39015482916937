import { IConfigurationType } from '../types';

const cardHeaderProperties = {
  title: {},
  subheader: {},
};

export const cardHeaderTypeInfo: IConfigurationType = {
  nestedConfigItem: true,
  types: {
    cardItems: true,
  },
  childTypes: {
    children: ['Button'],
  },
  properties: cardHeaderProperties,
};

const cardContentProperties = {};

export const cardContentTypeInfo: IConfigurationType = {
  nestedConfigItem: true,
  types: {
    cardItems: true,
  },
  childTypes: {
    children: true,
  },
  properties: cardContentProperties,
};

const cardActionsProperties = {};

export const cardActionsTypeInfo: IConfigurationType = {
  nestedConfigItem: true,
  types: {
    cardItems: true,
  },
  childTypes: {
    children: ['Button'],
  },
  properties: cardActionsProperties,
};

const cardMediaProperties = {
  image: {
    description:
      'Path of the image file from within the image folder of the s3 bucket, including the file extension!',
  },
  height: {
    description: 'Height of the image.',
  },
  width: {
    description: 'Width, if you want less than the card full width',
  },
};

export const cardMediaTypeInfo: IConfigurationType = {
  nestedConfigItem: true,
  types: {
    cardItems: true,
  },
  childTypes: {},
  properties: cardMediaProperties,
};
