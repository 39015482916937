import { Typography } from '@mui/material';
import { FunctionComponent } from 'react';
import { Redirect } from 'react-router-dom';

const DefaultHome: FunctionComponent<
  React.PropsWithChildren<{ homePage: string }>
> = (props) => {
  const { homePage } = props;
  if (homePage) {
    return <Redirect to={homePage} />;
  }
  return (
    <div id="home">
      <Typography component="h1">
        Please select one of the options on the left menu.
      </Typography>
    </div>
  );
};

export default DefaultHome;
