import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
} from '@mui/material';
import { useContext } from 'react';

import { getUrlFromImagePath } from '../../../util/clientUtilities';
import { generateChildren } from '../../../util/generateChildren';
import { compileProperties } from '../../../util/widgetUtilities';
import { ClientContext } from '../../../wrappers/ClientContext';
import { withActions } from '../../widgetEngine/ActionEnabler';
import { IConfigurationType, IWidgetProps } from '../types';

// TODO: enforce this properties const to IPropertyInfos interface
const properties = {
  width: {
    description:
      'width as a css string. Height is defined by context, Cards do not scroll',
    default: "'350px'",
  },
  height: {
    description:
      'height as a css string, cards will not scroll, use height to ensure that all cards are same size',
  },
  spacing: {
    description:
      'amount of space around the box. A number representing 8px * n. CSS strings are also allowed',
    default: 1,
  },
} as const;

export const cardWidgetTypeInfo: IConfigurationType = {
  types: {
    children: true,
  },
  childTypes: {
    cardItems: true,
  },
  properties,
  events: {
    load: [],
  },
};

type IProps = IWidgetProps<typeof properties>;

function CardWidget(props: IProps) {
  const {
    component: { cardItems },
    aliases,
    passThroughProps,
    context: { childContext },
    context: { nestedConfigContext: nonWidgetContext },
    width,
    height,
    spacing,
  } = props;

  const { clientManager } = useContext(ClientContext);
  const configName = props.component.configName;

  const getImage = (imagePath) =>
    getUrlFromImagePath(imagePath, configName, clientManager);

  const cardComponents = cardItems.map((cardItem) => {
    const itemChildren =
      cardItem.children &&
      generateChildren({
        childWidgets: cardItem.children,
        aliases,
        childContext,
        passThroughProps,
      });

    const itemProps = compileProperties(cardItem.properties, nonWidgetContext);
    return cardItemToMaterial[cardItem.type](itemChildren, itemProps, getImage);
  });

  return (
    <Box p={spacing} style={{ width, boxSizing: 'border-box' }}>
      <Card style={{ height: height ? height : '' }}> {cardComponents}</Card>
    </Box>
  );
}

const cardItemToMaterial = {
  CardHeader: (children, props) => <CardHeader action={children} {...props} />,
  CardContent: (children, props) => (
    <CardContent {...props}>{children}</CardContent>
  ),
  CardActions: (children, props) => (
    <CardActions {...props}>{children}</CardActions>
  ),
  CardMedia: (children, props, getImage) => {
    const { image, height, width } = props;
    const imageUrl = getImage(image);
    return (
      <CardMedia
        image={imageUrl}
        style={{ height, width: width ? width : '' }}
      />
    );
  },
};

export default withActions(CardWidget);
