import {
  DeleteObjectCommandOutput,
  GetObjectCommandOutput,
} from '@aws-sdk/client-s3';
import JSZip from 'jszip';
import safeJsonStringify from 'safe-json-stringify';

import { ClientManager } from '../clientManager';
import { CodeSupport } from '../codeSupport';
import { LOAD_INTO_MEMORY, SYSTEM } from '../common/commonConstants';
import { reThrow } from '../errors/errorLog';
import {
  IErrorNested,
  getErrorString,
  getOriginalError,
} from '../errors/errorString';
import { retry } from '../errors/retry';
import { LogLevels, Loggers, getLogger } from '../loggerSupport';
import { MetadataSupport } from '../metadataSupport';
import {
  CODE,
  CODE_TYPES_TYPE,
  CONFIG_SEPARATOR,
  DIRECTIVE_ACTIVEASOF,
  ENTITY_INCARNATIONS,
  IEntityType,
  IHasId,
  SINGLETON_ID,
  SYSTEM_CODE,
} from '../metadataSupportConstants';
import { SchemaManager, UseTestData } from '../schemaManager';
import { ITestContext } from '../testSupport';

import { ConvertType } from './graphQLManager';
import { okToLog } from './graphQLSupport';
import { PipelineManager } from './pipelineManager';

const logger = getLogger({
  name: Loggers.LOAD_INTO_MEMORY_SUPPORT,
  level: LogLevels.Info,
});

interface IControl {
  incarnation: number;
  // Each segment is a zip file
  numberOfSegments: number;
}

// Identifies the object to be saved. This is either an entire entity, or if the entity is
// system:Code, then it's all of the records associated with a given code type (as Code can be quite large).
interface ISavedEntityKey {
  executionConfig: string;
  // Must be qualified with the config name
  entityName: string;
  isTestData: boolean;
  codeType?: string;
}

// Derived from the corresponding typeDef
// tslint:disable-next-line:class-name interface-name
interface I_EntityIncarnation {
  savedEntityKey: string;
  incarnation: number;
}

// Derived from the corresponding typeDef
// tslint:disable-next-line:class-name interface-name
interface I_EntityIncarnations {
  id: string;
  incarnations: I_EntityIncarnation[];
}

interface ILoadEntityError extends IErrorNested {
  incorrectExpectedIncarnation: boolean;
  expectedIncarnation: number;
  foundIncarnation: number;
}

// Used to allow the caching of entity incarnations for a given GraphQL request
export interface IEntityIncarnationsHolder {
  entityIncarnations?: I_EntityIncarnations;
}

const RECORDS_FILE = 'records';

const INITIAL_INCARNATION = -1;

// Can't make the zip files too big
const ZIPRECORD_COUNT = 50 * 1024;
// Allow this to be changed by the test code
let zipRecordCount = ZIPRECORD_COUNT;

export class LoadIntoMemorySupport {
  private readonly pipelineManager: PipelineManager;
  private readonly clientManager: ClientManager;
  private readonly schemaManager: SchemaManager;

  private entityMapPromise = {};
  private entityMap: { [entityId: string]: { [id: string]: any } } = {};

  private entityCurrentIncarnation = {};

  constructor(pipelineManager: PipelineManager) {
    this.pipelineManager = pipelineManager;
    this.clientManager = pipelineManager.clientManager;
    this.schemaManager = this.clientManager.schemaManager;
  }

  public testSetZipRecordCount(count: number) {
    zipRecordCount = Math.round(count);
  }

  private makeSavedEntityKeyString(savedEntityKey: ISavedEntityKey) {
    const { executionConfig, entityName, codeType, isTestData } =
      savedEntityKey;
    const testString = isTestData ? '_TEST' : '';
    if (savedEntityKey.codeType) {
      return `${executionConfig}${testString}_${entityName}_${codeType}`;
    }
    return `${executionConfig}${testString}_${entityName}`;
  }

  private makeS3Params(fileName: string) {
    return {
      Bucket: this.clientManager.s3Support.getS3StackBucketName(),
      Key: `${LOAD_INTO_MEMORY}/${fileName.replace(CONFIG_SEPARATOR, '-')}`,
    };
  }

  public async entityModified(params: {
    input: IHasId[];
    entityType: IEntityType;
    useTestData: UseTestData;
    requestId: string;
  }) {
    const { input, entityType, useTestData, requestId } = params;

    if (!okToLog(entityType) || !entityType.loadIntoMemory) {
      return;
    }

    logger.debug(
      { entityType, requestId, input: safeJsonStringify(input) },
      'Entity Modified',
    );

    const executionConfig =
      this.clientManager.schemaManager.executionConfigName;

    // For a batch mutation on codes, there could be multiple code types
    if (input && entityType.id === SYSTEM_CODE) {
      const codeTypes = {};
      input.forEach((i) => {
        const idCodeType = CodeSupport.getCodeTypeFromId(i.id);
        codeTypes[idCodeType] = idCodeType;
      });

      const promises = [];
      for (const codeType in codeTypes) {
        const { savedEntityKey } = this.getCodeTypeInfo({
          id: CodeSupport.makeIdWithCodeType('unused', codeType),
          entity: entityType,
          executionConfig,
          useTestData,
        });

        promises.push(
          this.updateIncarnations({
            savedEntityKey,
            requestId,
            executionConfig,
          }),
        );
      }
      await Promise.all(promises);
      return;
    }

    await this.updateIncarnations({
      savedEntityKey: {
        executionConfig,
        entityName: MetadataSupport.getQualifiedName(
          entityType.id,
          entityType.configName,
        ),
        isTestData: this.schemaManager.isTestData(entityType, useTestData),
      },
      requestId,
      executionConfig,
    });
  }

  public async updateIncarnations(params: {
    savedEntityKey: ISavedEntityKey;
    requestId: string;
    executionConfig: string;
    deleteEntity?: boolean;
  }) {
    const { savedEntityKey, requestId, deleteEntity } = params;
    const savedEntityKeyString = this.makeSavedEntityKeyString(savedEntityKey);
    const entityIncarnations = await this.getOrCreateEntityIncarnations(params);

    const entityIncarnation = entityIncarnations.incarnations.find(
      (i) => i.savedEntityKey === savedEntityKeyString,
    );

    if (!entityIncarnation) {
      throw new Error(
        `Missing incarnation for entity: ${savedEntityKeyString}`,
      );
    }

    if (deleteEntity) {
      const incarnationList = entityIncarnations.incarnations;
      for (let i = 0; i < incarnationList.length; i++) {
        if (incarnationList[i].savedEntityKey === savedEntityKeyString) {
          incarnationList.splice(i, 1);
          break;
        }
      }
    }

    logger.debug(
      { savedEntityKey, requestId, entityIncarnation, entityIncarnations },
      'Update Incarnations',
    );

    entityIncarnation.incarnation++;

    // FIXME - needs conditional update check to make sure some other process did not
    // save first, see WORM-1325
    await this.pipelineManager.updateRecord({
      entityName: ENTITY_INCARNATIONS,
      configName: SYSTEM,
      record: entityIncarnations,
      requestId,
    });

    const input = {
      savedEntityKey,
      incarnation: entityIncarnation.incarnation,
    };

    // Do this remotely because the browser cannot write to S3
    await this.pipelineManager.executePipelineRemote({
      name: 'saveEntity',
      configName: SYSTEM,
      input,
    });
  }

  public async getOrCreateEntityIncarnations(params: {
    savedEntityKey: ISavedEntityKey;
    requestId?: string;
    executionConfig: string;
  }): Promise<I_EntityIncarnations> {
    const { savedEntityKey, requestId, executionConfig } = params;
    const savedEntityKeyString = this.makeSavedEntityKeyString(savedEntityKey);
    let entityIncarnations: I_EntityIncarnations =
      await this.pipelineManager.getRecord({
        entityName: ENTITY_INCARNATIONS,
        configName: SYSTEM,
        id: SINGLETON_ID,
        requestId,
        executionConfigName: executionConfig,
      });

    if (entityIncarnations) {
      const entityIncarnation = entityIncarnations.incarnations.find(
        (i) => i.savedEntityKey === savedEntityKeyString,
      );

      if (entityIncarnation) {
        return entityIncarnations;
      }
      entityIncarnations.incarnations.push({
        savedEntityKey: savedEntityKeyString,
        incarnation: INITIAL_INCARNATION,
      });
    } else {
      // First time, need to create the entity
      entityIncarnations = {
        id: SINGLETON_ID,
        incarnations: [
          {
            savedEntityKey: savedEntityKeyString,
            incarnation: INITIAL_INCARNATION,
          },
        ],
      };
    }

    await this.pipelineManager.upsertRecord({
      entityName: ENTITY_INCARNATIONS,
      configName: SYSTEM,
      record: entityIncarnations,
      requestId,
    });
    logger.info(entityIncarnations, 'EntityIncarnations - created');
    return entityIncarnations;
  }

  public async getOrCreateEntityIncarnation(params: {
    savedEntityKey: ISavedEntityKey;
    requestId?: string;
    executionConfig: string;
  }): Promise<I_EntityIncarnation> {
    const { savedEntityKey } = params;
    const savedEntityKeyString = this.makeSavedEntityKeyString(savedEntityKey);
    const entityIncarnations = await this.getOrCreateEntityIncarnations(params);
    const entityIncarnation = entityIncarnations.incarnations.find(
      (i) => i.savedEntityKey === savedEntityKeyString,
    );
    return entityIncarnation;
  }

  private getCodeTypeInfo(params: {
    id: string;
    entity: IEntityType;
    useTestData: UseTestData;
    executionConfig: string;
  }): {
    savedEntityKey: ISavedEntityKey;
    codeType: string;
    codeTypeEntity: IEntityType;
  } {
    const { id, entity, useTestData, executionConfig } = params;
    const codeType =
      entity.id === SYSTEM_CODE ? CodeSupport.getCodeTypeFromId(id) : undefined;
    let codeTypeEntity;
    if (codeType) {
      codeTypeEntity =
        this.clientManager.metadataSupport.getEntityTypeFromUnqualifiedEntityName(
          codeType,
          true,
        );
    }
    const savedEntityKey = {
      executionConfig,
      entityName: entity.id,
      codeType,
      isTestData: this.schemaManager.isTestData(entity, useTestData),
    };
    return { savedEntityKey, codeType, codeTypeEntity };
  }

  /**
   * This is called when a stack is first created to make sure we have the
   * current version of all loadIntoMemory entities created. Because of this, it
   * never uses test data.
   */
  public async saveAllEntities() {
    const { schemaManager } = this.clientManager;

    const entityTypes = schemaManager.getAllEntityTypes();

    for (const executionConfig of schemaManager.availableExecConfigNames) {
      const promises = [];

      await schemaManager.changeExecutionConfig(executionConfig);

      await this.pipelineManager.deleteAllRecords({
        entityName: MetadataSupport.getQualifiedName(
          ENTITY_INCARNATIONS,
          SYSTEM,
        ),
        executionConfigName: executionConfig,
      });

      for (const et of entityTypes) {
        if (
          !et.loadIntoMemory ||
          (et.id === CODE && et.configName === SYSTEM)
        ) {
          continue;
        }
        const savedEntityKey: ISavedEntityKey = {
          executionConfig,
          entityName: MetadataSupport.getQualifiedName(et.id, et.configName),
          isTestData: false,
        };
        promises.push(
          this.saveEntity({
            savedEntityKey,
            incarnation: (
              await this.getOrCreateEntityIncarnation({
                savedEntityKey,
                executionConfig: executionConfig,
              })
            ).incarnation,
            // We have no way of knowing if an entity is actually used in a given
            // execution config, so just don't save the empty ones
            skipIfEmpty: true,
          }).catch((e) =>
            logger.error(
              `Unable to save entity: ${
                savedEntityKey.entityName
              }: ${getErrorString(e)}`,
            ),
          ),
        );
      }

      const codeTypesResult = await this.pipelineManager.listRecords({
        entityName: SYSTEM_CODE,
        queryArguments: { codeType: CODE_TYPES_TYPE },
      });

      for (const ct of codeTypesResult.items) {
        // Code types corresponding to entities have already been processed
        const entity = this.clientManager.schemaManager.getEntityType({
          name: ct.code,
          configName: executionConfig,
          noThrow: true,
        });
        if (entity) {
          continue;
        }

        const savedEntityKey: ISavedEntityKey = {
          executionConfig,
          entityName: SYSTEM_CODE,
          codeType: ct.code,
          isTestData: false,
        };
        promises.push(
          this.saveEntity({
            savedEntityKey,
            incarnation: (
              await this.getOrCreateEntityIncarnation({
                savedEntityKey,
                executionConfig: executionConfig,
              })
            ).incarnation,
            skipIfEmpty: true,
          }),
        );
      }

      // Have to do all of them for the execution config, since that's set in the SchemaManager
      await Promise.all(promises);
    }
  }

  // FIXME Think about the race conditions associated with multiple mutations happening at once and the
  // linkage between the incarnation here and that in the incarnation records.

  // Returns true if something actually saved, false if not
  public async saveEntity(params: {
    savedEntityKey: ISavedEntityKey;
    executionConfigName?: string;
    incarnation: number;
    skipIfEmpty?: boolean;
  }): Promise<void> {
    const {
      savedEntityKey,
      incarnation,
      executionConfigName = this.clientManager.schemaManager
        .executionConfigName,
      skipIfEmpty,
    } = params;
    const { codeType, entityName } = savedEntityKey;

    const savedEntityKeyString = this.makeSavedEntityKeyString(savedEntityKey);
    const startTime = Date.now();

    let queryArguments;
    if (entityName === SYSTEM_CODE) {
      queryArguments = { codeType };
    }
    const entityListRecords = await this.pipelineManager.listRecords({
      entityName,
      executionConfigName,
      queryArguments,
      fieldDirectives: `@${DIRECTIVE_ACTIVEASOF}`,
      noCache: true,
    });

    const entityRecords = entityListRecords.items;
    if (entityRecords.length === 0 && skipIfEmpty) {
      return;
    }

    const entityType = this.clientManager.schemaManager.getEntityType({
      name: entityName,
    }) as IEntityType;
    const typeDef = this.clientManager.schemaManager.getTypeDefinition({
      name: entityType.typeDefinition,
      configName: entityType.configName,
    });

    this.clientManager.metadataSupport.convertObjectReferences({
      obj: entityRecords,
      typeDef,
      convertType: ConvertType.OBJECT_TO_ID,
    });

    let offset = 0;
    let seq = 0;
    while (offset < entityRecords.length) {
      const itemsToWrite: { [id: string]: any[] } = {};
      for (
        let i = offset;
        i < offset + zipRecordCount && i < entityRecords.length;
        i++
      ) {
        itemsToWrite[entityRecords[i].id] = entityRecords[i];
      }

      const zip = new JSZip();
      zip.file(RECORDS_FILE, safeJsonStringify(itemsToWrite));
      const zipBytes = await zip.generateAsync({ type: 'array' });
      const s3BaseParams = this.makeS3Params(
        `${savedEntityKeyString}_${seq}.zip`,
      );
      const s3Params = {
        Body: Buffer.from(zipBytes),
        ...s3BaseParams,
      };
      try {
        await retry({
          command: () => this.clientManager.s3Support.putObject(s3Params),
        });
      } catch (error) {
        reThrow({
          logger,
          error,
          message: `Problem accessing S3: ${safeJsonStringify(s3BaseParams)}`,
        });
      }
      offset += zipRecordCount;
      seq++;
    }

    await retry({
      command: () =>
        this.clientManager.s3Support.putObject({
          Body: Buffer.from(
            safeJsonStringify({ incarnation, numberOfSegments: seq }),
          ),
          ...this.makeS3Params(`${savedEntityKeyString}.json`),
        }),
    });

    logger.info(
      {
        executionConfigName,
        savedEntityKey,
        recordCount: entityRecords.length,
        incarnation,
        saveTimeMs: Date.now() - startTime,
      },
      'Saved entity records',
    );
  }

  public async deleteS3Object(entityName: string) {
    const savedEntityKey: ISavedEntityKey = {
      executionConfig: this.schemaManager.executionConfigName,
      entityName,
      isTestData: false,
    };

    const savedEntityKeyString = this.makeSavedEntityKeyString(savedEntityKey);
    const s3Params = this.makeS3Params(`${savedEntityKeyString}_${0}.zip`);
    await retry<DeleteObjectCommandOutput>({
      command: () =>
        this.clientManager.s3Support.deleteObject({
          ...s3Params,
        }),
    });
  }

  private async processZipFile(params: {
    records: any;
    savedEntityKeyString: string;
    executionConfig: string;
    seq: number;
  }) {
    const { records, savedEntityKeyString, seq } = params;
    let result: GetObjectCommandOutput;
    logger.debug(`Process zip start ${savedEntityKeyString} ${seq}`);
    const s3Params = this.makeS3Params(`${savedEntityKeyString}_${seq}.zip`);
    try {
      result = await retry<GetObjectCommandOutput>({
        command: () =>
          this.clientManager.s3Support.getObject({
            ...s3Params,
          }),
      });
    } catch (error) {
      reThrow({
        logger,
        error,
        message: 'Problem accessing S3',
        logObject: { s3Params },
      });
    }

    logger.debug(`Process zip read zip ${savedEntityKeyString} ${seq}`);
    const zip = new JSZip();
    await zip.loadAsync(await result.Body.transformToByteArray());
    logger.debug(`Process zip loaded zip ${savedEntityKeyString} ${seq}`);
    const recordContents = await zip.file(RECORDS_FILE).async('text');
    logger.debug(`Process zip loaded zip file ${savedEntityKeyString} ${seq}`);
    Object.assign(records, JSON.parse(recordContents));
    logger.debug(`Process zip end ${savedEntityKeyString} ${seq}`);
  }

  // public only for tests
  public async loadEntity(params: {
    savedEntityKey: ISavedEntityKey;
    expectedIncarnation?: number;
    executionConfig?: string;
  }): Promise<{ [itemId: string]: any } & any> {
    const {
      savedEntityKey,
      expectedIncarnation,
      executionConfig = this.clientManager.schemaManager.executionConfigName,
    } = params;
    const savedEntityKeyString = this.makeSavedEntityKeyString(savedEntityKey);

    const startTime = Date.now();

    const s3ControlParams = {
      ...this.makeS3Params(`${savedEntityKeyString}.json`),
    };
    let controlResult: GetObjectCommandOutput;
    try {
      controlResult = await retry<GetObjectCommandOutput>({
        command: () => this.clientManager.s3Support.getObject(s3ControlParams),
      });
    } catch (error) {
      reThrow({
        logger,
        error,
        message: 'Problem accessing S3',
        logObject: { s3ControlParams },
      });
    }

    const controlRecord: IControl = JSON.parse(
      await controlResult.Body.transformToString(),
    );
    if (
      expectedIncarnation !== undefined &&
      controlRecord.incarnation < expectedIncarnation &&
      controlRecord.incarnation !== -1
    ) {
      const error = new Error(
        `Expected incarnation: ${expectedIncarnation} (or higher), got ${controlRecord.incarnation}`,
      ) as unknown as ILoadEntityError;
      error.incorrectExpectedIncarnation = true;
      error.expectedIncarnation = expectedIncarnation;
      error.foundIncarnation = controlRecord.incarnation;
      // eslint-disable-next-line @typescript-eslint/only-throw-error
      throw error;
    }

    let seq = 0;
    const records = {};
    const promises = [];
    while (seq < controlRecord.numberOfSegments) {
      promises.push(
        this.processZipFile({
          savedEntityKeyString,
          seq,
          records,
          executionConfig,
        }),
      );
      seq++;
    }

    await Promise.all(promises);

    logger.info(
      {
        executionConfig,
        entityName: savedEntityKey.entityName,
        recordCount: Object.keys(records).length,
        incarnation: controlRecord?.incarnation,
        elapsedTimeMs: Date.now() - startTime,
      },
      'Loaded entity records',
    );
    return records;
  }

  public async getFromMemory(params: {
    id: string;
    entity: IEntityType;
    entityIncarnationsHolder: IEntityIncarnationsHolder;
    requestId: string;
    executionConfig: string;
    useTestData: UseTestData;
    testContext?: ITestContext;
  }) {
    const {
      id,
      entity,
      testContext,
      requestId,
      entityIncarnationsHolder,
      useTestData,
      executionConfig,
    } = params;

    const { savedEntityKey } = this.getCodeTypeInfo({
      id,
      entity,
      executionConfig,
      useTestData,
    });
    const savedEntityKeyString = this.makeSavedEntityKeyString(savedEntityKey);

    if (this.entityMapPromise[savedEntityKeyString]) {
      await this.entityMapPromise[savedEntityKeyString];
      // If we get a hit right after the load, don't go through the incarnation
      // checking for performance sake. There can be a lot of requests in some
      // situations
      if (this.entityMap[savedEntityKeyString]) {
        if (this.entityMap[savedEntityKeyString][id]) {
          return this.entityMap[savedEntityKeyString][id];
        }
      } else {
        // FIXME - we don't know how the entityMap could be null at savedEntityKeyString, but it
        // is pretty regularly in the build tests which shows up in pipeline.graphQLQuery.test Query placement list - WORM-869
        // so just add this check and a warning
        logger.warn(
          `Inexplicable missing entry from entityMap: ${savedEntityKeyString}`,
        );
      }
      // Fall through to the normal checking
    }

    // Block everything else until we are done
    let promiseResolver;
    this.entityMapPromise[savedEntityKeyString] = new Promise((resolve) => {
      promiseResolver = resolve;
    });

    let incarnation;

    let { entityIncarnations } = entityIncarnationsHolder;

    // We might not get a consistent incarnation because the stored data might differ
    // from the record in the _EntityIncarnations table. Keep trying until we do
    while (true) {
      try {
        if (!entityIncarnations) {
          if (testContext?.ignoreIncarnationInDb) {
            logger.info(
              'Ignoring _EntityIncarnations record because ignoreIncarnationInDb is set',
            );
          } else {
            logger.debug('Reading entity incarnations');
            entityIncarnations = await this.getOrCreateEntityIncarnations({
              savedEntityKey,
              requestId,
              executionConfig,
            });
          }
          entityIncarnationsHolder.entityIncarnations = entityIncarnations;
        }

        incarnation = entityIncarnations.incarnations.find(
          (i) => i.savedEntityKey === savedEntityKeyString,
        )?.incarnation;
        if (incarnation === null || incarnation === undefined) {
          incarnation = -2; // force it to not match anything - different than -1 (INITIAL_INCARNATION)
        }

        if (
          incarnation !== this.entityCurrentIncarnation[savedEntityKeyString]
        ) {
          this.entityMap[savedEntityKeyString] = null;
          logger.info(
            {
              savedEntityKey,
              incarnation,
              oldIncarnation:
                this.entityCurrentIncarnation[savedEntityKeyString],
            },
            'Incarnation change',
          );
          this.entityCurrentIncarnation[savedEntityKeyString] = incarnation;
        }

        if (!this.entityMap[savedEntityKeyString]) {
          const records = await this.loadEntity({
            savedEntityKey,
            executionConfig,
            expectedIncarnation: incarnation,
          });
          this.entityMap[savedEntityKeyString] = records;
        }
        break;
      } catch (error) {
        const loadEntityError = error as ILoadEntityError;
        if (
          !loadEntityError.expectedIncarnation &&
          (getOriginalError(loadEntityError) as any)?.Code !== 'NoSuchKey'
        ) {
          // Let the waiters go
          promiseResolver();
          reThrow({
            logger,
            message: 'Unexpected error during loadEntity',
            logObject: { savedEntityKey },
            error,
          });
        }
        logger.warn(
          {
            savedEntityKey,
            executionConfig,
            foundIncarnation: loadEntityError.foundIncarnation,
            expectedIncarnation: loadEntityError.expectedIncarnation,
            s3Code: (getOriginalError(loadEntityError) as any)?.Code,
            error: getErrorString(error),
          },
          'getFromMemory problem - retrying',
        );

        await this.saveEntity({
          savedEntityKey,
          incarnation: (
            await this.getOrCreateEntityIncarnation({
              savedEntityKey,
              executionConfig: executionConfig,
            })
          ).incarnation,
        });
        entityIncarnations = null;
      }
    }
    promiseResolver();
    this.entityMapPromise[savedEntityKeyString] = undefined;

    return this.entityMap[savedEntityKeyString][id];
  }
}
