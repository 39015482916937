import React, { useContext, useEffect, useRef, useState } from 'react';

export const Stasis = React.createContext([]);
Stasis.displayName = 'Stasis';

function StasisNode(props) {
  const [stasis, setStasis] = useStasis(false);

  return (
    <Stasis.Provider value={[stasis, setStasis]}>
      {props.children}
    </Stasis.Provider>
  );
}

function useStasis(inStasis) {
  const [, setStasis] = useContext(Stasis);
  useEffect(() => {
    if (setStasis) {
      setStasis(true);
    }
    return () => {
      if (setStasis) {
        setStasis(false);
      }
    };
  }, [setStasis]);
  return useState(inStasis);
}

export function withStasis(Component) {
  return (props) => {
    return (
      <StasisNode>
        <Component {...props} />
      </StasisNode>
    );
  };
}

export function StasisFreezer({ children }) {
  const [stasis] = useContext(Stasis);

  const previousRef = useRef();
  const previous =
    previousRef.current === undefined ? null : previousRef.current;
  const isEqual = stasis;

  useEffect(() => {
    if (!isEqual) {
      previousRef.current = children;
    }
  });

  return isEqual ? previous : children;
}
