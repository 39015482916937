import { Tooltip } from '@mui/material';

import { getIcon } from '../../../util/clientUtilities';
import { IWidgetProps, UniversalWidgetProps } from '../types';

import { iconPropertyInfo } from './IconTypeInfo';

// FIXME - this needs to be handled better - but will go away with the typescript change
type Props = IWidgetProps<typeof iconPropertyInfo> & UniversalWidgetProps;

const IconWidget: React.FC<React.PropsWithChildren<Props>> = (props) => {
  if (!props.icon) {
    throw new Error(
      `No icon property found for ${props.id} in ${props.component.treeId}`,
    );
  }
  const IconComponent = getIcon(
    props.icon,
    props.component.configName,
    props.elementAttributes,
  );
  return (
    <Tooltip title={props.tooltip}>
      <span>
        <IconComponent {...props.elementAttributes} />
      </span>
    </Tooltip>
  );
};

export default IconWidget;
