import { Route, Switch, withRouter } from 'react-router';
import { Redirect } from 'react-router-dom';
import { compose } from 'redux';

import { withClient } from '../../wrappers/ClientContext';

import EntityList from './EntityList';
import EntityViewer from './EntityViewer';

function DataRoute() {
  return (
    <Switch>
      <Route
        path="/data/:entityName/:id"
        render={() => (
          // @ts-ignore
          <EntityViewer />
        )}
      />
      <Route
        path="/data/:entityName"
        render={() => (
          // @ts-ignore
          <EntityList />
        )}
      />
      <Route>
        <Redirect to="/" />
      </Route>
    </Switch>
  );
}

export default compose(withRouter, withClient)(DataRoute);
