import { useContext } from 'react';
import {
  AccessType,
  PermissionType,
  SYSADMINAPP,
} from 'universal/permissionManager';

import { ClientContext } from '../wrappers/ClientContext';

export function useCheckPermission(params: {
  type: PermissionType;
  name: string;
  accessType: AccessType;
}): boolean {
  const { type, name, accessType } = params;
  const { clientManager, userInfo } = useContext(ClientContext);
  const { permissionManager } = clientManager;

  const allowed = !!(
    permissionManager.getAccess(userInfo.permissions, type, name) & accessType
  );
  return allowed;
}

export function useCheckPermissionSysAdmin() {
  return useCheckPermission({
    name: SYSADMINAPP,
    type: PermissionType.Programmatic,
    accessType: AccessType.Read,
  });
}
