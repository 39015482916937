import {
  Button,
  Dialog,
  DialogTitle,
  Icon,
  IconButton,
  LinearProgress,
  Menu,
  MenuItem,
  Tooltip,
} from '@mui/material';
import React, { useContext, useState } from 'react';
import { Loggers, getLogger } from 'universal/loggerSupport';

import { IAttachmentData } from '../../../util/presentationSupport';
import { ClientContext } from '../../../wrappers/ClientContext';
import { makeThemedStyles } from '../../../wrappers/ThemeWrapper';
import { IWidgetProps, UniversalWidgetProps } from '../types';

import { attachmentButtonPropertyInfo } from './AttachmentButtonTypeInfo';

const logger = getLogger({ name: Loggers.CLIENT });

// FIXME - this needs to be handled better - but will go away with the typescript change
type Props = IWidgetProps<typeof attachmentButtonPropertyInfo> &
  UniversalWidgetProps;

// FIXME - duplicates button - will be fixed with the MUI 5 work
const useStyles = makeThemedStyles<Props>((theme) => ({
  button: (props: any) => ({
    // padding: props.padding,
    height: props.height,
    width: props.width,
    color: props.color,
    minWidth: '0px',
    margin: props.margin,
    backgroundColor: props.backgroundColor,
  }),
  fabProgress: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    zIndex: 1,
  },
  linearProgress: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    zIndex: 1,
    width: '100%',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '10%',
    outline: 'none',
  },
  modalPaper: {
    padding: `${theme.spacing(1)}px`,
  },
  modalTypography: {
    whiteSpace: 'pre-wrap',
    textAlign: 'center',
  },
  modalButtons: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
}));

const AttachmentButtonWidget: React.FC<React.PropsWithChildren<Props>> = (
  props,
) => {
  const { presentationSupport } = useContext(ClientContext);
  const [loading, setLoading] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const [viewOpen, setViewOpen] = useState(false);
  const [attachmentData, setAttachmentData] = useState<IAttachmentData>(null);

  const inputId = (props as any).id + '-upload-input';

  const { attachmentContainer, pathPrefix } = props;

  if (!attachmentContainer) {
    throw new Error(
      `AttachmentButton - ${
        (props as any).id
      } - missing attachmentContainer, property value is: ${
        props.component.properties.attachmentContainer
      }`,
    );
  }

  function handleViewClose() {
    setViewOpen(false);
  }

  function handleOpenMenu(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  function handleUpload(event) {
    handleClose();
    const file = event.target.files[0];
    // Reset this so that the onChange event on the input element will always happen
    (document.getElementById(inputId) as any).value = '';
    // Canceled by user
    if (!file) {
      return;
    }

    async function handleFileLoad(loadEvent) {
      setLoading(true);
      await presentationSupport.clientPutAttachment({
        fileName: file.name,
        type: file.type,
        pathPrefix,
        path: attachmentContainer.id,
        arrayBuffer: loadEvent.target.result,
      });
      setLoading(false);
      props.updateFromWidget({
        data: { ...attachmentContainer, hasAttachment: true },
      });
    }

    const reader = new FileReader();
    reader.onload = handleFileLoad;
    reader.readAsArrayBuffer(file);
  }

  function makeHrefFromAttachmentData(ad: IAttachmentData): string {
    if (!ad) {
      return null;
    }
    return `data:${ad.type};base64, ${ad.rawBytes}`;
  }

  async function getAttachmentData(): Promise<IAttachmentData> {
    handleClose();
    setLoading(true);
    const localAttachmentData = (await presentationSupport.clientGetAttachment(
      pathPrefix,
      attachmentContainer.id,
    )) as IAttachmentData;
    setLoading(false);
    if (localAttachmentData.error) {
      logger.warn(
        `Attempting to download/view and attachment not found (check that hasAttachment is in sync): ${pathPrefix}/${attachmentContainer.id}`,
      );
      return null;
    }
    return localAttachmentData;
  }

  async function handleDownload() {
    const ad = await getAttachmentData();
    if (!ad) {
      return;
    }
    const a = document.createElement('a');
    a.href = makeHrefFromAttachmentData(ad);
    a.type = ad.type;
    a.download = ad.fileName;
    document.body.appendChild(a);
    a.click();
  }

  async function handleView() {
    const ad = await getAttachmentData();
    if (!ad) {
      return;
    }
    setAttachmentData(ad);
    setViewOpen(true);
  }

  async function handleDelete() {
    handleClose();
    setLoading(true);
    await presentationSupport.clientDeleteAttachment(
      pathPrefix,
      attachmentContainer.id,
    );
    props.updateFromWidget({
      data: { ...attachmentContainer, hasAttachment: false },
    });
    setAttachmentData(null);
    setLoading(false);
  }

  const { elementAttributes, acceptTypes, icon, tooltip } = props;
  const label = props.label;
  const classes = useStyles(props);

  const menuId = elementAttributes.id;

  const { hasAttachment } = attachmentContainer;

  const buttonAttributes: any = {
    ...elementAttributes,
    className: classes.button,
  };

  const inputAttributes = {
    // https://www.a11yproject.com/posts/how-to-hide-content/
    style: {
      clip: 'rect(0 0 0 0)',
      'clip-path': 'inset(50%)',
      height: '1px',
      overflow: 'hidden',
      'white-space': 'nowrap',
      width: '1px',
    },
    id: inputId,
    type: 'file',
    accept: acceptTypes,
    onChange: handleUpload,
  };

  let embeddedInput;
  if (hasAttachment) {
    buttonAttributes.onClick = handleOpenMenu;
    buttonAttributes['aria-controls'] = menuId;
    buttonAttributes['aria-haspopup'] = true;
  } else {
    buttonAttributes.component = 'label';
    embeddedInput = <input {...inputAttributes} />;
  }

  let button;
  if (icon) {
    button = (
      <IconButton {...buttonAttributes} size="large">
        {embeddedInput}
        <Icon>{icon}</Icon>
      </IconButton>
    );
  } else {
    button = (
      <Button {...buttonAttributes}>
        {label}
        {embeddedInput}
      </Button>
    );
  }

  if (tooltip) {
    button = <Tooltip title={tooltip}>{button}</Tooltip>;
  }

  if (hasAttachment) {
    return (
      <React.Fragment>
        {button}
        <Menu
          id={menuId}
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <label htmlFor={inputId}>
            <MenuItem>Upload</MenuItem>
          </label>
          <MenuItem onClick={handleView}>View</MenuItem>
          <MenuItem onClick={handleDownload}>Download</MenuItem>
          <MenuItem onClick={handleDelete}>Delete</MenuItem>
        </Menu>
        <input {...inputAttributes} />
        <Dialog open={viewOpen} onClose={handleViewClose}>
          <img
            src={makeHrefFromAttachmentData(attachmentData)}
            alt={attachmentData?.fileName}
          />
          <DialogTitle>{attachmentData?.fileName}</DialogTitle>
        </Dialog>
        {loading && <LinearProgress />}
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      {button}
      {loading && <LinearProgress />}
    </React.Fragment>
  );
};

export default AttachmentButtonWidget;
