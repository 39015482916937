import YAML from 'js-yaml';
import _ from 'lodash';
import safeJsonStringify from 'safe-json-stringify';

import { ClientManager } from '../clientManager';
import { CONFIGURATIONS_DIR } from '../common/commonConstants';
import { IFile, putFiles as putFilesInGithub } from '../common/github';
import { getErrorString } from '../errors/errorString';
import { Loggers, getLogger } from '../loggerSupport';
import {
  CONFIG_NAME,
  CONFIG_SEPARATOR,
  CONFIG_TYPE,
  IConfigItem,
  KEY_FIELD,
  RECORD_TYPE,
  TEXT_DATA_ATTRIBUTE,
  TYPE_NAME,
} from '../metadataSupportConstants';
import { removeTypeNames } from '../pipeline/graphQLSupport';
import { removeNullOrUndefined } from '../utilityFunctions';

import {
  configTypeEntityTypes,
  getDifferences,
  getEntityTypeForRecordType,
  getFullConfigRepoForBranch,
  parseConfigPath,
} from './loadstore';

const logger = getLogger({ name: Loggers.LOAD_STORE });

export async function storeAllConfigurations(params: {
  githubToken: string;
  branchName: string;
  commitMessage: string;
  clientManager: ClientManager;
}) {
  const { githubToken, branchName, commitMessage, clientManager } = params;

  logger.info('storing all configurations');

  if (typeof branchName !== 'string') {
    throw new Error(`Invalid branch name: ${safeJsonStringify(branchName)}`);
  }

  logger.info('getting items from db');

  const configItems = await getAllConfigsFromDatabase({
    clientManager,
  });

  logger.info('converting items to files');

  const configFiles = configItems
    .map((configItem) => convertConfigItemToFile({ configItem, clientManager }))
    .filter((item) => item !== undefined);

  logger.info(`putting ${configFiles.length} files in github`);

  return putFilesInGithub({
    githubToken,
    commitMessage,
    branchName,
    files: configFiles,
  });
}

export async function storeConfiguration(params: {
  githubToken: string;
  branchName: string;
  path: string;
  commitMessage;
  userName?: string;
  repoName?: string;
  clientManager: ClientManager;
}) {
  const {
    githubToken,
    branchName,
    path,
    commitMessage,
    userName,
    repoName,
    clientManager,
  } = params;

  logger.info('storing configuration', path);

  if (typeof branchName !== 'string') {
    throw new Error(`Invalid branch name: ${safeJsonStringify(branchName)}`);
  }

  const { configName, configType } = parseConfigPath(path);

  const fullRepo = await getFullConfigRepoForBranch({
    githubToken,
    branchName,
    repoName,
    clientManager,
  });

  logger.info('getting items from db');

  const configItems = await getConfigFromDatabase({
    configName,
    configType,
    clientManager,
  });

  logger.info(`converting ${configItems.length} items to files`);

  const configFiles = configItems.map((configItem) => {
    return convertConfigItemToFile({ configItem, clientManager });
  });

  logger.info("comparing sha's");

  const { databaseOnly, changed } = await getDifferences({
    githubToken,
    branchName,
    repoName,
    path,
    fullRepo,
    clientManager,
  });

  if (changed.length !== 0) {
    logger.info(
      'The following files will be changed:\n',
      changed.map((file) => file.path).join('\n'),
    );
  }

  const fileInfosToWrite = databaseOnly.concat(changed);

  const filesToWrite = configFiles.filter((file) =>
    fileInfosToWrite.find((fileInfo) => fileInfo.path === file.path),
  );

  logger.info(`putting ${filesToWrite.length} files in github`);

  await putFilesInGithub({
    githubToken,
    commitMessage,
    branchName,
    files: filesToWrite,
    userName,
    repoName,
  });

  logger.info('configuration store complete', path);

  return;
}

export async function storeConfigItems(params: {
  githubToken: string;
  branchName: string;
  commitMessage: string;
  configItems: IConfigItem[];
  userName?: string;
  repoName?: string;
  clientManager: ClientManager;
}) {
  const {
    githubToken,
    branchName,
    commitMessage,
    userName,
    repoName,
    clientManager,
  } = params;

  // Don't modify the input
  const configItems = _.cloneDeep(params.configItems);

  logger.info(
    'Storing items in github',
    configItems.map((rec) => rec.id),
  );

  if (typeof branchName !== 'string') {
    throw new Error(`Invalid branch name: ${safeJsonStringify(branchName)}`);
  }

  const configFiles = configItems.map((item) =>
    convertConfigItemToFile({ configItem: item, clientManager }),
  );

  await putFilesInGithub({
    githubToken,
    commitMessage,
    branchName,
    files: configFiles,
    userName,
    repoName,
  });

  logger.info('Store complete');

  return;
}

export async function getConfigFromDatabase(params: {
  configName: string;
  configType: string;
  clientManager: ClientManager;
}): Promise<IConfigItem[]> {
  const { configName, configType, clientManager } = params;

  const entityTypes = configTypeEntityTypes[configType];

  let configItems = [];

  for (const entityType of entityTypes) {
    const entity = getEntityTypeForRecordType({
      clientManager,
      recordType: entityType,
      noThrow: true,
    });
    if (!entity) {
      continue;
    }
    const configItemsOfRecordType = (
      await clientManager.pipelineManager.listRecords({
        configName: entity.configName,
        entityName: entity.id,
        filter: `item.configName === '${configName}'`,
        noCache: true,
      })
    ).items;
    configItems = configItems.concat(configItemsOfRecordType);
  }

  return configItems;
}

export async function getAllConfigsFromDatabase(params: {
  clientManager: ClientManager;
}): Promise<IConfigItem[]> {
  const { clientManager } = params;

  const recordTypes = [];

  Object.keys(configTypeEntityTypes).forEach((configType) => {
    configTypeEntityTypes[configType].forEach((rt) => recordTypes.push(rt));
  });

  let configItems = [];

  for (const recordType of recordTypes) {
    const entity = getEntityTypeForRecordType({
      clientManager,
      recordType,
      noThrow: true,
    });
    if (!entity) {
      continue;
    }
    const configItemsOfRecordType = (
      await clientManager.pipelineManager.listRecords({
        configName: entity.configName,
        entityName: entity.id,
      })
    ).items;
    configItems = configItems.concat(configItemsOfRecordType);
  }

  return configItems;
}

function convertDatabaseIdToFileId(dbId) {
  const splitId = dbId.includes(CONFIG_SEPARATOR)
    ? dbId.split(CONFIG_SEPARATOR)
    : dbId.split('-');
  const fileId = splitId[1];

  if (!fileId) {
    throw Error(
      `Expected ${KEY_FIELD} in config item to be in format ` +
        `${RECORD_TYPE}-${KEY_FIELD} for ${KEY_FIELD}: ${safeJsonStringify(
          dbId,
        )}`,
    );
  }

  return fileId;
}

export function convertConfigItemToFile({
  configItem,
  clientManager,
}: {
  configItem: IConfigItem;
  clientManager: ClientManager;
}): IFile {
  const path = configFieldsToPath(configItem);

  // Fix line endings in case files were edited manually
  let configItemString = safeJsonStringify(configItem);
  configItemString = configItemString.replace(/\\r\\n/g, '\\n');
  configItem = JSON.parse(configItemString);

  let entity;
  if (configItem[RECORD_TYPE] && configItem[CONFIG_NAME]) {
    entity = clientManager.schemaManager.getEntityType({
      name: configItem[RECORD_TYPE],
      configName: configItem[CONFIG_NAME],
    });
  }

  removeTypeNames(configItem);
  removeNullOrUndefined(configItem);

  delete configItem[KEY_FIELD];
  delete configItem[CONFIG_NAME];
  delete configItem[CONFIG_TYPE];
  // Don't care about any of the system fields
  Object.keys(configItem)
    .filter((k) => k.startsWith('_'))
    .forEach((k) => delete configItem[k]);

  let pathToUse;
  let contents: string;

  if (entity?.fileExtension) {
    pathToUse = `${path}.${entity.fileExtension}`;
    contents = configItem[TEXT_DATA_ATTRIBUTE];
  } else {
    pathToUse = `${path}.yml`;
    try {
      contents = YAML.dump(configItem, {
        sortKeys: true,
        flowLevel: -1,
        lineWidth: 200,
      });
    } catch (error) {
      throw new Error(`Problem dumping to YAML: ${getErrorString(error)}`);
    }
  }

  return {
    contents,
    path: pathToUse,
  };
}

function configFieldsToPath(configFields): string {
  const {
    [CONFIG_NAME]: configName,
    [TYPE_NAME]: entityType,
    [KEY_FIELD]: id,
  } = configFields;

  const configType = getConfigTypeFromEntityType(entityType);

  let path = `${CONFIGURATIONS_DIR}/${configName}/${configType}/${entityType}`;

  if (id) {
    const fileId = convertDatabaseIdToFileId(id);

    path += `/${fileId}`;
  }

  return path;
}

function getConfigTypeFromEntityType(entityType: string): string {
  let thisConfigType;

  for (const configType in configTypeEntityTypes) {
    const recordTypes = configTypeEntityTypes[configType];

    if (recordTypes.includes(entityType)) {
      thisConfigType = configType;
      break;
    }
  }

  if (!thisConfigType) {
    throw new Error(`configType not found for recordType ${entityType}.`);
  }

  return thisConfigType;
}
