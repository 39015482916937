import { createBrowserHistory } from 'history';
import { SnackbarProvider } from 'notistack';
import { Provider } from 'react-redux';
import { Route, Switch } from 'react-router';
import { BrowserRouter } from 'react-router-dom';
import '../configImports';
import './wdyr';

import ReactDOM from 'react-dom';
import { store } from './store/store';
import TestContainer from './util/TestContainer';
import { Authenticator } from './wrappers/Authenticator';
import Client from './wrappers/Client';
import ErrorBoundary from './wrappers/ErrorBoundary';
import ThemeWrapper from './wrappers/ThemeWrapper';

const history = createBrowserHistory();

const rootDiv =
  document.getElementById('root') || document.createElement('div');

const rootStyle = {
  height: '100%',
  overflow: 'hidden',
  userSelect: 'none',
};

Object.assign(rootDiv.style, rootStyle);

// we can't use createRoot and render because we get flickers sometimes in
// some modals when we do. this forces the app to work like react 17 even
// though 18 is installed (which is whats needed for some react testing stuff)

// noinspection JSDeprecatedSymbols
ReactDOM.render(
  <ThemeWrapper>
    <Provider store={store}>
      <ErrorBoundary>
        <SnackbarProvider>
          <Authenticator history={history}>
            <BrowserRouter>
              <Client />
              <Switch>
                <Route path={'/test'} render={() => <TestContainer />} />
              </Switch>
            </BrowserRouter>
          </Authenticator>
        </SnackbarProvider>
      </ErrorBoundary>
    </Provider>
  </ThemeWrapper>,
  rootDiv,
);
