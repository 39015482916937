import { Paper, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import classNames from 'classnames';
import produce from 'immer';
import _ from 'lodash';
import { withSnackbar } from 'notistack';
import { Resizable } from 're-resizable';
import React, { PureComponent } from 'react';
import isEqual from 'react-fast-compare';
import { HotKeys } from 'react-hotkeys';
import { withRouter } from 'react-router';
import { compose } from 'redux';
import { exists } from 'universal/common/commonUtilities';
import {
  deleteAppDefConfigItemsRemote,
  getConfigItemsForType,
  updateAppDefConfigItemsRemote,
  updateConfigItemsToStackInfo,
} from 'universal/loadStore/loadstore';
import { MetadataSupport } from 'universal/metadataSupport';
import {
  APP_DEF_PIPELINE,
  APP_DEF_WIDGET_TREE,
  IHasConfigName,
} from 'universal/metadataSupportConstants';
import { stagePropertyConfiguration } from 'universal/pipeline/stageTypes/stageInfos';
import {
  IPropertyConfiguration,
  PathArray,
  PropertyConfigurationHandler,
} from 'universal/propertySupport';
import { StackInfoKeys } from 'universal/stackInfo';

import Loading from '../../components/atoms/Loading';
import PropertiesEditor from '../../components/molecules/PropertiesEditor';
import WidgetMap from '../../components/molecules/WidgetMap';
import {
  ConfigurationTypeName,
  IPassThroughProps,
  IWidgetTree,
  IWidgetTrees,
  configurationTypeCategories,
  widgetEditorFields,
} from '../../components/widgets/types';
import {
  canBeChild,
  configurationTypesDetails,
  widgetPropertyConfiguration,
} from '../../components/widgets/widgetTypes';
import { escapeUrlPath } from '../../util/clientUtilities';
import {
  clientToDb,
  dbToClient,
  fromClient,
} from '../../util/transformWidgetTree';
import { IClientContext, withClient } from '../../wrappers/ClientContext';

import EditorControls from './EditorControls';
import Preview from './Preview';

interface IProps extends IClientContext {
  classes;
  enqueueSnackbar;
  initialPassThroughProps: IPassThroughProps;
}

interface IState {
  pageName: string;
  localEdit;
  editingNodePath: PathArray;
  saving: boolean;
  loading: boolean;
  showPreview: boolean;
  clipBoard;
  error?;
  errorMessage?: string;
  trees: {
    [TypeToEdit.widgetTree]?: IWidgetTrees;
    [TypeToEdit.pipeline]?: { [pipeline: string]: IHasConfigName };
  };
  typeToEdit: TypeToEdit;
}

export enum TypeToEdit {
  widgetTree = 'widgetTree',
  pipeline = 'pipeline',
}

const localStorageAddresses = {
  editingPageName: 'editingPageName',
  editingPagePath: 'editingPagePath',
  showPreview: 'showPreview',
  editingType: 'editingType',
  editingPipelineName: 'editingPipelineName',
  editingPipelinePath: 'editingPipelinePath',
};

const storage = localStorageAddresses;

class EditTree extends PureComponent<IProps, IState> {
  public handlers: { SAVE: (event: any) => void };
  public treesHandler: {
    [tree in keyof typeof TypeToEdit]: {
      newTreeTemplate: {
        id: string;
        [other: string]: any;
      };
      recordType: string;
      loadTrees: () => Promise<void>;
    };
  };

  constructor(props: IProps) {
    super(props);

    const { clientManager, loadWidgetTrees, widgetTrees } = props;
    const { executionConfigName } = clientManager.schemaManager;

    this.state = {
      pageName: null,
      localEdit: null,
      editingNodePath: [],
      saving: false,
      loading: false,
      showPreview: false,
      clipBoard: null,
      typeToEdit:
        TypeToEdit[localStorage.getItem(storage.editingType)] ||
        TypeToEdit.widgetTree,
      trees: {
        [TypeToEdit.widgetTree]: widgetTrees,
      },
    };
    this.toggleShowPreview = this.toggleShowPreview.bind(this);
    this.setTree = this.setTree.bind(this);
    this.resetPaths = this.resetPaths.bind(this);
    this.handleSaveTree = this.handleSaveTree.bind(this);
    this.handleSelectTree = this.handleSelectTree.bind(this);
    this.handleRevertTree = this.handleRevertTree.bind(this);
    this.handleDeleteTree = this.handleDeleteTree.bind(this);
    this.handleSetEditingNodePath = this.handleSetEditingNodePath.bind(this);
    this.handleCreateNewTree = this.handleCreateNewTree.bind(this);
    this.handleAddNodeLocal = this.handleAddNodeLocal.bind(this);
    this.handleAddNode = this.handleAddNode.bind(this);
    this.handleUpdateField = this.handleUpdateField.bind(this);
    this.handleDeleteNode = this.handleDeleteNode.bind(this);
    this.canSaveTree = this.canSaveTree.bind(this);
    this.handleMoveNode = this.handleMoveNode.bind(this);
    this.handleCopyNode = this.handleCopyNode.bind(this);
    this.handlePasteNode = this.handlePasteNode.bind(this);
    this.handleSaveAllTrees = this.handleSaveAllTrees.bind(this);
    this.canEditNode = this.canEditNode.bind(this);
    this.getParentType = this.getParentType.bind(this);
    this.handleSelectTypeToEdit = this.handleSelectTypeToEdit.bind(this);
    this.getNode = this.getNode.bind(this);
    this.loadPipelines = this.loadPipelines.bind(this);

    this.treesHandler = {
      [TypeToEdit.widgetTree]: {
        newTreeTemplate: {
          id: MetadataSupport.getQualifiedName(
            'NewWidgetTree',
            executionConfigName,
          ),
          widgets: [],
          actions: [],
        },
        recordType: APP_DEF_WIDGET_TREE,
        loadTrees: loadWidgetTrees,
      },
      [TypeToEdit.pipeline]: {
        newTreeTemplate: {
          id: MetadataSupport.getQualifiedName(
            'NewPipeline',
            executionConfigName,
          ),
          stages: [],
        },
        recordType: APP_DEF_PIPELINE,
        loadTrees: this.loadPipelines,
      },
    };

    this.handlers = {
      SAVE: (event) => {
        event.preventDefault();
        void this.handleSaveTree();
      },
    };
  }

  public async loadPipelines() {
    const { clientManager, widgetTrees } = this.props;

    await clientManager.stackInfo.refreshStackInfo(true);
    const appDefs = clientManager.stackInfo.getObject(StackInfoKeys.APP_DEFS);
    const pipelineArray = getConfigItemsForType(
      appDefs,
      APP_DEF_PIPELINE,
    ) as IWidgetTree[];
    const pipelines = {};
    pipelineArray.forEach((pipeline) => (pipelines[pipeline.id] = pipeline));
    this.setState({
      trees: {
        [TypeToEdit.widgetTree]: widgetTrees,
        [TypeToEdit.pipeline]: pipelines,
      },
    });
  }

  public componentDidMount() {
    this.loadFromLocalStorage();
  }

  public componentDidCatch() {
    console.log('Caught editor error - clearing cache, please reload page');
    this.clearLocalStorage();
  }

  public clearLocalStorage() {
    Object.values(storage).forEach((address) =>
      localStorage.removeItem(address),
    );
  }

  public loadFromLocalStorage() {
    const typeToEdit =
      this.state.typeToEdit ||
      (localStorage.getItem(storage.editingType) as TypeToEdit);

    if (!this.state.typeToEdit) {
      this.setState({ typeToEdit });
    }

    if (typeToEdit === TypeToEdit.widgetTree) {
      const pageName = localStorage.getItem(storage.editingPageName);
      this.setTree(pageName);

      const pagePath = JSON.parse(
        localStorage.getItem(storage.editingPagePath),
      );
      if (pagePath) {
        this.handleSetEditingNodePath(pagePath);
      }

      const showPreview = localStorage.getItem(storage.showPreview);
      if (showPreview) {
        this.setState({ showPreview: JSON.parse(showPreview) });
      }
      return;
    }

    if (typeToEdit !== TypeToEdit.pipeline) {
      throw new Error(`Unexpected typeToEdit: ${typeToEdit}`);
    }

    const handlePipeline = () => {
      const pipelineName = localStorage.getItem(storage.editingPipelineName);
      this.setTree(pipelineName);

      const pagePath = JSON.parse(
        localStorage.getItem(storage.editingPipelinePath),
      );
      if (pagePath) {
        this.handleSetEditingNodePath(pagePath);
      }
    };

    if (!this.treesLoaded(typeToEdit)) {
      void this.loadPipelines().then(() => handlePipeline());
      return;
    }
    handlePipeline();
  }

  public getNode(path, tree = this.state.localEdit) {
    if (!path.length) {
      return tree;
    }
    try {
      return _.get(tree, path.slice().reverse());
    } catch (error) {
      this.clearLocalStorage();
      throw new Error(
        'Unable to open editor at previous state - clearing cache, please reload page',
      );
    }
  }

  public toggleShowPreview() {
    const { showPreview } = this.state;
    this.setState({ showPreview: !showPreview });
    localStorage.setItem(storage.showPreview, JSON.stringify(!showPreview));
  }

  public setPreview(value) {
    this.setState({ showPreview: value });
    localStorage.setItem(storage.showPreview, JSON.stringify(value));
  }

  public setTree(pageName) {
    const { typeToEdit, trees } = this.state;
    if (pageName !== this.state.pageName) {
      this.resetPaths();
    }
    const treeToEdit = trees[typeToEdit][pageName];
    this.setState({ pageName, localEdit: _.cloneDeep(treeToEdit) });
  }

  public resetPaths() {
    this.setState({
      editingNodePath: [],
    });
  }

  public handleCreateNewTree() {
    const { typeToEdit } = this.state;
    this.setState({ localEdit: this.treesHandler[typeToEdit].newTreeTemplate });
  }

  private nodeTypeToPropertiesConfiguration(
    nodeType: string,
  ): IPropertyConfiguration {
    return nodeType === 'stages'
      ? stagePropertyConfiguration
      : configurationTypeCategories[nodeType]
        ? widgetPropertyConfiguration
        : undefined;
  }

  public handleSetEditingNodePath(path) {
    const { typeToEdit } = this.state;
    this.setState({ editingNodePath: path });
    const storagePath =
      typeToEdit === TypeToEdit.widgetTree
        ? storage.editingPagePath
        : storage.editingPipelinePath;
    localStorage.setItem(storagePath, JSON.stringify(path));
  }

  public handleRevertTree() {
    const { pageName } = this.state;
    this.handleSelectTree(pageName);
  }

  public handleSelectTree(pageName) {
    const { typeToEdit } = this.state;
    const { history } = this.props;
    this.setTree(pageName);

    if (typeToEdit === TypeToEdit.widgetTree) {
      this.setPreview(false);
    }

    const storagePath =
      typeToEdit === TypeToEdit.widgetTree
        ? storage.editingPageName
        : storage.editingPipelineName;

    localStorage.setItem(storagePath, pageName);
    history.push(`/editTrees/${escapeUrlPath(pageName)}`);
  }

  private handleAddNodeLocal(path, node, index?) {
    const { localEdit } = this.state;
    const { clientManager } = this.props;
    const newTree = _.cloneDeep(localEdit);
    const parent = this.getNode(path, newTree);
    const newIndex = index !== undefined ? index : parent.length;
    const newPath = [newIndex].concat(path);
    parent.splice(newIndex, 0, node);
    this.setState({ localEdit: dbToClient(newTree, clientManager) });
    if (this.canEditNode(newPath[1])) {
      this.handleSetEditingNodePath(newPath);
    }
  }

  public handleAddNode({
    path,
    type,
    propertyConfiguration,
  }: {
    path: PathArray;
    type: string | number;
    propertyConfiguration: IPropertyConfiguration;
  }) {
    const numberOfSiblings = this.getNode(path, this.state.localEdit).length;
    const propertyConfigurationHandler = new PropertyConfigurationHandler({
      propertyConfiguration,
      pathInWidgetTree: path,
    });

    const node = propertyConfigurationHandler.createNode({
      type,
    });

    const typeName = propertyConfigurationHandler.getTypeName();

    propertyConfigurationHandler.setName(`${typeName}_${numberOfSiblings + 1}`);

    this.handleAddNodeLocal(path, node);
  }

  public handleDeleteNode(path) {
    const { localEdit, editingNodePath } = this.state;
    const newTree = _.cloneDeep(localEdit);
    const pathToParent = path.slice();
    const childPath = pathToParent.splice(0, 1);
    const parentNode = this.getNode(pathToParent, newTree);
    parentNode.splice(childPath, 1);

    // prevents error if you are currently editing the deleted node or a child of it
    if (isEqual(editingNodePath, path)) {
      this.setState({ editingNodePath: editingNodePath.slice(1) });
    }
    this.setState({
      localEdit: newTree,
    });
  }

  public handleMoveNode(path, newIndex) {
    const { localEdit } = this.state;
    const newTree = _.cloneDeep(localEdit);
    const pathToParent = path.slice();
    const childPath = pathToParent.splice(0, 1);
    const parentNode = this.getNode(pathToParent, newTree);
    const movingNode = parentNode.splice(childPath, 1)[0];
    parentNode.splice(newIndex, 0, movingNode);

    this.setState({
      localEdit: newTree,
    });
  }

  // cuts off stuff only needed for rendering, like its id and parent tree
  public trimNode(node) {
    return _.omit(node, ['tree', 'widgetId']);
  }

  public handleCopyNode(path) {
    const { localEdit } = this.state;
    const { enqueueSnackbar } = this.props;
    const newTree = _.cloneDeep(localEdit);
    const pathToParent = path.slice();
    const childPath = pathToParent.splice(0, 1);
    const parentNode = this.getNode(pathToParent, newTree);
    const node = parentNode.splice(childPath, 1)[0];

    let nodeType;
    let compressed;
    const typeKey = path[1];
    if (
      configurationTypeCategories[typeKey] &&
      node.type &&
      configurationTypesDetails[node.type]
    ) {
      nodeType = node.type;
      const storableTree = fromClient({ [nodeType]: node });
      compressed = JSON.stringify(storableTree);
    } else if (widgetEditorFields[typeKey]) {
      nodeType = typeKey;
      compressed = JSON.stringify({ [nodeType]: node });
    }

    const name = node.name || node._name || 'anonymous';
    const singular =
      (widgetEditorFields[nodeType] && widgetEditorFields[nodeType].singular) ||
      nodeType;

    return {
      text: compressed,
      onSuccess: () =>
        enqueueSnackbar(`${singular} ${name} copied to clipboard`),
    };
  }

  public handlePasteNode(string, { path, index = 0 }) {
    const { enqueueSnackbar, clientManager } = this.props;

    let unCompressed;
    try {
      unCompressed = JSON.parse(string);
    } catch (error) {
      enqueueSnackbar('Invalid string provided', {
        variant: 'error',
      });
      return;
    }

    const pasteType = Object.keys(unCompressed)[0] as ConfigurationTypeName;
    const category = path[0];
    const parent = this.getNode(path.slice(1));
    const parentType = parent.type;

    const { localEdit } = this.state;
    const newTree = _.cloneDeep(localEdit);
    const parentNode = this.getNode(path, newTree);

    if (
      configurationTypeCategories[category] &&
      configurationTypesDetails[pasteType] &&
      canBeChild(parentType, category, pasteType)
    ) {
      parentNode.splice(
        index,
        0,
        dbToClient(unCompressed, clientManager)[pasteType],
      );
      this.setState({ localEdit: newTree });
    } else if (pasteType === category) {
      parentNode.splice(index, 0, unCompressed[pasteType]);
      this.setState({ localEdit: newTree });
    } else {
      const singular =
        (widgetEditorFields[pasteType] &&
          widgetEditorFields[pasteType].singular) ||
        pasteType;
      enqueueSnackbar(`Cannot paste ${singular} into ${category}`, {
        variant: 'warning',
      });
    }
  }

  public hasValidId() {
    const { localEdit } = this.state;
    return localEdit && !!localEdit.id;
  }

  public canSaveTree() {
    return this.hasValidId();
  }

  public handleUpdateField(value, path) {
    this.setState((prevState) => {
      const newTree = produce(prevState.localEdit, (draft) => {
        const pathToParent = path.slice();
        const [childPath] = pathToParent.splice(0, 1);
        const parentNode = this.getNode(pathToParent, draft);
        parentNode[childPath] = value;
      });
      return { localEdit: newTree };
    });
  }

  public async handleSaveAllTrees() {
    const { typeToEdit, trees } = this.state;

    for (const treeName of Object.keys(trees[typeToEdit])) {
      this.handleSelectTree(treeName);
      await this.handleSaveTree();
    }
  }

  public async handleSaveTree() {
    this.setState({ saving: true });
    const { clientManager } = this.props;
    const pipelineManager = clientManager.pipelineManager;
    const { localEdit, typeToEdit } = this.state;
    try {
      const dbTree = clientToDb(localEdit);
      if (typeToEdit === 'pipeline') {
        await pipelineManager.createNamedPipeline({
          name: dbTree.id,
          stages: dbTree.stages,
        });
        await updateConfigItemsToStackInfo({
          clientManager,
          stackInfoKey: StackInfoKeys.APP_DEFS,
          configItems: {
            [APP_DEF_PIPELINE]: [dbTree],
          },
        });
      } else {
        await updateAppDefConfigItemsRemote(clientManager, {
          [APP_DEF_WIDGET_TREE]: [dbTree],
        });
      }
      this.setState({ saving: false, error: false });
    } catch (error) {
      console.log('error saving page:', error);
      this.setState({
        error,
        errorMessage: 'error saving page',
        saving: false,
      });
    }
    void this.handleRefreshTree();
  }

  public async handleDeleteTree() {
    this.setState({ saving: true });
    const { pageName, typeToEdit, trees } = this.state;
    if (pageName === '' || !this.treesLoaded(typeToEdit)) {
      return;
    }
    try {
      const dbTree = clientToDb(trees[typeToEdit][pageName]);
      await deleteAppDefConfigItemsRemote(
        this.props.clientManager,
        this.treesHandler[typeToEdit].recordType,
        dbTree.id,
      );
      this.setState({ saving: false, error: false });
    } catch (error) {
      console.log('error saving page:', error);
      this.setState({
        error,
        errorMessage: 'error saving page',
        saving: false,
      });
    }

    this.setState({ pageName: '', localEdit: '' });
    this.resetPaths();
    void this.handleRefreshTree();
  }

  public async handleRefreshTree() {
    const {
      localEdit: { id },
      typeToEdit,
    } = this.state;
    await this.treesHandler[typeToEdit].loadTrees();
    if (id) {
      this.handleSelectTree(id);
    }
  }

  public canEditNode(nodeType) {
    return exists(this.nodeTypeToPropertiesConfiguration(nodeType));
  }

  private getParentType({ tree = this.state.localEdit, path }) {
    if (!configurationTypeCategories[path[1]]) {
      return;
    }
    const parentNode = this.getNode(path.slice(2), tree);
    return parentNode && parentNode.type;
  }

  public handleSelectTypeToEdit(typeToEdit: TypeToEdit) {
    localStorage.setItem(storage.editingType, TypeToEdit[typeToEdit]);
    this.resetPaths();
    this.setState(
      {
        localEdit: null,
        pageName: '',
        typeToEdit,
      },
      () => {
        this.loadFromLocalStorage();
      },
    );
  }

  public treesLoaded(typeToEdit: TypeToEdit): boolean {
    return !!this.state.trees[typeToEdit];
  }

  public render() {
    const { classes, initialPassThroughProps } = this.props;
    const {
      pageName,
      localEdit,
      editingNodePath,
      error,
      errorMessage,
      saving,
      showPreview,
      typeToEdit,
      trees,
    } = this.state;
    if (!this.treesLoaded(typeToEdit)) {
      return <Loading />;
    }

    const editingNode = !editingNodePath
      ? ''
      : this.getNode(editingNodePath, localEdit);

    const parentType = this.getParentType({
      path: editingNodePath,
      tree: localEdit,
    });

    const propertyConfiguration = this.nodeTypeToPropertiesConfiguration(
      editingNodePath[1] as string,
    );

    const editor = exists(propertyConfiguration) ? (
      <PropertiesEditor
        updateField={this.handleUpdateField}
        node={editingNode}
        propertyConfiguration={propertyConfiguration}
        pathInWidgetTree={editingNodePath}
        parentType={parentType}
      />
    ) : null;

    const canSave = this.canSaveTree();

    const errorReport = error ? (
      <div>
        <Typography variant="h5" style={{ color: 'red', textAlign: 'center' }}>
          {errorMessage}!
        </Typography>
      </div>
    ) : null;

    const treeName = localEdit ? localEdit.id : '';
    const disableTreeName = localEdit === null;

    const editorControls = (
      <EditorControls
        pageName={pageName}
        treeName={treeName}
        disableTreeName={disableTreeName}
        handleSelectTree={this.handleSelectTree}
        handleCreateNewTree={this.handleCreateNewTree}
        handleRevertTree={this.handleRevertTree}
        handleUpdateField={this.handleUpdateField}
        handleSaveTree={this.handleSaveTree}
        handleSaveAllTrees={this.handleSaveAllTrees}
        handleDeleteTree={this.handleDeleteTree}
        toggleShowPreview={this.toggleShowPreview}
        handleSelectTypeToEdit={this.handleSelectTypeToEdit}
        showPreview={showPreview}
        classes={classes}
        trees={trees[typeToEdit]}
        error={error}
        canSave={canSave}
        saving={saving}
        typeToEdit={typeToEdit}
      />
    );

    const treeMap = (
      <WidgetMap
        tree={localEdit}
        setEditingNodePath={this.handleSetEditingNodePath}
        addNode={this.handleAddNode}
        canEditNode={this.canEditNode}
        deleteNode={this.handleDeleteNode}
        editingNodePath={editingNodePath}
        copyNode={this.handleCopyNode}
        pasteNode={this.handlePasteNode}
        moveNode={this.handleMoveNode}
        getNode={this.getNode}
      />
    );

    return (
      <HotKeys handlers={this.handlers} className={classes.pageContainer}>
        <span className={classes.controls}>{editorControls}</span>
        {errorReport}
        {localEdit && (
          <React.Fragment>
            <Paper className={classNames(classes.tree, classes.innerPaper)}>
              {treeMap}
            </Paper>
            <Resizable
              enable={{
                left: true,
              }}
              className={classes.resizableRightSide}
            >
              {showPreview && typeToEdit === TypeToEdit.widgetTree && (
                <Paper
                  className={classNames(classes.preview, classes.innerPaper)}
                >
                  <div
                    style={{
                      height: '100%',
                      boxSizing: 'border-box',
                      padding: '5px',
                    }}
                  >
                    <Preview
                      initialPassThroughProps={initialPassThroughProps}
                      localEditTreeName={localEdit?.id}
                    />
                  </div>
                </Paper>
              )}
              <Paper
                className={classNames(classes.innerPaper, {
                  [classes.editorWithPreview]: showPreview,
                })}
              >
                {editor}
              </Paper>
            </Resizable>
          </React.Fragment>
        )}
      </HotKeys>
    );
  }
}

const styles = {
  pageContainer: {
    height: '100%',
    display: 'grid',
    gridTemplateRows: '48px 1fr 1fr',
    gridTemplateColumns: '1fr',
    outline: 'none',
  },
  controls: {
    gridRow: '1/2',
    gridColumn: '1/3',
  },
  pageSelect: {
    width: '100px',
  },
  tree: {
    overflow: 'scroll',
    gridRow: '2/4',
    gridColumn: '1/2',
  },
  innerPaper: {
    margin: '5px',
    backgroundColor: '#fafafa',
    overflow: 'scroll',
    height: '82vh',
  },
  preview: {
    overflow: 'scroll',
    height: 'calc(50% - 10px)',
  },
  editorWithPreview: {
    height: 'calc(50% - 5px)',
  },
  saveButtonWrapper: {
    position: 'relative',
  },
  progress: {
    position: 'absolute',
    bottom: -10,
    left: 0,
    zIndex: 1,
    width: '100%',
  },
  saveButtonError: {
    backgroundColor: 'red',
  },
  resizableRightSide: {
    justifySelf: 'end',
    gridColumn: '2/3',
    gridRow: '2/4',
    height: '100%',
  },
};

const editTree = compose(
  // @ts-ignore
  withStyles(styles, { withTheme: true }),
  withClient,
  withSnackbar,
  withRouter,
)(EditTree);

export default editTree as EditTree;
