import React from 'react';
import { IApplication } from 'universal/applicationManager';
import { IHasConfigName, INameInfo } from 'universal/metadataSupportConstants';
import { IUserInfo } from 'universal/permissionManager';

export interface IApplicationContext {
  application?: IApplication;
  initialContext?: IInitialContext;
  navigation?: INavigation;
  homePage?: string;
}

export interface IInitialContext {
  _userInfo: IUserInfo;
  _valueHelpers?: { [helperName: string]: (any) => any };
  // These come from the initialize pipeline
  [key: string]: any;
}

// Matches Navigation typedef
export interface INavigation extends IHasConfigName {
  showIcons: boolean;
  members: INavItem[];
}

export interface INavItem {
  name: string;
  nameInfo: INameInfo;
  // Link to another Navigation
  link?: string;
  // See 'internalViewComponent' below
  internalView?: string;
  internalLink?: 'widgetTrees';
  url?: string;
  icon?: string;
  members?: INavItem[];
  defaultOpen?: boolean;
  widgetTreeName?: string;
  // enriched with this
  configName: string;
}

export const ApplicationContext = React.createContext<IApplicationContext>(
  {} as IApplicationContext,
);
