/*
 Create the graphql definitions from the EntityTypes and TypeDefinitions
 */

import { DocumentNode, parse } from 'graphql';
import Handlebars from 'handlebars';
import safeJsonStringify from 'safe-json-stringify';

import { SYSTEM } from './common/commonConstants';
import { reThrow } from './errors/errorLog';
import { getLogger, Loggers } from './loggerSupport';
import { MetadataSupport } from './metadataSupport';
import {
  AGGREGATE_PIPELINE_OUTPUT,
  CHUNK_ID,
  CODE,
  CODE_FIELD,
  CODE_TYPE_FIELD,
  CollectionTypes,
  CONNECTION_COUNT,
  DIRECTIVE_ACTIVEASOF,
  DIRECTIVE_ACTIVEASOF_DATE,
  DIRECTIVE_ACTIVEASOF_DURATION,
  DIRECTIVE_BULK_UPDATE,
  DIRECTIVE_DELETE,
  DIRECTIVE_DOLOG,
  DIRECTIVE_ENTITY_TYPE,
  DIRECTIVE_ENTITY_TYPE_NAME,
  DIRECTIVE_FILTER,
  DIRECTIVE_FILTER_EXPR,
  DIRECTIVE_FORCEUPDATE,
  DIRECTIVE_IDIFNOTFOUND,
  DIRECTIVE_IGNORE_SIZECLASS,
  DIRECTIVE_MAYBE,
  DIRECTIVE_OUTPUTPIPELINE,
  DIRECTIVE_OUTPUTPIPELINE_AGGREGATE_PIPELINE,
  DIRECTIVE_OUTPUTPIPELINE_ARGUMENTS,
  DIRECTIVE_OUTPUTPIPELINE_LOGEXECUTION,
  DIRECTIVE_OUTPUTPIPELINE_LOGOUTPUT,
  DIRECTIVE_OUTPUTPIPELINE_NAME,
  DIRECTIVE_OVERWRITE,
  DIRECTIVE_PAGED,
  DIRECTIVE_QUERYID,
  DIRECTIVE_QUERYID_ID,
  DIRECTIVE_REASON,
  DIRECTIVE_REASON_CODE_ID,
  DIRECTIVE_REPLACE,
  DIRECTIVE_REPLACE_DELETE_PATH,
  DIRECTIVE_SUPPRESSLOCALNOTIFY,
  DIRECTIVE_SUPPRESSLOG,
  DIRECTIVE_SUPPRESSNOTIFY,
  DIRECTIVE_SUPPRESSPIPELINE,
  DIRECTIVE_SUPPRESSPIPELINE_PIPELINES,
  IEntityType,
  INCARNATION,
  ITEMS,
  KEY_FIELD,
  NEXT_TOKEN,
  PAGE_NUMBER,
  QUERY_ARG_LIMIT,
  QUERY_ARG_PAGE_LIMIT,
  QUERY_ARG_TEST_DATA,
  QUERY_LIST_SUFFIX,
  SYSTEM_QUERYABLE_FIELDS,
} from './metadataSupportConstants';
import { toGraphQLType } from './pipeline/graphQLSupport';
import { SchemaManager } from './schemaManager';
import { StackInfoKeys } from './stackInfo';
import { IItemType, TypeDefinition } from './typeDefinition';
import { BasicType, Duration, isBuiltin } from './types';
import { getEnumKeys } from './utilityFunctions';

interface IValues {
  ENTITY?: string;
  TYPE?: string;
  FIELDS?: any;
  FIELDS_FOR_QUERY?: any;
}

export interface IResolverMap {
  Query: { [key: string]: string };
  Mutation: { [key: string]: string };
  Subscription: { [key: string]: string };

  [entityId: string]: { [key: string]: string };
}

export interface IEntityIdToConfigName {
  [entityId: string]: string;
}
export interface ITypeDefIdToConfigName {
  [typeDefId: string]: string;
}
export interface ITypeDefsThatAreBoth {
  [qualifiedTypeDefName: string]: boolean;
}

const logger = getLogger({ name: Loggers.LOAD_GRAPHQL });

export const NESTED_SUFFIX = '_nested';
export const INPUT_SUFFIX = 'Input';

export const GET_RESOLVER = 'getResolver';
export const LIST_RESOLVER = 'listResolver';
export const PUT_RESOLVER = 'putResolver';
export const PUT_BATCH_RESOLVER = 'putBatchResolver';
export const UPDATE_RESOLVER = 'updateResolver';
export const UPDATE_BATCH_RESOLVER = 'updateBatchResolver';
export const UPSERT_RESOLVER = 'upsertResolver';
export const UPSERT_BATCH_RESOLVER = 'upsertBatchResolver';
export const DELETE_RESOLVER = 'deleteResolver';
export const DELETE_BATCH_RESOLVER = 'deleteBatchResolver';
export const ASSOC_RESOLVER = 'assocResolver';
export const NESTED_RESOLVER = 'nestedResolver';

export class Loadgraphql {
  private schemaManager: SchemaManager;
  private metadataSupport: MetadataSupport;
  private arrayOpen;
  private arrayClose;
  private entityIdToConfigName: { [entityId: string]: string } = {};
  private typeDefIdToConfigName: { [typeDefId: string]: string } = {};
  private enums: Set<string> = new Set();
  private entityTypeDefs: { [qualifiedTypeDefName: string]: string } = {};
  private typeDefsThatAreBoth: ITypeDefsThatAreBoth = {};
  private errors: string[] = [];

  private checkArray(attr, typeDef) {
    if (!attr.itemInfo) {
      this.errors.push(
        `itemInfo for ${safeJsonStringify(attr)} is missing in type ${
          typeDef.id
        }`,
      );
      return;
    }
    if (
      attr.itemInfo.collectionType === CollectionTypes.ARRAY ||
      attr.itemInfo.collectionType === CollectionTypes.MAP
    ) {
      this.arrayOpen = '[';
      this.arrayClose = ']';
    } else {
      this.arrayOpen = '';
      this.arrayClose = '';
    }
  }

  public checkForRecursion(params: {
    typeDef: TypeDefinition;
    seenTypes?: string[];
  }) {
    const { typeDef } = params;

    let { seenTypes } = params;
    if (!seenTypes) {
      seenTypes = [];
    }

    seenTypes.push(typeDef.id);
    const attributes = typeDef.getAttributes();
    for (const attr of attributes) {
      const typeId = attr.itemInfo.type;
      if (isBuiltin(typeId) || attr.itemInfo.associatedEntity) {
        continue;
      }
      const { typeDef: attrTypeDef } = this.checkType(attr);
      if (seenTypes.find((t) => t === attrTypeDef.id)) {
        this.errors.push(
          `Recursive type definition found at ${typeDef.id}/${attr.name} recursive type: ${attrTypeDef.id}`,
        );
        continue;
      }
      this.checkForRecursion({
        typeDef: attrTypeDef,
        seenTypes,
      });
    }
    seenTypes.pop();
  }

  // Check for a valid type return the GraphQL type to use
  private checkType(attr: IItemType): {
    typeDef?: TypeDefinition;
    unqualifiedName: string;
    suffix?: string;
  } {
    if (!attr.itemInfo.type) {
      this.errors.push(`No type specified for ${safeJsonStringify(attr)}`);
      // Prevent a throw when this is not present
      return { unqualifiedName: '' };
    }
    const typeName = attr.itemInfo.type;
    if (isBuiltin(typeName)) {
      return {
        unqualifiedName: toGraphQLType(attr.itemInfo),
        suffix: '',
      };
    }
    const attrTypeDef = attr.itemInfo.typeDefinitionObject;

    return {
      typeDef: attrTypeDef,
      unqualifiedName: MetadataSupport.getUnqualifiedName(typeName),
      suffix:
        this.typeDefsThatAreBoth[attrTypeDef.id] &&
        !attr.itemInfo.associatedEntity
          ? NESTED_SUFFIX
          : '',
    };
  }

  private createInputTypeBody(outputString: string[], typeDef: TypeDefinition) {
    for (const attr of typeDef.getAttributes()) {
      this.checkArray(attr, typeDef);
      const { unqualifiedName: typeNameToUse, suffix } = this.checkType(attr);
      if (
        isBuiltin(attr.itemInfo.type) ||
        this.enums.has(
          MetadataSupport.getQualifiedName(
            attr.itemInfo.type,
            typeDef.configName,
          ),
        )
      ) {
        outputString.push(
          `  ${attr.name}: ${this.arrayOpen}${typeNameToUse}${suffix}${this.arrayClose}\n`,
        );
      } else {
        outputString.push(
          `  ${attr.name}: ${this.arrayOpen}${typeNameToUse}${INPUT_SUFFIX}${suffix}${this.arrayClose}\n`,
        );
      }
    }
    outputString.push('  _replace: Boolean');
    this.addCommonRecordFields(outputString, typeDef.getAttributes());
    outputString.push('}\n\n');
  }

  private addCommonRecordFields(outputString: string[], attrs: IItemType[]) {
    for (const key of Object.keys(SYSTEM_QUERYABLE_FIELDS)) {
      // Type attr can be defined in the typedef sometimes
      if (!attrs.find((attr) => key === attr.name)) {
        outputString.push(`  ${key}: ${SYSTEM_QUERYABLE_FIELDS[key]}\n`);
      }
    }
  }

  private createType(
    outputString: string[],
    typeDef: TypeDefinition,
    nested = false,
  ) {
    try {
      const attributes = typeDef.getAttributes();
      const originalTypeName = MetadataSupport.getUnqualifiedName(typeDef.id);
      const typeName = originalTypeName + (nested ? NESTED_SUFFIX : '');
      const inputTypeName = `${originalTypeName}${INPUT_SUFFIX}${
        nested ? NESTED_SUFFIX : ''
      }`;

      outputString.push(`type ${typeName} {\n`);
      for (const attr of attributes) {
        this.checkArray(attr, typeDef);
        const { unqualifiedName: attrTypeNameToUse, suffix } =
          this.checkType(attr);
        let nameType;
        let entityTypeDirective = '';
        if (attr.itemInfo.associatedEntity) {
          const entity = this.schemaManager.getEntityType({
            name: attr.itemInfo.associatedEntity,
            parentRecord: typeDef,
          });
          if (!entity) {
            this.errors.push(
              `associatedEntity ${attr.itemInfo.associatedEntity} not found for attribute ${attr.name}`,
            );
            return;
          }
          if (attr.itemInfo.type !== BasicType.ID) {
            this.errors.push(
              `The type of a field with an associatedEntity must be ID: ${attr.name}`,
            );
            return;
          }
          entityTypeDirective = ` @${DIRECTIVE_ENTITY_TYPE}(${DIRECTIVE_ENTITY_TYPE_NAME}: "${MetadataSupport.getQualifiedNameForObject(
            entity,
          )}")`;
          // For the query, point directly to the nested type using the entity name/type
          const unqualifiedTypeDefName = MetadataSupport.getUnqualifiedName(
            entity.typeDefinition,
          );
          nameType = { name: attr.name, type: unqualifiedTypeDefName };
        } else {
          nameType = { name: attr.name, type: `${attrTypeNameToUse}${suffix}` };
        }
        outputString.push(
          `  ${nameType.name}: ${this.arrayOpen}${nameType.type}${this.arrayClose}${entityTypeDirective}\n`,
        );
      }
      // FIXME2 - I think the CHUNK_ID here should be removed; the user does not need to see this, it's a private matter
      // with the database (on a per-object basis);
      outputString.push(`  ${CHUNK_ID}: Int\n`);
      this.addCommonRecordFields(outputString, attributes);
      outputString.push('}\n\n');

      // The real input type
      outputString.push(`input ${inputTypeName} {\n`);
      this.createInputTypeBody(outputString, typeDef);

      // Must be aligned with IQueryConnectionResult in PipelineManager
      if (!nested) {
        outputString.push(
          `type ${typeName}Connection {
          ${ITEMS}: [${typeName}]
          ${NEXT_TOKEN}: String
          ${CONNECTION_COUNT}: Int
          ${PAGE_NUMBER}: Int
          requestId: String
          executionId: String
          outputPipelineExecutionId: String
          ${CHUNK_ID}: Int
          ${AGGREGATE_PIPELINE_OUTPUT}: Object
        }\n`,
        );
      }
    } catch (error) {
      reThrow({
        logger,
        message: 'Problem creating type',
        logObject: { typeId: typeDef.id },
        error,
      });
    }
  }

  private createEnumType(
    outputString: string[],
    typeName: string,
    attributes: any[],
  ) {
    outputString.push(
      `enum ${MetadataSupport.getUnqualifiedName(typeName)} {\n`,
    );
    for (const attr of attributes) {
      outputString.push(`  ${attr.name}\n`);
    }
    outputString.push('}\n\n');
    this.enums.add(typeName);
  }

  private addEntityIDConfigName = (entityId: string, configName: string) => {
    const unqualEntityId = MetadataSupport.getUnqualifiedName(entityId);
    if (this.entityIdToConfigName[unqualEntityId]) {
      throw new Error(
        `Entity ${unqualEntityId} is already associated with config ${this.entityIdToConfigName[unqualEntityId]} and can't add config ${configName}`,
      );
    }
    this.entityIdToConfigName[unqualEntityId] = configName;
  };

  private addTypeDefIDConfigName = (typeDefId: string, configName: string) => {
    const unqualTypeDefId = MetadataSupport.getUnqualifiedName(typeDefId);
    if (this.typeDefIdToConfigName[unqualTypeDefId]) {
      throw new Error(
        `TypeDef ${unqualTypeDefId} is already associated with config ${this.typeDefIdToConfigName[unqualTypeDefId]} and can't add config ${configName}`,
      );
    }
    this.typeDefIdToConfigName[unqualTypeDefId] = configName;
  };

  private verifyEntityType(entityType: IEntityType) {
    if (entityType.isCode) {
      if (entityType.keys) {
        throw new Error(
          `If an entity is a code, it cannot have keys. Entity id: ${entityType.id} Entity keys: ${entityType.keys}`,
        );
      }

      const codeEntityType = this.schemaManager.getEntityType({
        name: CODE,
        configName: SYSTEM,
      });

      if (codeEntityType.isConfiguration !== entityType.isConfiguration) {
        throw new Error(
          `If an entity is a code, it must an "isConfiguration" value of ${codeEntityType.isConfiguration}. Entity id: ${entityType.id} Entity isConfiguration: ${entityType.isConfiguration}`,
        );
      }

      const typeDefinition = this.schemaManager.getTypeDefinition({
        name: entityType.typeDefinition,
        parentRecord: entityType,
      });

      if (!typeDefinition) {
        throw new Error(
          `Type definition ${entityType.typeDefinition} not found for Entity type ${entityType.id}`,
        );
      }

      const codeTypeAttribute = typeDefinition
        .getAttributes()
        .find((attribute) => attribute.name === CODE_TYPE_FIELD);

      if (!codeTypeAttribute) {
        throw new Error(
          `If an entity is a code, it must have a ${CODE_TYPE_FIELD} attribute in its type definition. Entity type ${entityType.id}`,
        );
      }

      const codeAttribute = typeDefinition
        .getAttributes()
        .find((attribute) => attribute.name === CODE_FIELD);

      if (!codeAttribute) {
        throw new Error(
          `If an entity is a code, it must have a ${CODE_FIELD} attribute in its type definition. Entity type ${entityType.id}`,
        );
      }
    }
  }

  private createStandardEnums(outputString: string[]) {
    outputString.push('enum Duration {\n');
    getEnumKeys(Duration).forEach((k) => outputString.push(`${k}\n`));
    outputString.push('}\n\n');
  }

  private createDirectives(outputString: string[]) {
    outputString.push(
      `directive @${DIRECTIVE_FILTER} (${DIRECTIVE_FILTER_EXPR}: String) on FIELD\n`,
    );
    outputString.push(
      `directive @${DIRECTIVE_REPLACE} (${DIRECTIVE_REPLACE_DELETE_PATH}: String) on FIELD\n`,
    );
    outputString.push(
      `directive @${DIRECTIVE_DELETE} (${DIRECTIVE_REPLACE_DELETE_PATH}: String) on FIELD\n`,
    );
    outputString.push(
      `directive @${DIRECTIVE_QUERYID}(
        ${DIRECTIVE_QUERYID_ID}: Int
      ) on FIELD\n`,
    );
    outputString.push(
      `directive @${DIRECTIVE_ACTIVEASOF}(
        ${DIRECTIVE_ACTIVEASOF_DATE}: String
        ${DIRECTIVE_ACTIVEASOF_DURATION}: Duration
      ) on FIELD\n`,
    );

    outputString.push(`directive @${DIRECTIVE_BULK_UPDATE} on MUTATION\n`);
    outputString.push(`directive @${DIRECTIVE_DOLOG} on MUTATION\n`);
    outputString.push(`directive @${DIRECTIVE_FORCEUPDATE} on MUTATION\n`);
    outputString.push(`directive @${DIRECTIVE_MAYBE} on MUTATION\n`);
    outputString.push(`directive @${DIRECTIVE_OVERWRITE} on MUTATION\n`);
    outputString.push(
      `directive @${DIRECTIVE_REASON}(${DIRECTIVE_REASON_CODE_ID}: String) on MUTATION\n`,
    );
    outputString.push(
      `directive @${DIRECTIVE_SUPPRESSPIPELINE}(${DIRECTIVE_SUPPRESSPIPELINE_PIPELINES}: String) on MUTATION\n`,
    );
    outputString.push(`directive @${DIRECTIVE_SUPPRESSLOG} on MUTATION\n`);
    outputString.push(
      `directive @${DIRECTIVE_SUPPRESSLOCALNOTIFY} on MUTATION\n`,
    );
    outputString.push(`directive @${DIRECTIVE_SUPPRESSNOTIFY} on MUTATION\n`);

    outputString.push(`directive @${DIRECTIVE_PAGED} on QUERY\n`);
    outputString.push(
      `directive @${DIRECTIVE_OUTPUTPIPELINE}(
        ${DIRECTIVE_OUTPUTPIPELINE_NAME}: String
        ${DIRECTIVE_OUTPUTPIPELINE_AGGREGATE_PIPELINE}: String
        ${DIRECTIVE_OUTPUTPIPELINE_LOGOUTPUT}: Boolean
        ${DIRECTIVE_OUTPUTPIPELINE_LOGEXECUTION}: Boolean
        ${DIRECTIVE_OUTPUTPIPELINE_ARGUMENTS}: Object
      ) on QUERY\n`,
    );
    outputString.push(`directive @${DIRECTIVE_IDIFNOTFOUND} on QUERY\n`);
    outputString.push(`directive @${DIRECTIVE_IGNORE_SIZECLASS} on QUERY\n`);

    outputString.push(
      `directive @${DIRECTIVE_ENTITY_TYPE}(
        ${DIRECTIVE_ENTITY_TYPE_NAME}: String
      ) on FIELD_DEFINITION\n`,
    );

    outputString.push('scalar JSON\n');
    outputString.push('scalar Object\n');
  }

  public async createSchema(params: { schemaManager: SchemaManager }): Promise<{
    schema: string;
    resolvers: IResolverMap;
    parsedSchema: DocumentNode;
    entityIdToConfigName: IEntityIdToConfigName;
    typeDefIdToConfigName: ITypeDefIdToConfigName;
    typeDefsThatAreBoth: ITypeDefsThatAreBoth;
  }> {
    const { schemaManager } = params;

    const outputString = [];
    const resolvers: any = {};

    this.schemaManager = schemaManager;
    this.metadataSupport = this.schemaManager.metadataSupport;

    this.createStandardEnums(outputString);
    this.createDirectives(outputString);

    const entityTypes: IEntityType[] = this.schemaManager.getAllEntityTypes();
    const typeDefinitions: TypeDefinition[] =
      this.schemaManager.getAllTypeDefinitions();

    entityTypes.forEach((entityType) => {
      this.verifyEntityType(entityType);

      const typeDef = schemaManager.getTypeDefinition({
        name: entityType.typeDefinition,
        parentRecord: entityType,
      });
      this.entityTypeDefs[
        MetadataSupport.getQualifiedName(typeDef.id, typeDef.configName)
      ] = entityType.id;
    });

    const nestedTypeDefs: { [typedefId: string]: boolean } = {};
    for (const typeDef of typeDefinitions) {
      typeDef
        .getAttributes()
        .filter(
          (a) => !a.itemInfo.associatedEntity && !isBuiltin(a.itemInfo.type),
        )
        .forEach(
          (a) =>
            (nestedTypeDefs[
              MetadataSupport.getQualifiedName(
                a.itemInfo.type,
                typeDef.configName,
              )
            ] = true),
        );
    }

    // Do the enums all first because we have to refer to them
    this.enums.clear();
    for (const typeDef of typeDefinitions) {
      if (nestedTypeDefs[typeDef.id]) {
        const entityOfTypeDef = this.entityTypeDefs[typeDef.id];
        if (
          entityOfTypeDef &&
          typeDef.getAttributes().find((a) => a.name === 'id')
        ) {
          this.typeDefsThatAreBoth[typeDef.id] = true;
        }
      }

      if (typeDef.isEnum) {
        this.createEnumType(outputString, typeDef.id, typeDef.getAttributes());
      }
      this.addTypeDefIDConfigName(typeDef.id, typeDef.configName);
    }

    for (const type of typeDefinitions) {
      if (!type.isEnum) {
        this.checkForRecursion({ typeDef: type });
      }
    }

    for (const type of typeDefinitions) {
      if (type.isEnum) {
        continue;
      }
      this.createType(outputString, type);
      if (this.typeDefsThatAreBoth[type.id]) {
        this.createType(outputString, type, true);
      }
    }

    for (const entity of entityTypes) {
      this.addEntityIDConfigName(entity.id, entity.configName);
    }

    outputString.push(
      `input IdType${INPUT_SUFFIX} { id: String ${INCARNATION}: Int }\n`,
    );

    resolvers.Query = {};
    outputString.push('type Query {\n');
    for (const entity of entityTypes) {
      const typeName = MetadataSupport.getUnqualifiedName(
        entity.typeDefinition,
      );
      const entityName = MetadataSupport.getUnqualifiedName(entity.id);
      outputString.push(
        `  get${entityName}(${KEY_FIELD}: String, ${INCARNATION}: Int): ${typeName}\n`,
      );

      resolvers.Query[`get${entityName}`] = GET_RESOLVER;
      const values = this.makeValues(entity);
      const listString = Handlebars.compile(
        ` list{{ENTITY}}(
                ${QUERY_ARG_LIMIT}: Int, 
                ${QUERY_ARG_TEST_DATA}: Int, 
                ${NEXT_TOKEN}: String, 
                ${CHUNK_ID}: Int 
                ${QUERY_ARG_PAGE_LIMIT}: Int 
                {{#FIELDS_FOR_QUERY}}, 
                {{FIELD}}: {{FIELD_GRAPHQL_TYPE}}{{/FIELDS_FOR_QUERY}}): 
                {{TYPE}}Connection\n`,
      )(values);
      resolvers.Query[`list${entityName}`] = LIST_RESOLVER;
      outputString.push(listString);
    }
    outputString.push('}\n');

    resolvers.Mutation = {};
    outputString.push('type Mutation {\n');
    for (const entity of entityTypes) {
      const typeName = MetadataSupport.getUnqualifiedName(
        entity.typeDefinition,
      );
      const entityName = MetadataSupport.getUnqualifiedName(entity.id);
      // Used by the application - hooked to lambda resolver
      outputString.push(
        `  create${entityName}(input: ${typeName}${INPUT_SUFFIX}!): ${typeName}\n`,
      );
      resolvers.Mutation[`create${entityName}`] = PUT_RESOLVER;
      outputString.push(
        `  update${entityName}(input: ${typeName}${INPUT_SUFFIX}!): ${typeName}\n`,
      );
      resolvers.Mutation[`update${entityName}`] = UPDATE_RESOLVER;
      outputString.push(
        `  upsert${entityName}(input: ${typeName}${INPUT_SUFFIX}!): ${typeName}\n`,
      );
      resolvers.Mutation[`upsert${entityName}`] = UPSERT_RESOLVER;
      outputString.push(
        `  delete${entityName}(input: IdType${INPUT_SUFFIX}!): ${typeName}\n`,
      );
      resolvers.Mutation[`delete${entityName}`] = DELETE_RESOLVER;

      outputString.push(
        `  batchCreate${entityName}(input: [${typeName}${INPUT_SUFFIX}]!): [${typeName}]\n`,
      );
      resolvers.Mutation[`batchCreate${entityName}`] = PUT_BATCH_RESOLVER;
      outputString.push(
        `  batchUpdate${entityName}(input: [${typeName}${INPUT_SUFFIX}]!): [${typeName}]\n`,
      );
      resolvers.Mutation[`batchUpdate${entityName}`] = UPDATE_BATCH_RESOLVER;
      outputString.push(
        `  batchUpsert${entityName}(input: [${typeName}${INPUT_SUFFIX}]!): [${typeName}]\n`,
      );
      resolvers.Mutation[`batchUpsert${entityName}`] = UPSERT_BATCH_RESOLVER;
      outputString.push(
        `  batchDelete${entityName}(input: [IdType${INPUT_SUFFIX}]!): [${typeName}]\n`,
      );
      resolvers.Mutation[`batchDelete${entityName}`] = DELETE_BATCH_RESOLVER;

      // FIXME - keep these only for backwards compat, remove when all "Internal" fields are gone
      outputString.push(
        `  create${entityName}Internal(input: ${typeName}${INPUT_SUFFIX}!): ${typeName}\n`,
      );
      resolvers.Mutation[`create${entityName}Internal`] = PUT_RESOLVER;
      outputString.push(
        `  update${entityName}Internal(input: ${typeName}${INPUT_SUFFIX}!): ${typeName}\n`,
      );
      resolvers.Mutation[`update${entityName}Internal`] = UPDATE_RESOLVER;
      outputString.push(
        `  delete${entityName}Internal(input: IdType${INPUT_SUFFIX}!): ${typeName}\n`,
      );
      resolvers.Mutation[`delete${entityName}Internal`] = DELETE_RESOLVER;
    }
    outputString.push('}\n');

    const makeSubscription = (entity, values) => {
      const typeName = MetadataSupport.getUnqualifiedName(
        entity.typeDefinition,
      );
      const entityName = MetadataSupport.getUnqualifiedName(entity.id);
      let subString = '';
      if (values.FIELDS_FOR_QUERY.length > 0) {
        subString = Handlebars.compile(
          '{{#FIELDS_FOR_QUERY}} {{FIELD}}: {{FIELD_GRAPHQL_TYPE}} {{/FIELDS_FOR_QUERY}}',
        )(values);
      }

      const listString = `  ${entityName}(id : ID ${subString} ${INCARNATION}: Int ${CHUNK_ID}: Int): ${typeName}
    \n`;
      outputString.push(listString);
    };

    resolvers.Subscription = {};
    outputString.push('type Subscription {\n');
    for (const entity of entityTypes) {
      const values = this.makeValues(entity);
      makeSubscription(entity, values);
    }
    outputString.push('}\n');

    outputString.push(`schema {
    query: Query
    mutation: Mutation
    subscription: Subscription
  }`);

    const makeAssocResolver = (typeDef: TypeDefinition, nested = false) => {
      const unqualName = MetadataSupport.getUnqualifiedName(typeDef.id);
      const targetEntity = nested
        ? `${unqualName}${NESTED_SUFFIX}`
        : unqualName;
      let resolverEntity = resolvers[targetEntity];
      if (!resolverEntity) {
        resolverEntity = resolvers[targetEntity] = {};
      }
      typeDef.getAttributes().forEach((attr) => {
        if (attr.itemInfo.associatedEntity) {
          resolverEntity[attr.name] = ASSOC_RESOLVER;
        } else {
          // Not supporting this yet not clear if needed
          // resolverEntity[attr.name] = NESTED_RESOLVER;
        }
      });
    };

    for (const typeDef of typeDefinitions) {
      makeAssocResolver(typeDef);
      if (this.typeDefsThatAreBoth[typeDef.id]) {
        makeAssocResolver(typeDef, true);
      }
    }

    const finalSchema = outputString.join('');

    if (this.errors.length > 0) {
      const uniqueErrors = [...new Set(this.errors)];
      for (const error of uniqueErrors) {
        logger.error(error);
      }
      throw new Error('Errors found, see above log messages');
    }

    const { stackInfo } = schemaManager.clientManager;
    stackInfo.addOutputObject(StackInfoKeys.SCHEMA, finalSchema);
    stackInfo.addOutputObject(StackInfoKeys.RESOLVERS, resolvers);

    stackInfo.addOutputObject(
      StackInfoKeys.ENTITYIDTOCONFIGNAME,
      this.entityIdToConfigName,
    );
    stackInfo.addOutputObject(
      StackInfoKeys.TYPEDEFIDTOCONFIGNAME,
      this.typeDefIdToConfigName,
    );
    stackInfo.addOutputObject(
      StackInfoKeys.TYPEDEFS_THAT_ARE_BOTH,
      this.typeDefsThatAreBoth,
    );

    try {
      const parsedSchema = parse(finalSchema);

      // console.log(finalSchema);
      return {
        schema: finalSchema,
        resolvers,
        parsedSchema,
        entityIdToConfigName: this.entityIdToConfigName,
        typeDefIdToConfigName: this.typeDefIdToConfigName,
        typeDefsThatAreBoth: this.typeDefsThatAreBoth,
      };
    } catch (error) {
      reThrow({
        logger,
        message: 'Problem parsing GraphQL schema',
        error,
      });
    }
  }

  private makeValues(entity: IEntityType, excludeKey?: boolean) {
    const typeDef: TypeDefinition = this.schemaManager.getTypeDefinition({
      name: entity.typeDefinition,
      parentRecord: entity,
    });
    const typeName = MetadataSupport.getUnqualifiedName(entity.typeDefinition);
    const entityName = MetadataSupport.getUnqualifiedName(entity.id);
    const result: IValues = {};

    try {
      result.ENTITY = entityName;
      result.TYPE = typeName;

      result.FIELDS = [];
      result.FIELDS_FOR_QUERY = [];
      for (const field of typeDef.getAttributes()) {
        let fieldPrefix = '';
        if (field.itemInfo.associatedEntity) {
          fieldPrefix = `${MetadataSupport.getUnqualifiedName(
            field.itemInfo.associatedEntity,
          )}-`;
        }
        if (field.name === KEY_FIELD) {
          if (excludeKey) {
            continue;
          }
          fieldPrefix = `${entityName}-`;
        }

        const fieldInfo = {
          FIELD: field.name,
          FIELD_PREFIX: fieldPrefix,
          FIELD_GRAPHQL_TYPE: toGraphQLType(field.itemInfo),
        };
        if (field.itemInfo.generateQueryField) {
          if (
            this.metadataSupport.isObjectType({
              itemInfo: field.itemInfo,
              typeDef,
            })
          ) {
            logger.error(
              `!!! Cannot specify generateQueryField on ${typeDef.id}: ${field.name}`,
            );
            // This will fall through and cause a bad Schema to be generated, but it's
            // better than throwing, as we can catch all of the errors in one pass
          }
          result.FIELDS_FOR_QUERY.push(fieldInfo);
          result.FIELDS_FOR_QUERY.push({
            FIELD: `${field.name}${QUERY_LIST_SUFFIX}`,
            FIELD_PREFIX: fieldPrefix,
            FIELD_GRAPHQL_TYPE: `[${toGraphQLType(field.itemInfo)}]`,
          });
        }
        result.FIELDS.push(fieldInfo);
      }
    } catch (error) {
      throw new Error(
        `Problem making values: ${error.stack} for ${safeJsonStringify(
          entity,
        )}`,
      );
    }
    return result;
  }
}
