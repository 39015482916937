import { AdditionalEditor } from 'universal/types';

import { materialUiFontWeights } from '../../../util/typographyTranslate';
import { IConfigurationType, linkProperties, textVariants } from '../types';

export const properties = {
  ...linkProperties,
  label: {},
  data: {},
  variant: {
    default: "'body2'",
    description:
      'Applies the style and sizing, see examples of each here in link',
    link: 'https://material-ui.com/style/typography/',
    options: textVariants,
  },
  fontWeight: {
    description:
      'optional font weights defined by the material ui spec, medium and regular seem to be the same. Leave them set to blank to use the variants default',
    default: '',
    options: [
      '',
      ...(Object.keys(materialUiFontWeights).map(
        (key) => `'${key}'`,
      ) as string[]),
    ],
  },
  textDecoration: {
    default: "'none'",
    description:
      'add any number of decorators to the text, separated with a space. ex: line-through, underline red, wavy overline lime, dashed underline overline, blink',
    link: 'https://developer.mozilla.org/en-US/docs/Web/CSS/text-decoration',
  },
  fontStyle: {
    default: "'normal'",
    description: 'CSS font style',
    options: ["'normal'", "'italic'", "'oblique'"],
  },
  color: {
    description:
      'sets text color, valid values are any named html color or #(hex value), ex: red, peru, #FC3AEC',
    additionalEditor: AdditionalEditor.Color,
  },
  backgroundColor: {
    description:
      'sets backgroundColor color, valid values are any named html color or #(hex value), ex: red, peru, #FC3AEC',
    additionalEditor: AdditionalEditor.Color,
  },
  colorWhileVisiting: {
    additionalEditor: AdditionalEditor.Color,
  },
  backgroundWhileVisiting: {
    additionalEditor: AdditionalEditor.Color,
  },
  align: {
    options: ["'inherit'", "'left'", "'center'", "'right'", "'justify'"],
  },
  width: {},
  paddingTop: {},
  paddingBottom: {},
  paddingLeft: {
    default: "'5px'",
  },
  paddingRight: {
    default: "'5px'",
  },
  noWrap: {
    default: false,
    options: [true, false],
  },
} as const;

export const displayTypeInfo: IConfigurationType = {
  types: {
    children: true,
  },
  childTypes: {
    actions: true,
  },
  properties,
  events: {
    load: [],
  },
};
