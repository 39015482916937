import { Lambda } from '@aws-sdk/client-lambda';
import { isBrowser } from 'browser-or-node';
import safeJsonStringify from 'safe-json-stringify';

import { ClientManager } from './clientManager';
import {
  FUNCTION_NAME_ENV,
  getWarmupFunctionName,
  ILambdaFunction,
  ILambdaFunctions,
  TEST_LAMBDA_PORT,
} from './common/commonConstants';
import { getErrorString } from './errors/errorString';
import { retry } from './errors/retry';
import { getLogger, Loggers, LogLevels } from './loggerSupport';
import { StackInfo, StackInfoKeys } from './stackInfo';

const logger = getLogger({
  name: Loggers.LAMBDA_SUPPORT,
  level: LogLevels.Info,
});

const RESTART = '__restart';

// Known functions - refer to functions added in createServerless
export const GRAPHQL_FUNCTION = 'ah';
export const PIPELINE_HANDLER_FUNCTION = 'ph';
export const PIPELINE_HANDLER_QUEUED_FUNCTION = 'phQ';
export const PIPELINE_HANDLER_QUEUED_FUNCTION_FIFO = 'phQfifo';
export const ALLOCATION_OPTIMIZER = 'optAlloc';

export interface ILambdaClient {
  client: Lambda;
  testMode: boolean;
  functionInfo: ILambdaFunction;
  externalUrl?: string;
}

interface ILambdaClients {
  [key: string]: ILambdaClient;
}

export class LambdaSupport {
  private clientManager: ClientManager;
  private lambdaClient: Lambda;
  private lambdaClients: ILambdaClients;
  private stackInfo: StackInfo;
  public ignoreTestUrl: boolean;
  public testServers: boolean;
  public lambdaFunctions: ILambdaFunctions;

  constructor(params: {
    clientManager: ClientManager;
    stackInfo: StackInfo;
    ignoreTestUrl: boolean;
    lambdaClient: Lambda;
  }) {
    Object.assign(this, params);
    this.lambdaFunctions = this.stackInfo.getObject(
      StackInfoKeys.LAMBDAFUNCTIONS,
    );
  }

  public static checkForRestart(event: any) {
    return !!event[RESTART];
  }

  public static checkForWarmup(event: any) {
    return event.source === 'serverless-plugin-warmup';
  }

  // Returns true if running in an actual Lambda server (not including serverless offline)
  public static isInAwsRealLambdaServer(): boolean {
    return !!process.env.AWS_SESSION_TOKEN;
  }

  // True for real or serverless offline
  public static isInLambdaServer(): boolean {
    return !!process.env.AWS_LAMBDA_FUNCTION_NAME;
  }

  public static getCurrentFunctionName(): string {
    return process.env[FUNCTION_NAME_ENV];
  }

  public getLambdaClientInfo(functionName: string): ILambdaClient {
    if (!this.lambdaClients) {
      throw new Error('LambdaSupport not initialized');
    }
    const clientInfo = this.lambdaClients[functionName];
    if (!clientInfo) {
      throw new Error(`Can't get client info for ${functionName}`);
    }
    return clientInfo;
  }

  public getLambdaClient(functionName: string): Lambda {
    return this.lambdaClients[functionName].client;
  }

  public getExternalLambdaClientUrl(functionName: string): string {
    const client = this.lambdaClients[functionName];
    if (!client) {
      throw new Error(`Lambda client not found for function: ${functionName}`);
    }
    if (!client.functionInfo.isExternal) {
      throw new Error(
        `Lambda client at function is not external: ${functionName}`,
      );
    }
    return client.externalUrl;
  }

  private getLambdaTestEndpointUrl() {
    if (this.ignoreTestUrl) {
      return undefined;
    }
    const stackConfig = this.clientManager.getStackConfig();
    const testUrl = stackConfig.localTestEndpointUrl;
    if (testUrl) {
      let urlToUse;

      // Only use the tunnel if we need to (that is, we in a real lambda server)
      // Serverless offline will fake being in a lambda function by providing all of the AWS variables
      // so we use this function to check
      if (LambdaSupport.isInAwsRealLambdaServer()) {
        urlToUse = testUrl;
      } else {
        urlToUse = `http://localhost:${TEST_LAMBDA_PORT}`;
      }

      this.testServers = true;
      return urlToUse;
    }
    // Don't specify the endpoint at all, use the AWS default
    return undefined;
  }

  // The long function name is how the function is known to AWS
  public getFunctionNameForLongFunctionName(longFunctionName: string): string {
    for (const functionName of Object.keys(this.lambdaFunctions)) {
      if (
        this.lambdaFunctions[functionName].fullyQualifiedName ===
        longFunctionName
      ) {
        return functionName;
      }
    }
    throw new Error(`No function info found for ${longFunctionName}`);
  }

  public getProxyForFunctionName(functionName: string): string {
    const lambdaFunctionProxyFor =
      this.lambdaFunctions[functionName].isProxyForFunctionName;
    if (!lambdaFunctionProxyFor) {
      throw new Error(`Function ${functionName} must be a proxy`);
    }
    return lambdaFunctionProxyFor;
  }

  public async setupLambdaClients() {
    const testEndpointUrl = await this.getLambdaTestEndpointUrl();
    const stackConfig = this.clientManager.getStackConfig();

    this.lambdaClients = {};
    for (const f in this.lambdaFunctions) {
      const lambdaFunction = this.lambdaFunctions[f];
      const shortName = lambdaFunction.shortName;
      const lambdaParams: any = { ...this.clientManager.awsConfig };
      const useTestLambda = !!stackConfig.useTestLambda?.[shortName];
      const testMode = useTestLambda && testEndpointUrl;
      if (testMode) {
        lambdaParams.endpoint = testEndpointUrl;
      }

      let externalUrl;
      if (lambdaFunction.isExternal) {
        let baseUrl;
        if (stackConfig.localTestApiGatewayUrl && useTestLambda) {
          baseUrl = `${stackConfig.localTestApiGatewayUrl}/dev/`;
        } else {
          baseUrl = stackConfig.apiGatewayBaseUrl;
        }
        externalUrl = `${baseUrl}${lambdaFunction.httpEventPath}`;
      }

      if (testMode) {
        logger.info(`TEST ${shortName}: ${testEndpointUrl}`);
      }
      this.lambdaClients[shortName] = {
        client: new Lambda(lambdaParams),
        testMode,
        functionInfo: lambdaFunction,
        externalUrl,
      };
    }
  }

  public async setWarmupConcurrency(concurrency: number) {
    await this.setLambdaConfiguration({
      functionName: getWarmupFunctionName(this.clientManager.stackId),
      lambdaClient: this.lambdaClient,
      variableName: 'WARMUP_CONCURRENCY',
      variableValue: concurrency.toString(),
    });
  }

  public async restartLambdaFunctions(params?: {
    noTest?: boolean;
    functions?: string[];
  }) {
    if (isBrowser) {
      // Don't have the permission to update the Lambda functions from the browser
      await this.clientManager.pipelineManager.executePipelineRemote({
        name: 'system:restartLambdaFunctions',
      });
      logger.info('Restarted all lambda functions via remote pipeline');
      return;
    }

    const promises = [];
    for (const f in this.lambdaFunctions) {
      const func = this.lambdaFunctions[f];
      if (
        func.noRestart ||
        (params?.functions &&
          !params?.functions.find((sf) => sf === func.shortName))
      ) {
        continue;
      }
      const lc = this.lambdaClients[func.shortName];
      if (lc.testMode && params?.noTest) {
        continue;
      }
      promises.push(
        this.restartLambdaFunction({
          functionDef: func,
          lambdaClient: lc.client,
          testMode: lc.testMode,
        }),
      );
    }
    await Promise.all(promises);
    logger.info('Restarted lambda functions');
  }

  public async restartLambdaFunction(params: {
    functionDef?: ILambdaFunction;
    functionName?: string;
    lambdaClient: Lambda;
    testMode?: boolean;
  }): Promise<any> {
    const {
      functionDef,
      functionName = functionDef?.fullyQualifiedName,
      lambdaClient,
      testMode,
    } = params;

    if (testMode) {
      const lambdaParamsTest = {
        FunctionName: functionName,
        Payload: new TextEncoder().encode(
          safeJsonStringify({
            [RESTART]: true,
          }),
        ),
      };
      try {
        await retry({
          command: () => lambdaClient.invoke(lambdaParamsTest),
        });
        logger.debug(
          `Restarted AWS lambda function '${functionName}' (testMode)`,
        );
      } catch (error) {
        logger.error(
          `Error restarting '${functionName}'(testMode)`,
          getErrorString(error),
        );
      }
      return;
    }

    try {
      await this.setLambdaConfiguration({
        variableName: 'RESTART_TRIGGER',
        variableValue: Date.now().toString(),
        ...params,
      });
    } catch (error) {
      logger.warn(`Error restarting '${functionName}'`, getErrorString(error));
    }
  }

  public async setEnvironmentVariables(params?: {
    variableName: string;
    variableValue: string;
  }) {
    const promises = [];
    for (const f in this.lambdaFunctions) {
      const func = this.lambdaFunctions[f];
      const lc = this.lambdaClients[func.shortName];
      promises.push(
        this.setLambdaConfiguration({
          ...params,
          functionDef: func,
          lambdaClient: lc.client,
        }),
      );
    }
    await Promise.all(promises);
    logger.info('Set environment variables done');
  }

  private async setLambdaConfiguration(params: {
    functionDef?: ILambdaFunction;
    functionName?: string;
    lambdaClient: Lambda;
    variableName: string;
    variableValue: string;
  }): Promise<any> {
    const {
      functionDef,
      functionName = functionDef?.fullyQualifiedName,
      lambdaClient,
      variableName,
      variableValue,
    } = params;
    let existingEnvVars;

    try {
      logger.debug(`Restarting AWS lambda function '${functionName}'`);
      existingEnvVars = await retry({
        command: () =>
          this.getLambdaEnvVars({
            functionName,
            lambdaClient,
          }),
      });
    } catch (error) {
      if (!error.toString().includes('Function not found')) {
        throw error;
      }
      logger.warn(
        `Lambda function ${functionName} cannot be restarted because it doesn't exist`,
      );
      return;
    }

    const updatedEnvVars = Object.assign({}, existingEnvVars, {
      [variableName]: variableValue,
    });

    const lambdaParams = {
      FunctionName: functionName,
      Environment: {
        Variables: updatedEnvVars,
      },
      Timeout: functionDef?.timeout,
    };

    logger.info(`Restarting AWS Lambda function ${functionName}`);

    return retry({
      command: () => lambdaClient.updateFunctionConfiguration(lambdaParams),
    });
  }

  // Note, this will fail in a browser environment because there are no
  // permissions for the getFunctionConfiguration.
  public async getLambdaEnvVars(params: {
    functionName: string;
    lambdaClient: Lambda;
  }): Promise<any> {
    const { functionName, lambdaClient } = params;

    const lambdaParams = {
      FunctionName: functionName,
    };

    const lambdaResult = await retry({
      command: () => lambdaClient.getFunctionConfiguration(lambdaParams),
    });

    return lambdaResult.Environment.Variables;
  }
}
