import { Modal } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { compose } from 'redux';

import { withActions } from '../widgetEngine/ActionEnabler';
import Container from '../widgetEngine/Container';

const styles = () => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '10%',
    outline: 'none',
  },
});

class ModalWidget extends Container {
  constructor(props) {
    super(props);
    this.handleClickAway = this.handleClickAway.bind(this);
  }

  handleClickAway() {
    const { name, actionReference } = this.props;
    const { closingModal } = this.props.passThroughProps;
    const { close } = actionReference;
    if (closingModal) {
      // FIXME - this needs to be called on the normal modal close as well, see WORM-3522
      closingModal();
    }
    close(name);
  }

  render() {
    const { classes } = this.props;

    const Children = this.generateChildren();
    const { openingModal } = this.props.passThroughProps;
    if (openingModal) {
      openingModal();
    }
    return (
      <Modal
        key="modalWidget"
        onClose={this.handleClickAway}
        open={true}
        className={classes.modal}
      >
        <div
          style={{
            maxHeight: window.innerHeight,
            maxWidth: window.innerWidth,
            outline: 'none',
          }}
        >
          {Children}
        </div>
      </Modal>
    );
  }
}

export default compose(
  withActions,
  withStyles(styles, { withTheme: true }),
)(ModalWidget);
