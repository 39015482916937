import { Component } from 'react';
import { getLogger, Loggers } from 'universal/loggerSupport';

import { previewWidget } from '../../util/previewWidget';
import { compileProperty } from '../../util/widgetUtilities';

import Widget from './Widget';

const logger = getLogger({ name: Loggers.CLIENT });

// FIXME - this one is going away per Trevor
export default class Container<Type = void> extends Component<Type> {
  public declare props;
  public generateChildren(childWidgets = this.props.childWidgets, options?) {
    const {
      context: { childContext },
      passThroughProps,
    } = this.props;
    const Children = [];
    childWidgets.forEach((child) => {
      const duplicateBy = compileProperty(
        'duplicateBy',
        child.properties.duplicateBy,
        childContext,
      );
      const { duplicateBy: matchDuplicateBy } = child.matchProperties;
      const { instanceName } = child.matchProperties;

      if (duplicateBy && Array.isArray(duplicateBy)) {
        duplicateBy.forEach((instance, index) => {
          const nodeName = instanceName
            ? `_${instanceName}_${index}`
            : `_${index}`;
          Children.push(
            <Widget
              {...options}
              component={child}
              passThroughProps={passThroughProps}
              index={index}
              aliases={this.props.aliases.createDescendent({ nodeName })}
              key={`${child.widgetId}-${index}`}
            />,
          );
        });
      } else {
        if (matchDuplicateBy) {
          logger.warn(
            `in ${this.props.name} a duplicateBy property ${matchDuplicateBy} was defined but was not found in context`,
          );
          return;
        }
        Children.push(
          <Widget
            {...options}
            component={child}
            passThroughProps={passThroughProps}
            aliases={this.props.aliases}
            key={child.widgetId}
          />,
        );
      }
    });
    return Children;
  }

  previewChildren(childWidgets = this.props.childWidgets) {
    const {
      context: { childContext },
      passThroughProps,
      widgetPath,
    } = this.props;
    const newContext = Object.assign({}, childContext);
    const Children = [];
    childWidgets.forEach((child) => {
      const duplicateBy = compileProperty(
        'duplicateBy',
        child.properties.duplicateBy,
        childContext,
      );
      const { duplicateBy: matchDuplicateBy } = child.matchProperties;
      const { instanceName } = child.matchProperties;

      if (duplicateBy && Array.isArray(duplicateBy)) {
        duplicateBy.forEach((instance, index) => {
          const previewContext = Object.assign({}, newContext, {
            [instanceName]: instance,
          });

          Children.push(
            previewWidget({
              component: child,
              context: previewContext,
              passThroughProps,
              index,
            }),
          );
        });
      } else {
        if (matchDuplicateBy) {
          logger.warn(
            `in ${this.props.name} a duplicateBy property ${matchDuplicateBy} was defined but was not found in context`,
          );
          return;
        }
        Children.push(
          previewWidget({
            widgetPath,
            component: child,
            context: newContext,
            passThroughProps,
          }),
        );
      }
    });
    return Children;
  }
}
