import { Collapse, Icon } from '@mui/material';
import { useCallback, useState } from 'react';

export default function Collapsable(props) {
  const [open, setOpen] = useState(props.defaultOpen);

  const toggleOpen = useCallback(() => {
    setOpen((currentlyOpen) => !currentlyOpen);
  }, []);

  const icon = open ? (
    <Icon color="secondary">expand_less</Icon>
  ) : (
    <Icon color="secondary">expand_more</Icon>
  );

  const { collapseItem } = props;
  return (
    <div>
      {collapseItem(open, icon, toggleOpen)}
      <Collapse in={open}>{props.children}</Collapse>
    </div>
  );
}
