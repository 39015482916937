import { process } from '@progress/kendo-data-query';
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import { Component } from 'react';

import HeaderDiv from './HeaderDiv';

class ClientDebugPanel extends Component {
  public declare props;
  public declare state;
  constructor(props) {
    super(props);
    this.dataStateChange = this.dataStateChange.bind(this);
    this.state = {
      dataState: {
        sort: [{ field: 'updates', dir: 'desc' }],
      },
    };
  }

  dataStateChange(event) {
    this.setState({ dataState: event.dataState });
  }

  render() {
    const { logs = {} } = this.props;
    const { dataState } = this.state;
    const logRows = Object.entries(logs).map(([name, log]) => {
      return {
        name,
        ...(log as any),
      };
    });

    return (
      <HeaderDiv header="Widget Debugger">
        <Grid
          sortable
          filterable
          reorderable
          data={process(logRows, dataState)}
          onDataStateChange={this.dataStateChange}
          {...dataState}
        >
          <GridColumn
            title="Store updates per widget"
            width="300px"
            sortable={false}
            filterable={false}
          >
            <GridColumn title="Name" field="name" width={300} />
            <GridColumn title="Store Updates" field="updates" width={130} />
          </GridColumn>
        </Grid>
      </HeaderDiv>
    );
  }
}

export function ClientDebugPanelWrapper(props) {
  const { children, logs } = props;
  return (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: 'auto 450px',
        height: '100%',
      }}
    >
      <div style={{ gridColumn: '1/2' }}>{children}</div>
      <div style={{ gridColumn: '2/3', paddingTop: '48px' }}>
        <ClientDebugPanel logs={logs} />
      </div>
    </div>
  );
}
