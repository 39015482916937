import _ from 'lodash';

export type WidgetPath = string[];

export interface IAliases {
  [key: string]: IAliasPath | IData;
}

type ResolvedAlias = SubscribePath | IData;

interface IAliasPath {
  subscribePath: SubscribePath;
}

export type SubscribePath = Array<string | number>;

export interface IData {
  data: any;
}

export class ContextMap {
  public widgetName: string;
  public parentAliases: ContextMap;
  public widgetPath: WidgetPath;
  public aliases: IAliases;
  public notes?: any;

  constructor(params: {
    parentAliases?: ContextMap;
    aliases?: IAliases;
    nodeName: string;
    subscribePaths?: { [key: string]: SubscribePath };
    notes?: any;
  }) {
    const {
      parentAliases,
      aliases = {},
      nodeName,
      subscribePaths,
      notes,
    } = params;

    this.widgetName = nodeName;
    this.widgetPath = parentAliases?.widgetPath?.concat(nodeName) || [nodeName];
    this.parentAliases = parentAliases;
    this.aliases = aliases;
    this.notes = notes;

    if (subscribePaths) {
      this.addSubscribePaths(subscribePaths);
    }
  }

  public static create(params: {
    nodeName: string;
    aliases?: IAliases;
    subscribePaths?: { [key: string]: SubscribePath };
  }) {
    return new ContextMap(params);
  }

  public createDescendent(params: {
    nodeName: string;
    aliases?: IAliases;
    subscribePaths?: { [key: string]: SubscribePath };
    notes?: any;
  }) {
    return new ContextMap({ ...params, parentAliases: this });
  }

  public addAliases(aliases: IAliases) {
    Object.assign(this.aliases, aliases);
  }

  public addSubscribePaths(subscribeFields: { [key: string]: SubscribePath }) {
    Object.entries(subscribeFields).forEach(([key, subscribePath]) =>
      Object.assign(this.aliases, { [key]: { subscribePath } }),
    );
  }

  public addData(dataEntry: { [key: string]: any }) {
    Object.entries(dataEntry).forEach(([key, data]) =>
      Object.assign(this.aliases, { [key]: { data } }),
    );
  }
}

export function toPathDeep(rawPath) {
  return _.toPath(rawPath).reduce((acc, entry) => {
    return acc.concat(_.toPath(entry));
  }, []);
}

export function resolveRawPath(
  rawPath: string,
  aliases: ContextMap,
): {
  path: ResolvedAlias;
  aliases: ContextMap;
} {
  const path = _.toPath(rawPath);

  const { path: resolvedPath, aliases: perspectiveAliases } = resolvePath(
    path,
    aliases,
  );

  return {
    path: resolvedPath,
    aliases: perspectiveAliases,
  };
}

export function resolvePath(
  path: SubscribePath,
  aliases: ContextMap,
): {
  path: ResolvedAlias;
  aliases: ContextMap;
} {
  let perspectiveAliases = aliases;

  // if the path contains a #widgetName
  if (path.length && path[0][0] === '#') {
    const widgetName = path.shift() as string;
    perspectiveAliases = findAliasPerspective(aliases, widgetName);
  }

  const resolvedPath = resolveAliases(path, perspectiveAliases);

  // if it resolves a path with a widget at the start, it needs to reresolve from that widget's perspective
  // to find the same data that widget would receive given the same path
  if (
    !(resolvedPath as IData).data &&
    (resolvedPath as SubscribePath).length &&
    resolvedPath[0][0] === '#'
  ) {
    return resolvePath(resolvedPath as SubscribePath, perspectiveAliases);
  }

  return {
    path: resolvedPath,
    aliases: perspectiveAliases,
  };
}

export function resolveGlobalAddress(path: SubscribePath, localNode?: string) {
  const address = localNode ? path.concat(localNode) : path.slice();
  address.splice(0, 1, (path[0] as string).substring(1));
  return address;
}

export function findAliasPerspective(
  aliases: ContextMap,
  widgetName: string,
  noThrow?: boolean,
) {
  if (!aliases) {
    if (!noThrow) {
      throw new Error(
        `find alias perspective called without aliases, widgetName: ${widgetName}. Did you forget to wrap a string starting with # or / in quotes?`,
      );
    } else {
      return undefined;
    }
  }
  if (aliases.widgetName === widgetName) {
    return aliases;
  }
  return findAliasPerspective(aliases.parentAliases, widgetName, noThrow);
}

// resolves the aliases of an array path
export function resolveAliases(
  path: SubscribePath,
  aliases: ContextMap,
): ResolvedAlias {
  const resolvedPath = resolveAliasGeneration(path, aliases);
  if (aliases && aliases.parentAliases && !(resolvedPath as IData).data) {
    return resolveAliases(resolvedPath as SubscribePath, aliases.parentAliases);
  }
  return resolvedPath;
}

// resolves all aliases at a single generation
function resolveAliasGeneration(
  path: SubscribePath,
  aliases: ContextMap,
  safety = 0,
): ResolvedAlias {
  if (safety > 20) {
    throw new Error(
      `resolve alias seems to be looping, check that you dont have circular aliases that resolve to each other ${JSON.stringify(
        aliases,
        null,
        2,
      )}`,
    );
  }
  const matchingAlias = aliases?.aliases?.[path[0]];
  if (matchingAlias) {
    if ((matchingAlias as IAliasPath).subscribePath) {
      const aliasPath = (matchingAlias as IAliasPath).subscribePath;
      const resolvedPath = [..._.toPath(aliasPath), ...path.slice(1)];
      return resolveAliasGeneration(resolvedPath, aliases, safety + 1);
    } else if ((matchingAlias as IData).data) {
      // if it has real data, it extracts it using the current path, and returns it.
      const resolvedData = _.get(matchingAlias as IData, [
        'data',
        ...path.slice(1),
      ]);
      return { data: resolvedData };
    }
  }
  return path;
}

export function hasAlias(alias, aliases) {
  if (aliases?.aliases && aliases.aliases[alias]) {
    return true;
  }
  if (aliases?.parentAliases) {
    return hasAlias(alias, aliases.parentAliases);
  }
  return false;
}
