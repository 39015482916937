import { getLogger, Loggers } from '../loggerSupport';

import { analyzeError, getErrorString } from './errorString';

const logger = getLogger({ name: Loggers.ERRORS });

export interface IRetryReturn {
  // Don't retry no matter what
  stopRetrying?: boolean;

  // Always retry no matter what
  forceRetry?: boolean;
}

export type RetryFunction = (
  error: Error,
  context?: IRetryContext,
) => Promise<IRetryReturn>;

const retryableErrors = [
  'client disconnecting',
  'enotfound',
  'timeouterror',
  'enotconn',
  'econnreset',
  'econnrefused',
  'econnaborted',
  'enetdown',
  'enetreset',
  'ehostdown',
  'ehostunreach',
  'etimedout',
  'slowdown',
  'global rate limit exceeded',
  'connection closed',
  'throttlingexception',
  'toomanyrequestsexception',
  'resourceconflictexception',
  'requested resource not found', // Seems to happen sometimes with dynamodb
  'network error',
  'networkerror',
  'socket disconnected',
  'socket timed out',
  'socket hang up',
  'internal error',
  'internal server error',
  'execution timed out',
  'bad gateway',
  '403 error making request get https://api.github.com',
  'security token included',
  'too_many_attempts',
  'accessdenied: not auth',
  'unprocessable entity',
  'invalididentitytoken:',
  'accessdeniedexception: the role defined for the function cannot be assumed by lambda',
  'is currently being updated by another request', // smartsheets
  "cannot read property 'status' of undefined",
  'request failed with status code 403',
  'status code 504', // FIXME: Properly check the status. See executePipelineRemote
  'signal: killed',
  'provisionedthroughputexceededexception',
];
const notFoundErrors = ['404'];

const silentErrors = [
  // Auth0 throttling
  'invalididentitytoken',
];

const notRetryableErrors = [
  // 'resourcenotfoundexception: requested resource not found',
  'payload content length greater than maximum allowed',
  'response not successful',
  'exceeded maximum allowed payload size',
  'context creation failed: apollohandler: error resolving user',
];

export function getErrorStatusCode(error: any): number {
  if (error.statusCode) {
    return error.statusCode;
  } else if (error.networkError && error.networkError.statusCode) {
    return error.networkError.statusCode;
  } else {
    const matchResult = getErrorString(error).match(/status code ([0-9]{3})/i);

    if (matchResult) {
      return Number(matchResult[1]);
    } else {
      return 0;
    }
  }

  return 0;
}

function isApiGatewayRetryable(error): { retryable?: boolean } {
  const statusCode = getErrorStatusCode(error);

  // 500 could be an API Gateway error in response to a 429 (rate limit) from Lambda
  // See: https://docs.aws.amazon.com/apigateway/api-reference/handling-errors/
  if ((statusCode >= 500 && statusCode <= 504) || statusCode === 429) {
    return { retryable: true };
  }
  return {};
}

export function isNotFoundError(error: Error): boolean {
  const errorString = getErrorString(error).toLowerCase();

  return !!notFoundErrors.find((e) => errorString.includes(e));
}

export function isRetryableError(params: {
  error: any;
  retryContext?: IRetryContext;
  retryNotFound?: boolean;
}): boolean {
  const { error, retryContext = {}, retryNotFound } = params;

  if (!error || error.noRetry) {
    return false;
  }

  // DynamoDB seems to do this every now and then. We can't tell if it's real or not, so let's
  // only retry a couple of times in case it is real. S3 sometimes returns a 403 right after the
  // stack is created.
  if (
    ((error.name === 'ResourceNotFoundException' && error.statusCode === 400) ||
      error.statusCode === 403) &&
    !error.retryable
  ) {
    if (retryContext.tryTimes > 2) {
      retryContext.tryTimes = 2;
    }
    return true;
  }
  if (error.retryable) {
    return true;
  }
  const errorString = getErrorString(error).toLowerCase();
  const notRetryableError = notRetryableErrors.find((e) =>
    errorString.includes(e),
  );
  const statusCode = getErrorStatusCode(error);
  if (
    notRetryableError ||
    statusCode === 400 ||
    // Payload too large
    statusCode === 413 ||
    (statusCode === 404 && !retryNotFound)
  ) {
    return false;
  }

  const apiGatewayRetryable = isApiGatewayRetryable(error);
  if (apiGatewayRetryable.retryable) {
    return apiGatewayRetryable.retryable;
  }

  if (retryContext) {
    retryContext.silent = !!silentErrors.find((e) => errorString.includes(e));
  }
  const localRetryableErrors = retryNotFound
    ? retryableErrors.concat(notFoundErrors)
    : retryableErrors;
  const retryableError = !!localRetryableErrors.find((e) =>
    errorString.includes(e),
  );
  return retryableError;
}

export interface IRetryContext {
  startTime?: number;
  tryTimes?: number;
  retriedTimes?: number;
  silent?: boolean;
  error?: any;
  lastSleepTimeMs?: number;
}

export function getRetryContext(tryTimes?): IRetryContext {
  return {
    startTime: Date.now(),
    tryTimes,
    retriedTimes: 0,
  };
}

export const RETRY_SHORT = 2000;
export const RETRY_LONG = 15000;

interface IRetryParams {
  command;
  tryTimes?: number;
  timeoutMs?: number;
  // FIXME - this is not hooked up, so all errors are logged now (and this is used by some code)
  logErrors?: boolean;
  suppressErrorLogging?: boolean;
  // Retry for a 404 not found (normally not done)
  retryNotFound?: boolean;
  // Called before a retry would happen to determine whether to do the retry
  retryFunction?: RetryFunction;
  // Called after a normal execution to allow a retry to be attempted
  shouldRetry?: (result) => boolean;
  functionName?: string;
}

// -1 on requestedRetryDelay is exponential backoff starting from 1 sec
export async function retry<T = any>(params: IRetryParams): Promise<T> {
  const {
    command,
    tryTimes = 5,
    timeoutMs = 0,
    shouldRetry,
    functionName = '',
  } = params;

  const retryContext = getRetryContext(tryTimes);
  let result;
  while (true) {
    try {
      if (timeoutMs) {
        result = await handleTimeout({ retryContext, ...params });
        if (result === undefined) {
          continue;
        }
      } else {
        result = await command();
      }

      if (shouldRetry && shouldRetry(result)) {
        await retryCatch({
          retryContext,
          ...params,
          retryFunction: async () => ({ forceRetry: true }),
        });
        continue;
      }

      if (retryContext.retriedTimes > 0) {
        const message = `RETRY ${functionName} succeeded after ${
          retryContext.retriedTimes
        } retry - total time: ${Date.now() - retryContext.startTime}`;
        logger.warn(message);
      }
      break;
    } catch (error) {
      await retryCatch({
        error,
        retryContext,
        ...params,
      });
    }
  }
  return result;
}

// Returns the command function result if things worked out
async function handleTimeout(
  params: IRetryParams & { retryContext: IRetryContext },
) {
  const { command, timeoutMs } = params;

  const startTime = Date.now();
  let timeoutHappened;
  let timeout;

  const timeoutPromise = new Promise<void>((resolve) => {
    timeout = setTimeout(() => {
      timeoutHappened = true;
      resolve();
    }, timeoutMs);
  });

  const promiseResults = await Promise.race([command(), timeoutPromise]);

  if (timeoutHappened) {
    const message = `Timed out after ${Date.now() - startTime}ms (might retry)`;
    const error = new Error(message);
    (error as any).retryable = true;
    (error as any).timeout = true;
    await retryCatch({
      error,
      ...params,
    });
    // Cause retry
    return undefined;
  }

  clearTimeout(timeout);
  return promiseResults;
}

async function retryCatch(
  params: IRetryParams & {
    error?: any;
    retryContext: IRetryContext;
  },
) {
  const {
    error,
    retryContext,
    retryNotFound,
    retryFunction,
    functionName,
    suppressErrorLogging,
  } = params;

  if (error) {
    analyzeError({ error });
  }

  // Retry on network and other problems
  let forceRetry;
  if (retryFunction) {
    const retryReturn = await retryFunction(error, retryContext);
    if (retryReturn?.stopRetrying) {
      logger.error(`Retry ${functionName} stopped per retryFunction`);
      throw error;
    }
    forceRetry = retryReturn?.forceRetry;
  }

  retryContext.error = error;
  if (forceRetry || isRetryableError({ error, retryContext, retryNotFound })) {
    retryContext.retriedTimes++;
    // Don't show the word "error" in this case so as to not trigger log attention
    if (!retryContext.silent && !suppressErrorLogging) {
      const errorNoStack = Object.assign({}, error);
      // For some reason Object.assign does not pick up the message
      errorNoStack.message = error?.message || 'Retry because of shouldRetry()';
      delete errorNoStack.stack;
      delete errorNoStack.retryable;
      delete errorNoStack.timeout;

      const message = `RETRY - ${functionName} retryable problem (retry #${
        retryContext.retriedTimes
      } last sleep (${retryContext.lastSleepTimeMs}): ${getErrorString(
        // FIXME2 - was errorNoStack, bugcatcher
        error,
      ).replace(/error/g, 'err')}`;
      logger.warn(message);
    }
    retryContext.tryTimes--;
    if (retryContext.tryTimes === 0) {
      if (error) {
        logger.error(
          `RETRY - ${functionName} giving up: ${getErrorString(error)}`,
        );
        throw error;
      }
      throw new Error(
        `RETRY - ${functionName} giving up, no error provided (shouldRetry() used)`,
      );
    }
    if (!(error as any)?.timeout) {
      retryContext.lastSleepTimeMs = getRetryMs(retryContext.retriedTimes);
      await new Promise((resolve) =>
        setTimeout(resolve, retryContext.lastSleepTimeMs),
      );
    }
    return;
  }
  throw error;
}

export function getRetryMs(retriedTimes: number) {
  // Exponential with full jitter
  return Math.random() * retriedTimes ** 2 * 1000;
}
