import CircularProgress from '@mui/material/CircularProgress';

function Loading() {
  return (
    <div
      style={{
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        textAlign: 'center',
      }}
    >
      <div style={{ flex: 1 }}>
        <CircularProgress
          sx={{
            height: '100%',
            align: 'center',
            outline: 'none',
          }}
          size={80}
          id="loading"
        />
      </div>
    </div>
  );
}

export default Loading;
