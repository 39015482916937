import { useRef, useState } from 'react';
import { InputAction, default as ReactSelect, components } from 'react-select';

export type Option = {
  value: number | string;
  label: string;
};

const ReactMultiSelect = (props) => {
  const [selectInput, setSelectInput] = useState<string>('');
  const isAllSelected = useRef<boolean>(false);
  const selectAllLabel = useRef<string>('Select All');
  const allOption = { value: '*', label: selectAllLabel.current };

  const filterOptions = (options: Option[], input: string) => {
    return options?.filter(({ label }: Option) => {
      return label.toLowerCase().includes(input.toLowerCase());
    });
  };
  const comparator = (v1: Option, v2: Option) =>
    (v1.value as number) - (v2.value as number);

  const filteredOptions = filterOptions(props.options, selectInput);
  const filteredSelectedOptions = filterOptions(props.value, selectInput);

  const Option = (oprops) => {
    return (
      <components.Option {...oprops}>
        {oprops.value === '*' &&
        !isAllSelected.current &&
        filteredSelectedOptions?.length > 0 ? (
          <input
            key={oprops.value}
            type="checkbox"
            ref={(input) => {
              if (input) input.indeterminate = true;
            }}
          />
        ) : (
          <input
            key={oprops.value}
            type="checkbox"
            checked={oprops.isSelected || isAllSelected.current}
            onChange={() => {}}
          />
        )}
        <label style={{ marginLeft: '5px' }}>{oprops.label}</label>
      </components.Option>
    );
  };

  const Input = (iprops: any) => {
    return (
      <>
        {selectInput.length === 0 ? (
          <components.Input
            autoFocus={iprops.selectProps.menuIsOpen}
            {...iprops}
          >
            {iprops.children}
          </components.Input>
        ) : (
          <div style={{ border: '1px dotted gray' }}>
            <components.Input
              autoFocus={iprops.selectProps.menuIsOpen}
              {...iprops}
            >
              {iprops.children}
            </components.Input>
          </div>
        )}
      </>
    );
  };

  const customFilterOption = ({ value, label }: Option, input: string) =>
    (value !== '*' && label.toLowerCase().includes(input.toLowerCase())) ||
    (value === '*' && filteredOptions?.length > 0);

  const onInputChange = (
    inputValue: string,
    event: { action: InputAction },
  ) => {
    if (event.action === 'input-change') setSelectInput(inputValue);
    else if (event.action === 'menu-close' && selectInput !== '')
      setSelectInput('');
  };

  const onKeyDown = (e: React.KeyboardEvent<HTMLElement>) => {
    if ((e.key === ' ' || e.key === 'Enter') && !selectInput)
      e.preventDefault();
  };

  const handleChange = (selected: Option[]) => {
    if (
      selected.length > 0 &&
      !isAllSelected.current &&
      (selected[selected.length - 1].value === allOption.value ||
        JSON.stringify(filteredOptions) ===
          JSON.stringify(selected.sort(comparator)))
    )
      return props.onChange(
        [
          ...(props.value ?? []),
          ...props.options.filter(
            ({ label }: Option) =>
              label.toLowerCase().includes(selectInput?.toLowerCase()) &&
              (props.value ?? []).filter((opt: Option) => opt.label === label)
                .length === 0,
          ),
        ].sort(comparator),
      );
    else if (
      selected.length > 0 &&
      selected[selected.length - 1].value !== allOption.value &&
      JSON.stringify(selected.sort(comparator)) !==
        JSON.stringify(filteredOptions)
    )
      return props.onChange(selected);
    else {
      return props.onChange([
        ...props.value.filter(
          ({ label }: Option) =>
            !label.toLowerCase().includes(selectInput?.toLowerCase()),
        ),
      ]);
    }
  };

  const customStyles = {
    multiValueLabel: (def: any) => ({
      ...def,
      backgroundColor: 'lightgray',
    }),
    multiValueRemove: (def: any) => ({
      ...def,
      backgroundColor: 'lightgray',
    }),
    valueContainer: (base: any) => ({
      ...base,
      maxHeight: '65px',
      overflow: 'auto',
    }),
    option: (styles: any, { isSelected, isFocused }: any) => {
      return {
        ...styles,
        fontFamily: 'Roboto, Helvetica, Arial',
        color: 'rgba(0,0,0)',
        fontSize: '14px',
        backgroundColor:
          isSelected && !isFocused
            ? null
            : isFocused && !isSelected
              ? styles.backgroundColor
              : isFocused && isSelected
                ? '#DEEBFF'
                : null,
      };
    },
    menuPortal: (def: any) => ({ ...def, zIndex: 9999 }),
  };
  if (props.isSelectAll && props.options.length !== 0) {
    isAllSelected.current =
      JSON.stringify(filteredSelectedOptions) ===
      JSON.stringify(filteredOptions);

    if (filteredSelectedOptions?.length > 0) {
      if (filteredSelectedOptions?.length === filteredOptions?.length)
        selectAllLabel.current = `All (${filteredOptions.length}) selected`;
      else
        selectAllLabel.current = `${filteredSelectedOptions?.length} / ${filteredOptions.length} selected`;
    } else selectAllLabel.current = 'Select All';

    allOption.label = selectAllLabel.current;
  }

  return (
    <ReactSelect
      {...props}
      inputValue={selectInput}
      onInputChange={onInputChange}
      onKeyDown={onKeyDown}
      options={
        props.isSelectAll ? [allOption, ...props.options] : props.options
      }
      onChange={handleChange}
      components={{
        Option: Option,
        Input: Input,
        ...props.components,
      }}
      filterOption={customFilterOption}
      menuPlacement="bottom"
      menuPortalTarget={document.body}
      menuPosition={'fixed'}
      styles={customStyles}
      isMulti
      closeMenuOnSelect={false}
      tabSelectsValue={false}
      backspaceRemovesValue={false}
      hideSelectedOptions={false}
      blurInputOnSelect={false}
    />
  );
};

export default ReactMultiSelect;
