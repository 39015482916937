import makeStyles from '@mui/styles/makeStyles';
import { DataResult } from '@progress/kendo-data-query/dist/npm/data-result.interface';
import { State } from '@progress/kendo-data-query/dist/npm/state';
import { GridToolbar } from '@progress/kendo-react-grid';
import '@progress/kendo-theme-material/dist/all.css';
import { produce } from 'immer';
import _ from 'lodash';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
// FIXME - appears to be a bug in import/order

import ReactDOM from 'react-dom';
// replace this with official react hook when its available
// https://github.com/reactjs/rfcs/pull/119

import { exists } from 'universal/common/commonUtilities';
import { getLogger, Loggers, LogLevels } from 'universal/loggerSupport';
import { createContext as createSelectorContext } from 'use-context-selector';

import { hasAlias, resolvePath } from '../../../store/context';
import {
  createWidgetFromType,
  createWidgetPropertyConfigurationHandler,
} from '../../../util/createUtilities';
import {
  getChildPropertyFields,
  getNestedConfigFields,
  getPropertyFields,
} from '../../../util/extractSubscribeFields';
import { generateChildren } from '../../../util/generateChildren';
import { typographyTranslate } from '../../../util/typographyTranslate';
import { ClientContext } from '../../../wrappers/ClientContext';
// import TestSafeAutosizer from '../../../wrappers/TestSafeAutosizer';
import TestSafeAutosizer from '../../../wrappers/TestSafeAutosizer';
import Loading from '../../atoms/Loading';
import { withActions } from '../../widgetEngine/ActionEnabler';
import { IWidgetTree } from '../types';

import {
  addAggregationToDataState,
  addColumnsFromEntityType,
  buildColumns,
  buildDataStateFromProps,
  extractConfigurationFromColumns,
  processColumns,
  processRows,
  StyledGrid,
} from './KendoGridUtilities';
import { KendoGridProps } from './KendoTypes';

const logger = getLogger({ name: Loggers.CLIENT });

interface IState {
  // to detect a change in the group prop
  groups: string;
  dataState: State;
  scrolling: boolean;
}
interface IGridStatus {
  state: IState;
  result: DataResult;
  metaColumns: any[];
  kendoAggregates: IWidgetTree[];
  rows: any[];
}

export const GridStatus = createSelectorContext<IGridStatus>(null);

type IProps = KendoGridProps;

const buildVirtualizedDataState = (props) => ({
  ...(props.scrollable === 'virtual' && {
    take: props.pageSize,
    skip: 0,
  }),
});

// tslint:disable-next-line:no-shadowed-variable
const KendoGrid: React.FC<React.PropsWithChildren<IProps>> = (props) => {
  if (logger.isLevelEnabled(LogLevels.Debug)) {
    logger.debug(`KendoGrid - render ${props.aliases?.widgetPath.toString()}`);
  }

  const [state, setState] = useState<IState>({
    groups: props.groups,
    dataState: {
      ...buildDataStateFromProps(props),
      ...buildVirtualizedDataState(props),
    },
    scrolling: false,
  });

  if (props.groups !== state.groups) {
    setState((prevState) => ({
      ...prevState,
      groups: props.groups,
      dataState: {
        ...buildDataStateFromProps(props),
        ...buildVirtualizedDataState(props),
      },
    }));
  }

  const _columns = useRef(null);
  const _scrollTimer = useRef(null);
  const _rowCountAtPage = useRef(null);

  const {
    rows: actualRows,
    groupable,
    sortable,
    filterable,
    allowUnsort,
    sortMode,
    scrollable,
    rowHeight,
    rowHeightForMaxCalculation,
    maxRows,
    pageSize,
    reorderable,
    resizable,
    groupFooters,
    component: {
      matchProperties,
      kendoAggregates,
      kendoColumnTemplates: templates,
    },
    context: { columnContext, toolbarContext },
    dynamicColumns,
    dynamicColumnsInstanceName,
    aliases,
    passThroughProps,
    rowInstanceName,
    headerHeight,
    footerHeight,
    getPermission,
    component,
    oddRowColor,
    evenRowColor,
    hoverRowColor,
    groupRowColor,
    filterRowColor,
    headerColor,
    lockedHeaderColor,
    groupingHeaderColor,
    padding,
    paddingSides,
    cellBorder,
    cellBorderWidth,
    cellBorderColor,
    headerBorder,
    headerBorderWidth,
    headerBorderColor,
    debounceCells,
    paged,
    pageForward,
  } = props;

  const { clientManager } = useContext(ClientContext);
  const classes = useStyles();

  const rows = useMemo(() => actualRows || [], [actualRows]);

  const dataStateChange = (event) =>
    setState((draftState) => ({ ...draftState, dataState: event.dataState }));

  const expandChange = (event) => {
    event.dataItem[event.target.props.expandField] = event.value;
    setState((st: any) => ({
      ...st,
      result: Object.assign({}, st.result),
      dataState: st.dataState,
    }));
  };

  const pageChange = (event) => {
    setState((st) =>
      produce(st, (newState) => {
        newState.dataState.skip = event.page.skip;
      }),
    );
  };

  const onScroll = (event) => {
    // modals spawned by cells will trigger this onScroll
    // the conditional below is to be sure the grid triggered the scroll event
    if (event.nativeEvent.target.className.includes('k-grid-content')) {
      setState((st) => {
        if (st.scrolling) {
          return st;
        }
        return { ...st, scrolling: true };
      });
      if (_scrollTimer.current) {
        clearTimeout(_scrollTimer.current);
      }
      _scrollTimer.current = window.setTimeout(() => {
        setState((st) => {
          if (st.scrolling) {
            return { ...st, scrolling: false };
          }
          return st;
        });
        _scrollTimer.current = null;
      }, 1000);
    }
  };

  const formPath = useMemo(
    () =>
      hasAlias('form', aliases) ? resolvePath(['form'], aliases).path : null,
    [aliases],
  );

  const { dynamicColumns: dynamicColumnField, rows: rowGetter } =
    matchProperties;

  const enrichedColumns = useMemo(() => {
    return addColumnsFromEntityType(clientManager, props);
  }, [clientManager, props]);

  const metaColumns = useMemo(() => {
    const newMetaColumns = processColumns(
      columnContext,
      enrichedColumns,
      templates,
      {
        dynamicColumnField,
        dynamicColumns,
        dynamicColumnsInstanceName,
      },
    );

    newMetaColumns.sort((e1, e2) => {
      const e1Order = e1.compiledProperties.order;
      const e2Order = e2.compiledProperties.order;
      if (!exists(e1Order) && !exists(e2Order)) {
        return 0;
      }
      return e1Order < e2Order ? -1 : e1Order > e2Order ? 1 : 0;
    });
    _columns.current = newMetaColumns;

    return newMetaColumns;
  }, [
    columnContext,
    enrichedColumns,
    templates,
    dynamicColumnField,
    dynamicColumns,
    dynamicColumnsInstanceName,
  ]);

  const { mergableColumnFields, columnFieldAggregates } = useMemo(
    () => extractConfigurationFromColumns(metaColumns),
    [metaColumns],
  );

  const { dataState } = state;

  // if its paged, it removes all processing params beyond take and skip
  // if not, it adds the aggregations to all group levels
  const processedDataState = useMemo(() => {
    if (paged) {
      // tslint:disable-next-line:no-shadowed-variable
      const { take, skip } = dataState as any;
      return { take, skip };
    }

    return addAggregationToDataState(
      kendoAggregates,
      columnFieldAggregates,
      dataState,
    );
  }, [dataState, kendoAggregates, columnFieldAggregates, paged]);

  const columns = useMemo(() => {
    return buildColumns(
      formPath,
      metaColumns,
      rows,
      aliases,
      passThroughProps,
      rowInstanceName,
      rowGetter,
      classes,
      rowHeight,
      footerHeight,
      getPermission,
      clientManager,
    );
  }, [
    formPath,
    metaColumns,
    rows,
    aliases,
    passThroughProps,
    rowInstanceName,
    rowGetter,
    classes,
    rowHeight,
    footerHeight,
    getPermission,
    clientManager,
  ]);

  // if paged, the rows will not have a source index
  // so rendering redux aware widgets is impossible
  const rowsWithSourceIndex = useMemo(() => {
    if (paged) {
      return rows;
    }
    return rows.map((row, sourceIndex) => {
      return { ...row, sourceIndex };
    });
  }, [rows, paged]);

  const result = useMemo(() => {
    return processRows(
      rowsWithSourceIndex,
      processedDataState,
      mergableColumnFields,
      scrollable,
      paged,
    );
  }, [
    rowsWithSourceIndex,
    processedDataState,
    mergableColumnFields,
    scrollable,
    paged,
  ]);

  const {
    dataState: { take, skip },
  } = state;

  useEffect(() => {
    if (paged) {
      const { total } = result;
      const expectedRowCount = take + skip;
      if (expectedRowCount > total && _rowCountAtPage.current < total) {
        _rowCountAtPage.current = total;
        void pageForward();
      }
    }
  }, [take, skip, paged, result, pageForward]);

  const { kendoToolbars } = component;

  const toolbars = kendoToolbars.map((toolbar, i) => {
    return (
      <GridToolbar key={i}>
        <div className={classes.toolbar}>
          {generateChildren({
            childWidgets: toolbar.children,
            aliases,
            childContext: toolbarContext,
            passThroughProps,
          })}
        </div>
      </GridToolbar>
    );
  });

  const gridFragment = (height: number, width) => (
    <React.Fragment>
      <GridStatus.Provider
        value={{ state, result, metaColumns, kendoAggregates, rows }}
      >
        <StyledGrid
          data={result.data}
          total={result.total}
          onDataStateChange={dataStateChange}
          onExpandChange={expandChange}
          style={{ height, width }}
          groupable={{
            enabled: groupable,
            footer: groupFooters,
          }}
          filterable={filterable}
          sortable={sortable && { allowUnsort, mode: sortMode }}
          expandField="expanded"
          reorderable={reorderable}
          resizable={resizable}
          scrollable={scrollable}
          onPageChange={pageChange}
          {...(rowHeight && { rowHeight })}
          {...processedDataState}
          {...(scrollable === 'virtual' && { pageSize })}
          {...(debounceCells && { onScroll })}
          {...{
            oddRowColor,
            evenRowColor,
            hoverRowColor,
            groupRowColor,
            filterRowColor,
            headerColor,
            lockedHeaderColor,
            groupingHeaderColor,
            padding,
            paddingSides,
            cellBorder,
            cellBorderWidth,
            cellBorderColor,
            headerBorder,
            headerBorderWidth,
            headerBorderColor,
          }}
        >
          {columns}
          {toolbars}
        </StyledGrid>
      </GridStatus.Provider>
      {!actualRows && <LoadingPanel />}
    </React.Fragment>
  );
  let totalWidth = columns.reduce((acc, cur) => acc + cur.props?.width, 0) + 20; //leave space for scroll bar
  let totalWidthNumber = Number.MAX_SAFE_INTEGER;
  if (isNaN(totalWidth)) {
    totalWidth = null;
  } else {
    totalWidthNumber = totalWidth;
    totalWidth += 'px';
  }
  // OK, this is kind of a gross hack to allow the grid to take only the space that it needs, up to a
  // maximum number of rows. In this case, the Autosizer is not used. There appears to be no way to
  // calculate the header and actual row heights, so they have to be specified manually. Hopefully
  // when moving to the Ag Grid we can find a better solution.
  if (
    maxRows !== undefined &&
    rowHeightForMaxCalculation !== undefined &&
    headerHeight !== undefined
  ) {
    const neededHeight =
      rowHeightForMaxCalculation * Math.min(rows.length, maxRows) +
      headerHeight;
    return gridFragment(neededHeight, totalWidth || '100%');
  } else {
    return (
      <TestSafeAutosizer>
        {({ width, height }) =>
          gridFragment(height, Math.min(totalWidthNumber, width))
        }
      </TestSafeAutosizer>
    );
  }
};

function LoadingPanel() {
  const loading = <Loading />;
  const gridContent = document && document.querySelector('.k-grid-content');
  return gridContent ? ReactDOM.createPortal(loading, gridContent) : loading;
}

function kendoGridSubscribeMapBuilder(component) {
  const nestedConfigContext = getNestedConfigFields(component, true);
  const { kendoToolbars, ...rest } = nestedConfigContext;
  const columnContext = _.uniq(_.flatten(Object.values(rest)));

  return {
    properties: getPropertyFields(component),
    childContext: getChildPropertyFields(component),
    columnContext: columnContext,
    toolbarContext: kendoToolbars,
  };
}

function kendoGridTransform(widget) {
  const defaultColumnTemplate = widget.kendoColumnTemplates.find(
    (template) => template.name === 'defaultTemplate',
  );
  if (!defaultColumnTemplate) {
    const defaultTemplate = createWidgetFromType({
      type: 'KendoColumn',
      name: 'defaultTemplate',
      properties: 'all',
    });

    widget.kendoColumnTemplates.push(defaultTemplate);
  }
  // adds all properties, even if they dont have defaults
  // so the templates can be completely compiled against
  // the source objects within dynamic columns
  widget.kendoColumnTemplates.forEach((template) => {
    const propertyHandler = createWidgetPropertyConfigurationHandler(template);
    propertyHandler.addAllProperties();
  });

  // Migrates the deprecated export boolean property on grid to a toolbar with export button
  const transformExport = true;

  if (transformExport) {
    const { properties, kendoToolbars } = widget;

    if (properties.excelExport) {
      if (!kendoToolbars.length) {
        kendoToolbars.push(
          createWidgetFromType({
            type: 'KendoToolbar',
            name: 'Export Toolbar',
            properties: 'default',
          }),
        );
      }

      const toolbar = kendoToolbars[0];
      const exportButton = createWidgetFromType({
        type: 'KendoExportButton',
        name: 'Export Button',
        properties: 'default',
      });
      exportButton.properties.fileName = properties.exportFileName;
      if (widget.exportConfig.length) {
        exportButton.exportConfig = widget.exportConfig;
        widget.exportConfig = [];
      }
      if (!toolbar.children) {
        toolbar.children = [];
      }
      toolbar.children.push(exportButton);
      properties.excelExport = false;
      properties.exportFileName = '';
    }
  }

  return widget;
}

const useStyles = makeStyles({
  ...Object.entries(typographyTranslate).reduce(
    (acc, [name, size]) => Object.assign(acc, { [name]: { fontSize: size } }),
    {},
  ),
  top: {
    verticalAlign: 'top',
  },
  middle: {
    verticalAlign: 'middle',
  },
  bottom: {
    verticalAlign: 'bottom',
  },
  toolbar: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    width: '100%',
  },
});

export default withActions(KendoGrid);
export { kendoGridSubscribeMapBuilder, kendoGridTransform };
