import gql from 'graphql-tag';

import { getErrorString } from '../errors/errorString';
import { getLogger, Loggers, LogLevels } from '../loggerSupport';
import {
  ActionType,
  FETCH_POLICY_NO_CACHE,
  IEntityType,
  IHasIdAndTypeName,
  UpdateType,
} from '../metadataSupportConstants';

import _ from 'lodash';
import { ConvertType } from './graphQLManager';
import {
  actionFromRemoteSubscriptionEventName,
  ISubscriptionMessage,
  makeRemoteSubscriptionEventName,
  remoteSubscriptionTypeToUpdateType,
} from './graphQLSupport';
import { PipelineManager } from './pipelineManager';

const logger = getLogger({ name: Loggers.PIPELINE_MANAGER });
const logNetwork = getLogger({
  name: Loggers.PIPELINE_MANAGER_NETWORK,
  level: LogLevels.Warn,
});

export interface INetworkMessage {
  data: IHasIdAndTypeName;
  objectToWrite: IHasIdAndTypeName;
  queryFieldName: string;
  queryType: UpdateType;
  action: ActionType;
  entityType: IEntityType;
  requestingClientId: string;
}

/*
 The NetworkProcessor handles subscription events. These are generated by the apolloHandler whenever a change is
 made to a given entity type. The topic of the subscription includes the entity type id. So for a given
 process, there is at most one NetworkProcessor for each entity type.
 */
export class NetworkProcessor {
  private readonly pipelineManager: PipelineManager;

  private readonly entityType: IEntityType;
  public subscription: any;

  constructor(manager: PipelineManager, entityType: IEntityType) {
    this.pipelineManager = manager;
    this.entityType = entityType;
  }

  private async processSubscriptionData(params: {
    data: any;
    fieldName: string;
    queryType: UpdateType;
    requestingClientId: string;
  }) {
    const { data, fieldName, queryType, requestingClientId } = params;
    const subData = data[fieldName];
    if (!subData) {
      logNetwork.debug(
        `Network message with no subscription data: ${this.entityType.id} - ignoring`,
      );
      return;
    }

    this.pipelineManager.metadataSupport.convertObjectReferences({
      obj: subData,
      typeDef: this.entityType.typeDefinitionObject,
      convertType: ConvertType.ID_TO_OBJECT_WITH_ONLY_ID,
    });
    const message: INetworkMessage = {
      data,
      objectToWrite: subData,
      queryFieldName: fieldName,
      queryType,
      action: actionFromRemoteSubscriptionEventName(fieldName),
      entityType: this.entityType,
      requestingClientId,
    };

    this.pipelineManager.graphQLManager.queueMessage(message);
  }

  public subscribe() {
    try {
      const subquery = gql(
        `subscription { ${this.entityType.unqualifiedId} { id }}`,
      );
      logNetwork.debug(
        `%%% network - subscribe to network query: ${this.entityType.id}`,
      );

      const { clientId } = this.pipelineManager.clientManager;
      const subscription = this.pipelineManager.apolloClient
        .subscribe({
          query: subquery,
          fetchPolicy: FETCH_POLICY_NO_CACHE,
        })
        .subscribe({
          next: (subscriptionMessage: ISubscriptionMessage) => {
            logNetwork.debug(
              subscriptionMessage.data,
              `%%% network received data ${this.entityType.id}:`,
            );

            // Ignore anything requested by us
            if (subscriptionMessage.requestingClientId === clientId) {
              return;
            }

            const updateType = remoteSubscriptionTypeToUpdateType(
              subscriptionMessage.action,
            );
            const entityId = this.entityType.unqualifiedId;
            const fieldName = makeRemoteSubscriptionEventName(
              entityId,
              subscriptionMessage.action,
            );

            const counters =
              this.pipelineManager.graphQLManager.getCounters(entityId);

            // The object from tne network is immutable, and we sometimes fix
            // it when going to the cache
            const subscriptionData = _.cloneDeep(subscriptionMessage.data);
            if (Array.isArray(subscriptionData)) {
              subscriptionData.forEach(
                (d) =>
                  void this.processSubscriptionData({
                    data: d,
                    fieldName,
                    queryType: updateType,
                    requestingClientId: subscriptionMessage.requestingClientId,
                  }),
              );
              counters.subscriptionNotificationsReceived +=
                subscriptionData.length;
            } else {
              counters.subscriptionNotificationsReceived += 1;
              void this.processSubscriptionData({
                data: subscriptionData,
                fieldName,
                queryType: updateType,
                requestingClientId: subscriptionMessage.requestingClientId,
              });
            }
          },
          error: (err: any) => {
            // No retry here since the apollo-link-retry will have already done this.
            const errorText = getErrorString(err);
            logNetwork.debug(`Network error: ${errorText}`);
            if (!errorText.includes('Socket closed')) {
              logger.error(`!!! ERROR on subscribeToMore: ${errorText}`);
            }
            void this.pipelineManager.graphQLManager.networkSubscriptionError();
          },
          complete: () => {
            logNetwork.debug(`%%% network complete: ${this.entityType.id}`);
          },
        });
      this.subscription = subscription;
    } catch (error) {
      const errorString = `Problem subscribing: ${
        this.entityType.id
      }: ${getErrorString(error)}`;
      logger.error(errorString);
      throw new Error(errorString);
    }
  }
}
