import produce from 'immer';
import { Fragment, useMemo } from 'react';

import { StasisFreezer } from '../../util/Stasis';
import { shouldRender } from '../../util/widgetUtilities';
import LoadingModal from '../molecules/LoadingModal';
import { UniversalWidgetProps } from '../widgets/types';

import Widget from './Widget';

const ActionEnabler: React.FC<React.PropsWithChildren<UniversalWidgetProps>> = (
  props,
) => {
  const {
    renderingActions = [],
    passThroughProps,
    aliases,
    overlays = [],
    renderer,
    children,
    render,
    preventRender,
    showLoading,
    showUpdating,
    showReloading,
    loading,
    reloading,
    updating,
  } = props;

  const actionComponents = useMemo(
    () =>
      renderingActions.map((action, index) => {
        const passThroughPropsWithClose = produce(
          passThroughProps,
          (newPassThroughProps) => {
            newPassThroughProps.closePopup = action.actionReference.close;
          },
        );

        return (
          <Widget
            component={action}
            passThroughProps={passThroughPropsWithClose}
            index={index}
            aliases={aliases}
            key={action.widgetId}
          />
        );
      }),
    [aliases, passThroughProps, renderingActions],
  );

  const widget = renderer ? renderer(children) : children;

  const showSpinner =
    (showLoading && loading) ||
    (showReloading && reloading) ||
    (showUpdating && updating);

  const widgets = (
    <Fragment>
      {widget}
      {actionComponents}
      {showSpinner && <LoadingModal />}
    </Fragment>
  );

  const overlayedWidget = overlays.reduce((acc, overlay) => {
    return (
      <Widget
        component={overlay}
        passThroughProps={passThroughProps}
        index={1}
        aliases={aliases}
        addonProps={{
          children: acc,
        }}
      />
    );
  }, widgets);

  if (!shouldRender({ render, preventRender })) {
    return null;
  }

  return <Fragment>{overlayedWidget}</Fragment>;
};

export function withActions(Component) {
  return (props) => {
    const { renderer, ...propsMinusRenderer } = props;
    return (
      <ActionEnabler renderer={renderer} {...propsMinusRenderer}>
        <StasisFreezer>
          <Component {...propsMinusRenderer} />
        </StasisFreezer>
      </ActionEnabler>
    );
  };
}
