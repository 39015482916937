import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Switch,
  Tooltip,
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { compose } from 'redux';

import { withUiState } from '../../store/withUiState';
import { getIcon } from '../../util/clientUtilities';
import { withActions } from '../widgetEngine/ActionEnabler';
import Input from '../widgetEngine/Input';

const styles = () => ({});
//FIXME we should only use colors in themes but we will need to be able to handle dark backgrounds
const muiThemeColors = [
  'primary',
  'secondary',
  'success',
  'action',
  'disabled',
];
class CheckboxWidget extends Input {
  public declare props;
  public label;
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  initializeValue() {
    const {
      updateFromWidget,
      startingValue,
      initialValue,
      persistValue,
      data,
    } = this.props;
    if (persistValue !== undefined) {
      updateFromWidget({
        data: persistValue,
        initialUpdate: true,
      });
    } else if (data !== undefined) {
      return;
    } else if (initialValue === true || initialValue === false) {
      updateFromWidget({
        data: initialValue,
        initialUpdate: true,
      });
    } else if (startingValue === true || startingValue === false) {
      updateFromWidget({
        data: startingValue,
        initialUpdate: true,
      });
    } else {
      updateFromWidget({
        data: false,
        initialUpdate: true,
      });
    }
  }

  handleChange(event, checked) {
    const { readOnly, updateFromWidget } = this.props;
    event.stopPropagation();
    if (readOnly) {
      return null;
    }
    updateFromWidget({ data: checked, newWay: true });
  }

  render() {
    const {
      label,
      data,
      disabled,
      variant,
      valid,
      validationMessage,
      // likely will leave checkboxes nonNullable
      // nullable,
      uncheckedIcon,
      checkedIcon,
      readOnly,
      elementAttributes,
      tooltip,
    } = this.props;

    let Control;
    if (variant === 'switch') {
      Control = Switch;
    } else if (variant === 'checkbox') {
      Control = Checkbox;
      // color={this.props.color || null}
      // if (nullable) {
      //   Control = NullableCheckbox;
      // } else {
      //   Control = Checkbox;
      // }
    }

    const CheckedIcon = checkedIcon
      ? getIcon(
          checkedIcon,
          this.props.component.configName,
          this.props.elementAttributes,
        )
      : null;
    const UnCheckedIcon = uncheckedIcon
      ? getIcon(
          uncheckedIcon,
          this.props.component.configName,
          this.props.elementAttributes,
        )
      : null;
    // const colorToUse = data !== true ? uncheckedColor : color;
    const checkedProps = muiThemeColors.includes(this.props.color)
      ? { color: this.props.color }
      : { sx: { color: this.props.color } };
    const uncheckedProps = muiThemeColors.includes(this.props.uncheckedColor)
      ? { color: this.props.uncheckedColor }
      : { sx: { color: this.props.uncheckedColor } };
    const conditionalProps = {
      ...(!uncheckedIcon &&
        !checkedIcon && { color: this.props.color || null }),
      ...(variant === 'checkbox' &&
        uncheckedIcon && {
          icon: <UnCheckedIcon {...uncheckedProps} />,
          //icon: <Icon>{uncheckedIcon}</Icon>,
        }),
      ...(variant === 'checkbox' &&
        checkedIcon && {
          checkedIcon: <CheckedIcon {...checkedProps} />,
          //checkedIcon: <Icon>{checkedIcon}</Icon>,
        }),
      ...(readOnly && { disabled: true }),
    };
    let controlComponent = (
      <Control
        disabled={disabled}
        checked={data === true}
        onChange={this.handleChange}
        value={this.label}
        {...conditionalProps}
        {...elementAttributes}
      />
    );
    if (tooltip) {
      controlComponent = <Tooltip title={tooltip}>{controlComponent}</Tooltip>;
    }
    if (label) {
      controlComponent = (
        <FormControlLabel control={controlComponent} label={label} />
      );
    }
    return (
      <FormControl
        variant="standard"
        error={valid === false}
        key="checkboxWidget"
      >
        {controlComponent}
        {valid === false && (
          <FormHelperText>{validationMessage}</FormHelperText>
        )}
      </FormControl>
    );
  }
}

export default compose(
  withUiState,
  withActions,
  withStyles(styles, { withTheme: true }),
)(CheckboxWidget);
