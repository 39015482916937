import { Button, Modal, Paper, TextField, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import React, { Component } from 'react';

const styles = () => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '10%',
    outline: 'none',
  },
  modalPaper: {
    padding: '10px',
  },
});

// This can be given an identifier prop.
// This can be passed a text string to copy.
// It can be passed an onSuccess callback.
// It can be passed a getText callback that will be called with the identifier as an argument, and must return just the text, or an object with {text, onSuccess} fields, which take precedence over the other props
// It's default child is a button that says copy. Any other child can be passed, to which this will pass it's own onClick handler. Buttons work best.
// If an onClick function is provided, it will be run before the copy takes place with the event and identifier as arguments.

interface IProps {
  getText?;
  onClick?;
  identifier?;
  // FIXME - had to add this in the mui 5 conversion
  children?;
}

class CopyButton extends Component<IProps> {
  public declare props;
  public declare state;
  public inputRef;
  constructor(props: IProps) {
    super(props);
    this.state = {
      altCopy: false,
      text: null,
    };
    this.inputRef = React.createRef();
    this.selectText = this.selectText.bind(this);
    this.handleCopy = this.handleCopy.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.handleAlternateCopy = this.handleAlternateCopy.bind(this);
  }

  handleAlternateCopy() {
    const { altCopy } = this.state;
    altCopy();
    this.setState({ altCopy: false, text: null });
  }

  alternateCopy(text) {
    return new Promise((resolve) => {
      this.setState({ altCopy: resolve, text });
    });
  }

  async handleCopy(event) {
    const { getText, onClick, identifier } = this.props;
    let { onSuccess, text } = this.props;
    if (onClick) {
      onClick(event, identifier);
    }

    if (getText) {
      const result = getText(identifier);
      if (typeof result === 'object') {
        ({ text, onSuccess } = result);
      } else {
        text = result;
      }
    }

    if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
      await navigator.clipboard.writeText(text);
    } else {
      await this.alternateCopy(text);
    }

    if (onSuccess) {
      onSuccess(text, identifier);
    }
  }

  selectText() {
    this.inputRef?.current.focus();
    this.inputRef?.current.select();
  }

  closeModal() {
    this.setState({ altCopy: false });
  }

  render() {
    const { altCopy, text } = this.state;
    const { classes, children = <Button>Copy</Button> } = this.props;
    return (
      <React.Fragment>
        {React.cloneElement(children, { onClick: this.handleCopy })}
        <Modal
          open={!!altCopy}
          className={classes.modal}
          onClose={this.closeModal}
        >
          <Paper className={classes.modalPaper}>
            <Typography>Copy text, then press any letter to close</Typography>
            <TextField
              autoFocus
              onChange={this.handleAlternateCopy}
              value={text}
              inputRef={this.inputRef}
              onFocus={this.selectText}
              variant="standard"
              fullWidth
            />
          </Paper>
        </Modal>
      </React.Fragment>
    );
  }
}

export default withStyles(styles, { withTheme: true })(CopyButton);
