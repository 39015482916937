import { Operation } from '@apollo/client';
import { ApolloLink, Observable } from '@apollo/client/core/index.js';
import { Lambda } from '@aws-sdk/client-lambda';
import safeJsonStringify from 'safe-json-stringify';

import { ClientManager } from '../clientManager';
import { getErrorString } from '../errors/errorString';
import { getLogger, Loggers, LogLevels } from '../loggerSupport';
import {
  DIRECTIVE_SUPPRESSLOCALNOTIFY,
  ITEMS,
} from '../metadataSupportConstants';
import { PermissionManager } from '../permissionManager';
import {
  traceLinkRequestData,
  traceLinkResultData,
} from '../pipeline/graphQLSupport';
import {
  getFieldNameInfo,
  getGraphqlOperationFieldFromDocument,
  getQueryId,
  printGraphQLDocument,
} from '../pipeline/graphQLSupportAst';
import { PipelineManager } from '../pipeline/pipelineManager';
import { stringify } from '../utilityFunctions';

const logger = getLogger({ name: Loggers.GRAPHQL_LINKS });

export function createLambdaLink(params: {
  clientManager: ClientManager;
  lambda: Lambda;
  functionName: string;
  headers: any;
}) {
  const { clientManager, functionName, lambda, headers } = params;

  return new ApolloLink((operation: Operation) => {
    const { variables } = operation;
    let startTime;

    // Sometimes processes can outlive an idToken. In this case, they must die, as the graphQL
    // activity cannot be authenticated. This applies to both the browser and node environments.
    try {
      PermissionManager.basicValidateIdToken(headers.authorization);
    } catch (e) {
      logger.warn(
        `Id token has expired - killing process: ${getErrorString(e)}`,
      );
      clientManager.killMe();
    }

    const queryField = getGraphqlOperationFieldFromDocument(operation.query);
    const fieldName = queryField.name.value;
    const fieldInfo = getFieldNameInfo(fieldName);
    const counters = clientManager.pipelineManager.graphQLManager.getCounters(
      fieldInfo.entityId,
    );

    const { pipelineManager } = clientManager;
    if (
      pipelineManager.isSuppressingLocalNotifyQueries(getQueryId(queryField)) &&
      (fieldInfo.queryType === 'Get' || fieldInfo.queryType === 'List')
    ) {
      // This happens if a cache read during a mutation processing is missing data, when using
      // @suppresslocalnotify. These missing fields could be fields populated by the server, like
      // CREATION_TIME or DEACTIVATION_DATE or could be missing fields in a query that are not provided by
      // an upsert mutation. In these cases, we simply ignore the query because we don't want (and don't need)
      // to refresh anything.
      if (logger.isLevelEnabled(LogLevels.Debug)) {
        logger.debug(
          `Ignoring query for: ${fieldName} because of ${DIRECTIVE_SUPPRESSLOCALNOTIFY}`,
        );
      }
      counters.suppressLocalUpdateIgnoredQueries++;
      return new Observable((observer) => {
        observer.next({
          [fieldName]: fieldInfo.queryType === 'List' ? { [ITEMS]: [] } : {},
        });
        observer.complete();
      });
    }

    if (!counters.requests[fieldInfo.queryType]) {
      counters.requests[fieldInfo.queryType] = { started: 0, completed: 0 };
    }
    counters.requests[fieldInfo.queryType].started++;

    if (logger.isLevelEnabled(LogLevels.Debug)) {
      startTime = traceLinkRequestData({
        clientManager,
        logger,
        message: 'Link outgoing',
        operation,
      });
    }
    const query = printGraphQLDocument(operation.query);
    const lambdaParams = {
      FunctionName: functionName,
      Payload: new TextEncoder().encode(
        safeJsonStringify({
          body: { query, variables },
          headers: { ...headers, ...operation.getContext().headers },
          httpMethod: 'POST',
        }),
      ),
    };
    return new Observable((observer) => {
      lambda
        .invoke(lambdaParams)

        .then(({ StatusCode, Payload, FunctionError }) => {
          const inboundPayloadString =
            Payload && new TextDecoder().decode(Payload);
          const makeErrorMessageBeginning = (): string => {
            const outboundPayload = lambdaParams.Payload
              ? JSON.parse(new TextDecoder().decode(lambdaParams.Payload))
              : undefined;
            const remoteContext = PipelineManager.getRemoteContextFromHeaders(
              outboundPayload?.headers,
            );
            return `Error on GraphQL invocation ${PipelineManager.remoteIdString(
              {
                remoteContext,
              },
            )}`;
          };

          if (StatusCode >= 400 || FunctionError || !Payload) {
            // Given enough information so the retry mechanism will retry if necessary
            const madeError: any = new Error(
              `${makeErrorMessageBeginning()} - Lambda request failure ${stringify(
                {
                  input: {
                    functionName: lambdaParams.FunctionName,
                    payload: inboundPayloadString,
                  },
                  noThrow: true,
                },
              )}`,
            );
            madeError.statusCode = StatusCode;
            const timedOut = inboundPayloadString?.includes(
              'Task timed out after',
            );

            // See retry.ts for use of this
            madeError.noRetry = !!FunctionError && !timedOut;
            madeError.retryable = timedOut;
            observer.error(madeError);
            return;
          }

          const graphqlResponse: /*GraphQLResponseBody*/ any =
            JSON.parse(inboundPayloadString);

          if (graphqlResponse.errors) {
            const madeError: any = new Error(makeErrorMessageBeginning());
            // @ts-ignore - this status code is added by the apolloHandler
            madeError.statusCode = graphqlResponse.statusCode || 400;
            madeError.graphQLErrors = graphqlResponse.errors;
            // See retry.ts for use of this
            madeError.noRetry = true;
            observer.error(madeError);
            return;
          }

          counters.requests[fieldInfo.queryType].completed++;

          if (logger.isLevelEnabled(LogLevels.Debug)) {
            traceLinkResultData({
              clientManager,
              logger,
              message: 'Link result',
              bodyObj: graphqlResponse,
              operation,
              startTime,
            });
          }

          // @ts-ignore - problem is a mismatch in the errors field, but this does not matter since errors are not handled here
          observer.next(graphqlResponse);
          observer.complete();
        })
        .catch((err) => {
          observer.error(err);
        });
    });
  });
}
