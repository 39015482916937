import { GridApi } from '@ag-grid-community/core';
import '@ag-grid-community/styles/ag-grid.css';
import '@ag-grid-community/styles/ag-theme-balham.css';
import Button from '@mui/material/Button';
import './styles.css';

import { persistUIState } from '../../../store/persistUIState';
import { SELECT_COLID } from './AGGrid';

export function topToolBar(params: {
  topRowButtons: any[];
  api: GridApi;
  props: any;
  groupModel?: any;
  persistKey: string;
  setColumnInfo: any;
}) {
  const { topRowButtons } = params;

  const doResetFilters = (api: GridApi) => {
    return () => {
      api.setFilterModel(null);
    };
  };

  function addColumnKeys(cols, colKeys) {
    cols.forEach((col) => {
      if ((col.rowGroup || !col.hide) && col.colId !== SELECT_COLID) {
        colKeys.push(col.colId);
        if (col.children) {
          addColumnKeys(col.children, colKeys);
        }
      }
    });
    return colKeys;
  }
  const doExport = (api, excelParams) => {
    return () => {
      const cols = api.getColumnDefs();
      excelParams.columnKeys = addColumnKeys(cols, []);
      api.exportDataAsExcel(excelParams);
    };
  };
  const doExpandAll = () => params.api.expandAll();
  const doCollapseAll = () => params.api.collapseAll();
  async function doResetColumns() {
    const { props, api, groupModel, persistKey, setColumnInfo } = params;
    const newPersist = {
      filterModel: props.suppressPersistFilters ? null : api.getFilterModel(),
      columnModel: null,
      groupModel: props.suppressPersistGroups ? null : groupModel,
    };
    await persistUIState({ [persistKey]: newPersist });
    setColumnInfo(null);
  }
  const buttons = [];

  const resetFilters = () => {
    return (
      <Button
        onClick={doResetFilters(params.api)}
        variant="outlined"
        size="small"
        className="toolbarButtons"
        key="ClearFilters"
      >
        Reset Filters
      </Button>
    );
  };

  const resetColumns = () => {
    return (
      <Button
        onClick={doResetColumns}
        variant="outlined"
        size="small"
        className="toolbarButtons"
        key="ResetColumns"
      >
        Reset Columns to Default
      </Button>
    );
  };

  const excel = (p) => {
    return (
      <Button
        onClick={doExport(params.api, p)}
        variant="outlined"
        size="small"
        className="toolbarButtons"
        key="Export"
      >
        Export to Excel
      </Button>
    );
  };
  const expandAll = () => {
    return (
      <Button
        onClick={doExpandAll}
        variant="outlined"
        size="small"
        className="toolbarButtons"
        key="ExpandAll"
      >
        Expand All
      </Button>
    );
  };
  const collapseAll = () => {
    return (
      <Button
        onClick={doCollapseAll}
        variant="outlined"
        size="small"
        className="toolbarButtons"
        key="CollapseAll"
      >
        Collapse All
      </Button>
    );
  };
  topRowButtons.forEach((button) => {
    switch (button.name) {
      case '_resetFilters':
        buttons.push(resetFilters());
        break;
      case '_resetColumns':
        buttons.push(resetColumns());
        break;
      case '_excel':
        buttons.push(excel(button.params));
        break;
      case '_expandCollapse':
        buttons.push(expandAll(), collapseAll());
        break;
    }
  });
  return <div>{buttons.map((b) => b)}</div>;
}
