import withStyles from '@mui/styles/withStyles';

import CustomButton from '../../atoms/CustomButton';
import SlideUp from '../../molecules/SlideUp';

const styles = {};

function ListMenu(props) {
  const { downloadReport, report, filename, open, onClick, containerWidth } =
    props;

  const reportButton = (
    <CustomButton onClick={downloadReport}>Export {filename}.xlsx</CustomButton>
  );

  return (
    <SlideUp
      overlayContent={report !== false && reportButton}
      height="100px"
      label="options"
      open={open}
      onClick={onClick}
      containerWidth={containerWidth}
    >
      {props.children}
    </SlideUp>
  );
}

export default withStyles(styles, { withTheme: true })(ListMenu);
