import { PipelineExecutor } from '../pipelineExecutor';
import { IStageInfo, IStageProperties } from '../stage';
import { StageImpl } from '../stageImpl';

export enum Action {
  READ,
}

export interface IStagePropertiesS3 extends IStageProperties {
  action: Action;
  bucketName: string;
  key: string;
  range: string;
  returnBytes: boolean;
}

const execute = async (executor: PipelineExecutor, stage: StageImpl) => {
  const { bucketName, key, range, returnBytes } =
    stage.workingProperties as IStagePropertiesS3;

  const s3Params = {
    Bucket: bucketName,
    Key: key,
    Range: range,
  };

  const s3Result =
    await executor.pipelineManager.clientManager.s3Support.getObject(s3Params);
  const result = returnBytes
    ? await s3Result.Body.transformToByteArray()
    : await s3Result.Body.transformToString();
  executor.recordResult(stage, result);
};

export function initialize(stageInfo: IStageInfo) {
  stageInfo.executor = execute;
}
