import axios from 'axios';

import { reThrow } from '../../errors/errorLog';
import { retry } from '../../errors/retry';
import { getLogger, Loggers } from '../../loggerSupport';
import { stringifyPretty } from '../../utilityFunctions';
import { PipelineExecutor } from '../pipelineExecutor';
import { IStageInfo, IStageProperties } from '../stage';
import { StageImpl } from '../stageImpl';

const logger = getLogger({ name: Loggers.PIPELINE_MANAGER });

export enum RequestType {
  GET,
  PUT,
  POST,
  DELETE,
}

export interface IStagePropertiesHttp extends IStageProperties {
  requestType?: RequestType;
  uri: string;
  payload?: string;
  headers?: string;
  statusCode?: number;
  statusMessage?: string;
}

const execute = async (executor: PipelineExecutor, stage: StageImpl) => {
  const properties: IStagePropertiesHttp =
    stage.workingProperties as IStagePropertiesHttp;
  const { uri, headers, payload } = properties;
  let { requestType } = properties;

  if (!requestType) {
    requestType = RequestType.GET;
  }

  const options: any = {
    method: RequestType[requestType],
    url: uri,
    resolveWithFullResponse: true,
    headers: typeof headers === 'string' ? JSON.parse(headers) : headers,
    responseType: 'json',
    // We handle all bad statuses without a throw
    validateStatus: () => true,
  };
  if (payload) {
    if (typeof payload === 'string') {
      options.data = JSON.parse(payload);
    } else {
      options.data = payload;
    }
  }
  let result;
  try {
    logger.debug(options, 'HTTP request: ');
    result = await retry({
      command: async () => axios.request(options),
      shouldRetry: (res) => {
        if (res.status >= 500) {
          logger.warn(`HTTP shouldRetry: ${res.status}/${res.statusText}`);
          return true;
        }
        return false;
      },
      functionName: `HTTP request: ${stringifyPretty(options)}`,
    });
    logger.debug(
      'HTTP response: status: ',
      result.status,
      ' message: ',
      result.statusText,
      ' body: ',
      result.data,
    );
    executor.recordResult(stage, result.data);
    if (result.status >= 400) {
      const errorMessage = `${result.status}: ${result.statusText}`;
      logger.error(
        `HTTP ${errorMessage} result: ${stringifyPretty(
          result.data,
        )} request: ${stringifyPretty(options)}, result: ${stringifyPretty(
          result.data,
        )}`,
      );
      properties._error = { message: errorMessage };
      properties.statusCode = result.status;
      properties.statusMessage = result.statusText;
      if (properties._throwOnError) {
        throw new Error(errorMessage);
      }
    }
  } catch (error) {
    // We should never get here unless we threw above, or there was a bad problem
    const logObject = error.request;
    properties._error = error;
    reThrow({ logger, error, logObject, message: 'HTTP request error' });
  }
};

export function initialize(stageInfo: IStageInfo) {
  stageInfo.executor = execute;
}
