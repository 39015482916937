import Handlebars from 'handlebars';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import simpleColorConverter from 'simple-color-converter';
import decode from 'unescape';
import { ClientManager } from 'universal/clientManager';
import { IPresentationFormat } from 'universal/metadataSupportConstants';
import { AccessType } from 'universal/permissionManager';
import { IRanges } from 'universal/propertySupport';
import { IItemInfo, IItemType } from 'universal/typeDefinition';
import { BasicType } from 'universal/types';
import { adjustRelativeUrl, months } from 'universal/utilityFunctions';

import { IWidget } from '../components/widgets/types';

import { escapeUrlPath, externalUrl } from './clientUtilities';

export interface ITestEnvironment {
  clientManager: ClientManager;
  testIdToken: string;
}

const compactMap = {
  thousands: {
    suffix: 'K',
    multiplier: 1000,
  },
  millions: {
    suffix: 'M',
    multiplier: 1000000,
  },
  billions: {
    suffix: 'B',
    multiplier: 1000000000,
  },
};

export function convertColorToHex(colorString = '') {
  if (colorString.startsWith('#')) {
    return colorString;
  }

  if (colorString.startsWith('rgba')) {
    const resultColor = new simpleColorConverter({
      rgba: colorString,
      to: 'hex6',
    });
    return `#${resultColor.color}`;
  }

  const result = new simpleColorConverter({
    html: colorString,
    to: 'hex6',
  });
  return `#${result.color}`;
}

export function shouldRender(params: {
  render?: boolean;
  preventRender?: boolean;
}) {
  const { render, preventRender } = params;
  return (render || [undefined, null].includes(render)) && !preventRender;
}

export function createUrlProps({
  url,
  relativeUrlDepth,
  absolute,
  external,
  newTab,
  match,
}) {
  const urlProps: {
    target?: string;
    component?: any;
    href?: string;
    to?: string;
  } = {};

  if (url) {
    if (newTab) {
      urlProps.target = '_blank';
    }

    if (external) {
      urlProps.component = 'a';
      // if the url has // it should be used as is, otherwise it should be added. The browser will figure out what to put in front of it (http: or https:)
      urlProps.href = externalUrl(url);
    } else {
      url = escapeUrlPath(url);
      urlProps.component = Link;
      if (absolute) {
        urlProps.to = `/${url}`;
      } else {
        const relativeUrl = adjustRelativeUrl(match.url, relativeUrlDepth);
        urlProps.to = `${relativeUrl}/${url}`;
      }
    }
  }

  return urlProps;
}

export function createWidget(params: {
  name: string;
  type: string;
  parentType: string;
  configName: string;
}): IWidget {
  const { name, type, parentType, configName } = params;
  return {
    type,
    name,
    properties: {},
    parentType,
    configName,
    events: [],
    matchProperties: {},
    requiredFields: [],
    widgetId: getNextWidgetId(),
  };
}

let widgetId = 0;

export function getNextWidgetId() {
  widgetId += 1;
  return widgetId;
}

export function setupWidget(widget: IWidget) {
  setMatchProperties(widget);
}

export function setMatchProperties(widget) {
  widget.matchProperties = Object.entries(widget.properties).reduce(
    (acc, [key, value]) => {
      if (
        value !== undefined &&
        typeof value === 'string' &&
        value.startsWith("'") &&
        value.endsWith("'")
      ) {
        acc[key] = value.slice(1, value.length - 1);
      } else if (
        !isNaN(value as any) &&
        ![true, false, null].includes(value as any)
      ) {
        acc[key] = Number(value);
      } else {
        acc[key] = value;
      }
      return acc;
    },
    {},
  );
}

export function compileProperties(properties, context): { [key: string]: any } {
  return Object.entries(properties).reduce(
    (acc, [propertyKey, propertyValue]) => {
      const compiledProperty = compileProperty(
        propertyKey,
        propertyValue,
        context,
      );
      // does not add the property if it compiles to undefined
      if (compiledProperty === undefined) {
        return acc;
      }
      return Object.assign(acc, {
        [propertyKey]: compiledProperty,
      });
    },
    {},
  );
}

function compileRange(range: IRanges, context) {
  const { baseField, dynamic, ranges, rangesPointer } = range;
  const compiledBaseField = compileProperty('baseField', baseField, context);

  const compareRanges = dynamic
    ? compileProperty('rangesPointer', rangesPointer, context)
    : ranges;

  const rangeMatch = compareRanges.find((compareRange) => {
    if (![undefined, ''].includes(compareRange.match)) {
      return compareRange.match === compiledBaseField;
    } else {
      return (
        ([undefined, ''].includes(compareRange.min) ||
          compiledBaseField >= compareRange.min) &&
        ([undefined, ''].includes(compareRange.max) ||
          compiledBaseField <= compareRange.max)
      );
    }
  });

  if (!rangeMatch) {
    return undefined;
  }

  return rangeMatch.valuePointer
    ? compileProperty('valuePointer', rangeMatch.valuePointer, context)
    : rangeMatch.value;
}

export function compileProperty(propertyKey, propertyValue, context) {
  if (typeof propertyValue === 'object' && propertyValue.type === 'range') {
    return compileRange(propertyValue, context);
  }

  let compiledProperty = propertyValue;
  if (typeof compiledProperty === 'string') {
    compiledProperty = compiledProperty.trim();
    if (compiledProperty.includes('{{')) {
      const template = Handlebars.compile(compiledProperty);
      compiledProperty = decode(template(context));
      if (compiledProperty === "'true'") {
        compiledProperty = 'true';
      }
      if (compiledProperty === "'false'") {
        compiledProperty = 'false';
      }
    }

    if (compiledProperty.startsWith("'") && compiledProperty.endsWith("'")) {
      compiledProperty = compiledProperty.slice(1, compiledProperty.length - 1);
    } else if (['true', 'false', 'null'].includes(compiledProperty)) {
      compiledProperty = JSON.parse(compiledProperty);
    } else if (isNaN(compiledProperty)) {
      compiledProperty = _.get(context, compiledProperty);
    } else {
      compiledProperty = Number(compiledProperty);
    }
  } else if (propertyValue === undefined) {
    const keyValue = _.get(context, propertyKey);
    if (keyValue !== undefined) {
      compiledProperty = keyValue;
    }
  }

  return compiledProperty;
}
// FIXME Below function should go away after the Kendo grid is no longer used
export function decorateStringKendo(input: any, properties: any): string {
  const {
    prefix,
    suffix,
    decimalScale,
    fixedDecimalScale,
    style,
    currency = 'USD',
    thousandSeparator,
    notation,
    defaultValue,
  } = properties;
  let notationToUse = notation;
  let suffixToUse = suffix;

  let value =
    [undefined, null].includes(input) && defaultValue !== undefined
      ? defaultValue
      : input;

  if ([undefined, null].includes(value)) {
    return '';
  }

  if (style === 'month') {
    return months[value - 1].short;
  }
  if (compactMap[notationToUse]) {
    if (!decimalScale) {
      value = Math.round(value / compactMap[notationToUse].multiplier);
    } else {
      value = value / compactMap[notationToUse].multiplier;
    }
    if (value !== 0) {
      suffixToUse = compactMap[notationToUse].suffix;
    }
    notationToUse = 'standard';
  }
  if (
    prefix ||
    suffix ||
    decimalScale ||
    fixedDecimalScale ||
    style !== 'decimal' ||
    thousandSeparator ||
    notationToUse !== 'standard'
  ) {
    const defaultLanguage = 'en';
    let language = defaultLanguage;

    if (navigator) {
      language =
        (navigator.languages && navigator.languages[0]) || navigator.language;
    }

    const options = {
      style,
      currency,
      useGrouping: thousandSeparator,
      maximumFractionDigits: decimalScale,
      ...((fixedDecimalScale || (style === 'currency' && decimalScale < 2)) && {
        minimumFractionDigits: decimalScale,
      }),
      notationToUse,
    };

    const filteredOptions = _.omitBy(options, (val) => val === undefined);
    const string = value.toLocaleString(language, filteredOptions);

    return `${prefix || ''}${string}${suffixToUse || ''}`;
  }

  return value.toString();
}

export function decorateString(params: {
  input: any;
  presentationFormat?: IPresentationFormat;
  itemInfo: IItemInfo;
}): string {
  const { input, presentationFormat = {}, itemInfo } = params;
  const {
    style,
    currency = 'USD',
    useGrouping = 'auto',
    notation,
    minimumFractionDigits,
    maximumFractionDigits,
  } = presentationFormat;
  if (!itemInfo?.type) {
    throw new Error('decorateString: Must include itemInfo with type');
  }
  if (itemInfo.type === BasicType.String) {
    return input;
  }
  let value =
    [undefined, null].includes(input) && itemInfo?.initialValue !== undefined
      ? itemInfo?.initialValue
      : input;

  if (!Number.isFinite(value)) {
    return '';
  }

  let notationToUse = notation;
  let suffix = '';

  if ([undefined, null].includes(value)) {
    return '';
  }
  if (style === 'month') {
    return months[value - 1].short;
  }
  if (style === 'year') {
    return value.toString();
  }
  if (style !== 'percent' && compactMap[notationToUse]) {
    if (!maximumFractionDigits) {
      value = Math.round(value / compactMap[notationToUse].multiplier);
    } else {
      value = value / compactMap[notationToUse].multiplier;
    }
    if (value !== 0) {
      suffix = compactMap[notationToUse].suffix;
    }
    notationToUse = 'standard';
  }
  if (
    maximumFractionDigits ||
    minimumFractionDigits ||
    !notation ||
    notation !== 'standard'
  ) {
    const defaultLanguage = 'en';
    let language = defaultLanguage;

    if (navigator) {
      language =
        (navigator.languages && navigator.languages[0]) || navigator.language;
    }

    const options = {
      style: ['ratio', 'margin'].includes(style) ? 'percent' : style,
      currency,
      useGrouping,
      maximumFractionDigits,
      minimumFractionDigits,
      notationToUse,
    };

    const filteredOptions = _.omitBy(options, (val) => val === undefined);
    const string = value.toLocaleString(language, filteredOptions);

    return `${string}${suffix}`;
  }
  return value.toString();
}
export function prepNumericInput(params: {
  input: any;
  presentationFormat?: IPresentationFormat;
}): string {
  const { input, presentationFormat } = params;
  const { style, notation, maximumFractionDigits } = presentationFormat;
  let value;
  if (isNaN(input) || [null, undefined].includes(input)) {
    return '';
  }
  if (style !== 'percent' && compactMap[notation]) {
    if (!maximumFractionDigits) {
      value = Math.round(input / compactMap[notation].multiplier);
    } else {
      value = (input / compactMap[notation].multiplier).toFixed(
        maximumFractionDigits,
      );
    }
    return value;
  }
  if (style == 'percent') {
    return (input * 100).toFixed(maximumFractionDigits);
  }
  return input;
}

export function processInputString(params: {
  newValue: any;
  presentationFormat?: IPresentationFormat;
  itemInfo: IItemInfo;
}): any {
  const { newValue, presentationFormat = {}, itemInfo } = params;
  const { style, notation } = presentationFormat;

  if (!itemInfo?.type) {
    throw new Error('processInputString: Must include itemInfo with type');
  }
  if (
    ![BasicType.Int, BasicType.Decimal, BasicType.Float].includes(
      itemInfo.type as BasicType,
    )
  ) {
    return newValue;
  }
  if (isNaN(newValue)) {
    return null;
  }
  let value = parseFloat(newValue);
  if (style !== 'percent' && compactMap[notation]) {
    value = value * compactMap[notation].multiplier;
  } else if (style === 'percent') {
    value = value / 100;
  }
  if (itemInfo.type === BasicType.Int) {
    return Math.round(value);
  }
  return value;
}

export function formatValueFromTypeDef(params: {
  input: any;
  typeDef: IItemType;
}) {
  const { input, typeDef } = params;
  const { presentationFormat } = typeDef.presentationInfo;
  const itemInfo = typeDef.itemInfo;
  return decorateString({ input, itemInfo, presentationFormat });
}

function hasQuotes(string) {
  if (!string) {
    return false;
  }

  return string.startsWith("'") && string.endsWith("'");
}

export function addQuotes(string) {
  if (!hasQuotes(string)) {
    return `'${string}'`;
  }
  return string;
}

export function removeQuotes(string) {
  if (hasQuotes(string)) {
    return string.slice(1, -1);
  }
  return string;
}

export function includesAnyOf(hasIncludes: string | any[], array: any[]) {
  return array.reduce((acc, n) => {
    return acc || hasIncludes.includes(n);
  }, false);
}

// the implementation of this allows it to only be run on the frontend
export function measurePerfStart(name) {
  const start = performance.now();

  // @ts-ignore
  if (!window.performanceTracking) {
    // @ts-ignore
    window.performanceTracking = {};
  }

  // @ts-ignore
  if (!window.performanceTracking[name]) {
    // @ts-ignore
    window.performanceTracking[name] = {
      measurements: [],
    };
  }

  // @ts-ignore
  window.performanceTracking[name].start = start;
}

export function measurePerfEnd(name, reportFrequency = 100) {
  const end = performance.now();
  // @ts-ignore
  const entry = window.performanceTracking?.[name];

  if (!entry.start) {
    throw new Error(
      'measurePerfEnd was called with not matching start entry. Make sure start was called first with the same name, and that you are not trying to measure concurrent or async code.',
    );
  }

  const time = end - entry.start;
  delete entry.start;

  entry.measurements.push(time);

  if (entry.measurements.length % reportFrequency === 0) {
    const totalTime = entry.measurements.reduce(
      (total, instance) => total + instance,
    );
    entry.total = totalTime;
    entry.average = totalTime / entry.measurements.length;
    console.log('average', name, 'time', entry.average);
    console.log('total', name, 'time', entry.total);
  }
}

export function widgetCan(accessType: AccessType, permission: AccessType) {
  return (permission & accessType) === accessType;
}
