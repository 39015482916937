import { ColorPicker } from '@progress/kendo-react-inputs';

import { addQuotes, removeQuotes } from '../../util/widgetUtilities';

export default function ColorEditor({ onChange, value, identifier }) {
  return (
    <div>
      <ColorPicker
        view="gradient"
        onChange={(event) => onChange(addQuotes(event.value), identifier)}
        value={removeQuotes(value)}
      />
    </div>
  );
}
