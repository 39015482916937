export const typographyTranslate = {
  h1: 96,
  h2: 60,
  h3: 48,
  h4: 34,
  h5: 24,
  h6: 20,
  subtitle1: 16,
  subtitle2: 14,
  body1: 16,
  body2: 14,
  button: 14,
  caption: 12,
  overline: 10,
} as const;
//FIXME these constants should work instead of the values below WORM-3060
/* light: 'fontWeightLight',
  medium: 'fontWeightMedium',
  regular: 'fontWeightRegular',
  bold: 'fontWeightBold', */

export const materialUiFontWeights = {
  light: 300,
  medium: 400,
  regular: 500,
  bold: 700,
} as const;
