import { IPropertyInfos } from '../propertySupport';
import { ITestContext } from '../testSupport';
import { BasicType, StageType } from '../types';

import { PipelineExecutor } from './pipelineExecutor';
import { StageImpl } from './stageImpl';

// Describes everything about the stage, used by the PipelineManager
export interface IStageInfo {
  typeInfo: IStageTypeInfo;
  executor?: IStageExecutor;
  evaluator?: IStageEvaluate;
  close?: IStageClose;
}

export interface IStageInfos {
  [key: string]: IStageInfo;
}

// Function that executes the stage
export interface IStageExecutor {
  (executor: PipelineExecutor, stage: StageImpl);
}

// Evaluates the stage as it's being added to the executor
export interface IStageEvaluate {
  (evaluator: PipelineExecutor, stage: StageImpl);
}

export interface IStageClose {
  (close: PipelineExecutor, stage: StageImpl);
}

export interface IStageTypeInfo {
  moduleName: string;
  stageType: StageType;
  description: string;
  properties: IPropertyInfos;
}

export interface IStageProperties {
  _stageType: StageType;
  _name?: string;
  _nextStage?: string;
  _exitPipeline?: boolean;
  _forEachArray?: any[];
  _forEachItem?: any;
  _forEachConsume?: boolean;
  _throwOnError?: boolean;
  _error?: any;
  _requireHandlebarsValues?: boolean;
  _testContext?: ITestContext;
  _requestId?: string;
  _executionId?: string;

  [key: string]: any;
}

export const commonProperties: IPropertyInfos = {
  _stageType: { description: 'Execution type of the stage', editable: false },
  _name: { description: 'The name of the stage', displayName: 'name' },
  _nextStage: {
    resetBeforeExecution: true,
    description: 'The stage to execute after this stage',
    displayName: 'nextStage',
  },
  _exitPipeline: {
    type: BasicType.Boolean,
    resetBeforeExecution: true,
    description: 'Exit the pipeline after this stage',
    displayName: 'exitPipeline',
  },
  _forEachArray: {
    resetBeforeExecution: true,
    description:
      'Array to be used to trigger the execution of subsequent stages for each element of the array',
    displayName: 'forEachArray',
  },
  _forEachItem: {
    description:
      'The element of the _forEachArray presented for use by the stage',
    displayName: 'forEachItem',
  },
  _forEachConsume: {
    type: BasicType.Boolean,
    description:
      'Indicated this stage will consume items from a preceding stage with a _forEachArray',
    displayName: 'forEachConsume',
  },
  _throwOnError: {
    type: BasicType.Boolean,
    default: true,
    description:
      'If true, throw an exception if there is an error, ' +
      'otherwise, just record the error in _error',
    displayName: 'throwOnError',
  },
  _error: {
    resetBeforeExecution: true,
    description:
      'Set to the error value if something bad happened in the execution ' +
      'of the stage',
    displayName: 'error',
  },
  _paged: {
    type: BasicType.Boolean,
    description:
      'If true, this stage contributes pages to the PipelineExecutor',
    displayName: 'paged',
  },
  _testContext: {
    description:
      'Internal Use only, points to an IRemoteTestContext object to be sent with a request',
    displayName: 'testContext',
  },
  _requireHandlebarsValues: {
    description:
      'Requires that any handlebars references are resolved before the stage executes (browser only)',
    displayName: 'requireHandlebarValues',
  },
  _requestId: {
    description: 'The request id to use for this request',
    displayName: 'requestId',
  },
  _executionId: {
    description: 'The execution id to use for this request',
    displayName: 'executionId',
  },
};
