import { ClientManager } from './clientManager';
import { getConfigItemsForType } from './loadStore/loadstore';
import {
  APP_DEF_APPLICATION,
  IHasConfigName,
  INameInfo,
} from './metadataSupportConstants';
import { AccessType, IUserInfo, PermissionType } from './permissionManager';
import { StackInfoKeys } from './stackInfo';

import { getLogger, Loggers } from 'universal/loggerSupport';

// Must match typedef
export interface IApplication extends IHasConfigName {
  nameInfo: INameInfo;
  navigation: string;
  loginPipeline: string;
  defaultSubHeaderWidgetTree: string;
  subHeaderWidth: number;
  primaryLogo: string;
  primaryLogoHeight: string;
  secondaryLogo: string;
  secondaryLogoHeight: string;
  helpUrl: string;
  navbarWidgetTree: string;
}

const logger = getLogger({ name: Loggers.APPLICATION_MANAGER });

export class ApplicationManager {
  public clientManager: ClientManager;
  private currentApplication: IApplication;
  private clientCallback: (application: IApplication) => void;

  public initialize(clientManager: ClientManager) {
    this.clientManager = clientManager;
  }

  public registerCallback(callback: (application: IApplication) => void) {
    this.clientCallback = callback;
  }

  public getCurrentApplication() {
    return this.currentApplication;
  }

  public setApplication(params: {
    applicationId: string;
    userInfo?: IUserInfo;
  }) {
    const { applicationId, userInfo } = params;
    const applications = this.getAvailableApplications(userInfo);
    const application = applications.find((a) => a.id === applicationId);
    if (!application) {
      throw new Error(`Application ${applicationId} not found`);
    }
    logger.info(`Application changed to: ${applicationId}`);
    this.currentApplication = application;

    if (this.clientCallback) {
      this.clientCallback(this.currentApplication);
    }
  }

  public getAvailableApplications(userInfo?: IUserInfo): Array<IApplication> {
    const { permissionManager } = this.clientManager;

    const appDefs = this.clientManager.stackInfo.getObject(
      StackInfoKeys.APP_DEFS,
    );
    const applications = getConfigItemsForType(appDefs, APP_DEF_APPLICATION);

    const availableApplications = [];
    for (const application of applications) {
      if (userInfo) {
        const foundAccess = permissionManager.getAccess(
          userInfo.permissions,
          PermissionType.Application,
          application.id,
        );
        if (foundAccess === AccessType.Use) {
          availableApplications.push(application);
        }
      } else {
        availableApplications.push(application);
      }
    }
    return availableApplications;
  }

  public async getAvailableApplicationsForUserId(params: {
    email: string;
  }): Promise<Array<IApplication>> {
    const { email } = params;
    const { permissionManager } = this.clientManager;
    const userInfo = await permissionManager.getUserInfo({ email });
    return this.getAvailableApplications(userInfo);
  }
}
