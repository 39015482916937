import _ from 'lodash';

import { exists } from './common/commonUtilities';
import { AdditionalEditor, BasicType, EditorType, SimpleType } from './types';

export interface IPropertyTypeSelection {
  //
  default: BasicType;
  options: BasicType[];
}

export type Pointer = 'must' | 'can' | 'never';

export interface IPropertyInfo {
  default?: SimpleType | [];
  options?: readonly SimpleType[];
  description?: string;
  required?: boolean;
  resetBeforeExecution?: boolean;
  // rename to helpLink
  link?: string;
  // data type
  type?: BasicType;

  pointer?: Pointer;

  // boolean to only accept a literal value (generate the quotes)

  // Single line or multi-line text

  // Monaco editor configuration (e.g. Javascript, graphql, height)
  // array of junk to give to the monaco editor (defined by the editor)

  // Goes away
  types?: IPropertyTypeSelection;
  editor?: EditorType;
  additionalEditor?: AdditionalEditor;
  editable?: boolean;
  order?: number;
  displayName?: string;
}

export interface IPropertyInfos {
  [key: string]: IPropertyInfo;
}

export interface IRange {
  value?: any;
  valuePointer?: any;
  match?: string;
  min?: number;
  max?: number;
}

export interface IRanges {
  type: 'range';
  baseField: string;
  dynamic: boolean;
  ranges?: IRange[];
  rangesPointer?: string;
}

export type PathArray = Array<string | number>;

export interface IPropertyConfiguration {
  type: 'widget' | 'pipeline';
  allInfos: any;
  universalPropertyInfos?: IPropertyInfos;
  typeEnum?;

  // paths within the node
  propertiesPath: string;
  namePath: string;
  typePath: string;

  // paths within allInfos object
  getPropertyInfosPath: (type: string) => string;
  getInheritedPropertyInfosPath?: (parentType: string) => string;
}

export interface IProperties {
  [key: string]: SimpleType | [];
}

// Note that paths in the widget tree are PathArray's in reverse order
// whereas paths in propertyConfigurations are strings in standard order
export class PropertyConfigurationHandler {
  public propertyConfiguration: IPropertyConfiguration;
  private node;
  private readonly pathInWidgetTree: PathArray;
  private readonly parentType: string;

  constructor({
    propertyConfiguration,
    node,
    pathInWidgetTree,
    parentType,
  }: {
    propertyConfiguration: IPropertyConfiguration;
    node?;
    pathInWidgetTree?: PathArray;
    parentType?: string;
  }) {
    this.propertyConfiguration = propertyConfiguration;
    this.node = node;
    this.pathInWidgetTree = pathInWidgetTree;
    this.parentType = parentType;
  }

  public getName: () => string = () =>
    this.get(this.node, this.propertyConfiguration.namePath);

  public getRawProperties: () => IProperties = () =>
    this.get(this.node, this.propertyConfiguration.propertiesPath);

  public getPropertyNames: () => string[] = () =>
    Object.keys(this.getProperties());

  public getProperties(onlyEditable = false): IProperties {
    const propertyInfos = this.getPropertyInfos();
    const setProperties = this.getRawProperties();
    const properties = {};

    Object.entries(propertyInfos).forEach(([propertyName, propertyInfo]) => {
      if (
        onlyEditable &&
        exists(propertyInfo.editable) &&
        !propertyInfo.editable
      ) {
        return;
      }

      let propertyValue;

      if (setProperties && exists(setProperties[propertyName])) {
        propertyValue = setProperties[propertyName];
      } else {
        propertyValue = propertyInfo.default;
      }

      properties[propertyName] = propertyValue;
    });

    return properties;
  }

  public getEditableProperties: () => IProperties = () =>
    this.getProperties(true);

  public getEditablePropertyNames: () => string[] = () =>
    Object.keys(this.getEditableProperties());

  public addDefaults(): void {
    const properties = this.getRawProperties();
    const propertyInfos = this.getPropertyInfos();

    Object.entries(propertyInfos).forEach(([propertyName, propertyInfo]) => {
      if (!exists(properties[propertyName]) && exists(propertyInfo.default)) {
        properties[propertyName] = propertyInfo.default;
      }
    });
  }

  public addAllProperties(): void {
    const properties = this.getRawProperties();
    const propertyInfos = this.getPropertyInfos();

    Object.entries(propertyInfos).forEach(([propertyName, propertyInfo]) => {
      if (!exists(properties[propertyName])) {
        properties[propertyName] = propertyInfo.default;
      }
    });
  }

  public getPropertyValue: (propertyName: string) => SimpleType | [] = (
    propertyName,
  ) => this.getProperties()[propertyName];

  public getNamePathInWidgetTree(): PathArray {
    const { namePath } = this.propertyConfiguration;

    return (_.toPath(namePath) as PathArray)
      .reverse()
      .concat(this.pathInWidgetTree);
  }

  public getTypePathInWidgetTree(): PathArray {
    const { typePath } = this.propertyConfiguration;

    return (_.toPath(typePath) as PathArray)
      .reverse()
      .concat(this.pathInWidgetTree);
  }

  public getType: () => string = () =>
    this.get(this.node, this.propertyConfiguration.typePath);

  public getTypeName(): string {
    const { typeEnum } = this.propertyConfiguration;

    const type = this.getType();

    if (!typeEnum) {
      return type;
    } else {
      return typeEnum[type];
    }
  }

  public getPropertyInfos(): IPropertyInfos {
    const {
      propertyConfiguration: {
        allInfos,
        getPropertyInfosPath,
        universalPropertyInfos,
      },
    } = this;

    const propertyInfos = this.get(
      allInfos,
      getPropertyInfosPath(this.getType()),
    );

    const type = allInfos[this.getType()];
    const skipUniversalProperties = type?.nestedConfigItem;

    const inheritedPropertyInfos = this.getInheritedPropertyInfos();

    const combinedPropertyInfos = Object.assign(
      {},
      propertyInfos,
      !skipUniversalProperties && universalPropertyInfos,
      inheritedPropertyInfos,
    );

    const orderedPropertyInfos = _(combinedPropertyInfos)
      .toPairs()
      .orderBy('1.order')
      .fromPairs()
      .value();

    return orderedPropertyInfos;
  }

  public getInheritedPropertyInfos(): IPropertyInfos {
    const {
      parentType,
      propertyConfiguration: { getInheritedPropertyInfosPath, allInfos },
    } = this;

    if (!exists(parentType) || !exists(getInheritedPropertyInfosPath)) {
      return;
    }

    return this.get(allInfos, getInheritedPropertyInfosPath(parentType));
  }

  public getPropertyInfo: (propertyName: string) => IPropertyInfo = (
    propertyName,
  ) => this.getPropertyInfos()[propertyName];

  public getPropertyDisplayName(propertyName: string): string {
    const { displayName } = this.getPropertyInfo(propertyName);

    if (exists(displayName)) {
      return displayName;
    } else {
      return propertyName;
    }
  }

  private get(object, path) {
    if (!exists(path) || path === '') {
      return object;
    } else {
      return _.get(object, path);
    }
  }

  public getDefaultValue: (propertyName: string) => SimpleType | [] = (
    propertyName,
  ) => this.getPropertyInfo(propertyName)?.default;

  public getPropertiesPathInWidgetTree(): PathArray {
    const { propertiesPath } = this.propertyConfiguration;

    return (_.toPath(propertiesPath) as PathArray)
      .reverse()
      .concat(this.pathInWidgetTree);
  }

  public getPropertyPathInWidgetTree: (propertyName: string) => PathArray = (
    propertyName,
  ) =>
    ([propertyName] as PathArray).concat(this.getPropertiesPathInWidgetTree());

  public createNode({
    name,
    type,
  }: {
    name?: string;
    type?: string | number;
  }): any {
    const { propertiesPath } = this.propertyConfiguration;
    this.node = {};

    if (exists(name)) {
      this.setName(name);
    }

    if (exists(type)) {
      this.setType(type);
    }

    if (!_.has(this.node, propertiesPath)) {
      if (propertiesPath !== '') {
        _.set(this.node, propertiesPath, {});
      }
    }

    return this.node;
  }

  public setName: (name: string) => string = (name) =>
    _.set(this.node, this.propertyConfiguration.namePath, name);

  public setType: (type: string | number) => string = (type) =>
    _.set(this.node, this.propertyConfiguration.typePath, type);
}
