import { Parser } from 'acorn';
import {
  AssignmentProperty,
  Identifier,
  MemberExpression,
  ObjectPattern,
  Program,
  Statement,
  VariableDeclarator,
} from 'estree';
import _ from 'lodash';
import PolynomialRegression from 'ml-regression-polynomial';
import { RobustPolynomialRegression } from 'ml-regression-robust-polynomial';
import SimpleLinearRegression from 'ml-regression-simple-linear';
import TheilSenRegression from 'ml-regression-theil-sen';
import safeJsonStringify from 'safe-json-stringify';

import dayjs, { dayjsOriginal } from '../../common/dayjsSupport';
import { reThrow } from '../../errors/errorLog';
import {
  executeJavascript,
  handleError,
  IJavascriptBaseArgs,
} from '../../javascriptSupport';
import { getLogger, Loggers } from '../../loggerSupport';
import { AccessType, PermissionType } from '../../permissionManager';
import { PipelineExecutor } from '../pipelineExecutor';
import { PipelineManager } from '../pipelineManager';
import { IStageInfo, IStageProperties } from '../stage';
import { StageImpl } from '../stageImpl';

export const REQUIRE_DIRECTIVE = '@require';

let mailgunClient;

const logger = getLogger({ name: Loggers.PIPELINE_JAVASCRIPT });
export interface IStagePropertiesJavascript extends IStageProperties {
  code: string;
  payload: any;
  evaluationErrorMessage: string;
}

const ml = {
  SimpleLinearRegression,
  PolynomialRegression,
  TheilSenRegression,
  RobustPolynomialRegression,
};

export interface IJavascriptArgs extends IJavascriptBaseArgs {
  input?: any;
  inputGlobal?: string;
  inputWidget?: string;
  output?: any;
  external?: any;
  properties?: any;
  currentProperties?: any;
  pipelineManager?: PipelineManager;
  pipelineExecutor?: PipelineExecutor;
  permissionGetAccess?: any;
  functions?: any;
  mailgunClient?: any;
}

export const setMailgun = (mailgunJs) => {
  mailgunClient = mailgunJs;
};

const evaluate = (executor: PipelineExecutor, stage: StageImpl) => {
  const { required, requested } = extractRequestedFields(executor, stage);
  stage.requestedFields = requested;
  stage.requiredFields = required;
};

const execute = async (executor: PipelineExecutor, stage: StageImpl) => {
  const jsArgs = await makeJsArgs(executor, stage);
  const workingProperties =
    stage.workingProperties as IStagePropertiesJavascript;
  await executeJavascript({
    jsCodeToExecute: workingProperties.code,
    jsArgs,
    evaluationErrorMessage: workingProperties.evaluationErrorMessage,
  });
};

export const makeJsArgs = async (
  executor: PipelineExecutor,
  stage: StageImpl,
): Promise<IJavascriptArgs> => {
  // This is setup by the client code
  const userInfo = executor.input._userInfo;

  // This is a convenience function to check an application permission
  // with only a single argument
  const permissionGetAccess = (qual): AccessType => {
    if (!userInfo) {
      // This can happen in tests and remote pipeline execution
      return AccessType.None;
    }
    return executor.pipelineManager.clientManager.permissionManager.getAccess(
      userInfo.permissions,
      PermissionType.Programmatic,
      qual,
    );
  };

  const jsArgs: IJavascriptArgs = {
    input: executor.input,
    inputGlobal: executor.inputGlobal,
    inputWidget: executor.inputWidget,
    output: executor.output,
    external: executor.external,
    properties: executor.properties,
    currentProperties: stage.workingProperties,
    clientManager: executor.pipelineManager.clientManager,
    pipelineManager: executor.pipelineManager,
    pipelineExecutor: executor,
    permissionGetAccess,
    libraries: executor.pipelineManager.javascriptStageLibraries,
    functions: executor.pipelineManager.javascriptStageFunctions,
    _,
    mailgunClient,
    logger,
    dayjs,
    dayjsOriginal,
    ml,
  };
  return jsArgs;
};

const extractRequestedFields = (
  executor: PipelineExecutor,
  stage: StageImpl,
): { requested: string[]; required: string[] } => {
  const workingProperties =
    stage.workingProperties as IStagePropertiesJavascript;
  const { code } = workingProperties;
  try {
    let required = [];
    const parsed: Program = Parser.parse(code, {
      ecmaVersion: 'latest',
      allowReturnOutsideFunction: true,
      allowAwaitOutsideFunction: true,
      onComment: (block: boolean, text: string) => {
        const requiredPos = text.indexOf(REQUIRE_DIRECTIVE);
        if (requiredPos < 0) {
          return;
        }
        const fields = text
          .slice(requiredPos + REQUIRE_DIRECTIVE.length)
          .trim()
          .split(',')
          .map((f) => f.trim());
        required = required.concat(fields);
      },
    }) as unknown as Program;

    const isInputDeclarator = (declarator: VariableDeclarator) => {
      if (
        !(
          declarator.type === 'VariableDeclarator' &&
          declarator.id.type === 'ObjectPattern'
        ) ||
        !declarator.init
      ) {
        return false;
      }

      if (
        declarator.init.type === 'Identifier' &&
        declarator.init.name.startsWith('input')
      ) {
        return true;
      }

      if (declarator.init.type === 'MemberExpression') {
        let lookMemberExpression = declarator.init;
        while (true) {
          if (lookMemberExpression.object!.type === 'MemberExpression') {
            lookMemberExpression = lookMemberExpression.object;
            continue;
          }
          if (lookMemberExpression.object!.type === 'Identifier') {
            return lookMemberExpression.object.name === 'input';
          }
        }
      }
      return false;
    };

    const requested = [];
    parsed.body.forEach((statement) => {
      const st = statement as Statement;
      if (st.type === 'VariableDeclaration') {
        st.declarations
          .filter((d) => isInputDeclarator(d))
          .forEach((d) => {
            processProperties(
              (d.id as ObjectPattern).properties as AssignmentProperty[],
              requested,
            );
            if (d.init.type === 'MemberExpression') {
              processMemberExpressions(d.init, requested, `${requested.pop()}`);
            }
          });
      }
    });

    for (const requiredField of required) {
      if (!requested.find((requestedF) => requestedF === requiredField)) {
        throw new Error(
          `Required field ${requiredField} not present in requested fields: ${safeJsonStringify(
            requested,
          )}`,
        );
      }
    }

    return { required, requested };
  } catch (error) {
    try {
      handleError(error, stage.originalProperties.code);
    } catch (err) {
      workingProperties.evaluationErrorMessage = err.message;
      executor.failedValidation = true;
      reThrow({
        logger,
        message: `Problem pre-processing Javascript in ${
          executor.tracingIdentifier
        }-${stage.traceId()}`,
        error: err,
        noThrow: true,
      });
      // Do not throw, as we can and should continue, errors will be caught when the code is actually executed
    }
    return { required: [], requested: [] };
  }
};

const processMemberExpressions = (
  memberExpression: MemberExpression,
  fields: string[],
  suffixFields?: string,
) => {
  const suffix = suffixFields ? `.${suffixFields}` : '';
  if (
    memberExpression.object?.type === 'Identifier' &&
    memberExpression.object?.name === 'input'
  ) {
    if (memberExpression.property.type === 'Identifier') {
      fields.push(`${memberExpression.property.name}${suffix}`);
    } else if (memberExpression.property.type === 'Literal') {
      fields.push(`${memberExpression.property.value}${suffix}`);
    } else {
      // ??? check the type here
      processProperties(
        [memberExpression.property as unknown as AssignmentProperty],
        fields,
        suffix,
      );
    }
  } else if (memberExpression.object) {
    processMemberExpressions(
      memberExpression.object as MemberExpression,
      fields,
      `${(memberExpression.property as Identifier).name}${suffix}`,
    );
  }
};

const processProperties = (
  properties: AssignmentProperty[],
  fields: string[],
  prefixFields?: string,
) => {
  const prefix = prefixFields ? `${prefixFields}.` : '';
  properties.forEach((p) => {
    if (p.value.type === 'Identifier') {
      fields.push(`${prefix}${p.value.name}`);
    } else if (p.value.type === 'ObjectPattern') {
      processProperties(
        p.value.properties as AssignmentProperty[],
        fields,
        `${prefix}${(p.key as Identifier).name}`,
      );
    }
  });
};

export function initialize(stageInfo: IStageInfo) {
  stageInfo.executor = execute;
  stageInfo.evaluator = evaluate;
}
