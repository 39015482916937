import { MetadataSupport } from '../../metadataSupport';
import { PipelineExecutor } from '../pipelineExecutor';
import { IStageInfo, IStageProperties } from '../stage';
import { StageImpl } from '../stageImpl';

export interface IStagePropertiesExecPipeline extends IStageProperties {
  pipelineName: string;
  remote: boolean;
  remoteAsync: boolean;
  stages: IStageProperties[];
  mergeIntoOutput: boolean;
}

const execute = async (executor: PipelineExecutor, stage: StageImpl) => {
  const { pipelineName, remote, remoteAsync, mergeIntoOutput, stages } =
    stage.workingProperties as IStagePropertiesExecPipeline;

  let result;

  const qualifiedPipelineName = MetadataSupport.getQualifiedName(
    pipelineName,
    executor.configName,
  );

  const input = {
    ...executor.input,
    ...executor.output,
  };

  const params = {
    name: qualifiedPipelineName,
    input,
    stages,
  };

  const { pipelineManager } = executor;
  if (!remote && !remoteAsync) {
    const execParams = { ...executor.params, ...params, callback: undefined };
    if (params.name) {
      result = await pipelineManager.executeNamedPipeline(execParams);
    } else {
      result = await pipelineManager.executePipelineObject(execParams);
    }
  } else if (!remoteAsync) {
    result = await pipelineManager.executePipelineRemote(params);
  } else {
    await pipelineManager.executePipelineRemoteAsync(params);
  }

  if (mergeIntoOutput) {
    // Don't record the result (below) in this case because this will add a field
    // in the output corresponding this stage name which might conflict with
    // a field in the output of the pipeline being executed. The mergeIntoOutput
    // should behave like the executed stages were part of the calling pipeline
    Object.assign(executor.output, result);
  } else {
    executor.recordResult(stage, result);
  }
};

export function initialize(stageInfo: IStageInfo) {
  stageInfo.executor = execute;
}
