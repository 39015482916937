import {
  ApolloClient,
  ApolloLink,
  FetchPolicy,
  NormalizedCacheObject,
} from '@apollo/client/core';
import { Lambda } from '@aws-sdk/client-lambda';
import { SQS } from '@aws-sdk/client-sqs';
import { STS } from '@aws-sdk/client-sts';
import { FetchHttpHandler } from '@smithy/fetch-http-handler';
import { isBrowser } from 'browser-or-node';
import { v1 as uuidv1 } from 'uuid';

import { IMqttProvider } from 'universal/mqttProvider';
import {
  createGraphQLClient,
  shutdownGraphQLClient,
} from './apolloClient/graphQlClient';
import { AppDefConfigManager } from './appDefConfigManager';
import { ApplicationManager } from './applicationManager';
import { CodeSupport } from './codeSupport';
import {
  BASE_QUEUE_NAME,
  CLIENT_ROLE,
  COMPANY_NAME,
  DEV_STACK_TYPE,
  ImportMapType,
  PROD_STACK_TYPE,
} from './common/commonConstants';
import { IAWSConfig, getInternalStackDomain } from './common/commonUtilities';
import { setDayjsDefaultLocale } from './common/dayjsSupport';
import * as github from './common/github';
import { S3Support } from './common/s3Support';
import * as commonTest from './commonTest';
import { aggregate } from './data/aggregate';
import { DataOperation } from './data/dataOperation';
import { DistributeData } from './data/distributeData';
import { HashSupport } from './data/hashSupport';
import { ValidationSupport } from './data/validationSupport';
import { EntityKeySupport } from './entityKeySupport';
import { reThrow } from './errors/errorLog';
import { getErrorString } from './errors/errorString';
import { retry } from './errors/retry';
import { ExcelSupport } from './excelSupport';
import { GRAPHQL_FUNCTION, LambdaSupport } from './lambdaSupport';
import * as loadStore from './loadStore/loadstore';
import { Loggers, getLogger } from './loggerSupport';
import { MetadataSupport } from './metadataSupport';
import * as metadataSupportConstants from './metadataSupportConstants';
import { MetadataVisitor } from './metadataVisitor';
import { createMqttClient } from './mqttProvider';
import { OptimizerSupport } from './optimizerSupport';
import { PermissionManager } from './permissionManager';
import {
  IServerExecutionContext,
  PipelineManager,
} from './pipeline/pipelineManager';
import { ScenarioSupport } from './scenarioSupport';
import { ISchemaManagerInitParams, SchemaManager } from './schemaManager';
import * as sizeClass from './sizeClass';
import { IStackConfig, StackInfo } from './stackInfo';
import * as testSupport from './testSupport';
import { UserSupport } from './userSupport';
import * as utilityFunctions from './utilityFunctions';
import { WaitNotify } from './waitNotify';
import { ZendeskSupport } from './zendeskSupport';

export const DEVOPS_EMAIL_PROD = 'devops@snapstrat.com';
export const DEVOPS_EMAIL_TEST = 'devopsemailtest@snapstrat.com';

const logger = getLogger({ name: Loggers.CLIENT_MANAGER });

export type ClientManagerCreator = () => ClientManager;

export interface ICreateClientManagerBaseParams {
  awsConfig?: IAWSConfig;

  // This is created as early as possible
  stackInfo?: StackInfo;

  // A client manager previously created in boot mode
  clientManager?: ClientManager;

  boot?: boolean;

  ignoreTestUrl?: boolean;

  schemaManagerParams?: ISchemaManagerInitParams;

  // Function to create the actual ClientManager object
  clientManagerCreator?: ClientManagerCreator;

  // Function to initialize ClientManager before communication
  clientManagerInitializer?: (clientManager: ClientManager) => Promise<void>;

  idToken?: string;

  // Used only by the apollo server for local requests
  localLink?: ApolloLink;

  configsImportMap?: ImportMapType;
}

export type ICreateClientManagerParams = ICreateClientManagerBaseParams;

export async function createClientManager(
  params: ICreateClientManagerParams,
): Promise<ClientManager> {
  let { clientManager, awsConfig } = params;
  const {
    stackInfo,
    boot,
    schemaManagerParams,
    ignoreTestUrl,
    clientManagerCreator,
    clientManagerInitializer,
    idToken,
    configsImportMap,
  } = params;

  const startTime = Date.now();
  logger.debug('Client manager is starting to create clients');

  let existingClientManager;
  if (!clientManager) {
    clientManager = clientManagerCreator
      ? clientManagerCreator()
      : new ClientManager();
    clientManager.stackId = stackInfo.baseStackInfo.stackId;
    clientManager.utilityFunctions = utilityFunctions;
    clientManager.aggregate = aggregate;
    clientManager.loadStore = loadStore;
    clientManager.metadataSupportConstants = metadataSupportConstants;
    clientManager.github = github;
    clientManager.sizeClass = sizeClass;
    clientManager.testSupport = testSupport;
    clientManager.commonTest = commonTest;
    clientManager.stackInfo = stackInfo;
    clientManager.mqttProvider = { createMqttClient };
    clientManager.configsImportMap = configsImportMap;
    if (!awsConfig) {
      awsConfig = await clientManager.getAwsClientConfig(idToken);
    }
  } else {
    existingClientManager = true;
    logger.debug('ClientManager - starting using existing');
    awsConfig = clientManager.awsConfig;
  }

  stackInfo.awsConfig = awsConfig;

  if (!clientManager.stackInfo && !stackInfo) {
    throw new Error(
      'The stackInfo option must be specified or an existing ClientManager provided that includes a stackInfo object',
    );
  }

  const finishStartup = () => {
    clientManager.booting = boot;
    logger.debug(`All clients created in ${Date.now() - startTime}ms`);
  };

  try {
    if (!existingClientManager) {
      clientManager.awsConfig = awsConfig;

      // AWS Clients
      clientManager.sqsClient = new SQS(awsConfig);
      clientManager.lambdaClient = new Lambda(awsConfig);

      // Boot Managers/support classes - all need to be created before any are initialized
      clientManager.metadataSupport = new MetadataSupport();
      clientManager.MetadataSupport = MetadataSupport;
      clientManager.metadataVisitor = new MetadataVisitor();
      clientManager.schemaManager = new SchemaManager();
      clientManager.entityKeySupport = new EntityKeySupport();
      clientManager.excelSupport = new ExcelSupport();

      // The path.sep is reset in ClientManagerServer for the node environment
      clientManager.s3Support = new S3Support(stackInfo, '/');
      clientManager.zendeskSupport = new ZendeskSupport();

      clientManager.zendeskSupport.initialize(clientManager);
      clientManager.metadataSupport.initialize(clientManager);
      clientManager.metadataVisitor.initialize(clientManager);
      await clientManager.schemaManager.initialize(
        clientManager,
        schemaManagerParams,
      );
      clientManager.entityKeySupport.initialize(clientManager);
      clientManager.excelSupport.initialize(clientManager);
      clientManager.stackType = stackInfo.baseStackInfo.stackType;

      clientManager.lambdaSupport = new LambdaSupport({
        clientManager,
        stackInfo,
        ignoreTestUrl,
        lambdaClient: clientManager.lambdaClient,
      });
    }

    if (boot) {
      logger.info('ClientManager started in boot mode');
      finishStartup();
      return clientManager;
    }

    if (!idToken && !awsConfig) {
      throw new Error(
        'Attempting to start ClientManager without idToken or awsConfig',
      );
    }

    logger.debug('ClientManager - starting full initialization');

    clientManager.idToken = idToken;

    // Don't need (want) to do this in boot mode as there should be no reason
    // to access the lambda clients when the ClientManager is in that state.
    await clientManager.lambdaSupport.setupLambdaClients();

    clientManager.pipelineManager = new PipelineManager();
    clientManager.createApolloClientAndInitializePipelineManager({
      idToken,
      ...params,
    });
    logger.debug('ClientManager - after apolloclient and pipelinemanager');

    await clientManager.schemaManager.initializeAfterPipelineManager();
    logger.debug('ClientManager - after initializeAfterPipelineManager');
    clientManager.metadataSupport.initializeAfterFullStartup(clientManager);

    if (clientManagerInitializer) {
      await clientManagerInitializer(clientManager);
    }

    clientManager.appDefConfigManager = new AppDefConfigManager();
    clientManager.appDefConfigManager.initialize(clientManager);

    clientManager.permissionManager = new PermissionManager();
    clientManager.codeSupport = new CodeSupport();
    clientManager.userSupport = new UserSupport();
    clientManager.hashSupport = new HashSupport();
    clientManager.distributeData = new DistributeData();
    clientManager.dataOperation = new DataOperation();
    clientManager.scenarioSupport = new ScenarioSupport();
    clientManager.optimizerSupport = new OptimizerSupport();
    clientManager.waitNotify = new WaitNotify();
    clientManager.applicationManager = new ApplicationManager();
    clientManager.validationSupport = new ValidationSupport();
    logger.debug('ClientManager - after many misc initialization');

    await clientManager.permissionManager.initialize({
      clientManager,
      idToken,
    });
    logger.debug('ClientManager - after PermissionManager initialization');
    clientManager.codeSupport.initialize(clientManager);
    clientManager.userSupport.initialize(clientManager);
    clientManager.hashSupport.initialize(clientManager);
    clientManager.distributeData.initialize(clientManager);
    clientManager.dataOperation.initialize(clientManager);
    clientManager.scenarioSupport.initialize(clientManager);
    clientManager.optimizerSupport.initialize(clientManager);
    clientManager.waitNotify.initialize(clientManager);

    clientManager.applicationManager.initialize(clientManager);
    clientManager.validationSupport.initialize(clientManager);

    finishStartup();
    return clientManager;
  } catch (error) {
    reThrow({ logger, error, message: 'ClientManager start error' });
  }
}

// WARNING - the ClientManager may not depend on anything outside of the universal
// package. Sadly, there is no tooling check for this, since all packages are included
// in node_modules at the top level (thanks to yarn workspaces), but if this is violated
// the build will fail in serverless packing. If you need a class that's in another
// package, add it to clientManagerServer.
export class ClientManager {
  public clientId: string = uuidv1();

  public stackId: string;
  public stackType: string;

  public idToken: string;

  public booting: boolean;

  // The fetch service used by the GraphQL client. This in only required for
  // the node environment
  public fetch: any;

  public awsConfig: IAWSConfig;
  public sqsClient: SQS;
  public pipelineManager: PipelineManager;
  public appDefConfigManager: AppDefConfigManager;
  public permissionManager: PermissionManager;
  public lambdaClient: Lambda;
  public zendeskSupport: ZendeskSupport;

  public apolloClient: ApolloClient<NormalizedCacheObject>;
  public mqttProvider: IMqttProvider;

  public codeSupport: CodeSupport;
  public userSupport: UserSupport;
  public hashSupport: HashSupport;
  public distributeData: DistributeData;
  public dataOperation: DataOperation;
  public scenarioSupport: ScenarioSupport;
  public optimizerSupport: OptimizerSupport;
  public waitNotify: WaitNotify;
  public applicationManager: ApplicationManager;

  public lambdaSupport: LambdaSupport;
  public s3Support: S3Support;
  public entityKeySupport: EntityKeySupport;
  public excelSupport: ExcelSupport;
  public schemaManager: SchemaManager;
  public metadataSupport: MetadataSupport;
  // Allows for access to the static methods
  public MetadataSupport: typeof MetadataSupport;
  public validationSupport: ValidationSupport;
  public metadataVisitor: MetadataVisitor;
  public stackInfo: StackInfo;

  private defaultLocale: string;

  // Allow access to functions and constants from these modules
  public metadataSupportConstants;
  public utilityFunctions;
  public aggregate: typeof aggregate;
  public loadStore;
  public testSupport;
  public commonTest;
  public sizeClass;
  public github;

  // Used when running in a pipelineHandler* function
  public serverExecutionContext: IServerExecutionContext;

  public configsImportMap: ImportMapType;

  // Can be used to reset the apolloClient as well
  public createApolloClientAndInitializePipelineManager(params: {
    idToken: string;
    localLink?: ApolloLink;
    defaultFetchPolicy?: FetchPolicy;
  }) {
    this.apolloClient = createGraphQLClient({
      clientManager: this,
      mqttProvider: this.mqttProvider,
      lambdaClient: this.lambdaSupport.getLambdaClient(GRAPHQL_FUNCTION),
      mqttEndpoint: this.getStackConfig().mqttEndpoint,
      ...params,
    });
    this.pipelineManager.initialize(this);
  }

  // Used for tests to make sure the subscriptions links are stopped
  public async shutdown() {
    await shutdownGraphQLClient(this.apolloClient);
  }

  private getAwsRole(roleName: string) {
    const { awsAccountId } = this.stackInfo.getStackConfig();
    try {
      return `arn:aws:iam::${awsAccountId}:role/${this.stackId}_${roleName}`;
    } catch (error) {
      throw new Error(`Problem getting role: ${getErrorString(error)}`);
    }
  }

  private async getClientSessionCredentials(token: string) {
    const awsConfig = this.stackInfo.getAwsConfig();
    const sts = new STS({
      ...awsConfig,
      endpoint: `https://sts.${awsConfig.region}.amazonaws.com`,
    });
    let role;
    try {
      role = this.getAwsRole(CLIENT_ROLE);
      const result = await retry({
        command: async () =>
          sts.assumeRoleWithWebIdentity({
            RoleArn: role,
            WebIdentityToken: token,
            RoleSessionName: this.stackId,
            DurationSeconds: 43000,
          }),
        // Small number here because this is what happens when there is no backend
        tryTimes: 3,
      });
      return result.Credentials;
    } catch (error) {
      throw new Error(
        `Problem: when getting credentials for role: ${role} using token ${token} - 
        ***  - This is likely caused by a problem setting up the ${
          this.stackInfo.getStackConfig().product
        } role.\n` +
          `***  - If you are developer, make sure the configuration file in .${COMPANY_NAME} has the correct STACK_ID, and the stack is actually present\n Error: ${getErrorString(
            error,
          )}`,
      );
    }
  }

  // Used to connect to AWS services. The available services are defined by the IAM identity provider role.
  public async getAwsClientConfig(jwtToken: string) {
    try {
      PermissionManager.basicValidateIdToken(jwtToken);
    } catch (error) {
      reThrow({
        logger,
        error,
        message:
          'Problem validating ID Token, if expired reauthentication is necessary',
      });
    }
    const creds = await retry({
      command: () => this.getClientSessionCredentials(jwtToken),
    });
    return Object.assign(
      {
        credentials: {
          accessKeyId: creds.AccessKeyId,
          secretAccessKey: creds.SecretAccessKey,
          sessionToken: creds.SessionToken,
        },
      },
      this.stackInfo.getAwsConfig(),
      { requestHandler: new FetchHttpHandler({ requestTimeout: 0 }) },
    );
  }

  public testKilled: boolean;

  /**
   * Used when the process must go away
   *
   * This can be because the credentials (idToken) associated with the process
   * is (about to) expire, or a stackId mismatch is detected between the client
   * and server (normally caused by a new version upgrade).
   */
  public killMe(justTesting?: boolean) {
    if (justTesting) {
      logger.info('killMe - testing');
      this.testKilled = true;
      return;
    }
    if (isBrowser) {
      logger.info('Refresh due to being killed (usually for stackId mismatch)');
      this.pipelineManager.refreshCallback();
    }
    // In the node case, this is overridden
  }

  public isDevelopmentStack(): boolean {
    return this.stackType === DEV_STACK_TYPE;
  }

  public static isDevelopmentStack(stackType: string): boolean {
    return stackType === DEV_STACK_TYPE;
  }

  public isProductionStack(): boolean {
    return this.stackType === PROD_STACK_TYPE;
  }

  public throwIfProd(errorMessage) {
    if (this.isProductionStack()) {
      throw new Error(errorMessage);
    }
  }

  // Friendly name for the non-production stack (to be displayed in the nav bar)
  public getStackTypeName(): string {
    if (this.stackType === PROD_STACK_TYPE) {
      return null;
    }
    return this.stackType;
  }

  public getStackConfig(): IStackConfig {
    return this.stackInfo.getStackConfig();
  }

  public async getStackConfigWithRefresh(): Promise<IStackConfig> {
    return (await this.stackInfo.refreshStackInfo()).getStackConfig();
  }

  public setDefaultLocale(locale: string) {
    logger.info(`Setting default locale to ${locale}`);
    this.defaultLocale = locale;
    setDayjsDefaultLocale(locale);
  }

  public getDefaultLocale(): string {
    return this.defaultLocale;
  }

  public getDevopsEmailTest() {
    return DEVOPS_EMAIL_TEST;
  }

  public getDevopsEmail() {
    if (this.isDevelopmentStack()) {
      return DEVOPS_EMAIL_TEST;
    } else {
      return DEVOPS_EMAIL_PROD;
    }
  }

  public getSqsQueueUrlPrefix() {
    return `https://sqs.${this.awsConfig.region}.amazonaws.com/${
      this.getStackConfig().awsAccountId
    }/${this.stackId}_`;
  }

  public getSqsQueueUrl(params?: {
    queueName?: string;
    queueNameExtension?: string;
  }) {
    const { queueName, queueNameExtension } = params || {};
    return (
      this.getSqsQueueUrlPrefix() +
      (queueName
        ? queueName
        : queueNameExtension
          ? BASE_QUEUE_NAME + queueNameExtension
          : BASE_QUEUE_NAME)
    );
  }

  // Used only in node environment
  public getInternalSiteUrl() {
    return getInternalStackDomain(this.stackId);
  }

  // FIXME - probably not the best place for this, but all of this is going away in any case
  // with the typescript move

  public getLocalConfigObject(params: {
    objectName: string;
    recordType: string;
  }): any {
    // Overridden in the server
    return undefined;
  }
}
