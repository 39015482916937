import jwt_decode from 'jwt-decode';
import _ from 'lodash';
import safeJsonStringify from 'safe-json-stringify';

import { ClientManager } from './clientManager';
import { SYSTEM } from './common/commonConstants';
import { reThrow } from './errors/errorLog';
import { getConfigItemsForType } from './loadStore/loadstore';
import { getLogger, Loggers } from './loggerSupport';
import { MetadataSupport } from './metadataSupport';
import {
  APP_DEF_PERMISSION,
  CONFIG_SEPARATOR,
  IKeyValueType,
  INameInfo,
} from './metadataSupportConstants';
import { SchemaManager } from './schemaManager';
import { StackInfoKeys } from './stackInfo';
import { isNullOrUndefined } from './utilityFunctions';

const logger = getLogger({ name: Loggers.PERMISSION_MANAGER });

// Special permissions that we need to know about

export const SERVERUSER = 'system:serveruser';
export const SYSADMINAPP = 'system:sysadminapp';
export const IMPERSONATEROLE = 'system:impersonaterole';
export const ALLOWMAINTENANCEACCESS = 'system:allowmaintenanceaccess';
export const IDTOKENKEY = 'idtokens';

// FIXME - have schema system express this

export enum AccessType {
  None = 0,
  Read = 1 << 0,
  Create = 1 << 1,
  Update = 1 << 2,
  Delete = 1 << 3,

  Write = Create | Update | Delete,
  All = Read | Write,
  Use = Read,
  Assign = Write,
}

// FIXME - have schema system express this, these values express order in the TypeDefinition

export enum PermissionType {
  Data = 0,
  Navigation = 1,
  Widget = 2,
  Programmatic = 3,
  Application = 4,
}

// FIXME - this is an artifact of the RoleUse type - see WORM-2317
// Only exists to support IDBUser - will be replaced by IUser once that's fixed
interface IUserBase {
  // Database Id of the user
  id?: string;
  email: string;
  userName?: string;
  configName?: string;
  apiKey?: string;
  properties?: IKeyValueType[];
  // Tags used for external systems like Zendesk and Auth0
  tags?: string[];
}

interface IPermissions {
  // By PermissionType
  [permissionType: number]: IPermission[];
}

// External facing user information used by the rest of the system
export interface IUserInfo extends IUserBase {
  permissions?: IPermissions;
  impersonatedRole?: IRole;
  roles?: IRole[];

  nickname?: string;
  picture?: any;

  githubToken?: string;
}

// Used to provide the just the permissions information to auth0
export interface IUserPermissions {
  idr: string;
  configName?: string;
  permissions: IPermission[];
}

// Matches typedef
// FIXME - this is an artifact of the RoleUse type - see WORM-2317
interface IDBUser extends IUserBase {
  permissions?: IPermission[];

  impersonatedRole?: IRoleUse;
  roles?: IRoleUse[];
}

// Matches typedef
// FIXME - this is an artifact of the RoleUse type - see WORM-2317
export interface IRoleUse {
  role: IRole;
}

// Matches typedef
export interface IRole {
  id: string;
  nameInfo: INameInfo;
  properties: IKeyValueType[];
  permissions: IPermission[];
  primary: boolean;
  rank: number;
}

// Matches typedef
export interface IPermissionUse {
  permission: IPermission;
  isNegative?: boolean;
}

export interface IItemValueFilter {
  // Qualified entity id
  entityId: string;

  // Field path relative to the entity - not required if filter is specified
  fieldPath: string;

  // Only one of the the following fields (this should really be a union, but we don't support this in our definitions).

  // Exact match on the field with a string
  matchString?: string;
  // Regexp match
  matchRegexp?: string;
  // Property key match (matches any value exactly that's present for this property key)
  propertyKey?: string;

  // Filter as would be specified with the @filter directive (does not require the field name)
  filter?: string;
}

// Matches typedef
export interface IPermission {
  id: string;
  configName: string;
  nameInfo: INameInfo;
  priority: number;
  permissionType: PermissionType;
  accessTypes: AccessType;
  negative: boolean;
  // Data permissions only Accepts (or rejects if negative) items returns from the query based on matching the filters
  itemValueFilters: IItemValueFilter[];
  // Other than data permissions
  qualifiers: IKeyValueType[];
  enabled: string[];
  successful: string[];
  childPermissions: IPermissionUse[];
}

// FIXME - eliminate the need for this
export interface IDBPermission {
  id: string;
  nameInfo: INameInfo;
  priority: number;
  permissionType: string;
  accessTypes: string[];
  negative: boolean;
  qualifiers: IKeyValueType[];
  itemValueFilters: IItemValueFilter[];
  enabled: string[];
  successful: string[];
  childPermissions: IPermissionUse[];
}

export interface IValidateUserResults {
  userInfo?: IUserInfo;
  failReason?: string;
  failMessages?: { [locale: string]: string };
}

export class PermissionManager {
  private clientManager: ClientManager;
  private metadataSupport: MetadataSupport;

  private permissionTypeDef;

  // Permissions read from the database, used for diagnostic purposes
  private dbPermissions: any;

  private allPermissions: { [permissionId: string]: IPermission };

  private idTokenMap: { [idToken: string]: IUserInfo };
  public schemaManger: SchemaManager;

  private currentUserInfo: IUserInfo;

  // Used to not recurse in the loadPermissions query
  private loadingPermissions: boolean;

  // Used for tests
  public foundErrors: boolean;

  // Right now there is one for the whole stack
  private githubToken: string;

  public async initialize(params: {
    clientManager: ClientManager;
    idToken: string;
  }) {
    const { clientManager, idToken } = params;

    this.clientManager = clientManager;
    this.metadataSupport = clientManager.metadataSupport;
    this.schemaManger = clientManager.schemaManager;
    this.idTokenMap = {};

    this.githubToken = clientManager.getStackConfig().githubToken;

    this.permissionTypeDef = this.schemaManger.getTypeDefinition({
      name: 'system:PermissionTypes',
    });
    try {
      this.loadAllPermissions();

      if (idToken) {
        const userInfo = this.makeUserInfo({ idToken });
        const dbUser = await this.readUserFromDb(userInfo.id);
        if (!dbUser) {
          throw new Error(
            `Login user ${userInfo.id} not found in execution config: ${this.schemaManger.executionConfigName}, cannot initialize`,
          );
        }
        // Do this to add the idToken to the cache, so the subsequent getUserInfo
        // does not cause a DB read.
        this.currentUserInfo = await this.getUserInfo({ idToken, dbUser });
      }
    } catch (error) {
      reThrow({
        logger,
        error,
        message: 'permissionManager failed to initialize',
      });
    }
  }

  private loadAllPermissions() {
    const appDefs = this.clientManager.stackInfo.getObject(
      StackInfoKeys.APP_DEFS,
    );
    this.dbPermissions = getConfigItemsForType(appDefs, APP_DEF_PERMISSION);

    // FIXME - should allow non-fully qualified qualifier values (e.g. BrandPortal instead of sephora:BrandPortal), but
    // right now we don't have the config name of the permission since it's not implemented yet, so let's assume
    // they are fully qualified

    this.allPermissions = {};
    const badPermissions: Set<string> = new Set();
    this.dbPermissions.forEach((p) => {
      const permission: IPermission = this.permissionFromDatabase(p);
      this.allPermissions[p.id] = permission;
      if (!permission.accessTypes) {
        logger.error(`Missing accessTypes on permission: ${permission.id}`);
        this.foundErrors = true;
        badPermissions.add(permission.id);
      }
    });

    const fixChildPermissions = (
      permission: IPermission,
      seenPermissions: Record<string, any>,
    ) => {
      if (seenPermissions[permission.id]) {
        badPermissions.add(permission.id);
        logger.error(`Recursion in child permissions at: ${permission.id}`);
        this.foundErrors = true;
        return;
      }
      seenPermissions[permission.id] = permission;
      if (!permission.childPermissions) {
        return;
      }
      permission.childPermissions = permission.childPermissions.map(
        (p: IPermissionUse) => {
          if (!p.permission) {
            badPermissions.add(permission.id);
            logger.error(
              `Unexpected null child permission in permission: ${permission.id}`,
            );
            this.foundErrors = true;
            return;
          }
          const childPermission =
            this.allPermissions[
              MetadataSupport.getQualifiedName(
                p.permission.id,
                permission.configName,
              )
            ];
          if (!childPermission) {
            badPermissions.add(permission.id);
            logger.warn(
              `Child permission: ${p.permission.id} not found in permission: ${permission.id}`,
            );
            this.foundErrors = true;
            return;
          }
          if (childPermission.permissionType !== permission.permissionType) {
            badPermissions.add(permission.id);
            logger.error(
              `Child permission: ${p.permission.id} must have the same permission type as: ${permission.id}`,
            );
            this.foundErrors = true;
            return;
          }
          fixChildPermissions(childPermission, seenPermissions);
          const retValue: IPermissionUse = { permission: childPermission };
          if (p.isNegative) {
            retValue.isNegative = true;
          }
          return retValue;
        },
      );
    };

    Object.keys(this.allPermissions).forEach((k) =>
      fixChildPermissions(this.allPermissions[k], {}),
    );
    if (badPermissions.size > 0) {
      logger.warn(
        `Invalid permissions encountered in: ${safeJsonStringify(
          this.dbPermissions,
          null,
          2,
        )}`,
      );
    }
    badPermissions.forEach((p) => {
      delete this.allPermissions[p];
      logger.warn(`Ignoring invalid permission ${p}`);
      this.foundErrors = true;
    });
  }

  public isLoadingPermissions(): boolean {
    return this.loadingPermissions;
  }

  private async readUserFromDb(userName: string): Promise<IDBUser> {
    this.loadingPermissions = true;
    const user: IDBUser =
      await this.clientManager.pipelineManager.executeGraphqlQuery({
        query: `
        query { getUser(id: "${userName}") { 
          id userName apiKey properties { key value } 
          impersonatedRole { role { id nameInfo { displayName abbreviation } permissions { permission { id } } } }
          roles { role { id rank nameInfo { displayName abbreviation } permissions { permission { id } } } }
          permissions { permission { id } }
        } }`,
        noCache: true,
        useLocalLink: true,
      });

    this.loadingPermissions = false;
    if (user) {
      user.configName = this.clientManager.schemaManager.executionConfigName;
    }
    return user;
  }

  private async populateUserInfoFromDb(params: {
    userInfo: IUserInfo;
    dbUser?: IDBUser;
  }) {
    const { userInfo } = params;
    let { dbUser } = params;

    const effectivePermissions: IPermissions = {};
    effectivePermissions[PermissionType.Widget] = [];
    effectivePermissions[PermissionType.Data] = [];
    effectivePermissions[PermissionType.Navigation] = [];
    // Remember these for diagnostic purposes
    effectivePermissions[PermissionType.Programmatic] = [];
    effectivePermissions[PermissionType.Application] = [];

    if (!dbUser) {
      dbUser = await this.readUserFromDb(userInfo.id || userInfo.email);
      if (!dbUser) {
        throw new Error(
          `Failed to find user ${userInfo.id} in the database, exec config:  ${this.clientManager.schemaManager.executionConfigName}`,
        );
      }
    }

    userInfo.properties = dbUser.properties;
    userInfo.userName = dbUser.userName;
    userInfo.configName = dbUser.configName;
    userInfo.apiKey = dbUser.apiKey;
    userInfo.impersonatedRole = dbUser.impersonatedRole?.role;

    const processPermissions = (
      inputPermissions: Record<string, any>[],
      enclosingId: string,
    ) => {
      if (!inputPermissions) {
        return;
      }
      inputPermissions.forEach((p: any) => {
        if (!p.permission) {
          logger.warn(
            `null permission for role or user: ${enclosingId} - ignoring`,
          );
          return;
        }
        const foundP = this.allPermissions?.[p.permission.id];
        if (!foundP) {
          // There are no permissions (yet) when the stack is being created, let's not have an error
          if (Object.keys(this.allPermissions).length > 0) {
            logger.warn(
              `Permission id ${p.permission.id} not found for role or user: ${enclosingId} - ignoring`,
            );
          }
          return;
        }
        if (isNullOrUndefined(foundP.permissionType)) {
          logger.warn(
            `Permission id: ${p.permission.id} - missing permissionType - ignoring`,
          );
          return;
        }
        if (foundP.permissionType === PermissionType.Data) {
          if (!foundP.itemValueFilters) {
            throw new Error('A data permission requires itemValueFilters');
          }
          for (const itemValueFilter of foundP.itemValueFilters) {
            const {
              entityId,
              fieldPath,
              matchString,
              matchRegexp,
              propertyKey,
              filter,
            } = itemValueFilter;
            if (filter) {
              continue;
            }
            if (!entityId) {
              throw new Error('entityId is required');
            }
            if (!fieldPath) {
              throw new Error('fieldPath is required');
            }
            let count = 0;
            if (matchString) {
              count++;
            }
            if (matchRegexp) {
              count++;
            }
            if (propertyKey) {
              count++;
            }
            if (count !== 1) {
              throw new Error(
                'Exactly one of matchString, matchRegexp, or propertyKey must be specified',
              );
            }
          }
        }

        effectivePermissions[foundP.permissionType].push(foundP);
      });
    };

    processPermissions(dbUser.permissions, dbUser.id);
    userInfo.permissions = effectivePermissions;

    userInfo.roles = [];
    if (dbUser.impersonatedRole) {
      const impersonatedRole = dbUser.impersonatedRole.role;
      processPermissions(impersonatedRole.permissions, impersonatedRole.id);
      userInfo.roles.push(impersonatedRole);
    } else {
      if (dbUser.roles) {
        dbUser.roles.forEach((r) => {
          // FIXME - this should not be needed, role should always be populated
          if (r.role) {
            processPermissions(r.role.permissions, r.role.id);
            userInfo.roles.push(r.role);
          }
        });
      }
    }

    const tags = [`c_${this.clientManager.schemaManager.executionConfigName}`];
    userInfo.properties?.forEach((p) => tags.push(`o_${p.key}_${p.value}`));
    userInfo.permissions?.[PermissionType.Programmatic].forEach((p) =>
      tags.push(`p_${p.id}`),
    );
    userInfo.tags = tags;
  }

  private permissionFromDatabase(dbPermission: IDBPermission): IPermission {
    const permission: IPermission = _.cloneDeep(
      dbPermission,
    ) as unknown as IPermission;
    if (dbPermission.permissionType) {
      permission.permissionType = this.metadataSupport.enumStringToNumber(
        this.permissionTypeDef,
        dbPermission.permissionType,
      );
    }
    if (dbPermission.accessTypes) {
      dbPermission.accessTypes.forEach((at) => {
        switch (at) {
          case 'ALL':
            permission.accessTypes |= AccessType.All;
            break;
          case 'WRITE':
            permission.accessTypes |= AccessType.Write;
            break;
          case 'READ':
            permission.accessTypes |= AccessType.Read;
            break;
          case 'CREATE':
            permission.accessTypes |= AccessType.Create;
            break;
          case 'UPDATE':
            permission.accessTypes |= AccessType.Update;
            break;
          case 'DELETE':
            permission.accessTypes |= AccessType.Delete;
            break;
          case 'USE':
            permission.accessTypes |= AccessType.Use;
            break;
        }
      });
    }
    return permission;
  }

  private makeUserInfo(params: {
    idToken?: string;
    email?: string;
  }): IUserInfo {
    const { idToken, email } = params;

    if (idToken) {
      const parsedId = PermissionManager.decodeIdToken(idToken);
      logger.debug(
        `getUserInfo: parsedId: ${safeJsonStringify(parsedId.payloadObj)}`,
      );

      const userInfo: IUserInfo = {
        id: parsedId.email.toLowerCase(),
        email: parsedId.email,
        userName: parsedId.name,
        nickname: parsedId.nickname,
        picture: parsedId.picture,
        githubToken: this.githubToken,
      };
      return userInfo;
    } else if (email) {
      const userInfo: IUserInfo = {
        id: email.toLowerCase(),
        email,
      };
      return userInfo;
    } else {
      throw new Error('Specify either idToken or email');
    }
  }

  public static decodeIdToken(token: string): any {
    try {
      return jwt_decode(token);
    } catch (error) {
      reThrow({ logger, message: 'Invalid JWT token', error });
    }
  }

  // Used by Auth0 at signup time
  public async validateUser(email: string): Promise<IValidateUserResults> {
    const userInfo = this.makeUserInfo({ email });
    const dbUser = await this.readUserFromDb(userInfo.id);
    if (!dbUser) {
      return {
        failReason: 'User not found in database',
        failMessages: {
          en: `Your user id ${email} not found, please ensure the administrator has set this up`,
        },
      };
    }
    await this.populateUserInfoFromDb({ userInfo, dbUser });
    return { userInfo };
  }

  public async getUsers(): Promise<IUserInfo[]> {
    const dbUsersResult = await this.clientManager.pipelineManager.listRecords({
      entityName: 'User',
      configName: SYSTEM,
      noCache: true,
    });

    const users = [];
    const promises = [];
    dbUsersResult.items.forEach((u) =>
      promises.push(
        this.getUserInfo({ email: u.email }).then((ui) => users.push(ui)),
      ),
    );
    await Promise.all(promises);
    return users;
  }

  public getCurrentUserInfo() {
    return this.currentUserInfo;
  }

  public async getUserInfo(params: {
    idToken?: string;
    email?: string;
    dbUser?: IDBUser;
  }): Promise<IUserInfo> {
    const { idToken, email, dbUser } = params;

    let userInfo: IUserInfo;
    if (idToken) {
      logger.debug(`getUserInfo: ${idToken}`);
      userInfo = this.idTokenMap[idToken];
      if (userInfo) {
        return userInfo;
      }
      userInfo = this.makeUserInfo({ idToken });
      this.idTokenMap[idToken] = userInfo;
    } else if (email) {
      userInfo = this.makeUserInfo({ email });
    } else {
      throw new Error('Specified either idToken or email');
    }

    await this.populateUserInfoFromDb({ userInfo, dbUser });
    userInfo.roles.forEach((r) =>
      logger.debug(`Role: ${safeJsonStringify(r)}`),
    );
    Object.keys(userInfo.permissions).forEach((k) =>
      logger.debug(
        `User permission ${k}: ${safeJsonStringify(userInfo.permissions[k])}`,
      ),
    );

    return userInfo;
  }

  public async setImpersonatedRole(params: {
    userInfo: IUserInfo;
    role?: string;
  }) {
    const { userInfo, role } = params;
    const dbUser = await this.readUserFromDb(userInfo.id);
    delete dbUser.configName;
    const record = Object.assign({}, dbUser, {
      impersonatedRole: role ? { role } : null,
    });

    try {
      await this.clientManager.pipelineManager.updateRecord({
        entityName: 'User',
        configName: SYSTEM,
        record,
      });
    } catch (error) {
      reThrow({
        logger,
        message: `Failed to set impersonated role to ${role}`,
        error,
      });
    }
  }

  public async getAllRoles(params: { visibleOnly?: boolean }) {
    const { visibleOnly } = params;
    let userInfo, minRank;
    if (visibleOnly) {
      userInfo = this.getCurrentUserInfo();
      minRank = _.min(userInfo.roles.map((r) => r.rank));
    }
    const query = `query { 
        listRole { 
          items { 
            id rank primary nameInfo { displayName abbreviation shortDescription longDescription } 
            requiredProperties {property codeType}
          }
        } 
      }`;

    const roles = await this.clientManager.pipelineManager.executeGraphqlQuery({
      query,
    });
    return visibleOnly ? roles.filter((r) => !(r.rank < minRank)) : roles;
  }

  public static basicValidateIdToken(
    idToken: string,
    expirationDeltaFromNowSeconds = 30,
  ) {
    if (!idToken || idToken.length === 0) {
      throw new Error('idToken is undefined or empty');
    }

    const decoded = PermissionManager.decodeIdToken(idToken);
    if (!decoded) {
      throw new Error('Invalid JWT token - unable to decode');
    }

    const checkSeconds = Date.now() / 1000 + expirationDeltaFromNowSeconds;
    if (decoded.exp < checkSeconds) {
      throw new Error(
        `idToken will expire in fewer than ${expirationDeltaFromNowSeconds} seconds from now`,
      );
    }
  }

  public getAccess(
    permissions: IPermissions,
    permissionType: PermissionType,
    // Must be fully qualified
    qualifierValue: string,
  ): AccessType {
    if (!permissions || !qualifierValue) {
      throw new Error(
        'Missing one of the required parameters. ' +
          ` Got permissions: ${safeJsonStringify(permissions)}, ` +
          ` permissionType ${permissionType}, ` +
          ` qualifierValue ${qualifierValue} `,
      );
    }

    const permissionList = permissions[permissionType];
    const pm = new PermissionMatcher(qualifierValue);
    switch (permissionType) {
      case PermissionType.Widget:
      case PermissionType.Programmatic:
        pm.qualifierKey = 'permissionId';
        break;
      case PermissionType.Navigation:
        pm.qualifierKey = 'name';
        break;
      case PermissionType.Application:
        pm.qualifierKey = 'applicationId';
        break;
      case PermissionType.Data:
        throw new Error(
          'Data permissions are not implemented here, use checkDataAccess',
        );
      default:
        throw new Error(`Unexpected permission type: ${permissionType}`);
    }
    const access = pm.matchPermissions(permissionList);
    return access;
  }

  public getCurrentDataPermissions() {
    // May not have a current user during the boot
    return this.currentUserInfo?.permissions[PermissionType.Data];
  }

  public checkDataAccess(
    permissions: IPermissions,
    compareFunc: (permission: IPermission) => boolean,
  ): AccessType {
    const permissionList = permissions[PermissionType.Data];
    if (permissionList.length === 0) {
      return AccessType.All;
    }
    const pm = new PermissionMatcher(null, compareFunc);
    const access = pm.matchPermissions(permissionList);
    return access;
  }

  public getQualifiedPermissionId(id, configName, permissionId) {
    const qualifiedId = MetadataSupport.getQualifiedName(id, configName);
    return `${qualifiedId}.${permissionId}`;
  }
}

class PermissionMatcher {
  public qualifierKey: string;
  private readonly qualifierValue: string;
  private readonly dataCompareFunc: (permission: IPermission) => boolean;

  private accessTypesMap: Map<number, AccessType>;
  private highPriority: number;

  public constructor(
    qualifierValue: string,
    dataCompareFunc?: (permission: IPermission) => boolean,
  ) {
    this.qualifierValue = qualifierValue;
    this.dataCompareFunc = dataCompareFunc;
    this.accessTypesMap = new Map();
    this.highPriority = 0;
  }

  private compareQualifier(permissionQualifierValue: string): boolean {
    const pqv = permissionQualifierValue
      .replace(CONFIG_SEPARATOR, '.')
      .split('.');
    const qv = this.qualifierValue.replace(CONFIG_SEPARATOR, '.').split('.');
    if (pqv[pqv.length - 1] !== '**' && pqv.length !== qv.length) {
      return false;
    }
    for (let i = 0; i < permissionQualifierValue.length; i++) {
      if (pqv[i] === '*') {
        continue;
      }
      if (pqv[i] === '**') {
        return true;
      }
      if (pqv[i] === qv[i]) {
        continue;
      }
      return false;
    }
    return true;
  }

  private matchPermissionQualifiers(permission: IPermission): boolean {
    if (permission.permissionType === PermissionType.Data) {
      return this.dataCompareFunc(permission);
    }
    if (!permission.qualifiers) {
      return false;
    }
    const reducer = (accumulator, q) =>
      accumulator || this.compareQualifier(q.value);

    const match = permission.qualifiers
      .filter((q) => q.key === this.qualifierKey)
      .reduce(reducer, false);
    return match;
  }

  private matchPermissionChildren(permission: IPermission): boolean {
    if (!permission.childPermissions) {
      return false;
    }
    const reducer = (accumulator, pu) =>
      accumulator || this.matchPermission(pu.permission);

    const matchPos = permission.childPermissions
      .filter((pu) => !pu.isNegative)
      .reduce(reducer, false);
    if (!matchPos) {
      return false;
    }
    const matchNeg = permission.childPermissions
      .filter((pu) => pu.isNegative)
      .reduce(reducer, false);
    return !matchNeg;
  }

  private matchPermission(permission: IPermission): boolean {
    return (
      this.matchPermissionQualifiers(permission) ||
      this.matchPermissionChildren(permission)
    );
  }

  private mergePermissionAccessType(pu: IPermissionUse) {
    const { permission, isNegative } = pu;

    const permissionPriority = permission.priority | 0;
    if (permissionPriority > this.highPriority) {
      this.highPriority = permissionPriority;
    }

    const prevPriority = this.accessTypesMap.get(permissionPriority) | 0;
    const newPriority = isNegative
      ? prevPriority & ~pu.permission.accessTypes
      : prevPriority | pu.permission.accessTypes;
    this.accessTypesMap.set(permissionPriority, newPriority);

    logger.debug(
      `matchPermission: pri: ${permissionPriority}: result: ${this.accessTypesMap.get(
        permissionPriority,
      )} ${safeJsonStringify(permission)}`,
    );
  }

  private matchPermissionSet(permissions: IPermissionUse[]) {
    permissions = permissions.filter((pu) =>
      this.matchPermission(pu.permission),
    );
    permissions
      .filter((pu) => !pu.isNegative)
      .forEach((pu) => this.mergePermissionAccessType(pu));

    permissions
      .filter((pu) => pu.isNegative)
      .forEach((pu) => this.mergePermissionAccessType(pu));
  }

  public matchPermissions(permissions: IPermission[]): AccessType {
    const permissionSet: IPermissionUse[] = [];
    permissions.forEach((p) =>
      permissionSet.push({ permission: p, isNegative: p.negative }),
    );
    this.matchPermissionSet(permissionSet);
    const retVal = this.accessTypesMap.get(this.highPriority) | AccessType.None;
    logger.debug(
      `matchPermissions: ${retVal} qualValue: ${this.qualifierValue}`,
    );
    return retVal;
  }
}
