import {
  Divider,
  Drawer,
  Icon,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import React, { useContext, useMemo } from 'react';
import { Link } from 'react-router-dom';

import { escapeUrlPath, getIcon } from '../../util/clientUtilities';
import {
  ApplicationContext,
  INavItem,
} from '../../wrappers/ApplicationContext';
import Collapsable from '../atoms/Collapsable';

import { appBarHeight } from './NavLayout';

export const navDrawerWidth = 240;
const navIconWidth = 24;

const sxDivider = {
  bg: 'secondary.contrastText',
  opacity: 0.5,
};

export const calcDrawerWidth = (params: {
  isOpen: boolean;
  showIcons: boolean;
  offset?: number;
}) => {
  const { isOpen, showIcons, offset = 0 } = params;
  return isOpen
    ? `${navDrawerWidth + offset}px`
    : (theme) => {
        const width = showIcons
          ? `${theme.spacingNumber(2) + navIconWidth + offset}px`
          : `${theme.spacingNumber(1) + offset}px`;
        return width;
      };
};

const NavDrawer: React.FC<
  React.PropsWithChildren<{
    isOpen: boolean;
    navModel: INavItem[];
  }>
> = (props) => {
  const { isOpen, navModel = [] } = props;

  const { navigation } = useContext(ApplicationContext);
  const { showIcons } = navigation;

  const navLinks = useMemo(
    () => buildNavLinks({ model: navModel, showIcons, isOpen }),
    [navModel, showIcons, isOpen],
  );

  const width = calcDrawerWidth({ isOpen, showIcons });

  const sxDrawer = {
    display: 'flex',
    flexShrink: 0,
    '& .MuiDrawer-paper': {
      width,
      bgcolor: 'primary.main',
      color: 'secondary.contrastText',
      ...(!isOpen && {
        overflow: 'hidden',
        ':not(:hover)&::-webkit-scrollbar': {
          width: 0,
          background: 'transparent',
        },
        '&:hover': {
          width: navDrawerWidth,
        },
      }),
      transition: (theme) =>
        theme.transitions.create('width', {
          easing: theme.transitions.easing.easeOut,
          duration: isOpen
            ? theme.transitions.duration.enteringScreen
            : theme.transitions.duration.leavingScreen,
        }),
    },
  };

  return (
    <Drawer variant="persistent" open sx={sxDrawer}>
      <List
        sx={{
          pt: `${appBarHeight}px`,
          ...(!isOpen && { ':not(:hover)&': { whiteSpace: 'nowrap' } }),
          wordBreak: 'break-word',
          boxSizing: 'border-box',
        }}
      >
        {navLinks}
      </List>
    </Drawer>
  );
};

function buildNavLinks(params: { model; showIcons: boolean; isOpen: boolean }) {
  const { model, showIcons, isOpen } = params;
  const sxListItem = {
    pl: 1,
    pr: 1,
    pt: '2',
    pb: '2',
  };

  const sxListItemText = (isUrl: boolean) => {
    const widthAdjust = showIcons
      ? isUrl
        ? navIconWidth
        : navIconWidth * 2
      : isUrl
        ? 0
        : navIconWidth;
    return {
      ...sxListItem,
      width: (theme) =>
        `${
          isOpen
            ? navDrawerWidth - (theme.spacing(5) + widthAdjust)
            : navIconWidth + 10
        }px`,
    };
  };

  return model.map((navItem, index) => {
    if (navItem.members) {
      const NavIcon = navItem.icon && getIcon(navItem.icon, model.configName);
      const collapseItem = (open, icon, toggleOpen) => (
        <React.Fragment>
          <ListItem
            dense
            sx={sxListItem}
            button
            onClick={toggleOpen}
            key={navItem.nameInfo.displayName}
          >
            {(showIcons && NavIcon && (
              <NavIcon sx={open ? { opacity: 0.5 } : {}} />
            )) ||
              (showIcons && <Icon />)}
            <ListItemText
              sx={{
                ...sxListItemText(false),
                ...(open ? { opacity: 0.5 } : {}),
              }}
              primaryTypographyProps={{ variant: 'body2' }}
            >
              {navItem.nameInfo.displayName}
            </ListItemText>
            {icon}
          </ListItem>
        </React.Fragment>
      );

      return (
        <Collapsable
          key={index}
          defaultOpen={navItem.defaultOpen}
          collapseItem={collapseItem}
        >
          <Divider sx={sxDivider} />
          {buildNavLinks({ model: navItem.members, showIcons, isOpen })}
          <Divider sx={sxDivider} />
        </Collapsable>
      );
    }
    if (navItem.url) {
      const NavIcon = navItem.icon && getIcon(navItem.icon, model.configName);
      return (
        <ListItem
          key={navItem.nameInfo.displayName}
          dense
          sx={sxListItem}
          button
          component={Link}
          to={escapeUrlPath(`/${navItem.url}`)}
        >
          {(showIcons && NavIcon && <NavIcon />) || (showIcons && <Icon />)}
          <ListItemText
            sx={sxListItemText(true)}
            primaryTypographyProps={{ variant: 'body2' }}
          >
            {navItem.nameInfo.displayName}
          </ListItemText>
        </ListItem>
      );
    }
    console.log('no options or url in nav item');
    return null;
  });
}

export default NavDrawer;
