import { ColDef } from '@ag-grid-community/core';

export function getExcelFormula(params) {
  const { api, column, context } = params;
  const typeDef = context.typeDef;
  const attr = typeDef.getAttribute(column.colId);
  let formula = attr.presentationInfo?.gridFormat?.excelFormula;
  if (!formula) {
    return params.value;
  }
  //const colReferences = formula.match(/(?<=_\s+).*?(?=\s+_)/gs);
  const colReferences = [...formula.matchAll(/_(.*?)_/g)].map((f) => f[1]);
  /* Assumes a single header row */
  const row = params.node.rowIndex + 2;
  colReferences.forEach((colRef) => {
    const ref = convertColDefToColIndex(api.getColumnDefs(), colRef);
    formula = formula.replace(`_${colRef}_`, `${ref}${row}`);
  });
  return `=${formula}`;
}
function convertColDefToColIndex(colDefs: ColDef[], colId: string) {
  const filteredColDefs = colDefs.filter((colDef) => !colDef.hide);
  const idx = filteredColDefs.findIndex((c) => c.colId === colId);
  return toExcelCol(idx + 1);
}

const divmodExcel = (n) => {
  const a = Math.floor(n / 26);
  const b = n % 26;

  return b === 0 ? [a - 1, b + 26] : [a, b];
};

const uppercaseAlphas = Array.from({ length: 26 }, (_, i) =>
  String.fromCodePoint(i + 'A'.codePointAt(0)),
);

/** @param {number} n */
const toExcelCol = (n) => {
  const chars = [];

  let d;
  while (n > 0) {
    [n, d] = divmodExcel(n);
    chars.unshift(uppercaseAlphas[d - 1]);
  }
  return chars.join('');
};
