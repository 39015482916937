import { decode, encode } from 'base64-arraybuffer';
import _ from 'lodash';
import { ClientManager } from 'universal/clientManager';
import { dayjsOriginal } from 'universal/common/dayjsSupport';
import { S3_ATTACHMENTS } from 'universal/common/s3Support';
import {
  FilterAutoCompleteType,
  FilterType,
  IFilter,
  IFilterInfo,
} from 'universal/filterConstants';
import { executeJavascriptSync } from 'universal/javascriptSupport';
import { Loggers, getLogger } from 'universal/loggerSupport';
import {
  CollectionTypes,
  INCARNATION,
  IPresentationInfo,
} from 'universal/metadataSupportConstants';
import { IStageProperties } from 'universal/pipeline/stage';
import { IItemType, TypeDefinition } from 'universal/typeDefinition';
import { BasicType, isScalar } from 'universal/types';
import { IUser } from 'universal/userSupport';
import { months, stringifyPretty } from 'universal/utilityFunctions';

import { persistUIState } from '../store/persistUIState';

import {
  ICellFormatParams,
  ROW_FORMATTING_FIELD,
} from 'client/src/components/widgets/AGGrid/AGGridCellRenderers';

const logger = getLogger({ name: Loggers.PRESENTATION });

export interface IFilterParams {
  typeDefName: string;
  include?: string[];
  modifications?: IFilterMod[];
  persistKey?: string;
  inputData?: any[];
  filteredData?: any[];
  configName: string;
}
export interface IFilterMod {
  filterName: string;
  modifiedValues: IFilterInfo;
}

interface IFilterOptions {
  inputData: any[];
  filterName?: string;
  filters?: IFilter[];
  filter?: IFilter;
  filterResults?: any;
}

export interface IAttachmentData {
  fileName?: string;
  pathPrefix?: string;
  path: string;
  type?: string;
  arrayBuffer?: ArrayBuffer;
  rawBytes?: string;
  error?: string;
}

export interface ILogRequest {
  typeDefName: string;
  id: string;
  configName: string;
  inputLogData?: any[]; // for testing
}

export class PresentationSupport {
  public clientManager: ClientManager;

  public initialize(clientManager: ClientManager) {
    this.clientManager = clientManager;
  }
  private fixupCode(code) {
    return code.trim()[0] === '{' ? code : ` return ${code}`;
  }
  public runJsCode(params) {
    const { context, code } = params;
    const codeToRun = this.fixupCode(code);
    return executeJavascriptSync({
      jsCodeToExecute: codeToRun,
      jsArgs: context,
    });
  }
  private setFilter(params: {
    attribute: IItemType;
    filterInfo: IFilterInfo;
    configName: string;
  }) {
    const { attribute, filterInfo, configName } = params;
    const { filterType, autoComplete, filterOptions, width } = filterInfo;
    let enumTypeDef = null;
    let labelCode = null;
    let filterName = filterInfo.nestedField
      ? `${attribute.name}.${filterInfo.nestedField}`
      : attribute.name;

    const nestedField = attribute.itemInfo.associatedEntity
      ? filterInfo.nestedField
        ? `${attribute.name}.${filterInfo.nestedField}`
        : `${attribute.name}`
      : null;
    const nestedFieldArray = nestedField?.split('.') || [];

    if (
      attribute.itemInfo.collectionType === CollectionTypes.MAP ||
      attribute.itemInfo.collectionType === CollectionTypes.ARRAY
    ) {
      throw new Error(
        `Filtering on Array or Map collection types not supported: ${attribute.name}`,
      );
    }
    let nestedTypeDefinition;
    let nextAttribute = attribute;
    let label = nestedField;
    nestedFieldArray.forEach((field, idx) => {
      if (nextAttribute.itemInfo.associatedEntity) {
        nestedTypeDefinition =
          this.clientManager.schemaManager.getTypeDefinition({
            name: nextAttribute.itemInfo.associatedEntity,
            configName,
          });
        if (idx < nestedFieldArray.length - 1) {
          // this is nested AE
          nextAttribute = nestedTypeDefinition
            .getAttributes()
            .find((attr) => attr.name === nestedFieldArray[idx + 1]);
        } else {
          if (nestedTypeDefinition.presentationInfo?.labelCode) {
            labelCode = nestedTypeDefinition.presentationInfo?.labelCode;
          } else if (nestedTypeDefinition.presentationInfo?.label) {
            label += `.${nestedTypeDefinition.presentationInfo.label}`;
          } else if (
            nextAttribute.itemInfo.associatedEntity === 'system:Code'
          ) {
            label += '.description';
          } else if (
            nestedTypeDefinition
              .getAttributes()
              .find((attr) => attr.name === nestedFieldArray[idx])?.itemInfo
              .associatedEntity === 'system:Code'
          ) {
            label += '.code';
          }
        }
      } else {
        nextAttribute = nestedTypeDefinition
          .getAttributes()
          .find((attr) => attr.name === field);
        if (!nextAttribute) {
          throw new Error(`Cannot find nested field ${nestedField} `);
        }
        if (
          nextAttribute.itemInfo.collectionType === CollectionTypes.MAP ||
          nextAttribute.itemInfo.collectionType === CollectionTypes.ARRAY
        ) {
          throw new Error(
            `Filtering on Array or Map collection types not supported: ${nextAttribute.name}`,
          );
        }
      }
    });
    if (!isScalar(nextAttribute.itemInfo.type)) {
      if (!nextAttribute.itemInfo.associatedEntity) {
        const itemType = this.clientManager.schemaManager.getTypeDefinition({
          name: nextAttribute.itemInfo.type,
          configName,
        });
        if (itemType.isEnum) {
          enumTypeDef = itemType;
        }
      }
    }
    if (!filterType) {
      if (enumTypeDef) {
        filterInfo.filterType = FilterType.Multi;
      } else if (nextAttribute.itemInfo.associatedEntity) {
        filterInfo.filterType = FilterType.Multi;
      } else {
        switch (nextAttribute.itemInfo.type) {
          case BasicType.String:
            filterInfo.filterType = FilterType.Text;
            break;
          case BasicType.Boolean:
            filterInfo.filterType = FilterType.Boolean;
            break;
          case BasicType.Date:
          case BasicType.DateTime:
            filterInfo.filterType = FilterType.DateRange;
            break;
          case BasicType.Int:
          case BasicType.Decimal:
          case BasicType.Float:
            filterInfo.filterType = FilterType.NumericRange;
            break;
          case BasicType.Object:
            filterInfo.filterType = FilterType.Multi;
            break;
          default:
            logger.warn(
              `Filter defaults for type ${attribute.itemInfo.type} not supported; attribute = ${attribute.name}`,
            );
        }
      }
    }
    if (!autoComplete) {
      if (nextAttribute.itemInfo.associatedEntity) {
        filterInfo.autoComplete = FilterAutoCompleteType.Auto;
      } else {
        switch (nextAttribute.itemInfo.type) {
          case BasicType.String:
            filterInfo.autoComplete = FilterAutoCompleteType.On;
            break;
          default:
            filterInfo.autoComplete = FilterAutoCompleteType.Off;
        }
      }
    }
    if (!(filterOptions === true || filterOptions === false)) {
      switch (filterInfo.filterType) {
        case FilterType.Multi:
        case FilterType.Single:
        case FilterType.Month:
        case FilterType.Text:
          filterInfo.filterOptions = true;
          break;
        default:
          filterInfo.filterOptions = false;
      }
    }
    if (!width) {
      filterInfo.width = '300px';
    }
    if (nextAttribute.itemInfo.associatedEntity) {
      filterName += '.id';
    }
    let result = null;
    switch (filterInfo.filterType) {
      case 'Multi':
      case 'Single':
      case 'Boolean':
      case 'BooleanWithNotSelected':
      case 'Month':
        result = [];
        break;
      case 'Text':
        result = '';
        break;
    }
    return {
      name: filterName,
      displayName: nextAttribute.nameInfo.displayName,
      label,
      labelCode,
      enumTypeDef,
      result,
      ...filterInfo,
    };
  }

  private findFilter(params: { attributes: IItemType[]; filterName: string }) {
    const { attributes, filterName } = params;
    let nextAttr = attributes.find((attr) => attr.name === filterName);
    let nextFilter = {};
    if (nextAttr) {
      nextFilter = nextAttr.presentationInfo?.filterInfo?.find(
        (fi) => !fi.nestedField,
      );
    }

    if (!nextAttr) {
      // if not at top level may be a nested field
      for (const attr of attributes) {
        nextFilter = attr?.presentationInfo?.filterInfo?.find(
          (fi) => fi.nestedField === filterName,
        );
        if (nextFilter) {
          nextAttr = attr;
          break;
        }
      }
    }
    return { attribute: nextAttr, filterInfo: nextFilter };
  }
  public getFilters(params: IFilterParams) {
    const { typeDefName, include, modifications, configName } = params;
    let filters = [];

    const typeDefinition = this.clientManager.schemaManager.getTypeDefinition({
      name: typeDefName,
      configName,
    });

    let attributes;
    if (include) {
      const includeAttributes = {};
      include.forEach((filterName) => {
        const { attribute, filterInfo } = this.findFilter({
          attributes: typeDefinition.getAttributes(),
          filterName,
        });
        if (!attribute) {
          logger.warn(
            `Could not find filter ${filterName} in typeDef ${typeDefName}`,
          );
          return;
        }
        const includeAttribute = includeAttributes[attribute.name];
        if (includeAttribute) {
          includeAttribute.presentationInfo.filterInfo.push(filterInfo);
        } else {
          includeAttributes[attribute.name] = _.cloneDeep(attribute);
        }
      });
      attributes = Object.values(includeAttributes);
    } else {
      attributes = _.cloneDeep(typeDefinition.getAttributes());
    }

    if (modifications) {
      modifications.forEach((mod) => {
        const { attribute, filterInfo } = this.findFilter({
          attributes,
          filterName: mod.filterName,
        });
        if (!attribute) {
          logger.warn(
            `Could not find filter ${mod.filterName} in typeDef ${typeDefName}`,
          );
        } else {
          if (!attribute.presentationInfo) {
            attribute.presentationInfo = {};
          }
          if (!attribute.presentationInfo.filterInfo) {
            attribute.presentationInfo.filterInfo = [];
          }
          if (!filterInfo) {
            attribute.presentationInfo.filterInfo.push(mod.modifiedValues);
          } else {
            for (const modField in mod.modifiedValues) {
              filterInfo[modField] = mod.modifiedValues[modField];
            }
          }
        }
      });
    }

    attributes
      .filter((attr) => attr.presentationInfo?.filterInfo)
      .forEach((attr) => {
        attr.presentationInfo.filterInfo.forEach((fi) => {
          if (fi?.order) {
            filters.push(
              this.setFilter({ attribute: attr, filterInfo: fi, configName }),
            );
          }
        });
      });
    filters = _.sortBy(filters, ['order', 'displayName']);
    return filters;
  }
  private getLabel(params: { record: any; label: string }) {
    const { label, record } = params;
    return _.get(record, label);
  }
  private getEnumLabel(params: { value: any; enumTypeDef: any }) {
    const { enumTypeDef, value } = params;
    return enumTypeDef.getAttributes().find((attr) => attr.name === value)
      .nameInfo.displayName;
  }

  public getFilterOptions(params: IFilterOptions) {
    const { inputData, filterName, filters, filterResults } = params;

    const myFilter = filters.find((filter) => filter.name === filterName);
    const { label, labelCode, name, defaultOn, enumTypeDef, filterType } =
      myFilter;
    const optionMap = {};
    const options = [];
    let labelValue;
    const optionFilters = filters.filter(
      (filter) => filter.name !== filterName && filter.filterOptions,
    );
    const filteredData = this.clientManager.utilityFunctions.filterData({
      inputData,
      filters: optionFilters,
      filterResults,
    });
    if (filterType === FilterType.Month) {
      months.forEach((month) => {
        optionMap[month.number] = {
          id: month.number,
          label: month.full,
          count: 0,
          selected: false,
        };
      });
    }
    filteredData?.forEach((record) => {
      const segments = name.split('.');
      let nextAccessor = _.get(record, name);

      if (!nextAccessor) {
        nextAccessor = record;
        for (const segment of segments) {
          if (Array.isArray(nextAccessor)) {
            nextAccessor = nextAccessor.map((item) => item[segment]);
            break;
          } else {
            nextAccessor = nextAccessor ? nextAccessor[segment] : undefined;
          }
        }
        if (Array.isArray(nextAccessor)) {
          nextAccessor = nextAccessor.filter(Boolean).join(', ');
        }
      }

      if (
        filterType === FilterType.Boolean ||
        filterType === FilterType.BooleanWithNotSelected
      ) {
        if (nextAccessor === false) {
          nextAccessor = 'false';
        } else if (nextAccessor === true) {
          nextAccessor = 'true';
        } else {
          nextAccessor =
            filterType === FilterType.BooleanWithNotSelected ? null : 'false';
        }
      }
      if (!nextAccessor) {
        if (!optionMap['(None)']) {
          optionMap['(None)'] = {
            id: '(None)',
            label: '(None)',
            count: 1,
            selected: !!defaultOn,
          };
        } else {
          optionMap['(None)'].count++;
        }
      } else {
        if (labelCode) {
          labelValue = this.runJsCode({
            context: record[label],
            code: labelCode,
          });
        } else if (label) {
          labelValue = this.getLabel({ record, label });
        } else if (enumTypeDef) {
          labelValue = this.getEnumLabel({ value: nextAccessor, enumTypeDef });
        }
        if (!optionMap[nextAccessor]) {
          optionMap[nextAccessor] = {
            id: nextAccessor,
            label: labelValue || nextAccessor,
            count: 1,
            selected: !!defaultOn,
          };
        } else {
          optionMap[nextAccessor].count++;
        }
      }
    });
    for (const option in optionMap) {
      options.push(optionMap[option]);
    }
    if (filterType !== FilterType.Month) {
      options.sort((a, b) => (a.label > b.label ? 1 : -1));
    } else {
      options.sort((a, b) => (a.id > b.id ? 1 : -1));
    }
    return options;
  }

  public getFilterMinMax(params: IFilterOptions) {
    const { inputData, filterName, filters, filterResults } = params;
    let min = 0,
      max;

    const optionFilters = filters.filter(
      (filter) => filter.name !== filterName,
    );
    const filteredData = this.clientManager.utilityFunctions.filterData({
      inputData,
      filters: optionFilters,
      filterResults,
    });
    filteredData.forEach((record) => {
      const nextAccessor = _.get(record, filterName);
      if (nextAccessor) {
        if (!max) {
          max = nextAccessor;
        } else if (nextAccessor < min) {
          min = nextAccessor;
        } else if (nextAccessor > max) {
          max = nextAccessor;
        }
      }
    });
    return { min, max };
  }

  public async userGetAllUiState(userId: string): Promise<any[]> {
    const userRecord =
      await this.clientManager.pipelineManager.executeGraphqlQuery({
        query: `query { getUserUiState (id:"${userId}") { id uiState {id value }} }`,
        noCache: true,
      });
    return userRecord?.uiState;
  }

  public async saveUiState(params: {
    userId: string;
    key: string;
    value: any;
  }) {
    const { userId, key, value } = params;
    const keyToUse = key.replaceAll("'", "\\\\'");
    await this.clientManager.pipelineManager.executeGraphqlMutation({
      mutation: `mutation updateUIState ($value: Object) @suppresslog {
          upsertUserUiState( input: { id:"${userId}" 
            uiState: [{ id: "${keyToUse}" value: $value } ] } )  
          @replace(path: "/uiState/'${keyToUse}'") { id  } }`,
      variables: { value },
    });
  }

  public async userSetUiState(params: {
    user: IUser;
    key: string;
    value: any;
  }) {
    const { key, value } = params;
    await persistUIState({ [key]: value });
  }

  public async userGetUiState(params: { user: IUser; key: string }) {
    /* return value for a key */
    const { key, user } = params;
    const uiState = await this.userGetAllUiState(user.id);
    const state = uiState?.find((s) => s.id === key);
    if (!state) {
      return undefined;
    }
    return state.value;
  }

  makePath(pathPrefix: string, path: string): string {
    return `${S3_ATTACHMENTS}${pathPrefix ? '/' + pathPrefix : ''}/${path}`;
  }

  public async clientPutAttachment(
    attachmentData: IAttachmentData,
  ): Promise<void> {
    const { path, pathPrefix, arrayBuffer } = attachmentData;
    attachmentData.rawBytes = encode(arrayBuffer);
    attachmentData.arrayBuffer = undefined;
    await this.clientManager.pipelineManager.executePipelineRemote({
      name: 'system:s3PutAttachment',
      input: {
        path: this.makePath(pathPrefix, path),
        bytes: JSON.stringify(attachmentData),
      },
    });
  }

  public async clientGetAttachment(
    pathPrefix: string,
    path: string,
  ): Promise<IAttachmentData> {
    let output;
    try {
      output = await this.clientManager.pipelineManager.executePipelineRemote({
        name: 'system:s3GetAttachment',
        input: {
          path: this.makePath(pathPrefix, path),
        },
      });
    } catch (error) {
      return { path, pathPrefix, error: error.toString() };
    }

    const outputString = output.bytes.toString();
    const attachmentData: IAttachmentData = JSON.parse(outputString);
    attachmentData.arrayBuffer = decode(attachmentData.rawBytes);
    return attachmentData;
  }

  public async clientDeleteAttachment(
    pathPrefix: string,
    path: string,
  ): Promise<void> {
    await this.clientManager.pipelineManager.executePipelineRemote({
      name: 'system:s3DeleteAttachment',
      input: { path: this.makePath(pathPrefix, path) },
    });
  }

  private difference(orig, changed) {
    function changes(origObj, newObj) {
      Object.keys(newObj).forEach((key) => {
        if (_.isObject(origObj) && !Object.keys(origObj).includes(key)) {
          origObj[key] = undefined;
        }
      });
      return _.transform(newObj, function (result, value, key) {
        const cmpValue = _.isObject(origObj) ? origObj[key] : origObj;
        if (!_.isEqual(value, cmpValue)) {
          if (_.isArray(origObj) || _.isArray(value)) {
            result[key] = {
              originalValue: cmpValue,
              newValue: value,
            };
          } else {
            result[key] =
              _.isObject(value) || _.isObject(cmpValue)
                ? changes(cmpValue || {}, value)
                : { originalValue: cmpValue, newValue: value };
          }
        }
      });
    }
    return changes(orig, changed);
  }
  private async getItemLabel(params: {
    typeDefinition: TypeDefinition;
    id: string;
  }) {
    const { typeDefinition, id } = params;
    if (!id) {
      return '';
    }
    if (typeDefinition.presentationInfo?.label) {
      const record =
        await this.clientManager.pipelineManager.executeGraphqlQuery({
          query: `{ get${typeDefinition.getUnqualifiedId()} (id: "${id}")
              { ${typeDefinition.presentationInfo.label} }  }`,
        });
      return record ? record[typeDefinition.presentationInfo.label] : id;
    } else if (
      typeDefinition.presentationInfo?.labelCode &&
      typeDefinition.presentationInfo?.labelCodeGraphql
    ) {
      const record =
        await this.clientManager.pipelineManager.executeGraphqlQuery({
          query: `{ get${typeDefinition.getUnqualifiedId()} (id: "${id}")
              { ${typeDefinition.presentationInfo.labelCodeGraphql} } }`,
        });
      return record
        ? this.runJsCode({
            context: record,
            code: typeDefinition.presentationInfo.labelCode,
          })
        : id;
    }
    return id;
  }

  public async getLogData(params: ILogRequest) {
    const { typeDefName, id, configName, inputLogData } = params;

    const typeDefinition = this.clientManager.schemaManager.getTypeDefinition({
      name: typeDefName,
      configName,
    });
    const logResult = {
      label: await this.getItemLabel({ typeDefinition, id }),
      entries: [],
    };
    let logData =
      inputLogData ||
      (await this.clientManager.pipelineManager.executeGraphqlQuery({
        query: `{ list_Log(entityId: "${id}")
              { items { id entityType entityId activity reason timestamp before mutation user } } }`,
      }));
    logData = _.orderBy(logData, 'timestamp', 'desc');
    logger.debug(`Got log data: ${stringifyPretty(logData)}`);
    for (const logEntry of logData) {
      const diff: any = this.difference(
        logEntry.before || {},
        logEntry.mutation || {},
      );
      delete diff[INCARNATION];

      if (Object.keys(diff).length === 0) {
        continue;
      }
      const nextResult = {
        activity: logEntry.activity === 'UPSERT' ? 'UPDATE' : logEntry.activity,
        id: logEntry.id,
        timestamp: dayjsOriginal
          .utc(logEntry.timestamp)
          .local()
          .format('MM/DD/YY h:mm A'),
        user: logEntry.user,
        reason: logEntry.reason ? `Reason: ${logEntry.reason}` : undefined,
        changes: [],
      };

      for (const field in diff) {
        const typeDefEntry = typeDefinition
          .getAttributes()
          .find((attr) => attr.name === field);
        if (!typeDefEntry) {
          logger.warn(
            `Could not find attribute ${field} in typeDef ${typeDefName}`,
          );
          continue;
        }
        await this.getLogItemValue({
          typeDefEntry,
          diff: diff[field],
          configName,
          resultArray: nextResult.changes,
          field: typeDefEntry.nameInfo?.displayName || typeDefEntry.name,
        });
      }
      if (nextResult.changes.length > 0) {
        logResult.entries.push(nextResult);
      }
    }
    return logResult;
  }

  private async getLogItemValue(params: {
    typeDefEntry: IItemType;
    diff: any;
    configName: string;
    resultArray: any[];
    field: string;
  }) {
    const { typeDefEntry, diff, configName, field, resultArray } = params;
    const result: any = {
      field,
    };
    if (typeDefEntry.presentationInfo?.suppressLogViewer) {
      return;
    }
    logger.debug(
      `Processing log item: ${stringifyPretty(typeDefEntry)}, field: ${field}`,
    );
    if (
      typeDefEntry.itemInfo.collectionType === 'ARRAY' ||
      typeDefEntry.itemInfo.collectionType === 'MAP'
    ) {
      result.fromValue = await this.handleLogArray({
        logObjectArray: diff.originalValue,
        typeDefEntry,
        configName,
        inputString: '',
      });
      result.toValue = await this.handleLogArray({
        logObjectArray: diff.newValue,
        typeDefEntry,
        configName,
        inputString: '',
      });
      if (result.toValue !== result.fromValue) {
        resultArray.push(result);
      }
    } else if (typeDefEntry.itemInfo.associatedEntity) {
      const subType = this.clientManager.schemaManager.getTypeDefinition({
        name: typeDefEntry.itemInfo.associatedEntity,
        configName,
      });
      result.fromValue = await this.getItemLabel({
        typeDefinition: subType,
        id: diff.originalValue,
      });
      result.toValue = await this.getItemLabel({
        typeDefinition: subType,
        id: diff.newValue,
      });
      resultArray.push(result);
    } else if (!isScalar(typeDefEntry.itemInfo.type)) {
      const subType = this.clientManager.schemaManager.getTypeDefinition({
        name: typeDefEntry.itemInfo.type,
        configName,
      });
      if (subType.isEnum) {
        result.fromValue =
          diff.originalValue === undefined ? '' : diff.originalValue;
        result.toValue = diff.newValue === undefined ? '' : diff.newValue;
        resultArray.push(result);
      } else {
        for (const subField in diff) {
          if (subField === 'originalValue') {
            // This should only happen if the field is converted from a simple field to an embedded object and we have an old log entry
            result.fromValue =
              diff.originalValue === undefined ? '' : diff.originalValue;
            result.toValue = diff.newValue === undefined ? '' : diff.newValue;
            resultArray.push(result);
            break;
          }
          if (subField === INCARNATION) {
            continue;
          }
          const subTypeDefEntry = subType
            .getAttributes()
            .find((attr) => attr.name === subField);
          await this.getLogItemValue({
            typeDefEntry: subTypeDefEntry,
            diff: diff[subField],
            configName,
            resultArray,
            field: `${field} - ${subTypeDefEntry.nameInfo.displayName}`,
          });
        }
      }
    } else {
      result.fromValue =
        diff.originalValue === undefined ? '' : diff.originalValue;
      result.toValue = diff.newValue === undefined ? '' : diff.newValue;
      resultArray.push(result);
    }
  }

  private async handleLogArray(params: {
    logObjectArray: any[];
    typeDefEntry: IItemType;
    configName: string;
    inputString: string;
  }) {
    const { logObjectArray, typeDefEntry, configName, inputString } = params;
    logger.debug(`Processing log array: ${stringifyPretty(typeDefEntry)}`);
    let typeDefinition;
    if (
      typeDefEntry.itemInfo.associatedEntity ||
      !isScalar(typeDefEntry.itemInfo.type)
    ) {
      typeDefinition = this.clientManager.schemaManager.getTypeDefinition({
        name:
          typeDefEntry.itemInfo.associatedEntity || typeDefEntry.itemInfo.type,
        configName,
      });
    }
    if (!logObjectArray || logObjectArray.length === 0) {
      return '';
    }
    let outputString = `${inputString}${typeDefEntry.nameInfo.displayName}: [`;
    for (const logObject of logObjectArray) {
      logger.debug(`Processing log object: ${stringifyPretty(logObject)}`);
      if (!_.isObject(logObject)) {
        let newValue;
        if (typeDefEntry.itemInfo.associatedEntity) {
          newValue = await this.getItemLabel({
            typeDefinition,
            id: logObject,
          });
        } else {
          newValue = logObject;
        }
        outputString += `${newValue}, `;
      } else {
        for (const field in logObject) {
          if (field === INCARNATION) {
            continue;
          }
          let newValue;
          const fieldTypeDefEntry = typeDefinition
            .getAttributes()
            .find((attr) => attr.name === field);
          const newField = fieldTypeDefEntry.nameInfo.displayName;
          if (_.isArray(logObject[field])) {
            outputString += `[${await this.handleLogArray({
              logObjectArray: logObject[field],
              typeDefEntry: fieldTypeDefEntry,
              configName,
              inputString: outputString,
            })}]`;
          } else if (fieldTypeDefEntry.itemInfo.associatedEntity) {
            const subType = this.clientManager.schemaManager.getTypeDefinition({
              name: fieldTypeDefEntry.itemInfo.associatedEntity,
              configName,
            });
            newValue = await this.getItemLabel({
              typeDefinition: subType,
              id: logObject[field],
            });
          } else if (isScalar(fieldTypeDefEntry.itemInfo.type)) {
            newValue = logObject[field];
          } else {
            const subType = this.clientManager.schemaManager.getTypeDefinition({
              name: fieldTypeDefEntry.itemInfo.type,
              configName,
            });
            if (subType.isEnum) {
              newValue = logObject[field];
            } else {
              outputString += `${
                fieldTypeDefEntry.nameInfo.displayName
              }:{ ${await this.handleLogArray({
                logObjectArray: [logObject[field]],
                typeDefEntry: fieldTypeDefEntry,
                configName,
                inputString: outputString,
              })}}`;
            }
          }
          outputString += `${newField}: ${newValue}, `;
        }
      }
    }
    outputString += ']';
    return outputString;
  }
  public getLabelFromRecord(params: {
    presentationInfo: IPresentationInfo;
    record: any;
  }) {
    const { presentationInfo, record } = params;

    if (presentationInfo.label) {
      return _.get(record, presentationInfo.label);
    } else if (presentationInfo.labelCode) {
      return this.runJsCode({
        context: record,
        code: presentationInfo.labelCode,
      });
    }
    return record.id;
  }
  public async pasteData(params: {
    stages: IStageProperties[];
    data?: any;
    runPipelineWithWidgetContext: any;
  }) {
    const { stages, data, runPipelineWithWidgetContext } = params;
    void runPipelineWithWidgetContext({
      data,
      stages,
    });
  }

  public addFormatToCell(params: {
    field: string;
    row: any;
    format: ICellFormatParams;
  }) {
    const { row, field, format } = params;
    if (!row[ROW_FORMATTING_FIELD]) {
      row[ROW_FORMATTING_FIELD] = { [field]: { formatting: { ...format } } };
    } else {
      row[ROW_FORMATTING_FIELD] = {
        ...row[ROW_FORMATTING_FIELD],
        [field]: { formatting: { ...format } },
      };
    }
  }
}
