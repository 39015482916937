import { isBrowser } from 'browser-or-node';
import safeJsonStringify from 'safe-json-stringify';

import { HEADER_REMOTE_CONTEXT } from '../common/commonConstants';
import { getLogger, Loggers } from '../loggerSupport';
import { IRemoteContext } from '../pipeline/pipelineManager';

import { reThrow } from './errorLog';
import { getErrorStatusCode } from './retry';

const logger = getLogger({ name: Loggers.ERRORS });

enum ErrorType {
  ERROR,
  NETWORK,
  ORIGINAL_ERROR,
  RESULT,
  GRAPHQL,
  NESTED_ERROR,
}

// Common error object
interface IErrorObject {
  errorType?: ErrorType;
  statusCode?: number;
  message?: string;
  nested?: IErrorObject[];
  stack?: string[];
  amazonRequestId?: string;
  httpResponseData?: string;
  requestId?: string;
  otherKeys?: any;
  parent?: IErrorObject;
  graphQLCode?: string;
  graphQLLocations?: [{ line: number; column: number }];
}

export interface IErrorNested {
  originalError: any;
  statusCode: number;
  additionalInfo: any;
}

// Not public - only to be used by reThrow (errorLog)
export class ErrorNested extends Error implements IErrorNested {
  public originalError: any;
  public statusCode: number;
  public additionalInfo: any;

  constructor(params: {
    message: string;
    originalError?: any;
    additionalInfo?: any;
  }) {
    const { message, originalError, additionalInfo } = params;
    super(message);
    this.originalError = originalError;
    this.additionalInfo = additionalInfo;
    if (originalError) {
      this.statusCode = getErrorStatusCode(originalError);
    }
  }

  // Get this an all enclosing errors in the right order
  public getErrorHierarchy(errors: Error[]) {
    errors.push(this);
    if (this.originalError instanceof ErrorNested) {
      (this.originalError as ErrorNested).getErrorHierarchy(errors);
    } else {
      errors.push(this.originalError);
    }
  }

  public toString() {
    return this.message;
  }
}

export function stringifyError(error): string {
  function replaceErrors(key, value) {
    if (value instanceof Error) {
      const newError = {};
      Object.getOwnPropertyNames(value).forEach((k) => {
        newError[k] = value[k];
      });
      return newError;
    }
    return value;
  }

  return JSON.stringify(error, replaceErrors);
}

// Any keys in the error other than these are directly printed
const keys = new Set([
  'name',
  'error',
  'errors',
  'statusCode',
  'message',
  'stack',
  'request',
  'response',
  'fixed',
  'result',
  'networkError',
  'originalError',
  'graphQLErrors',
  '_errorObject',
]);

export function analyzeError({
  error,
  errorObject,
}: {
  error: any;
  errorObject?: IErrorObject;
}): IErrorObject {
  try {
    const addNestedError = (e: any, errorType: ErrorType): IErrorObject => {
      return analyzeError({
        error: e,
        errorObject: { errorType, parent: errorObject },
      });
    };

    if (!errorObject) {
      errorObject = { errorType: ErrorType.ERROR };
    }
    errorObject.nested = [];
    errorObject.otherKeys = {};

    errorObject.statusCode = error.statusCode;

    let message = error.message;
    if (message?.includes(error.stack)) {
      message = message.replace(error.stack, '');
    }

    let stackOutput;
    if (isBrowser) {
      if (message) {
        errorObject.message = message;
      }
      stackOutput = error.stack?.split('\n');
    } else {
      if (message) {
        if (error.name) {
          errorObject.message = `${error.name}: ${message}`;
        } else {
          errorObject.message = message;
        }
      }
      if (error.stack) {
        const stackArray = error.stack.split('\n');
        if (!errorObject.message) {
          errorObject.message = stackArray[0];
        }
        stackOutput = stackArray.slice(1);
      }
    }

    if (stackOutput) {
      if (!errorObject.stack) {
        errorObject.stack = stackOutput.map((s) => s.replace(/^(\s)+/g, '  '));
      }
    }

    if (error.extensions?.code && error.locations) {
      errorObject.errorType = ErrorType.GRAPHQL;
    }

    if (errorObject.errorType === ErrorType.GRAPHQL) {
      if (error.extensions) {
        errorObject.graphQLCode = error.extensions.code;
        if (!error.originalError && error.extensions.originalError) {
          error.originalError = error.extensions.originalError;
        }
        // See below for how this is ignored
      }
      if (error.locations) {
        errorObject.graphQLLocations = error.locations;
        // See below for how this is ignored
      }
    }

    Object.getOwnPropertyNames(error).forEach((k) => {
      if (keys.has(k)) {
        return;
      }
      let otherObj;
      try {
        otherObj = error[k];
      } catch (err) {
        // Sometimes there might be a problem getting a property
      }

      if (
        otherObj === undefined ||
        otherObj === null ||
        (typeof otherObj === 'object' &&
          Object.getOwnPropertyNames(otherObj).length === 0)
      ) {
        return;
      }

      if (errorObject.errorType === ErrorType.GRAPHQL) {
        // See above for how this is handled
        if (k === 'locations' || 'extensions') {
          return;
        }
      }
      if (typeof otherObj === 'string') {
        errorObject.otherKeys[k] = otherObj;
      } else if (
        Array.isArray(otherObj) &&
        otherObj.length > 0 &&
        otherObj[0].toString() !== '[object Object]'
      ) {
        errorObject.otherKeys[k] = otherObj;
      } else {
        let s = safeJsonStringify(otherObj);
        if (s === '{}') {
          s = otherObj.toString();
        }
        errorObject.otherKeys[k] = s;
      }
    });

    if (error.response) {
      // Go to great trouble to get the amazon request id, which can be useful for support
      // purposes
      const internals = Object.getOwnPropertySymbols(error.response);
      if (internals && internals[1]) {
        const headers = error.response[internals[1]].headers;
        const headersInternals = Object.getOwnPropertySymbols(headers);
        if (headersInternals && headersInternals[0]) {
          const headersMap = headers[headersInternals[0]];
          if (headersMap['x-amzn-requestid']) {
            errorObject.amazonRequestId = headersMap['x-amzn-requestid'];
          }
          if (headersMap[HEADER_REMOTE_CONTEXT]) {
            errorObject.requestId = (
              JSON.parse(headersMap[HEADER_REMOTE_CONTEXT]) as IRemoteContext
            ).requestId;
          }
        }
      }
      if (error.response.data) {
        errorObject.httpResponseData = safeJsonStringify(error.response.data);
      }
    }

    if (error.networkError) {
      if (Array.isArray(error.networkError)) {
        error.networkError
          .filter((e) => !!e)
          .forEach((e) => {
            errorObject.nested.push(addNestedError(e, ErrorType.NETWORK));
          });
      } else {
        errorObject.nested.push(
          addNestedError(error.networkError, ErrorType.NETWORK),
        );
      }
    }

    if (error.originalError) {
      errorObject.nested.push(
        addNestedError(error.originalError, ErrorType.ORIGINAL_ERROR),
      );
    }

    if (error.error) {
      if (typeof error.error === 'string') {
        const errorEscaped = error.error
          .replace(/\n/g, '\\n')
          .replace(/"/g, '\\"');

        // Sometimes this happens on HTTP errors
        if (
          !message?.includes(errorEscaped) &&
          !error.stack?.includes(errorEscaped)
        ) {
          errorObject.otherKeys.error = error;
        }
      } else {
        errorObject.nested.push(
          addNestedError(error.error, ErrorType.NESTED_ERROR),
        );
      }
    }

    if (Array.isArray(error.errors)) {
      error.errors.forEach((e) => {
        errorObject.nested.push(addNestedError(e, ErrorType.RESULT));
      });
    }

    if (error.result) {
      if (Array.isArray(error.result.errors)) {
        error.result.errors.forEach((e) => {
          errorObject.nested.push(addNestedError(e, ErrorType.RESULT));
        });
      } else {
        addNestedError(error.result, ErrorType.RESULT);
      }
    }

    if (error.graphQLErrors?.errors) {
      error.graphQLErrors.errors
        .filter((e) => !!e)
        .forEach((e) => {
          errorObject.nested.push(addNestedError(e, ErrorType.GRAPHQL));
        });
    }

    // Leading Error: is meaningless and disrupts the duplicate checking
    if (errorObject.message?.startsWith('Error: ')) {
      errorObject.message = errorObject.message.slice(6);
    }

    errorObject.nested.forEach((n) => {
      if (n.message?.startsWith('Error: ')) {
        n.message = n.message.slice(6);
      }
      if (errorObject.message?.includes(n.message)) {
        errorObject.message = errorObject.message.replace(n.message, '');
      }
    });

    errorObject.message = errorObject.message?.trim();
    return errorObject;
  } catch (err) {
    reThrow({
      logger,
      error: error,
      message: 'Problem in errorString analysis',
    });
  }
}

function errorObjectToString(
  errorObject: IErrorObject,
  indentString = '',
): string {
  const {
    errorType,
    message,
    stack,
    nested,
    otherKeys,
    statusCode,
    graphQLCode,
    graphQLLocations,
    requestId,
    amazonRequestId,
    httpResponseData,
  } = errorObject;

  let output = '';
  const printLine = (line: any, title?: string, truncate?: boolean) => {
    if (line) {
      line = line.toString().replace(/\n/g, `\n${indentString}`);
      if (truncate) {
        line = line.slice(0, 500);
      }
      output += `${indentString}${title ? title + ': ' : ''}${line}\n`;
    }
  };

  const graphQLMessage = graphQLCode ? ' (' + graphQLCode + ') ' : '';
  const locStr = graphQLLocations
    ?.map((l) => l.line + '/' + l.column)
    .join(', ');
  const printLocStr = locStr ? 'at ' + locStr : '';
  printLine(
    `${ErrorType[errorType]}: ${message}${graphQLMessage}${printLocStr}`,
  );
  printLine(statusCode, 'Status Code');
  printLine(amazonRequestId, 'Amazon Request ID');
  printLine(requestId, 'Request ID');
  printLine(httpResponseData, 'HTTP Response Data');
  Object.keys(otherKeys).forEach((k) => printLine(otherKeys[k], k, true));
  if (stack) {
    printLine(stack.join('\n'));
  }
  const nestedIndent = (indentString += '  ');
  nested?.forEach((e) => {
    output += errorObjectToString(e, nestedIndent);
  });
  return output;
}

function removeParentDuplications(errorObject: IErrorObject) {
  if (!errorObject.parent || !errorObject.parent.stack || !errorObject.stack) {
    return;
  }

  let parent = errorObject.parent;
  while (parent) {
    let removedDuplicates;
    for (let i = errorObject.stack.length - 1; i >= 0; i--) {
      if (errorObject.stack[i] === parent.stack?.[i]) {
        parent.stack.pop();
        removedDuplicates = true;
      }
    }
    if (removedDuplicates) {
      parent.stack.push(
        '  --- Removed duplicate stack trace entries (see below) ---',
      );
    }
    parent = parent.parent;
  }

  removeParentDuplications(errorObject.parent);
}

function removeStackDuplications(errorObject: IErrorObject) {
  errorObject.nested.forEach((n) => {
    removeStackDuplications(n);
  });
  if (errorObject.nested.length === 0) {
    removeParentDuplications(errorObject);
  }
}

export function getOriginalError(error): Error {
  if (error.originalError) {
    return getOriginalError(error.originalError);
  }
  return error;
}

export function getErrorString(error): string {
  if (!error) {
    const message = `getErrorString called with no error: ${new Error().stack}`;
    logger.error(message);
    return message;
  }

  const errorObject = analyzeError({ error });
  removeStackDuplications(errorObject);
  const output = errorObjectToString(errorObject);
  return output;
}
