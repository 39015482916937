import { buttonProperties } from '../types';

export const attachmentButtonPropertyInfo = {
  attachmentContainer: {
    description:
      'a pointer to the record containing the id and hasAttachment fields (read by this widget), this record becomes the data for the widget and is available to an update pipeline when hasAttachment changes value',
  },
  acceptTypes: {
    description:
      'comma separate list of file type specifiers, like .jpg, .png, or image/*',
  },
  pathPrefix: {
    description: 'the prefix to the path for the attachments',
  },
  ...buttonProperties,
} as const;

export const attachmentButtonTypeInfo = {
  types: {
    children: true,
  },
  properties: attachmentButtonPropertyInfo,
  events: {
    load: [],
    update: [],
  },
  childTypes: {},
};

// The record that contains the reference to the attachment
export interface IAttachmentContainer {
  id: string;
  hasAttachment: boolean;
}
