import produce from 'immer';
import {
  FunctionComponent,
  Profiler,
  useCallback,
  useRef,
  useState,
} from 'react';
import { LogLevels, Loggers, getLogger } from 'universal/loggerSupport';

import { useInterval } from '../hooks/useInterval';

import {
  ClientLogDebugContext,
  IClientLogDebugContext,
} from './ClientLogDebugContext';

const performanceLogger = getLogger({
  name: Loggers.CLIENT_PERFORMANCE,
  level: LogLevels.Warn,
});

const ClientLogDebug: FunctionComponent<React.PropsWithChildren<unknown>> = (
  props,
) => {
  const { children } = props;
  const [clientLogDebugContext, setClientLogDebugContext] =
    useState<IClientLogDebugContext>(null);

  const performance = useRef({
    renders: 0,
    renderTime: 0,
    mountTime: 0,
    updateTime: 0,
  });

  const storeUpdates = useRef({});

  const { clientDebugMode, logs } = clientLogDebugContext || {};
  const debugUpdateInterval = clientDebugMode ? 5000 : null;

  useInterval(() => {
    setClientLogDebugContext({
      ...clientLogDebugContext,
      logs: { ...storeUpdates.current },
    });
  }, debugUpdateInterval);

  function logPerformance(id, phase, actualDuration) {
    performance.current.renders++;
    performance.current.renderTime += actualDuration;
    if (phase === 'mount') {
      performance.current.mountTime += actualDuration;
    } else {
      performance.current.updateTime += actualDuration;
    }

    const alteredWindow = window as any;

    performanceLogger.info(`Render time: ${performance.current.renderTime}`);

    if (alteredWindow.reportPerformance) {
      alteredWindow.reportPerformance(performance.current);
    }
  }

  const logUpdate = useCallback(
    (name: string) => {
      if (storeUpdates.current[name]) {
        storeUpdates.current = produce(storeUpdates.current, (draft) => {
          draft[name].updates++;
        });
      } else {
        storeUpdates.current[name] = { updates: 1 };
      }
    },
    [storeUpdates],
  );

  if (!clientLogDebugContext) {
    setClientLogDebugContext({
      clientDebugMode,
      logs,
      logUpdate,
      setClientDebugMode: (debugMode: boolean) => {
        setClientLogDebugContext({
          ...clientLogDebugContext,
          clientDebugMode: debugMode,
        });
      },
    });
  }

  return (
    <ClientLogDebugContext.Provider value={clientLogDebugContext}>
      <Profiler id="app" onRender={logPerformance}>
        {children}
      </Profiler>
    </ClientLogDebugContext.Provider>
  );
};

export default ClientLogDebug;
