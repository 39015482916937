/**
 * Client side database considerations.
 *
 * This is any in universal/client that needs to be aware of when accessing the database.
 */

import { DynamoDBProviderClient } from './dynamoDBProviderClient';

export interface IDatabaseProviderClient {
  getGraphQLBatchSizeLimit(): number;
  getIamPolicy(): string;
}

export function getDatabaseProviderClient(): IDatabaseProviderClient {
  return new DynamoDBProviderClient();
}
