import { InMemoryCache, TypePolicies } from '@apollo/client/cache';
import {
  ApolloClient,
  ApolloLink,
  FetchPolicy,
  FetchResult,
  NormalizedCacheObject,
} from '@apollo/client/core/index.js';
import { loadDevMessages, loadErrorMessages } from '@apollo/client/dev';

import { RetryLink } from '@apollo/client/link/retry';
import { getMainDefinition } from '@apollo/client/utilities';
import { Lambda } from '@aws-sdk/client-lambda';
import { OperationDefinitionNode } from 'graphql';
import { v1 as uuidv1 } from 'uuid';
import Observable from 'zen-observable';
import { Observer } from 'zen-observable-ts';

import { ClientManager } from '../clientManager';
import { getLambdaFunctionFullName } from '../common/commonUtilities';
import { reThrow } from '../errors/errorLog';
import { getErrorString } from '../errors/errorString';
import { isRetryableError } from '../errors/retry';
import { GRAPHQL_FUNCTION } from '../lambdaSupport';
import { getLogger, Loggers } from '../loggerSupport';
import { MetadataSupport } from '../metadataSupport';
import { CollectionTypes } from '../metadataSupportConstants';
import { getGraphqlTypeName } from '../pipeline/graphQLSupport';
import {
  getFieldNameInfo,
  getGraphqlQueryFieldNameFromDocument,
  printGraphQLDocument,
} from '../pipeline/graphQLSupportAst';
import { removeExtraWhiteSpace } from '../utilityFunctions';

import { IMqttProvider } from 'universal/mqttProvider';
import { GraphQLManager } from '../pipeline/graphQLManager';
import { DynamicTransportLink } from './dynamicTransportLink';
import { createLambdaLink } from './lambdaLink';
import { NonTerminatingLink } from './nonTerminatingLink';
import { SubscriptionHandshakeLink } from './subscriptionHandshakeLink';

const logger = getLogger({ name: Loggers.GRAPHQL_CLIENT });

// Give it a little longer than the lambda startup time which can be about 10 sec
export const GRAPHQL_CLIENT_TIMEOUT_MS = 20000;

export const FETCH_POLICY = 'cache-first';

export const createSubscriptionHandshakeLink = (params: {
  clientManager: ClientManager;
  mqttProvider: IMqttProvider;
  mqttEndpoint: string;
  resultsFetcherLink: ApolloLink;
}) => {
  const { resultsFetcherLink } = params;
  return ApolloLink.split(
    (operation) => {
      const { query } = operation;
      const { kind, operation: graphqlOperation } = getMainDefinition(
        query,
      ) as OperationDefinitionNode;
      const isSubscription =
        kind === 'OperationDefinition' && graphqlOperation === 'subscription';

      return isSubscription;
    },
    ApolloLink.from([
      // Normal HTTP request to the server to process the subscribe message
      new NonTerminatingLink('subsInfo', { link: resultsFetcherLink }),
      // Handles the subscription with MQTT
      new SubscriptionHandshakeLink({
        subsInfoContextKey: 'subsInfo',
        clientIdRoot: uuidv1(),
        ...params,
      }),
    ]),
    resultsFetcherLink,
  );
};

function createTypePolicies(clientManager: ClientManager): TypePolicies {
  const { schemaManager } = clientManager;

  const typePolicies: TypePolicies = {
    Query: { fields: {} },
  };
  const queryFields = typePolicies.Query.fields;
  const entityTypes = schemaManager.getAllEntityTypes();
  for (const entity of entityTypes) {
    const entityName = MetadataSupport.getUnqualifiedName(entity.id);
    queryFields[`list${entityName}`] = GraphQLManager.createListFieldPolicy(
      clientManager,
      entity.typeDefinitionObject,
    );
    queryFields[`get${entityName}`] = GraphQLManager.createGetFieldPolicy(
      clientManager,
      entity.typeDefinitionObject,
    );
  }

  const typeDefs = schemaManager.getAllTypeDefinitions();
  typeDefs
    .filter((td) => td.useAlternateTypeNameWhenNested || !td.isEntitysType)
    .forEach(
      (td) =>
        (typePolicies[getGraphqlTypeName(td, true)] = {
          keyFields: false,
        }),
    );

  for (const typeDef of typeDefs) {
    const attrs = typeDef.getAttributes();
    for (const attr of attrs) {
      const { collectionType } = attr.itemInfo;
      if (
        collectionType === CollectionTypes.MAP ||
        collectionType === CollectionTypes.ARRAY
      ) {
        const graphqlTypeName = getGraphqlTypeName(typeDef);
        let typePolicy = typePolicies[graphqlTypeName];
        if (!typePolicy) {
          typePolicy = typePolicies[graphqlTypeName] = { fields: {} };
        } else if (!typePolicy.fields) {
          typePolicy.fields = {};
        }

        typePolicy.fields[attr.name] = {
          merge(existing, incoming, { mergeObjects }) {
            if (!existing) {
              return incoming;
            }
            if (!incoming) {
              return existing;
            }
            if (collectionType === CollectionTypes.MAP) {
              const incomingMap = {};
              const existingMap = {};
              incoming.forEach((i) => (incomingMap[i.id] = i));
              existing.forEach((e) => (existingMap[e.id] = e));
              Object.keys(incomingMap).forEach((k) => {
                if (existingMap[k]) {
                  existingMap[k] = mergeObjects(existingMap[k], incomingMap[k]);
                } else {
                  existingMap[k] = incomingMap[k];
                }
              });
              return Object.values(existingMap);
            } else {
              // Arrays are always just replaced with the new data
              return incoming;
            }
          },
        };
      }
    }
  }

  return typePolicies;
}

export function createGraphQLClient(params: {
  clientManager: ClientManager;
  lambdaClient: Lambda;
  mqttProvider: IMqttProvider;
  mqttEndpoint: string;
  idToken: string;
  localLink?: ApolloLink;
  defaultFetchPolicy?: FetchPolicy;
}): ApolloClient<NormalizedCacheObject> {
  const {
    clientManager,
    lambdaClient,
    localLink,
    idToken,
    defaultFetchPolicy,
  } = params;

  try {
    if (!idToken) {
      throw new Error('Missing idToken');
    }

    loadDevMessages();
    loadErrorMessages();

    const lambdaLink = lambdaClient
      ? createLambdaLink({
          clientManager,
          lambda: lambdaClient,
          functionName: getLambdaFunctionFullName(
            clientManager.stackId,
            GRAPHQL_FUNCTION,
          ),
          headers: {
            authorization: idToken,
          },
        })
      : undefined;

    const transportLink = new DynamicTransportLink(lambdaLink, localLink);

    const linkTimeout = new ApolloLink((operation, forward) => {
      const downstreamObservable = forward(operation);

      const fieldName = getGraphqlQueryFieldNameFromDocument(operation.query);
      if (!fieldName) {
        return downstreamObservable;
      }
      const fieldNameInfo = getFieldNameInfo(fieldName);

      let timeoutMs;
      switch (fieldNameInfo.queryType) {
        case 'Mutation':
        case 'Get':
          timeoutMs = GRAPHQL_CLIENT_TIMEOUT_MS;
          break;
      }
      if (!timeoutMs) {
        return downstreamObservable;
      }

      let upstreamObserver: Observer<FetchResult>;

      const timeout = setTimeout(() => {
        const message = `Query/mutation timed out after ${timeoutMs}ms`;
        const error: any = new Error(message);
        error.retryable = true;
        logger.warn(
          `${message} - restarting: ${removeExtraWhiteSpace(
            printGraphQLDocument(operation.query),
          ).slice(0, 200)}`,
        );
        upstreamObserver.error(error);
      }, timeoutMs);

      const observable = new Observable((observer) => {
        upstreamObserver = observer;
        downstreamObservable.subscribe(observer);
        return () => {
          clearTimeout(timeout);
        };
      });

      return observable;
    });

    const linkRetry = new RetryLink({
      delay: {
        initial: 2001,
        jitter: true,
      },
      attempts: {
        max: 10,
        retryIf: (error) => {
          const retryable = isRetryableError({ error, retryContext: {} });
          if (retryable) {
            logger.warn({ error: getErrorString(error) }, 'link retry request');
          }
          return retryable;
        },
      },
    });

    const link = ApolloLink.from([
      linkRetry,
      linkTimeout,
      createSubscriptionHandshakeLink({
        resultsFetcherLink: transportLink,
        ...params,
      }),
    ]);

    const defaultOptions = defaultFetchPolicy
      ? {
          watchQuery: {
            fetchPolicy: defaultFetchPolicy,
          },
          query: {
            fetchPolicy: defaultFetchPolicy,
          },
        }
      : undefined;

    const apolloClient = new ApolloClient({
      link,
      connectToDevTools: true,
      cache: new InMemoryCache({
        addTypename: true,
        typePolicies: createTypePolicies(clientManager),
      }),
      defaultOptions,
    });
    return apolloClient;
  } catch (error) {
    reThrow({ error, logger, message: 'Problem getting apolloClient' });
  }
}

export async function shutdownGraphQLClient(apolloClient: ApolloClient<any>) {
  const shutdownLink = async (link: ApolloLink) => {
    if (link instanceof SubscriptionHandshakeLink) {
      await (link as SubscriptionHandshakeLink).shutdown();
    }
    if (link.left) {
      await shutdownLink(link.left);
    }
    if (link.right) {
      await shutdownLink(link.right);
    }
  };
  await shutdownLink(apolloClient.link);
  apolloClient.stop();
}
