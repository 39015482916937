import { IPropertyConfiguration } from 'universal/propertySupport';
import { AdditionalEditor, BasicType } from 'universal/types';

import { materialUiFontWeights } from '../../util/typographyTranslate';

import { agChartTypeInfo } from './AGChart/AGChartTypeInfo';
import { agGridTypeInfo } from './AGGrid/AGGridTypeInfo';
import { attachmentButtonTypeInfo } from './AttachmentButton/AttachmentButtonTypeInfo';
import { buttonTypeInfo } from './Button';
import { cardWidgetTypeInfo } from './Card/Card';
import {
  cardActionsTypeInfo,
  cardContentTypeInfo,
  cardHeaderTypeInfo,
  cardMediaTypeInfo,
} from './Card/CardItems';
import { collapseTypeInfo } from './Collapse/CollapseTypeInfo';
import { displayTypeInfo } from './Display/DisplayTypeInfo';
import { flexContainerTypeInfo } from './FlexContainer/FlexContainerTypeInfo';
import { gridContainerTypeInfo } from './GridContainer/GridContainerTypeInfo';
import { iconTypeInfo } from './Icon/IconTypeInfo';
import { imageTypeInfo } from './Image/Image';
import {
  kendoGridSubscribeMapBuilder,
  kendoGridTransform,
} from './KendoGrid/KendoGrid';
import {
  kendoAggregateTypeInfo,
  kendoCellTypeInfo,
  kendoColumnTypeInfo,
  kendoExportButtonTypeInfo,
  kendoExportTypeInfo,
  kendoGridTypeInfo,
  kendoToolbarTypeInfo,
} from './KendoGrid/KendoTypes';
import { listTransform } from './List/List';
import { menuItemTypeInfo } from './MenuItemWidget/MenuItemTypeInfo';
import { menuWidgetTypeInfo } from './MenuWidget/MenuWidgetTypeInfo';
import { multiSelectTypeInfo } from './MultiSelect/MultiSelectTypeInfo';
import { richTextTypeInfo } from './RichTextEditor/RichTextTypeInfo';
import {
  ConfigurationTypeName,
  ICON_DESCRIPTION,
  IConfigurationType,
  IConfigurationTypes,
  categories,
  configurationTypeCategories,
  containerProperties,
  duplicateBy,
  graphAxisScale,
  gridAlignOptions,
  gridOptions,
  inputProperties,
  mUiColorPrimary,
  mUiColorSecondary,
  overflow,
  textVariants,
  universalProperties,
} from './types';

const configurationTypesDetails: IConfigurationTypes = {
  CardWidget: cardWidgetTypeInfo,
  CardHeader: cardHeaderTypeInfo,
  CardContent: cardContentTypeInfo,
  CardActions: cardActionsTypeInfo,
  CardMedia: cardMediaTypeInfo,
  Grid: {
    types: {
      deprecated: true,
    },
    childTypes: {},
    properties: {},
  },
  GridWidget: {
    types: {
      deprecated: true,
    },
    childTypes: {},
    properties: {},
  },
  Tree: {
    types: {
      children: true,
    },
    childTypes: {
      children: true,
    },
    properties: {
      json: {
        pointer: 'must',
      },
    },
    childProperties: {
      node: {},
      parentNode: {},
      labelValue: {
        default: "'label'",
        options: ["'label'", "'value'"],
      },
    },
    events: {
      load: [],
    },
  },
  LayoutWidget: {
    types: {
      children: true,
    },
    childTypes: {
      widgetPool: true,
      selectorPools: true,
      layouts: true,
    },
    properties: {
      layoutToUse: {
        pointer: 'can',
      },
    },
    childProperties: {
      ...duplicateBy,
    },
    events: {
      load: [],
      update: [],
      validate: [],
    },
  },
  Layout: {
    nestedConfigItem: true,
    types: {
      layouts: true,
    },
    childTypes: {
      flexAreas: true,
      widgetSelectors: true,
      widgetArrays: true,
    },
    properties: {
      ...containerProperties,
      permissionId: {},
      gridRows: {
        required: false,
        description:
          'Space separated list of measurements representing rows, units are px, %, fr, and auto, ex: 10% auto 10%, 400px 2fr 1fr',
        link: 'https://css-tricks.com/snippets/css/complete-guide-grid/',
      },
      gridColumns: {
        required: false,
        description:
          'Space separated list of measurements representing columns, units are px, %, fr, and auto, ex: 10% auto 10%, 400px 2fr 1fr',
        link: 'https://css-tricks.com/snippets/css/complete-guide-grid/',
      },
      alignItems: {
        options: gridAlignOptions,
        link: 'https://css-tricks.com/snippets/css/complete-guide-grid/',
      },
      justifyItems: {
        options: gridAlignOptions,
        link: 'https://css-tricks.com/snippets/css/complete-guide-grid/',
      },
      gridRowGap: {
        description: 'forms gutters between the rows',
      },
      gridColumnGap: {
        description: 'forms gutters between the columns',
      },
      adjustableHeight: {
        description:
          'If set to true, allows children widgets with a height setting api like list to set the height of this widget',
        options: [true, false],
        default: false,
      },
    },
    childProperties: {
      gridRow: {
        required: false,
      },
      gridColumn: {
        required: false,
      },
      alignSelf: {
        options: gridAlignOptions,
        link: 'https://css-tricks.com/snippets/css/complete-guide-grid/',
      },
      justifySelf: {
        options: gridAlignOptions,
        link: 'https://css-tricks.com/snippets/css/complete-guide-grid/',
      },
      padding: {},
      allowGridBlowout: {
        options: [true, false],
        default: false,
        description:
          'If set to true, the content of the area will be able to stretch its grid panel to fit its content',
      },
    },
  },
  FlexArea: {
    nestedConfigItem: true,
    types: {
      flexAreas: true,
    },
    childTypes: {
      widgetSelectors: true,
      widgetArrays: true,
      selectorPoolSelectors: true,
    },
    properties: {
      width: {
        default: "'100%'",
        required: false,
        description:
          'If empty, this container will fill all available space. Set a unit or percentage here to set width manually. ex: 20%, 400px, 3cm, min-content',
      },
      height: {
        default: "'100%'",
        required: false,
        description:
          'If empty, this container will fill all available space. set a unit or percentage here to override that ex: 20%, 400px, 3cm, min-content',
      },
      header: {},
      headerTextColor: {
        additionalEditor: AdditionalEditor.Color,
      },
      headerBackgroundColor: {
        additionalEditor: AdditionalEditor.Color,
      },
      padding: { default: "'5px'" },
      margin: { default: "'5px'" },
      border: {
        default: "'none'",
        options: ["'paper'", "'solid'", "'dotted'", "'dashed'", "'none'"],
      },
      flexDirection: {
        default: "'row'",
        options: ["'row'", "'column'"],
        description: 'Direction that content will flow',
        link: 'https://css-tricks.com/snippets/css/a-guide-to-flexbox/',
      },
      flexWrap: {
        default: "'wrap'",
        options: ["'nowrap'", "'wrap'", "'wrap-reverse'"],
        description:
          'How and if to wrap context that overflows the flex direction',
        link: 'https://css-tricks.com/snippets/css/a-guide-to-flexbox/',
      },
      ...gridOptions,
      spacing: {
        default: 0,
        options: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      },
      justifyContent: {
        default: "'flex-start'",
        options: [
          "'flex-start'",
          "'flex-end'",
          "'center'",
          "'space-between'",
          "'space-around'",
          "'space-evenly'",
        ],
        link: 'https://css-tricks.com/snippets/css/a-guide-to-flexbox/',
      },
      alignItems: {
        default: "'stretch'",
        options: [
          "'flex-start'",
          "'flex-end'",
          "'center'",
          "'stretch'",
          "'baseline'",
        ],
        link: 'https://css-tricks.com/snippets/css/a-guide-to-flexbox/',
      },
      alignContent: {
        default: "'stretch'",
        options: [
          "'flex-start'",
          "'flex-end'",
          "'center'",
          "'space-between'",
          "'space-around'",
          "'stretch'",
        ],
        link: 'https://css-tricks.com/snippets/css/a-guide-to-flexbox/',
      },
      render: {
        default: true,
      },
      preventRender: {
        default: false,
      },
      overflow,
    },
    childProperties: {
      useOwnGridSizing: {
        options: [true, false],
        default: false,
        description:
          'Whether or not to use this selectors own size properties below. When false this will use the parents grid sizing for convenience',
      },
      ...gridOptions,
    },
  },
  WidgetSelector: {
    nestedConfigItem: true,
    types: {
      widgetSelectors: true,
      widgetArrays: true,
    },
    childTypes: {},
    properties: {
      widgetName: {},
    },
  },
  WidgetArray: {
    nestedConfigItem: true,
    types: {
      widgetArrays: true,
    },
    childTypes: {},
    properties: {
      widgetNames: {},
    },
  },
  SelectorPool: {
    nestedConfigItem: true,
    types: {
      selectorPools: true,
    },
    childTypes: {
      widgetSelectors: true,
      widgetArrays: true,
    },
    properties: {},
    childProperties: {
      useOwnGridSizing: {
        options: [true, false],
        default: false,
        description:
          'Whether or not to use this selectors own size properties below. When false this will use the parents grid sizing for convenience',
      },
      ...gridOptions,
    },
  },
  SelectorPoolSelector: {
    nestedConfigItem: true,
    types: {
      selectorPoolSelectors: true,
    },
    childTypes: {},
    properties: {
      selectorPoolName: {},
    },
  },
  Tabs: {
    types: {
      children: true,
    },
    childTypes: {
      children: true,
    },
    properties: {
      indicatorColor: mUiColorPrimary,
      textColor: mUiColorPrimary,
      centered: {
        default: false,
        options: [false, true],
      },
    },
    childProperties: {
      tabName: {},
      tabIcon: {},
      tabColor: {
        additionalEditor: AdditionalEditor.Color,
      },
      defaultTab: {
        default: false,
        options: [true, false],
      },
      disabled: {
        default: false,
        options: [true, false],
      },
      ...duplicateBy,
    },
    events: {
      load: [],
    },
  },
  LogViewer: {
    types: {
      children: true,
    },
    childTypes: {
      children: true,
    },
    properties: {
      log: {
        description: 'the log',
        pointer: 'must',
      },
      height: {
        default: "'600px'",
      },
      headerHeight: {
        default: 0,
        description:
          'Will render any children within a header area of specified height',
      },
    },
    events: {
      load: [],
    },
  },
  OptionsOverlay: {
    types: {
      overlays: true,
    },
    childTypes: {
      children: true,
    },
    properties: {
      label: {
        default: "'options'",
      },
      height: {
        default: "'50%'",
      },
      overflow,
    },
    events: {
      load: [],
    },
  },
  PDFContainer: {
    types: {
      children: true,
    },
    childTypes: {
      children: true,
      actions: true,
      overlays: true,
    },
    properties: {
      orientation: {
        default: "'portrait'",
        options: ["'portrait'", "'landscape'"],
      },
      filename: {},
      includeDate: {
        default: true,
        options: [true, false],
      },
      overSample: {
        default: 4,
        description:
          'oversample scale to increase pdf resolution. 4 is usually optimum, lower can be blurry, higher can be aliased and slow.',
      },
    },
    events: {
      load: [],
    },
  },
  CSVWidget: {
    types: {
      children: true,
    },
    childTypes: {},
    properties: {
      rows: {},
      sortBy: {
        description:
          'underscore separated string of fields, in order, you want to sort by',
      },
      columns: {},
      columnLabels: {},
      filename: {},
      buttonText: {
        default: "'download'",
      },
      closePopupOnClick: {
        default: true,
        options: [true, false],
      },
    },
  },
  CollapseWidget: collapseTypeInfo,
  ActionGroup: {
    nestedConfigItem: true,
    types: {
      actions: true,
    },
    childTypes: {},
    properties: {
      actionGroupName: {
        description: 'pointer to the action group',
      },
      renderOwnActions: {
        default: false,
        options: [true, false],
        description:
          'By default, a widget of the popup category and all of its children will not render their own popup actions, so that if that popup closes the new one remains mounted. This overrides this behavior, allowing context to be passed as normal, but causing all of this popups actions to be closed if it closes.',
      },
    },
  },
  MenuItemWidget: menuItemTypeInfo,
  ModalWidget: {
    types: {
      actions: true,
    },
    childTypes: {
      children: true,
    },
    properties: {
      mouseTrigger: {
        default: "'click'",
        options: ["'click'", "'rightClick'"],
      },
      renderOwnActions: {
        default: false,
        options: [true, false],
        description:
          'By default, a widget of the popup category and all of its children will not render their own popup actions, so that if that popup closes the new one remains mounted. This overrides this behavior, allowing context to be passed as normal, but causing all of this popups actions to be closed if it closes.',
      },
    },
    events: {
      load: [],
    },
  },
  ModalWidgetStasis: {
    types: {
      actions: true,
    },
    childTypes: {
      children: true,
    },
    properties: {
      mouseTrigger: {
        default: "'click'",
        options: ["'click'", "'rightClick'"],
      },
      renderOwnActions: {
        default: false,
        options: [true, false],
        description:
          'By default, a widget of the popup category and all of its children will not render their own popup actions, so that if that popup closes the new one remains mounted. This overrides this behavior, allowing context to be passed as normal, but causing all of this popups actions to be closed if it closes.',
      },
    },
    events: {
      load: [],
    },
  },
  PopupWidget: {
    types: {
      actions: true,
    },
    childTypes: {
      children: true,
    },
    category: categories.popup,
    properties: {
      mouseTrigger: {
        default: "'hover'",
        options: ["'click'", "'rightClick'", "'hover'"],
      },
      anchorReference: {
        options: ["'anchorEl'", "'anchorPosition'"],
        default: "'anchorEl'",
        description:
          'switches between html element and xy coords as the anchoring point of the popup, anchor position breaks within scroll',
      },
      anchorOriginVertical: {
        description: 'sets the contact corner of the click trigger element',
        default: "'top'",
        options: ["'top'", "'center'", "'bottom'"],
      },
      anchorOriginHorizontal: {
        description: 'sets the contact corner of the click trigger element',
        default: "'right'",
        options: ["'left'", "'center'", "'right'"],
      },
      transFormOriginVertical: {
        description: 'sets the contact corner of the popup',
        default: "'top'",
        options: ["'top'", "'center'", "'bottom'"],
      },
      transFormOriginHorizontal: {
        description: 'sets the contact corner of the popup',
        default: "'left'",
        options: ["'left'", "'center'", "'right'"],
      },
      anchorPositionTop: {
        description:
          "used only when 'anchorPosition' is selected, specifies the location relative to the top of the window",
      },
      anchorPositionLeft: {
        description:
          "used only when 'anchorPosition' is selected, specifies the location relative to the left side of the window",
      },
      renderOwnActions: {
        default: false,
        options: [true, false],
        description:
          'By default, a widget of the popup category and all of its children will not render their own popup actions, so that if that popup closes the new one remains mounted. This overrides this behavior, allowing context to be passed as normal, but causing all of this popups actions to be closed if it closes.',
      },
    },
    events: {
      load: [],
    },
  },
  MenuWidget: menuWidgetTypeInfo,
  Chip: {
    types: {
      children: true,
    },
    childTypes: {
      actions: true,
    },
    properties: {
      label: {},
      width: {},
      color: {
        additionalEditor: AdditionalEditor.Color,
      },
      textColor: {
        additionalEditor: AdditionalEditor.Color,
      },
      icon: {
        description: ICON_DESCRIPTION,
      },
      avatarColor: {
        additionalEditor: AdditionalEditor.Color,
      },
      iconColor: {
        additionalEditor: AdditionalEditor.Color,
      },
      textDecoration: {
        default: "'none'",
        description:
          'add any number of decorators to the text, separated with a space. ex: line-through, , wavy overline lime, dashed underline overline, blink',
        link: 'https://developer.mozilla.org/en-US/docs/Web/CSS/text-decoration',
      },
      variant: {
        description: 'options are filled and outlined',
        options: ["'filled'", "'outlined'"],
      },
    },
    events: {
      load: [],
    },
  },
  CheckboxWidget: {
    types: {
      children: true,
    },
    childTypes: {
      actions: true,
    },
    category: categories.input,
    properties: {
      ...inputProperties,
      startingValue: {
        default: false,
        options: [true, false, "'indeterminate'"],
        description:
          'Starting state of the checkbox, used only if the initialValue is not defined. Use this for a form where there is no starting data.',
      },
      variant: {
        default: "'checkbox'",
        options: ["'checkbox'", "'switch'", "'dropdown'"],
      },
      color: mUiColorSecondary,
      uncheckedIcon: {
        description: 'uncheckedIcon icon: ' + ICON_DESCRIPTION,
      },
      checkedIcon: {
        description: 'checked icon: ' + ICON_DESCRIPTION,
      },
      uncheckedColor: {},
    },
    events: {
      load: [],
      update: [],
      validate: [],
    },
  },
  Text: {
    types: {
      children: true,
    },
    childTypes: {
      actions: true,
    },
    category: categories.input,
    properties: {
      ...inputProperties,
      variant: {
        default: "'standard'",
        options: ["'standard'", "'outlined'", "'filled'"],
      },
      inForm: {
        // default: true,
        options: [true, false],
        description: 'This component participates in the form its within',
      },
      required: {
        default: false,
        options: [true, false],
        description: 'Will indicate if this field is required',
      },
      multiline: {
        default: false,
        options: [true, false],
        description: 'Text area instead of text box for multiline input',
      },
      fontSize: {
        options: textVariants,
      },
      fontWeight: {
        description:
          'optional font weights defined by the material ui spec, medium and regular seem to be the same. Leave them set to blank to use the variants default',
        default: '',
        options: [
          '',
          ...(Object.keys(materialUiFontWeights).map(
            (key) => `'${key}'`,
          ) as string[]),
        ],
      },
      color: {
        additionalEditor: AdditionalEditor.Color,
      },
      rows: {
        description: 'If multiline is enabled, sets the number of visible rows',
      },
      rowsMax: {
        description: 'If multiline is enabled, sets the max number of rows',
      },
      maxLength: {
        description: 'Number of total characters allowed',
      },
      type: {
        default: "'text'",
        options: [
          "'text'",
          "'email'",
          "'number'",
          "'date'",
          "'tel'",
          "'time'",
          "'url'",
          "'password'",
          "'datetime'",
          "'datetime-local'",
        ],
        description: 'Sets the html type tag for different input enforcement',
      },
      compact: {
        default: false,
        options: [
          false,
          "'thousands'",
          "'millions'",
          "'billions'",
          "'percent'",
        ],
        description:
          'Only applies to number type inputs. Will override some other properties',
      },
      thousandSeparator: {
        default: false,
        options: [true, false],
        description:
          'Only applies to number type inputs. Add thousand separators on number',
      },
      decimalScale: {
        description:
          'Only applies to number type inputs. If defined it limits to given decimal scale',
      },
      fixedDecimalScale: {
        default: false,
        options: [true, false],
        description:
          'Only applies to number type inputs. If true it add 0s to match given decimalScale',
      },
      prefix: {
        description: 'Only applies to number type inputs.',
      },
      suffix: {
        description: 'Only applies to number type inputs.',
      },
      format: {
        description:
          'Only applies to number type inputs. String: Hash based (ex: (###) ###-#### )',
        default: false,
      },
      mask: {
        description:
          'Only applies to number type inputs. String (ex : _) If mask defined, component will show non entered placed with masked value.',
      },
      pattern: {
        description: 'Regular expression pattern for string inputs.',
      },
      patternFailText: {
        description: 'Text to display if the pattern is not met.',
      },
      allowEmptyFormatting: {
        default: true,
        options: [true, false],
        description:
          'Only applies to number type inputs. Apply formatting to empty inputs.',
      },
      allowNegative: {
        default: true,
        options: [true, false],
        description:
          'Only applies to number type inputs. Allow negative numbers (Only when format option is not provided)',
      },
      nullable: {
        default: true,
        options: [true, false],
        description:
          'If set to false, there will always be a value, a 0 for numbers or an empty string for text. If true, an empty input for numbers will have a null value',
      },
      textAlign: {
        default: "'left'",
        options: ["'left'", "'right'", "'center'"],
        description: 'Aligns the text within the input.',
      },
      autoFocus: {
        default: false,
        options: [true, false],
        description:
          'Start editing this field as soon as it mounts, can only have one field at a time autoFocused, might not work reliably',
      },
      width: {},
      fullWidth: {
        default: false,
        options: [true, false],
        description: 'Whether the text field stretches to fill its container',
      },
      updateOnChange: {
        default: false,
        options: [true, false],
        description: 'Run update pipeline on change (useful for text filters)',
      },
      height: {},
      backgroundColor: {
        description: 'Changes the background color of the text area.',
        additionalEditor: AdditionalEditor.Color,
      },
      backgroundColorIfEmpty: {
        description:
          'Changes the background color to this only if there is no value',
        additionalEditor: AdditionalEditor.Color,
      },
      helperText: {
        description: 'Displays the text below the input box',
      },
    },
    events: {
      load: [],
      update: [],
      validate: [],
    },
  },
  SliderWidget: {
    types: {
      children: true,
    },
    childTypes: {
      actions: true,
    },
    category: categories.input,
    properties: {
      ...inputProperties,
      startingValue: {
        type: BasicType.Float,
        description:
          'Starting value for the slider, used only if the initialValue is not defined. Use this for a form where there is no starting data.',
      },
      min: {
        type: BasicType.Float,
      },
      max: {
        type: BasicType.Float,
      },
      step: {
        type: BasicType.Float,
      },
      width: {
        default: 300,
      },
      labelVariant: {
        default: "'body2'",
        description:
          'Applies the style and sizing, see examples of each here in link',
        link: 'https://material-ui.com/style/typography/',
      },
      color: mUiColorSecondary,
      padding: { default: "'5px'" },
    },
    events: {
      load: [],
      update: [],
      validate: [],
    },
  },
  DropdownSelect: {
    types: {
      children: true,
    },
    childTypes: {
      actions: true,
    },
    category: categories.input,
    properties: {
      ...inputProperties,
      options: {
        pointer: 'must',
        required: true,
        description:
          'Array of options for selection. Can be strings or objects. If objects, an optionLabel property is required which points to a string withing the object to be used as a label, and an optionData field is optional',
      },
      optionLabel: {
        required: false,
        description:
          'If options are objects, this must point to the field to display as the label',
      },
      optionData: {
        description:
          'If options are objects, this points to the data field within the object, which will be passed to any form or update pipelines and be used to match the current selection to the options. If not specified, the whole object is passed.',
      },
      boolean: {
        default: false,
        options: [true, false],
        description:
          'If true, options are replaced with true and false. Works with nullOption',
      },
      yesNo: {
        default: false,
        options: [true, false],
        description:
          'If true, and boolean is true, will use yes/no as option labels in place of true/false',
      },
      nullOption: {
        default: false,
        description:
          'Whether or not a null value is an option in addition to the options, if not false, the string will represent the null option for the user to click, ex: none , choose one...',
      },
      required: {
        default: false,
        options: [true, false],
        description: 'Will indicate if this field is required',
      },
      freeSolo: {
        default: false,
        options: [true, false],
        description: 'If true, arbitrary text may be entered',
      },
      width: {
        default: "'200px'",
        description: '%, px, cm',
      },
      graphqlIsString: {
        default: false,
        options: [true, false],
      },
      backgroundColor: {
        description: 'Changes the background color of the text area.',
        additionalEditor: AdditionalEditor.Color,
      },
      backgroundColorIfEmpty: {
        description:
          'Changes the background color to this only if there is no value',
        additionalEditor: AdditionalEditor.Color,
      },
      fontSize: {
        default: "'body1'",
        options: textVariants,
      },
      closePopupOnClick: {
        options: [true, false],
        description:
          'if true, this will close the popup this is within on select',
      },
      helperText: {
        description: 'Displays the text below the input box',
      },
    },
    events: {
      load: [],
      update: [],
      validate: [],
    },
  },
  Router: {
    types: {
      children: true,
    },
    childTypes: {
      overlays: true,
      children: true,
    },
    properties: {},
    childProperties: {
      routeLabel: {
        required: true,
        description: 'This is what will show up in the breadcrumb',
      },
      url: {
        required: true,
      },
      ...duplicateBy,
    },
    events: {
      load: [],
    },
  },
  TreeLink: {
    types: {
      children: true,
    },
    childTypes: {
      overlays: true,
      children: true,
    },
    properties: {
      treeName: {
        pointer: 'never',
        description:
          'The name of a widget tree to be invoked as children from this point in the current tree, context intact.',
        required: true,
      },
      optional: {
        default: false,
        options: [true, false],
        description:
          'If true, will not produce an error if treelink is not found',
      },
    },
    events: {
      load: [],
    },
  },
  Button: buttonTypeInfo,
  AttachmentButton: attachmentButtonTypeInfo,
  Icon: iconTypeInfo,
  Image: imageTypeInfo,
  Display: displayTypeInfo,
  GridContainer: gridContainerTypeInfo,
  FlexContainer: flexContainerTypeInfo,
  DataVis: {
    types: {
      children: true,
    },
    childTypes: {
      graphs: true,
    },
    properties: {
      data: {
        pointer: 'must',
        description: 'Must be an array of objects',
      },
    },
    events: {
      load: [],
    },
    childProperties: {
      // this duplicate by is only for nestedConfigItems, does not need the other fields
      duplicateBy: {
        pointer: 'must',
        default: false,
        description:
          'A pointer to an array within context or a pipeline. This graph will be duplicated for each item in the array, and all properties will be treated as a pointer to a field within that object if it is present.',
      },
    },
  },
  Bar: {
    nestedConfigItem: true,
    types: {
      graphs: true,
    },
    childTypes: {
      labels: true,
    },
    properties: {
      dataKey: {
        description:
          'The key or getter of a group of data which should be unique in a LineChart.',
      },
      fill: {
        description:
          'can use random color generator by putting "random". You can also decorate it further with a color name and luminosity ex: "random red", "random blue dark", "random green light"',
      },
      label: {
        default: false,
        options: [true, false],
      },
      data: {
        description:
          'The position information of all the rectangles, usually calculated internally.',
      },
      xAxisId: {
        types: {
          default: BasicType.Int,
          options: [BasicType.Int, BasicType.String],
        },
        default: 0,
        description: 'The id of x-axis which is corresponding to the data.',
      },
      yAxisId: {
        types: {
          default: BasicType.Int,
          options: [BasicType.Int, BasicType.String],
        },
        default: 0,
        description: 'The id of y-axis which is corresponding to the data.',
      },
      stackId: {
        types: {
          default: BasicType.Int,
          options: [BasicType.Int, BasicType.String],
        },
        default: '',
        description:
          'The stack id of bar, when two bars have the same value axis and same stackId, then the two bars are stacked in order.',
      },
      unit: {
        description: 'The unit of data. This option will be used in tooltip.',
      },
      name: {
        description:
          'The name of data. This option will be used in tooltip and legend to represent a bar. If no value was set to this option, the value of dataKey will be used alternatively.',
      },
      legendType: {
        default: "'rect'",
        options: [
          "'line'",
          "'square'",
          "'rect'",
          "'circle'",
          "'cross'",
          "'diamond'",
          "'square'",
          "'star'",
          "'triangle'",
          "'wye'",
          "'none'",
        ],
        description:
          'The type of icon in legend. If set to "none", no legend item will be rendered.',
      },
      minPointSize: {
        type: BasicType.Float,
        description:
          'The minimal height of a bar in a horizontal BarChart, or the minimal width of a bar in a vertical BarChart. By default, 0 values are not shown. To visualize a 0 (or close to zero) point, set the minimal point size to a pixel value like 3. In stacked bar charts, minPointSize might not be respected for tightly packed values. So we strongly recommend not using this props in stacked BarChart.',
      },
    },
  },
  Line: {
    nestedConfigItem: true,
    types: {
      graphs: true,
    },
    childTypes: {
      labels: true,
    },
    properties: {
      type: {
        default: "'linear'",
        options: [
          "'basis'",
          "'basisClosed'",
          "'basisOpen'",
          "'linear'",
          "'linearClosed'",
          "'natural'",
          "'monotoneX'",
          "'monotoneY'",
          "'monotone'",
          "'step'",
          "'stepBefore'",
          "'stepAfter'",
        ],
      },
      dataKey: {
        description:
          'The key or getter of a group of data which should be unique in a LineChart.',
      },
      xAxisId: {
        types: {
          default: BasicType.Int,
          options: [BasicType.Int, BasicType.String],
        },
        default: 0,
        description: 'The id of x-axis which is corresponding to the data.',
      },
      yAxisId: {
        types: {
          default: BasicType.Int,
          options: [BasicType.Int, BasicType.String],
        },
        default: 0,
        description: 'The id of y-axis which is corresponding to the data.',
      },
      stroke: {},
      label: {
        default: false,
        options: [true, false],
      },
      dot: {
        default: true,
        options: [true, false],
        description:
          'If false set, dots will not be drawn. If true set, dots will be drawn which have the props calculated internally.',
      },
      activeDot: {
        default: true,
        options: [true, false],
        description:
          'The active dot is shown when a user enters a line chart and this chart has tooltip. If set to false, no active dot will be drawn. If set to true, active dot will be drawn with the props calculated internally.',
      },
      points: {
        description:
          'The coordinates of all the points in the line, usually calculated internally.',
      },
      connectNulls: {
        default: false,
        options: [true, false],
        description: 'Whether to connect a graph line across null points.',
      },
      unit: {
        description: 'The unit of data. This option will be used in tooltip.',
      },
      name: {
        description:
          'The name of data. This option will be used in tooltip and legend to represent a bar. If no value was set to this option, the value of dataKey will be used alternatively.',
      },
      legendType: {
        default: "'line'",
        options: [
          "'line'",
          "'square'",
          "'rect'",
          "'circle'",
          "'cross'",
          "'diamond'",
          "'square'",
          "'star'",
          "'triangle'",
          "'wye'",
          "'none'",
        ],
        description:
          'The type of icon in legend. If set to "none", no legend item will be rendered.',
      },
      minPointSize: {
        type: BasicType.Float,
        description:
          'The minimal height of a bar in a horizontal BarChart, or the minimal width of a bar in a vertical BarChart. By default, 0 values are not shown. To visualize a 0 (or close to zero) point, set the minimal point size to a pixel value like 3. In stacked bar charts, minPointSize might not be respected for tightly packed values. So we strongly recommend not using this props in stacked BarChart.',
      },
    },
  },
  Area: {
    nestedConfigItem: true,
    types: {
      graphs: true,
    },
    childTypes: {
      labels: true,
    },
    properties: {
      type: {
        default: "'linear'",
        options: [
          "'basis'",
          "'basisClosed'",
          "'basisOpen'",
          "'linear'",
          "'linearClosed'",
          "'natural'",
          "'monotoneX'",
          "'monotoneY'",
          "'monotone'",
          "'step'",
          "'stepBefore'",
          "'stepAfter'",
        ],
      },
      dataKey: {
        description:
          'The key or getter of a group of data which should be unique in a LineChart.',
      },
      xAxisId: {
        types: {
          default: BasicType.Int,
          options: [BasicType.Int, BasicType.String],
        },
        default: 0,
        description: 'The id of x-axis which is corresponding to the data.',
      },
      yAxisId: {
        types: {
          default: BasicType.Int,
          options: [BasicType.Int, BasicType.String],
        },
        default: 0,
        description: 'The id of y-axis which is corresponding to the data.',
      },
      stackId: {
        types: {
          default: BasicType.Int,
          options: [BasicType.Int, BasicType.String],
        },
        default: "''",
        description:
          'The stack id of bar, when two bars have the same value axis and same stackId, then the two bars are stacked in order.',
      },
      stroke: {
        description: 'line color',
      },
      fill: {
        description:
          'can use random color generator by putting "random". You can also decorate it further with a color name and luminosity ex: "random red", "random blue dark", "random green light"',
      },
      fillOpacity: {},
      label: {
        default: false,
        options: [true, false],
      },
      dot: {
        default: true,
        options: [true, false],
        description:
          'If false set, dots will not be drawn. If true set, dots will be drawn which have the props calculated internally.',
      },
      activeDot: {
        default: true,
        options: [true, false],
        description:
          'The active dot is shown when a user enters a line chart and this chart has tooltip. If set to false, no active dot will be drawn. If set to true, active dot will be drawn with the props calculated internally.',
      },
      points: {
        description:
          'The coordinates of all the points in the line, usually calculated internally.',
      },
      connectNulls: {
        default: false,
        options: [true, false],
        description: 'Whether to connect a graph line across null points.',
      },
      unit: {
        description: 'The unit of data. This option will be used in tooltip.',
      },
      name: {
        description:
          'The name of data. This option will be used in tooltip and legend to represent a bar. If no value was set to this option, the value of dataKey will be used alternatively.',
      },
      legendType: {
        default: "'line'",
        options: [
          "'line'",
          "'square'",
          "'rect'",
          "'circle'",
          "'cross'",
          "'diamond'",
          "'square'",
          "'star'",
          "'triangle'",
          "'wye'",
          "'none'",
        ],
        description:
          'The type of icon in legend. If set to "none", no legend item will be rendered.',
      },
      minPointSize: {
        type: BasicType.Float,
        description:
          'The minimal height of a bar in a horizontal BarChart, or the minimal width of a bar in a vertical BarChart. By default, 0 values are not shown. To visualize a 0 (or close to zero) point, set the minimal point size to a pixel value like 3. In stacked bar charts, minPointSize might not be respected for tightly packed values. So we strongly recommend not using this props in stacked BarChart.',
      },
    },
  },
  Legend: {
    nestedConfigItem: true,
    types: {
      graphs: true,
    },
    childTypes: {},
    properties: {
      layout: {
        default: "'horizontal'",
        options: ["'horizontal'", "'vertical'"],
      },
      align: {
        default: "'center'",
        options: ["'left'", "'center'", "'right'"],
      },
      verticalAlign: {
        default: "'top'",
        options: ["'top'", "'middle'", "'bottom'"],
      },
      iconSize: {
        type: BasicType.Float,
        default: 14,
      },
      iconType: {
        default: "''",
        options: [
          "''",
          "'plainline'",
          "'line'",
          "'square'",
          "'rect'",
          "'circle'",
          "'cross'",
          "'diamond'",
          "'star'",
          "'triangle'",
          "'wye'",
        ],
        description:
          'Sets the icon for every graph, overwriting the individual graph icon settings, leave black to allow graphs to choose their own icon.',
      },
    },
  },
  XAxis: {
    nestedConfigItem: true,
    types: {
      graphs: true,
    },
    childTypes: {
      dataVisLabels: true,
    },
    properties: {
      dataKey: {},
      label: {},
      hide: {},
      xAxisId: {
        types: {
          default: BasicType.Int,
          options: [BasicType.Int, BasicType.String],
        },
        default: 0,
      },
      // width: {},
      // height: {},
      orientation: {
        default: "'bottom'",
        options: ["'bottom'", "'top'"],
      },
      type: {
        default: "'category'",
        options: ["'number'", "'category'"],
      },
      interval: {
        default: "'preserveEnd'",
        options: ["'preserveStart'", "'preserveEnd'", "'preserveStartEnd'", 0],
      },
      allowDecimals: {
        default: true,
        options: [true, false],
        description: 'Allow the ticks of XAxis to be decimals or not.',
      },
      allowDataOverflow: {
        default: false,
        options: [true, false],
        description:
          'When domain of the axis is specified and the type of the axis is "number", if allowDataOverflow is set to be false, the domain will be adjusted when the minimum value of data is smaller than domain[0] or the maximum value of data is greater than domain[1] so that the axis displays all data values. If set to true, graphic elements (line, area, bars) will be clipped to conform to the specified domain.',
      },
      allowDuplicatedCategory: {
        default: true,
        options: [true, false],
        description:
          'Allow the axis has duplicated categories or not when the type of axis is "category".',
      },
      minTickGap: {
        type: BasicType.Float,
        default: 5,
        description: 'The minimum gap between two adjacent labels.',
      },
      axisLine: {
        default: true,
        options: [true, false],
        description:
          'If set false, no axis line will be drawn. If set a object, the option is the configuration of axis line.',
      },
      tickLine: {
        default: true,
        options: [true, false],
        description:
          'If set false, no axis tick lines will be drawn. If set a object, the option is the configuration of tick lines.',
      },
      tickSize: {
        type: BasicType.Float,
        default: 6,
        description: 'The length of tick line.',
      },
      angle: {
        type: BasicType.Float,
        description: 'The angle of the tick labels',
        default: 0,
      },
      scaleToFit: {
        default: false,
        options: [true, false],
        description: 'Scale the text to fit the width or not.',
      },
      mirror: {
        default: false,
        options: [true, false],
        description:
          'If set true, flips ticks around the axis line, displaying the labels inside the chart instead of outside.',
      },
      reversed: {
        default: false,
        options: [true, false],
        description: 'Reverse the ticks or not.',
      },
      scale: {
        default: "'auto'",
        options: graphAxisScale,
        description:
          'If set to "auto", the scale function is decided by the type of chart, and the props type.',
      },
      unit: {
        description:
          'The unit of data displayed in the axis. This option will be used to represent an index unit in a scatter chart.',
      },
      name: {
        description:
          'The name of data displayed in the axis. This option will be used to represent an index in a scatter chart.',
      },
      tickMargin: {
        description: 'The margin between tick line and tick.',
      },
      tick: {},
      textAnchor: {
        default: "'start'",
        options: ["'start'", "'middle'", "'end'"],
      },
    },
  },
  YAxis: {
    nestedConfigItem: true,
    types: {
      graphs: true,
    },
    childTypes: {
      dataVisLabels: true,
    },
    properties: {
      dataKey: {},
      label: {},
      hide: {},
      yAxisId: {
        types: {
          default: BasicType.Int,
          options: [BasicType.Int, BasicType.String],
        },
        default: 0,
      },
      // width: {},
      // height: {},
      orientation: {
        default: "'left'",
        options: ["'left'", "'right'"],
      },
      type: {
        default: "'number'",
        options: ["'number'", "'category'"],
      },
      interval: {
        default: "'preserveEnd'",
        options: ["'preserveStart'", "'preserveEnd'", "'preserveStartEnd'", 0],
      },
      allowDecimals: {
        default: true,
        options: [true, false],
        description: 'Allow the ticks of XAxis to be decimals or not.',
      },
      allowDataOverflow: {
        default: false,
        options: [true, false],
        description:
          'When domain of the axis is specified and the type of the axis is "number", if allowDataOverflow is set to be false, the domain will be adjusted when the minimum value of data is smaller than domain[0] or the maximum value of data is greater than domain[1] so that the axis displays all data values. If set to true, graphic elements (line, area, bars) will be clipped to conform to the specified domain.',
      },
      allowDuplicatedCategory: {
        default: true,
        options: [true, false],
        description:
          'Allow the axis has duplicated categories or not when the type of axis is "category".',
      },
      minTickGap: {
        default: 5,
        description: 'The minimum gap between two adjacent labels.',
      },
      axisLine: {
        default: true,
        options: [true, false],
        description:
          'If set false, no axis line will be drawn. If set a object, the option is the configuration of axis line.',
      },
      tickLine: {
        default: true,
        options: [true, false],
        description:
          'If set false, no axis tick lines will be drawn. If set a object, the option is the configuration of tick lines.',
      },
      tickSize: {
        default: 6,
        description: 'The length of tick line.',
      },
      mirror: {
        default: false,
        options: [true, false],
        description:
          'If set true, flips ticks around the axis line, displaying the labels inside the chart instead of outside.',
      },
      reversed: {
        default: false,
        options: [true, false],
        description: 'Reverse the ticks or not.',
      },
      scale: {
        default: "'auto'",
        options: graphAxisScale,
        description:
          'If set to "auto", the scale function is decided by the type of chart, and the props type.',
      },
      unit: {
        description:
          'The unit of data displayed in the axis. This option will be used to represent an index unit in a scatter chart.',
      },
      name: {
        description:
          'The name of data displayed in the axis. This option will be used to represent an index in a scatter chart.',
      },
      tickMargin: {
        description: 'The margin between tick line and tick.',
      },
      tick: {},
      domain: {
        description:
          'Sets min and max of axis, if numeric An array of two numbers, can be "auto"',
      },
    },
  },
  ReferenceLine: {
    nestedConfigItem: true,
    types: {
      graphs: true,
    },
    childTypes: {},
    properties: {
      y: {
        types: {
          default: BasicType.Float,
          options: [BasicType.Float, BasicType.String],
        },
        description:
          'Only set an x or a y value, never both. Should correlate to a location on the defined axis, usually a number for y and a string for x',
      },
      x: {
        types: {
          default: BasicType.String,
          options: [BasicType.Float, BasicType.String],
        },
      },
      stroke: {
        description: 'color',
      },
      label: {},
      strokeDasharray: {
        description:
          'A string of numbers representing the length of dashes and spaces, ex: "3" will create a dashed line with a 3px stroke, then 3px space, "3 7" will create one with a 7 pixel spaces, and "3 7 3" would alternate the stroke or space being 7 pixels because of the odd number of values',
      },
    },
  },
  ReferenceDot: {
    nestedConfigItem: true,
    types: {
      graphs: true,
    },
    childTypes: {},
    properties: {
      xAxisId: {
        types: {
          default: BasicType.Int,
          options: [BasicType.Int, BasicType.String],
        },
        default: 0,
        description: 'The id of x-axis which is corresponding to the data.',
      },
      yAxisId: {
        types: {
          default: BasicType.Int,
          options: [BasicType.Int, BasicType.String],
        },
        default: 0,
        description: 'The id of y-axis which is corresponding to the data.',
      },
      y: {
        types: {
          default: BasicType.Float,
          options: [BasicType.Float, BasicType.String],
        },
      },
      x: {
        types: {
          default: BasicType.String,
          options: [BasicType.Float, BasicType.String],
        },
      },
      alwaysShow: {
        default: false,
        options: [true, false],
      },
      label: {},
      isFront: {
        default: false,
        options: [true, false],
        description:
          'If set true, the dot will be rendered in front of bars in BarChart, etc.',
      },
    },
  },
  Brush: {
    nestedConfigItem: true,
    types: {
      graphs: true,
    },
    childTypes: {},
    properties: {
      dataKey: {
        description: 'The key of data displayed in Brush.',
      },
      x: {
        type: BasicType.Float,
      },
      y: {
        type: BasicType.Float,
      },
      width: {
        type: BasicType.Float,
      },
      height: {
        type: BasicType.Float,
      },
      travellerWidth: {
        type: BasicType.Float,
      },
      gap: {
        type: BasicType.Float,
      },
      startIndex: {
        type: BasicType.Int,
      },
      endIndex: {
        type: BasicType.Int,
      },
    },
  },
  Tooltip: {
    nestedConfigItem: true,
    types: {
      graphs: true,
    },
    childTypes: {},
    properties: {
      filterNull: {
        default: true,
        options: [true, false],
        description:
          'When an item of the payload has value null or undefined, this item wont be displayed.',
      },
      offset: {
        default: 10,
        description:
          'The offset size between the position of tooltip and the active position.',
      },
    },
  },
  CartesianGrid: {
    nestedConfigItem: true,
    types: {
      graphs: true,
    },
    childTypes: {},
    properties: {
      horizontal: {
        default: true,
        options: [true, false],
        description: 'If horizontal grid lines should be shown',
      },
      vertical: {
        default: true,
        options: [true, false],
        description: 'If vertical grid lines should be shown',
      },
      strokeDasharray: {
        description:
          'A string of numbers representing the length of dashes and spaces, ex: "3" will create a dashed line with a 3px stroke, then 3px space, "3 7" will create one with a 7 pixel spaces, and "3 7 3" would alternate the stroke or space being 7 pixels because of the odd number of values',
      },
    },
  },
  LabelList: {
    nestedConfigItem: true,
    types: {
      labels: true,
    },
    childTypes: {},
    properties: {
      dataKey: {
        description: 'The key of a group of label values in data.',
      },
      position: {
        options: [
          "'top'",
          "'left'",
          "'right'",
          "'bottom'",
          "'inside'",
          "'outside'",
          "'insideLeft'",
          "'insideRight'",
          "'insideTop'",
          "'insideBottom'",
          "'insideTopLeft'",
          "'insideBottomLeft'",
          "'insideTopRight'",
          "'insideBottomRight'",
          "'insideStart'",
          "'insideEnd'",
          "'end'",
          "'center'",
        ],
        description: 'The position of each label relative to it view box',
      },
      offset: {
        type: BasicType.Float,
        description: 'The offset to the specified "position"',
      },
      angle: {},
      clockWise: {
        type: BasicType.String,
        description:
          'The parameter to calculate the view box of label in radial charts.',
      },
    },
  },
  DataVisLabel: {
    nestedConfigItem: true,
    types: {
      dataVisLabels: true,
    },
    childTypes: {},
    properties: {
      value: {
        description: 'The value of the label',
      },
      position: {
        options: [
          "'top'",
          "'left'",
          "'right'",
          "'bottom'",
          "'inside'",
          "'outside'",
          "'insideLeft'",
          "'insideRight'",
          "'insideTop'",
          "'insideBottom'",
          "'insideTopLeft'",
          "'insideBottomLeft'",
          "'insideTopRight'",
          "'insideBottomRight'",
          "'insideStart'",
          "'insideEnd'",
          "'end'",
          "'center'",
        ],
        description: 'The position of each label relative to it view box',
      },
      offset: {
        type: BasicType.Float,
        description: 'The offset to the specified "position"',
      },
      angle: {},
    },
  },
  ColumnGroup: {
    nestedConfigItem: true,
    types: {
      columnGroups: true,
    },
    childTypes: {
      groupHeader: true,
      header: true,
      cell: true,
    },
    properties: {
      columns: {
        pointer: 'must',
      },
      width: {
        default: "'200px'",
      },
      fixed: {
        default: false,
        options: ["'left'", "'right'", false],
        description:
          'Fixes the column group left or right, it will always be visible',
      },
      permissionId: {},
    },
  },
  RowGroup: {
    nestedConfigItem: true,
    types: {
      rowGroups: true,
    },
    childTypes: {},
    properties: {
      rows: {
        pointer: 'must',
      },
      height: {
        default: "'200px'",
      },
      permissionId: {},
    },
  },
  List: {
    types: {
      children: true,
    },
    childTypes: {
      // children: true,
      columns: true,
      header: true,
      overlays: true,
      actions: true,
    },
    properties: {
      columns: {
        required: true,
        description:
          'underscore separated string of data fields to access data from each row, ex: id_name_data',
      },
      columnLabels: {
        required: false,
        description: 'the name of the columns that will be displayed',
      },
      columnWidths: {
        required: false,
        description:
          'underscore separated string of column widths in px or fr units, px are exact number of pixels, fr is fraction of remaining space. If empty, sets all to 1fr, if shorter than the number of columns, it fills the rest with the value of the last one',
      },
      columnDataTypes: {
        required: false,
        description:
          'underscore separated string of data types for each column. Currently only necessary if you need a boolean for a check box',
      },
      columnPermissionIds: {
        required: false,
        description:
          'underscore separated string of permission keys, to add separate permissions per column',
      },
      rows: {
        pointer: 'must',
        required: true,
        description:
          'array of data containing objects with fields that match the columns property',
      },
      rowInstanceName: {
        pointer: 'never',
        description:
          'the name representing the singular of rows, will become an alias for _row',
      },
      rowIdentifier: {
        description:
          'an accessor to a unique field for each row, necessary for collapsing rows',
      },
      filterBy: {
        description:
          'underscore separated string of values that must match columns, it adds a textbox to the top of the widget to filter rows by the whats entered',
      },
      sortable: {
        default: false,
        options: [true, false],
        description:
          'turns on user controlled per column sorting, requires a column header to be the click handler',
      },
      sortBy: {
        description:
          'if this is selected while sortable is false, the table will always be sorted by this field',
      },
      sortDirection: {
        default: "'ASC'",
        options: ["'ASC'", "'DESC'"],
        description:
          'if this is selected while sortable is false and sortBy is set, the table will always be sorted in this direction by the sortBy field',
      },
      rowHeight: {
        default: 30,
      },
      headerHeight: {
        default: 50,
        description:
          'if you enable filtering, give at least 50px in the header to allow room for the filter controls',
      },
      headerTreeHeight: {
        default: 0,
        description:
          'sets the height of the optional header tree that is rendered above the header',
      },
      adjustableRowHeight: {
        default: false,
        options: [true, false],
        description:
          'if this is set, a child can add row height. The amount added will be this property number times a childs setRowHeight property',
      },
      fullHeight: {
        default: false,
        options: [true, false],
        description:
          'If set to true, the table will set its height to the combined height of all its rows. This should be used within a container with overflow set to scroll, and will have the side effect of the header scrolling up along with the rows.',
      },
      tableWidth: {
        description:
          'By default, the table will set its width to fit the container its in. If this is set it will override that width.',
      },
      collapsibleRowHeight: {},
      minimumRowHeight: {
        default: 30,
        description:
          'if row height is set to be adjustable, this a minimum row height that will be used if the set height is less',
      },
      disableVirtualization: {
        default: false,
        options: [true, false],
      },
      nestedTable: {
        default: false,
        options: [true, false],
        description:
          'Takes over responsibility for setting parent row height, disabling any setRowHeight context. Only set to true if this is inside a parent list/table and that parent table has adjustableRowHeight set to true.',
      },
      evenRowColor: { additionalEditor: AdditionalEditor.Color },
      oddRowColor: { additionalEditor: AdditionalEditor.Color },
      headerColor: { additionalEditor: AdditionalEditor.Color },
      cellOverflow: {
        default: "'hidden'",
        options: overflow.options,
        description:
          'Sets the overflow for cells of the table, default is hidden in list',
      },
      cellTextWrap: {
        default: false,
        options: [true, false],
      },
      downloadCSV: {
        default: false,
        options: [true, false, '{{appPermission sysadminapp}}'],
      },
      report: {
        default: false,
        options: [false, "'excel'"],
      },
      filename: {},
    },
    childProperties: {
      columnCells: {
        required: true,
        description:
          'Underscore separated string representing the columns whose cell this child will be rendered as, will have all row data in context as row, and the cell field as cell',
      },
      headerCells: {
        required: true,
        description:
          'Underscore separated string representing the columns whose header this child will be rendered as, will have the column name content as cell',
      },
      headerTree: {
        default: false,
        options: [true, false],
        description:
          'renders an additional widget tree above the header, or instead of the header by setting header height to 0',
      },
    },
    events: {
      load: [],
    },
  },
  Column: {
    nestedConfigItem: true,
    types: {
      columns: true,
    },
    childTypes: {
      header: true,
      cell: true,
    },
    properties: {
      label: {},
      field: {},
      width: {
        default: "'1fr'",
      },
      filter: {
        default: true,
        options: [true, false],
      },
      filterAccessor: {},
      sort: {
        default: true,
        options: [true, false],
      },
      sortAccessor: {},
      type: {},
      permissionId: {},
      render: {
        default: true,
      },
    },
  },
  Upload: {
    types: {
      children: true,
    },
    childTypes: {},
    properties: {
      filePath: {
        required: false,
        description: 'optional path to put file into',
      },
      bucket: {
        required: true,
        description: 'bucket to put file into',
      },
    },
    events: {
      load: [],
    },
  },
  Page: {
    types: {
      widgets: true,
    },
    childTypes: {
      children: true,
      subHeader: true,
    },
    properties: {
      pageTitle: {
        description: 'Replaces the title in the browser tab',
      },
      pageGroup: {},
      form: {
        // default: false,
        // options: [true, false],
      },
    },
    events: {
      load: [],
    },
  },
  AGGrid: agGridTypeInfo,
  AGChart: agChartTypeInfo,
  MultiSelect: multiSelectTypeInfo,
  KendoExport: kendoExportTypeInfo,
  KendoToolbar: kendoToolbarTypeInfo,
  KendoExportButton: kendoExportButtonTypeInfo,
  KendoGrid: kendoGridTypeInfo,
  KendoColumn: kendoColumnTypeInfo,
  KendoCell: kendoCellTypeInfo,
  KendoAggregate: kendoAggregateTypeInfo,
  RichText: richTextTypeInfo,
};

let errors;
Object.keys(configurationTypesDetails).forEach((k) => {
  if (!configurationTypesDetails[k]) {
    console.log(`ERROR - widget type info for ${k} not found`);
    errors = true;
  }
});

if (errors) {
  throw new Error(
    'Missing widget type info in configurationTypesDetails - likely caused by a circular reference in the source',
  );
}

const widgetPropertyConfiguration: IPropertyConfiguration = {
  type: 'widget',
  allInfos: configurationTypesDetails,
  universalPropertyInfos: universalProperties,
  propertiesPath: 'properties',
  namePath: 'name',
  typePath: 'type',
  getPropertyInfosPath(type) {
    return `${type}.properties`;
  },
  getInheritedPropertyInfosPath(parentType) {
    return `${parentType}.childProperties`;
  },
};

function canBeChild(
  parentTypeName: ConfigurationTypeName | undefined,
  category,
  childTypeName: ConfigurationTypeName,
) {
  const parentType: IConfigurationType =
    configurationTypesDetails[parentTypeName];
  const childType: IConfigurationType =
    configurationTypesDetails[childTypeName];

  if (parentTypeName) {
    const parentChildCategory = parentType.childTypes[category];
    if (!parentChildCategory) {
      return false;
    }
    if (Array.isArray(parentChildCategory)) {
      return parentChildCategory.includes(childTypeName);
    }
  }

  const childCategories = childType.types;
  return !!(
    childCategories[category] ||
    childCategories[configurationTypeCategories[category].aliasFor]
  );
}

const widgetTreeTransforms = {
  List: listTransform,
  KendoGrid: kendoGridTransform,
};
const widgetSubscribeMapBuilders = {
  KendoGrid: kendoGridSubscribeMapBuilder,
};

export {
  canBeChild,
  configurationTypesDetails,
  widgetPropertyConfiguration,
  widgetSubscribeMapBuilders,
  widgetTreeTransforms,
};
