import { ClientManager } from './clientManager';
import { ExcelWorkbook } from './excelWorkbook';
import { TypeDefinition } from './typeDefinition';

export class ExcelSupport {
  public clientManager: ClientManager;

  public initialize(clientManager: ClientManager) {
    this.clientManager = clientManager;
  }

  public async createWorkbook(params: {
    typeDef: TypeDefinition;
    sheetName: string;
  }): Promise<ExcelWorkbook> {
    return new ExcelWorkbook({ excelSupport: this, ...params });
  }
}
