import { Icon, MenuItem } from '@mui/material';
import { useRouteMatch } from 'react-router';

import { createUrlProps, withActions } from '../dependencies';

import { MenuItemProps, menuItemTypeInfo } from './MenuItemTypeInfo';

export { menuItemTypeInfo };

const MenuItemWidget: React.FC<React.PropsWithChildren<MenuItemProps>> = (
  props,
) => {
  const handleClick = (event) => {
    const {
      updateFromWidget,
      elementAttributes,
      passThroughProps,
      closePopupOnClick,
    } = props;

    updateFromWidget({ widgetDoesNotProvideData: true });

    if (closePopupOnClick && passThroughProps.closePopup) {
      passThroughProps.closePopup();
    }

    // this is so that a button can run its own click actions and its parent's
    elementAttributes?.onClick && elementAttributes.onClick(event);
  };

  const {
    label,
    icon,
    disabled,
    url,
    absolute,
    external,
    relativeUrlDepth,
    newTab,
  } = props;

  const match = useRouteMatch();

  const urlProps = createUrlProps({
    url,
    relativeUrlDepth,
    absolute,
    external,
    newTab,
    match,
  });

  const content = icon ? <Icon>{icon}</Icon> : label;

  const menuItem = (
    <MenuItem
      {...props.elementAttributes}
      onClick={handleClick}
      disabled={disabled}
      {...urlProps}
    >
      {content}
    </MenuItem>
  );

  return <div>{menuItem}</div>;
};

export default withActions(MenuItemWidget);
