import { IDatabaseProviderClient } from './databaseProviderClient';

export class DynamoDBProviderClient implements IDatabaseProviderClient {
  public getIamPolicy(): string {
    return 'dynamodb:*';
  }

  public getGraphQLBatchSizeLimit(): number {
    return 25;
  }
}
