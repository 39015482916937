import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import React, { Component } from 'react';

export const sxModal = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  margin: '10%',
  outline: 'none',
};
export const sxModalPaper = {
  padding: 1,
};

export const sxModalButtons = {
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
};

export const sxModalTypography = {
  whiteSpace: 'pre-wrap',
  textAlign: 'center',
};

// takes onAccept, and message props. Hijacks the onClick of the child to open the prompt instead.

interface IProps {
  onAccept;
  message;
  // FIXME - had to add this in the mui 5 conversion
  children?;
}

class Prompt extends Component<IProps> {
  public declare props;
  public declare state;
  constructor(props: IProps) {
    super(props);
    this.state = {
      open: false,
    };
    this.handleClosePrompt = this.handleClosePrompt.bind(this);
    this.handleOpenPrompt = this.handleOpenPrompt.bind(this);
    this.handleAccept = this.handleAccept.bind(this);
    this.handleClosePrompt = this.handleClosePrompt.bind(this);
  }

  handleOpenPrompt(event) {
    const { onClick, identifier } = this.props;
    if (onClick) {
      onClick(event, identifier);
    }
    this.setState({ open: true });
  }

  handleClosePrompt() {
    this.setState({ open: false });
  }

  handleAccept() {
    const { onAccept, identifier } = this.props;
    if (onAccept) {
      onAccept(identifier);
    }
    this.handleClosePrompt();
  }

  render() {
    const { open } = this.state;
    const { message, children } = this.props;

    return (
      <React.Fragment>
        <Modal open={open} sx={sxModal} onClose={this.handleClosePrompt}>
          <Paper sx={sxModalPaper}>
            <Typography variant="subtitle1" sx={sxModalTypography}>
              {message}
            </Typography>
            <Box sx={sxModalButtons}>
              <Button onClick={this.handleAccept}>Okay</Button>
              <Button onClick={this.handleClosePrompt}>Cancel</Button>
            </Box>
          </Paper>
        </Modal>
        {React.cloneElement(children, { onClick: this.handleOpenPrompt })}
      </React.Fragment>
    );
  }
}

export default Prompt;
