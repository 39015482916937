// FIXME - appears to be a bug in import/order

import {
  Table as MUITable,
  Paper,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

import withStyles from '@mui/styles/withStyles';
import _ from 'lodash';
import {
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  Column,
  Table,
} from 'react-virtualized';
import { compose } from 'redux';
import dayjs from 'universal/common/dayjsSupport';
import { INCARNATION } from 'universal/metadataSupportConstants';

import CustomButton from '../atoms/CustomButton';
import { withActions } from '../widgetEngine/ActionEnabler';
import Container from '../widgetEngine/Container';

const styles = () => ({});

//This is a hack
//ToDo: remove these and make this general
const suppressedFields = ['updatedTimestamp', INCARNATION];

interface IProps {
  log: any[];
  containerWidth;
}

class LogViewer extends Container<IProps> {
  public declare props;
  public declare state;
  public cache;
  public lastRenderedWidth;
  public list;
  public expanded;

  constructor(props: IProps) {
    super(props);
    this.renderCollapseButton = this.renderCollapseButton.bind(this);
    this.rowGetter = this.rowGetter.bind(this);
    this.handleExpandRow = this.handleExpandRow.bind(this);
    this.expandAll = this.expandAll.bind(this);
    this.collapseAll = this.collapseAll.bind(this);
    this.renderCell = this.renderCell.bind(this);
    this.state = {
      expanded: {},
    };
    this.cache = new CellMeasurerCache({
      defaultHeight: 24,
      fixedWidth: true,
      minHeight: 24,
    });
    this.lastRenderedWidth = props.containerWidth;
  }

  expandAll() {
    const expanded = {};
    this.getAllRows().forEach((row, index) => (expanded[index] = true));
    this.setState({ expanded });
    this.cache.clearAll();
    this.list.recomputeRowHeights();
  }

  collapseAll() {
    const expanded = {};
    this.setState({ expanded });
    this.cache.clearAll();
    this.list.recomputeRowHeights();
  }

  handleExpandRow(index) {
    this.setState((state: any) => {
      return {
        expanded: Object.assign({}, state.expanded, {
          [index]: !state.expanded[index],
        }),
      };
    });
    this.cache.clear(index, 1);
    this.list.recomputeRowHeights(index);
  }

  renderCollapseButton(cell) {
    const { rowIndex } = cell;
    const { expanded } = this.state;
    const icon = expanded[rowIndex] ? 'expand_more' : 'chevron_right';
    return (
      <div
        style={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
        }}
      >
        <CustomButton
          onClick={this.handleExpandRow}
          identifier={rowIndex}
          icon={icon}
          style={{ padding: 0, height: '24px', width: '24px' }}
        />
      </div>
    );
  }

  isExpanded(index) {
    return !!this.state.expanded[index];
  }

  getAllRows() {
    const { log } = this.props;
    if (!Array.isArray(log)) {
      throw new Error('The log must be an array');
    }

    const logEntries = log;
    return _.sortBy(logEntries, ['timestamp']).reverse();
  }

  rowGetter({ index }) {
    return this.getAllRows()[index];
  }

  renderCell(cell) {
    const { rowIndex, parent, dataKey } = cell;
    const cellComponent = this.isExpanded(rowIndex)
      ? this.renderDetailsCell(cell)
      : this.renderSummaryCell(cell);

    return (
      <CellMeasurer
        cache={this.cache}
        columnIndex={1}
        rowIndex={rowIndex}
        parent={parent}
        key={dataKey}
        width={800}
      >
        {cellComponent}
      </CellMeasurer>
    );
  }

  renderSummaryCell(cell) {
    const {
      rowData: { timestamp, user, activity },
    } = cell;
    const date = new Date(timestamp);
    const dateString =
      date.toLocaleTimeString() + ' ' + dayjs(date).format('MM/DD/YY');

    return (
      <span
        style={{
          height: 24,
          display: 'flex',
          flexDirection: 'row',
          // justifyContent: 'space-evenly',
          alignItems: 'stretch',
        }}
      >
        <span style={{ flex: 1 }}>
          <Typography>{dateString}</Typography>
        </span>
        <span style={{ flex: 1 }}>
          <Typography>{user}</Typography>
        </span>
        <span style={{ flex: 1 }}>
          <Typography>{activity}</Typography>
        </span>
      </span>
    );
  }

  renderDetailsCell(cell) {
    return (
      <div>
        {this.renderSummaryCell(cell)}
        {this.renderDetails(cell)}
      </div>
    );
  }

  renderDetails(cell) {
    const {
      rowData: { before, mutation },
    } = cell;

    const parsedBefore = before;
    const parsedMutation = mutation;
    const beforeKeys = (parsedBefore && Object.keys(parsedBefore)) || [];
    const mutationKeys = (parsedMutation && Object.keys(parsedMutation)) || [];
    const rows = _.union(beforeKeys, mutationKeys).filter(
      (key) =>
        !parsedBefore ||
        !parsedMutation ||
        (!suppressedFields.includes(key) &&
          !_.isEqual(parsedBefore[key], parsedMutation[key]) &&
          !(
            //this is hacky extra logic because some boolean fields are undefined instead of false sometimes
            (
              [undefined, false].includes(parsedBefore[key]) &&
              [undefined, false].includes(parsedMutation[key])
            )
          )),
    );

    const tableRows = rows.map((row) => (
      <TableRow style={{ height: '24px' }}>
        <TableCell>{row}</TableCell>
        <TableCell>
          {parsedBefore &&
            parsedBefore[row] !== undefined &&
            parsedBefore[row].toString()}
        </TableCell>
        <TableCell>
          {parsedMutation &&
            parsedMutation[row] !== undefined &&
            parsedMutation[row].toString()}
        </TableCell>
      </TableRow>
    ));

    return (
      <div style={{ paddingBottom: '10px' }}>
        <MUITable style={{ width: '800px' }}>
          <colgroup>
            <col width="30%" />
            <col width="35%" />
            <col width="35%" />
          </colgroup>
          <TableHead>
            <TableRow
              style={{
                height: '24px',
              }}
            >
              <TableCell>Field</TableCell>
              <TableCell>Before</TableCell>
              <TableCell>After</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{tableRows}</TableBody>
        </MUITable>
      </div>
    );
  }

  renderHeader() {
    const { headerHeight } = this.props;
    return (
      <div style={{ height: headerHeight + 'px' }}>
        {this.generateChildren()}
      </div>
    );
  }

  render() {
    const { height, headerHeight } = this.props;

    if (this.lastRenderedWidth !== this.props.containerWidth) {
      this.lastRenderedWidth = this.props.containerWidth;
      this.cache.clearAll();
    }

    return (
      <Paper style={{ height: height || '100%', width: 832 }}>
        {this.renderHeader()}
        <span
          style={{
            height: '36px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
          }}
        >
          <CustomButton onClick={this.expandAll}>Expand All</CustomButton>
          <CustomButton onClick={this.collapseAll}>Collapse All</CustomButton>
        </span>
        <AutoSizer>
          {({ width, height: heightLocal }) => (
            <Table
              ref={(list) => {
                this.list = list;
              }}
              // dont know why this breaks it, or why it does not seem necessary, but its in the docs
              // deferredMeasurementCache={this.cache}
              width={width}
              height={heightLocal - 40 - headerHeight}
              rowGetter={this.rowGetter}
              rowCount={this.getAllRows().length}
              rowHeight={this.cache.rowHeight}
              overscanRowCount={2}
            >
              <Column
                style={{ height: '100%', padding: 0 }}
                cellRenderer={this.renderCollapseButton}
                dataKey="collapse"
                width={32}
              />
              <Column
                style={{ height: '100%', padding: 0 }}
                cellRenderer={this.renderCell}
                dataKey="data"
                width={800}
              />
            </Table>
          )}
        </AutoSizer>
      </Paper>
    );
  }
}

export default compose(
  withActions,
  withStyles(styles, { withTheme: true }),
)(LogViewer);
