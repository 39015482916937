import {
  FormControl,
  Icon,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
  Typography,
} from '@mui/material';
import _ from 'lodash';
import { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { Column, Table } from 'react-virtualized';
import 'react-virtualized/styles.css';
import { compose } from 'redux';
import { PipelineManager } from 'universal/pipeline/pipelineManager';

import CopyButton from '../../components/atoms/CopyButton';
import Loading from '../../components/atoms/Loading';
import { persistUIState } from '../../store/persistUIState';
import { IClientContext, withClient } from '../../wrappers/ClientContext';
import TestSafeAutosizer from '../../wrappers/TestSafeAutosizer';

import EntityCellLink from './EntityCellLink';

class DataViewer extends Component<RouteComponentProps & IClientContext> {
  public declare props;
  public declare state;
  constructor(props) {
    super(props);

    this.state = {
      result: null,
      attributeNames: [],
      loading: true,
    };

    this.cellRenderer = this.cellRenderer.bind(this);
    this.buildColumns = this.buildColumns.bind(this);
    this.buildQuery = this.buildQuery.bind(this);
    this.runQuery = this.runQuery.bind(this);
    this.handleSelectColumns = this.handleSelectColumns.bind(this);
  }

  componentDidMount() {
    const {
      clientManager: { metadataSupport },
      match: {
        params: { entityName },
      },
    } = this.props;

    const typeDef =
      metadataSupport.getTypeDefFromUnqualifiedEntityName(entityName);

    const attributeNames = typeDef
      .getAttributes()
      .map((attribute) => attribute.name);
    const normalizedAttributes = typeDef
      .getAttributes()
      .reduce((acc, attribute) => {
        return Object.assign(acc, { [attribute.name]: attribute });
      }, {});

    const columnOptions = attributeNames.filter(
      (attribute) => !normalizedAttributes[attribute].itemInfo.collectionType,
    );

    this.setState({
      attributeNames,
      columnOptions,
      normalizedAttributes,
    });

    void this.runQuery();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.persistentColumns.length !== this.props.persistentColumns.length
    ) {
      void this.runQuery();
    }
  }

  static getDerivedStateFromProps(nextProps, nextState) {
    const { persistentColumns } = nextProps;
    const { attributeNames } = nextState;
    const sortedColumns = _.intersection(attributeNames, persistentColumns);
    return { sortedColumns };
  }

  buildQuery() {
    const {
      match: {
        params: { entityName },
      },
      persistentColumns,
    } = this.props;
    const pipelineManager: PipelineManager =
      this.props.clientmanager.pipelineManager;
    return pipelineManager.buildListQuery(entityName, persistentColumns);
  }

  async runQuery() {
    const pipelineManager: PipelineManager =
      this.props.clientmanager.pipelineManager;
    const query = this.buildQuery();
    const result = await pipelineManager.executeGraphqlWithParams({ query });
    this.setState({ result, loading: false });
  }

  buildColumns() {
    const { sortedColumns } = this.state;

    return (
      sortedColumns &&
      sortedColumns.map((column) => {
        return (
          <Column
            key={column}
            label={column}
            dataKey={column}
            width={300}
            cellRenderer={this.cellRenderer}
            headerRenderer={this.headerRenderer}
            columnData={{ column }}
          />
        );
      })
    );
  }

  cellRenderer(cell) {
    const {
      match: {
        params: { entityName },
      },
    } = this.props;
    const { normalizedAttributes } = this.state;
    const {
      rowData,
      columnData: { column },
    } = cell;

    return (
      <EntityCellLink
        column={column}
        entityName={entityName}
        entityId={rowData.id}
        cell={cell}
        normalizedAttributes={normalizedAttributes}
      />
    );
  }

  headerRenderer(header) {
    const { label } = header;
    return <Typography>{label}</Typography>;
  }

  handleSelectColumns(event) {
    const {
      match: {
        params: { entityName },
      },
    } = this.props;
    const { value } = event.target;
    const newColumns = _.uniq(value[0] === 'id' ? value : ['id'].concat(value));
    void persistUIState({ [`entityListColumns-${entityName}`]: newColumns });
  }

  render() {
    const {
      match: {
        params: { entityName },
      },
      persistentColumns,
    } = this.props;
    const { result, loading, columnOptions } = this.state;

    const columnOptionItems =
      columnOptions &&
      columnOptions.map((attributeName) => {
        return (
          <MenuItem key={attributeName} value={attributeName}>
            {attributeName}
          </MenuItem>
        );
      });

    const resultList = loading ? (
      <div
        style={{
          display: 'flex',
          flex: '1',
          justifyContent: 'center',
        }}
      >
        <Loading />
      </div>
    ) : (
      result &&
      Array.isArray(result) && (
        <div>
          <Typography variant="h6" style={{ padding: '10px' }}>
            Number of records: {result.length}
          </Typography>
          <TestSafeAutosizer>
            {({ width, height }) => {
              return (
                <Table
                  width={Math.max(persistentColumns.length * 300, width)}
                  height={height}
                  headerHeight={50}
                  rowHeight={30}
                  rowCount={result.length}
                  rowGetter={({ index }) => result[index]}
                  gridStyle={{ outline: 'none' }}
                >
                  {this.buildColumns()}
                </Table>
              );
            }}
          </TestSafeAutosizer>
        </div>
      )
    );

    return (
      <div
        style={{
          display: 'grid',
          gridTemplateRows: '50px 1fr',
          height: '100%',
          width: '100%',
        }}
      >
        <div
          style={{
            gridRow: '1/2',
            display: 'flex',
            flex: 1,
            flexDirection: 'row',
          }}
        >
          <Typography variant="h6" style={{ padding: '10px' }}>
            {entityName}
          </Typography>
          <FormControl variant="standard">
            <InputLabel shrink htmlFor="select-multiple-native">
              Columns
            </InputLabel>
            <Select
              multiple
              variant="standard"
              value={persistentColumns}
              onChange={this.handleSelectColumns}
            >
              {columnOptionItems}
            </Select>
          </FormControl>
          <CopyButton getText={this.buildQuery}>
            <Tooltip title="copy query to clipboard" placement="top">
              <IconButton size="large">
                <Icon>file_copy</Icon>
              </IconButton>
            </Tooltip>
          </CopyButton>
        </div>
        <div
          style={{
            display: 'flex',
            flexGrow: 1,
            gridRow: '2/3',
            overflow: 'scroll',
          }}
        >
          <div
            style={{
              width: `${persistentColumns.length * 300}px`,
              display: 'flex',
              flex: '1',
            }}
          >
            {resultList}
          </div>
        </div>
      </div>
    );
  }
}

export default compose(withRouter, withClient)(DataViewer);
