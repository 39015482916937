import * as ExcelJs from 'exceljs/dist/exceljs.min.js';

export async function buildExcelExport({ report }) {
  const workbook = new ExcelJs.Workbook();
  workbook.created = new Date();
  const sheet = workbook.addWorksheet('export', {
    pageSetup: { paperSize: 9, orientation: 'landscape', showGridLines: true },
  });

  const excelColumns = report.map((column) => {
    return {
      header: column[0],
      width: 32,
    };
  });

  sheet.columns = excelColumns;

  report.forEach((column, columnIndex) => {
    // sheet.getColumn(index + 1).values = column;
    column.forEach((cellData, rowIndex) => {
      const cellName =
        excelColumnNumberToLetters(columnIndex + 1) + (rowIndex + 1);
      const cell = sheet.getCell(cellName);
      Object.assign(cell, cellData);
    });
  });

  const excelExport = await workbook.xlsx.writeBuffer('report');
  const blob = new Blob([excelExport], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  });
  return blob;
}

//converts to and from excel column syntax, shamelessly stolen from stack overflow
export function excelColumnLettersToNumber(letters) {
  return letters.split('').reduce((r, a) => r * 26 + parseInt(a, 36) - 9, 0);
}

export function excelColumnNumberToLetters(num) {
  let ret = '';
  for (let a = 1, b = 26; (num -= a) >= 0; a = b, b *= 26) {
    ret = String.fromCharCode((num % b) / a + 65) + ret;
  }
  return ret;
}
