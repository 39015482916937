import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import IndeterminateCheckBoxOutlinedIcon from '@mui/icons-material/IndeterminateCheckBoxOutlined';
import { TreeView } from '@mui/lab';
import { Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { Component } from 'react';
import { withRouter } from 'react-router';
import { compose } from 'redux';

import { withClient } from '../../wrappers/ClientContext';

import EntityTree from './EntityTree';

const styles = {};

class EntityViewer extends Component {
  public declare props;
  componentDidMount() {
    const {
      clientManager: { schemaManager },
    } = this.props;
    void schemaManager.ensureInitializedSchemaInfo();
  }

  render() {
    const {
      clientManager,
      match: {
        params: { entityName, id: entityId },
      },
    } = this.props;

    return (
      <div
        style={{
          display: 'grid',
          gridTemplateRows: '50px 1fr',
          gridTemplateColumns: '1fr',
          height: '100%',
        }}
      >
        <div
          style={{
            gridRow: '1/2',
          }}
        >
          <Typography
            variant="h6"
            style={{ padding: '10px' }}
          >{`${entityName} - ${entityId}`}</Typography>
        </div>
        <div
          style={{
            gridRow: '2/3',
            gridColumn: '1/2',
            overflow: 'auto',
          }}
        >
          <TreeView
            defaultExpandIcon={<AddBoxOutlinedIcon />}
            defaultCollapseIcon={<IndeterminateCheckBoxOutlinedIcon />}
          >
            <EntityTree
              entityName={entityName}
              entityId={entityId}
              clientManager={clientManager}
            />
          </TreeView>
        </div>
      </div>
    );
  }
}

export default compose(
  withRouter,
  withClient,
  withStyles(styles, { withTheme: true }),
)(EntityViewer);
