import { Icon } from '@mui/material';
import { Parser as HtmlToReactParser } from 'html-to-react';
import { ClientManager } from 'universal/clientManager';
import { S3_IMAGE } from 'universal/common/s3Support';
import { getConfigItemsForType } from 'universal/loadStore/loadstore';
import { getLogger, Loggers } from 'universal/loggerSupport';
import { MetadataSupport } from 'universal/metadataSupport';
import {
  APP_DEF_ICON,
  APP_DEF_WIDGET_TREE,
  CONFIG_SEPARATOR,
} from 'universal/metadataSupportConstants';
import { StackInfoKeys } from 'universal/stackInfo';

import CustomIcon from '../components/atoms/CustomIcon';
import { IElementAttributes, IWidgetTree } from '../components/widgets/types';

const logger = getLogger({ name: Loggers.CLIENT });

export function externalUrl(url) {
  return url && (url.includes('://') ? url : `https://${url}`);
}

export function escapeUrlPath(url) {
  return url && url.replace(':', '%3A');
}

export function readWidgetTrees(clientManager: ClientManager): IWidgetTree[] {
  const appDefs = clientManager.stackInfo.getObject(StackInfoKeys.APP_DEFS);
  return getConfigItemsForType(appDefs, APP_DEF_WIDGET_TREE) as IWidgetTree[];
}

export function getUrlFromImagePath(
  imagePath: string,
  configName: string,
  clientManager: ClientManager,
) {
  const unqualifiedImagePath = MetadataSupport.getUnqualifiedName(imagePath);
  const imageConfigName =
    MetadataSupport.getConfigName(imagePath, true) || configName;

  const imageUrl = clientManager.s3Support.getS3DataUrl({
    path: `${S3_IMAGE}/${unqualifiedImagePath}`,
    configName: imageConfigName,
  });

  return imageUrl;
}

const iconCache = {};

export async function getIconFromPath(
  iconPath: string,
  configName: string,
  clientManager: ClientManager,
) {
  const { appDefConfigManager } = clientManager;
  const cacheKey = `${configName}${iconPath}`;

  if (iconCache[cacheKey]) {
    return iconCache[cacheKey];
  }

  const result = await appDefConfigManager.getAppDefObject({
    name: iconPath,
    appDefName: APP_DEF_ICON,
    configName,
  });

  if (!result) {
    logger.error(`Icon ${configName}${CONFIG_SEPARATOR}${iconPath} not found`);
    return undefined;
  }

  const parser = new HtmlToReactParser();
  const svg = parser.parse(result.data);

  iconCache[cacheKey] = svg;
  return svg;
}
/** See ICON_DESCRIPTION in widgets.ts for icon syntax */

export function getIcon(
  iconPath: string,
  configName: string,
  elementAttributes?: IElementAttributes,
) {
  const pathComponents = iconPath.split(CONFIG_SEPARATOR);
  if (iconPath.startsWith(`_config${CONFIG_SEPARATOR}`)) {
    const effectiveConfigName =
      pathComponents.length > 2 ? pathComponents[1] : configName;
    return () => (
      <CustomIcon
        iconPath={pathComponents[pathComponents.length - 1]}
        configName={effectiveConfigName}
        elementAttributes={elementAttributes}
      />
    );
  }
  if (pathComponents.length > 1) {
    throw new Error(
      `Invalid 'icon' property: ${iconPath} - reference to a Material icon can only be a single word`,
    );
  }
  return (props) => {
    return <Icon {...props}>{pathComponents[0]}</Icon>;
  };
}
