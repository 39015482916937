import { Checkbox, FormControlLabel, Icon, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import _ from 'lodash';
import memoize from 'memoize-one';
import { Component, Fragment } from 'react';
import { Column, SortDirection, Table } from 'react-virtualized';
import 'react-virtualized/styles.css';
import { compose } from 'redux';
import { AccessType } from 'universal/permissionManager';
import { BasicType } from 'universal/types';

import { hasAlias, resolvePath } from '../../../store/context';
import { updateContextBatch } from '../../../store/data';
import { store } from '../../../store/store';
import { withUiState } from '../../../store/withUiState';
import { compileProperty, widgetCan } from '../../../util/widgetUtilities';
import TestSafeAutosizer from '../../../wrappers/TestSafeAutosizer';
import CustomButton from '../../atoms/CustomButton';
import CustomTextField from '../../atoms/CustomTextField';
import { withActions } from '../../widgetEngine/ActionEnabler';
import Widget from '../../widgetEngine/Widget';

import Cell from './Cell';
import ListMenu from './ListMenu';

const styles = () => ({}) as const;

class List extends Component {
  public declare props;
  public declare state;
  public list;
  constructor(props) {
    super(props);
    this.state = {
      filters: {},
      rowHeights: {},
      collapsedRows: {},
      hoverRow: null,
      // filteredRows: this.getAllRows(props),
      rowMap: null,
      tableHeight: null,
      menuOpen: false,
      loadingRows: false,
      formPath: hasAlias('form', props.aliases)
        ? resolvePath(['form'], props.aliases).path
        : null,
    };
    this.handleUpdateFilter = this.handleUpdateFilter.bind(this);
    this.handleUpdateBooleanFilter = this.handleUpdateBooleanFilter.bind(this);
    this.getRowHeight = this.getRowHeight.bind(this);
    this.setRowHeight = this.setRowHeight.bind(this);
    this.getRowStyle = this.getRowStyle.bind(this);
    this.cellRenderer = this.cellRenderer.bind(this);
    this.headerRenderer = this.headerRenderer.bind(this);
    this.headerRowRenderer = this.headerRowRenderer.bind(this);
    this.collapseCellRenderer = this.collapseCellRenderer.bind(this);
    this.toggleCollapseRow = this.toggleCollapseRow.bind(this);
    this.handleUpdateSort = this.handleUpdateSort.bind(this);
    this.getCellData = this.getCellData.bind(this);
    // this.handleDownloadCSV = this.handleDownloadCSV.bind(this);
    this.handleRequestReport = this.handleRequestReport.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
  }

  componentDidMount() {
    const { setRowHeight, rowIndex } = this.props.passThroughProps;
    const { nestedTable, adjustableRowHeight } = this.props;

    const tableHeight = this.getTableHeight({});
    this.setState((previousState) => {
      return {
        ...previousState,
        tableHeight,
      };
    });

    if (!adjustableRowHeight && nestedTable && setRowHeight) {
      setRowHeight(rowIndex, tableHeight);
    }

    this.initializeFilters();
  }

  componentDidUpdate(prevProps, prevState) {
    const { setRowHeight, rowIndex } = this.props.passThroughProps;
    const { rowHeights } = this.state;
    const { nestedTable, adjustableRowHeight } = this.props;
    const tableHeight = this.getTableHeight({});

    if (tableHeight !== prevState.tableHeight) {
      this.setState({ tableHeight });
      if (nestedTable && setRowHeight) {
        if (
          !adjustableRowHeight ||
          Object.values(rowHeights).length >= this.getAllRows().length
        ) {
          setRowHeight(rowIndex, tableHeight);
        }
      }
    }
  }

  initializeFilters() {
    const { persistValue, columns } = this.props;

    if (persistValue) {
      const savedFilterState = persistValue;
      const columnFields = columns.split('_');
      Object.keys(savedFilterState).forEach((column) => {
        // this is because some versions of the same lists will have different columns
        if (columnFields.includes(column)) {
          this.handleUpdateFilter(savedFilterState[column], column, true);
        }
      });
    }
  }

  toggleMenu() {
    this.setState((prevState: any) => ({
      menuOpen: !prevState.menuOpen,
    }));
    // this.setState({ loadingRows: true }, async () => {
    //   this.forceUpdate();
    //   this.setState({ menuOpen: !this.state.menuOpen }, () =>
    //     this.setState({ loadingRows: false }),
    //   );
    // });
  }

  handleUpdateFilter(filter, column, initializing?) {
    const { persistKey, updateUiState } = this.props;
    let validRegEx = true;

    try {
      new RegExp(filter);
    } catch (error) {
      console.log('invalid regex in filter input');
      validRegEx = false;
    }

    if (validRegEx) {
      this.setState((prevState: any) => {
        const newFilters = Object.assign({}, prevState.filters, {
          [column]: filter,
        });

        if (persistKey && !initializing) {
          updateUiState(newFilters);
        }

        return {
          filters: newFilters,
        };
      });
    }
  }

  handleUpdateBooleanFilter(event, checked) {
    const column = event.target.id;
    const currentValue = this.state.filters[column];

    const newValue =
      checked === true && currentValue === false ? undefined : checked;

    const newFilters = Object.assign({}, this.state.filters, {
      [column]: newValue,
    });

    this.setState({
      filters: newFilters,
    });
  }

  handleUpdateSort({ sortBy, sortDirection }) {
    this.setState({ sortBy, sortDirection });
  }

  getAllRows(initialProps?) {
    const props = initialProps || this.props;
    return props.rows || [];
  }

  getFilteredSortedRows(filters = this.state.filters) {
    const allRows = this.getAllRows();
    // let filteredRows = allRows;
    const rowMap = allRows.map((row, index) => index);

    const filteredRowMap = rowMap.filter((rowIndex) => {
      return Object.keys(filters).reduce((acc, filterField) => {
        if (acc === false) {
          return false;
        }
        if (![undefined, ''].includes(filters[filterField])) {
          const rowFilterValue = this.getCellData({
            dataKey: filterField,
            rowIndex,
          });
          if (
            // need to resolve undefined or null as a false for boolean sorting
            // typeof rowFilterValue === 'boolean' &&
            typeof filters[filterField] === 'boolean'
          ) {
            return !!rowFilterValue === filters[filterField];
          }
          return (
            ![null, undefined].includes(rowFilterValue) &&
            rowFilterValue
              .toString()
              .toLowerCase()
              .search(filters[filterField].toLowerCase()) !== -1
          );
        }
        return acc;
      }, true);
    });

    const sortBy = this.state.sortBy || this.props.sortBy;
    const sortDirection = this.state.sortDirection || this.props.sortDirection;

    let sortedFilteredRowMap = _.sortBy(filteredRowMap, [
      (rowIndex) => {
        const sortableRowData = this.getCellData({
          dataKey: sortBy,
          rowIndex,
        });
        return typeof sortableRowData === 'string'
          ? sortableRowData.toLowerCase()
          : sortableRowData;
      },
    ]);
    sortedFilteredRowMap =
      sortDirection === SortDirection.DESC
        ? sortedFilteredRowMap.reverse()
        : sortedFilteredRowMap;

    return sortedFilteredRowMap;
  }

  getRowHeight({ index, rowHeights, collapsedRows }) {
    const { rowIdentifier, rowHeight } = this.props;
    const displayRows = this.state.filteredRows || this.getAllRows();
    const row = displayRows[index];

    const currentRowHeights = rowHeights || this.state.rowHeights;
    const currentCollapsedRows = collapsedRows || this.state.collapsedRows;

    if (
      this.props.collapsibleRowHeight &&
      currentCollapsedRows[row[rowIdentifier]]
    ) {
      return Number(this.props.collapsibleRowHeight);
    }

    const height = Number(currentRowHeights[index]) || Number(rowHeight);

    const minimumRowHeight = Number(this.props.minimumRowHeight);
    if (minimumRowHeight && minimumRowHeight > height) {
      return minimumRowHeight;
    }
    return height;
  }

  getTableHeight({
    rowHeights,
    collapsedRows,
  }: {
    rowHeights?;
    collapsedRows?;
  }) {
    const { filteredRows } = this.state;
    const { headerHeight, headerTreeHeight } = this.props;
    const displayRows = filteredRows || this.getAllRows();
    const totalRowsHeight = displayRows.reduce((acc, row, index) => {
      return acc + this.getRowHeight({ index, rowHeights, collapsedRows });
    }, 0);
    let tableHeight = totalRowsHeight + Number(headerHeight);
    if (headerTreeHeight) {
      tableHeight += Number(headerTreeHeight);
    }
    return tableHeight;
  }

  setRowHeight(index, rowHeight) {
    const { adjustableRowHeight } = this.props;
    const { rowHeights } = this.state;
    if (
      adjustableRowHeight &&
      (rowHeights[index] === undefined || rowHeights[index] !== rowHeight)
    ) {
      this.setState((previousState: any) => {
        const newRowHeights = Object.assign({}, previousState.rowHeights, {
          [index]: rowHeight,
        });
        const newTableHeight = this.getTableHeight({
          rowHeights: newRowHeights,
        });

        this.updateTableHeight({ newTableHeight, newRowHeights });

        return {
          ...previousState,
          rowHeights: newRowHeights,
          // tableHeight: newTableHeight
        };
      });

      if (this.list) {
        this.list.recomputeRowHeights(index);
      }
    }
  }

  updateTableHeight({
    newTableHeight,
    newRowHeights,
  }: {
    newTableHeight;
    newRowHeights?;
  }) {
    const { nestedTable, adjustableRowHeight } = this.props;
    const { tableHeight } = this.state;
    const { setRowHeight, rowIndex } = this.props.passThroughProps;

    const rowHeights = newRowHeights || this.state.rowHeights;

    if (tableHeight !== newTableHeight) {
      this.setState({ tableHeight: newTableHeight });

      if (nestedTable && setRowHeight) {
        if (
          !adjustableRowHeight ||
          Object.values(rowHeights).length >= this.getAllRows().length
        ) {
          setRowHeight(rowIndex, newTableHeight);
        }
      }
    }
  }

  getRowStyle({ index }) {
    const { evenRowColor, oddRowColor, hoverColor, headerColor } = this.props;
    const { hoverRow } = this.state;
    const style = {};
    if (hoverColor && hoverRow === index) {
      Object.assign(style, {
        backgroundColor: hoverColor,
      });
    } else if (index < 0) {
      Object.assign(style, { backgroundColor: headerColor });
    } else if ((index + 1) % 2) {
      Object.assign(style, {
        backgroundColor: oddRowColor,
      });
    } else {
      Object.assign(style, {
        backgroundColor: evenRowColor,
      });
    }
    return style;
  }

  // gets the non compiled property for use rendering child widgets
  getRawRowStyle({ index }): any {
    const { evenRowColor, oddRowColor, hoverColor, headerColor } =
      this.props.component.properties;
    const { hoverRow } = this.state;
    const style = {};
    if (hoverColor && hoverRow === index) {
      Object.assign(style, {
        backgroundColor: hoverColor,
      });
    } else if (index < 0) {
      Object.assign(style, { backgroundColor: headerColor });
    } else if ((index + 1) % 2) {
      Object.assign(style, {
        backgroundColor: oddRowColor,
      });
    } else {
      Object.assign(style, {
        backgroundColor: evenRowColor,
      });
    }
    return style;
  }

  toggleCollapseRow(row) {
    const { rowIdentifier } = this.props;
    this.setState((previousState: any) => {
      const rowName = row.rowData[rowIdentifier];
      const newCollapsedRows = {
        ...previousState.collapsedRows,
        [rowName]: !previousState.collapsedRows[rowName],
      };
      const newTableHeight = this.getTableHeight({
        collapsedRows: newCollapsedRows,
      });
      this.updateTableHeight({ newTableHeight });
      return {
        collapsedRows: newCollapsedRows,
        // tableHeight: newTableHeight,
      };
    });
    this.list.recomputeRowHeights();
  }

  cellRenderer(cell) {
    const {
      passThroughProps,
      adjustableRowHeight,
      rowIdentifier,
      cellOverflow,
      cellTextWrap,
      rowInstanceName,
      component: {
        matchProperties: { rows: rowsGetter },
      },
    } = this.props;
    const {
      cellData,
      columnData: columnWidget,
      rowData: rowDataIndex,
      rowIndex,
    } = cell;
    const {
      matchProperties: { field: columnField },
      cell: [cellWidget],
    } = columnWidget;
    const { collapsedRows, formPath } = this.state;
    const row = this.getAllRows()[rowDataIndex];
    const rowName = row?.[rowIdentifier];
    const collapsed = collapsedRows[rowName] === true;
    const overflow = collapsed ? 'hidden' : cellOverflow;

    const { backgroundColor } = this.getRawRowStyle({ index: rowIndex });
    const key = `${columnField}-${rowDataIndex}cell`;
    return (
      <Cell
        key={key}
        backgroundColor={backgroundColor}
        rowsGetter={rowsGetter}
        rowDataIndex={rowDataIndex}
        columnField={columnField}
        formPath={formPath}
        passThroughProps={passThroughProps}
        cellWidget={cellWidget}
        cellData={cellData}
        rowName={rowName}
        rowIndex={rowIndex}
        adjustableRowHeight={adjustableRowHeight}
        setRowHeight={this.setRowHeight}
        aliases={this.props.aliases}
        overflow={overflow}
        cellTextWrap={cellTextWrap}
        rowInstanceName={rowInstanceName}
      />
    );
  }

  collapseCellRenderer(cell) {
    const { rowIdentifier } = this.props;
    const rowName = cell.rowData[rowIdentifier];
    const { collapsedRows } = this.state;
    const collapsed = collapsedRows[rowName] === true;
    const icon = collapsed ? 'add' : 'remove';

    return (
      <div
        style={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
        }}
        key={`${cell.rowIndex}collapse`}
      >
        <CustomButton
          style={{
            height: '24px',
            width: '24px',
            padding: '0px',
          }}
          icon={icon}
          identifier={cell}
          onClick={this.toggleCollapseRow}
        />
      </div>
    );
  }

  headerRenderer(headerColumn) {
    const {
      passThroughProps,
      sortable,
      component: {
        properties: { headerColor },
      },
    } = this.props;
    const { sortBy, sortDirection } = this.state;
    const { columnData } = headerColumn;
    const {
      header: [headerWidget],
      matchProperties: { field: columnField, filter },
    } = columnData;

    const filterBox = filter ? this.buildFilterBox(columnData) : null;

    const properties = {
      backgroundColor: headerColor,
    };

    const columnAliasMap = this.props.aliases.createDescendent({
      nodeName: `_column_${columnField}`,
    });

    const child = headerWidget ? (
      <Widget
        {...{
          component: headerWidget,
          passThroughProps,
          properties,
          aliases: columnAliasMap,
        }}
      />
    ) : null;

    let sortIcon = null;
    if (sortable) {
      if (sortBy === columnField) {
        sortIcon =
          sortDirection === 'ASC' ? (
            <Icon>expand_more</Icon>
          ) : (
            <Icon>expand_less</Icon>
          );
      } else {
        sortIcon = <Icon>unfold_more</Icon>;
      }
    }
    const header = (
      <span
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'flex-end',
        }}
      >
        {filterBox || child}
        {headerColumn.dataType !== BasicType.Boolean && sortIcon}
      </span>
    );
    return (
      <div
        style={{
          height: '100%',
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'flex-end',
          paddingRight: '5px',
        }}
      >
        {header}
      </div>
    );
  }

  buildHeader(metaChild) {
    const { passThroughProps, headerTreeHeight, headerColor } = this.props;
    const Child = metaChild ? (
      <Widget
        {...{
          component: metaChild,
          passThroughProps,
          aliases: this.props.aliases,
        }}
      />
    ) : null;
    return (
      <div
        style={{
          height: Number(headerTreeHeight),
          width: '100%',
          backgroundColor: headerColor,
        }}
      >
        {Child}
      </div>
    );
  }

  buildFilterBox(columnDefinition) {
    const { filters } = this.state;
    const {
      matchProperties: { field: columnField, label },
    } = columnDefinition;

    let filter;
    if (columnDefinition.dataType === BasicType.Boolean) {
      const indeterminate = filters[columnField] === undefined;
      const checked = !!filters[columnField] && !indeterminate;

      filter = (
        <FormControlLabel
          control={
            <Checkbox
              id={columnField}
              checked={checked}
              indeterminate={indeterminate}
              style={{ padding: '0px' }}
            />
          }
          label={<Typography variant="subtitle1">{label}</Typography>}
          labelPlacement="start"
          id={columnField}
          onChange={this.handleUpdateBooleanFilter}
        />
      );
    } else {
      filter = (
        <CustomTextField
          icon="search"
          name={label}
          identifier={columnField}
          value={filters[columnField]}
          multiline={false}
          onChange={this.handleUpdateFilter}
        />
      );
    }

    return (
      <span
        key={`${columnField}filter`}
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          alignItems: 'flex-end',
          height: '48px',
          paddingLeft: '5px',
        }}
      >
        {filter}
      </span>
    );
  }

  headerRowRenderer({ className, columns, style }) {
    const customStyle = Object.assign({}, style, { paddingRight: '0px' });
    return (
      <div className={className} style={customStyle}>
        {columns}
      </div>
    );
  }

  getCellData(cell) {
    const { dataKey, rowIndex } = cell;
    const { rows } = this.props;
    const rowData = rows[rowIndex];
    return _.get(rowData, dataKey);
  }

  getAllowedColumns() {
    const {
      getPermission,
      component: { columns },
    } = this.props;

    return columns.filter((column) => {
      return (
        !column.permissionId ||
        widgetCan(AccessType.Read, getPermission(column.permissionId))
      );
    });
  }

  buildColumns = memoize(
    (containerWidth, tableWidth, widgetPath, context, collapsibleRowHeight) => {
      const totalWidth = tableWidth || containerWidth;

      const allowedColumns = this.getAllowedColumns().filter((column) =>
        compileProperty('render', column.properties.render, context),
      );

      // updating the store at render is a no no,
      // but this works until I figure out a better way to do it
      const columnStoreData = allowedColumns.map((column) => {
        const {
          matchProperties: { field, label },
        } = column;
        const path = [
          `${widgetPath.slice(0, 1)}`,
          ...widgetPath.slice(1),
          `_column_${field}`,
          'context',
        ];
        return {
          rawPath: path,
          data: { _column: { field, label } },
        };
      });
      store.dispatch(updateContextBatch({ updates: columnStoreData }));

      const calculatedColumnWidths = {};
      let remainingWidth = totalWidth;
      let totalFr = 0;
      allowedColumns.forEach((column, index) => {
        const width = Number(column.matchProperties.width.slice(0, -2));
        if (column.matchProperties.width.endsWith('px')) {
          remainingWidth -= width;
          // columnDefinitions[columnFields[index]].width = width;
          // allowedColumns.calculatedWidth = width;
          calculatedColumnWidths[index] = width;
        } else if (column.matchProperties.width.endsWith('fr')) {
          totalFr += width;
        }
      });

      allowedColumns.forEach((column, index) => {
        if (column.matchProperties.width.endsWith('fr')) {
          const ratio = Number(column.matchProperties.width.slice(0, -2));
          const width = Math.floor((remainingWidth / totalFr) * ratio);
          // allowedColumns.calculatedWidth = width;
          calculatedColumnWidths[index] = width;
        }
      });

      const Columns = allowedColumns.map((columnDefinition, index) => {
        // if filterBy property is present, renders a search box in the header

        return (
          <Column
            key={columnDefinition.matchProperties.field}
            label={columnDefinition.matchProperties.label}
            dataKey={columnDefinition.matchProperties.field}
            columnData={columnDefinition}
            style={{ height: '100%', margin: 0 }}
            headerStyle={{ height: '100%', margin: 0 }}
            cellRenderer={this.cellRenderer}
            headerRenderer={this.headerRenderer}
            width={calculatedColumnWidths[index] || 50}
            cellDataGetter={this.getCellData}
          />
        );
      });

      if (collapsibleRowHeight) {
        const CollapseColumn = (
          <Column
            key="collapse"
            dataKey="collapse"
            cellRenderer={this.collapseCellRenderer}
            width={25}
            style={{ height: '100%', margin: 0 }}
            headerStyle={{ height: '100%', margin: 0 }}
          />
        );
        Columns.unshift(CollapseColumn);
      }

      return Columns;
    },
  );

  handleRequestReport() {
    const { requestReport, report } = this.props;
    requestReport({ type: report });
  }

  render() {
    const {
      rowHeight,
      headerHeight,
      adjustableRowHeight,
      headerColor,
      fullHeight,
      tableWidth,
      sortable,
      downloadCSV,
      report,
      filename,
      headerTreeHeight,
      disableVirtualization,
      aliases: { widgetPath },
      context: { nestedConfigContext: context },
      collapsibleRowHeight,
    } = this.props;
    const {
      // Columns,
      sortBy,
      sortDirection,
      menuOpen,
      loadingRows,
      // rowMap,
      filters,
    } = this.state;

    const rowMap = this.getFilteredSortedRows(filters);

    if (!rowMap) {
      return null;
    }

    const headerTree = null;
    const headerWidget = headerTree ? this.buildHeader(headerTree) : null;

    const csvButton = downloadCSV ? (
      <CustomButton
        // onClick={this.handleDownloadCSV}
        icon="save"
        style={{
          position: 'absolute',
          top: 0,
          right: 0,
        }}
      />
    ) : null;

    const Wrapper = report ? ListMenu : Fragment;
    const renderAllRows = report || disableVirtualization;
    const wrapperWidth = tableWidth ? `${tableWidth}px` : '100%';

    return (
      <Wrapper
        {...(report && {
          downloadReport: this.handleRequestReport,
          filename,
          open: menuOpen,
          onClick: this.toggleMenu,
          loadingRows,
          containerWidth: wrapperWidth,
        })}
      >
        <div
          style={{
            display: 'grid',
            gridTemplateRows: `${headerTreeHeight}px ${
              fullHeight
                ? `${this.state.tableHeight - headerTreeHeight}px`
                : 'auto'
            }`,
            height: '100%',
            width: wrapperWidth,
          }}
        >
          <div style={{ gridRow: '1/2' }}>{headerWidget}</div>
          <div style={{ gridRow: '2/3' }}>
            {csvButton}
            <TestSafeAutosizer>
              {({ width: containerWidth, height: containerHeight }) => {
                const height = fullHeight
                  ? this.state.tableHeight - headerTreeHeight
                  : containerHeight;
                const width = tableWidth ? Number(tableWidth) : containerWidth;

                return (
                  <div>
                    <Table
                      // bug in react-virtualized, this currently breaks cell updating.
                      // isScrollingOptOut
                      ref={(list) => {
                        this.list = list;
                      }}
                      gridStyle={{ outline: 'none' }}
                      width={width}
                      height={height}
                      headerHeight={Number(headerHeight)}
                      rowHeight={
                        adjustableRowHeight
                          ? this.getRowHeight
                          : Number(rowHeight)
                      }
                      rowCount={rowMap.length}
                      rowGetter={({ index }) => rowMap[index]}
                      rowStyle={this.getRowStyle}
                      headerStyle={{
                        backgroundColor: headerColor,
                      }}
                      headerRowRenderer={this.headerRowRenderer}
                      {...(sortable && { sort: this.handleUpdateSort })}
                      sortBy={sortBy}
                      sortDirection={SortDirection[sortDirection]}
                      {...(renderAllRows && {
                        overscanIndicesGetter: ({ cellCount }) => {
                          return {
                            overscanStartIndex: 0,
                            overscanStopIndex: cellCount - 1,
                          };
                        },
                      })}
                    >
                      {this.buildColumns(
                        containerWidth,
                        tableWidth,
                        widgetPath,
                        context,
                        collapsibleRowHeight,
                      )}
                    </Table>
                  </div>
                );
              }}
            </TestSafeAutosizer>
          </div>
        </div>
      </Wrapper>
    );
  }
}

export default compose(
  withUiState,
  withActions,
  withStyles(styles, { withTheme: true }),
)(List);

function listTransform(widget) {
  const {
    properties: {
      columns,
      columnDataTypes,
      columnLabels,
      columnPermissionIds,
      columnWidths,
      filterBy,
    },
  } = widget;

  const defaultCell = {
    type: 'Display',
    name: 'Cell',
    properties: {
      data: "'{{{_cell}}}'",
    },
  };

  const defaultHeader = {
    type: 'Display',
    name: 'Cell',
    properties: {
      data: "'{{{_column.label}}}'",
    },
  };

  const removeQuotesIfPresent = (string) =>
    string.startsWith("'") && string.endsWith("'")
      ? string.slice(1, -1)
      : string;

  if (columns) {
    const columnsSplit = columns && removeQuotesIfPresent(columns).split('_');
    const typesSplit =
      columnDataTypes && removeQuotesIfPresent(columnDataTypes).split('_');
    const labelsSplit =
      columnLabels && removeQuotesIfPresent(columnLabels).split('_');
    const permissionIdsSplit =
      columnPermissionIds &&
      removeQuotesIfPresent(columnPermissionIds).split('_');
    const widthsSplit =
      columnWidths && removeQuotesIfPresent(columnWidths).split('_');
    const filterBySplit =
      filterBy && removeQuotesIfPresent(filterBy).split('_');

    const columnChildren =
      columnsSplit &&
      columnsSplit.map((columnField, index) => {
        return {
          name: columnField,
          type: 'Column',
          properties: {
            field: `'${columnField}'`,
            type: typesSplit && `'${typesSplit[index]}'`,
            label: labelsSplit && `'${labelsSplit[index]}'`,
            permissionId:
              permissionIdsSplit && `'${permissionIdsSplit[index]}'`,
            width: widthsSplit && `'${widthsSplit[index]}'`,
            filter: !!filterBySplit && !!filterBySplit[index],
            sort: true,
            filterAccessor:
              filterBySplit &&
              filterBySplit[index] &&
              `'${filterBySplit[index]}'`,
            sortAccessor:
              filterBySplit &&
              filterBySplit[index] &&
              `'${filterBySplit[index]}'`,
          },
        };
      });

    columnChildren.forEach((columnChild) => {
      const existingColumn = widget.columns.find(
        (column) => column.properties.field === columnChild.properties.field,
      );
      if (!existingColumn) {
        widget.columns.push(columnChild);
      }
    });

    widget.columns.forEach((column) => {
      if (!column.cell) {
        column.cell = [];
      }

      const oldCell = widget.children?.find((child) =>
        child.properties.columnCells?.includes(
          column.properties.field.slice(1, -1),
        ),
      );

      const newCell =
        oldCell &&
        oldCell.name === 'Cell' &&
        oldCell.properties.data === '{{{cell}}}'
          ? defaultCell
          : oldCell;

      if (!column.cell.length) {
        column.cell.push(newCell || defaultCell);
      }

      if (!column.header) {
        column.header = [];
      }

      const oldHeader = widget.children?.find((child) =>
        child.properties.headerCells?.includes(
          column.properties.field.slice(1, -1),
        ),
      );

      if (!column.header.length) {
        column.header.push(oldHeader || defaultHeader);
      }
    });
  }
}

export { listTransform };
