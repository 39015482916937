import { Button, LinearProgress } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { Component } from 'react';
import { compose } from 'redux';

import { withClient } from '../../wrappers/ClientContext';
import { withActions } from '../widgetEngine/ActionEnabler';

const styles = () => ({
  input: {
    display: 'none',
  },
});
class Upload extends Component {
  public declare props;
  public declare state;
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleFileLoad = this.handleFileLoad.bind(this);
  }

  handleChange(event) {
    const file = event.target.files[0];
    this.setState({ file });
    const reader = new FileReader();
    reader.onload = this.handleFileLoad;
    reader.readAsArrayBuffer(file);
  }

  async handleFileLoad(event) {
    const { clientManager, filePath, bucket } = this.props;
    const { s3Client } = clientManager;
    const {
      file: { name },
    } = this.state;
    const { result: fileContents } = event.target;

    if (!bucket) {
      throw new Error('You must specify the bucket to upload to.');
    }

    const path =
      (filePath && (filePath.endsWith('/') ? filePath : `${filePath}/`)) || '';

    const fullPath = `${path}${name}`;

    const params = {
      Body: fileContents,
      Bucket: bucket,
      Key: fullPath,
    };

    this.setState({ loading: true });

    const putResult = await s3Client.putObject(params).promise();

    this.setState({ loading: false });

    if (putResult.error) {
      console.log('Error uploading file:', putResult.error);
    }
  }

  render() {
    const { classes } = this.props;
    const { loading } = this.state;

    return (
      <div>
        <input
          className={classes.input}
          id="raised-button-file"
          multiple
          type="file"
          onChange={this.handleChange}
        />
        <label htmlFor="raised-button-file">
          <Button component="span" className={classes.button}>
            Upload
          </Button>
        </label>
        {loading && <LinearProgress />}
      </div>
    );
  }
}

export default compose(
  withClient,
  withActions,
  withStyles(styles, { withTheme: true }),
)(Upload);
