import Slider from '@mui/material/Slider';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import withStyles from '@mui/styles/withStyles';
import { compose } from 'redux';

import { withUiState } from '../../../store/withUiState';
import { withActions } from '../../widgetEngine/ActionEnabler';
import Input from '../../widgetEngine/Input';

const styles = () => ({});

class SliderWidget extends Input {
  public declare props;
  public declare state;
  public value;
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleDragEnd = this.handleDragEnd.bind(this);
  }

  public initializeValue() {
    const {
      updateFromWidget,
      startingValue,
      initialValue,
      persistValue,
      min,
      max,
    } = this.props;

    if (persistValue !== undefined) {
      updateFromWidget({
        data: persistValue,
        initialUpdate: true,
      });
    } else if (initialValue !== undefined && initialValue !== null) {
      updateFromWidget({
        data: initialValue,
        initialUpdate: true,
      });
    } else if (startingValue !== undefined || startingValue !== null) {
      updateFromWidget({
        data: startingValue,
        initialUpdate: true,
      });
    } else {
      updateFromWidget({
        data: (max - min) / 2,
        initialUpdate: true,
      });
    }
  }

  handleChange(event, value) {
    const { readOnly, updateFromWidget } = this.props;
    event.stopPropagation();
    if (readOnly) {
      return null;
    }
    updateFromWidget({
      data: value,
      debounce: true,
    });
    this.value = value;
  }

  handleDragEnd(event) {
    const { readOnly, updateFromWidget } = this.props;
    event.stopPropagation();
    if (readOnly) {
      return null;
    }

    updateFromWidget({ data: this.value, newWay: true });
  }

  render() {
    const {
      id,
      label,
      labelVariant,
      data,
      disabled,
      classes,
      min,
      max,
      step,
      width,
      padding,
      tooltip,
      elementAttributes,
    } = this.props;

    if (data === undefined) {
      return null;
    }

    const component = (
      <div
        {...(id && { id })}
        {...(elementAttributes && elementAttributes)}
        style={{ width, padding }}
      >
        <Typography id="slider-image" variant={labelVariant}>
          {label}
        </Typography>
        <Slider
          className={classes.slider}
          disabled={disabled}
          min={min}
          max={max}
          step={step}
          onChange={this.handleChange}
          onChangeCommitted={this.handleDragEnd}
          value={data}
        />
      </div>
    );
    if (tooltip) {
      return <Tooltip title={tooltip}>{component}</Tooltip>;
    }
    return component;
  }
}

export default compose(
  withUiState,
  withActions,
  withStyles(styles, { withTheme: true }),
)(SliderWidget);
