import { MenuItem } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { Component } from 'react';

const styles = () => ({});

interface IProps {
  option;
  onClick;
}

class CustomMenuItem extends Component<IProps> {
  public declare props;
  constructor(props: IProps) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    const { option, onClick } = this.props;
    onClick(option);
  }

  render() {
    const { option } = this.props;
    return <MenuItem onClick={this.handleClick}>{option}</MenuItem>;
  }
}

export default withStyles(styles, { withTheme: true })(CustomMenuItem);
