import { Paper } from '@mui/material';
import { useContext, useMemo } from 'react';

import { ThemeWrapperContext } from '../../../wrappers/ThemeWrapperContext';
import { TitleWrapper } from '../ContainerTitleWrapper';
import {
  generateSimpleChildren,
  makeThemedStyles,
  withActions,
} from '../dependencies';

import {
  GridContainerChildProps,
  GridContainerProps,
  gridContainerTypeInfo,
} from './GridContainerTypeInfo';

export { gridContainerTypeInfo };

const useStyles = makeThemedStyles<GridContainerProps>(() => ({
  outer: (props) => ({
    display: 'grid',
    maxWidth: props.maxWidth,
    maxHeight: props.maxHeight,
    width: props.width,
    height: props.height,
    boxSizing: props.boxSizing,
    borderStyle: props.border,
    gridTemplateColumns: props.gridColumns,
    gridTemplateRows: props.gridRows,
    borderWidth: props.borderWidth,
    borderColor: props.borderColor,
    borderRadius: props.borderRadius,
    backgroundColor: props.backgroundColor,
    alignItems: props.alignItems,
    justifyItems: props.justifyItems,
    overflow: props.overflow,
    gridRowGap: props.gridRowGap,
    gridColumnGap: props.gridColumnGap,
  }),
}));

const useStyleGridArea = makeThemedStyles<GridContainerChildProps>(() => ({
  area: (props) => ({
    gridColumn: props.gridColumn,
    gridRow: props.gridRow,
    alignSelf: props.alignSelf,
    justifySelf: props.justifySelf,
    minHeight: 0,
    minWidth: 0,
  }),
}));

const GridArea: React.FC<React.PropsWithChildren<GridContainerChildProps>> = (
  props,
) => {
  const classes = useStyleGridArea(props);
  return <div className={classes.area}>{props.children}</div>;
};

const GridContainer: React.FC<React.PropsWithChildren<GridContainerProps>> = (
  props,
) => {
  const { border = false, title } = props;
  const classes = useStyles(props);
  const { theme } = useContext(ThemeWrapperContext);

  const Children = generateSimpleChildren(props);

  const content = Children.map((DynamicComponent) => {
    const { gridColumn, gridRow, alignSelf, justifySelf } =
      DynamicComponent.props.component.matchProperties;
    const { widgetId } = DynamicComponent.props.component;
    return (
      <GridArea
        key={`gridArea${widgetId}${gridColumn}${gridRow}`}
        gridColumn={gridColumn}
        gridRow={gridRow}
        alignSelf={alignSelf}
        justifySelf={justifySelf}
      >
        {DynamicComponent}
      </GridArea>
    );
  });

  const Wrapper = border === 'paper' ? Paper : 'div';

  const Container = useMemo(
    () => (
      <Wrapper className={classes.outer} {...props.elementAttributes}>
        {content}
      </Wrapper>
    ),
    [content, Wrapper, classes, props.elementAttributes],
  );

  if (title) {
    return TitleWrapper(Container, title, theme);
  }

  return Container;
};

export default withActions(GridContainer);
