import { PropertyConfigurationHandler } from 'universal/propertySupport';

import { widgetPropertyConfiguration } from '../components/widgets/widgetTypes';

export function createWidgetPropertyConfigurationHandler(widget) {
  return new PropertyConfigurationHandler({
    propertyConfiguration: widgetPropertyConfiguration,
    node: widget,
  });
}

export function createWidgetFromType({
  type,
  name,
  properties,
}: {
  type: string;
  name?: string;
  properties?: 'default' | 'all';
}) {
  const widgetPropertyConfigurationHandler = new PropertyConfigurationHandler({
    propertyConfiguration: widgetPropertyConfiguration,
  });
  const newWidget = widgetPropertyConfigurationHandler.createNode({
    name,
    type,
  });
  if (properties) {
    if (properties === 'default') {
      widgetPropertyConfigurationHandler.addDefaults();
    } else if (properties === 'all') {
      widgetPropertyConfigurationHandler.addAllProperties();
    }
  }
  return newWidget;
}
