import {
  IConfigProperties,
  IWidgetProps,
  categories,
  containerProperties,
  gridAlignOptions,
} from '../types';

const gridContainerPropertyInfo = {
  ...containerProperties,
  gridRows: {
    required: false,
    description:
      'Space separated list of measurements representing rows, units are px, %, fr, and auto, ex: 10% auto 10%, 400px 2fr 1fr',
    link: 'https://css-tricks.com/snippets/css/complete-guide-grid/',
  },
  gridColumns: {
    required: false,
    description:
      'Space separated list of measurements representing columns, units are px, %, fr, and auto, ex: 10% auto 10%, 400px 2fr 1fr',
    link: 'https://css-tricks.com/snippets/css/complete-guide-grid/',
  },
  alignItems: {
    options: gridAlignOptions,
    link: 'https://css-tricks.com/snippets/css/complete-guide-grid/',
  },
  justifyItems: {
    options: gridAlignOptions,
    link: 'https://css-tricks.com/snippets/css/complete-guide-grid/',
  },
  gridRowGap: {
    description: 'forms gutters between the rows',
  },
  gridColumnGap: {
    description: 'forms gutters between the columns',
  },
  adjustableHeight: {
    description:
      'If set to true, allows children widgets with a height setting api like list to set the height of this widget',
    options: [true, false],
    default: false,
  },
};

const gridContainerChildPropertyInfo = {
  gridRow: {
    required: false,
  },
  gridColumn: {
    required: false,
  },
  alignSelf: {
    options: gridAlignOptions,
    link: 'https://css-tricks.com/snippets/css/complete-guide-grid/',
  },
  justifySelf: {
    options: gridAlignOptions,
    link: 'https://css-tricks.com/snippets/css/complete-guide-grid/',
  },
};

export type GridContainerProps = IWidgetProps<typeof gridContainerPropertyInfo>;
export type GridContainerChildProps = IConfigProperties<
  typeof gridContainerChildPropertyInfo
>;

export const gridContainerTypeInfo = {
  types: {
    children: true,
  },
  childTypes: {
    actions: true,
    overlays: true,
    children: true,
  },
  category: categories.container,
  properties: gridContainerPropertyInfo,
  childProperties: gridContainerChildPropertyInfo,
  events: {
    load: [],
    update: [],
    validate: [],
  },
};
