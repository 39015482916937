/*
  Logger Support

  To use logging, either set the SNAPSTRAT_DEBUG environment variable, or localstorage.snapstratDebug to a logging string:

    loggerName:level[,loggerName:level]*
    loggerName is the name of the logger in either Loggers or TestLoggers below.
    level is one of the LogLevels values.

    For example, SNAPSTRAT_DEBUG=pipelineManager:debug,javascript:debug

  The default logging level is "info", which means anything at the level of "info" or higher (error, warning) will get logged.

  Sometimes the loggerNames are compound, like "pipelineManager.network". This has no significance
  other than organizing the logger names.

  To create a logger in your code, use the getLogger() function, for example:

    const logger = getLogger({ name: Loggers.UTILITY_FUNCTIONS });

  The logger instance can then be directly used for logging, like this:

    logger.info('my log message');
    logger.error({ dataObject1, dataObject2 }, 'Something bad happened with the data objects');

  If the logger function gets a single argument, that's a string, if there are two or more arguments, the first
  argument is output in the log as JSON, and can be any arbitrary object. The second argument is the message.

  See the pino documentation for more information.

  WARNING - this code cannot depend on anything that uses a logger.
 */

import { isBrowser } from 'browser-or-node';
import { BaseLogger, Logger } from 'pino';
import { process } from 'ts-invariant';

import { COMPANY_NAME } from './common/commonConstants';
// This import statement is rewritten in the build tooling for the browser to be loggerProviderBrowser
import getPino from './loggerProviderBrowser';

export enum Loggers {
  AGGREGATE = 'aggregate',
  ANALYZE_RECORDS = 'analyzeRecords',
  APPLICATION_MANAGER = 'applicationManager',
  APOLLO_HANDLER = 'apolloHandler',
  APOLLO_HANDLER_FILTER = 'apolloHandler.filter',
  APOLLO_HANDLER_PIPELINE = 'apolloHandler.pipeline',
  APOLLO_HANDLER_PUBSUB = 'apolloHandler.pubSub',
  APOLLO_HANDLER_QUERY_TIMING = 'apolloHandler.queryTiming',
  BUILD_TOOLS = 'buildTools',
  CLIENT = 'client',
  CLIENT_ERRORS = 'client.errors',
  CLIENT_INITIALIZE = 'client.initialize',
  CLIENT_PERFORMANCE = 'client.performance',
  CLIENT_RELOAD = 'client.reload',
  CLIENT_MANAGER = 'clientManager',
  CLIENT_DATA = 'client.data',
  CONFIGURATION_OBJECT_MANAGER = 'configurationObjectManager',
  DATA = 'data',
  DATA_OPERATION = 'dataOperation',
  DEV_SETUP = 'devSetup',
  DYNAMO_ACCESS = 'databaseProvider',
  ENTITY_KEY_SUPPORT = 'entityKeySupport',
  ERRORS = 'errors',
  FORMS = 'forms',
  GITHUB = 'github',
  GRAPHQL_CLIENT = 'graphqlClient',
  GRAPHQL_LINKS = 'graphqlLinks',
  GRAPHQL_SUBSCRIPTIONS = 'graphqlSubscriptions',
  GRAPHQL_SUPPORT = 'graphqlSupport',
  INDEX_SUPPORT = 'indexSupport',
  INGEST = 'ingest',
  INGEST_CHUNK_PROCESSING = 'ingest.chunkProcessing',
  INSTALL_METADATA = 'installMetadata',
  JAVASCRIPT_SUPPORT = 'javascriptSupport',
  LAMBDA_SUPPORT = 'lambdaSupport',
  LOAD_GRAPHQL = 'loadGraphql',
  LOAD_INTO_MEMORY_SUPPORT = 'loadIntoMemorySupport',
  LOAD_STORE = 'loadStore',
  METADATA_SUPPORT = 'metadataSupport',
  NODE_ID_TOKEN = 'nodeIdToken',
  NODE_CONFIG = 'nodeConfig',
  OPTIMIZER = 'optimizer',
  PERMISSION_MANAGER = 'permissionManager',
  PIPELINE_JAVASCRIPT = 'javascript',
  PIPELINE_MANAGER = 'pipelineManager',
  PIPELINE_MANAGER_BATCHING = 'pipelineManager.batching',
  PIPELINE_MANAGER_NETWORK = 'pipelineManager.network',
  PIPELINE_MANAGER_RECORD_QUERY = 'pipelineManager.recordQuery',
  PIPELINE_SERVER = 'pipelineServer',
  PIPELINE_STATUS_ANALYSIS = 'pipelineStatusAnalysis',
  PRESENTATION = 'presentation',
  QUERY_TIMING = 'queryTiming',
  REST_API_SERVER = 'restApiServer',
  S3SUPPORT = 's3Support',
  S3_ACCESS = 's3DatabaseProvider',
  VERIFY_AND_SETUP_STACK = 'verifyAndSetupStack',
  SCHEMA_MANAGER = 'schemaManager',
  STACK_INFO = 'stackInfo',
  TRANSFORM = 'transform',
  USER_SUPPORT = 'userSupport',
  UTILITY_FUNCTIONS = 'utilityFunctions',
  UTILITY_FUNCTIONS_WAIT_FOR = 'utilityFunctions.waitFor',
  VALIDATION_SUPPORT = 'validationSupport',
  WORM_CLI = 'wormCli',
  WAIT_NOTIFY = 'waitNotify',
  ZENDESK_SUPPORT = 'zendeskSupport',
}

export enum TestLoggers {
  APOLLO_HANDLER = '',
  CLIENT = 'client.test',
  CODE = 'code.test',
  COMMON = 'common.test',
  CONFIG = 'config.test',
  INDEX_SUPPORT = 'indexSupport.test',
  INGEST = 'ingest.test',
  JDBC = 'jdbc.test',
  OPTIMIZER = 'optimizer.test',
  LOGGER_SUPPORT = 'loggerSupport.test',
  MQTT_PROVIDER = 'mqttProvider.test',
  PERMISSION_MANAGER = 'permissionManager.test',
  PIPELINE = 'pipeline.test',
  REST_API_SERVER = 'restApiServer.test',
  SCHEMA_MANAGER = 'schemaManager.test',
  SMARTSHEET = 'smartsheet.test',
  TRANSFORM = 'transform.test',
  USER = 'userSupport.test',
}

export const TIMEOUT_MS = 2222;

type AllLoggers = Loggers | TestLoggers;
type AllLoggerKeys = keyof AllLoggers;

// This matches the LevelWIthSilent type in pino. We can't use that directly however,
// since the pino logger instance level field and functions that deal with it
// take a LevelWithSilent | string, which eliminates the checking of the value.
export enum LogLevels {
  Trace = 'trace',
  Debug = 'debug',
  Info = 'info',
  Warn = 'warn',
  Error = 'error',
  Fatal = 'fatal',
  None = 'silent',
}

// Copied from pino
const levels = {
  trace: 10,
  debug: 20,
  info: 30,
  warn: 40,
  error: 50,
  fatal: 60,
};

export function levelToDatadogStatus(level) {
  if (level === 10 || level === 20) {
    return 'debug';
  }
  if (level === 40) {
    return 'warning';
  }
  if (level === 50) {
    return 'error';
  }
  if (level >= 60) {
    return 'critical';
  }
  return 'info';
}

export const loggerProperties: {
  pino?: Logger;
  loggerConfigMap: { [key: string]: string };
  loggerMap: Partial<{ [K in AllLoggerKeys]: BaseLogger }>;
  rawNamespaces?: string[];
} = {
  loggerMap: {},
  loggerConfigMap: {},
};

export const pinoLogOptions = {
  name: COMPANY_NAME,
  base: null,
  nestedKey: 'payload',
  browser: undefined,
};

export const TEST_SERVER_PORT = 9123;

// Force Lambda logging mode to be used - note, this does not work
// when running serverless offline because there are multiple execution
// threads which this code cannot handle. That does not happen in a real lambda
// environment
export let testLambdaMode;

export function setTestLambdaMode(mode: boolean) {
  testLambdaMode = mode;
}

// Exported only for the loggerSupport.test
export const initLogger = () => {
  loggerProperties.loggerMap = {};
  loggerProperties.loggerConfigMap = {};

  loggerProperties.pino = getPino();

  const namespaces = getNamespaces();
  if (!namespaces || namespaces.length === 0) {
    return;
  }
  loggerProperties.rawNamespaces = namespaces;
  namespaces.forEach((n) => {
    const nsSplit = n.split(':');
    if (nsSplit.length < 2) {
      console.error(
        `No logging level specified for ${nsSplit[0]} in ${loggerProperties.rawNamespaces} - ignoring. Specify 'namespace:debug' for example.`,
      );
      return;
    }
    const levelString = nsSplit[1];
    const level = levels[levelString];
    if (level === undefined) {
      console.error(
        `Invalid log level specification ${levelString} on ${n} in ${loggerProperties.rawNamespaces} - specify 'fatal', 'error', 'warn', 'info', 'debug', 'trace'`,
      );
      return;
    }
    loggerProperties.loggerConfigMap[nsSplit[0]] = levelString;
  });
};

const getNamespaces = (): string[] => {
  let namespaces = '';
  if (process?.env?.SNAPSTRAT_DEBUG) {
    namespaces = process.env.SNAPSTRAT_DEBUG;
  } else if (
    typeof localStorage !== 'undefined' &&
    localStorage?.snapstratDebug
  ) {
    namespaces = localStorage.snapstratDebug;
  }

  if (namespaces.length === 0) {
    return [];
  }
  return namespaces.trim().split(/[\s,]+/);
};

let errorLogWrapper;

export const setErrorLogWrapper = (wrapper: (logFunction) => void) => {
  errorLogWrapper = wrapper;
  Object.keys(loggerProperties.loggerMap).forEach((k) => {
    if (!loggerProperties.loggerMap[k].wrapped) {
      loggerProperties.loggerMap[k] = wrapLogger(loggerProperties.loggerMap[k]);
    }
  });
};

const wrapLogger = (childLogger) => {
  childLogger.error = errorLogWrapper(childLogger.error.bind(childLogger));
  childLogger.fatal = errorLogWrapper(childLogger.fatal.bind(childLogger));
  childLogger.wrapped = true;
};

export const getLogger = (params: {
  name: AllLoggers;
  nameSuffix?: string;
  level?: LogLevels;
}): Logger => {
  const { name, nameSuffix, level = LogLevels.Info } = params;

  const effectiveLoggerName = nameSuffix ? name + nameSuffix : name;

  if (!loggerProperties.pino) {
    initLogger();
  }
  const levelToUse = loggerProperties.loggerConfigMap[name] || level;

  const knownLogger = loggerProperties.loggerMap[effectiveLoggerName];
  if (knownLogger) {
    knownLogger.level = levelToUse;
    return knownLogger;
  }

  const childLogger = loggerProperties.pino.child(
    {
      name: effectiveLoggerName,
    },
    { level: levelToUse },
  );

  if (errorLogWrapper && !(childLogger as any).wrapped) {
    wrapLogger(childLogger);
  }

  loggerProperties.loggerMap[effectiveLoggerName] = childLogger;

  if (isBrowser) {
    // pino does not seem to provide this function in the browser environment, and the level
    // is also not set correctly. Maybe there is some way to use this in the browser environment
    // that's more correct, but for now this seems to work.
    childLogger.level = levelToUse;
    childLogger.isLevelEnabled = (checkLevel): boolean => {
      const retVal = levels[checkLevel] >= childLogger.levelVal;
      return retVal;
    };

    // Have to set the logging level functions manually as well
    for (const checkLevel of Object.keys(levels)) {
      if (checkLevel === levelToUse) {
        break;
      }
      childLogger[checkLevel] = (): void => {
        // noop
      };
    }
  }

  return childLogger;
};

export const setLogLevel = (loggerName: AllLoggers, level: LogLevels) => {
  const logger = getLogger({ name: loggerName });
  logger.level = level;
};
