import { IPropertyConfiguration } from '../../propertySupport';
import { EditorType, StageType } from '../../types';
import { IStageInfos, IStageTypeInfo, commonProperties } from '../stage';

export const graphQLQueryTypeInfo: IStageTypeInfo = {
  moduleName: 'graphQLQuery',
  stageType: StageType.graphQLQuery,
  description: 'Execute GraphqlQuery function',
  properties: {
    ...commonProperties,
    query: {
      description: 'The text of the query',
      editor: EditorType.GraphQL,
      order: 0,
    },
    writeToExternal: {
      description:
        'Write the result of the query to the local external store using the stage name as the path',
      order: 5,
    },
    outputAsMap: {
      description:
        'If true, a list query is output as a map rather than an array',
      order: 10,
    },
    orderBy: {
      description: 'the field which to sort the returned query by',
      order: 12,
    },
    ascDesc: {
      description:
        'Only valid if orderBy is set, asc for ascending, and desc for descending',
      order: 13,
    },
    variables: {
      description:
        'The variables for the query. This is an object where each ' +
        'key can be referred to as a variable name.',
      order: 15,
    },
    noCache: {
      description:
        'Does not use the local GraphQL cache. Shorthand for fetchPolicy: FETCH_POLICY_NO_CACHE',
      order: 17,
    },
    fetchPolicy: {
      description:
        'Overrides the default fetch-policy as specified in the PipelineManager. See the Apollo fetch policies',
      order: 20,
    },
    connectionResult: {
      description:
        'Provides an IQueryConnectionResult for the query. This is not used as an input property',
      order: 30,
    },
    pageNumber: {
      description:
        'Internal use only: the sequence number of this part of the query',
      order: 30,
    },
    useLocalLink: {
      description:
        'Internal use only: in the apolloHandler serve the request locally',
      order: 40,
    },
  },
};

export const graphQLMutationTypeInfo: IStageTypeInfo = {
  moduleName: 'graphQLMutation',
  stageType: StageType.graphQLMutation,
  description: 'Execute a GraphQL mutation',
  // Executes a GraphQL mutation. The GraphQL mutation can be specified explicitly using the `mutation` property.
  // Alternatively, it can be automatically generated given an `entityId` and `field`. A `queryId` may be optionally
  // specified in the generation case.
  properties: {
    ...commonProperties,
    mutation: {
      description: 'The GraphQL mutation text',
      editor: EditorType.GraphQL,
      order: 0,
    },
    variables: {
      description:
        'The variables for the mutation. This is an object where each ' +
        'key can be referred to as a variable name.',
      order: 1,
    },
    queryId: {
      description: 'Update the specified query when auto generating a mutation',
      order: 2,
    },
    entityTypeId: {
      description: 'The entity type for which the mutation is generated',
      order: 3,
    },
    data: {
      description: 'The data to be used when generating the mutation',
      order: 4,
    },
  },
};

export const javascriptTypeInfo: IStageTypeInfo = {
  moduleName: 'javascript',
  stageType: StageType.javaScript,
  description: 'Execute Javascript or Typescript code',
  properties: {
    ...commonProperties,
    code: {
      description: 'The code to execute',
      editor: EditorType.Javascript,
      order: 0,
    },
  },
};

export const lambdaTypeInfo: IStageTypeInfo = {
  moduleName: 'lambda',
  stageType: StageType.lambda,
  description: 'Execute Lambda function',
  properties: {
    ...commonProperties,
    functionName: {
      description: 'Simple name of the Lambda function',
      order: 0,
    },
    payload: {
      description: 'String containing the JSON to be passed to the function',
      order: 1,
    },
  },
};

export const httpTypeInfo: IStageTypeInfo = {
  moduleName: 'http',
  stageType: StageType.http,
  description: 'Execute HTTP Request',
  properties: {
    ...commonProperties,
    requestType: {
      default: 'GET',
      description: 'The type of the HTTP request: GET, POST, PUT, DELETE.',
      order: 0,
    },
    uri: {
      description: 'URI',
      order: 1,
    },
    payload: {
      // FIXME - this can be an object or a string
      description: 'String value of the request body',
      order: 2,
    },
    headers: {
      description: 'A JSON string of the HTTP headers to send with request',
      order: 3,
    },
    statusCode: {
      description: 'Returned HTTP status code',
      editable: false,
    },
    statusMessage: {
      description: 'Returned message associated with the HTTP status code',
      editable: false,
    },
  },
};

export const execPipelineTypeInfo: IStageTypeInfo = {
  moduleName: 'execPipeline',
  stageType: StageType.execPipeline,
  description: 'Execute pipeline',
  properties: {
    ...commonProperties,
    pipelineName: {
      description: 'Optionally qualified name of the pipeline to execute',
      order: 0,
    },
    remote: {
      description:
        'Execute the pipeline in a remote server and return the results',
      order: 1,
    },
    remoteAsync: {
      description:
        'Execute the pipeline asynchrounously on a remote server and continue execution. ' +
        'The result of the pipeline execution are not available.',
      order: 2,
    },
    stages: {
      description: 'Stages to execute remotely',
      default: [],
      editable: false,
      order: 4,
    },
    mergeIntoOutput: {
      description:
        "Merge the output of the remote pipeline execution with this pipeline's output",
      order: 3,
    },
  },
};

export const emailTypeInfo: IStageTypeInfo = {
  moduleName: 'email',
  stageType: StageType.email,
  description: 'Execute Email function',
  properties: {
    ...commonProperties,
    to: {
      description: 'Recipient',
      order: 0,
    },
    cc: {
      description: 'Copy',
      order: 1,
    },
    from: {
      description: 'Sender',
      order: 2,
    },
    subject: {
      description: 'Subject',
      order: 3,
    },
    text: {
      description: 'Body of email',
      order: 4,
    },
    otherId: {
      description:
        'An optional identifier associated with the email for the email log',
      order: 5,
    },
    entityType: {
      description: 'Type of the entity associated with the email',
      order: 6,
    },
    entityId: {
      description: 'Id of the entity associated with the email',
      order: 7,
    },
  },
};

export const s3TypeInfo: IStageTypeInfo = {
  moduleName: 's3',
  stageType: StageType.s3,
  description: 'Execute S3 function',
  properties: {
    ...commonProperties,
    action: {
      description: 'Action to take, currently only READ is supported',
      order: 0,
    },
    bucketName: {
      description: 'Name of the S3 bucket',
      order: 1,
    },
    key: {
      description: 'Key of the object within the bucket',
      order: 2,
    },
    range: {
      description: 'S3 range expression for partial access',
      order: 3,
    },
    returnBytes: {
      description:
        'If true, the raw bytes are returned, If false, the results are converted to a string',
      order: 4,
    },
  },
};

export const typescriptTypeInfo: IStageTypeInfo = {
  moduleName: 'typescript',
  stageType: StageType.typeScript,
  description: 'Execute Typescript code',
  properties: {
    ...commonProperties,
    moduleName: {
      description: 'Module name to execute',
      order: 0,
    },
    functionName: {
      description: 'Function name to execute',
      order: 10,
    },
  },
};

export const stageInfos: IStageInfos = {
  [StageType.lambda]: { typeInfo: lambdaTypeInfo },
  [StageType.javaScript]: { typeInfo: javascriptTypeInfo },
  [StageType.http]: { typeInfo: httpTypeInfo },
  [StageType.email]: { typeInfo: emailTypeInfo },
  [StageType.s3]: { typeInfo: s3TypeInfo },
  [StageType.graphQLQuery]: { typeInfo: graphQLQueryTypeInfo },
  [StageType.graphQLMutation]: { typeInfo: graphQLMutationTypeInfo },
  [StageType.execPipeline]: { typeInfo: execPipelineTypeInfo },
  [StageType.typeScript]: { typeInfo: typescriptTypeInfo },
};

export const stagePropertyConfiguration: IPropertyConfiguration = {
  type: 'pipeline',
  allInfos: stageInfos,
  propertiesPath: '',
  namePath: '_name',
  typePath: '_stageType',
  getPropertyInfosPath(type) {
    return `${type}.typeInfo.properties`;
  },
  typeEnum: StageType,
};
