import { EditorType } from 'universal/types';

import CustomMonacoEditor from './CustomMonacoEditor';

function JavascriptEditor({ updateField, value, identifier }) {
  return (
    <CustomMonacoEditor
      language="javascript"
      updateField={updateField}
      value={value}
      identifier={identifier}
      height={450}
    />
  );
}

function GraphQLEditor({ updateField, value, identifier }) {
  return (
    <CustomMonacoEditor
      language="graphql"
      updateField={updateField}
      value={value}
      identifier={identifier}
      height={200}
    />
  );
}

export const specialPropertyEditors = {
  [EditorType.GraphQL]: GraphQLEditor,
  [EditorType.Javascript]: JavascriptEditor,
};
