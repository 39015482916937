import _ from 'lodash';

import { CHUNK_ID } from './metadataSupportConstants';
import { getEnumValues, sha1HashHex } from './utilityFunctions';

export interface ISizeClass {
  numberOfBuckets: number;
  maxNumberOfRecords: number;
}

export interface ISizeClassProperties {
  [fsClass: string]: ISizeClass;
}

// This should match
// configurations/system/dataDefs/TypeDefinition/SizeClass.yml
export enum SizeClass {
  Class10 = 'CLASS_10',
  Class15 = 'CLASS_15',
}

export const sizeClassGeneratorBase = 16;
// If this is changed the hash in getChunkId must be changed to a different base.
// If this is smaller there will be more classes.

export const sizeClassGeneratorNumber = {
  [SizeClass.Class10]: 1,
  [SizeClass.Class15]: 2,
};

export const MAX_NUMBER_OF_RECORDS_IN_MEMORY = 2000;

function generateSizeClassProperties(): ISizeClassProperties {
  const fsClassProperties: ISizeClassProperties = {};

  const maxBucketSize = 16000;

  getEnumValues(SizeClass).forEach((sizeClass) => {
    const numberOfBuckets =
      sizeClassGeneratorBase ** sizeClassGeneratorNumber[sizeClass];

    fsClassProperties[sizeClass] = {
      numberOfBuckets,
      maxNumberOfRecords: numberOfBuckets * maxBucketSize,
    };
  });

  return fsClassProperties;
}

export const sizeClassProperties = generateSizeClassProperties();

export function getSizeClass({
  numberOfRecords,
}: {
  numberOfRecords: number;
}): SizeClass | undefined {
  if (numberOfRecords <= MAX_NUMBER_OF_RECORDS_IN_MEMORY) return null;
  const sizeClassesArray = Object.entries(sizeClassProperties).map((entry) => {
    const sizeClass = entry[0] as SizeClass;
    const properties = entry[1];

    return Object.assign({ sizeClass } as any, properties);
  });

  const filteredClasses = sizeClassesArray.filter(
    ({ maxNumberOfRecords }) => numberOfRecords <= maxNumberOfRecords,
  );

  const matchingClass =
    _.minBy(filteredClasses, 'maxNumberOfRecords') ||
    sizeClassesArray[sizeClassesArray.length - 1];

  return matchingClass.sizeClass;
}

export function getChunkId({
  id,
  sizeClass,
}: {
  id: string | number;
  sizeClass: SizeClass;
}): number {
  // Also see configurations/system/dataDefs/TypeDefinition/SizeClass.yml
  //
  // class 10:
  // numberOfBuckets = 16
  // 2000 < numberOfRecords <= 256,000
  // 125 < bucketSize <= 16000
  //
  // class 15:
  // numberOfBuckets = 256
  // 256,000 < numberOfRecords <= 4,096,000
  // 1000 < bucketSize <= 16000

  const defaultChunkId = 0;

  if (!sizeClass) {
    return defaultChunkId;
  }

  const idHash = sha1HashHex(id.toString());

  const chunkId = parseInt(
    idHash.slice(0, sizeClassGeneratorNumber[sizeClass]),
    sizeClassGeneratorBase,
  );

  return chunkId;
}

export function getChunkIdFieldName(fieldName?: string) {
  if (!fieldName) {
    return CHUNK_ID;
  } else {
    return `_${fieldName}${CHUNK_ID}`;
  }
}
