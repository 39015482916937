import withStyles from '@mui/styles/withStyles';
import { Component } from 'react';
import { JSONTree } from 'react-json-tree';
import { compose } from 'redux';

import { withActions } from '../../widgetEngine/ActionEnabler';
import Widget from '../../widgetEngine/Widget';

const styles = {};

const treeTheme = {
  base00: '#fafafa', // background
  base01: '#202020',
  base02: '#303030',
  base03: '#505050',
  base04: '#b0b0b0',
  base05: '#d0d0d0',
  base06: '#e0e0e0',
  base07: '#ffffff',
  base08: '#eb008a',
  base09: '#f29333',
  base0A: '#f8ca12',
  base0B: '#af3f00', // values
  base0C: '#00aabb',
  base0D: '#1f8c98', // keys
  base0E: '#b31e8d',
  base0F: '#7a2d00',
};

class Tree extends Component {
  public declare props;
  public declare state;
  constructor(props) {
    super(props);
    this.labelRenderer = this.labelRenderer.bind(this);
    this.valueRenderer = this.valueRenderer.bind(this);
  }

  componentDidMount() {
    this.mapChildren();
  }

  // label/value parent/node string
  mapChildren() {
    const { childWidgets } = this.props;
    const childMap = { label: {}, value: {} };
    childWidgets.forEach((child) => {
      const { properties } = child;

      ['node', 'parentNode'].forEach((level) => {
        if (properties[level]) {
          if (!childMap[properties.labelValue][level]) {
            childMap[properties.labelValue][level] = {};
          }
          childMap[properties.labelValue][level][properties[level]] = child;
        }
      });
    });

    this.setState({ childMap });
  }

  labelRenderer(keyPath) {
    const { passThroughProps, aliases } = this.props;
    const {
      childMap: { label },
    } = this.state;

    if (label.node && label.node[keyPath[0]]) {
      return (
        <Widget
          {...{
            component: label.node[keyPath[0]],
            passThroughProps,
            aliases,
          }}
        />
      );
    }

    if (label.parentNode && label.parentNode[keyPath[1]]) {
      return (
        <Widget
          {...{
            component: label.parentNode[keyPath[1]],
            passThroughProps,
            aliases,
          }}
        />
      );
    }

    return keyPath[0];
  }

  // the value string is the only argument
  valueRenderer(value) {
    return value;
  }

  render() {
    const { childMap } = this.state;
    if (!childMap) {
      return null;
    }
    // const { json } = this.props;
    // const data = _.get(context, json);

    return (
      <JSONTree
        hideRoot
        // data={data}
        theme={treeTheme}
        invertTheme={false}
        getItemString={() => null}
        labelRenderer={this.labelRenderer}
        valueRenderer={this.valueRenderer}
      />
    );
  }
}

export default compose(
  withActions,
  withStyles(styles, { withTheme: true }),
)(Tree);
