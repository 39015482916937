import { IConfigurationType } from '../types';

export const properties = {
  chartOptions: {
    description: 'Can put any chart option data here',
  },
} as const;

export const agChartTypeInfo: IConfigurationType = {
  childTypes: {},
  types: {
    children: true,
  },
  properties,
  events: {
    load: [],
  },
};
