import YAML from 'js-yaml';
import safeJsonStringify from 'safe-json-stringify';

import { ClientManager } from '../clientManager';
import {
  getBranchNames,
  getDirContentsFullRepo,
  getFileContentsFullRepo,
  getFullRepoForBranch,
  IFile,
  IFileInfo,
} from '../common/github';
import { reThrow } from '../errors/errorLog';
import { getErrorString } from '../errors/errorString';
import { isNotFoundError } from '../errors/retry';
import { getLogger, Loggers } from '../loggerSupport';
import { MetadataSupport } from '../metadataSupport';
import {
  CODE,
  CONFIG_NAME,
  DIRECTIVE_OVERWRITE,
  IConfigItem,
  IConfigItemsByType,
  KEY_FIELD,
  TEXT_DATA_ATTRIBUTE,
} from '../metadataSupportConstants';
import { StackInfoKeys } from '../stackInfo';

import {
  APP_DEFS_DIR,
  DATA_DEFS_DIR,
  getConfigurationPaths,
  getEntityTypeForRecordType,
  getFullConfigRepoForBranch,
  ICodeTypeConfigItem,
  parseConfigPath,
  updateConfigItemsToStackInfo,
} from './loadstore';

const logger = getLogger({ name: Loggers.LOAD_STORE });

export async function loadAllConfigurations(params: {
  githubToken: string;
  branchName: string;
  fullRepo?: any;
  configNamesToLoad?: Set<string>;
  dataConfigItems?: IConfigItemsByType;
  appConfigItems: IConfigItemsByType;
}): Promise<string[]> {
  const {
    githubToken,
    branchName,
    fullRepo = await getFullRepoForBranch(params),
    configNamesToLoad,
    dataConfigItems,
    appConfigItems,
  } = params;

  const configPaths = await getConfigurationPaths({
    githubToken,
    configNames: configNamesToLoad,
    branchName,
    fullRepo,
  });

  const loadedConfigNames = new Set();
  for (const path of configPaths) {
    loadedConfigNames.add(path.split('/')[1]);
    if (!dataConfigItems && path.includes(DATA_DEFS_DIR)) {
      continue;
    }
    const configItems = path.includes(APP_DEFS_DIR)
      ? appConfigItems
      : dataConfigItems;
    await loadConfiguration({
      githubToken,
      path,
      branchName,
      fullRepo,
      configItems,
      updateDatabase: false,
    });
  }

  return Array.from(loadedConfigNames) as string[];
}

export async function loadConfiguration(params: {
  githubToken: string;
  branchName: string;
  path: string;
  configItems?: { [recordType: string]: IConfigItem[] };
  fullRepo?: any;
  updateDatabase?: boolean;
  clientManager?: ClientManager;
}) {
  const {
    githubToken,
    branchName,
    path,
    fullRepo = await getFullConfigRepoForBranch({
      ...params,
      clientManager: params.clientManager,
    }),
    updateDatabase = true,
    configItems = {},
    clientManager,
  } = params;

  const diplayConfigPath = path.split('/').slice(1).join('/');

  logger.info(`loading config =======>     ${diplayConfigPath}     <=======`);

  if (typeof branchName !== 'string') {
    throw new Error(`Invalid branch name: ${safeJsonStringify(branchName)}`);
  }

  const existingBranches = await getBranchNames({
    githubToken,
  });

  if (!existingBranches.includes(branchName)) {
    throw new Error(`Branch ${branchName} does not exist`);
  }

  logger.info('getting config files from github');

  const rawConfigFiles = await getConfigFilesFromGithub({
    branchName,
    path,
    fullRepo,
  });

  rawConfigFiles
    .filter(({ contents }) => contents !== '')
    .forEach((rawFile) => convertFileToConfigItem({ rawFile, configItems }));

  if (updateDatabase) {
    path.includes(APP_DEFS_DIR) &&
      (await updateConfigItemsToStackInfo({
        clientManager,
        stackInfoKey: StackInfoKeys.APP_DEFS,
        configItems,
      }));

    await putConfigInDatabase({ clientManager, configItems });
  }

  logger.info(`${diplayConfigPath} config load complete`);
}

async function getConfigFilesFromGithub(params: {
  path: string;
  branchName: string;
  fullRepo: any;
}): Promise<IFile[]> {
  const { fullRepo } = params;

  const fileInfos: IFileInfo[] = await getConfigFileInfosFromGithub(params);

  logger.info(`getting ${fileInfos.length} files from github`);

  const files = [];

  for (const fileInfo of fileInfos) {
    const { path: filePath } = fileInfo;

    const fileContents = await getFileContentsFullRepo({
      fullRepo,
      path: filePath,
    });

    files.push({
      contents: fileContents,
      path: filePath,
    });
  }

  return files;
}

export async function getConfigFileInfosFromGithub(params: {
  path: string;
  branchName: string;
  fullRepo: any;
  repoName?: string;
  computeHash?: boolean;
}): Promise<IFileInfo[]> {
  const { path, branchName, repoName, fullRepo, computeHash } = params;

  const commonParams = {
    branchName,
    repoName,
    fullRepo,
  };

  let dirInfos: any[] = [];

  logger.info(`getting config ${path} from github`);

  try {
    dirInfos = await getDirContentsFullRepo({
      path,
      ...commonParams,
    });
  } catch (error) {
    if (isNotFoundError(error)) {
      return [];
    }
  }

  const allFileInfos: IFileInfo[] = [];

  for (const dirInfo of dirInfos) {
    const { name, type } = dirInfo;

    const subDirPath = `${path}/${name}`;

    if (type !== 'dir') {
      continue;
    }

    const fileInfos = await getDirContentsFullRepo({
      path: subDirPath,
      ...commonParams,
      computeHash,
    });

    allFileInfos.push(...fileInfos);
  }

  allFileInfos
    .filter((fileInfo) => fileInfo.type !== 'file')
    .forEach((fileInfo) =>
      logger.warn(
        `${fileInfo.path} is a directory and is currently ignored, vote for WORM-1346`,
      ),
    );

  return allFileInfos.filter((fileInfo) => fileInfo.type === 'file');
}

export function convertFileToConfigItem({
  rawFile,
  configItems,
}: {
  rawFile: IFile;
  configItems?: { [recordType: string]: IConfigItem[] };
}) {
  const { contents, path } = rawFile;

  const { configName, recordName, recordType } = parseConfigPath(path);

  const qualifiedRecordType = MetadataSupport.getQualifiedName(
    recordType,
    configName,
  );

  let configItemArray = configItems?.[qualifiedRecordType];
  if (!configItemArray) {
    configItemArray = [];
    if (configItems) {
      configItems[qualifiedRecordType] = configItemArray;
    }
  }

  let configItem;
  try {
    configItem = convertConfigFromYml({
      ...rawFile,
      contents,
    });
  } catch (error) {
    reThrow({
      logger,
      error,
      message: `Error converting ${qualifiedRecordType} - ${recordName} from YAML`,
    });
  }

  // This is what we get back from the YAML parse if it's not YAML, which can be OK for icons and such
  if (typeof configItem === 'string') {
    configItem = { [TEXT_DATA_ATTRIBUTE]: configItem };
  }

  if (recordType === CODE) {
    const codeType: ICodeTypeConfigItem = configItem as any;
    const { codes } = codeType;

    codes?.forEach((code) => {
      configItemArray.push(
        Object.assign(code, {
          [CONFIG_NAME]: configName,
        }),
      );
    });
    delete codeType.codes;
  }

  configItemArray.push(
    Object.assign(configItem, {
      [KEY_FIELD]: MetadataSupport.getQualifiedName(recordName, configName),
      [CONFIG_NAME]: configName,
    }),
  );
}

export function convertConfigFromYml(file: IFile): IConfigItem {
  const { contents, path } = file;

  if (path.includes('createTestData') || path.includes('lib')) {
    //@ts-ignore
    return {};
  }

  let configItem;

  try {
    configItem = YAML.load(contents);
  } catch (error) {
    throw new Error(
      `Error parsing YAML at path: ${path} , ${getErrorString(error)}`,
    );
  }

  return configItem || {};
}

export async function putConfigInDatabase(params: {
  clientManager: ClientManager;
  configItems: { [recordType: string]: IConfigItem[] };
  updateCustomerMetadata?: boolean;
}) {
  const { configItems, clientManager, updateCustomerMetadata } = params;

  const mutationDirectives = `@${DIRECTIVE_OVERWRITE}`;

  const promises = [];

  for (const k in configItems) {
    const entity = getEntityTypeForRecordType({
      clientManager,
      recordType: k,
    });

    // The configName is automatically provided from the loading from the source, but some entity types
    // don't want it.
    if (
      !entity.typeDefinitionObject
        .getAttributes()
        .find((attr) => attr.name === CONFIG_NAME)
    ) {
      configItems[k].forEach((item) => {
        delete item.configName;
      });
    }

    if (updateCustomerMetadata) {
      if (entity.isConfiguration) {
        continue;
      }
      if (!entity.includeConfigNameInId) {
        configItems[k].map(
          (ci) => (ci.id = MetadataSupport.getUnqualifiedName(ci.id)),
        );
      }
    } else {
      if (!entity.isConfiguration) {
        continue;
      }
    }
    const upsert = entity.useUpsertOnConfigLoad;
    logger.info(
      `Writing ${k} config: ${entity.isConfiguration} - ${configItems[k].length} records`,
    );
    promises.push(
      clientManager.pipelineManager
        .createRecords({
          mutationDirectives,
          entityName: entity.id,
          configName: entity.configName,
          upsert,
          records: configItems[k],
        })
        .catch((error) => {
          reThrow({
            logger,
            error,
            logObject: configItems[k],
            message: `Problem adding configuration information to the database: ${k}`,
          });
        }),
    );
  }

  await Promise.all(promises);
}
