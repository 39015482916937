import { process } from '@progress/kendo-data-query';
import { ExcelExportColumn } from '@progress/kendo-react-excel-export';
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import classNames from 'classnames';
import { produce } from 'immer';
import _ from 'lodash';
import styled from 'styled-components';
import { ClientManager } from 'universal/clientManager';
import { TYPE_NAME } from 'universal/metadataSupportConstants';
import { AccessType } from 'universal/permissionManager';

import {
  compileProperties,
  compileProperty,
  createWidget,
  setupWidget,
  shouldRender,
  widgetCan,
} from '../../../util/widgetUtilities';
import { IWidget } from '../types';

import { buildCell, cellTypes } from './KendoCell';
import buildExportCell, { exportCellTypes } from './KendoExportCell';
import { KendoGridProps } from './KendoTypes';

export function processRows(
  rows = [],
  dataState,
  mergableColumnFields,
  scrollable,
  paged,
) {
  const processedRows = process(rows, dataState);

  if (scrollable !== 'virtual' && !paged) {
    processedRows.data = findMergableRows(
      processedRows.data,
      mergableColumnFields,
    );
  }

  return processedRows;
}

export function addAggregationToDataState(
  kendoAggregates,
  columnFieldAggregates,
  dataState,
) {
  const aggregates = kendoAggregates.map((kendoAggregate) => {
    const {
      matchProperties: { aggregate, field },
    } = kendoAggregate;
    return { aggregate, field };
  });

  const allAggregates = [...aggregates, ...columnFieldAggregates];

  return {
    ...dataState,
    group:
      dataState.group &&
      dataState.group.map((group) => ({
        ...group,
        aggregates: allAggregates,
      })),
  };
}

export function extractConfigurationFromColumns(kendoColumns) {
  return kendoColumns.reduce(
    (acc, column) => {
      if (column.kendoColumns.length) {
        const { mergableColumnFields, columnFieldAggregates } =
          extractConfigurationFromColumns(column.kendoColumns);
        return {
          mergableColumnFields: [
            ...acc.mergableColumnFields,
            ...mergableColumnFields,
          ],
          columnFieldAggregates: [
            ...acc.columnFieldAggregates,
            ...columnFieldAggregates,
          ],
        };
      }
      if (column.compiledProperties.field) {
        const { field, mergeLikeCells, aggregate } = column.compiledProperties;

        const nextMergableColumnFields = mergeLikeCells
          ? [...acc.mergableColumnFields, field]
          : acc.mergableColumnFields;

        const nextColumnFieldAggregates = aggregate
          ? [...acc.columnFieldAggregates, { field, aggregate }]
          : acc.columnFieldAggregates;
        return {
          ...acc,
          mergableColumnFields: nextMergableColumnFields,
          columnFieldAggregates: nextColumnFieldAggregates,
        };
      }
      return acc;
    },
    { mergableColumnFields: [], columnFieldAggregates: [] },
  );
}

export function hasEachProperty(object, properties) {
  return properties.reduce((acc, property) => {
    return acc && object[property];
  }, true);
}

export function findMergableRows(groupedRows, mergableColumnFields) {
  if (
    groupedRows.length &&
    hasEachProperty(groupedRows[0], ['aggregates', 'field', 'items', 'value'])
  ) {
    return groupedRows.map((group) => ({
      ...group,
      items: findMergableRows(group.items, mergableColumnFields),
    }));
  }
  const spans = {};
  groupedRows.forEach((row, rowIndex) => {
    const lastRow = !groupedRows[rowIndex + 1];
    mergableColumnFields.forEach((field) => {
      if (!spans[field]) {
        spans[field] = { value: _.get(row, field), row, spanLength: 1 };
      } else if (spans[field].value === _.get(row, field)) {
        // value is equal, sets the row to skip this field, and adds a spanLength to the spanRow
        spans[field].spanLength++;
        if (!row._skips) {
          row._skips = {};
        }
        row._skips[field] = true;
      }

      if (lastRow || spans[field].value !== _.get(row, field)) {
        if (spans[field].spanLength > 1) {
          if (!spans[field].row._spans) {
            spans[field].row._spans = {};
          }
          spans[field].row._spans[field] = spans[field].spanLength;
        }
        if (!lastRow) {
          spans[field] = { value: _.get(row, field), row, spanLength: 1 };
        }
      }
    });
  });
  return groupedRows;
}

const buildPaddingString = (props) => {
  const { padding, paddingSides } = props;

  let paddingString;
  if (padding && paddingSides) {
    paddingString = `${padding} ${paddingSides}`;
  } else if (padding) {
    paddingString = padding;
  } else if (paddingSides) {
    paddingString = `10px ${paddingSides}`;
  }
  return paddingString;
};

export const StyledGrid = styled(Grid)`
  td {
    ${(props) => {
      const paddingString = buildPaddingString(props);
      return { ...(paddingString && { padding: paddingString }) };
    }}
    border-style: ${(props) => props.cellBorder};
    border-width: ${(props) => props.cellBorderWidth};
    border-color: ${(props) => props.cellBorderColor};
  }
  th {
    ${(props) => {
      const paddingString = buildPaddingString(props);
      return { ...(paddingString && { padding: paddingString }) };
    }}
  }
  td.alignRight {
    text-align: right;
  }
  th.alignRight {
    text-align: right;
  }
  td.alignCenter {
    text-align: center;
  }
  th.alignCenter {
    text-align: center;
  }
  & .k-grid td {
    text-align: initial;
    color: green;
  }
  & .k-grouping-header {
    background-color: ${({ groupingHeaderColor }) => groupingHeaderColor};
  }
  & .k-grid-header {
    tr {
      background-color: ${({ headerColor }) => headerColor};
      &:hover {
        background-color: ${({ headerColor }) => headerColor};
      }
    }
    & .k-filter-row {
      ${({ filterRowColor }) =>
        filterRowColor && `background-color: ${filterRowColor}`};
      &:hover {
        ${({ filterRowColor }) =>
          filterRowColor && `background-color: ${filterRowColor}`};
      }
    }
    & .k-grid-header-sticky {
      background-color: ${({ lockedHeaderColor, headerColor }) =>
        lockedHeaderColor || headerColor};
    }
  }
  & .k-grid-content {
    & tbody {
      & .k-grouping-row {
        background-color: ${({ groupRowColor }) => groupRowColor};
      }
      .k-master-row {
        &:not(.k-alt) {
          background-color: ${({ oddRowColor }) => oddRowColor};
          & .k-grid-content-sticky {
            background-color: ${({ oddRowColor }) => oddRowColor};
          }
        }
        &.k-alt {
          background-color: ${({ scrollable, oddRowColor, evenRowColor }) =>
            scrollable === 'virtual' ? oddRowColor : evenRowColor};
          & .k-grid-content-sticky {
            background-color: ${({ scrollable, oddRowColor, evenRowColor }) =>
              scrollable === 'virtual' ? oddRowColor : evenRowColor};
          }
        }
        &:hover {
          ${({ hoverRowColor }) => ({
            ...(hoverRowColor && { 'background-color': hoverRowColor }),
          })}
          ${({ hoverRowColor }) => ({
            ...(!hoverRowColor && { filter: 'brightness(85%)' }),
          })}
            & .k-grid-content-sticky {
            ${({ hoverRowColor }) => ({
              ...(hoverRowColor && { 'background-color': hoverRowColor }),
            })}
            ${({ hoverRowColor }) => ({
              ...(!hoverRowColor && { filter: 'brightness(85%)' }),
            })}
          }
        }
      }
    }
  }
`;

export function createColumnPathEntry(entry) {
  if (typeof entry === 'number') {
    return entry;
  }
  const dot = entry.lastIndexOf('.');
  if (dot > 0) {
    return entry.slice(dot + 1);
  }
  return entry;
}

export function processStaticColumns(
  templatesByName,
  context,
  kendoColumns,
  cellAccessors,
  columnAliases,
  columnPath = [],
) {
  const resolvedColumns = kendoColumns.reduce(
    (finishedColumns, kendoColumn) => {
      const {
        properties,
        name,
        matchProperties: { dynamicColumns: dynamicColumnField },
      } = kendoColumn;

      const compiledProperties = compileProperties(properties, context);

      const {
        field,
        cellInstanceName,
        duplicateBy,
        instanceName,
        title,
        dynamicColumns = [],
        dynamicColumnsInstanceName,
        cellPropertyAccessor,
      } = compiledProperties;

      const nextColumnContext = {
        ...context,
        _widget: {
          ...context._widget,
          _column: { field, title },
          ...(instanceName && {
            [instanceName]: field,
          }),
        },
      };

      const childCellAccessors = produce(cellAccessors, (draft) => {
        if (field && cellInstanceName) {
          draft[cellInstanceName] = field;
        }
        if (cellPropertyAccessor) {
          draft._cellProperties = cellPropertyAccessor;
        }
      });

      const childColumnPath = columnPath.concat(
        `_${createColumnPathEntry(field || title || name)}`,
      );

      if (duplicateBy && Array.isArray(duplicateBy)) {
        return finishedColumns.concat(
          processDynamicColumns(
            templatesByName,
            context,
            kendoColumn,
            null,
            duplicateBy,
            instanceName,
            childCellAccessors,
            columnAliases,
            childColumnPath,
          ),
        );
      }

      const resolvedNestedColumns = processStaticColumns(
        templatesByName,
        nextColumnContext,
        kendoColumn.kendoColumns,
        childCellAccessors,
        columnAliases,
        childColumnPath,
      );

      const resolvedNestedDynamicColumns = processDynamicColumns(
        templatesByName,
        nextColumnContext,
        null,
        dynamicColumnField,
        dynamicColumns,
        dynamicColumnsInstanceName,
        cellAccessors,
        columnAliases,
      );

      return finishedColumns.concat({
        ...kendoColumn,
        columnContext: nextColumnContext,
        compiledProperties,
        kendoColumns: [
          ...resolvedNestedColumns,
          ...resolvedNestedDynamicColumns,
        ],
        cellAccessors: childCellAccessors,
        columnAliases,
        columnPath: childColumnPath,
      });
    },
    [],
  );

  return resolvedColumns;
}

export function processDynamicColumns(
  templatesByName,
  context,
  kendoColumn,
  dynamicColumnField,
  duplicateBy,
  instanceName,
  cellAccessors,
  columnAliases,
  columnPath = [],
) {
  const processedColumns = duplicateBy.map((instance, index) => {
    const columnTemplate =
      kendoColumn ||
      (instance.template && templatesByName[instance.template]) ||
      templatesByName[dynamicColumnField] ||
      templatesByName.defaultTemplate;

    const {
      kendoColumns: nestedKendoColumns = [],
      matchProperties: templateMatchProperties,
      name,
    } = columnTemplate;

    const columnContext = {
      ...context,
      ...instance,
      _widget: {
        ...context._widget,
        ...(instanceName && {
          [instanceName]: instance,
        }),
      },
    };

    const compiledProperties = compileProperties(
      columnTemplate.properties,
      columnContext,
    );

    const {
      duplicateBy: duplicateByFieldName,
      dynamicColumns: childDynamicColumnField,
    } = templateMatchProperties;

    const duplicatorField = dynamicColumnField || duplicateByFieldName;

    const {
      field,
      title,
      cellInstanceName,
      dynamicColumns = [],
      dynamicColumnsInstanceName,
      cellPropertyAccessor,
    } = compiledProperties;

    const nextColumnContext = {
      ...columnContext,
      _widget: {
        ...columnContext._widget,
        _column: { field, title },
      },
    };
    delete nextColumnContext.dynamicColumns;

    const childCellAccessors = produce(cellAccessors, (draft) => {
      if (field && cellInstanceName) {
        draft[cellInstanceName] = field;
      }
      if (cellPropertyAccessor) {
        draft._cellProperties = cellPropertyAccessor;
      }
    });

    const childColumnAliases = instanceName
      ? {
          ...columnAliases,
          [instanceName]: duplicatorField.startsWith('_widget.')
            ? [..._.toPath(duplicatorField.slice(8)), index]
            : [..._.toPath(duplicatorField), index],
        }
      : columnAliases;

    const childColumnPath = columnPath.concat(
      `_${createColumnPathEntry(field || title || name)}`,
    );

    const resolvedNestedKendoColumns = processStaticColumns(
      templatesByName,
      nextColumnContext,
      nestedKendoColumns,
      childCellAccessors,
      childColumnAliases,
      childColumnPath,
    );

    const resolvedNestedDynamicColumns = processDynamicColumns(
      templatesByName,
      nextColumnContext,
      null,
      childDynamicColumnField,
      dynamicColumns,
      dynamicColumnsInstanceName,
      childCellAccessors,
      childColumnAliases,
    );

    return {
      ...columnTemplate,
      kendoColumns: [
        ...resolvedNestedKendoColumns,
        ...resolvedNestedDynamicColumns,
      ],
      compiledProperties,
      columnContext: nextColumnContext,
      cellAccessors: childCellAccessors,
      columnAliases: childColumnAliases,
      columnPath: childColumnPath,
    };
  });

  return processedColumns;
}

export function addColumnsFromEntityType(
  clientManager: ClientManager,
  props: KendoGridProps,
): IWidget[] {
  const {
    entityType,
    component: { kendoColumns, configName },
  } = props;
  if (!entityType) {
    return kendoColumns;
  }
  const typeDefColumns: IWidget[] = [];

  const typeDef = clientManager.metadataSupport.getTypeInfoFromEntity({
    entityName: entityType,
    configName,
  }).typeDef;

  const { presentationInfo } = typeDef;
  typeDef.getAttributes().forEach((attr) => {
    const labelAttr = presentationInfo?.label === attr.name;
    if (!(attr.presentationInfo?.includeAsListColumn || labelAttr)) {
      return;
    }

    const column = createWidget({
      name: attr.name,
      type: 'KendoColumn',
      parentType: 'KendoGrid',
      configName,
    });
    column.properties = {
      field: `'${attr.name}'`,
      title: `'${attr.nameInfo.displayName}'`,
    };
    column.kendoColumns = [];
    column.kendoCells = [];
    setupWidget(column);
    compileProperties(column.properties, props.context);
    typeDefColumns.push(column);
  });

  return [...typeDefColumns, ...kendoColumns];
}

export function processColumns(
  context,
  kendoColumns,
  templates = [],
  { dynamicColumns = [], dynamicColumnField, dynamicColumnsInstanceName },
  cellAccessors = {},
  columnAliases = {},
) {
  const templatesByName = templates.reduce(
    (acc, template) => Object.assign(acc, { [template.name]: template }),
    {},
  );
  const resolvedColumns = processStaticColumns(
    templatesByName,
    context,
    kendoColumns,
    cellAccessors,
    columnAliases,
  );

  const resolvedDynamicColumns = processDynamicColumns(
    templatesByName,
    context,
    null,
    dynamicColumnField,
    dynamicColumns,
    dynamicColumnsInstanceName,
    cellAccessors,
    columnAliases,
  );
  return [...resolvedColumns, ...resolvedDynamicColumns];
}

export function buildExportColumns(
  exportColumns,
  allCurrentRows,
  getPermission,
  clientManager: ClientManager,
) {
  // we only want to do this work once
  const customAggregator = buildCustomAggregator(clientManager);
  const getFieldType = buildGetFieldType(clientManager, allCurrentRows);

  return buildExportColumnsRecurse(
    exportColumns,
    allCurrentRows,
    getPermission,
    customAggregator,
    getFieldType,
  );
}

export function buildExportColumnsRecurse(
  exportColumns,
  allCurrentRows,
  getPermission,
  customAggregator,
  getFieldType,
) {
  return exportColumns.reduce((acc, column) => {
    const {
      compiledProperties: compiledColumnProperties,
      kendoColumns: nestedKendoColumns = [],
      kendoCells,
      columnContext,
      columnAliases,
    } = column;

    const { field, title, permissionId, render, preventRender } =
      compiledColumnProperties;

    if (
      permissionId &&
      !widgetCan(AccessType.Read, getPermission(permissionId))
    ) {
      return acc;
    }

    if (!shouldRender({ render, preventRender })) {
      return acc;
    }

    const filteredColumnProperties = _.omitBy(
      compiledColumnProperties,
      (value) => value === undefined,
    );

    const nestedColumns = buildExportColumnsRecurse(
      nestedKendoColumns,
      allCurrentRows,
      getPermission,
      customAggregator,
      getFieldType,
    );

    const hasOwnField = field !== undefined;

    const customCells = kendoCells.reduce(
      (accCustom, cell) =>
        Object.assign(accCustom, { [cell.matchProperties.cellType]: cell }),
      {},
    );

    return acc.concat(
      <ExcelExportColumn
        {...filteredColumnProperties}
        locked={false}
        {...(hasOwnField && {
          field: field.toString(),
        })}
        {...(customCells.groupFooter &&
          buildExportCell({
            cellType: exportCellTypes.groupCell,
            cell: customCells.groupFooter,
            context: columnContext,
            columnAliases,
            columnField: field,
            columnTitle: title,
            customAggregator,
            allRows: allCurrentRows,
          }))}
        {...(customCells.cell &&
          buildExportCell({
            cellType: exportCellTypes.cell,
            cell: customCells.cell,
            context: columnContext,
            columnAliases,
            columnField: field,
            columnTitle: title,
          }))}
        {...(customCells.footerCell &&
          buildExportCell({
            cellType: exportCellTypes.footerCell,
            cell: customCells.footerCell,
            context: columnContext,
            columnAliases,
            columnField: field,
            columnTitle: title,
            customAggregator,
            allRows: allCurrentRows,
          }))}
      >
        {nestedColumns}
      </ExcelExportColumn>,
    );
  }, []);
}

// takes meta columns, and returns JSX columns for KendoGrid to consume
export function buildColumns(
  formPath,
  kendoColumns,
  allCurrentRows,
  aliases,
  passThroughProps,
  rowInstanceName,
  rowGetter,
  classes,
  rowHeight,
  footerHeight,
  getPermission,
  clientManager: ClientManager,
) {
  // we only want to do this work once
  const customAggregator = buildCustomAggregator(clientManager);
  const getFieldType = buildGetFieldType(clientManager, allCurrentRows);

  return buildColumnsRecurse(
    formPath,
    kendoColumns,
    allCurrentRows,
    aliases,
    passThroughProps,
    rowInstanceName,
    rowGetter,
    classes,
    rowHeight,
    footerHeight,
    getPermission,
    customAggregator,
    getFieldType,
  );
}

function buildColumnsRecurse(
  formPath,
  kendoColumns,
  allCurrentRows,
  aliases,
  passThroughProps,
  rowInstanceName,
  rowGetter,
  classes,
  rowHeight,
  footerHeight,
  getPermission,
  customAggregator,
  getFieldType,
) {
  if (!kendoColumns.length) {
    return [];
  }

  return kendoColumns.reduce((acc, column, columnNumber) => {
    if (!column.compiledProperties) {
      throw new Error(
        'tried to build a column without compiled properties, it has not been resolved yet',
      );
    }

    const {
      compiledProperties: compiledColumnProperties,
      kendoColumns: nestedKendoColumns = [],
      kendoCells,
      columnContext,
      cellAccessors,
      columnAliases,
      columnPath,
    } = column;

    const {
      headerFontSize,
      defaultCellFontSize,
      headerVerticalAlign,
      field,
      align,
      title,
      type,
      permissionId,
      render,
      preventRender,
    } = compiledColumnProperties;

    const nestedColumns = buildColumnsRecurse(
      formPath,
      nestedKendoColumns,
      allCurrentRows,
      aliases,
      passThroughProps,
      rowInstanceName,
      rowGetter,
      classes,
      rowHeight,
      footerHeight,
      getPermission,
      customAggregator,
      getFieldType,
    );
    const customCells = kendoCells.reduce(
      (accCalls, cell) =>
        Object.assign(accCalls, { [cell.matchProperties.cellType]: cell }),
      {},
    );

    const hasOwnField = field !== undefined;
    const fieldType = hasOwnField && getFieldType(field);

    const fieldTypeIsNumeric = ['Int', 'number'].includes(fieldType);
    const shouldAlignRight =
      align === 'right' || (align === 'auto' && fieldTypeIsNumeric);
    const shouldAlignCenter = align === 'center';

    const cellClassNames = classNames(
      { alignRight: shouldAlignRight },
      { alignCenter: shouldAlignCenter },
      classes[defaultCellFontSize],
    );

    if (
      permissionId &&
      !widgetCan(AccessType.Read, getPermission(permissionId))
    ) {
      return acc;
    }

    if (!shouldRender({ render, preventRender })) {
      return acc;
    }

    let Cell: any = false;
    if (customCells.cell || customCells.groupFooter) {
      Cell = buildCell({
        cellType: cellTypes.cell,
        cell: customCells.cell,
        groupFooter: customCells.groupFooter,
        aliases,
        passThroughProps,
        rowGetter,
        rowInstanceName,
        formPath,
        className: cellClassNames,
        context: columnContext,
        cellAccessors,
        columnAliases,
        columnPath,
        rowHeight,
        columnField: field,
        columnTitle: title,
        allRows: allCurrentRows,
        customAggregator,
      });
    }

    let HeaderCell: any = false;
    if (customCells.headerCell) {
      HeaderCell = buildCell({
        cellType: cellTypes.headerCell,
        cell: customCells.headerCell,
        aliases,
        passThroughProps,
        rowGetter,
        className: cellClassNames,
        context: columnContext,
        columnAliases,
        columnPath,
        columnField: field,
        columnTitle: title,
        allRows: allCurrentRows,
        customAggregator,
      });
    }

    let FooterCell: any = false;
    if (customCells.footerCell) {
      FooterCell = buildCell({
        cellType: cellTypes.footerCell,
        cell: customCells.footerCell,
        aliases,
        passThroughProps,
        rowGetter,
        className: cellClassNames,
        context: columnContext,
        columnAliases,
        columnPath,
        footerHeight,
        columnField: field,
        columnTitle: title,
        allRows: allCurrentRows,
        customAggregator,
      });
    }

    const filteredColumnProperties = _.omitBy(
      compiledColumnProperties,
      (value) => value === undefined,
    );

    const { width, minWidth, ...allOtherProperties } = filteredColumnProperties;

    return acc.concat(
      // @ts-ignore
      <GridColumn
        minResizableWidth={minWidth}
        width={minWidth && (!width || width < minWidth) ? minWidth : width}
        key={field || title || columnNumber}
        className={cellClassNames}
        filter={type}
        headerClassName={classNames(
          { alignRight: shouldAlignRight },
          { alignCenter: shouldAlignCenter },
          classes[headerFontSize],
          classes[headerVerticalAlign],
        )}
        {...allOtherProperties}
        {...(hasOwnField && {
          field: field.toString(),
        })}
        {...(Cell && { cell: Cell })}
        {...(HeaderCell && { headerCell: HeaderCell })}
        {...(FooterCell && { footerCell: FooterCell })}
      >
        {nestedColumns}
      </GridColumn>,
    );
  }, []);
}

export function buildGetFieldType(clientManager: ClientManager, rows) {
  return (field) => {
    if (!(rows && rows.length)) {
      return false;
    }

    const typeName = rows?.[0][TYPE_NAME];

    if (typeName) {
      const typeDef = clientManager.schemaManager.getTypeDefinition({
        name: typeName,
        configName:
          clientManager.metadataSupport.getConfigNameFromUnqualifiedTypeDefName(
            typeName,
          ),
      });

      const fieldAttribute = typeDef
        .getAttributes()
        .find((attribute) => attribute.name === field);
      if (fieldAttribute) {
        return fieldAttribute.itemInfo.type;
      }
    }

    const rowWithFieldValue = rows.find(
      (row) => ![undefined, null].includes(row[field]),
    );
    if (rowWithFieldValue) {
      return typeof rowWithFieldValue[field];
    }
    // defaults to string if it can't figure out the type
    return 'string';
  };
}

export function flattenNestedItems(items) {
  if (items?.[0]?.items) {
    return items.reduce((acc, item) => {
      return acc.concat(flattenNestedItems(item.items));
    }, []);
  }

  return items;
}

export function buildCustomAggregator(clientManager: ClientManager) {
  const {
    pipelineManager: { javascriptStageFunctions },
  } = clientManager;

  return (functionName, input) => {
    if (!javascriptStageFunctions[functionName]) {
      throw new Error(
        `did not find a function in javascriptStageFunctions named ${functionName}`,
      );
    }

    return javascriptStageFunctions[functionName](input);
  };
}

export function getCellDisplay(displayProperty, cellContent, cellContext) {
  const displayContext = {
    ...cellContext,
    _widget: { ...cellContext._widget, cellContent },
  };

  const result = compileProperty('display', displayProperty, displayContext);
  if (typeof result === 'object') {
    return '...';
  }
  return result;
}

export function getExportMetaColumns(exportConfig, columnContext) {
  const {
    kendoColumnTemplates: exportKendoColumnTemplates,
    kendoColumns: exportKendoColumns,
    matchProperties: { dynamicColumns: exportDynamicColumnField },
  } = exportConfig;

  const {
    dynamicColumns: exportDynamicColumns,
    dynamicColumnsInstanceName: exportDynamicColumnsInstanceName,
  } = compileProperties(exportConfig.properties, columnContext);

  const exportMetaColumns = processColumns(
    columnContext,
    exportKendoColumns,
    exportKendoColumnTemplates,
    {
      dynamicColumnField: exportDynamicColumnField,
      dynamicColumns: exportDynamicColumns,
      dynamicColumnsInstanceName: exportDynamicColumnsInstanceName,
    },
  );

  return exportMetaColumns;
}

export const buildDataStateFromProps = (props) => ({
  ...(props.groups && {
    group: props.groups.split(',').map((field) => ({
      field: _.trim(field),
    })),
  }),
  ...(props.sortBy && {
    sort: props.sortBy.split(',').map((field) => ({
      field: _.trim(field),
      dir: 'asc',
    })),
  }),
});
