import _ from 'lodash';

import { ClientManager } from './clientManager';
import { IUser } from './userSupport';
import { stripAllButLettersAndNumbers as mangleOnlyLettersAndNumbers } from './utilityFunctions';

// import { Loggers, getLogger } from 'universal/loggerSupport';

export interface IScenarioParams {
  user: IUser;
  scenarioType: string;
  name?: string;
  description?: string;
  timePeriod?: number;
  defaults: any[];
  queryName?: string; // FIXME - Needed for GUI refresh until new caching is in
  entityName: string;
  fields: any;
}
const DEFAULT_SCENARIO_NAME = 'global';

// const logger = getLogger({ name: Loggers.SCENARIO_SUPPORT });

export class ScenarioSupport {
  public clientManager: ClientManager;

  public initialize(clientManager: ClientManager) {
    this.clientManager = clientManager;
  }
  public getDefaultScenario(scenarios) {
    return scenarios.find(
      (s) => s.nameInfo.displayName === DEFAULT_SCENARIO_NAME,
    );
  }
  public async createDefaultScenario(params: IScenarioParams) {
    const { entityName, defaults, user, scenarioType, timePeriod } = params;
    const scenarioRecord = Object.assign(
      {
        id: `${DEFAULT_SCENARIO_NAME}_${scenarioType}_${timePeriod}`,
        nameInfo: {
          displayName: DEFAULT_SCENARIO_NAME,
          shortDescription: 'Default scenario',
        },
        scenarioType: scenarioType,
        createdBy: user,
        timePeriod,
      },
      defaults,
    );
    await this.clientManager.pipelineManager.createRecord({
      entityName,
      record: scenarioRecord,
      upsert: true,
    });
    return scenarioRecord;
  }
  public async createScenario(params: IScenarioParams) {
    const { entityName, fields, user, scenarioType } = params;
    delete fields.id;
    const name = mangleOnlyLettersAndNumbers(fields.nameInfo.displayName);
    const scenarioRecord = Object.assign(
      {
        id: `${name}_${scenarioType}_${fields.timePeriod}`,
        scenarioType: scenarioType,
        createdBy: user,
      },
      fields,
    );
    const newScenario = await this.clientManager.pipelineManager.createRecord({
      entityName,
      record: scenarioRecord,
      upsert: true,
    });
    return newScenario.result;
  }
  public async updateScenario(params: IScenarioParams) {
    const { entityName, fields } = params;
    const record = _.cloneDeep(fields);
    await this.clientManager.pipelineManager.updateRecord({
      entityName,
      record,
    });
  }
  public mergeScenarios(params: { baseScenario: any; scenarioToMerge: any }) {
    const { baseScenario, scenarioToMerge } = params;
    const mergedScenario = {};
    const keys = [
      ...new Set([
        ...Object.keys(baseScenario),
        ...Object.keys(scenarioToMerge || {}),
      ]),
    ];
    for (const field of keys) {
      if (field === 'createdBy' || field === 'nameInfo' || field === 'public') {
        mergedScenario[field] = scenarioToMerge[field];
        continue;
      }
      if ([null, undefined].includes(baseScenario[field])) {
        mergedScenario[field] = scenarioToMerge[field];
      } else if (
        typeof baseScenario[field] === 'object' &&
        !Array.isArray(baseScenario[field])
      ) {
        mergedScenario[field] = this.mergeScenarios({
          baseScenario: baseScenario[field],
          scenarioToMerge: scenarioToMerge[field],
        });
      } else {
        mergedScenario[field] =
          scenarioToMerge && ![null, undefined].includes(scenarioToMerge[field])
            ? scenarioToMerge[field]
            : baseScenario[field];
      }
    }
    return mergedScenario;
  }
}
