import deepEqual from 'fast-deep-equal';
import { enableMapSet, produce } from 'immer';
import keyMirror from 'keymirror';
import _ from 'lodash';
import memoize from 'memoize-one';
import { BaseLogger } from 'pino';
import { Component, Fragment } from 'react';
import ReactDOM from 'react-dom';
import isEqual from 'react-fast-compare';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { compose } from 'redux';
import { reThrow } from 'universal/errors/errorLog';
import { getErrorString } from 'universal/errors/errorString';
import { LogLevels, Loggers, getLogger } from 'universal/loggerSupport';
import { findConfigItem } from 'universal/metadataSupport';
import { AccessType, PermissionType } from 'universal/permissionManager';
import { PipelineExecutor } from 'universal/pipeline/pipelineExecutor';
import { IStageProperties } from 'universal/pipeline/stage';
import { basicToHtml5 } from 'universal/types';
import diff from 'variable-diff';

import {
  IRawUpdate,
  clearContext,
  updateContext,
  updateContextBatch,
} from '../../store/data';
import { persistUIState } from '../../store/persistUIState';
import { store } from '../../store/store';
import {
  createSubscriptionMapSelector,
  getStoreState,
} from '../../store/storeSelectors';
import { isBrowserUnderTest, isTesting } from '../../util/environment';
import PipelineExternalAdaptor from '../../util/pipelineExternalAdaptor';
import { generateReport } from '../../util/reporting';
import {
  compileProperty,
  shouldRender,
  widgetCan,
} from '../../util/widgetUtilities';
import {
  IClientContext,
  clientContextFields,
  withClient,
} from '../../wrappers/ClientContext';
import Broken from '../atoms/Broken';
import Loading from '../atoms/Loading';
import AGChart from '../widgets/AGChart/AGChart';
import AGGrid from '../widgets/AGGrid/AGGrid';
import AttachmentButton from '../widgets/AttachmentButton/AttachmentButton';
import Button from '../widgets/Button';
import CardWidget from '../widgets/Card/Card';
import CheckboxWidget from '../widgets/CheckboxWidget';
import Chip from '../widgets/Chip';
import CollapseWidget from '../widgets/Collapse/CollapseWidget';
import DataVis from '../widgets/DataVis/DataVis';
import Display from '../widgets/Display/Display';
import DropdownSelect from '../widgets/DropdownSelect/DropdownSelect';
import FlexContainer from '../widgets/FlexContainer/FlexContainer';
import GridContainer from '../widgets/GridContainer/GridContainer';
import Icon from '../widgets/Icon/Icon';
import KendoExportButton from '../widgets/KendoGrid/KendoExportButton';
import KendoGrid from '../widgets/KendoGrid/KendoGrid';
import LayoutWidget from '../widgets/LayoutWidget/LayoutWidget';
import List from '../widgets/List/List';
import LogViewer from '../widgets/LogViewer';
import MenuItemWidget from '../widgets/MenuItemWidget/MenuItemWidget';
import MenuWidget from '../widgets/MenuWidget/MenuWidget';
import ModalWidget from '../widgets/ModalWidget';
import ModalWidgetStasis from '../widgets/ModalWidgetStasis';
import MultiSelect from '../widgets/MultiSelect/MultiSelect';
import OptionsOverlay from '../widgets/OptionsOverlay';
import PDFContainer from '../widgets/PDFContainer';
import Page from '../widgets/Page';
import PopupWidget from '../widgets/PopupWidget';
import RichText from '../widgets/RichTextEditor/RichText';
import Router from '../widgets/Router';
import SliderWidget from '../widgets/Slider/SliderWidget';
import Tabs from '../widgets/Tabs/Tabs';
import Text from '../widgets/Text/Text';
import Tree from '../widgets/Tree/Tree';
import TreeLink from '../widgets/TreeLink';
import Upload from '../widgets/Upload';
import {
  IAction,
  IElementAttributes,
  IEventBindingProps,
  IPassThroughProps,
  UpdateFromWidgetParams,
} from '../widgets/types';
import { configurationTypesDetails } from '../widgets/widgetTypes';

import Widget, { setEventBindingContainer } from './Widget';

const logger = getLogger({ name: Loggers.CLIENT });
const loggerData = getLogger({ name: Loggers.CLIENT_DATA });
const loggerReload = getLogger({ name: Loggers.CLIENT_RELOAD });

interface IState {
  initialLoadCompleted?: boolean;
  executingUpdatePipeline?: boolean;
  normalizedEvents?: {
    [key: string]: {
      stages: IStageProperties[];
    };
  };
  context?: any;
  hasError?: boolean;
  widgetState?: any;
  loadedActions?: { hover?: any; click?: any; rightClick?: any };
  executingActions?: { [key: string]: IAction };
  mouseTrigger?: any;
  click?: boolean;
  rightClick?: boolean;
  hover?: boolean;
  compiledProperties?: any;
  valid?: boolean;
  validationMessage?: string;
  formValidationReasonCodes?: any[];
  formValidationReasonStoreLocation?: string;
  childrenAreValid?: boolean;
  childrenMissingRequired?: string[];
  childValidity?: {
    [key: string]: { inputValid: boolean; missingRequired: boolean };
  };
  error?: { message?: string };
  info?: any;
  fromPipeline?: boolean;
  permission?: AccessType;
  rejectedFormSubmit?: boolean;
  formSubmitted?: boolean;
  localContext?: Record<string, any>;
}

// This is for non widgets that should be treated as widgets in the editor.
const graphConfig = keyMirror({
  ActionGroup: null,
  Bar: null,
  Legend: null,
  XAxis: null,
  YAxis: null,
  Tooltip: null,
  CartesianGrid: null,
});

// ToDo: separate widgets that can only live in certain keys like menu in action, and options in overlays
// FIXME - move the population of these to the widget code!
export const widgetTypes = {
  AttachmentButton,
  Display,
  GridContainer,
  FlexContainer,
  DataVis,
  Icon,
  Image,
  Text,
  List,
  Button,
  TreeLink,
  Router,
  DropdownSelect,
  CheckboxWidget,
  SliderWidget,
  Chip,
  CollapseWidget,
  PopupWidget,
  ModalWidget,
  ModalWidgetStasis,
  MultiSelect,
  MenuWidget,
  MenuItemWidget,
  PDFContainer,
  OptionsOverlay,
  LogViewer,
  Tabs,
  Upload,
  Page,
  Tree,
  AGGrid,
  AGChart,
  RichText,
  KendoGrid,
  KendoExportButton,
  LayoutWidget,
  CardWidget,
  ...graphConfig,
};

const missingTypes = [];
Object.keys(widgetTypes).forEach(
  (k) => widgetTypes[k] === undefined && missingTypes.push(k),
);
if (missingTypes.length > 0) {
  throw new Error(
    `Missing widget types (this is likely a circular dependency issue): ${JSON.stringify(
      missingTypes,
      null,
      2,
    )}`,
  );
}

enableMapSet();

export class EventBinding extends Component<
  IEventBindingProps & IClientContext,
  IState
> {
  public elementAttributes: IElementAttributes;
  public loadPipeline?: PipelineExecutor;
  public reportInterval: any;
  public parentContainer: EventBinding;
  public widgetLogger: BaseLogger;
  public timeouts: { [key: string]: number };
  public hoverTime: number;
  public debugId: string;

  constructor(props: IEventBindingProps & IClientContext) {
    super(props);
    this.timeouts = {};
    this.hoverTime = 500;
    this.updateFromWidget = this.updateFromWidget.bind(this);
    this.runPipelineWithWidgetContext =
      this.runPipelineWithWidgetContext.bind(this);
    this.updateFormValidity = this.updateFormValidity.bind(this);
    this.receiveData = this.receiveData.bind(this);
    this.activate = this.activate.bind(this);
    this.deactivate = this.deactivate.bind(this);

    this.handleMouseEnter = this.handleMouseEnter.bind(this);
    this.handleMouseLeave = this.handleMouseLeave.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleRightClick = this.handleRightClick.bind(this);
    this.handleClickAway = this.handleClickAway.bind(this);
    this.logPipelineError = this.logPipelineError.bind(this);
    this.requestReport = this.requestReport.bind(this);
    this.compileFreshProperties = this.compileFreshProperties.bind(this);
    this.updateUiState = this.updateUiState.bind(this);
    this.getPermission = this.getPermission.bind(this);
    this.handleUpdateContext = this.handleUpdateContext.bind(this);
    this.handleBatchUpdateContext = this.handleBatchUpdateContext.bind(this);
    this.iCan = this.iCan.bind(this);
    this.pageForward = this.pageForward.bind(this);
    this.hasPageForward = this.hasPageForward.bind(this);
    this.setFilterModel = this.setFilterModel.bind(this);

    const loadedActions = this.initiateActions(props);
    const { hover, click, rightClick } = loadedActions;

    this.elementAttributes = {
      ...((isTesting() || isBrowserUnderTest()) && { id: props.name }),
      'data-test': props.name,
      ...(hover && { onMouseEnter: this.handleMouseEnter }),
      ...(hover && { onMouseLeave: this.handleMouseLeave }),
      ...((click || hover) && { onClick: this.handleClick }),
      ...((rightClick || hover) && { onContextMenu: this.handleRightClick }),
    };

    const normalizedEvents = this.normalizeEvents(props);
    const permission = this.initializePermission(props);
    const shouldLoad = this.shouldLoad({ normalizedEvents, props });

    this.debugId = `${this.props.name}(${this.props.component.type}),instanceId: ${
      this.props.instanceId
    } ${this.props.aliases?.widgetPath.toString()} widgetId: ${
      this.props.component.widgetId
    }`;

    this.state = {
      initialLoadCompleted: !shouldLoad,
      fromPipeline: false,
      error: null,
      info: null,
      normalizedEvents,
      context: null,
      executingUpdatePipeline: false,
      hasError: false,
      widgetState: {},
      loadedActions,
      executingActions: {},
      mouseTrigger: false,
      click: false,
      rightClick: false,
      hover: false,
      compiledProperties: null,
      valid: true,
      childrenAreValid: true,
      validationMessage: '',
      childValidity: {},
      rejectedFormSubmit: false,
      permission,
      localContext: {
        readOnly: permission === AccessType.Read,
      },
    };
    if (logger.isLevelEnabled(LogLevels.Debug)) {
      logger.debug(`create ${this.debugId}`);
    }
  }

  componentDidMount() {
    const { context } = this.props;
    const { type } = this.props.component;
    const { permission } = this.state;

    if (logger.isLevelEnabled(LogLevels.Debug)) {
      logger.debug(this.props, `componentDidMount ${this.debugId}`);
    }

    const initialStoreCommits = [
      {
        condition: permission === AccessType.Read,
        updates: [{ data: true, rawPath: '_self.readOnly' }],
      },
      {
        condition: type === 'Page',
        updates: [
          {
            data: this.compileProperty('pageGroup', context.render.properties),
            rawPath: '_self.pageGroup',
          },
        ],
      },
    ];

    const updates = initialStoreCommits.reduce((acc, commit) => {
      if (commit.condition) {
        return [...acc, ...commit.updates];
      }
      return acc;
    }, []);

    if (updates.length) {
      this.handleBatchUpdateContext(updates);
    }

    if (this.shouldLoad()) {
      this.runLoad();
    }

    this.initializeLoggers();
  }

  shouldComponentUpdate(
    nextProps: Readonly<IEventBindingProps & IClientContext>,
    nextState: Readonly<IState>,
  ): boolean {
    const currentPropsToCompare = Object.assign({}, this.props);
    const nextPropsToCompare = Object.assign({}, nextProps);

    Object.keys(clientContextFields).forEach(
      (k) => delete currentPropsToCompare[k],
    );
    Object.keys(clientContextFields).forEach(
      (k) => delete nextPropsToCompare[k],
    );
    // @ts-ignore
    delete currentPropsToCompare.widgetRegistry;
    // @ts-ignore
    delete currentPropsToCompare.location;
    // @ts-ignore
    delete nextPropsToCompare.widgetRegistry;
    // @ts-ignore
    delete nextPropsToCompare.location;

    const currentStateToCompare = Object.assign({}, this.state) as IState;
    const nextStateToCompare = Object.assign({}, nextState) as IState;

    // We should not compare an HTML element
    Object.keys(currentStateToCompare.executingActions).forEach((k) => {
      if (currentStateToCompare.executingActions[k].anchor) {
        currentStateToCompare.executingActions[k].anchor.element = null;
      }
    });
    Object.keys(nextStateToCompare.executingActions).forEach((k) => {
      if (nextStateToCompare.executingActions[k].anchor) {
        nextStateToCompare.executingActions[k].anchor.element = null;
      }
    });

    const shouldUpdateForProps = !deepEqual(
      currentPropsToCompare,
      nextPropsToCompare,
    );
    const shouldUpdateForState = !deepEqual(
      currentStateToCompare,
      nextStateToCompare,
    );
    const { reloadLogger } = this.props.component.matchProperties;
    if (
      reloadLogger ||
      loggerReload.isLevelEnabled(LogLevels.Debug)
      //|| this.props.clientManager.isDevelopmentStack()
    ) {
      // We don't care about anything that happens before the initial load is completed because
      // the rendering of the child widget does not happen until that's done, so it's not a reload
      if (this.state.initialLoadCompleted) {
        this.handleReloadLogging({
          currentPropsToCompare,
          nextPropsToCompare,
          currentStateToCompare,
          nextStateToCompare,
        });
      }
    }
    return shouldUpdateForProps || shouldUpdateForState;
  }

  handleReloadLogging(params: {
    currentPropsToCompare;
    nextPropsToCompare;
    currentStateToCompare;
    nextStateToCompare;
  }) {
    const {
      currentPropsToCompare,
      nextPropsToCompare,
      currentStateToCompare,
      nextStateToCompare,
    } = params;
    let diffMessage = '';
    let diffProps;
    let diffState;
    try {
      diffProps = diff(currentPropsToCompare, nextPropsToCompare);
      if (diffProps.changed) {
        diffMessage = ' diffProps: ' + diffProps.text;
      }
    } catch (error) {
      reThrow({
        logObject: { props: currentPropsToCompare, nextPropsToCompare },
        level: LogLevels.Error,
        message: `shouldComponentUpdate ${this.debugId}: Problem with props diff`,
        logger,
        error,
        noThrow: true,
      });
    }
    try {
      diffState = diff(currentStateToCompare, nextStateToCompare);
      if (diffState.changed) {
        diffMessage += ' diffState: ' + diffState.text;
      }
    } catch (error) {
      reThrow({
        logObject: { state: currentStateToCompare, nextStateToCompare },
        level: LogLevels.Error,
        message: `shouldComponentUpdate ${this.debugId}: Problem with state diff`,
        logger,
        error,
        noThrow: true,
      });
    }

    // logger.info so the reloadLogger works.
    if (diffMessage.length > 0) {
      loggerReload.info(
        {
          props: currentPropsToCompare,
          nextPropsToCompare,
          state: currentStateToCompare,
          nextStateToCompare,
        },
        `shouldComponentUpdate ${this.debugId}: RELOAD: \n${diffMessage}`,
      );
    }
  }

  componentWillUnmount() {
    const {
      aliases: { widgetPath },
    } = this.props;

    if (logger.isLevelEnabled(LogLevels.Debug)) {
      logger.debug(this.props, `componentWillUnmount, ${this.debugId}`);
    }

    if (this.loadPipeline) {
      this.loadPipeline.close();
      this.loadPipeline = null;
    }
    if (this.reportInterval) {
      clearInterval(this.reportInterval);
    }

    store.dispatch(clearContext({ address: widgetPath }));
  }

  componentDidUpdate(prevProps: IEventBindingProps) {
    if (!this.shouldLoad()) {
      return;
    }

    // These are the fields that the load pipeline subscribes to; if they did not change, there
    // is no reason to rerun the pipeline
    const runLoad = !isEqual(this.props.context.load, prevProps.context.load);
    if (runLoad) {
      if (logger.isLevelEnabled(LogLevels.Debug)) {
        logger.debug(
          diff(prevProps.context.load, this.props.context.load),
          `componentDidUpdate, ${this.debugId} - runLoad: diffs`,
        );
      }
    } else {
      if (logger.isLevelEnabled(LogLevels.Debug)) {
        logger.debug(`componentDidUpdate, ${this.debugId}`);
      }
    }

    if (runLoad) {
      this.runLoad();
    }
  }

  digestStoreUpdate(external: PipelineExternalAdaptor) {
    if (external.commits.length > 0) {
      this.handleBatchUpdateContext(external.commits);
      external.clearStore();
    }
  }

  handleBatchUpdateContext(updates: IRawUpdate[]) {
    const { aliases, name, passThroughProps } = this.props;

    const updateLogger =
      passThroughProps?.logUpdate && (() => passThroughProps.logUpdate(name));

    store.dispatch(
      updateContextBatch({ updates, aliases, logger: updateLogger }),
    );
  }

  handleUpdateContext({ data, rawPath }: IRawUpdate) {
    const { aliases, name, passThroughProps } = this.props;
    const updateLogger =
      passThroughProps?.logUpdate && (() => passThroughProps.logUpdate(name));
    store.dispatch(
      updateContext({
        update: { data, rawPath },
        aliases,
        logger: updateLogger,
      }),
    );
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true, error, info });
    const contextReport = `:\nCurrent state:: ${JSON.stringify(
      this.state.context,
      null,
      2,
    )}`;
    this.handlelogger({
      error: getErrorString(error),
      info,
      context: contextReport,
      location: 'EventBinding componentDidCatch',
    });
    logger.error({ info, error: getErrorString(error) }, 'Caught error');
  }

  getPermission(permissionId, props = this.props) {
    const {
      component: { configName, treeId },
      clientManager,
      userInfo,
    } = props;

    const { permissionManager } = clientManager;
    // const { configName, id } = tree;
    if (!permissionId) {
      return AccessType.All;
    }
    const qualifiedPermissionId = permissionManager.getQualifiedPermissionId(
      treeId,
      configName,
      permissionId,
    );
    const permission = permissionManager.getAccess(
      userInfo.permissions,
      PermissionType.Widget,
      qualifiedPermissionId,
    );
    return permission;
  }

  // used by the event binding constructor
  public initializePermission(props) {
    const { context, passThroughProps } = props;
    const permissionId = this.compileProperty(
      'permissionId',
      context.render.properties,
    );

    if (permissionId) {
      const permission = this.getPermission(permissionId, props);
      return permission;
    }

    if (passThroughProps && passThroughProps.permission) {
      return passThroughProps.permission;
    }

    return AccessType.All;
  }

  initializeLoggers() {
    const { loadPipelineLogger, name } = this.props;
    if (loadPipelineLogger) {
      this.widgetLogger = getLogger({
        name: Loggers.CLIENT,
        nameSuffix: name,
      });
      this.widgetLogger.level = LogLevels.Debug;
    }
  }

  handlelogger(errorInfo) {
    const { name, component } = this.props;
    logger.error(
      {
        ...errorInfo,
        widgetName: name,
        widgetType: component.type,
        state: this.state,
      },
      'EventBinding handle error',
    );
  }

  logPipelineError({ error, context }) {
    this.handlelogger({
      error,
      stack: error.stack,
      context,
      location: 'pipeline',
    });
  }

  async updateUiState(data) {
    const { context } = this.props;
    const renderContext = this.aggregateContextForRender(
      context.render.properties,
    );
    const persistKey = this.compileProperty('persistKey', renderContext);

    if (persistKey) {
      await persistUIState({ [persistKey]: data });
    }
  }

  buildPassThroughs = memoize(
    (params: {
      passThroughProps: IPassThroughProps;
      valid: boolean;
      childrenAreValid: boolean;
      rejectedFormSubmit: boolean;
      validationMessage: string;
      formValidationReasonCodes?: any[];
      formValidationReasonStoreLocation?: string;
      category: string;
      matchProperties: { [key: string]: string };
      formSubmitted: boolean;
    }): IPassThroughProps => {
      const {
        passThroughProps,
        valid,
        childrenAreValid,
        rejectedFormSubmit,
        validationMessage,
        formValidationReasonCodes,
        formValidationReasonStoreLocation,
        category,
        matchProperties,
        formSubmitted,
      } = params;
      const newPassThroughProps = produce(
        passThroughProps,
        (newPassThroughProps_) => {
          if (this.props.component.matchProperties.form) {
            newPassThroughProps_.updateFormValidity = this.updateFormValidity;
            newPassThroughProps_.updateFormFromWidget = this.updateFromWidget;
            newPassThroughProps_.formValid = valid && childrenAreValid;
            newPassThroughProps_.formValidationMessage = validationMessage;
            newPassThroughProps_.formValidationReasonCodes =
              formValidationReasonCodes;
            newPassThroughProps_.formValidationReasonStoreLocation =
              formValidationReasonStoreLocation;
            newPassThroughProps_.rejectedFormSubmit = !!rejectedFormSubmit;
            newPassThroughProps_.formSubmitted = formSubmitted;
            newPassThroughProps_.updateOnFieldChange = this.compileProperty(
              'updateOnFieldChange',
            );
          }

          if (
            category === 'popup' &&
            !this.props.component.matchProperties.renderOwnActions
          ) {
            newPassThroughProps_.inPopup = true;
          }

          if (!newPassThroughProps_.inPopup) {
            newPassThroughProps_.activate = this.activate;
            newPassThroughProps_.deactivate = this.deactivate;
          }

          // this scales a list row
          if (matchProperties.setRowHeight !== undefined) {
            if (
              newPassThroughProps_.setRowHeight &&
              !matchProperties.nestedTable
            ) {
              newPassThroughProps_.setRowHeight(
                newPassThroughProps_.rowIndex,
                matchProperties.setRowHeight,
              );
            }
          }
          newPassThroughProps_.permission = this.state.permission;
        },
      );
      return newPassThroughProps;
    },
  );

  buildPassThroughProps(): IPassThroughProps {
    const {
      valid,
      childrenAreValid,
      validationMessage,
      formValidationReasonCodes,
      formValidationReasonStoreLocation,
      rejectedFormSubmit,
      formSubmitted,
    } = this.state;
    const { passThroughProps, component } = this.props;
    const { matchProperties } = component;
    const { type } = component;
    const details = configurationTypesDetails[type];
    const { category } = details;

    const newPassThroughProps = this.buildPassThroughs({
      passThroughProps,
      valid,
      childrenAreValid,
      rejectedFormSubmit,
      validationMessage,
      formValidationReasonCodes,
      formValidationReasonStoreLocation,
      category,
      matchProperties,
      formSubmitted,
    });

    return newPassThroughProps;
  }

  requestReport({ type }) {
    void generateReport({ type, container: this });
  }

  aggregateLoadPipelines() {
    const aggregateLoadRecurse = (container, loadPipelines) => {
      const loadPipeline = container.state.normalizedEvents.load;
      if (loadPipeline && loadPipeline.stages.length) {
        loadPipelines.unshift({
          ...loadPipeline,
          widget: container.props.name,
          tree: container.props.component.treeId,
        });
      }
      if (container.parentContainer) {
        return aggregateLoadRecurse(container.parentContainer, loadPipelines);
      }
      return loadPipelines;
    };

    return aggregateLoadRecurse(this, []);
  }

  initiateActions(props: IEventBindingProps & IClientContext) {
    const { component, widgetTrees } = props;
    const { actions } = component;
    const actionsByTrigger: any = {};
    const { configName } = component;

    const populateActions = (acts) => {
      acts.forEach((action) => {
        if (action.type === 'ActionGroup') {
          const { actionGroupName } = action.matchProperties;
          const actionGroup = findConfigItem(
            widgetTrees,
            actionGroupName,
            configName,
          );
          if (actionGroup) {
            populateActions(actionGroup.actions);
          }
        } else {
          const { mouseTrigger } = action.matchProperties;
          actionsByTrigger[mouseTrigger] = action;
        }
      });
    };

    if (actions) {
      populateActions(actions);
    }

    return actionsByTrigger;
  }

  aggregateContextForPipeline(context) {
    const { localContext } = this.state;
    const { userInfo, persistKey } = this.props;
    // FIXME (maybe) consider having this cloned only once (it might get cloned in should component update)
    // if it's a performance issue
    const contextClone = _.cloneDeep({
      ...context,
      ...localContext,
    });
    return {
      ...contextClone,
      _userInfo: userInfo,
      _persistKey: persistKey,
    };
  }

  aggregateContextForRender(context) {
    const { localContext } = this.state;
    const { userInfo } = this.props;
    return {
      ...context,
      ...localContext,
      _userInfo: userInfo,
    };
  }

  receiveData(pipeline) {
    if (loggerData.isLevelEnabled(LogLevels.Debug)) {
      loggerData.debug(
        pipeline.external.commits,
        `receiveData, ${this.debugId}`,
      );
    }

    this.digestStoreUpdate(pipeline.external);

    this.setState(
      (prevState) => {
        // It's possible that receiveData could be called again before this
        // state function is actually executed. But that's OK, as another setState will be
        // called to replace this. This is why it's OK to use pipeline.output without cloning it
        const newLocalContext = produce(prevState.localContext, (draft) =>
          Object.assign(draft, pipeline.output),
        );

        const newState: Partial<IState> = { initialLoadCompleted: true };
        if (prevState.localContext !== newLocalContext) {
          newState.localContext = newLocalContext;
        }
        return Object.keys(newState).length > 0 ? (newState as any) : null;
      },
      () => {
        if (logger.isLevelEnabled(LogLevels.Debug)) {
          logger.debug(
            this.state,
            `receiveData - after set state, ${this.debugId}`,
          );
        }
      },
    );
  }

  setData({ data }) {
    const {
      context: { data: currentData },
    } = this.props;
    if (data !== currentData) {
      this.handleUpdateContext({ data, rawPath: 'data' });
    }
  }

  async validate(
    newInput: any,
  ): Promise<{ valid: boolean; validationMessage: string }> {
    let valid = true;
    let validationMessage;
    let reasonCodes: any[];
    let reasonStoreLocation;

    const optionalAndEmpty =
      !this.props.properties.required &&
      typeof newInput === 'string' &&
      newInput.length === 0;

    if (!optionalAndEmpty) {
      const validatedContext = await this.runValidate(newInput);
      const {
        _valid,
        _validationMessage,
        _reasonCodeType,
        _reasonStoreLocation,
      } = validatedContext;

      if (_valid === undefined) {
        throw new Error(
          `validate pipeline did not return _valid in validate pipeline in widget: ${this.props.name} in tree: ${this.props.component.treeId}`,
        );
      }

      valid = !!_valid;
      validationMessage = _validationMessage;
      if (
        (_reasonCodeType && !_reasonStoreLocation) ||
        (!_reasonCodeType && _reasonStoreLocation)
      ) {
        throw new Error(
          `_reasonCodeTYpe and _reasonStoreLocation must both be specified if used in widget: ${this.props.name} in tree: ${this.props.component.treeId}`,
        );
      }
      const reasonCodesObj =
        await this.props.clientManager.codeSupport.getCodesFromType(
          _reasonCodeType,
        );
      reasonCodes = Object.values(reasonCodesObj);
      reasonStoreLocation = _reasonStoreLocation;
    }
    this.setState((state) => {
      if (
        state.valid === valid &&
        state.validationMessage === validationMessage &&
        state.formValidationReasonCodes === reasonCodes &&
        state.formValidationReasonStoreLocation === reasonStoreLocation
      ) {
        return null;
      }
      return {
        valid,
        validationMessage,
        formValidationReasonCodes: reasonCodes,
        formValidationReasonStoreLocation: reasonStoreLocation,
      };
    });
    return { valid, validationMessage };
  }

  iCan(accessType: AccessType) {
    return widgetCan(accessType, this.state.permission);
  }

  normalizeEvents(props) {
    const { events = [] } = props;

    const normalizedEvents = events.reduce((acc, event) => {
      return Object.assign(acc, { [event.name]: event });
    }, {});

    return normalizedEvents;
  }

  // load run from props context, only runs on componentDidMount
  private runLoad() {
    if (!this.iCan(AccessType.Read)) {
      return;
    }

    const {
      context: { load: loadContext },
      component: { configName, requiredFields },
    } = this.props;

    const { normalizedEvents } = this.state;
    const { clientManager, context } = this.props;
    const { stages } = normalizedEvents.load;

    const inputArg = this.aggregateContextForPipeline(loadContext);
    let hasRequiredFields = true;
    const missingRequiredFields = [];
    if (requiredFields) {
      for (const f of requiredFields) {
        if (_.get(inputArg, f) === undefined) {
          hasRequiredFields = false;
          missingRequiredFields.push(f);
        }
      }
    }
    if (!hasRequiredFields) {
      logger.debug(
        missingRequiredFields,
        `runload - SKIPPING, Missing required fields, ${this.debugId}`,
      );
      return;
    }

    logger.debug(`runload - executing load pipeline, ${this.debugId}`);

    if (this.loadPipeline) {
      this.loadPipeline.close();
    }
    this.loadPipeline = clientManager.pipelineManager.createPipelineExecutor({
      stages,
      input: inputArg,
      callback: this.receiveData,
      errorLogger: this.logPipelineError,
      category: 'load',
      tracingIdentifier: this.debugId,
      configName,
      external: new PipelineExternalAdaptor(),
    });

    this.loadPipeline.execute().catch((error) => {
      if (this) {
        this.setState({
          hasError: true,
          initialLoadCompleted: true,
          error,
        });
        if (this.logPipelineError) {
          this.logPipelineError({
            error,
            context: this.aggregateContextForPipeline(context.load),
          });
        }
        reThrow({
          logger,
          error,
          noThrow: true,
          message: `runLoad - error executing pipeline ${this.debugId}`,
        });
      }
    });
  }

  shouldLoad(
    { normalizedEvents, props } = {
      normalizedEvents: this.state.normalizedEvents,
      props: this.props,
    },
  ) {
    // const { normalizedEvents } = this.state;
    const { passThroughProps } = props;

    const result =
      normalizedEvents.load &&
      normalizedEvents.load.stages.length &&
      // for disabling pipeline during editing
      !(passThroughProps && passThroughProps.underBlockedPipeline);

    return result;
  }

  // This is called only on the form
  updateFormValidity({ identifier, inputValid, missingRequired }) {
    this.setState((previousState) => {
      const hasPreviousValue = previousState.childValidity?.[identifier];
      if (hasPreviousValue) {
        const { inputValid: previousValid } =
          previousState.childValidity[identifier];
        if (previousValid === inputValid) {
          return null;
        }
      }

      const newChildValidity = Object.assign({}, previousState.childValidity);
      if (inputValid !== undefined) {
        newChildValidity[identifier] = { inputValid, missingRequired };
      }

      const { childrenAreValid, childrenMissingRequired } = Object.keys(
        newChildValidity,
      ).reduce(
        (acc, childIdentifier) => {
          const childInput = newChildValidity[childIdentifier];
          return {
            childrenAreValid: acc.childrenAreValid && childInput.inputValid,
            childrenMissingRequired: childInput.missingRequired
              ? acc.childrenMissingRequired.concat(childIdentifier)
              : acc.childrenMissingRequired,
          };
        },
        {
          childrenAreValid: true,
          childrenMissingRequired: [],
        },
      );

      const validationMessage = childrenMissingRequired.length
        ? 'Missing required fields'
        : '';

      return {
        childValidity: newChildValidity,
        childrenAreValid,
        childrenMissingRequired,
        validationMessage,
      };
    });
  }

  // This is called on a member of the form
  handleFormValidity(newInput: any, valid: boolean) {
    const { required, dataBind } = this.props.component.matchProperties;
    const updateFormValidity = this.props.passThroughProps?.updateFormValidity;

    if (
      dataBind &&
      (dataBind as string).includes('form') &&
      updateFormValidity
    ) {
      const missingRequired =
        required && [undefined, null, ''].includes(newInput);
      const inputValid = valid && !missingRequired;

      updateFormValidity({
        identifier: this.props.name,
        inputValid,
        missingRequired,
      });
    }
  }

  updateFromWidget(params: UpdateFromWidgetParams) {
    const {
      data,
      widgetDoesNotProvideData,
      acceptConditionalValidation,
      initialUpdate,
      callback,
      fromSubmitButton,
      stages,
    } = params;
    if (!this.iCan(AccessType.Update)) {
      return;
    }

    const { validate } = this.state.normalizedEvents;
    const { required, form, submitForm } = this.props.component.matchProperties;
    const { valid, childrenAreValid } = this.state;
    const { passThroughProps } = this.props;

    if (form) {
      if (!childrenAreValid) {
        this.setState({ rejectedFormSubmit: true });
      }
      if (fromSubmitButton) {
        this.setState({ formSubmitted: true });
      }
      if (acceptConditionalValidation === false) {
        this.setState({ formSubmitted: false });
      }
      if (!childrenAreValid || acceptConditionalValidation === false) {
        return;
      }
    }

    const doUpdateStuff = async (dataParam) => {
      this.setData({ data: dataParam });
      void this.updateUiState(dataParam);
      if (initialUpdate) {
        return;
      }
      await this.runUpdate(dataParam, stages);
      if (submitForm || passThroughProps.updateOnFieldChange) {
        // Calls updateFromWidget on the enclosing form
        void passThroughProps.updateFormFromWidget({
          callback,
          widgetDoesNotProvideData: true,
          fromSubmitButton: !!submitForm,
        });
        return;
      }
      callback && callback();
    };

    // When (in the form's validate pipeline) _valid is true and there is a _validationMessage.
    // the Button UI will ask for OK/Cancel.
    if (acceptConditionalValidation !== undefined) {
      if (submitForm || acceptConditionalValidation === false) {
        void passThroughProps.updateFormFromWidget(params);
        return;
      }
      // In the form
      const { closePopup } = passThroughProps;
      if (acceptConditionalValidation) {
        void this.runUpdate(null);
        closePopup && closePopup();
        return;
      }
      valid === false && closePopup && closePopup();
      // Otherwise, we leave the form up, as it might be further corrected
      return;
    }

    const doUpdate =
      widgetDoesNotProvideData || this.props.context.data !== data;
    if (validate?.stages.length && !initialUpdate) {
      void this.validate(data).then((validParams) => {
        const { valid: validParam, validationMessage: validationMessageParam } =
          validParams;
        this.handleFormValidity(data, validParam);
        if (doUpdate) {
          if (validParam && !validationMessageParam) {
            void doUpdateStuff(data);
          } else if (form && !validParam) {
            this.setState({ rejectedFormSubmit: true });
          }
        }
      });
    } else {
      required && this.handleFormValidity(data, true);
      doUpdate && void doUpdateStuff(data);
    }
  }

  // update run from state context
  async runUpdate(data, stages?: IStageProperties[]) {
    // data here and in update context causes problems in text widget within a form, but is needed for checkboxes
    const { normalizedEvents } = this.state;
    const { showUpdating } = this.props;
    if (
      (normalizedEvents.update && normalizedEvents.update.stages.length) ||
      stages
    ) {
      // only sets the state if it's needed because it triggers an update cycle
      if (showUpdating) {
        this.setState(
          { executingUpdatePipeline: true },
          () => void this.runUpdatePipeline(data, stages),
        );
      } else {
        await this.runUpdatePipeline(data, stages);
      }
    }
  }
  async runPipelineWithWidgetContext(params: {
    data;
    stages: IStageProperties[];
  }) {
    const {
      clientManager,
      component: { configName },
      context,
      aliases,
    } = this.props;
    const { data, stages } = params;
    let pipeline: PipelineExecutor;
    let updateContextLocal;
    try {
      pipeline = clientManager.pipelineManager.createPipelineExecutor({
        stages,
        category: 'update',
        tracingIdentifier: this.debugId,
        configName,
        external: new PipelineExternalAdaptor(),
      });

      updateContextLocal = {
        ...this.aggregateContextForPipeline(context.update),
        ...getStoreState(pipeline.requestedFields, aliases),
        data,
      };
      await pipeline.execute(updateContextLocal);
      this.digestStoreUpdate(pipeline.external as PipelineExternalAdaptor);
      pipeline.close();
      return pipeline.output;
    } catch (error) {
      reThrow({
        logger,
        error,
        message: `error running pipeline ${this.debugId}`,
      });
    }
  }
  async runUpdatePipeline(data, stages?: IStageProperties[]) {
    const {
      clientManager,
      component: { configName },
      context,
      aliases,
    } = this.props;
    const { normalizedEvents } = this.state;
    let pipeline: PipelineExecutor;
    let updateContextLocal;
    if (!stages) {
      stages = normalizedEvents.update.stages;
    }
    try {
      pipeline = clientManager.pipelineManager.createPipelineExecutor({
        stages,
        category: 'update',
        tracingIdentifier: this.debugId,
        configName,
        external: new PipelineExternalAdaptor(),
      });

      updateContextLocal = {
        ...this.aggregateContextForPipeline(context.update),
        ...getStoreState(pipeline.requestedFields, aliases),
        data,
      };
      await pipeline.execute(updateContextLocal);
      this.digestStoreUpdate(pipeline.external as PipelineExternalAdaptor);
      pipeline.close();
      this.setState({
        executingUpdatePipeline: false,
        rejectedFormSubmit: false,
      });
    } catch (error) {
      if (this.logPipelineError) {
        this.logPipelineError({ error, context: updateContextLocal });
      }
      this.setState({
        executingUpdatePipeline: false,
        hasError: true,
        error,
      });
      throw new Error(
        `error running update pipeline: ${getErrorString(
          error,
        )}  ${pipeline.toString()}`,
      );
    }
  }

  async runValidate(data) {
    const { context, aliases } = this.props;
    const {
      normalizedEvents: { validate },
    } = this.state;
    const { stages } = validate;
    if (validate && validate.stages.length) {
      const {
        clientManager,
        component: { configName },
      } = this.props;
      let pipeline: PipelineExecutor;
      let validateContext = null;
      try {
        pipeline = clientManager.pipelineManager.createPipelineExecutor({
          stages,
          category: 'validate',
          tracingIdentifier: this.debugId,
          configName,
        });
        validateContext = {
          ...this.aggregateContextForPipeline(context.validate),
          ...getStoreState(pipeline.requestedFields, aliases),
          data,
        };
        await pipeline.execute(validateContext);
        pipeline.close();
        return pipeline.output;
      } catch (error) {
        if (this.logPipelineError) {
          this.logPipelineError({ error, context: validateContext });
        }
        console.error(
          `Error running validate pipeline: ${getErrorString(error)}`,
        );
        this.setState({
          hasError: true,
          error,
        });
      }
    }
  }

  private activate(tree, anchor, close) {
    this.setState((previousState) => {
      return {
        ...previousState,
        executingActions: Object.assign({}, previousState.executingActions, {
          [tree.name]: {
            anchor,
            tree,
            close,
          },
        }),
      };
    });
  }

  private deactivate(name) {
    this.setState((previousState) => {
      return {
        ...previousState,
        executingActions: Object.assign({}, previousState.executingActions, {
          [name]: false,
        }),
      };
    });
  }

  private initiateActivate(tree, anchor, close) {
    const { passThroughProps } = this.props;
    if (
      passThroughProps &&
      passThroughProps.inPopup &&
      passThroughProps.activate
    ) {
      passThroughProps.activate(tree, anchor, close);
    } else {
      this.activate(tree, anchor, close);
    }
  }

  private initiateDeactivate(name) {
    const { passThroughProps } = this.props;
    if (
      passThroughProps &&
      passThroughProps.inPopup &&
      passThroughProps.deactivate
    ) {
      passThroughProps.deactivate(name);
    } else {
      this.deactivate(name);
    }
  }

  handleMouseEvent(trigger: string, event) {
    const { loadedActions } = this.state;
    const action = loadedActions && loadedActions[trigger];

    if (action) {
      const anchor = {
        element: event.target,
        left: event.pageX - document.body.scrollLeft,
        top: event.pageY - document.body.scrollTop,
      };
      this.initiateActivate(action, anchor, () =>
        this.handleClickAway(action.name),
      );
      this.setState(
        Object.assign(
          { click: false, rightClick: false, hover: false },
          { [trigger]: action.name },
        ) as IState,
      );
    }
  }

  private clearSingleMouseToggle(trigger) {
    if (trigger && this.state[trigger]) {
      this.initiateDeactivate(this.state[trigger]);
      this.setState({ [trigger]: false } as unknown as IState);
    }
  }

  private clearAllMouseToggles() {
    ['click', 'rightClick', 'hover'].forEach((trigger) => {
      if (this.state[trigger]) {
        this.initiateDeactivate(this.state[trigger]);
      }
    });
    const { click, rightClick, hover } = this.state;
    if (click || rightClick || hover) {
      this.setState({ click: false, rightClick: false, hover: false });
    }
  }

  private handleMouseEnter(event) {
    // Drag case - primary button down
    if (event.buttons === 1) {
      return;
    }
    if (
      !this.state.click &&
      !this.state.rightClick &&
      this.state.loadedActions &&
      this.state.loadedActions.hover
    ) {
      this.timeouts.hover = window.setTimeout(() => {
        this.handleMouseEvent('hover', event);
      }, this.hoverTime);
    }
  }

  private handleMouseLeave() {
    clearTimeout(this.timeouts.hover);
    if (this.state.hover) {
      this.clearSingleMouseToggle('hover');
    }
  }

  private handleClick(event) {
    // event.stopPropagation();
    // event.nativeEvent.stopImmediatePropagation();
    clearTimeout(this.timeouts.hover);
    this.clearAllMouseToggles();
    this.handleMouseEvent('click', event);
  }

  private handleRightClick(event) {
    event.preventDefault();
    // event.stopPropagation();
    // event.nativeEvent.stopImmediatePropagation();
    clearTimeout(this.timeouts.hover);
    this.clearAllMouseToggles();
    this.handleMouseEvent('rightClick', event);
  }

  private handleClickAway(name) {
    clearTimeout(this.timeouts.hover);
    this.initiateDeactivate(name);
    const { click, rightClick, hover } = this.state;
    if (click || rightClick || hover) {
      this.setState({ click: false, rightClick: false, hover: false });
    }
  }

  // for reporting
  compileFreshProperties() {
    return this.compileProperties();
  }

  compileProperties(
    renderContext = this.aggregateContextForRender(
      this.props.context.render.properties,
    ),
  ) {
    const { properties } = this.props;
    const compiledProperties: any = {};

    Object.keys(properties).forEach((propertyKey) => {
      const compiledProperty = this.compileProperty(propertyKey, renderContext);
      if (compiledProperty !== undefined) {
        compiledProperties[propertyKey] = compiledProperty;
      }
    });

    return compiledProperties;
  }

  // should this just be moved into the redux mapState?
  compileProperty(
    propertyKey,
    renderContext = this.props.context.render.properties,
  ) {
    const { properties } = this.props;
    const propertyValue = properties[propertyKey];

    let compiledProperty = compileProperty(
      propertyKey,
      propertyValue,
      renderContext,
    );

    // In the compiled versions the widgets always deal with HTML5 types.
    // FIXME this is a little brittle because it means it always has to be called "type"
    // as we may want this conversion for other properties that handle type, we probably
    // need a BasicType indicating a type, and then use the type of the property (rather
    // than the name) to trigger the conversion.
    if (propertyKey === 'type') {
      compiledProperty = basicToHtml5[propertyValue] || compiledProperty;
    }

    return compiledProperty;
  }

  async pageForward() {
    if (this.loadPipeline?.paged) {
      await this.loadPipeline.pageForward();
    }
  }

  hasPageForward(): boolean {
    if (this.loadPipeline?.paged) {
      return this.loadPipeline.hasPageForward();
    }
    return false;
  }

  async setFilterModel(filterModel: any) {
    if (!this.loadPipeline?.paged) {
      throw new Error('Cannot set filterModel on a non-paged pipeline');
    }
    this.loadPipeline.setFilterModel(filterModel);
  }

  actionsToChildren = memoize(
    (executingActions: { [key: string]: IAction }) => {
      return Object.values(executingActions)
        .filter((action) => action)
        .map((action) => {
          // Don't want to mutate the widget tree, this could put stuff in the tree that
          // does not belong, like react elements.
          const newChild = Object.assign({}, action.tree);
          newChild.actionReference = action;
          return newChild;
        });
    },
  );

  render() {
    const {
      widgetState,
      initialLoadCompleted,
      hasError,
      error,
      executingActions,
      loadedActions,
      executingUpdatePipeline,
      valid,
      validationMessage,
    } = this.state;
    const {
      instanceId,
      aliases,
      passThroughProps,
      childProps,
      component,
      name,
      renderer,
      context,
    } = this.props;
    const {
      type,
      children: childWidgets,
      widgetId,
      overlays,
      subHeader,
      actionReference,
    } = component;

    if (logger.isLevelEnabled(LogLevels.Debug)) {
      logger.debug(`render ${this.debugId}`);
    }

    if (!this.iCan(AccessType.Read)) {
      logger.debug(`render SKIP (no permission) ${this.debugId}`);
      return null;
    }
    const toRender = [];

    const subHeaderDomNode = document.getElementById('subHeader');
    if (subHeader && subHeaderDomNode) {
      const subHeaderRender = subHeader.map((subHeaderWidget) => (
        <Widget
          component={subHeaderWidget}
          passThroughProps={passThroughProps}
          aliases={aliases}
        />
      ));
      //logger.debug(`${this.props.name} - render (subheader)`);
      toRender.push(ReactDOM.createPortal(subHeaderRender, subHeaderDomNode));
    }

    if (!initialLoadCompleted) {
      if (logger.isLevelEnabled(LogLevels.Debug)) {
        logger.debug(`render SKIP - waiting for initial load ${this.debugId}`);
      }
      toRender.push(<Loading key={`${instanceId}loading`} />);
    } else if (hasError) {
      logger.debug(`render SKIP - hasError ${this.debugId}`);
      // @ts-ignore
      return <Broken message={error.message || error} />;
    } else {
      if (this.widgetLogger) {
        this.widgetLogger.info(this.aggregateLoadPipelines());
      }

      const elementProps: { cursor?: string } = {};

      if (loadedActions && loadedActions.click) {
        elementProps.cursor = 'pointer';
      }

      // this adds triggered actions to the children to be rendered
      const actionsAsChildren = this.actionsToChildren(executingActions);

      const aggregatePropertyContext = this.aggregateContextForRender(
        context.render.properties,
      );
      const compiledProperties = this.compileProperties(
        aggregatePropertyContext,
      );

      if (
        shouldRender({
          render: compiledProperties.render,
          preventRender: compiledProperties.preventRender,
        })
      ) {
        const WidgetComponent = widgetTypes[type];

        if (!WidgetComponent || !type) {
          const { id, name: componentName, treeId } = this.props.component;
          throw new Error(
            `Widget type not found (this is a bug) for: ${name} ${type} ${id} ${componentName} ${treeId} ${widgetId} ${instanceId}`,
          );
        }

        const widgetComponent = (
          <WidgetComponent
            renderer={renderer}
            id={name}
            widgetType={type}
            component={component}
            actionReference={actionReference}
            elementAttributes={this.elementAttributes}
            renderingActions={actionsAsChildren}
            overlays={overlays}
            {...childProps}
            {...widgetState}
            childWidgets={childWidgets}
            key={`${instanceId}widget`}
            valid={valid}
            validationMessage={validationMessage}
            context={context.render}
            data={aggregatePropertyContext.data}
            {...compiledProperties}
            passThroughProps={this.buildPassThroughProps()}
            aliases={this.props.aliases}
            updateFromWidget={this.updateFromWidget}
            runPipelineWithWidgetContext={this.runPipelineWithWidgetContext}
            requestReport={this.requestReport}
            {...elementProps}
            updating={executingUpdatePipeline}
            updateUiState={this.updateUiState}
            getPermission={this.getPermission}
            batchUpdateContext={this.handleBatchUpdateContext}
            iCan={this.iCan}
            paged={this.loadPipeline && this.loadPipeline.paged}
            pageForward={this.pageForward}
            hasPageForward={this.hasPageForward}
            setFilterModel={this.setFilterModel}
            instanceId={this.props.instanceId}
          />
        );
        toRender.push(widgetComponent);
        if (logger.isLevelEnabled(LogLevels.Debug)) {
          logger.debug(`render finish ${this.debugId}`);
        }
      } else {
        if (logger.isLevelEnabled(LogLevels.Debug)) {
          logger.debug(`render SKIP - dynamically prevented ${this.debugId}`);
        }
      }
    }

    return <Fragment>{toRender}</Fragment>;
  }
}

const makeMapState = (currentState, props) => {
  const {
    component: { subscribeFields: subscriptionMap = {} },
    aliases = {},
  } = props;

  const subscriptionMapSelector = createSubscriptionMapSelector(
    subscriptionMap,
    aliases,
    {},
  );

  return (state, ownProps: IEventBindingProps) => {
    const { instanceId } = ownProps;
    const contextByType = subscriptionMapSelector(state);
    if (loggerData.isLevelEnabled(LogLevels.Debug)) {
      loggerData.debug(
        contextByType,
        `${ownProps.name} instance: ${instanceId} - store changed (showing context value)`,
      );
    }
    return contextByType;
  };
};

const mergeState = (
  propsFromState,
  propsFromDispatch,
  ownProps,
): IEventBindingProps => {
  return {
    ...ownProps,
    ...propsFromDispatch,
    context: propsFromState,
  };
};

function withContext(component) {
  return connect(makeMapState, {}, mergeState)(component);
}

const ebc = compose<any>(withClient, withRouter, withContext)(EventBinding);

setEventBindingContainer(ebc);

export default ebc;
