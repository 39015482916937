import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { FunctionComponent, useContext, useEffect, useState } from 'react';
import { titleCase } from 'title-case';
import { UseTestData as UseTestDataEnum } from 'universal/schemaManager';
import { getEnumKeys } from 'universal/utilityFunctions';

import { useCheckPermissionSysAdmin } from '../../hooks/useCheckPermissions';
import { persistUIState } from '../../store/persistUIState';
import { ClientContext } from '../../wrappers/ClientContext';

const UseTestData: FunctionComponent<React.PropsWithChildren<unknown>> = () => {
  const { clientManager } = useContext(ClientContext);
  const allowed =
    useCheckPermissionSysAdmin() && clientManager.isDevelopmentStack();
  const { schemaManager } = clientManager;
  const { useTestData } = schemaManager;

  const [localUseTestData, setLocalUseTestData] = useState(useTestData);

  useEffect(() => {
    if (localUseTestData !== useTestData) {
      schemaManager.setUseTestData(localUseTestData);
      void persistUIState({
        useTestData: localUseTestData,
      }).then(() => window.location.reload());
    }
  });

  if (!allowed) {
    return null;
  }

  function handleChange(event) {
    setLocalUseTestData(event.target.value);
  }

  return (
    <FormControl variant="standard">
      <InputLabel>Use Test Data</InputLabel>
      <Select value={useTestData} variant="standard" onChange={handleChange}>
        {getEnumKeys(UseTestDataEnum).map((enumKey) => (
          <MenuItem value={UseTestDataEnum[enumKey]} key={enumKey}>
            {titleCase(enumKey)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default UseTestData;
