import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import keyMirror from 'keymirror';
import React, {
  FunctionComponent,
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useState,
} from 'react';
import {
  ALLOWMAINTENANCEACCESS,
  PermissionType,
} from 'universal/permissionManager';

import {
  ApplicationContext,
  INavItem,
} from '../../wrappers/ApplicationContext';
import { ClientContext } from '../../wrappers/ClientContext';
import Loading from '../atoms/Loading';

import BreadCrumbs from './BreadCrumbs';
import NavBar from './NavBar';
import NavDrawer, { calcDrawerWidth } from './NavDrawer';

export const appBarHeight = 68;
const breadCrumbRowHeight = 68;

interface IBreadCrumb {
  label: string;
  url: string;
}

interface INavState {
  navDrawerIsOpen: boolean;
  breadCrumbs: IBreadCrumb[];
  title: string;
}

interface INavContext extends INavState {
  dispatchNavState: React.Dispatch<any>;
}

const initialNavState = {
  navDrawerIsOpen: true,
  breadCrumbs: [],
  title: '',
};

export const navReducerTypes = keyMirror({
  OPEN_NAV_DRAWER: null,
  CLOSE_NAV_DRAWER: null,
  BREADCRUMBS_SET: null,
  TITLE_UPDATE: null,
});

const navLayoutReducer = (state, action) => {
  switch (action.type) {
    case navReducerTypes.OPEN_NAV_DRAWER:
      return {
        ...state,
        navDrawerIsOpen: true,
      };
    case navReducerTypes.CLOSE_NAV_DRAWER:
      return {
        ...state,
        navDrawerIsOpen: false,
      };
    case navReducerTypes.BREADCRUMBS_SET:
      return {
        ...state,
        breadCrumbs: action.payload,
      };
    case navReducerTypes.TITLE_UPDATE:
      return {
        ...state,
        title: action.payload,
      };
    default:
      return state;
  }
};

export const NavContext = React.createContext<INavContext>(null);

const NavLayout: FunctionComponent<
  React.PropsWithChildren<{
    navModel: INavItem[];
    passThroughProps;
  }>
> = (props) => {
  const [portalTargetsReady, setReady] = useState(false);
  const [navState, dispatchNavState] = useReducer(
    navLayoutReducer,
    initialNavState,
  );

  const { clientManager, userInfo } = useContext(ClientContext);
  const { permissionManager } = clientManager;
  const {
    application: { subHeaderWidth },
    navigation: { showIcons },
  } = useContext(ApplicationContext);
  const stackConfig = clientManager.getStackConfig();

  useEffect(() => {
    // This will be extended to wait for all the DOM nodes needed to run the app's portals
    function waitForSubheader() {
      if (!document.getElementById('subHeader')) {
        window.requestAnimationFrame(waitForSubheader);
      } else {
        setReady(true);
      }
    }

    waitForSubheader();
  }, []);

  const { navModel, children, passThroughProps } = props;
  const { navDrawerIsOpen } = navState as INavState;

  let content;
  if (
    stackConfig.downForMaintenance &&
    !permissionManager.getAccess(
      userInfo.permissions,
      PermissionType.Programmatic,
      ALLOWMAINTENANCEACCESS,
    )
  ) {
    content = <Typography>{stackConfig.maintenanceMessage}</Typography>;
  } else {
    content = portalTargetsReady ? children : <Loading />;
  }

  const openNavDrawer = useCallback(
    () => dispatchNavState({ type: navReducerTypes.OPEN_NAV_DRAWER }),
    [],
  );
  const closeNavDrawer = useCallback(
    () => dispatchNavState({ type: navReducerTypes.CLOSE_NAV_DRAWER }),
    [],
  );

  // FIXMETHEME - should '3' be something in the theme
  const marginLeft = calcDrawerWidth({
    isOpen: navDrawerIsOpen,
    showIcons,
    offset: 3,
  });

  return (
    <NavContext.Provider value={{ ...navState, dispatchNavState }}>
      <Box
        sx={{
          display: 'grid',
          height: '100%',
          gridTemplateRows: `${appBarHeight}px 1fr`,
          gridTemplateColumns: '1fr',
          overflow: 'hidden',
        }}
      >
        <NavBar
          sx={{ gridRow: '1', gridColumn: '1' }}
          navDrawerIsOpen={navDrawerIsOpen}
          openNavDrawer={openNavDrawer}
          closeNavDrawer={closeNavDrawer}
          passThroughProps={passThroughProps}
        />
        <Box
          sx={{
            marginLeft,
            transition: (theme) =>
              theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
              }),
            gridRow: '2',
            gridColumn: '1',
            display: 'grid',
            gridTemplateRows: `${breadCrumbRowHeight}px 1fr`,
            gridTemplateColumns: `1fr ${subHeaderWidth || 400}px`,
            overflow: 'hidden',
          }}
        >
          <Box
            sx={{
              gridRow: '1',
              gridColumn: '1',
            }}
          >
            <BreadCrumbs />
          </Box>
          <Box
            sx={{
              gridRow: '1',
              gridColumn: '2',
            }}
          >
            <div id="subHeader" />
          </Box>
          <Box
            sx={{
              gridRow: '2',
              gridColumn: '1/3',
              boxSizing: 'border-box',
              overflow: 'hidden',
            }}
          >
            {content}{' '}
          </Box>
        </Box>
        <NavDrawer isOpen={navDrawerIsOpen} navModel={navModel} />
      </Box>
    </NavContext.Provider>
  );
};

export default NavLayout;
