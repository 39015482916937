import { DocumentNode, FieldNode, OperationDefinitionNode } from 'graphql';
import _ from 'lodash';

import { IEntityType, IFieldNameInfo } from '../metadataSupportConstants';
import { TypeDefinition } from '../typeDefinition';
import { StageType } from '../types';
import { removeNullOrUndefined, stringify } from '../utilityFunctions';

import {
  getGraphqlOperation,
  getGraphqlOperationFieldFromDocument,
} from './graphQLSupportAst';
import { PipelineExecutor } from './pipelineExecutor';
import { IRemoteContext } from './pipelineManager';
import { IStageProperties } from './stage';
import { StageQuery } from './stageQuery';

export class StageImpl {
  // Original property values specified with the stage
  public originalProperties: IStageProperties;

  // Working property values, after interpolation and that are modified
  // during execution
  public workingProperties: IStageProperties;

  public remoteContext: IRemoteContext;

  public stageIndex: number;

  public executor: PipelineExecutor;

  public configName: string;

  public requestedFields: string[] = [];
  public requiredFields: string[] = [];

  // Stage has been closed for good
  public closed: boolean;

  // Result of the execution of this stage
  public result: any;

  public jsCode: string;

  //
  // Remainder is GraphQL stuff
  //

  public stageQuery: StageQuery;

  public useLocalLink: boolean;

  public graphQLDocument: DocumentNode;
  public graphQLDocumentText: string;
  public graphQLField: FieldNode;

  public suppressLocalNotify: boolean;

  public fieldNameInfo: IFieldNameInfo;

  public typeDef: TypeDefinition;
  public entity: IEntityType;

  public timestamp: number;

  constructor(properties: IStageProperties) {
    this.originalProperties = properties;
    this.timestamp = Date.now();
  }

  public setupGraphqlInfo(document: DocumentNode) {
    // Clone this because we mutate the document, and gql (which creates the document) caches by query text
    this.graphQLDocument = _.cloneDeep(document);
    this.graphQLField = getGraphqlOperationFieldFromDocument(
      this.graphQLDocument,
    );
  }

  public getGraphQLOperation(): OperationDefinitionNode {
    return getGraphqlOperation(this.graphQLDocument);
  }

  public getGraphQLVariables() {
    return {
      ...this.workingProperties._forEachItem,
      ...this.workingProperties.variables,
    };
  }

  public getFetchPolicy() {
    if (this.workingProperties.fetchPolicy) {
      return this.workingProperties.fetchPolicy;
    }
    return this.executor.pipelineManager.fetchPolicy;
  }

  public traceId() {
    return `${this.originalProperties._name}/${
      StageType[this.originalProperties._stageType]
    }`;
  }

  public toString() {
    let output = `${this.traceId()}\n`;

    if (this.workingProperties) {
      output += 'properties:\n';
      removeNullOrUndefined(this.workingProperties);
      const props = this.workingProperties;
      Object.keys(props)
        .filter((k) => props[k])
        .forEach((k) => {
          output += `  ${k}: `;
          let propString =
            props[k].toString() === '[object Object]'
              ? stringify({ input: props[k], noThrow: true })
              : props[k].toString();
          if (propString.length > 500) {
            propString = propString.substring(0, 500) + '... (truncated)';
          }
          output += stringify({
            input: propString,
            noThrow: true,
            pretty: true,
          })
            .replace(/\\n/g, '\n')
            .replace(/\\"/g, '"');
          output += '\n';
        });
    }
    return output;
  }
}
