//
// System-wide constants, used by everything, so nothing can depend on these
//

export const BRANCH_MAIN = 'main';

// Stack types can be anything, only DEV and PROD are treated specially
export const DEV_STACK_TYPE = 'dev';
export const PROD_STACK_TYPE = 'prod';

export const STAGING_STACK_TYPE = 'staging';

export const LOAD_INTO_MEMORY = 'loadIntoMemory';

// The environment suffix for developer build, where to look for the environment variable definitions
export const DEFAULT_ENV = 'dev';

// Not a display company name, but used for naming of any files/buckets, etc
export const COMPANY_NAME = 'snapstrat';
// If this is changed, update auth0/actions/validate-user.js as it can't use this constant
export const COMPANY_DOMAIN = 'snapstrat.com';
export const REST_URL_COMPONENT = 'rest/{proxy+}';

export const DEV_COMPANY_DOMAIN = 'snapstratdev.net';

export const CLIENT_ROLE = 'ClientRole';

export const PRODUCT_NAME = 'worm';

export const BASE_QUEUE_NAME = 'queue';
export const DEAD_LETTER_QUEUE_NAME = 'dlq';
// This is appended to the BASE_QUEUE_NAME/DEAD_LETTER_QUEUE_NAME by the runtime
export const FIFO_QUEUE_NAME = '.fifo';
export const FIFO_QUEUE_NAME_AGGREGATION = 'aggregation' + FIFO_QUEUE_NAME;
export const FIFO_QUEUE_NAME_ACTION = 'action' + FIFO_QUEUE_NAME;

export const TEST_API_GATEWAY_PORT = 4001;
export const TEST_LAMBDA_PORT = 4002;

export const FUNCTION_NAME_ENV = 'FUNCTION_NAME';

// Known source directories
export const PACKAGES_DIR = 'packages';
export const CONFIGURATIONS_DIR = 'configurations';

// Known configuration names
export const SYSTEM = 'system';
export const SYSTEM_TEST = 'systemtest';

export const MAX_LAMBDA_SERVER_TIMEOUT_SECONDS = 900;

// Common HTTP headers
export const HEADER_REMOTE_CONTEXT = `${COMPANY_NAME}-context`;

// Used by devsetup and installMetadata
export const LAMBDA_FUNCTIONS_FILE = 'lambdaFunctions.json';

export const SKIP_INTERMITTENT = 'SKIP_INTERMITTENT';

export const AUTH0_DEV_CONNECTION = 'dev';

export type ImportMapType = { [moduleName: string]: any };

export function getConfigTableName(stackId) {
  const table = `${stackId}.system.config`;
  return table;
}

export function getTestTableName(stackId) {
  const table = `${stackId}.system.test`;
  return table;
}

export function getWarmupFunctionName(stackId) {
  return `${stackId}-warmup`;
}

// This is used by as a part of the serverless configuration and also at runtime to provide
// a directory of the available functions. The fields in this interface must match those
// defined by serverless, but there maybe additional fields as well.
export interface ILambdaFunction {
  // Serverless fields
  environment?: { [key: string]: string };
  reservedConcurrency?: number;
  handler: string;
  events?: any;
  httpEventPath?: string;
  // In seconds
  timeout?: number;
  memorySize?: number;
  architecture?: 'arm64';
  enabled?: boolean;
  alarms?: any[];

  // Additional fields not used by serverless

  // Fully qualified name of the function
  fullyQualifiedName?: string;

  // The unqualified name, like 'ah'
  shortName: string;

  description: string;

  // The timeout value for a queue handler function cannot be set to what we want (15 mins) because:
  // https://github.com/aws/serverless-application-model/issues/1424
  // So we work around by setting the lambda function timeout whenever the lambda functions are
  // restarted.
  initialTimeoutForCloudFormation?: number;

  interval?: string;

  pipelineSenderInput?: IPipelineSenderInput;

  // Can be accessed via API gateway
  isExternal?: boolean;

  // Not to be restarted
  noRestart?: boolean;

  // Used for a pipelineHandlerQueuedConsumer function
  isProxyForFunctionName?: string;
  isQueueHandlerFunction?: boolean;

  proxyFunctionName?: string;

  // Each message is processed one at a time (used in conjuction with FIFO queues)
  queuedHandlerSingleThreaded?: boolean;

  queueName?: string;

  warmup?: any;

  python?: boolean;

  isLambdaIntegration?: boolean;
}

export interface ILambdaFunctions {
  [functionName: string]: ILambdaFunction;
}

export interface IPipelineSenderInput {
  // Sometimes this is not necessary for certain system work
  executionConfigName?: string;
  pipelines: Array<{ name: string; queueName?: string; arguments?: any[] }>;
}

// Variables whose types are simple and could be used in the client testing code
export class BaseVarsClass {
  AWS_REGION? = '';

  AWS_ACCOUNT_ID? = '';

  // Per AWS account - may have PROD_ (FIXME - move to "prod_" for consistency)
  AWS_ACCESS_KEY_ID? = '';
  AWS_SECRET_ACCESS_KEY? = '';

  // Used for cloudfront - AWS account, may have prod_
  CERTIFICATE_ARN? = '';

  BUILD_NUMBER? = '';
  BRANCH? = '';
  STACK_ID? = '';
  STACK_TYPE? = '';

  // The API key used for inbound pipeline executions from other applications
  API_KEY? = '';
  // Used for when the externalPipelineHandler is not to use an API key
  DONT_USE_API_KEY? = '';

  // The main configName being built
  CONFIG? = '';
  // All configs that can be an execution config for this stack, the first is the default execution config
  AVAILABLE_EXEC_CONFIG_NAMES? = '';
  // Other non-execution configs to be loaded for this stack
  OTHER_CONFIG_NAMES? = '';

  // Used for testing
  TEST_SVELTE? = '';
  TEST_BACKEND_WORM? = '';
  TEST_REACT? = '';
  TEST_PYTHON? = '';

  NO_DELETE_STACK? = '';

  // Per developer - GITHUB_TOKEN_<GitHub user id>
  GITHUB_TOKEN? = '';
}

export type IBaseVars = BaseVarsClass;
