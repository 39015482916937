import * as convert from 'color-convert';
import saveAs from 'file-saver';
import _ from 'lodash';
import { escapeString, sortBy } from 'universal/utilityFunctions';

import { downloadBlob } from './downloads';
import { buildExcelExport } from './excel';
import { typographyTranslate } from './typographyTranslate';

export function downloadCSV({
  filename,
  columns,
  columnLabels,
  rows,
  sortFields,
}) {
  const csv = transFormToCSV({
    columns,
    columnLabels,
    rows,
    sortFields,
  });

  const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });

  downloadBlob({ blob: blob, filename });
}

export function transFormToCSV({ columns, columnLabels, rows, sortFields }) {
  const dataKeys = columns.split('_');
  const header = [
    columnLabels.split('_').map((label) => escapeString({ string: label })),
  ];

  if (sortFields) {
    const sortMap = sortFields.map((field) => (row) => _.get(row, field));
    rows = sortBy({ items: rows, sortFields: sortMap });
  }

  const content = rows.map((row) => {
    const cells = dataKeys.map((dataKey) => {
      const cell = _.get(row, dataKey) || '';
      return escapeString({ string: cell.toString() });
    });
    return cells.join(',');
  });

  return header.concat(content).join('\r\n');
}

export async function generateReport({ type: reportType, container }) {
  const {
    props: {
      component: { type },
    },
    compileFreshProperties,
  } = container;

  const properties = compileFreshProperties();

  const { filename } = properties;

  const reportBuilders = {
    Grid: buildGridReport,
    List: buildListReport,
  };

  const exportBuilders = {
    excel: buildExcelExport,
  };

  const report = reportBuilders[type]({ container });

  const blob = await exportBuilders[reportType]({ report });
  saveAs(blob, `${filename}.xlsx`);
}

export function buildListReport({ container }) {
  const { childContainers, compileFreshProperties } = container;

  const gridMap = [];

  Object.values(childContainers).forEach((childContainer: any) => {
    const context = childContainer.aggregateContext();
    const { columnIndex, rowIndex } = context;
    const cell = buildCell({ container: childContainer });
    if (!gridMap[columnIndex]) {
      gridMap[columnIndex] = [];
    }
    gridMap[columnIndex][rowIndex + 1] = cell;
  });

  const properties = compileFreshProperties();
  const columns = properties.columns.split('_');

  //This is for building list autoheaders, like those added by the list for filtering
  columns.forEach((column, index) => {
    if (!gridMap[index]) {
      gridMap[index] = [];
    }
    if (!gridMap[index][0]) {
      gridMap[index][0] = {
        value: {
          richText: [
            {
              font: {
                size: 16,
              },
              text: column.toUpperCase(),
            },
          ],
        },
        fill: {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: convertColor(properties.headerColor) },
        },
      };
    }
    return;
  });

  return gridMap;
}

export function buildGridReport({ container }) {
  const { childContainers } = container;

  const gridMap = [];
  Object.values(childContainers).forEach((childContainer: any) => {
    const context = childContainer.aggregateContext();

    const { columnGroupIndex, columnIndex, rowIndex, rowGroup } = context;

    const cell = buildCell({ container: childContainer });

    if (!gridMap[columnGroupIndex]) {
      gridMap[columnGroupIndex] = [];
    }

    if (columnGroupIndex !== undefined && columnIndex !== undefined) {
      if (!gridMap[columnGroupIndex][columnIndex]) {
        gridMap[columnGroupIndex][columnIndex] = [];
      }
      if (rowIndex !== undefined) {
        gridMap[columnGroupIndex][columnIndex][rowIndex + 1] = cell;
      } else if (rowGroup === 'columnHeader') {
        gridMap[columnGroupIndex][columnIndex][0] = cell;
      }
      // ToDo: add group header building here
      // } else if (rowGroup === 'columnGroupHeader') {
      //   gridMap[columnGroupIndex][0] = data;
    }
  });

  const finishedReport = _.flatten(gridMap);
  return finishedReport;
}

function getLeaves({ container }) {
  if (!container.childContainers) {
    return [container];
  }

  const leaves = [];

  function getLeavesRecurse(c) {
    if (!c.childContainers) {
      leaves.push(c);
    } else {
      Object.values(c.childContainers).forEach((childContainer) =>
        getLeavesRecurse(childContainer),
      );
    }
  }

  getLeavesRecurse(container);
  return leaves;
}

function buildCell({ container }) {
  const leaves = getLeaves({ container });
  const textValues = leaves
    .map((c, index) => {
      return extractTextValue({ container: c, index });
    })
    .filter((result) => result !== null);
  return {
    value: { richText: textValues },
    alignment: { wrapText: true },
    border: {
      top: { style: 'medium' },
      left: { style: 'medium' },
      bottom: { style: 'medium' },
      right: { style: 'medium' },
    },
    ...extractCellValue({ container }),
  };
}

function extractCellValue({ container }) {
  const { compileFreshProperties } = container;

  const properties = compileFreshProperties();

  return {
    fill: {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: convertColor(properties.backgroundColor) },
    },
  };
}

//next represents the next container in the array do decide if a newline is needed
function extractTextValue({ container, index }) {
  const {
    props: {
      component: { type },
    },
    compileFreshProperties,
  } = container;

  const properties = compileFreshProperties();

  //newlines are scrubbed off if they are the very end of a line, and single space after preserves it,
  //or - adding the newline to the befinning of the next row works even better
  const addNewLine = (string) => (index > 0 ? ' \r\n' : '') + string;

  const widgetTypeExtracts = {
    Display: (p) => {
      const content =
        p.reportOverride !== undefined
          ? addNewLine(p.reportOverride)
          : // : addNewLine(properties.label + ' ' + properties.data);
            addNewLine(
              [...(p.label ? [p.label] : []), ...(p.data ? [p.data] : [])].join(
                ' ',
              ),
            );
      return {
        text: content,
        font: {
          size: convertVariantToSize(p.variant),
          color: { argb: convertColor(p.color) },
          underline: p.textDecoration.includes('underline'),
          strike: p.textDecoration.includes('line-through'),
        },
      };
    },
    Chip: (p) => {
      const content =
        p.reportOverride !== undefined
          ? addNewLine(p.reportOverride)
          : addNewLine(p.label);
      return {
        text: content,
        font: {
          color: { argb: convertColor(p.textColor) },
          underline: p.textDecoration.includes('underline'),
          strike: p.textDecoration.includes('line-through'),
        },
      };
    },
  };

  if (widgetTypeExtracts[type]) {
    return widgetTypeExtracts[type](properties);
  } else {
    return null;
  }
}

function convertColor(colorString) {
  if (!colorString) {
    return null;
  }
  if (colorString[0] === '#') {
    return 'FF' + colorString.slice(1);
  } else {
    const hex = convert.keyword.hex(colorString);
    return 'FF' + hex;
  }
}

//converts display/typography variants to font sizes
function convertVariantToSize(variant) {
  if (!variant) {
    return null;
  }

  return typographyTranslate[variant];
}
